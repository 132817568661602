import { tutortoliveConstant } from "../_constants";
export default function TutorToLive(state = {}, action) {
  switch (action.type) {





    case tutortoliveConstant.GET_NEWLY_JOIN_TUTOR_LIST3_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case tutortoliveConstant.GET_NEWLY_JOIN_TUTOR_LIST3_SUCCESS:
      return {
        ...state,
        loading: false,
        SkipToutor: action?.users?.data?.total,
        SkipToutorList: action?.users?.data?.demoList
      };
    case tutortoliveConstant.GET_NEWLY_JOIN_TUTOR_LIST3_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };


    case tutortoliveConstant.GET_NEWLY_JOIN_TUTOR_LIST2_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case tutortoliveConstant.GET_NEWLY_JOIN_TUTOR_LIST2_SUCCESS:
      return {
        ...state,
        loading: false,
        RejectToutor: action?.users?.data?.total,
        RejectToutorList: action?.users?.data?.demoList
      };
    case tutortoliveConstant.GET_NEWLY_JOIN_TUTOR_LIST2_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };





    case tutortoliveConstant.GET_NEWLY_JOIN_TUTOR_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case tutortoliveConstant.GET_NEWLY_JOIN_TUTOR_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        getNewJoinTutorList: action?.users,
      };
    case tutortoliveConstant.GET_NEWLY_JOIN_TUTOR_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };




    case tutortoliveConstant.GET_TUTOR_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case tutortoliveConstant.GET_TUTOR_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        getTutorByIdList: action?.users,
      };
    case tutortoliveConstant.GET_TUTOR_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };











    case tutortoliveConstant.INTERVIEW_STATUS_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case tutortoliveConstant.INTERVIEW_STATUS_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,

      };
    case tutortoliveConstant.INTERVIEW_STATUS_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };



    case tutortoliveConstant.GET_NEWLY_JOIN_2_TUTOR_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case tutortoliveConstant.GET_NEWLY_JOIN_2_TUTOR_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        getNewJoinTutorListCount: action?.users

      };
    case tutortoliveConstant.GET_NEWLY_JOIN_2_TUTOR_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };



    default:
      return state;

  }
}