
import { allModuleConstant } from "../_constants";
import { headerForPrivateAPI, apiCallFunctionSecond } from "../_helpers"

export const moduleAction = {
    allModule,

}

function allModule(data) {

    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: "module/getAllModuleList"
    };
    return (dispatch) => {
        dispatch(request(data));
        apiCallFunctionSecond(credentials).then(
            (users) => {
                dispatch(success(users))
            },
            (error) => {

                dispatch(failure(error))
            }
        )

    }


    function request() { return { type: allModuleConstant.ALLMODULE_GET_REQUEST } }
    function success(users) { return { type: allModuleConstant.ALLMODULE_GET_SUCCESS, users } }
    function failure(error) { return { type: allModuleConstant.ALLMODULE_GET_FAILURE, error } }


};