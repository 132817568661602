import { pincodeConstants } from "../_constants/pincode.constants";

export default function Pincode(state = {}, action) {
  switch (action.type) {
    case pincodeConstants.GET_PINCODE_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case pincodeConstants.GET_PINCODE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        pincodeList: action?.users?.data?.list,
      };
    case pincodeConstants.GET_PINCODE_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case pincodeConstants.DELETE_PINCODE_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case pincodeConstants.DELETE_PINCODE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case pincodeConstants.DELETE_PINCODE_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case pincodeConstants.CREATE_PINCODE_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case pincodeConstants.CREATE_PINCODE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        pincodeCreate: action.users,
      };
    case pincodeConstants.CREATE_PINCODE_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case pincodeConstants.UPDATE_PINCODE_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case pincodeConstants.UPDATE_PINCODE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case pincodeConstants.UPDATE_PINCODE_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    default:
      return state;
  }
}
