import React from 'react'
import { FaStar } from 'react-icons/fa'
import { MdOutlineClose } from 'react-icons/md'

function RatingModel({ ratingModal, setRatingModal, ratingList }) {
    return ratingModal && (
        <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40">
                <div className="relative w-11/12 mx-auto lg:w-[800px]">
                    <button
                        className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center"
                        onClick={() => setRatingModal(false)}
                    >
                        <span><MdOutlineClose /></span> Close
                    </button>
                    <div className="form_class border-0 rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        <div className="flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                            <h2 className="text-lg font-semibold">Rating</h2>
                        </div>
                        <div className="relative lg:h-[400px] overflow-y-auto">
                            <div className="overflow-x-auto overflow-hidden p-4">
                                <table className="w-full bg-white table-auto border rounded-lg">
                                    <thead className="border">
                                        <tr className="bg-[#F3F4F6]">
                                            <th className="px-4 py-2  border text-left">Rating By</th>
                                            <th className="px-4 py-2  border text-left">Rating Remark</th>
                                            <th className="px-4 py-2  border text-left">Rating</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {ratingList &&
                                            ratingList.map((rating, id) => (
                                                <tr key={id} className="border-b capitalize">
                                                    <td className="px-4 py-2 border-b ">
                                                        {(rating?.ratingByAdmin?.userName || rating?.ratingByUser?.name) || "--"}
                                                    </td>
                                                    <td className="px-4 py-2 border-b ">
                                                        {rating?.remark ?? "-"}
                                                    </td>
                                                    <td className="px-4 py-2 border-b ">
                                                        <div className="flex items-center">
                                                            {Array.from({ length: rating.rating }, (_, index) => (
                                                                <FaStar key={index} className="text-yellow-500" />
                                                            ))}
                                                            <span className="ml-2">{rating.rating}</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RatingModel
