import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { parentAction } from '../../_actions';
import { useParams } from 'react-router-dom';
import moment from 'moment/moment';
import { IoArrowBack, IoAdd, IoRemove } from "react-icons/io5";


const TutorViewHistory = () => {
    const dispatch = useDispatch()
    const selector = useSelector(state => state)
    let historyData = selector?.Parent?.getLogHistoryList?.data?.list

    const { id } = useParams()

    useEffect(() => {
        let histoyDetails = {
            "userId": id,
            "keyWord": "",
            "pageNo": 1,
            "size": 10
        }
        dispatch(parentAction.getLogHistoryListByUserId(histoyDetails))
    }, [])
    const handleBack = () => {
        window.history.back();
    };
    return (
        <div>
            <div className="flex gap-2">
                <IoArrowBack
                    className="text-[#023565] text-3xl border rounded-full border-black my-2 mx-3 cursor-pointer bg-white"
                    onClick={handleBack}
                />
            </div>
            <div className='m-5'>


                <div class="relative overflow-x-auto">
                    <table class="w-full bg-white rounded-lg border ">
                        <thead class="border-b">
                            <tr className='capitalize'>
                                <th scope="col" class="px-6 py-3">
                                    Admin
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    user
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    activity
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    createdAt
                                </th>

                            </tr>
                        </thead>
                        <tbody>
                            {
                                Array.isArray(historyData) && historyData.length > 0 && historyData?.map((item, id) => (
                                    <tr class=" text-center capitalize" key={id}>
                                        <th scope="row" class="">
                                            {item && item?.isAdmin?.userName ? item?.isAdmin?.userName : "-"}
                                        </th>
                                        <td class="px-6 py-4">
                                            {item && item?.userId?.name ? item?.userId?.name : "-"}
                                        </td>
                                        <td class="px-6 py-4">
                                            {item && item?.activity ? item?.activity : null}
                                        </td>
                                        <td class="px-6 py-4">
                                            {item && item?.createdAt ? moment(item.createdAt).format('MMMM Do, YYYY h:mm:ss a') : null}
                                        </td>

                                    </tr>

                                ))
                            }

                        </tbody>
                    </table>
                </div>

            </div>
        </div>
    )
}

export default TutorViewHistory
