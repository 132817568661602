import { holidaysConstants } from "../_constants/holidays.constants";

export default function Holidays(state = {}, action) {
  switch (action.type) {
    case holidaysConstants.GET_HOLIDAY_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case holidaysConstants.GET_HOLIDAY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        holidaysList: action.users,
      };
    case holidaysConstants.GET_HOLIDAY_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case holidaysConstants.DELETE_HOLIDAY_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case holidaysConstants.DELETE_HOLIDAY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case holidaysConstants.DELETE_HOLIDAY_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case holidaysConstants.CREATE_HOLIDAY_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case holidaysConstants.CREATE_HOLIDAY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        holidayCreate: action?.users,
      };
    case holidaysConstants.CREATE_HOLIDAY_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case holidaysConstants.UPDATE_HOLIDAY_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case holidaysConstants.UPDATE_HOLIDAY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case holidaysConstants.UPDATE_HOLIDAY_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
      case holidaysConstants.ENA_DIS_HOLIDAY_LIST_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case holidaysConstants.ENA_DIS_HOLIDAY_LIST_SUCCESS:
        return {
          ...state,
          loading: false,
        };
      case holidaysConstants.ENA_DIS_HOLIDAY_LIST_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.error,
        };

    default:
      return {
        state,
      };
  }
}
