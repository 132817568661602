// IconComponent.js
import React from 'react'

function IconComponent ({ iconName }) {
  const iconMap = {
    dashboardLogo: '/dashboard-interface.png',
    mamangeLogo: '/manage.png',
    GroupIcon: '/Group.png',
    tutor: '/teacher (1).png',
    teacher: '/teacher.png',
    employ: '/check (1) (1).png',
    location: '/location.png',
    book: '/open-book.png',
    calander: '/calendar 2.png',
    termsCondition: '/terms-and-conditions 2.png',
    assignment: '/homework.png',
    visuals: '/visual.png',
    banner: '/banner 1.png',
    feedback: '/satisfaction.png',
    notigication: '/notification 1.png',
    idCard: '/id-card (1).png',
    support: '/support.png',
    strem: '/stream.png',
    dollar: '/dollar.png',
    persent: '/presentation (2).png',
    earning: '/earning.png',
    calender: '/calendar1.png',
    wallet: '/wallet.png',
    tutorprofile: '/teacher.png',
    parents: '/teacher.png',
    Employ: '/check (1) (1).png',
    city: '/location.png'
  }

  const iconUrl = iconMap[iconName]

  if (!iconUrl) {
    return null
  }

  return <img width={'25px'} src={iconUrl} alt={iconName} />
}

export default IconComponent
