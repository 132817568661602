import { employprofileConstant } from "../_constants";

export default function EmplyProfile(state = {}, action) {
    switch (action.type) {
        case employprofileConstant.GET_TEAM_LIST_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case employprofileConstant.GET_TEAM_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                getEmplyList: action?.users
            };
        case employprofileConstant.GET_TEAM_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case employprofileConstant.DELETE_TEAM_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case employprofileConstant.DELETE_TEAM_SUCCESS:
            return {
                ...state,
                loading: false,

            };
        case employprofileConstant.DELETE_TEAM_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };


        case employprofileConstant.CREATE_TEAM_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case employprofileConstant.CREATE_TEAM_SUCCESS:
            return {
                ...state,
                loading: false,
                getEmplyList: action?.users
            };
        case employprofileConstant.CREATE_TEAM_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };


        case employprofileConstant.GET_ALL_ADMIN_LIST_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case employprofileConstant.GET_ALL_ADMIN_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                getAdminList: action?.users
            };
        case employprofileConstant.GET_ALL_ADMIN_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };



        case employprofileConstant.UPDATE_TEAM_STATUS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case employprofileConstant.UPDATE_TEAM_STATUS_SUCCESS:
            return {
                ...state,
                loading: false,

            };
        case employprofileConstant.UPDATE_TEAM_STATUS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };



        case employprofileConstant.UPDATE_TEAM_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case employprofileConstant.UPDATE_TEAM_SUCCESS:
            return {
                ...state,
                loading: false,

            };
        case employprofileConstant.UPDATE_TEAM_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case employprofileConstant.GET_ALL_EMPLOY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case employprofileConstant.GET_ALL_EMPLOY_SUCCESS:
            return {
                ...state,
                loading: false,
                getAllEmplyList: action?.users

            };
        case employprofileConstant.GET_ALL_EMPLOY_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case employprofileConstant.UPDATE_SUBADMIN_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case employprofileConstant.UPDATE_SUBADMIN_SUCCESS:
            return {
                ...state,
                loading: false,


            };
        case employprofileConstant.UPDATE_SUBADMIN_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };


        case employprofileConstant.UPDATE_SUBADMIN_STATUS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case employprofileConstant.UPDATE_SUBADMIN_STATUS_SUCCESS:
            return {
                ...state,
                loading: false,


            };
        case employprofileConstant.UPDATE_SUBADMIN_STATUS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };


        case employprofileConstant.CREATE_SUBADMIN_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case employprofileConstant.CREATE_SUBADMIN_SUCCESS:
            return {
                ...state,
                loading: false,

            };
        case employprofileConstant.CREATE_SUBADMIN_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };




        default:
            return state


    }


}