import { APIcallFunction,headerForPrivateAPI } from "../_helpers"
import { positiveNegativeConstant } from "../_constants"

export const positiveNegativeAction={
  getPositiveNegativeFeedback
   
}

function getPositiveNegativeFeedback(data) {
    
    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: "feedback/getListPositiveNegativeStatus "
    };
    return (dispatch) => {
        dispatch(request(data));
        APIcallFunction(credentials).then(
            (users) => {
                dispatch(success(users))
            },
            (error) => {
                dispatch(failure(error))
            }
        )

    }


    function request() { return { type: positiveNegativeConstant.GET_POSITIVE_NEGATIVE_STATUS_LIST_REQUEST } }
    function success(users) { return { type: positiveNegativeConstant.GET_POSITIVE_NEGATIVE_STATUS_LIST_SUCCESS, users } }
    function failure(error) { return { type: positiveNegativeConstant.GET_POSITIVE_NEGATIVE_STATUS_LIST_FAILURE, error } }


};