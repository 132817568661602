/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect } from "react";
import { FaAngleDown } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { cityAction } from "../../../_actions/city.action";
import { downloadCSV } from "../../../_helpers";
import HeaderProfileComp from "../../../components/Header/HeaderProfileComp";
import HeaderHeading from "../../../components/Header/HeaderHeading";
import MobileSearchHeader from "../../../components/Header/MobileSearchHeader";




const FeeHeader = ({ active, headerCityId, setHeaderCityId, searchText, handleSearch, roleId }) => {

  const dispatch = useDispatch();
  const selector = useSelector((state) => state);
  useEffect(() => {
    let cityDetails = {
      isManagement: true
    };
    dispatch(cityAction.cityGet(cityDetails));
  }, []);






  const FeeData = selector?.FeeList?.FeeHistoryList?.data?.data?.list
  const fields = ['Parent Name', 'Mobile Number', 'Address', 'City', 'Assign Tutor', 'Fee Due Date ', 'Tuition Fee', "Class Reference", "Current Status", "Remark"];
  const fields2 = ['Parent Name', 'Mobile Number', 'Address', 'City', 'Assign Tutor', 'Fee Due Date ', 'Tuition Fee', "Current Status", "Remark"];


  const convertToCSV = (data, active) => {

    if (active === "All") {
      const array = typeof data !== 'object' ? JSON.parse(data) : data;
      let csv = fields.join(',') + '\r\n';

      array?.forEach(item => {
        const parentName = item?.parentId
          ?.name ? item?.ParentId?.name : '-';
        const mobNo = item?.ParentId?.mobNo ? item?.ParentId?.mobNo : '-';
        const city = item?.demoClassId?.cityId?.name ? item?.demoClassId?.cityId?.name : '-';
        const Tutor = item?.demoClassId?.tutorId?.name ? item?.demoClassId?.tutorId?.name : '-'

        const row = [parentName, mobNo, city, Tutor].join(',');
        csv += row + '\r\n';
      });
      return csv
    }
    if (active === "Unpaid") {
      const array = typeof data !== 'object' ? JSON.parse(data) : data;
      let csv = fields.join(',') + '\r\n';
      array?.forEach(item => {
        const parentName = item?.parentId
          ?.name ? item?.ParentId?.name : '-';
        const mobNo = item?.ParentId?.mobNo ? item?.ParentId?.mobNo : '-';
        const city = item?.demoClassId?.cityId?.name ? item?.demoClassId?.cityId?.name : '-';
        const Tutor = item?.demoClassId?.tutorId?.name ? item?.demoClassId?.tutorId?.name : '-'

        const row = [parentName, mobNo, city, Tutor].join(',');
        csv += row + '\r\n';
      });
      return csv
    }
    if (active === "Paid") {
      const array = typeof data !== 'object' ? JSON.parse(data) : data;
      let csv = fields.join(',') + '\r\n';
      array?.forEach(item => {
        const parentName = item?.parentId
          ?.name ? item?.ParentId?.name : '-';
        const mobNo = item?.ParentId?.mobNo ? item?.ParentId?.mobNo : '-';
        const studentsClasses = item && item.bookFreeDemo ? item.bookFreeDemo.map(demo => {
          const subjects = demo.subjectId ? demo.subjectId.map(sub => sub.name).join(';') : '-';
          const className = demo.classId ? demo.classId.name : '-';
          return `${subjects} (${className})`;
        }).join(' | ') : 'N/A';
        const city = item.cityId?.name ? item.cityId?.name : '-';
        const DateTime = item?.demoTime
        const assignedTutor = item?.tutorId?.name ? item?.tutorId?.name : '-';
        const Remark = item.remark ? item.remark : '-';
        const row = [parentName, mobNo, studentsClasses, city, DateTime, assignedTutor, Remark].join(',');
        csv += row + '\r\n';
      });
      return csv
    }
    if (active === "No Fee") {
      const array = typeof data !== 'object' ? JSON.parse(data) : data;
      let csv = fields2.join(',') + '\r\n';
      array?.forEach(item => {
        const parentName = item?.parentId
          ?.name ? item?.ParentId?.name : '-';
        const mobNo = item?.ParentId?.mobNo ? item?.ParentId?.mobNo : '-';
        const studentsClasses = item && item.bookFreeDemo ? item.bookFreeDemo.map(demo => {
          const subjects = demo.subjectId ? demo.subjectId.map(sub => sub.name).join(';') : '-';
          const className = demo.classId ? demo.classId.name : '-';
          return `${subjects} (${className})`;
        }).join(' | ') : 'N/A';
        const city = item.cityId?.name ? item.cityId?.name : '-';
        const DateTime = item?.demoTime
        const assignedTutor = item?.tutorId?.name ? item?.tutorId?.name : '-';
        const Feedback = item &&
          item.processStatus === 7 ? 'Approve' :
          item.processStatus === 8 ? 'Change Tutor Request' :
            item.processStatus === 6 ? 'Pending for Student' :
              null
        const Remark = item.remark ? item.remark : '-';
        const row = [parentName, mobNo, studentsClasses, city, DateTime, assignedTutor, Feedback, Remark].join(',');
        csv += row + '\r\n';
      });
      return csv
    }
    if (active === "Fee Refunded") {
      const array = typeof data !== 'object' ? JSON.parse(data) : data;
      let csv = fields.join(',') + '\r\n';
      array?.forEach(item => {
        const parentName = item?.parentId
          ?.name ? item?.ParentId?.name : '-';
        const mobNo = item?.ParentId?.mobNo ? item?.ParentId?.mobNo : '-';
        const address = item.address ? item.address : '-';
        const city = item.cityId?.name ? item.cityId?.name : '-';
        const tutionFee = item.tuitionFee ? item.tuitionFee : '-';
        const Reference = item.referenceFee ? item.referenceFee : '-';
        const tutorFee = item?.tutorFee ? item?.tutorFee : '-';
        const Company = item?.companyShare ? item?.companyShare : '-';
        const holdOn = item?.companyShare ? item?.companyShare : '-';
        const Remark = item.remark ? item.remark : '-';
        const row = [parentName, mobNo, address, city, tutionFee, Reference, tutorFee, Company, holdOn, Remark].join(',');
        csv += row + '\r\n';
      });
      return csv
    }



    ;
  };



  return (
    <Fragment>
      <section className=" bg-[#ffffff] lg:block hidden border-r border pl-4">


        <div className="w-full py-3 px-10 flex justify-between items-center">
          <HeaderHeading label={"fee"} />
          <div className="flex items-center ml-40">
            {/* <SearchIconComponent handleSearch={handleSearch} searchText={searchText} /> */}
            <div className="flex gap-2 relative  city_select cursor-pointer items-center">
              <select className="flex items-center capitalize justify-center bg-white border-2 text-[#000] rounded-lg w-full lg:w-36 px-4 h-9"
                value={headerCityId}
                onChange={(e) => { setHeaderCityId(e.target.value) }}
                disabled={roleId !== 1 ? true : false}
              >
                <option className="text-black align-middle" value={""}>
                  {" "}
                  All Cities <FaAngleDown className="ml-3" color="#D9D9D9" />
                </option>
                {selector?.Citys?.cityGetList?.map((city, id) => (
                  <option className="text-black capitalize" value={city._id} key={id}>
                    {" "}
                    {city.name}
                  </option>
                ))}
              </select>

        
              <div className="flex justify-end">
                {
                  active === "All" ? (
                    <button className="bg-white px-3 py-2 border flex justify-between rounded-lg" onClick={() => downloadCSV(convertToCSV(FeeData, active))}>
                      <span>Export</span>
                      <img src="/export 1.png" alt='' />
                    </button>
                  ) : active === "Unpaid" ? (
                    <button className="bg-white px-3 py-2 border flex justify-between rounded-lg" onClick={() => downloadCSV(convertToCSV(FeeData, active))}>
                      <span>Export</span>    <img src="/export 1.png" alt='' />
                    </button>
                  ) : active === "Paid" ? (
                    <button className="bg-white px-3 py-2 border flex justify-between rounded-lg" onClick={() => downloadCSV(convertToCSV(FeeData, active))}>
                      <span>Export</span>    <img src="/export 1.png" alt='' />
                    </button>
                  ) : active === "No Fee" ? (
                    <button className="bg-white px-3 py-2 border flex justify-between rounded-lg" onClick={() => downloadCSV(convertToCSV(FeeData, active))}>
                      <span>Export</span>    <img src="/export 1.png" alt='' />
                    </button>
                  ) : active === "Fee Refunded" ? (
                    <button className="bg-white px-3 py-2 border flex justify-between rounded-lg" onClick={() => downloadCSV(convertToCSV(FeeData, active))}>
                      <span>Export</span>    <img src="/export 1.png" alt='' />
                    </button>
                  ) : null
                }

              </div>
            </div>
          </div>
          <HeaderProfileComp />
        </div>
      </section>

      <section className="bg-white lg:hidden block">
        <MobileSearchHeader handleSearch={handleSearch} searchText={searchText} />
      </section>
    </Fragment>
  );
};

export default FeeHeader;
