/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import ParrentFeedback from './ParrentFeedback'
import './Feedback.css'
import FeedbackHeader from './FeedbackHeader'
import { useLocation, useNavigate } from 'react-router'
import { cityAction } from '../../_actions/city.action'

const Feedback = (navName, headerJSON, isOpen, menuRef, setIsOpen) => {
  const dispatch = useDispatch()
  const [active, setActive] = useState("Parents")
  const [searchText, setSearchText] = useState('')
  const btnName = ["Parents", "Positive", "Negative", "Issue Resolved"];
  const handleClick = (data) => {
    setActive(tabValueResult(data))
    navigate(`?tab=${tabValueResult(data)}`)
  }


  const location = useLocation()
  const navigate = useNavigate()
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const activeTab = queryParams.get('tab');
    if (activeTab) {
      setActive(activeTab);
      navigate(`?tab=${activeTab}`)
    } else {
      setActive("Parents");
      navigate(`?tab=${"Parents"}`)
    }
    dispatch(cityAction.cityGet({ isManagement: true }))

  }, [])


  function tabValueResult(state) {
    switch (state) {
      case "Issue Resolved":
        return "Issue-Resolved"
      default:
        return state
    }
  }

  const handleSearch = (e) => {
    setSearchText(e.target.value)
  }


  return (
    <section className='overflow-hidden overflow-y-auto'>

      <FeedbackHeader navName={navName} handleSearch={handleSearch} searchText={searchText} />
      <div className='m-5'>
        {/* <section className='flex gap-4 buttonDisplay'>
          {
            btnName.map((item, id) => (
              <div key={id} className={` text-white tableHead font-semibold px-4 py-2 rounded-full ${active === tabValueResult(item) ? 'bg-[#023565]' : 'bg-[#9C9EAB]'}`} onClick={() => handleClick(item)}>
                <Button name={item} handleClick={() => handleClick(item)} />
              </div>
            ))
          }
        </section> */}

        <section>
          {active === tabValueResult("Parents") && <ParrentFeedback searchText={searchText} />}
          {/* {active === tabValueResult('Positive') && <PositiveFeedback />} */}
          {/* {active === tabValueResult('Negative') && <NegativeFeedback />} */}
          {/* {active === tabValueResult('Issue Resolved') && <Resoloved />} */}
        </section>
      </div>
    </section>
  )
}

export default Feedback
