export const boardConstants={
    GET_BOARD_LIST_REQUEST:"GET_BOARD_LIST_REQUEST",
    GET_BOARD_LIST_SUCCESS:"GET_BOARD_LIST_SUCCESS",
    GET_BOARD_LIST_FAILURE:"GET_BOARD_LIST_FAILURE",

    CREATE_BOARD_LIST_REQUEST:"CREATE_BOARD_LIST_REQUEST",
    CREATE_BOARD_LIST_SUCCESS:"CREATE_BOARD_LIST_SUCCESS",
    CREATE_BOARD_LIST_FAILURE:"CREATE_BOARD_LIST_FAILURE",

    UPDATE_BOARD_LIST_REQUEST:"UPDATE_BOARD_LIST_REQUEST",
    UPDATE_BOARD_LIST_SUCCESS:"UPDATE_BOARD_LIST_SUCCESS",
    UPDATE_BOARD_LIST_FAILURE:"UPDATE_BOARD_LIST_FAILURE",

    DELETE_BOARD_LIST_REQUEST:"DELETE_BOARD_LIST_REQUEST",
    DELETE_BOARD_LIST_SUCCESS:"DELETE_BOARD_LIST_SUCCESS",
    DELETE_BOARD_LIST_FAILURE:"DELETE_BOARD_LIST_FAILURE",

    ENA_DIS_BOARD_LIST_REQUEST:"ENA_DIS_BOARD_LIST_REQUEST",
    ENA_DIS_BOARD_LIST_SUCCESS:"ENA_DIS_BOARD_LIST_SUCCESS",
    ENA_DIS_BOARD_LIST_FAILURE:"ENA_DIS_BOARD_LIST_FAILURE",
}