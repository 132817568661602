import { APIcallFunction, headerForPrivateAPI } from "../_helpers"
import { userListConstants } from "../_constants"
import { alertActions } from "./alert.actions";
import { tutionAction } from "./tution.actions";

export const userListAction = {
    getUserList,
    getKycByUserId,
    getOnGoingTutionList,
    getFeeHistoryByUserId,
    getRatingByuserId,
    blockByAdmin,
    getAppliedTutionList,
    getDemoDoneList,
    completeTutionList,
    kycReminder,
    packageReminder,
    updateTutorByAdmin,
    getUserTypeList,
    gitAllSubjectListBySelectedData,
    getUserList2,
    getTutorById,
    getAllTutorListWithNameOrId,
    getUserTypeListByPromise,
    getAllTutorListWithNameOrIdByPromise,
    getAllStudentListByPromise,
    getAllParentListWithNameOrIdByPromise,
    getTutorCountByPromise,
    getParentCountByPromise
}
function getParentCountByPromise(data) {
    const credentials = {
      header: headerForPrivateAPI(),
      method: "POST",
      body: data ?? {},
      endPoint: "user/getParentCount"
    };
  
    return APIcallFunction(credentials)
  
  }
function getTutorCountByPromise(data) {
    const credentials = {
      header: headerForPrivateAPI(),
      method: "POST",
      body: data ?? {},
      endPoint: "user/getTutorCount"
    };
  
    return APIcallFunction(credentials)
  
  }

function getAllParentListWithNameOrIdByPromise(data) {
    const credentials = {
      header: headerForPrivateAPI(),
      method: "POST",
      body: data ?? {},
      endPoint: "user/getAllParentListWithNameOrId"
    };
  
    return APIcallFunction(credentials)
  
  }

function getAllStudentListByPromise(data) {
    const credentials = {
      header: headerForPrivateAPI(),
      method: "POST",
      body: data ?? {},
      endPoint: "user/getAllStudentList"
    };
  
    return APIcallFunction(credentials)
  
  }

function getAllTutorListWithNameOrIdByPromise(data) {
    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: "user/getAllTutorListWithNameOrId"
    };

    return APIcallFunction(credentials)

}

function getUserTypeListByPromise(data) {
    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: "user/getUserTypeList"
    };

    return APIcallFunction(credentials)

}
function getAllTutorListWithNameOrId(data) {

    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: "user/getAllTutorListWithNameOrId"
    };
    return (dispatch) => {
        dispatch(request(data));
        APIcallFunction(credentials).then(
            (users) => {

                dispatch(success(users))
            },
            (error) => {

                dispatch(failure(error))
            }
        )

    }


    function request() { return { type: userListConstants.GET_ALLTUTORLIST_WITH_NAMEORID_REQUEST } }
    function success(users) { return { type: userListConstants.GET_ALLTUTORLIST_WITH_NAMEORID_SUCCESS, users } }
    function failure(error) { return { type: userListConstants.GET_ALLTUTORLIST_WITH_NAMEORID_FAILURE, error } }


};

function getUserList(data) {

    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: "user/getUserList "
    };
    return (dispatch) => {
        dispatch(request(data));
        APIcallFunction(credentials).then(
            (users) => {

                dispatch(success(users))
            },
            (error) => {

                dispatch(failure(error))
            }
        )

    }


    function request() { return { type: userListConstants.GET_USERLIST_REQUEST } }
    function success(users) { return { type: userListConstants.GET_USERLIST_SUCCESS, users } }
    function failure(error) { return { type: userListConstants.GET_USERLIST_FAILURE, error } }


};



function getUserList2(data) {

    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: "user/getUserTypeList "
    };
    return (dispatch) => {
        dispatch(request(data));
        APIcallFunction(credentials).then(
            (users) => {

                dispatch(success(users))
            },
            (error) => {

                dispatch(failure(error))
            }
        )

    }


    function request() { return { type: userListConstants.GET_USER_TYPE_REQUEST2 } }
    function success(users) { return { type: userListConstants.GET_USER_TYPE_SUCCESS2, users } }
    function failure(error) { return { type: userListConstants.GET_USER_TYPE_FAILURE2, error } }
};



function getTutorById(data) {
    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: "user/getTutorById"
    };
    return (dispatch) => {
        dispatch(request(data));

        return APIcallFunction(credentials)
            .then(
                (users) => {
                    dispatch(success(users));
                    dispatch(tutionAction.getTuitionCount())
                    return users; // Return the users data to allow chaining
                },
                (error) => {
                    dispatch(failure(error));
                    throw error; // Throw the error to allow error handling
                }
            );
    };

    function request() { return { type: userListConstants.GET_UPDATE_TUTOR_REQUEST }; }
    function success(users) { return { type: userListConstants.GET_UPDATE_TUTOR_SUCCESS, users }; }
    function failure(error) { return { type: userListConstants.GET_UPDATE_TUTOR_FAILURE, error }; }
}


function getKycByUserId(data) {

    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: "user/getKycByUserId"
    };
    return (dispatch) => {
        dispatch(request(data));
        APIcallFunction(credentials).then(
            (users) => {

                dispatch(success(users))
            },
            (error) => {

                dispatch(failure(error))
            }
        )

    }


    function request() { return { type: userListConstants.GETKYC_BYUSERID_REQUEST } }
    function success(users) { return { type: userListConstants.GETKYC_BYUSERID_SUCCESS, users } }
    function failure(error) { return { type: userListConstants.GETKYC_BYUSERID_FAILURE, error } }


};

function getOnGoingTutionList(data) {

    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: "demo/getOnGoingTuitionListByUserId"
    };
    return (dispatch) => {
        dispatch(request(data));
        APIcallFunction(credentials).then(
            (users) => {

                dispatch(success(users))
            },
            (error) => {

                dispatch(failure(error))
            }
        )

    }


    function request() { return { type: userListConstants.GET_ONGOING_TUTION_LIST_BYID_REQUEST } }
    function success(users) { return { type: userListConstants.GET_ONGOING_TUTION_LIST_BYID_SUCCESS, users } }
    function failure(error) { return { type: userListConstants.GET_ONGOING_TUTION_LIST_BYID_FAILURE, error } }


};

function getFeeHistoryByUserId(data) {

    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: "feeHistory/getFeeHistoryByUserId "
    };
    return (dispatch) => {
        dispatch(request(data));
        APIcallFunction(credentials).then(
            (users) => {

                dispatch(success(users))
            },
            (error) => {

                dispatch(failure(error))
            }
        )

    }


    function request() { return { type: userListConstants.GET_FREE_HISTORY_BYUSERID_REQUEST } }
    function success(users) { return { type: userListConstants.GET_FREE_HISTORY_BYUSERID_SUCCESS, users } }
    function failure(error) { return { type: userListConstants.GET_FREE_HISTORY_BYUSERID_FAILURE, error } }


};


function getRatingByuserId(data) {

    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: "rating/getRatingByUserId"
    };
    return (dispatch) => {
        dispatch(request(data));
        APIcallFunction(credentials).then(
            (users) => {

                dispatch(success(users))
            },
            (error) => {

                dispatch(failure(error))
            }
        )

    }


    function request() { return { type: userListConstants.GET_RATING_BYUSERID_REQUEST } }
    function success(users) { return { type: userListConstants.GET_RATING_BYUSERID_SUCCESS, users } }
    function failure(error) { return { type: userListConstants.GET_RATING_BYUSERID_FAILURE, error } }


};

function blockByAdmin(data, resData) {

    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: "user/blockByAdmin "
    };
    return (dispatch) => {
        dispatch(request(data));
        APIcallFunction(credentials).then(
            (users) => {

                dispatch(success(users))
                dispatch(getUserList(resData))
            },
            (error) => {

                dispatch(failure(error))
            }
        )

    }


    function request() { return { type: userListConstants.BLOCK_BY_ADMIN_REQUEST } }
    function success(users) { return { type: userListConstants.BLOCK_BY_ADMIN_SUCCESS, users } }
    function failure(error) { return { type: userListConstants.BLOCK_BY_ADMIN_FAILURE, error } }


};

function getAppliedTutionList(data) {

    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: "demo/getAppliedTuitionListByUserId "
    };
    return (dispatch) => {
        dispatch(request(data));
        APIcallFunction(credentials).then(
            (users) => {

                dispatch(success(users))
            },
            (error) => {

                dispatch(failure(error))
            }
        )

    }


    function request() { return { type: userListConstants.GET_APPLIED_TUTION_LIST_REQUEST } }
    function success(users) { return { type: userListConstants.GET_APPLIED_TUTION_LIST_SUCCESS, users } }
    function failure(error) { return { type: userListConstants.GET_APPLIED_TUTION_LIST_FAILURE, error } }


};

function getDemoDoneList(data) {

    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: "demo/getDemoDoneListByUserId "
    };
    return (dispatch) => {
        dispatch(request(data));
        APIcallFunction(credentials).then(
            (users) => {

                dispatch(success(users))
            },
            (error) => {

                dispatch(failure(error))
            }
        )

    }


    function request() { return { type: userListConstants.GET_DEMO_DONELIST_REQUEST } }
    function success(users) { return { type: userListConstants.GET_DEMO_DONELIST_SUCCESS, users } }
    function failure(error) { return { type: userListConstants.GET_DEMO_DONELIST_FAILURE, error } }


};

function completeTutionList(data, resData) {

    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: "demo/getCompletedTuitionListByUserId "
    };
    return (dispatch) => {
        dispatch(request(data));
        APIcallFunction(credentials).then(
            (users) => {

                dispatch(success(users))
                dispatch(getUserList(resData))
            },
            (error) => {

                dispatch(failure(error))
            }
        )

    }


    function request() { return { type: userListConstants.GET_COMPLETE_TUTION_LIST_REQUEST } }
    function success(users) { return { type: userListConstants.GET_COMPLETE_TUTION_LIST_SUCCESS, users } }
    function failure(error) { return { type: userListConstants.GET_COMPLETE_TUTION_LIST_FAILURE, error } }


};

function kycReminder(data, resData) {

    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: "notification/kycReminder  "
    };
    return (dispatch) => {
        dispatch(request(data));
        APIcallFunction(credentials).then(
            (users) => {

                dispatch(success(users))
                dispatch(getUserList(resData))
                dispatch(alertActions.success("KYC Reminder Success"));

            },
            (error) => {

                dispatch(failure(error))
            }
        )

    }


    function request() { return { type: userListConstants.KYC_REMINDER_REQUEST } }
    function success(users) { return { type: userListConstants.KYC_REMINDER_SUCCESS, users } }
    function failure(error) { return { type: userListConstants.KYC_REMINDER_FAILURE, error } }


};

function packageReminder(data, resData) {

    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: "notification/packageReminder"
    };
    return (dispatch) => {
        dispatch(request(data));
        APIcallFunction(credentials).then(
            (users) => {

                dispatch(success(users))
                dispatch(getUserList(resData))
                dispatch(alertActions.success("Package Reminder Success"));


            },
            (error) => {

                dispatch(failure(error))
            }
        )

    }


    function request() { return { type: userListConstants.PACKAGE_REMINDER_REQUEST } }
    function success(users) { return { type: userListConstants.PACKAGE_REMINDER_SUCCESS, users } }
    function failure(error) { return { type: userListConstants.PACKAGE_REMINDER_FAILURE, error } }
};

function updateTutorByAdmin(data, resData) {

    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: "user/updateTutorByAdmin "
    };
    return (dispatch) => {
        dispatch(request(data));
        APIcallFunction(credentials).then(
            (users) => {
                dispatch(success(users))
                dispatch(alertActions.success("Successfully updated."))
                dispatch(userListAction.getUserList(resData))
            },
            (error) => {
                dispatch(alertActions.error("Please try again later."))
                dispatch(failure(error))
            }
        )

    }


    function request() { return { type: userListConstants.UPDATE_TUTOR_BYADMIN_REQUEST } }
    function success(users) { return { type: userListConstants.UPDATE_TUTOR_BYADMIN_SUCCESS, users } }
    function failure(error) { return { type: userListConstants.UPDATE_TUTOR_BYADMIN_FAILURE, error } }
};





function getUserTypeList(data, resData) {

    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: "user/getUserTypeList "
    };
    return (dispatch) => {
        dispatch(request(data));
        APIcallFunction(credentials).then(
            (users) => {

                dispatch(success(users))
                dispatch(getUserList(resData))
                dispatch(tutionAction.getTuitionCount())
            },
            (error) => {

                dispatch(failure(error))
            }
        )

    }


    function request() { return { type: userListConstants.GET_USER_TYPE_REQUEST } }
    function success(users) { return { type: userListConstants.GET_USER_TYPE_SUCCESS, users } }
    function failure(error) { return { type: userListConstants.GET_USER_TYPE_FAILURE, error } }
};








function gitAllSubjectListBySelectedData(data, resData) {

    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: "subject/gitAllSubjectListBySelectedData "
    };
    return (dispatch) => {
        dispatch(request(data));
        APIcallFunction(credentials).then(
            (users) => {

                dispatch(success(users))
                dispatch(getUserList(resData))
            },
            (error) => {

                dispatch(failure(error))
            }
        )

    }


    function request() { return { type: userListConstants.GET_ALL_SUBJECT_LIST_REQUEST } }
    function success(users) { return { type: userListConstants.GET_ALL_SUBJECT_LIST_SUCCESS, users } }
    function failure(error) { return { type: userListConstants.GET_ALL_SUBJECT_LIST_FAILURE, error } }
};