/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { tutionAction } from '../../../_actions'
import { useParams } from 'react-router-dom';
import { IoArrowBack, } from "react-icons/io5";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import { dateInGivenFormat } from '../../../_helpers';
import TuitionHeader from "./TuitionHeader/TuitionHeader";




const ViewHistoy = () => {
    const dispatch = useDispatch();
    const selector = useSelector(state => state)
    let [data, setData] = useState('')
    const { id } = useParams()
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const pageSize = 10


    // eslint-disable-next-line no-unused-vars
    const [active, showActive] = useState("demo-request");
    const [searchText, setSearchText] = useState('')
    const [headerCity, setHeaderCity] = useState("")
    useEffect(() => {
        let viewHisatoyDetails = {
            demoId: id,
            "keyWord": "",
            pageNo: currentPage,
            size: pageSize,
        }

        dispatch(tutionAction.getLogHistoryList(viewHisatoyDetails))
    }, [currentPage])

    useEffect(() => {
        setData(selector?.tution?.getLogHistoryList?.data?.list || []);
    }, [selector])

    useEffect(() => {
        if (selector?.tution?.getLogHistoryList?.data?.total) {
            setTotalPages(Math.ceil(selector?.tution?.getLogHistoryList?.data?.total / pageSize));
        }
        // setdemoData(() => selector?.tution?.demoList?.data?.list)
    }, [selector]);

    const handlePreviousPage = () => {
        if (currentPage > 1) setCurrentPage(currentPage - 1);
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) setCurrentPage(currentPage + 1);
    };


    const handleBack = () => {
        window.history.back();
    };


    const handleCity = async (e) => {
        setHeaderCity(e.target.value)
    };


    const handleSearch = (e) => {
        setSearchText(e.target.value);

    };


    return (
        <>
            <TuitionHeader active={active} handleCity={handleCity} handleSearch={handleSearch} searchText={searchText} headerCity={headerCity} />
            <div className='m-5 overflow-hidden overflow-x-auto overflow-y-auto'>
                <div className="flex gap-2">
                    <IoArrowBack
                        className="text-[#023565] text-3xl border rounded-full border-black my-2 mx-3 cursor-pointer bg-white"
                        onClick={handleBack}
                    />
                </div>


                <div class=" bg-white border lg:block hidden border-[#D3D3D3] rounded-lg">
                    <table class="min-w-full table-auto divide-y divide-gray-200 ">
                        <thead class="border-b">
                            <tr class="capitalize">
                                <th class="px-6 py-3 whitespace-nowrap text-black text-left">
                                    User Name
                                </th>
                                <th class="px-6 py-3 whitespace-nowrap text-black text-left">
                                    Type
                                </th>
                                <th class="px-6 py-3 whitespace-nowrap text-black text-left">
                                    Activity
                                </th>
                                <th class="px-6 py-3 whitespace-nowrap text-black text-left">
                                    Date
                                </th>
                                <th class="px-6 py-3 whitespace-nowrap text-black text-left">
                                    Time
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {data && data.map((item, id) => (
                                <tr class="border-b capitalize" key={id}>
                                    <td class="px-6 py-4 text-gray-900">
                                        {item?.isAdmin?.userName || item?.userId?.name}
                                    </td>
                                    <td class="px-6 py-4 text-gray-900">
                                        {item?.isAdmin ? "Admin" : item?.userId ? item?.userId?.userType === "PARENT" ? "Parent" : "Tutor" : "N/A"}
                                    </td>
                                    <td class="px-6 py-4 text-gray-900">
                                        {item?.activity || "-"}
                                    </td>
                                    <td class="px-6 py-4 text-gray-900">
                                        {item?.createdAt ? dateInGivenFormat(item?.createdAt, "DD-MM-YYYY") : "N/A"}
                                    </td>
                                    <td class="px-6 py-4 text-gray-900">
                                        {item?.createdAt ? dateInGivenFormat(item?.createdAt, "hh:mm A") : "N/A"}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                </div>
                <section className="space-y-5 lg:hidden block overflow-x-auto overflow-y-auto">
                    {data && data.length > 0 ? (
                        data?.map((item, index) => (
                            <div key={index} className=' bg-white p-4 rounded-[10px]'>
                                <>
                                    <div className='flex justify-between p-2 w-full'>
                                        <div className='w-full'>
                                            <p className='text-[16px] font-[700] text-left'> User Name</p>
                                        </div>
                                        <div className='w-full'>
                                            {item?.userId?.name || "N/A"}

                                        </div>
                                    </div>
                                    <div className='flex justify-between p-2 w-full'>
                                        <div className='w-full'>
                                            <p className='text-[16px] font-[700] text-left'> Type</p>
                                        </div>
                                        <div className='w-full'>Admin</div>
                                    </div>
                                    <div className='flex justify-between p-2 w-full'>
                                        <div className='w-full'>
                                            <p className='text-[16px] font-[700] text-left'>Activity</p>
                                        </div>
                                        <div className='w-full'>
                                            {item?.activity || "N/A"}

                                        </div>
                                    </div>
                                    <div className='flex justify-between p-2 w-full'>
                                        <div className='w-full'>
                                            <p className='text-[16px] font-[700] text-left'>Date</p>
                                        </div>
                                        <div className='w-full'>
                                            {item?.createdAt ? dateInGivenFormat(item?.createdAt, "DD-MM-YYYY") : "N/A"}
                                        </div>
                                    </div>
                                    <div className='flex justify-between p-2 w-full'>
                                        <div className='w-full'>
                                            <p className='text-[16px] font-[700] text-left'>Time</p>
                                        </div>
                                        <div className='w-full'>
                                            {item?.createdAt ? dateInGivenFormat(item?.createdAt, "hh:mm A") : "N/A"}

                                        </div>
                                    </div>






                                </>
                            </div>

                        ))

                    ) : (
                        <p className="text-center">Data Not Found</p>
                    )

                    }
                    {totalPages > 1 && (
                        <div className="flex justify-end items-center py-2">
                            <button
                                className={`px-4 py-2 border text-black rounded ${currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""}`}
                                onClick={handlePreviousPage}
                                disabled={currentPage === 1}
                            >
                                <MdOutlineKeyboardArrowLeft />
                            </button>
                            <span className="text-gray-700 mx-1">
                                <span className="border px-4 py-2 text-black rounded">{currentPage}</span> of {totalPages}
                            </span>
                            <button
                                className={`px-4 py-2 border text-black rounded ${currentPage === totalPages ? "opacity-50 cursor-not-allowed" : ""}`}
                                onClick={handleNextPage}
                                disabled={currentPage === totalPages}
                            >
                                <FaChevronRight />
                            </button>
                        </div>
                    )}
                </section>
                {totalPages > 1 && (
                    <div className="flex justify-end items-center py-2 px-4">
                        <button
                            className={`px-4 py-2 border text-white bg-[#023565] rounded ${currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""}`}
                            onClick={handlePreviousPage}
                            disabled={currentPage === 1}
                        >
                            <FaChevronLeft />
                        </button>
                        <span className="text-gray-700 mx-1">
                            <span className="border px-4 py-2 text-black  rounded">{currentPage}</span> of <span className="border px-4 py-2 text-black  rounded">{totalPages}</span>
                        </span>
                        <button
                            className={`px-4 py-2 border text-white bg-[#023565] rounded ${currentPage === totalPages ? "opacity-50 cursor-not-allowed" : ""}`}
                            onClick={handleNextPage}
                            disabled={currentPage === totalPages}
                        >
                            <FaChevronRight />
                        </button>
                    </div>
                )}
            </div>
        </>
    )
}

export default ViewHistoy
