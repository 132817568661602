import { feeConstant } from "../_constants";

export default function Fee(state = {}, action) {
    switch (action.type) {
        case feeConstant.GET_TUTION_EARNING_LIST_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case feeConstant.GET_TUTION_EARNING_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                tutionList: action?.users
            };
        case feeConstant.GET_TUTION_EARNING_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };


        case feeConstant.GET_CREDIT_BASE_TUITION_EARNING_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case feeConstant.GET_CREDIT_BASE_TUITION_EARNING_SUCCESS:
            return {
                ...state,
                loading: false,
                creditBaseTuition: action?.users
            };
        case feeConstant.GET_CREDIT_BASE_TUITION_EARNING_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case feeConstant.GET_FEE_RECEVIED_LIST_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case feeConstant.GET_FEE_RECEVIED_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                feeRecevied: action?.users
            };
        case feeConstant.GET_FEE_RECEVIED_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case feeConstant.GET_ALL_FEE_RECEIVED_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case feeConstant.GET_ALL_FEE_RECEIVED_SUCCESS:
            return {
                ...state,
                loading: false,
                allFeeReceived: action?.users
            };
        case feeConstant.GET_ALL_FEE_RECEIVED_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };


        case feeConstant.GET_FEE_PAID_LIST_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case feeConstant.GET_FEE_PAID_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                feePaidList: action?.users
            };
        case feeConstant.GET_FEE_PAID_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };


        case feeConstant.GET_ALL_FEE_PAID_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case feeConstant.GET_ALL_FEE_PAID_SUCCESS:
            return {
                ...state,
                loading: false,
                allFeePaid: action?.users
            };
        case feeConstant.GET_ALL_FEE_PAID_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case feeConstant.GET_FEE_PENDING_LIST_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case feeConstant.GET_FEE_PENDING_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                feePending: action?.users
            };
        case feeConstant.GET_FEE_PENDING_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case feeConstant.GET_ALL_FEE_PENDING_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case feeConstant.GET_ALL_FEE_PENDING_SUCCESS:
            return {
                ...state,
                loading: false,
                allFeePending: action?.users
            };
        case feeConstant.GET_ALL_FEE_PENDING_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case feeConstant.GET_REFERENCE_PAID_LIST_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case feeConstant.GET_REFERENCE_PAID_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                referencePaidList: action?.users
            };
        case feeConstant.GET_REFERENCE_PAID_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case feeConstant.GET_ALL_REFERENCE_PAID_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case feeConstant.GET_ALL_REFERENCE_PAID_SUCCESS:
            return {
                ...state,
                loading: false,
                allReferencePaid: action?.users
            };
        case feeConstant.GET_ALL_REFERENCE_PAID_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };


        case feeConstant.GET_REFERRAL_PAID_LIST_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case feeConstant.GET_REFERRAL_PAID_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                referralPaidList: action?.users
            };
        case feeConstant.GET_REFERRAL_PAID_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };


        case feeConstant.GET_LIST_REFERRAL_PAID_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case feeConstant.GET_LIST_REFERRAL_PAID_SUCCESS:
            return {
                ...state,
                loading: false,
                allReferralPaid: action?.users
            };
        case feeConstant.GET_LIST_REFERRAL_PAID_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case feeConstant.GET_FEE_REFUND_LIST_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case feeConstant.GET_FEE_REFUND_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                feeRefundList: action?.users
            };
        case feeConstant.GET_FEE_REFUND_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case feeConstant.GET_TOTAL_RECEIVED_AMOUNT_LIST_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case feeConstant.GET_TOTAL_RECEIVED_AMOUNT_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                feeRefundListCb: action?.users
            };
        case feeConstant.GET_TOTAL_RECEIVED_AMOUNT_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case feeConstant.GET_TOTAL_REFUNDED_AMOUNT_LIST_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case feeConstant.GET_TOTAL_REFUNDED_AMOUNT_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                feeRefundDataCb: action?.users
            };
        case feeConstant.GET_TOTAL_REFUNDED_AMOUNT_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case feeConstant.GET_ALL_FEE_REFUND_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case feeConstant.GET_ALL_FEE_REFUND_SUCCESS:
            return {
                ...state,
                loading: false,
                allFeeRefund: action?.users
            };
        case feeConstant.GET_ALL_FEE_REFUND_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case feeConstant.GET_TUTOR_FEE_PAID_LIST_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case feeConstant.GET_TUTOR_FEE_PAID_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                tutorFeePaid: action?.users
            };
        case feeConstant.GET_TUTOR_FEE_PAID_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case feeConstant.GET_ALL_TUTOR_FEE_PAID_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case feeConstant.GET_ALL_TUTOR_FEE_PAID_SUCCESS:
            return {
                ...state,
                loading: false,
                allTutorFeePaid: action?.users
            };
        case feeConstant.GET_ALL_TUTOR_FEE_PAID_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };



        case feeConstant.GET_TUTOR_FEE_PENDIGN_LIST_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case feeConstant.GET_TUTOR_FEE_PENDIGN_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                tutorFeePending: action?.users
            };
        case feeConstant.GET_TUTOR_FEE_PENDIGN_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };


        case feeConstant.GET_ALL_TUTOR_FEE_PENDING_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case feeConstant.GET_ALL_TUTOR_FEE_PENDING_SUCCESS:
            return {
                ...state,
                loading: false,
                allTutorFeePending: action?.users
            };
        case feeConstant.GET_ALL_TUTOR_FEE_PENDING_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };




        case feeConstant.GET_TRANSACTION_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case feeConstant.GET_TRANSACTION_SUCCESS:
            return {
                ...state,
                loading: false,
                getTransactionList: action?.users
            };
        case feeConstant.GET_TRANSACTION_FAILURE:
            return {
                ...state,
                loading: false,
                getTransactionList: {},
                error: action.error,
            };




        case feeConstant.FEE_REMARK_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case feeConstant.FEE_REMARK_SUCCESS:
            return {
                ...state,
                loading: false,

            };
        case feeConstant.FEE_REMARK_FAILURE:
            return {
                ...state,
                loading: false,
                getTransactionList: {},
                error: action.error,
            };










        case feeConstant.GET_FEE_HISTORY_COUNT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case feeConstant.GET_FEE_HISTORY_COUNT_SUCCESS:
            return {
                ...state,
                loading: false,
                feeCount: action?.users
            };
        case feeConstant.GET_FEE_HISTORY_COUNT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case feeConstant.UPDATE_FEE_DUE_DATE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case feeConstant.UPDATE_FEE_DUE_DATE_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case feeConstant.UPDATE_FEE_DUE_DATE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case feeConstant.GET_NO_FEE_LIST_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case feeConstant.GET_NO_FEE_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                noFee: action?.users
            };
        case feeConstant.GET_NO_FEE_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case feeConstant.GET_ALL_NO_FEE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case feeConstant.GET_ALL_NO_FEE_SUCCESS:
            return {
                ...state,
                loading: false,
                allNoFee: action?.users
            };
        case feeConstant.GET_ALL_NO_FEE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        default:
            return state

    }

}