import { feedbackConstants } from "../_constants";

export default function Feedback(state = {}, action) {
  switch (action.type) {
    case feedbackConstants.GET_FEEDBACK_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case feedbackConstants.GET_FEEDBACK_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        feedbackList: action?.user,
      };
    case feedbackConstants.GET_FEEDBACK_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

      case feedbackConstants.UPDATE_FEEDBACK_STATUS_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case feedbackConstants.UPDATE_FEEDBACK_STATUS_SUCCESS:
        return {
          ...state,
          loading: false,
         
        };
      case feedbackConstants.UPDATE_FEEDBACK_STATUS_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.error,
        };



        case feedbackConstants.UPDATE_FEEDBACK_ISSUE_RESOLVE_REQUEST:
          return {
            ...state,
            loading: true,
          };
        case feedbackConstants.UPDATE_FEEDBACK_ISSUE_RESOLVE_SUCCESS:
          return {
            ...state,
            loading: false,
           
          };
        case feedbackConstants.UPDATE_FEEDBACK_ISSUE_RESOLVE_FAILURE:
          return {
            ...state,
            loading: false,
            error: action.error,
          };
       default:
         return state ;
      
  }
}
