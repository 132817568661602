/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react'
import { FaChevronLeft, FaEye } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import {
  parentAction,
  tutionAction,
  userActions,
  userListAction
} from '../../../_actions'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom'
import {
  FaAngleRight,
  FaChevronCircleRight,
  FaChevronRight
} from 'react-icons/fa'
import Loader from '../../../components/Loader'
import {
  teachModeViewData,
  timeStanderFollowFunction,
  truncateText
} from '../../../_helpers'
import moment from 'moment'
import StudentDetail from './Models/StudentDetail'
import ImagePreviewModal from '../../Tutor-Profile/Model/ImagePreview'
import UpdateNoteModel from './Models/UpdateNoteModel'
import ViewParent from './Models/ViewParent'
import TuitionFilters from './TuitionFilters'
import ViewMoreTuitionModel from './Models/ViewMoreTuitionModel'

const ButtonEightTabel = ({ searchText, headerCity }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const selector = useSelector(state => state)

  // let rejectDemoData = ;
  const [open, setOpen] = useState(false)
  const [historyDataModal, setHistoryDataModal] = useState(false)
  const [rejectDemoData, setrejectDemoData] = useState([])

  const [historyData, setHistoryData] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const pageSize = 6

  const menuRef = useRef(null)

  const mobileRef = useRef(null)
  const [filters, setFilters] = useState({
    tutorFee: '',
    preferredTeachMode: '',
    tutorId: '',
    classId: '',
    subjectId: '',
    classReference: '',
    leadSource: '',
    requiredGender: '',
    assignToEmployee: '',
    parentId: ''
  })

  const handleClickOutside = event => {
    if (
      menuRef.current &&
      !menuRef.current.contains(event.target) &&
      mobileRef.current &&
      !mobileRef.current.contains(event.target)
    ) {
      setOpen(null)
    }
  }
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    let listData = {
      status: [2],
      keyWord: searchText,
      cityId: headerCity ? [headerCity] : '',
      fromDate: '',
      toDate: '',
      sortOrder: 'asc',
      sortBy: 'createdAt',
      pageNo: currentPage,
      size: pageSize,
      ...filters,
      tutorId: filters.tutorId !== '' ? [filters.tutorId] : [],
      parentId: filters.parentId !== '' ? [filters.parentId] : []
    }

    dispatch(tutionAction.getDemoClassListStatus(listData, listData.cityId))
  }, [currentPage, filters, headerCity, searchText])

  useEffect(() => {
    setrejectDemoData(() => selector?.tution?.demoList?.data?.list)
  }, [selector])

  useEffect(() => {
    if (selector?.tution?.demoList?.data?.total) {
      setTotalPages(
        Math.ceil(selector?.tution?.demoList?.data?.total / pageSize)
      )
    }
  }, [selector])

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1)
  }

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1)
  }

  const openAction = id => {
    setOpen(prevId => (prevId === id ? null : id))
  }

  const handleHistory = data => {
    navigate(`/app/viewhistory/${data?._id}`)
    setHistoryDataModal(true)
    setHistoryData([data])
  }

  const handleSendToDemo = data => {
    let listData = {
      status: [2],
      keyWord: searchText,
      cityId: headerCity ? [headerCity] : '',
      fromDate: '',
      toDate: '',
      sortOrder: '',
      sortBy: '',
      pageNo: currentPage,
      size: pageSize,
      ...filters,
      tutorId: filters.tutorId !== '' ? [filters.tutorId] : [],
      parentId: filters.parentId !== '' ? [filters.parentId] : []
    }
    let dataId = {
      demoId: data._id
    }

    dispatch(tutionAction.revertDemoClass(dataId, listData))
    // dispatch(tutionAction.getTuitionCount())
    const alertBox = document.getElementById('alert-box')
    alertBox.style.display = 'block'
    setTimeout(() => {
      alertBox.style.display = 'none'
    }, 1000)

    setOpen(false)
  }

  const [remarkModal, setRemarkModal] = useState(false)
  const remarkId = ''
  const [remark, setRemark] = useState('')

  const handleRemarkSubmit = e => {
    e.preventDefault()
    let demoData = {
      status: [2],
      // leadCreate: 'CREATED_SELF',
      keyWord: searchText,
      cityId: headerCity ? [headerCity] : '',
      fromDate: '',
      toDate: '',
      sortOrder: '',
      sortBy: '',
      pageNo: currentPage,
      size: pageSize,
      ...filters,
      tutorId: filters.tutorId !== '' ? [filters.tutorId] : [],
      parentId: filters.parentId !== '' ? [filters.parentId] : []
    }
    let resStatus = 'Hold'

    let remarkData = {
      demoId: remarkId,
      remark: remark ? remark : ''
    }
    dispatch(tutionAction.addUpdateRemark(remarkData, demoData, resStatus))
    setRemarkModal(false)
    setRemark('')
  }

  const remarkClose = () => {
    setRemark('')
    setRemarkModal(false)
  }

  useEffect(() => {
    let details = {
      cityId: '',
      userType: 'TUTOR',
      isDisable: false
    }
    dispatch(userListAction.getUserTypeList(details))
  }, [])

  const [viewStudentModal, setViewStudentModal] = useState(false)
  const [studentListdata, setStudentListData] = useState()
  const viewStudent = (data, demoId) => {
    let studentListDetails = {
      parentId: data._id,
      demoId: demoId
    }
    parentAction
      .getStudentListByParentIdAndDemoIdPromise(studentListDetails)
      .then(resData => {
        setViewStudentModal(true)
        setStudentListData(resData.data)
      })
      .catch(err => {})
  }

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [image, setImage] = useState('')
  const handleCloseFunctionImageModel = data => {
    if (data) {
      setImage(() => data)
    } else {
      setImage('')
    }
    setIsModalVisible(!isModalVisible)
  }

  const [fullDetails, setFullDetails] = useState(false)
  const [DetailsData, setFullDetailsData] = useState()
  const [tuitionDetails, setTuitionDetails] = useState({})
  const handleFullDetails = data => {
    userActions.getUserByPromiseId({ id: data.parentId._id }).then(res => {
      setFullDetails(true)
      setFullDetailsData(res?.data)
      setTuitionDetails({
        classDuration: data?.classDuration,
        tuitionFee: data?.tuitionFee,
        numberOfClasses: data?.numberOfClasses,
        tutorFee: data?.tutorFee,
        tuitionType: data?.tuitionType,
        teachingLocation: data?.teachingLocation,
        requiredGender: data?.requiredGender,
        location: data?.location,
        classReference: data?.classReference
      })
    })
  }
  const [isViewMoreModel, setIsViewMoreModel] = useState(false)
  const tuitionId = ''

  return (
    <div className=' '>
      <Loader loading={selector?.tution?.loading} />

      <TuitionFilters filters={filters} setFilters={setFilters} />

      <section className='lg:py-6'>
        <div className='bg-white border lg:block hidden border-[#D3D3D3] rounded-lg overflow-x-auto'>
          <table className='min-w-full table-auto divide-y divide-gray-200 '>
            <thead className='min-w-full rounded-sm'>
              <tr className='table_head border-b '>
                <th className='px-6 py-3 whitespace-nowrap text-sm text-black'>
                  Parent Name
                </th>
                <th className='px-6 py-3 whitespace-nowrap text-sm text-black'>
                  Student
                </th>
                <th className='px-6 py-3 whitespace-nowrap text-sm text-black'>
                  <tr className='flex justify-center items-center'> Address</tr>
                  <tr className='flex justify-center items-center'> City</tr>
                </th>
                <th className='px-6 py-3 whitespace-nowrap text-sm text-black'>
                  <tr className='flex justify-center items-center'>
                    {' '}
                    Demo Date
                  </tr>
                  <tr className='flex justify-center items-center'> Time</tr>
                </th>
                <th className='px-6 py-3 whitespace-nowrap text-sm text-black'>
                  <tr className='flex justify-center items-center'>
                    Lead Source
                  </tr>
                  <tr className='flex justify-center items-center'>Manager</tr>
                </th>
                <th className='px-6 py-3 whitespace-nowrap text-sm text-black'>
                  Mode
                </th>
                <th className='px-6 py-3 whitespace-nowrap text-sm text-black'>
                  Rejected Reason
                </th>
                <th className='px-6 py-3 whitespace-nowrap text-sm'>Action</th>
              </tr>
            </thead>

            <tbody className='bg-white divide-y divide-gray-200'>
              {rejectDemoData && rejectDemoData.length > 0 ? (
                rejectDemoData?.map((item, index) => (
                  <tr key={index} className='border-b capitalize'>
                    <td className='px-4 py-3 flex items-center gap-4 text-sm text-gray-900'>
                      <div className='flex items-center justify-center gap-2'>
                        <span
                          onClick={
                            item?.parentId?.image
                              ? () =>
                                  handleCloseFunctionImageModel(
                                    item?.parentId?.image
                                  )
                              : null
                          }
                        >
                          {item?.parentId && item?.parentId?.image ? (
                            <img
                              alt=''
                              src={
                                item?.parentId && item?.parentId?.image
                                  ? item?.parentId?.image
                                  : 'https://thumbs.dreamstime.com/z/vector-illustration-avatar-dummy-logo-collection-image-icon-stock-isolated-object-set-symbol-web-137160339.jpg?ct=jpeg'
                              }
                              onError={e => {
                                e.target.src =
                                  'https://thumbs.dreamstime.com/z/vector-illustration-avatar-dummy-logo-collection-image-icon-stock-isolated-object-set-symbol-web-137160339.jpg?ct=jpeg'
                              }}
                              className='rounded-full my-3 max-w-[50px] max-h-[50px] min-w-[50px] min-h-[50px]'
                            />
                          ) : (
                            <div className='bg-gray-300 rounded-full h-14 w-14 flex justify-center items-center'>
                              <span className='text-base font-semibold text-[#033565]'>
                                {' '}
                                {item?.parentId?.name
                                  ? item.parentId.name
                                      .split(' ')
                                      .map(name => name.charAt(0))
                                      .join('')
                                  : ''}
                              </span>
                            </div>
                          )}
                        </span>
                        <div className='w-24'>
                          <p className='font-semibold whitespace-nowrap text-[#023565]'>
                            {item?.parentId?.name ?? 'Not Available'}
                          </p>
                          <p className='text-gray-500 whitespace-nowrap'>
                            {item?.parentId?.mobNo ?? 'Not Available'}
                          </p>
                          <div
                            className='flex items-center  gap-1 cursor-pointer'
                            onClick={() => handleFullDetails(item)}
                          >
                            <p className='text-base font-normal cursor-pointer'>
                              More
                            </p>
                            <FaChevronCircleRight className='text-[#023565] text-base cursor-pointer' />
                          </div>
                        </div>
                      </div>
                    </td>

                    <td className='px-4 py-3 text-sm'>
                      <span className='flex items-center justify-center'>
                        <FaEye
                          size={24}
                          className='cursor-pointer text-[#023565] hover:text-[#E4006F]'
                          onClick={() => viewStudent(item?.parentId, item._id)}
                        />
                      </span>
                    </td>

                    <td className='px-4 py-3 text-sm text-gray-900'>
                      <span
                        className='flex justify-center items-center text-center cursor-pointer'
                        title={item?.address || 'N/A'}
                      >
                        {' '}
                        {truncateText(item?.address, 15) ?? 'N/A'}
                      </span>
                      <span className='flex justify-center items-center'>
                        {item.cityId?.name ?? 'N/A'}
                      </span>
                    </td>

                    <td className='px-4 py-3 text-sm text-gray-900'>
                      <span className='text-center flex items-center justify-center'>
                        {moment(item?.demoDoneDate).format('DD-MMM-YYYY')}
                      </span>
                      <span className='text-center flex items-center justify-center'>
                        {moment(item?.demoDoneDate).format('hh:mm A')}
                      </span>
                    </td>

                    <td className='px-4 py-3 text-sm text-gray-900'>
                      <span className='flex items-center justify-center'>
                        {item.leadSource === 'CREATED_SELF' ? 'App' : 'Admin'}
                      </span>
                      <span className='flex items-center justify-center'>
                        {item?.assignToEmployee
                          ? item.assignToEmployee.userName
                          : 'N/A'}
                      </span>
                    </td>

                    <td className='px-4 py-3 text-sm text-gray-900 text-center'>
                      {teachModeViewData(item?.preferredTeachMode)}
                    </td>

                    <td className='px-4 py-3 text-sm text-gray-900 text-center' title={item?.rejectReason ? item.rejectReason : '---'}>
                      {truncateText(item?.rejectReason ? item.rejectReason : '---', 20)}
                    </td>

                    <td
                      overflow-x-scroll
                      className='px-4 py-3 text-gray-900 relative table-cell'
                    >
                      <span className='flex justify-center items-center'>
                        {' '}
                        <BsThreeDotsVertical
                          onClick={() => openAction(item._id)}
                          className='text-2xl cursor-pointer text-red-500 '
                        />
                      </span>
                      {open === item._id && (
                        <ul
                          className='bg-white border text-[#023565] rounded-md font-bold  absolute w-60 right-20 bottom-2 mt-4 py-1.5 text-start px-2 text-sm shadow-lg z-50'
                          ref={menuRef}
                        >
                          <li
                            className=' px-2 py-1 border-b  flex justify-between items-center hover:text-[#C60E6B]'
                            onClick={() => handleSendToDemo(item)}
                          >
                            <button className='w-full text-left'>
                              {' '}
                              Send to Demo Request
                            </button>
                            <FaAngleRight className='text-md' />
                          </li>
                          <li
                            className=' px-2 py-1 border-b  flex justify-between items-center hover:text-[#C60E6B]'
                            onClick={() => handleHistory(item)}
                          >
                            <button className='w-full text-left'>
                              View History
                            </button>
                            <FaAngleRight className='text-md' />
                          </li>
                        </ul>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr className='border-b px-6 py-4 text-gray-900'>
                  <td colSpan='9'>Data Not Available</td>
                </tr>
              )}
            </tbody>
            {historyDataModal && (
              <div className='fixed   top-40 left-[30%] w-2/4 h-auto bg-gray-900 bg-opacity-50 '>
                <div
                  className=' text-white p-3  cursor-pointer text-end font-semibold'
                  onClick={() => setHistoryDataModal(false)}
                >
                  Close
                </div>
                <div className='bg-white  shadow-md p-4 w-full'>
                  <table className='w-full table-auto border-collapse border rounded'>
                    <thead>
                      <tr className='border-b'>
                        <th className=' p-2'>Address</th>
                        <th className=' p-2'>Board</th>
                        <th className=' p-2'>Class Mode</th>
                        <th className=' p-2'>Tuition Fee</th>
                        <th className=' p-2'>Tutor's Fee</th>
                        <th className=' p-2'>No. of classes</th>
                        <th className=' p-2'>Class Duration</th>
                        <th className=' p-2'>Class Reference</th>
                      </tr>
                    </thead>
                    <tbody>
                      {historyData &&
                        historyData?.map((itm, id) => (
                          <tr key={id} className='text-center'>
                            <td className='p-2'>
                              {itm?.board && itm?.board?.name
                                ? itm?.board?.name
                                : 'No Board'}
                            </td>
                            <td className='p-2'>
                              {itm?.preferredTeachMode
                                ? itm?.preferredTeachMode
                                : null}
                            </td>
                            <td className='p-2'>
                              {itm?.tuitionFee ? itm?.tuitionFee : 'No Data'}
                            </td>
                            <td className='p-2'>
                              {itm?.tutorFee ? itm?.tutorFee : null}
                            </td>
                            <td className='p-2'>
                              {itm?.numberOfClasses
                                ? itm?.numberOfClasses
                                : null}
                            </td>
                            <td className='p-2'>
                              {itm?.classDuration ? itm?.classDuration : null}
                            </td>
                            <td className='p-2'>
                              {itm?.classReference ? itm?.classReference : null}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </table>
        </div>
      </section>

      <section className='space-y-5 lg:hidden block'>
        {rejectDemoData && rejectDemoData.length > 0 ? (
          rejectDemoData?.map((item, index) => (
            <div
              key={index}
              className=' bg-white p-4 rounded-[10px] border border-[#023565]'
            >
              <div className='flex justify-between p-2 w-full'>
                <div className='w-full'>
                  <p className='text-[14px] font-[600] text-left'>
                    Parent Name
                  </p>
                </div>
                <div className='w-full flex items-start'>
                  <div className='flex justify-start  gap-2 w-[95%]'>
                    <div
                      onClick={
                        item?.parentId?.image
                          ? () =>
                              handleCloseFunctionImageModel(
                                item?.parentId?.image
                              )
                          : null
                      }
                    >
                      {item?.parentId && item?.parentId?.image ? (
                        <img
                          alt=''
                          src={
                            item?.parentId && item?.parentId?.image
                              ? item?.parentId?.image
                              : ''
                          }
                          className='rounded  max-w-[50px] max-h-[50px] min-w-[50px] min-h-[50px]'
                        />
                      ) : (
                        <div className='bg-gray-300 rounded h-14 w-14 flex justify-center items-center'>
                          <span className='text-base font-semibold text-[#033565]'>
                            {' '}
                            {item?.parentId?.name
                              ? item.parentId.name
                                  .split(' ')
                                  .map(name => name.charAt(0))
                                  .join('')
                              : ''}
                          </span>
                        </div>
                      )}
                    </div>
                    <div className=''>
                      <p className='text-[12px] font-semibold whitespace-nowrap text-[#023565]'>
                        {/* {item?.parentId?.name ?? 'Not Available'} */}
                        {truncateText(item?.parentId?.name, 10) ?? 'N/A'}
                      </p>
                      <p className='text-[12px] font-medium text-gray-500 whitespace-nowrap'>
                        {item?.parentId?.mobNo ?? 'Not Available'}
                      </p>
                      <div className='flex items-center  gap-3 cursor-pointer'>
                        <div
                          className='flex items-center  gap-1'
                          onClick={() => handleFullDetails(item)}
                        >
                          <p className='text-[12px] font-medium cursor-pointer'>
                            More
                          </p>
                          <FaChevronCircleRight className='text-[#023565] text-base cursor-pointer' />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='w-[5%] relative'>
                    <BsThreeDotsVertical
                      onClick={() => openAction(item._id)}
                      className='text-2xl text-[#C60E6B]'
                    />
                    {open === item._id && (
                      <div
                        className='bg-white fixed right-10 border rounded-md text-[#023565] py-2 px-2 text-start text-sm font-semibold'
                        ref={menuRef}
                      >
                        <div
                          className='flex justify-between place-items-center gap-2 hover:text-[#E4006F] hover:bg-gray-100 cursor-pointer border-b'
                          onClick={() => handleSendToDemo(item)}
                        >
                          <button className='flex items-center  justify-between py-2 p text-[#C60E6B]'>
                            Send to Demo Request
                          </button>
                          <FaAngleRight className='hover:text-[#C60E6B]' />
                        </div>
                        <div
                          className='flex justify-between place-items-center hover:text-[#E4006F] hover:bg-gray-100 cursor-pointer'
                          onClick={() => handleHistory(item)}
                        >
                          <button className='flex items-center  justify-between py-2  text-black'>
                            View History
                          </button>
                          <FaAngleRight className='hover:text-[#C60E6B]' />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className='flex justify-between p-2 w-full'>
                <div className='w-full'>
                  <p className='text-[14px] font-[600] text-left'>
                    Student Name
                  </p>
                </div>
                <div className='w-full'>
                  <p
                    className='text-[14px] font-[700] text-[#023565] hover:text-[#E4006F] '
                    onClick={() => viewStudent(item?.parentId, item._id)}
                  >
                    View
                  </p>
                </div>
              </div>
              <div className='flex justify-between p-2 w-full'>
                <div className='w-full'>
                  <p className='text-[14px] font-[600] text-left'>Address</p>
                </div>
                <div className='w-full'>
                  <p
                    className='text-[14px] font-[400]'
                    title={item?.address || 'N/A'}
                  >
                    {/* {truncateText(item?.address, 20) ?? 'N/A'}  */}
                    {item?.address || 'N/A'}
                  </p>
                </div>
              </div>
              <div className='flex justify-between p-2 w-full'>
                <div className='w-full'>
                  <p className='text-[14px] font-[600] text-left'>City</p>
                </div>
                <div className='w-full'>
                  <p className='text-[14px] font-[400]'>
                    {' '}
                    {item.cityId?.name ?? 'N/A'}
                  </p>
                </div>
              </div>
              <div className='flex justify-between p-2 w-full'>
                <div className='w-full'>
                  <p className='text-[14px] font-[600] text-left'>
                    Demo Date / Time
                  </p>
                </div>
                <div className='w-full'>
                  <p className='text-[14px] font-[400]'>
                    {item?.demoDate} /{' '}
                    {item?.demoTime
                      ? timeStanderFollowFunction(item?.demoTime)
                      : null}
                  </p>
                </div>
              </div>
              <div className='flex justify-between p-2 w-full'>
                <div className='w-full'>
                  <p className='text-[14px] font-[600] text-left'>
                    {' '}
                    Lead Source
                  </p>
                </div>
                <div className='w-full'>
                  <p className='text-[14px] font-[400]'>
                    {item.leadSource === 'CREATED_SELF' ? 'App' : 'Admin'} /{' '}
                    {item?.assignToEmployee
                      ? item?.assignToEmployee?.userName
                        ? item?.assignToEmployee?.userName
                        : 'N/A'
                      : 'N/A'}
                  </p>
                </div>
              </div>
              <div className='flex justify-between p-2 w-full'>
                <div className='w-full'>
                  <p className='text-[14px] font-[600] text-left'> Mode</p>
                </div>
                <div className='w-full'>
                  <p className='text-[14px] font-[400]'>
                    {' '}
                    {item?.preferredTeachMode === 'ONLINE_TUITION'
                      ? 'Online'
                      : 'In-Home'}
                  </p>
                </div>
              </div>
              <div className='flex justify-between p-2 w-full'>
                <div className='w-full'>
                  <p className='text-[14px] font-[600] text-left'>
                    Rejected Reason
                  </p>
                </div>
                <div className='w-full'>
                  <p className='text-[14px] font-[400]'>
                    {item?.rejectReason ? item.rejectReason : '---'}
                  </p>
                </div>
              </div>
            </div>
          ))
        ) : (
           <div  className=' bg-white p-4 rounded-[10px]'>
            <p className='text-center'>Data Not Found</p>
          </div>
        )}
      </section>

      {totalPages > 1 && (
        <div className='flex justify-end items-center py-2 '>
          <button
            className={`px-4 py-2 border text-white bg-[#023565] rounded ${
              currentPage === 1 ? 'opacity-50 cursor-not-allowed' : ''
            }`}
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
          >
            <FaChevronLeft />
          </button>
          <span className='text-gray-700 mx-1'>
            <span className='border px-4 py-2 text-black  rounded'>
              {currentPage}
            </span>{' '}
            of{' '}
            <span className='border px-4 py-2 text-black  rounded'>
              {totalPages}
            </span>
          </span>
          <button
            className={`px-4 py-2 border text-white bg-[#023565] rounded ${
              currentPage === totalPages ? 'opacity-50 cursor-not-allowed' : ''
            }`}
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            <FaChevronRight />
          </button>
        </div>
      )}
      <div
        id='alert-box'
        style={{
          display: 'none',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'yellow',
          padding: '10px',
          borderRadius: '10px'
        }}
      >
        Tuition Send to Demo Request!
      </div>

      <StudentDetail
        setViewStudentModal={setViewStudentModal}
        viewStudentModal={viewStudentModal}
        setStudentListData={setStudentListData}
        studentListdata={studentListdata}
      />

      <ImagePreviewModal
        handleClose={handleCloseFunctionImageModel}
        showWarning={isModalVisible}
        image={image}
      />

      <ViewParent
        fullDetails={fullDetails}
        setFullDetails={setFullDetails}
        DetailsData={DetailsData}
        tuitionDetails={tuitionDetails}
      />

      <UpdateNoteModel
        remarkModal={remarkModal}
        remarkClose={remarkClose}
        Heading={'Update Note'}
        remark={remark}
        setRemark={setRemark}
        handleRemarkSubmit={handleRemarkSubmit}
        btnName={`${remark !== 'N/A' || remark ? 'Update' : 'Add'} Note`}
      />

      {isViewMoreModel && (
        <ViewMoreTuitionModel
          isViewMoreModel={isViewMoreModel}
          setIsViewMoreModel={setIsViewMoreModel}
          tuitionId={tuitionId}
        />
      )}
    </div>
  )
}

export default ButtonEightTabel
