/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { tutortoliveAction } from '../../_actions/tutortolive.action'
import { MdDelete } from 'react-icons/md'

import {
  FaChevronCircleRight,
  FaChevronLeft,
  FaRegCheckCircle
} from 'react-icons/fa'
import { MdOutlineClose } from 'react-icons/md'
import { FaChevronRight } from 'react-icons/fa'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import './Manage.css'
import { confirmAlert } from 'react-confirm-alert'
import { tutionAction, userListAction } from '../../_actions'
import moment from 'moment'
import Loader from '../../components/Loader'
import KycViewModel from '../Tutor-Profile/Model/KycViewModel'
import DetailsPreViewModel from '../Tutor-Profile/Model/DetailsPreViewModel'
import ImagePreviewModal from '../Tutor-Profile/Model/ImagePreview'
import AcceptInterView from './ModelTutorToLive/AcceptInterView'
import { tutorUpdateInitialState } from '../../_helpers/dataInitialState'
import { classAction } from '../../_actions/class.action'

const TableThird = ({ newSetState, searchText, headerCity }) => {
  const dispatch = useDispatch()
  const selector = useSelector(state => state)
  const [skipedTutorDataList, setskipedTutorDataList] = useState()
  const [fullData, setFullData] = useState([])
  const [acceptModal, setAcceptModal] = useState(false)
  const [rejectModal, setrejectModal] = useState(false)
  const [rejectResion, setRejectResion] = useState('')
  const [oldRejectData, setOldRejectData] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const pageSize = 10
  const [kycDataShow, setKycDataShow] = useState(false)
  const [kycListData, setKycListData] = useState({})
  const [newAcceptData, setNewAcceptData] = useState(tutorUpdateInitialState)
  const [selectedTeachMode, setSelectedTeachMode] = useState([])
  const [selectedPinCode, setSelectedPinCode] = useState([])
  const [subjectYouCanTeach, setSubjectYouCanTeach] = useState([])
  const [selectedClass, setSelectedClass] = useState([])

  useEffect(() => {
    if (selector?.TutorToLive?.SkipToutor) {
      setTotalPages(Math.ceil(selector?.TutorToLive?.SkipToutor / pageSize))
    }
    setskipedTutorDataList(
      () => selector?.TutorToLive?.getNewJoinTutorList?.data?.demoList
    )
  }, [currentPage, selector])

  useEffect(() => {
    let rejectInInterviewDetails = {
      status: 'SKIP_TUTOR',
      keyWord: searchText,
      cityId: headerCity !== '' ? [headerCity] : [],
      fromDate: '',
      toDate: '',
      sortOrder: '',
      sortBy: 'asc',
      pageNo: currentPage,
      size: pageSize,
      isManagement: true
    }
    const ClassList = {
      keyWord: '',
      fromDate: '',
      toDate: '',
      sortOrder: 'asc',
      sortBy: 'createdAt',
      pageNo: 1,
      size: 100
    }
    dispatch(tutionAction.getAllCityList())
    dispatch(tutionAction.gitAllClassListByAdmin())
    dispatch(classAction.classGet(ClassList))
    dispatch(tutortoliveAction.getNewlyJoinTutorList3(rejectInInterviewDetails))
    newSetState('SKIP_TUTOR')
  }, [currentPage, searchText, headerCity])

  const showAccept = async user => {
    tutortoliveAction
      .getTutorByPromiseId({
        id: user._id
      })
      .then(async data => {
        let user = data.data
        setNewAcceptData({
          _id: user._id,
          name: user?.name,
          email: user?.email,
          whatsappNo: user?.whatsappNo,
          address: user?.address,
          city: user?.city,
          pinCode: user?.pinCode,
          dob: user?.tutorInfoId?.dob,
          gender: user?.tutorInfoId?.gender,
          schoolName: user?.tutorInfoId?.schoolName,
          schoolPassOutYear: user?.tutorInfoId?.schoolPassOutYear,
          ugCourse: user?.tutorInfoId?.ugCourse,
          ugCollegeName: user?.tutorInfoId?.ugCollegeName,
          ugPassOutYear: user?.tutorInfoId?.ugPassOutYear,
          pgCourse: user?.tutorInfoId?.pgCourse,
          pgCollegeName: user?.tutorInfoId?.pgCollegeName,
          pgPassOutYear: user?.tutorInfoId?.pgPassOutYear,
          teachingExp: user?.tutorInfoId?.teachingExp
        })
        setSelectedTeachMode(
          user?.tutorInfoId?.teachingMode.map(ele => ({
            name: teachModeViewData(ele),
            _id: ele
          }))
        )
        setSelectedPinCode(
          user?.tutorInfoId?.pinCodeYouCanGo?.map(ele => ({
            name: ele,
            _id: ele
          })) || []
        )
        setSubjectYouCanTeach(user?.tutorInfoId?.subYouCanTeach)
        setSelectedClass(user?.tutorInfoId?.classYouCanTeach)
        setAcceptModal(prev => !prev)
      })
  }
  const closeModal = () => {
    setAcceptModal(false)
  }

  const handleReject = data => {
    setrejectModal(true)
    let requiredData = {
      id: data._id,
      interviewStatus: 2
    }
    setOldRejectData(requiredData)
  }
  const handleNextPage = () => {
    setCurrentPage(prevPage => prevPage + 1)
  }

  const handlePreviousPage = () => {
    setCurrentPage(prevPage => Math.max(prevPage - 1, 1))
  }

  const handleRemoveProfile = data => {
    let rejectData = {
      status: 'SKIP_TUTOR',
      keyWord: searchText,
      cityId: headerCity !== '' ? [headerCity] : [],
      fromDate: '',
      toDate: '',
      sortOrder: '',
      sortBy: 'asc',
      pageNo: currentPage,
      size: pageSize,
      isManagement: true
    }
    let removeData = {
      id: data._id
    }

    confirmAlert({
      title: 'Confirm to Remove?',
      message: `Are you sure to Remove ?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () =>
            dispatch(tutortoliveAction.removeProfile(removeData, rejectData))
        },
        {
          label: 'No'
        }
      ]
    })

    newSetState('SKIP_TUTOR')
  }

  const handleRejectResion = () => {
    let rejectData = {
      status: 'SKIP_TUTOR',
      keyWord: searchText,
      cityId: headerCity !== '' ? [headerCity] : [],
      fromDate: '',
      toDate: '',
      sortOrder: '',
      sortBy: 'createdAt',
      pageNo: currentPage,
      size: pageSize,
      isManagement: true
    }

    let newRejctData = {
      reason: rejectResion,
      ...oldRejectData
    }

    dispatch(
      tutortoliveAction.interviewStatusUpdateByAdmin(newRejctData, rejectData)
    )
    dispatch(tutortoliveAction.getNewlyJoinTutorCount({ isManagement: true }))

    setrejectModal(false)
  }
  const handleCancel = () => {
    setrejectModal(false)
  }

  const kycDetailModal = data => {
    let kycData = {
      id: data?._id
    }
    setKycDataShow(true)
    dispatch(userListAction.getKycByUserId(kycData))
  }

  useEffect(() => {
    setKycListData(selector?.userList?.userKycListByUserId?.data)
  }, [selector])

  function teachModeViewData (data) {
    switch (data) {
      case 'HOME_TUITION':
        return 'Home Tuition'
      case 'ONLINE_TUITION':
        return 'Online Tuition'
      default:
        return null
    }
  }

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [image, setImage] = useState('')
  const handleCloseFunctionImageModel = data => {
    if (data) {
      setImage(() => data)
    } else {
      setImage('')
    }
    setIsModalVisible(!isModalVisible)
  }

  const [isModalDetails, setIsModalDetails] = useState(false)
  const handelDetailsModel = data => {
    if (data) {
      let tutorId = {
        id: data._id
      }
      tutortoliveAction.getTutorByPromiseId(tutorId).then(response => {
        if (response.data) {
          setFullData(response.data)
          setIsModalDetails(!isModalDetails)
        }
      })
    } else {
      setFullData({})
      setIsModalDetails(!isModalDetails)
    }
  }

  let { TutorToLive } = selector ? selector : {}
  let { SkipToutorList } = TutorToLive ? TutorToLive : {}

  return (
    <>
      <Loader loading={selector?.TutorToLive?.loading} />
      <section className='py-6'>
        <div className='border border-[#D3D3D3] rounded-xl overflow-x-auto lg:block hidden'>
          <table className='min-w-full bg-white rounded-xl'>
            <thead className='border-b border-[#D1D1D1]'>
              <tr className='border-b'>
                <th className='px-6 py-3 whitespace-nowrap text-left'>
                  Tutor Name
                </th>
                <th className='px-6 py-3 whitespace-nowrap text-left'>
                  Address
                </th>
                <th className='px-6 py-3 whitespace-nowrap text-left'>City</th>
                <th className='px-6 py-3 whitespace-nowrap text-left'>KYC</th>
                <th className='px-6 py-3 whitespace-nowrap text-left'>
                  Skip Reason
                </th>
                <th className='px-6 py-3 whitespace-nowrap text-left'>
                  Interview
                </th>
                <th className='px-6 py-3 whitespace-nowrap text-left'>
                  Delete
                </th>
              </tr>
            </thead>
            <tbody className='bg-white divide-y divide-gray-200 relative'>
              {SkipToutorList && SkipToutorList.length > 0 ? (
                SkipToutorList?.map((item, index) => (
                  <tr key={index} className='capitalize'>
                    <td className='flex items-center gap-4 px-6 py-4 text-sm text-gray-900 '>
                      <div className='flex items-center justify-center gap-2'>
                        <span
                          onClick={
                            item?.image
                              ? () => handleCloseFunctionImageModel(item?.image)
                              : null
                          }
                        >
                          {item && item?.image ? (
                            <img
                              alt=''
                              src={item && item?.image ? item?.image : ''}
                              className='rounded-full my-3 max-w-[50px] max-h-[50px] min-w-[50px] min-h-[50px]'
                            />
                          ) : (
                            <div className='bg-gray-300 rounded-full h-14 w-14 flex justify-center items-center'>
                              <span className='text-base font-semibold text-[#033565]'>
                                {' '}
                                {item?.name
                                  ? item.name
                                      .split(' ')
                                      .map(name => name.charAt(0))
                                      .join('')
                                  : ''}
                              </span>
                            </div>
                          )}
                        </span>
                        <div className='w-24'>
                          <p className='font-semibold whitespace-nowrap text-[#023565]'>
                            {item?.name ?? 'Not Available'}
                          </p>
                          <p className='text-gray-500 whitespace-nowrap'>
                            {item?.mobNo ?? 'Not Available'}
                          </p>
                          <div
                            className='flex items-center gap-1 cursor-pointer'
                            onClick={() => handelDetailsModel(item)}
                          >
                            <p className='text-base font-normal cursor-pointer'>
                              More
                            </p>
                            <FaChevronCircleRight className='text-[#023565] text-base cursor-pointer' />
                          </div>
                        </div>
                      </div>
                    </td>

                    <td className='px-6 py-4 text-sm  text-gray-900 capitalize'>
                      {item?.address ?? 'No Data'}
                    </td>
                    <td className='px-6 py-4 text-sm  text-gray-900 capitalize'>
                      {item?.city?.name ?? 'No Data'}
                    </td>

                    <td
                      className='px-6 py-4 text-sm  text-gray-900 capitalize font-bold underline'
                      onClick={() => kycDetailModal(item)}
                    >
                      View
                    </td>
                    <td className='px-6 py-4 text-sm  text-gray-900 capitalize'>
                      {item?.skipReason ?? 'No Data'}
                    </td>
                    <td className='px-6 py-4 space-y-1 text-sm  text-gray-900 capitalize'>
                      <button
                        className='border px-3 py-1 inline-flex items-center gap-2 font-[600] text-[#2C9408] border-[#2C9408] rounded-full'
                        onClick={() => showAccept(item)}
                        type=''
                      >
                        <span>
                          <FaRegCheckCircle size={20} />
                        </span>{' '}
                        Accept
                      </button>{' '}
                      <br />
                      <button
                        className='border px-4 py-1 inline-flex items-center gap-2 font-[600] text-[#E70909] border-[#E70909] rounded-full'
                        type=''
                        onClick={() => handleReject(item)}
                      >
                        <span>
                          <AiOutlineCloseCircle size={20} />
                        </span>{' '}
                        Reject
                      </button>
                    </td>
                    <td className='px-6 py-4 text-sm  text-gray-900 capitalize'>
                      <button
                        className='border px-4 py-1 inline-flex items-center gap-2 font-[600] text-[#E70909] border-[#E70909] rounded-full'
                        type=''
                        onClick={() => handleRemoveProfile(item)}
                      >
                        <span>
                          <MdDelete size={20} />
                        </span>{' '}
                        Delete
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={6}
                    className='px-6 py-4 font-[400] text-base text-[#313131] whitespace-nowrap text-center'
                  >
                    Data Not Available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {rejectModal && (
          <>
            <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40'>
              <div className='relative w-11/12 mx-auto lg:w-[600px]'>
                <button
                  className='z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center'
                  onClick={handleCancel}
                >
                  <span>
                    <MdOutlineClose />
                  </span>{' '}
                  Close
                </button>
                <div className='form_class border-0 rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
                  <div className='flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t'>
                    <h2 className='text-lg font-semibold'>Reason</h2>
                  </div>
                  <div className='relative  overflow-y-auto'>
                    <form onSubmit={handleRejectResion} className='p-4'>
                      <div className='mb-4'>
                        <label
                          htmlFor='skipResion'
                          className='block text-gray-700 font-bold mb-2'
                        >
                          Reason for Reject
                        </label>
                        <input
                          type='text'
                          id='skipResion'
                          value={rejectResion}
                          onChange={e => setRejectResion(e.target.value)}
                          className='w-full p-2 border border-gray-300 rounded-md'
                          placeholder='Enter reason'
                        />
                      </div>
                      <div className='flex justify-center space-x-2'>
                        <button
                          type='submit'
                          className='w-60 mx-auto bg-[#023565] text-white font-semibold py-2 px-4 rounded-full '
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        <ImagePreviewModal
          handleClose={handleCloseFunctionImageModel}
          showWarning={isModalVisible}
          image={image}
        />
        <DetailsPreViewModel
          isModalOpen={isModalDetails}
          closeModal={handelDetailsModel}
          response={fullData}
          isKyc={true}
        />
        <KycViewModel
          kycDataShow={kycDataShow}
          setKycDataShow={setKycDataShow}
          kycListData={kycListData}
        />

        <AcceptInterView
          closeModal={closeModal}
          newAcceptData={newAcceptData}
          acceptModal={acceptModal}
          setNewAcceptData={setNewAcceptData}
          currentPage={currentPage}
          pageSize={pageSize}
          setOldRejectData={setOldRejectData}
          selectedTeachMode={selectedTeachMode}
          setSelectedTeachMode={setSelectedTeachMode}
          selectedPinCode={selectedPinCode}
          setSelectedPinCode={setSelectedPinCode}
          subjectYouCanTeach={subjectYouCanTeach}
          setSubjectYouCanTeach={setSubjectYouCanTeach}
          selectedClass={selectedClass}
          setSelectedClass={setSelectedClass}
        />

        <section className='space-y-5 lg:hidden block'>
          {SkipToutorList && SkipToutorList.length > 0 ? (
            SkipToutorList?.map((item, index) => (
              <div
                key={index}
                className=' bg-white p-4 rounded-[10px] border border-[#033565]'
              >
                <>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[16px] font-[700] text-left'>
                        Tutor Name{' '}
                      </p>
                    </div>
                    <div className='w-full'>
                      <div className='flex items-center  gap-2 capitalize'>
                        <span
                          onClick={
                            item?.image
                              ? () => handleCloseFunctionImageModel(item?.image)
                              : null
                          }
                        >
                          {item && item?.image ? (
                            <img
                              alt=''
                              src={item && item?.image ? item?.image : ''}
                              className='rounded  max-w-[50px] max-h-[50px] min-w-[50px] min-h-[50px]'
                            />
                          ) : (
                            <div className='bg-gray-300 rounded-full h-14 w-14 flex justify-center items-center'>
                              <span className='text-base font-semibold text-[#033565]'>
                                {' '}
                                {item?.name
                                  ? item.name
                                      .split(' ')
                                      .map(name => name.charAt(0))
                                      .join('')
                                  : ''}
                              </span>
                            </div>
                          )}
                        </span>
                        <div>
                          <p className='text-xs font-medium text-black '>
                            {item?.name ?? 'No Data'}
                          </p>
                          <p className='text-xs text-gray-500'>
                            {item?.mobNo || 'No Number'}
                          </p>
                          <p className='text-xs text-gray-500'>
                            {moment(item?.createdAt).format('DD-MMM-YYYY')}
                          </p>
                          <FaChevronCircleRight
                            className='text-[#023565] cursor-pointer'
                            onClick={() => handelDetailsModel(item)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[16px] font-[700] text-left'>
                        Address
                      </p>
                    </div>
                    <div className='w-full'>
                      <p className='text-[14px] font-[400]'>
                        {' '}
                        {item?.address ?? 'No Data'}
                      </p>
                    </div>
                  </div>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[16px] font-[700] text-left'>City</p>
                    </div>
                    <div className='w-full'>
                      <p className='text-[14px] font-[400]'>
                        {' '}
                        {item?.city?.name ?? 'No Data'}
                      </p>
                    </div>
                  </div>
                  {/* <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[16px] font-[700] text-left'>
                        Class Selected{' '}
                      </p>
                    </div>
                    <div className='w-full'>
                      <p className='text-[14px] font-[400]'>
                        {Array.isArray(item?.tutorInfoId?.classYouCanTeach) &&
                        item.tutorInfoId.classYouCanTeach.length > 0 ? (
                          <span>
                            {item.tutorInfoId.classYouCanTeach
                              .map((cls, id) => cls?.name)
                              .join(', ')}
                          </span>
                        ) : (
                          <span>N/A</span>
                        )}
                      </p>
                    </div>
                  </div>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[16px] font-[700] text-left'>
                        Subject Selected{' '}
                      </p>
                    </div>
                    <div className='w-full'>
                      <p className='text-[14px] font-[400]'>
                        {' '}
                        {Array.isArray(item?.tutorInfoId?.subYouCanTeach) &&
                        item.tutorInfoId.subYouCanTeach.length > 0 ? (
                          <span>
                            {item.tutorInfoId.subYouCanTeach
                              .map(sub => sub?.name || 'N/A')
                              .join(', ')}
                          </span>
                        ) : (
                          <span>N/A</span>
                        )}
                      </p>
                    </div>
                  </div> */}
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[16px] font-[700] text-left'>KYC</p>
                    </div>
                    <div className='w-full'>
                      <p
                        className='text-[14px] font-[400]'
                        onClick={() => kycDetailModal(item)}
                      >
                        View
                      </p>
                    </div>
                  </div>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[16px] font-[700] text-left'>
                        Skip Reason{' '}
                      </p>
                    </div>
                    <div className='w-full'>
                      <p className='text-[14px] font-[400]'>
                        {item?.skipReason || 'N/A'}
                      </p>
                    </div>
                  </div>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[16px] font-[700] text-left'>
                        Interview
                      </p>
                    </div>
                    <div className='w-full'>
                      <p className='text-[14px] font-[400] relative space-y-1'>
                        <button
                          className='border px-3 py-1 inline-flex items-center gap-2 font-[600] text-[#2C9408] border-[#2C9408] rounded-full'
                          onClick={() => showAccept(item)}
                          type=''
                        >
                          <span>
                            <FaRegCheckCircle size={20} />
                          </span>{' '}
                          Accept
                        </button>{' '}
                        <button
                          className='border px-4 py-1 inline-flex items-center gap-2 font-[600] text-[#E70909] border-[#E70909] rounded-full'
                          type=''
                          onClick={() => handleReject(item)}
                        >
                          <span>
                            <AiOutlineCloseCircle size={20} />
                          </span>{' '}
                          Reject
                        </button>{' '}
                      </p>
                    </div>
                  </div>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[16px] font-[700] text-left'>Action</p>
                    </div>
                    <div className='w-full'>
                      {/* <p className='text-[14px] font-[400] relative' onClick={() => handleRemoveProfile(item)}>Remove Profile</p>
                    <p className='text-[14px] font-[400] relative' >{item?.remark ?? ""}</p> */}
                      <button
                        className='border px-4 py-1 inline-flex items-center gap-2 font-[600] text-[#E70909] border-[#E70909] rounded-full'
                        type=''
                        onClick={() => handleRemoveProfile(item)}
                      >
                        <span>
                          <MdDelete size={20} />
                        </span>{' '}
                        Delete
                      </button>
                    </div>
                  </div>
                </>
              </div>
            ))
          ) : (
            <p className='px-6 py-4 font-[400] bg-white rounded-md text-base text-[#313131] whitespace-nowrap text-center'>
              Data Not Available
            </p>
          )}
        </section>
        {totalPages > 1 && (
          <div className='flex justify-end items-center py-2 px-4'>
            <button
              className={`px-4 py-2 border text-white bg-[#023565] rounded ${
                currentPage === 1 ? 'opacity-50 cursor-not-allowed' : ''
              }`}
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
            >
              <FaChevronLeft />
            </button>
            <span className='text-gray-700 mx-1'>
              <span className='border px-4 py-2 text-black  rounded'>
                {currentPage}
              </span>{' '}
              of{' '}
              <span className='border px-4 py-2 text-black  rounded'>
                {totalPages}
              </span>
            </span>
            <button
              className={`px-4 py-2 border text-white bg-[#023565] rounded ${
                currentPage === totalPages
                  ? 'opacity-50 cursor-not-allowed'
                  : ''
              }`}
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              <FaChevronRight />
            </button>
          </div>
        )}
      </section>
    </>
  )
}

export default TableThird
