export const pincodeConstants={
    GET_PINCODE_LIST_REQUEST:"GET_PINCODE_LIST_REQUEST",
    GET_PINCODE_LIST_SUCCESS:"GET_PINCODE_LIST_SUCCESS",
    GET_PINCODE_LIST_FAILURE:"GET_PINCODE_LIST_FAILURE",
    
    CREATE_PINCODE_LIST_REQUEST:"CREATE_PINCODE_LIST_REQUEST",
    CREATE_PINCODE_LIST_SUCCESS:"CREATE_PINCODE_LIST_SUCCESS",
    CREATE_PINCODE_LIST_FAILURE:"CREATE_PINCODE_LIST_FAILURE", 

    DELETE_PINCODE_LIST_REQUEST:"DELETE_PINCODE_LIST_REQUEST",
    DELETE_PINCODE_LIST_SUCCESS:"DELETE_PINCODE_LIST_SUCCESS",
    DELETE_PINCODE_LIST_FAILURE:"DELETE_PINCODE_LIST_FAILURE", 

    UPDATE_PINCODE_LIST_REQUEST:"UPDATE_PINCODE_LIST_REQUEST",
    UPDATE_PINCODE_LIST_SUCCESS:"UPDATE_PINCODE_LIST_SUCCESS",
    UPDATE_PINCODE_LIST_FAILURE:"UPDATE_PINCODE_LIST_FAILURE",

    ENA_DIS_PINCODE_LIST_REQUEST:"ENA_DIS_PINCODE_LIST_REQUEST",
    ENA_DIS_PINCODE_LIST_SUCCESS:"ENA_DIS_PINCODE_LIST_SUCCESS",
    ENA_DIS_PINCODE_LIST_FAILURE:"ENA_DIS_PINCODE_LIST_FAILURE",
}