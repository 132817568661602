import { attandanceConstant } from "../_constants";
export default function attendance(state = {}, action) {
    switch (action.type) {
        case attandanceConstant.GET_ATTENDANCE_LIST_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case attandanceConstant.GET_ATTENDANCE_LIST_SUCCESS:
            return {
                ...state,
                attendanceList: action?.user,
                loading: false,
            };
        case attandanceConstant.GET_ATTENDANCE_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };


        case attandanceConstant.GET_ADMIN_VIEWATTENDANCE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case attandanceConstant.GET_ADMIN_VIEWATTENDANCE_SUCCESS:
            return {
                ...state,
                getAdminViewAttendanceList: action?.user,
                loading: false,
            };
        case attandanceConstant.GET_ADMIN_VIEWATTENDANCE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case attandanceConstant.UPDATE_ATTENDANCE_BYADMIN_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case attandanceConstant.UPDATE_ATTENDANCE_BYADMIN_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case attandanceConstant.UPDATE_ATTENDANCE_BYADMIN_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };



        case attandanceConstant.GET_ABSENT_TODAY_BY_ADMIN_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case attandanceConstant.GET_ABSENT_TODAY_BY_ADMIN_SUCCESS:
            return {
                ...state,
                loading: false,
                getAbsentTodayList: action?.user
            };
        case attandanceConstant.GET_ABSENT_TODAY_BY_ADMIN_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };


            


        default:
            return state;

    }
}