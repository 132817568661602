export const holidaysConstants={
    GET_HOLIDAY_LIST_REQUEST:"GET_HOLIDAY_LIST_REQUEST",
    GET_HOLIDAY_LIST_SUCCESS:"GET_HOLIDAY_LIST_SUCCESS",
    GET_HOLIDAY_LIST_FAILURE:"GET_HOLIDAY_LIST_FAILURE",

    CREATE_HOLIDAY_LIST_REQUEST:"CREATE_HOLIDAY_LIST_REQUEST",
    CREATE_HOLIDAY_LIST_SUCCESS:"CREATE_HOLIDAY_LIST_SUCCESS",
    CREATE_HOLIDAY_LIST_FAILURE:"CREATE_HOLIDAY_LIST_FAILURE",

    UPDATE_HOLIDAY_LIST_REQUEST:"UPDATE_HOLIDAY_LIST_REQUEST",
    UPDATE_HOLIDAY_LIST_SUCCESS:"UPDATE_HOLIDAY_LIST_SUCCESS",
    UPDATE_HOLIDAY_LIST_FAILURE:"UPDATE_HOLIDAY_LIST_FAILURE",

    DELETE_HOLIDAY_LIST_REQUEST:"DELETE_HOLIDAY_LIST_REQUEST",
    DELETE_HOLIDAY_LIST_SUCCESS:"DELETE_HOLIDAY_LIST_SUCCESS",
    DELETE_HOLIDAY_LIST_FAILURE:"DELETE_HOLIDAY_LIST_FAILURE",

    ENA_DIS_HOLIDAY_LIST_REQUEST:"ENA_DIS_HOLIDAY_LIST_REQUEST",
    ENA_DIS_HOLIDAY_LIST_SUCCESS:"ENA_DIS_HOLIDAY_LIST_SUCCESS",
    ENA_DIS_HOLIDAY_LIST_FAILURE:"ENA_DIS_HOLIDAY_LIST_FAILURE",
}