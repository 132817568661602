import moment from 'moment'
import React from 'react'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'
import { GoUnread, GoRead } from "react-icons/go";



function TableBanner({ getAdminNotification, totalPages, currentPages, pageSize, handlePreviousPage, handleNextPage, handleRead, highlightedRow }) {

  return (
    <section className="md:block hidden">
      {getAdminNotification && getAdminNotification.length > 0 && getAdminNotification.map((item, index) => (
        <div class={`w-full p-3 mt-4 rounded flex cursor-pointer ${item.isRed ? 'bg-green-100' : "bg-red-100 text-black"}`} onClick={() => handleRead(item)}>
          <div tabindex="0" aria-label="post icon" role="img" className={`focus:outline-none w-8 h-8 border rounded-full flex items-center justify-center  ${item.isRed ? 'border-green-700 text-green-700' : "border-red-600 text-red-600"}`}>
            {item.isRed ?
              <GoRead />
              :
              <GoUnread />
            }
          </div>
          <div class="pl-3">
            <p tabindex="0" class="focus:outline-none text-base leading-none">{item.msg}</p>
            <p tabindex="0" class="focus:outline-none text-sm leading-3 pt-1.5 text-gray-500 capitalize font-semibold">
              <span className='text-pink-400'>{item.createdAt ? moment(item.createdAt).format("DD-MMM-YYYY") : null}</span>
              {" "}
              <span className='text-blue-950'>{item.cityId?.name}</span></p>
          </div>
        </div>
      ))}

      {totalPages > 1 && (
        <div className="flex justify-end items-center py-2 px-4">
          <button
            className={`px-4 py-2 border text-white bg-[#023565] rounded ${currentPages === 1 ? "opacity-50 cursor-not-allowed" : ""
              }`}
            onClick={handlePreviousPage}
            disabled={currentPages === 1}
          >
            <FaChevronLeft />
          </button>
          <span className="text-gray-700 mx-1">
            <span className="border px-4 py-2 text-black  rounded">
              {currentPages}
            </span>{" "}
            of{" "}
            <span className="border px-4 py-2 text-black  rounded">
              {totalPages}
            </span>
          </span>
          <button
            className={`px-4 py-2 border text-white bg-[#023565] rounded ${currentPages === totalPages
              ? "opacity-50 cursor-not-allowed"
              : ""
              }`}
            onClick={handleNextPage}
            disabled={currentPages === totalPages}
          >
            <FaChevronRight />
          </button>
        </div>
      )}
    </section>
  )
}

export default TableBanner
