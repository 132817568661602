import { roleConstants } from "../_constants";
import { APIcallFunction, headerForPrivateAPI } from "../_helpers"
import { alertActions } from "./alert.actions";


export const roleAction = {
    roleGet,
    roleCreate,
    roleDelete,
    roleUpdate

}

function roleGet(data) {

    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: "roles/getRolesList"
    };
    return (dispatch) => {
        dispatch(request(data));
        APIcallFunction(credentials).then(
            (users) => {
                dispatch(success(users))
            },
            (error) => {
                dispatch(failure(error))
            }
        )

    }


    function request() { return { type: roleConstants.GET_ROLE_LIST_REQUEST } }
    function success(users) { return { type: roleConstants.GET_ROLE_LIST_SUCCESS, users } }
    function failure(error) { return { type: roleConstants.GET_ROLE_LIST_FAILURE, error } }


};

function roleCreate(data, roleData) {

    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: "roles/create"
    };
    return (dispatch) => {
        dispatch(request(data));
        APIcallFunction(credentials).then(
            (users) => {


                dispatch(success(users))
                dispatch(roleGet(roleData))
                dispatch(alertActions.success("Success"))
            },
            (error) => {

                dispatch(failure(error))
                dispatch(alertActions.error(error))
            }
        )

    }


    function request() { return { type: roleConstants.CREATE_ROLE_LIST_REQUEST } }
    function success(users) { return { type: roleConstants.CREATE_ROLE_LIST_SUCCESS, users } }
    function failure(error) { return { type: roleConstants.CREATE_ROLE_LIST_FAILURE, error } }


};

function roleDelete(data, roleData) {

    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: "roles/delete"
    };
    return (dispatch) => {
        dispatch(request(data));
        APIcallFunction(credentials).then(
            (users) => {

                dispatch(success(users))
                dispatch(roleGet(roleData))
                dispatch(alertActions.success("Success"))
            },
            (error) => {

                dispatch(failure(error))
                dispatch(alertActions.error(error))
            }
        )

    }


    function request() { return { type: roleConstants.DELETE_ROLE_LIST_REQUEST } }
    function success(users) { return { type: roleConstants.DELETE_ROLE_LIST_SUCCESS, users } }
    function failure(error) { return { type: roleConstants.DELETE_ROLE_LIST_FAILURE, error } }


};
function roleUpdate(data, roleData) {

    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: "roles/update "
    };
    return (dispatch) => {
        dispatch(request(data));
        APIcallFunction(credentials).then(
            (users) => {

                dispatch(success(users))
                dispatch(roleGet(roleData))
                dispatch(alertActions.success("Success"))

            },
            (error) => {
                dispatch(failure(error))
                dispatch(alertActions.error(error))
            }
        )

    }


    function request() { return { type: roleConstants.UPDATE_ROLE_LIST_REQUEST } }
    function success(users) { return { type: roleConstants.UPDATE_ROLE_LIST_SUCCESS, users } }
    function failure(error) { return { type: roleConstants.UPDATE_ROLE_LIST_FAILURE, error } }


};



