import React from 'react'
import { MdOutlineClose } from 'react-icons/md'

function FeedBackIssueModel({ feedbackIssueModal, setFeedbackIssueModal, handleIssueResolveSubmit, setName, name }) {
    return feedbackIssueModal && (
        <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40">
                <div className="relative w-11/12 mx-auto lg:w-[600px]">
                    <button
                        className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center"
                        onClick={() => setFeedbackIssueModal(false)}
                    >
                        <span><MdOutlineClose /></span> Close
                    </button>
                    <div className="form_class border-0 rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        <div className="flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                            <h2 className="text-lg font-semibold">Issue Resolved </h2>
                        </div>
                        <form onSubmit={handleIssueResolveSubmit} className="relative lg:h-[320px] overflow-y-auto">
                            <div className="flex flex-col items-center p-4 space-y-4">
                                <p className="text-md font-semibold ">Provide details of the resolution:</p>
                                <textarea
                                    className="w-full p-2 border rounded"
                                    rows="4"
                                    placeholder="Write your remark here..."
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>

                            <div className="flex justify-center items-center py-2">
                                <button
                                    type="submit"
                                    className="w-60 mx-auto bg-[#023565] text-white font-semibold py-2 px-4 rounded-full "

                                >
                                    Submit
                                </button>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </>
    )
}

export default FeedBackIssueModel