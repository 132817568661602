export const feeListConstant = {
    GET_FEE_HISTORY_LIST_REQUEST: "GET_FEE_HISTORY_LIST_REQUEST",
    GET_FEE_HISTORY_LIST_SUCCESS: "GET_FEE_HISTORY_LIST_SUCCESS",
    GET_FEE_HISTORY_LIST_FAILURE: "GET_FEE_HISTORY_LIST_FAILURE",

    MARK_PAID_OR_UNPAID_BYADMIN_REQUEST: "MARK_PAID_OR_UNPAID_BYADMIN_REQUEST",
    MARK_PAID_OR_UNPAID_BYADMIN_SUCCESS: "MARK_PAID_OR_UNPAID_BYADMIN_SUCCESS",
    MARK_PAID_OR_UNPAID_BYADMIN_FAILURE: "MARK_PAID_OR_UNPAID_BYADMIN_FAILURE",

    FEE_REMINDER_REQUEST: "FEE_REMINDER_REQUEST",
    FEE_REMINDER_SUCCESS: "FEE_REMINDER_SUCCESS",
    FEE_REMINDER_FAILURE: "FEE_REMINDER_FAILURE",

    GET_FEE_HISTORY_LIST_BY_ADMIN_REQUEST: "GET_FEE_HISTORY_LIST_BY_ADMIN_REQUEST",
    GET_FEE_HISTORY_LIST_BY_ADMIN_SUCCESS: "GET_FEE_HISTORY_LIST_BY_ADMIN_SUCCESS",
    GET_FEE_HISTORY_LIST_BY_ADMIN_FAILURE: "GET_FEE_HISTORY_LIST_BY_ADMIN_FAILURE",



}