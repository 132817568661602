export const feedbackConstants = {
    GET_FEEDBACK_LIST_REQUEST: "GET_FEEDBACK_LIST_REQUEST",
    GET_FEEDBACK_LIST_SUCCESS: "GET_FEEDBACK_LIST_SUCCESS",
    GET_FEEDBACK_LIST_FAILURE: "GET_FEEDBACK_LIST_FAILURE",

 

     UPDATE_FEEDBACK_STATUS_REQUEST:"UPDATE_FEEDBACK_STATUS_REQUEST",
     UPDATE_FEEDBACK_STATUS_SUCCESS:"UPDATE_FEEDBACK_STATUS_SUCCESS",
     UPDATE_FEEDBACK_STATUS_FAILURE:"UPDATE_FEEDBACK_STATUS_FAILURE",

     UPDATE_FEEDBACK_ISSUE_RESOLVE_REQUEST:"UPDATE_FEEDBACK_ISSUE_RESOLVE_REQUEST",
     UPDATE_FEEDBACK_ISSUE_RESOLVE_SUCCESS:"UPDATE_FEEDBACK_ISSUE_RESOLVE_SUCCESS",
     UPDATE_FEEDBACK_ISSUE_RESOLVE_FAILURE:"UPDATE_FEEDBACK_ISSUE_RESOLVE_FAILURE",
}