export const dashboardConstant = {
  GET_DASHBOARD_COUNT_LIST_REQUEST: "GET_DASHBOARD_COUNT_LIST_REQUEST",
  GET_DASHBOARD_COUNT_LIST_SUCCESS: "GET_DASHBOARD_COUNT_LIST_SUCCESS",
  GET_DASHBOARD_COUNT_LIST_FAILURE: "GET_DASHBOARD_COUNT_LIST_FAILURE",

  GET_DASHBOARD_COUNT_CREDIT_BASE_REQUEST: "GET_DASHBOARD_COUNT_CREDIT_BASE_REQUEST",
  GET_DASHBOARD_COUNT_CREDIT_BASE_SUCCESS: "GET_DASHBOARD_COUNT_CREDIT_BASE_SUCCESS",
  GET_DASHBOARD_COUNT_CREDIT_BASE_FAILURE: "GET_DASHBOARD_COUNT_CREDIT_BASE_FAILURE",

  GET_LATEST_DEMO_DONE_LIST_REQUEST: "GET_LATEST_DEMO_DONE_LIST_REQUEST",
  GET_LATEST_DEMO_DONE_LIST_SUCCESS: "GET_LATEST_DEMO_DONE_LIST_SUCCESS",
  GET_LATEST_DEMO_DONE_LIST_FAILURE: "GET_LATEST_DEMO_DONE_LIST_FAILURE",

  GET_CONTACT_SEEN_LIST_REQUEST: "GET_CONTACT_SEEN_LIST_REQUEST",
  GET_CONTACT_SEEN_LIST_SUCCESS: "GET_CONTACT_SEEN_LIST_SUCCESS",
  GET_CONTACT_SEEN_LIST_FAILURE: "GET_CONTACT_SEEN_LIST_FAILURE",

  GET_LATEST_DEMO_REQUEST_LIST_REQUEST: "GET_LATEST_DEMO_REQUEST_LIST_REQUEST",
  GET_LATEST_DEMO_REQUEST_LIST_SUCCESS: "GET_LATEST_DEMO_REQUEST_LIST_SUCCESS",
  GET_LATEST_DEMO_REQUEST_LIST_FAILURE: "GET_LATEST_DEMO_REQUEST_LIST_FAILURE",

  GET_NEW_REFERRAL_LIST_REQUEST: "GET_NEW_REFERRAL_LIST_REQUEST",
  GET_NEW_REFERRAL_LIST_SUCCESS: "GET_NEW_REFERRAL_LIST_SUCCESS",
  GET_NEW_REFERRAL_LIST_FAILURE: "GET_NEW_REFERRAL_LIST_FAILURE",

  GET_NEW_TICKET_LIST_REQUEST: "GET_NEW_TICKET_LIST_REQUEST",
  GET_NEW_TICKET_LIST_SUCCESS: "GET_NEW_TICKET_LIST_SUCCESS",
  GET_NEW_TICKET_LIST_FAILURE: "GET_NEW_TICKET_LIST_FAILURE",

  GET_LATEST_TUTOR_CHANGE_REQUEST: "GET_LATEST_TUTOR_CHANGE_REQUEST",
  GET_LATEST_TUTOR_CHANGE_SUCCESS: "GET_LATEST_TUTOR_CHANGE_SUCCESS",
  GET_LATEST_TUTOR_CHANGE_FAILURE: "GET_LATEST_TUTOR_CHANGE_FAILURE",
};
