import React from 'react'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { FaAngleRight, FaEdit } from 'react-icons/fa'
import { timeZoneReturnDateType } from '../../../_helpers'

function FullTable ({
  getCityList,
  handleAction,
  actionCityId,
  handleEditCity,
  menuRef,
  handleEnaDis
}) {
  return (
    <>
      <section className='lg:block hidden'>
        <div className='mt-5 border border-[#D3D3D3] rounded-xl bg-white'>
          <table className='min-w-full table-autorounded-xl overflow-x-auto'>
            <thead className='border-b border-[#D1D1D1]'>
              <tr className='text-black'>
                <th className='px-6 py-3 whitespace-nowrap text-sm'>S No</th>
                <th className='px-6 py-3 whitespace-nowrap text-sm'>
                  City Name{' '}
                </th>
                <th className='px-6 py-3 whitespace-nowrap text-sm'>
                  Work Mode
                </th>
                <th className='px-6 py-3 whitespace-nowrap text-sm'>
                  Platform Fee
                </th>
                <th className='px-6 py-3 whitespace-nowrap text-sm'>
                  Created Date
                </th>
                <th className='px-6 py-3 whitespace-nowrap text-sm'>Status</th>
                <th className='px-6 py-3 whitespace-nowrap text-sm'>Action</th>
              </tr>
            </thead>
            <tbody>
              {getCityList?.data && getCityList?.data?.list.length > 0 ? (
                getCityList?.data?.list
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((item, id) => (
                    <tr className=' border-b capitalize' key={id}>
                      <td className='px-4 py-4 text-sm text-gray-900 text-center'>
                        {id + 1}
                      </td>
                      <td className='px-4 py-4 text-sm text-gray-900 text-center'>
                        {item.name}
                      </td>
                      <td className='px-4 py-4 text-sm text-gray-900 text-center'>
                        {item.isManagement ? 'Full Management' : 'Credit Based'}
                      </td>
                      <td className='px-4 py-4 text-sm text-gray-900 text-center'>
                        <p>
                          {' '}
                          {item && item?.platformFee
                            ? item.platformFee
                            : 0}{' '}
                        </p>
                      </td>
                      <td className='px-4 py-4 text-sm text-gray-900 text-center'>
                        {timeZoneReturnDateType(item?.createdAt)}
                      </td>
                      <td
                        className={`px-4 py-4 text-sm text-gray-900 text-center font-semibold cursor-pointer ${
                          item.isDisable ? 'text-red-600' : 'text-green-600'
                        }`}
                        onClick={() => handleEnaDis(item)}
                      >
                        {item.isDisable ? 'Inactive' : 'Active'}
                      </td>

                      <td className='px-4 py-4 text-gray-900 relative text-center'>
                        <span className='flex justify-center items-center'>
                          <FaEdit
                            onClick={() => handleEditCity(item)}
                            className='text-2xl cursor-pointer text-[#023565]'
                          />
                        </span>
                      </td>
                    </tr>
                  ))
              ) : (
                <tr>
                  <td colSpan='6' className='text-center'>
                    Data Not Available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </section>
    </>
  )
}

export default FullTable
