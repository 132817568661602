import { headerForPrivateAPI, APIcallFunction } from "../_helpers";
import { myprofileConstant } from "../_constants";
import { alertActions } from "./alert.actions";

export const myprofileAction = {
  getAdminProfile,
  updateAdminProfile,
  updatePassword
}

function getAdminProfile(data) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "admin/getAdminProfile "
  };
  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (user) => {
        dispatch(success(user));

      },
      (error) => {
        dispatch(failure(error));

      }
    );

  };
  function request() {
    return { type: myprofileConstant.GET_ADMIN_PROFILE_REQUEST };
  }
  function success(user) {
    return { type: myprofileConstant.GET_ADMIN_PROFILE_SUCCESS, user };
  }
  function failure(error) {
    return { type: myprofileConstant.GET_ADMIN_PROFILE_FAILURE, error };
  }
}

function updateAdminProfile(data) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "admin/updateAdminProfile "
  };
  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (user) => {
        dispatch(success(user));
        dispatch(getAdminProfile());

      },
      (error) => {
        dispatch(failure(error));

      }
    );

  };
  function request() {
    return { type: myprofileConstant.UPDATE_ADMIN_PROFILE_REQUEST };
  }
  function success(user) {
    return { type: myprofileConstant.UPDATE_ADMIN_PROFILE_SUCCESS, user };
  }
  function failure(error) {
    return { type: myprofileConstant.UPDATE_ADMIN_PROFILE_FAILURE, error };
  }
}


function updatePassword(data) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "admin/updatePassword "
  };
  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (user) => {
        dispatch(success(user));
        dispatch(getAdminProfile());
      }
    ).catch((error) => {
      dispatch(failure(error));
      dispatch(alertActions.error(error));
    })

  };
  function request() {
    return { type: myprofileConstant.UPDATE_PASSWORD_REQUEST };
  }
  function success(user) {
    return { type: myprofileConstant.UPDATE_PASSWORD_SUCCESS, user };
  }
  function failure(error) {
    return { type: myprofileConstant.UPDATE_PASSWORD_FAILURE, error };
  }
}