import React from 'react'
import { FaSearch } from 'react-icons/fa'

function SearchIconComponent({ searchText, handleSearch }) {
    return (
        <div className="search_bar  relative text-center flex justify-start lg:justify-start  lg:w-auto pt-2 lg:pt-0">
            {searchText === "" && (
                <div className="absolute inset-y-0 right-0 flex items-center pr-4 pb-3">
                    <FaSearch className="text-gray-400 mt-3 search_icon" />
                </div>
            )}
            <input type="search" className={`border-2 w-full lg:w-80 h-9 px-2 rounded-lg py-1 ${searchText ? "search-active" : ""}`} onChange={handleSearch} placeholder="" id="search" value={searchText} />
        </div>
    )
}

export default SearchIconComponent