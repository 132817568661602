/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { employprofileAction } from '../../_actions'
import { BsThreeDotsVertical } from 'react-icons/bs'
import Multiselect from 'multiselect-react-dropdown'
import { FaAngleRight, FaChevronLeft, FaEdit, FaPlus } from 'react-icons/fa'
import { MdOutlineClose } from 'react-icons/md'
import { FaChevronRight } from 'react-icons/fa'
import './Emply.css'
import Loader from '../../components/Loader'
import { downloadCSV } from '../../_helpers'
import { BiExport } from 'react-icons/bi'

export const Teams = () => {
  const dispatch = useDispatch()
  let [errors, setErrors] = useState({})

  const selector = useSelector(state => state)

  const [action, setAction] = useState(false)
  const [createModal, setCreateModal] = useState(false)

  const [options, setOptions] = useState(
    selector?.Citys?.cityGetList?.map(city => ({
      name: city.name,
      id: city._id
    }))
  )
  const [teamName, setTeamName] = useState('')
  const [workDesc, setWorkDesc] = useState('')
  const [selectedCities, setSelectedCities] = useState([])
  const [selectedTeamLead, setSelectedTeamLead] = useState('')
  const [selectedAdmin, setSelectedAdmin] = useState([])
  const [teamLead, setTeamLead] = useState([])
  const [updateTeamModal, setUpdateModal] = useState(false)
  const [updateTeamData, setUpdateTeamData] = useState('')
  const [updatedTeamLead, setUpdatedTeamlead] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const pageSize = 10

  const menuRef = useRef(null)

  const handleClickOutside = event => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setAction(null)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const [updateId, setUpdateId] = useState('')
  const [allAdminoptions, setAllOptions] = useState(
    selector?.EmplyProfile?.getAdminList?.data?.map(ele => ({
      name: ele.userName,
      id: ele._id
    }))
  )
  let [getTeamList, setGetTeamList] = useState()

  let updatedCityData = updateTeamData?.cityId?.map(city => ({
    name: city.name,
    id: city._id
  }))

  const handleAction = id => {
    setAction(previd => (previd === id ? null : id))
  }

  useEffect(() => {
    let teamDetails = {
      keyWord: '',
      fromDate: '',
      toDate: '',
      sortOrder: '',
      sortBy: '',
      pageNo: currentPage,
      size: pageSize
    }

    dispatch(employprofileAction.getTeamList(teamDetails))

    if (teamLead) {
      let arrydata =
        teamLead &&
        teamLead.map(ele => ({
          name: ele.userName,
          id: ele._id
        }))
      setAllOptions(arrydata)
    }
  }, [currentPage, teamLead])

  useEffect(() => {
    if (selector?.EmplyProfile?.getEmplyList?.data?.total) {
      setTotalPages(
        Math.ceil(selector?.EmplyProfile?.getEmplyList?.data?.total / pageSize)
      )
    }
  }, [selector])

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1)
  }

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1)
  }

  useEffect(() => {
    setGetTeamList(() => selector?.EmplyProfile?.getEmplyList?.data?.list)
  }, [selector])

  const onSelect = (selectedList, selectedItem) => {
    setSelectedCities(selectedList)
    handleCityChange(selectedList)
  }

  const onRemove = (selectedList, removedItem) => {
    setSelectedCities(selectedList)
    handleCityChange(selectedList)
  }

  const handleCityChange = selectedList => {}

  useEffect(() => {
    setTeamLead(() => selector?.EmplyProfile?.getAdminList?.data)
  }, [selector])

  const handleCreate = () => {
    setCreateModal(true)
    dispatch(employprofileAction.getAllAdminList())
  }

  const handleCreateTeam = e => {
    e.preventDefault()
    let teamDetails = {
      keyWord: '',
      fromDate: '',
      toDate: '',
      sortOrder: '',
      sortBy: '',
      pageNo: currentPage,
      size: pageSize
    }
    if (handleValidation()) {
      let createTeamDetails = {
        teamName: teamName,
        workDesc: workDesc,
        cityId:
          selectedCities &&
          selectedCities.length > 0 &&
          selectedCities?.map(city => city?.id),
        teamLead: selectedTeamLead,
        member:
          selectedAdmin &&
          selectedAdmin.length > 0 &&
          selectedAdmin.map(admin => admin.id)
      }

      dispatch(employprofileAction.createTeam(createTeamDetails, teamDetails))
      setCreateModal(false)
      setSelectedCities({})
      setSelectedAdmin({})
      setWorkDesc('')
      setTeamName('')
    }
  }
  function handleValidation () {
    let valid = true
    let error = {}
    if (!selectedAdmin || selectedAdmin.length === 0) {
      error['selectedAdmin'] = 'Select at least one member'
      valid = false
    }

    if (!workDesc) {
      error['workDesc'] = ' Work Desc is required'
      valid = false
    }
    if (
      !selectedCities ||
      selectedCities.length === 0 ||
      selectedCities === null
    ) {
      error['city'] = 'City is required'
      valid = false
    }
    if (!selectedTeamLead) {
      error['teamLead'] = 'Team Lead is required'
      valid = false
    }
    if (!teamName) {
      error['teamName'] = 'Team Name is required'
      valid = false
    }

    setErrors(error)

    return valid
  }

  const handleEnableDisableAction = data => {
    let teamDetails = {
      keyWord: '',
      fromDate: '',
      toDate: '',
      sortOrder: '',
      sortBy: '',
      pageNo: 1,
      size: 10
    }
    let enaDisData = {
      id: data._id
    }

    dispatch(employprofileAction.updateTeamStatus(enaDisData, teamDetails))
    setAction(false)
  }

  const handleUpdateTeam = data => {
    setSelectedCities(data?.cityId)
    setUpdateModal(true)
    setUpdateTeamData(data)
    setUpdateId(data._id)
    setAction(false)
  }

  const handleOnChnage = e => {
    const { name, value } = e.target
    setUpdateTeamData({ ...updateTeamData, [name]: e.target.value })
  }

  const handleteamLead = e => {
    const selectedTeamLead = teamLead.find(item => item._id === e.target.value)
    setUpdateTeamData(prevData => ({ ...prevData, teamLead: selectedTeamLead }))
    setUpdatedTeamlead(selectedTeamLead._id)
  }

  const handleUpdateSubmitTeam = e => {
    e.preventDefault()
    let teamDetails = {
      keyWord: '',
      fromDate: '',
      toDate: '',
      sortOrder: '',
      sortBy: '',
      pageNo: 1,
      size: 10
    }
    let updatedTeamDetails = {
      id: updateId,
      teamName: updateTeamData.teamName,
      workDesc: updateTeamData.workDesc,
      cityId: selectedCities?.map(city => city?.id),
      teamLead: updatedTeamLead,
      member: selectedAdmin.map(admin => admin?.id)
    }

    dispatch(employprofileAction.updateTeam(updatedTeamDetails, teamDetails))
    setUpdateModal(false)
    setAction(false)
  }

  useEffect(() => {
    let teamDetails = {
      keyWord: '',
      fromDate: '',
      toDate: '',
      sortOrder: '',
      sortBy: '',
      pageNo: 1,
      size: 100000000000
    }

    dispatch(employprofileAction.getTeamList(teamDetails))
  }, [])

  let allemplyDownload = selector?.EmplyProfile?.getEmplyList?.data?.list

  const fields = ['Team Name', 'Work Description ', 'Team Lead', 'Status']

  const convertToCSV = data => {
    const array = typeof data !== 'object' ? JSON.parse(data) : data
    let csv = fields.join(',') + '\r\n'
    array.forEach(element => {
      const teamName = element?.teamName || '-'
      const workDes = element?.workDesc || '-'
      const TeamLead = element?.teamLead?.userName || '-'

      const status = element?.isDisable === false ? 'Active' : 'Inactive'

      const row = [teamName, workDes, TeamLead, status].join(',')
      csv += row + '\r\n'
    })

    return csv
  }

  return (
    <>
      <section className=''>
        <Loader loading={selector?.EmplyProfile?.loading} />
        <div className='lg:flex hidden  justify-end gap-2 lg:-mt-10'>
          <button
            className='bg-white px-4 py-1.5 border-2 border-black gap-2  flex justify items-center hover:text-white hover:bg-[#023565] hover:scale-105 transition-all duration-300 ease-in-out-center rounded-lg'
            onClick={() => downloadCSV(convertToCSV(allemplyDownload))}
          >
            <span>Export</span>
            <span>
              <BiExport size={20} />
            </span>
          </button>

          <button
            className='flex justify-center items-center gap-2 md:w-48 w-full text-[#C60E6B] py-2.5 text-sm  border border-gray-300 rounded-lg bg-gray-50'
            onClick={() => handleCreate()}
          >
            <span>
              <FaPlus />
            </span>{' '}
            New Team
          </button>
        </div>

        <section className='lg:block hidden'>
          <div className='mt-5 border border-[#D3D3D3] rounded-xl bg-white'>
            <table className='min-w-full table-autorounded-xl overflow-x-auto'>
              <thead className='border-b border-[#D1D1D1]'>
                <tr className='text-black'>
                  <th className='px-4 py-3 whitespace-nowrap text-sm text-left'>
                    Team Name
                  </th>
                  <th className='px-4 py-3 whitespace-nowrap text-sm text-left'>
                    Work Description
                  </th>
                  <th className='px-4 py-3 whitespace-nowrap text-sm text-left'>
                    Team Lead
                  </th>
                  <th className='px-4 py-3 whitespace-nowrap text-sm text-left'>
                    Status
                  </th>
                  <th className='px-4 py-3 whitespace-nowrap text-sm'>
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {getTeamList && getTeamList.length > 0 ? (
                  getTeamList?.map((team, id) => (
                    <tr key={id} className='capitalize border-b '>
                      <td className='px-4 py-4 text-sm text-gray-900 text-left'>
                        {team?.teamName}
                      </td>
                      <td className='px-4 py-4 text-sm text-gray-900 text-left'>
                        {team?.workDesc}
                      </td>
                      <td className='px-4 py-4 text-sm text-gray-900 text-left'>
                        {team?.teamLead?.userName}
                      </td>
                      <td className='px-4 py-4 text-sm text-gray-900 text-left font-semibold'>
                        <span onClick={() => handleEnableDisableAction(team)}
                          className={`${
                            team?.isDisable === false
                              ? 'text-green-700 cursor-pointer'
                              : 'text-red-600 cursor-pointer'
                          }`}
                        >
                          {' '}
                          {team?.isDisable === false ? 'Active' : 'InActive'}
                        </span>
                      </td>
                      <td className='px-4 py-4 text-gray-900 relative'>
                        <span className='flex justify-center items-center'>
                        <FaEdit
                          onClick={() => handleUpdateTeam(team)}
                          className='text-2xl  text-[#C60E6B]'
                        />
                        </span>
                      
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr className='border-b'>
                    <td
                      colSpan={5}
                      className='px-6 py-4 font-[400] text-base text-[#313131] whitespace-nowrap text-center'
                    >
                      Data Not Available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </section>

        <section className='space-y-5 py-4 lg:hidden block'>
          <div className='lg:hidden flex justify-start  gap-2'>
            <button className='px-4 py-2 border rounded-md text-[#C60E6B] border-[#D9D9D9] bg-white flex  place-items-center justify-between gap-2'>
              <span className='e text-[#8D8D8D]'>Export</span>{' '}
              <span className='img pb-1'>
                <img src='/export 1.png' alt='' />
              </span>
            </button>
            <button
              className='px-4 py-2 border rounded-md text-[#C60E6B] border-[#D9D9D9] bg-white flex  place-items-center justify-between gap-2'
              onClick={() => handleCreate()}
            >
              <span>
                <FaPlus />
              </span>{' '}
              New Team
            </button>
          </div>
          {getTeamList && getTeamList.length > 0 ? (
            getTeamList?.map((team, id) => (
              <div className=' bg-white p-4 rounded-[10px] border border-[#023565]'>
                <>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[16px] font-[700] text-left'>
                        Team Name{' '}
                      </p>
                    </div>
                    <div className='w-full'>
                      <p className='text-[14px] font-[400]'>
                        {' '}
                        {team?.teamName}
                      </p>
                    </div>
                  </div>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[16px] font-[700] text-left'>
                        Work Description{' '}
                      </p>
                    </div>
                    <div className='w-full'>
                      <p className='text-[14px] font-[400] capitalize'>
                        {team?.workDesc}
                      </p>
                    </div>
                  </div>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[16px] font-[700] text-left'>
                        Team Lead{' '}
                      </p>
                    </div>
                    <div className='w-full'>
                      <p className='text-[14px] font-[400] capitalize'>
                        {team?.teamLead?.userName}
                      </p>
                    </div>
                  </div>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[16px] font-[700] text-left'>Status</p>
                    </div>
                    <div className='w-full'>
                      <p className='text-[14px] font-[800]'>
                        <span
                          onClick={() => handleEnableDisableAction(team)}
                          className={`${
                            team?.isDisable === false
                              ? 'text-green-700'
                              : 'text-red-600'
                          }`}
                        >
                          {team?.isDisable === false ? 'Active' : 'InActive'}
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[16px] font-[700] text-left'>Action</p>
                    </div>
                    <div className='w-full'>
                      <p className='text-[14px] font-[400]'>
                        <FaEdit
                          onClick={() => handleUpdateTeam(team)}
                          className='text-2xl  text-[#C60E6B]'
                        />
                      </p>
                    </div>
                  </div>
                </>
              </div>
            ))
          ) : (
            <div className=' bg-white p-4 rounded-[10px] border border-[#023565]'>
              <p className='text-center font-bold text-[17px]'>Data Not Found</p>
            </div>
          )}
        </section>

        {totalPages > 1 && (
          <div className='flex justify-end items-center py-2'>
            <button
              className={`px-4 py-2 border text-white bg-[#023565] rounded ${
                currentPage === 1 ? 'opacity-50 cursor-not-allowed' : ''
              }`}
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
            >
              <FaChevronLeft />
            </button>
            <span className='text-gray-700 mx-1'>
              <span className='border px-4 py-2 text-black  rounded'>
                {currentPage}
              </span>{' '}
              of{' '}
              <span className='border px-4 py-2 text-black  rounded'>
                {totalPages}
              </span>
            </span>
            <button
              className={`px-4 py-2 border text-white bg-[#023565] rounded ${
                currentPage === totalPages
                  ? 'opacity-50 cursor-not-allowed'
                  : ''
              }`}
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              <FaChevronRight />
            </button>
          </div>
        )}

        {createModal && (
          <>
            <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40'>
              <div className='relative w-11/12 mx-auto lg:w-[600px]'>
                <button
                  className='z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center'
                  onClick={() => setCreateModal(false)}
                >
                  <span>
                    <MdOutlineClose />
                  </span>{' '}
                  Close
                </button>
                <div className='form_class border-0 rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
                  <div className='flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t'>
                    <h2 className='text-lg font-semibold'>Create New</h2>
                  </div>
                  <div className='relative'>
                    <form className='gap-2 p-4  grid lg:grid-cols-2'>
                      <div className='space-y-2'>
                        <label
                          htmlFor='small-input'
                          className='block text-sm font-medium text-gray-700'
                        >
                          Team Name
                        </label>
                        <input
                          type='text'
                          value={teamName}
                          onChange={e => setTeamName(e.target.value)}
                          id='small-input'
                          className=' p-2 border block w-full border-gray-300 rounded-md'
                        />
                        {errors && errors['teamName'] ? (
                          <span className=' text-red-500 text-xs'>
                            {errors['teamName']}
                          </span>
                        ) : (
                          ''
                        )}
                      </div>
                      <div className='space-y-2'>
                        <label
                          htmlFor='teamLead'
                          className='block text-sm font-medium text-gray-700'
                        >
                          Select Team Lead
                        </label>
                        <select
                          className='outline-none p-2.5 border block w-full border-gray-300 rounded-md bg-white'
                          onChange={e => setSelectedTeamLead(e.target.value)}
                        >
                          <option value=''>See all</option>
                          {teamLead &&
                            teamLead.map((itm, id) => (
                              <option value={itm._id} key={id}>
                                {itm.userName}
                              </option>
                            ))}
                        </select>
                        {errors && errors['teamLead'] ? (
                          <span className=' text-red-500 text-xs'>
                            {errors['teamLead']}
                          </span>
                        ) : (
                          ''
                        )}
                      </div>
                      <div className='space-y-2 lg:col-span-2'>
                        <label
                          htmlFor='message'
                          className='block text-sm font-medium text-gray-700'
                        >
                          Work Description
                        </label>
                        <input
                          type='text'
                          value={workDesc}
                          onChange={e => {
                            setWorkDesc(e.target.value)
                            setErrors(pre => ({
                              ...pre,
                              workDesc: ''
                            }))
                          }}
                          id='small-input'
                          className='mt-1 p-2 border block w-full border-gray-300 rounded-md'
                        />
                        {errors && errors['workDesc'] ? (
                          <span className=' text-red-500 text-xs'>
                            {errors['workDesc']}
                          </span>
                        ) : (
                          ''
                        )}
                      </div>

                      <div className='space-y-2 lg:col-span-2'>
                        <label
                          htmlFor='countries'
                          className='block text-sm font-medium text-gray-700'
                        >
                          Select City
                        </label>
                        <Multiselect
                          options={options}
                          selectedValues={selectedCities}
                          onSelect={onSelect}
                          onRemove={onRemove}
                          displayValue='name'
                        />
                        {errors && errors['city'] ? (
                          <span className=' text-red-500 text-xs'>
                            {errors['city']}
                          </span>
                        ) : (
                          ''
                        )}
                      </div>

                      <div className='space-y-2 lg:col-span-2'>
                        <label
                          htmlFor='members'
                          className='block text-sm font-medium text-gray-700'
                        >
                          Select Member
                        </label>
                        <Multiselect
                          options={allAdminoptions}
                          selectedValues={selectedAdmin}
                          onSelect={(selectedList, selectedItem) =>
                            setSelectedAdmin(selectedList)
                          }
                          onRemove={(selectedList, removedItem) =>
                            setSelectedAdmin(selectedList)
                          }
                          displayValue='name'
                        />
                        {errors && errors['selectedAdmin'] ? (
                          <span className=' text-red-500 text-xs'>
                            {errors['selectedAdmin']}
                          </span>
                        ) : (
                          ''
                        )}
                      </div>
                      <div className='flex justify-center items-center pt-2 lg:col-span-2'>
                        <button
                          onClick={handleCreateTeam}
                          className='w-60 mx-auto bg-[#023565] text-white font-semibold py-2 px-4 rounded-full '
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {updateTeamModal && (
          <>
            <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40'>
              <div className='relative w-11/12 mx-auto lg:w-[600px]'>
                <button
                  className='z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center'
                  onClick={() => setUpdateModal(false)}
                >
                  <span>
                    <MdOutlineClose />
                  </span>{' '}
                  Close
                </button>
                <div className='form_class border-0 rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
                  <div className='flex items-center justify-between px-5 py-2 border-b border-solid border-slate-200 rounded-t'>
                    <h2 className='text-lg font-semibold'>Edit</h2>
                  </div>
                  <div className='relative '>
                    <form className='gap-2 p-4 grid lg:grid-cols-2'>
                      <div className='space-y-2'>
                        <label
                          htmlFor='small-input'
                          className='block text-sm font-medium text-gray-700'
                        >
                          Team Name
                        </label>
                        <input
                          type='text'
                          name='teamName'
                          value={updateTeamData?.teamName}
                          onChange={handleOnChnage}
                          id='small-input'
                          className='mt-1 p-2 border block w-full border-gray-300 rounded-md'
                        />
                      </div>
                      <div className='space-y-2'>
                        <label
                          htmlFor='teamLead'
                          className='block text-sm font-medium text-gray-700'
                        >
                          Team Lead
                        </label>
                        <select
                          value={updateTeamData?.teamLead?._id}
                          onChange={handleteamLead}
                          className='p-2.5 bg-white outline-none border block w-full border-gray-300 rounded-md'
                        >
                          {teamLead &&
                            teamLead.map((itm, id) => (
                              <option value={itm._id} key={id}>
                                {itm.userName}
                              </option>
                            ))}
                        </select>
                      </div>
                      <div className='space-y-2 lg:col-span-2'>
                        <label
                          htmlFor='message'
                          className='block text-sm font-medium text-gray-700'
                        >
                          Work Description
                        </label>
                        <input
                          type='text'
                          name='workDesc'
                          value={updateTeamData?.workDesc}
                          onChange={handleOnChnage}
                          id='small-input'
                          className='mt-1 p-2 border block w-full border-gray-300 rounded-md'
                        />
                      </div>

                      <div className='space-y-2 lg:col-span-2'>
                        <label
                          htmlFor='countries'
                          className='block text-sm font-medium text-gray-700'
                        >
                          City
                        </label>
                        <Multiselect
                          value={updatedCityData?.name}
                          options={options}
                          selectedValues={selectedCities}
                          onSelect={onSelect}
                          onRemove={onRemove}
                          displayValue='name'
                          style={{
                            multiselectContainer: {
                              backgroundColor:
                                'bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                            }
                          }}
                        />
                      </div>

                      <div className='space-y-2 lg:col-span-2'>
                        <label
                          htmlFor='members'
                          className='block text-sm font-medium text-gray-700'
                        >
                          Team Member
                        </label>
                        <Multiselect
                          options={allAdminoptions}
                          selectedValues={selectedAdmin}
                          onSelect={(selectedList, selectedItem) =>
                            setSelectedAdmin(selectedList)
                          }
                          onRemove={(selectedList, removedItem) =>
                            setSelectedAdmin(selectedList)
                          }
                          displayValue='name'
                          style={{
                            multiselectContainer: {
                              backgroundColor:
                                'bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                            }
                          }}
                        />
                      </div>
                      <div className='flex justify-center items-center pt-2'>
                        <button
                          onClick={handleUpdateSubmitTeam}
                          className='w-60 mx-auto bg-[#023565] text-white font-semibold py-2 px-4 rounded-full '
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </section>
    </>
  )
}
