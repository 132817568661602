import { bannerConstants } from "../_constants";
import { alertActions } from "./alert.actions";
import { headerForPrivateAPI, APIcallFunction } from "../_helpers";

export const bannerActions = {
  Bannercreate,
  BannerGet,
  BannerDelete,
  BannerUpdate,
  bannerEnaDis,
  bannerCreateByPromise
};

function bannerCreateByPromise(data) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "banner/create"
  };

  return APIcallFunction(credentials)

}

function Bannercreate(data, bannerSet) {
 
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "banner/create",
  };

  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (user) => {
        dispatch(alertActions.success("Banner created"));
        dispatch(success(user));
        dispatch(BannerGet(bannerSet));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: bannerConstants.BANNER_CREATE_REQUEST };
  }
  function success(user) {
    return { type: bannerConstants.BANNER_CREATE_SUCCESS, user };
  }
  function failure(error) {
    return { type: bannerConstants.BANNER_CREATE_FAILURE, error };
  }
}

function BannerGet(data) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "banner/getList"
  };
  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (user) => {
        dispatch(success(user));
        
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
    
  };
  function request() {
    return { type: bannerConstants.BANNER_GET_REQUEST };
  }
  function success(user) {
    return { type: bannerConstants.BANNER_GET_SUCCESS, user };
  }
  function failure(error) {
    return { type: bannerConstants.BANNER_GET_FAILURE, error };
  }
}

function BannerDelete(data, bannerSet) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "banner/delete",
  };

  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (user) => {
        dispatch(alertActions.success("Banner Deleted"));
        dispatch(success(user));
        dispatch(BannerGet(bannerSet));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: bannerConstants.BANNER_DELETE_REQUEST, data };
  }
  function success(user) {
    return { type: bannerConstants.BANNER_DELETE_SUCCESS, user };
  }
  function failure(error) {
    return { type: bannerConstants.BANNER_DELETE_FAILURE, error };
  }
}

function BannerUpdate(data, bannerSet) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "banner/update",
  };

  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (user) => {
        dispatch(alertActions.success("Banner Update Successfuly"));
        dispatch(success(user));
        dispatch(BannerGet(bannerSet));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };

  function request() {
    return { type: bannerConstants.BANNER_UPDATE_REQUEST, data };
  }
  function success(user) {
    return { type: bannerConstants.BANNER_UPDATE_SUCCESS, user };
  }
  function failure(error) {
    return { type: bannerConstants.BANNER_UPDATE_FAILURE, error };
  }
}

function bannerEnaDis(data, bannerSet) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "banner/enableDisable",
  };
  return (dispatch) => {
    dispatch(request(data));

    APIcallFunction(credentials).then(
      (user) => {
        
        dispatch(success(user));
        dispatch(BannerGet(bannerSet));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };
  function request() {
    return { type: bannerConstants.BANNER_ENA_DIS_REQUEST };
  }
  function success(user) {
    return { type: bannerConstants.BANNER_ENA_DIS_SUCCESS, user };
  }
  function failure(error) {
    return { type: bannerConstants.BANNER_ENA_DIS_FAILURE, error };
  }
}
