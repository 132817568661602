import { headerForPrivateAPI, APIcallFunction } from "../_helpers";
import { alertActions } from "./alert.actions";
import { assignmentConstant } from "../_constants";

export const assignmentAction = {
  assignmentGet,
  assignmentCreate,
  assignmentDelete,
  getAllTuitionList,
  updateAssignment,
  getAssignmentCityList,
  getAssignmentClassList,
  getAssignmentTuitionListList
}

function assignmentGet(data) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "assignment/getAssignmentAdminList"
  };

  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (user) => {
        dispatch(success(user));

      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );

  };
  function request() {
    return { type: assignmentConstant.GET_ASSIGNMENT_LIST_REQUEST };
  }
  function success(user) {
    return { type: assignmentConstant.GET_ASSIGNMENT_LIST_SUCCESS, user };
  }
  function failure(error) {
    return { type: assignmentConstant.GET_ASSIGNMENT_LIST_FAILURE, error };
  }
}

function assignmentCreate(data, resdata) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "assignment/createAssignmentByAdmin"
  };

  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (user) => {
        dispatch(success(user));
        dispatch(assignmentGet(resdata))
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );

  };
  function request() {
    return { type: assignmentConstant.CREATE_ASSIGNMENT_LIST_REQUEST };
  }
  function success(user) {
    return { type: assignmentConstant.CREATE_ASSIGNMENT_LIST_SUCCESS, user };
  }
  function failure(error) {
    return { type: assignmentConstant.CREATE_ASSIGNMENT_LIST_FAILURE, error };
  }
}

function assignmentDelete(data, resdata) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "assignment/deleteAssignment"
  };

  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (user) => {
        dispatch(success(user));
        dispatch(assignmentGet(resdata))


      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );

  };
  function request() {
    return { type: assignmentConstant.DELETE_ASSIGNMENT_LIST_REQUEST };
  }
  function success(user) {
    return { type: assignmentConstant.DELETE_ASSIGNMENT_LIST_SUCCESS, user };
  }
  function failure(error) {
    return { type: assignmentConstant.DELETE_ASSIGNMENT_LIST_FAILURE, error };
  }
}

function updateAssignment(data, resdata) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "assignment/updateAssignment"
  };

  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (user) => {
        dispatch(success(user));
        dispatch(assignmentGet(resdata))


      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );

  };
  function request() {
    return { type: assignmentConstant.UPDATE_ASSIGNMENT_REQUEST };
  }
  function success(user) {
    return { type: assignmentConstant.UPDATE_ASSIGNMENT_SUCCESS, user };
  }
  function failure(error) {
    return { type: assignmentConstant.UPDATE_ASSIGNMENT_FAILURE, error };
  }
}







function getAllTuitionList(data, resdata) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "demo/getAllTuitionList"
  };

  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (user) => {
        dispatch(success(user));
        // dispatch(assignmentGet(resdata))


      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );

  };
  function request() {
    return { type: assignmentConstant.GET_ALL_TUITION_LIST_REQUEST };
  }
  function success(user) {
    return { type: assignmentConstant.GET_ALL_TUITION_LIST_SUCCESS, user };
  }
  function failure(error) {
    return { type: assignmentConstant.GET_ALL_TUITION_LIST_FAILURE, error };
  }
}




function getAssignmentCityList(data, resdata) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "assignment/getAssignmentCityList"
  };

  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (user) => {
        dispatch(success(user));
        // dispatch(assignmentGet(resdata))


      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );

  };
  function request() {
    return { type: assignmentConstant.GET_ASSIGNMENT_CITY_LIST_REQUEST };
  }
  function success(user) {
    return { type: assignmentConstant.GET_ASSIGNMENT_CITY_LIST_SUCCESS, user };
  }
  function failure(error) {
    return { type: assignmentConstant.GET_ASSIGNMENT_CITY_LIST_FAILURE, error };
  }
}






function getAssignmentClassList(data, resdata) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "assignment/getAssignmentClassList"
  };

  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (user) => {
        dispatch(success(user));
        // dispatch(assignmentGet(resdata))


      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );

  };
  function request() {
    return { type: assignmentConstant.GET_ASSIGNMENT_CLASS_LIST_REQUEST };
  }
  function success(user) {
    return { type: assignmentConstant.GET_ASSIGNMENT_CLASS_LIST_SUCCESS, user };
  }
  function failure(error) {
    return { type: assignmentConstant.GET_ASSIGNMENT_CLASS_LIST_FAILURE, error };
  }
}





function getAssignmentTuitionListList(data, resdata) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "assignment/getAssignmentTuitionListList"
  };

  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (user) => {
        dispatch(success(user));
        // dispatch(assignmentGet(resdata))


      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );

  };
  function request() {
    return { type: assignmentConstant.GET_ASSIGNMENT_TUITION_LIST_REQUEST };
  }
  function success(user) {
    return { type: assignmentConstant.GET_ASSIGNMENT_TUITION_LIST_SUCCESS, user };
  }
  function failure(error) {
    return { type: assignmentConstant.GET_ASSIGNMENT_TUITION_LIST_FAILURE, error };
  }
}