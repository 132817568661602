import { userConstants } from '../_constants';
import { alertActions } from './alert.actions';
import { APIcallFunction, headerForPrivateAPI, headerForPublicAPI, logoutFunction } from '../_helpers';


export const userActions = {
    login,
    logout,
    addUserRatingByAdmin,
    getUserByPromiseId
};


async function getUserByPromiseId(data) {

    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: "user/getUserById "
    };
    return await APIcallFunction(credentials)
}
function login(data, navigate) {
    const credentials = {
        header: headerForPublicAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: 'admin/adminLogin'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                user => {

                    if (user?.data?.roleAccess?.roleId === 1) {
                        window.sessionStorage.setItem("management", JSON.stringify(true))
                        if (navigate) {
                            navigate('/app/dashboard');
                        }
                    }
                    else {
                        if (user?.data?.roleAccess.roleId !== 1) {
                            window.sessionStorage.setItem("management", JSON.stringify(user?.data?.isManagement))
                            if (navigate && !user?.data?.isManagement) {
                                navigate('/app/dashboard-cb');
                            }
                            else {
                                navigate("/app/dashboard")
                            }
                        }
                    }
                    window.sessionStorage.setItem("adminuser", JSON.stringify(user.data))
                    dispatch(success(user));
                    dispatch(alertActions.success("Login Successfully"))
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}


function addUserRatingByAdmin(data, parentUserList) {

    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: "rating/addUserRatingByAdmin"
    };
    return (dispatch) => {
        dispatch(request(data));
        APIcallFunction(credentials).then(
            (users) => {
                dispatch(success(users))
            },
            (error) => {

                dispatch(failure(error))
            }
        )

    }
    function request() { return { type: userConstants.ADD_RATING_ACCEPT_DATA_REQUEST } }
    function success(users) { return { type: userConstants.ADD_RATING_ACCEPT_DATA_SUCCESS, users } }
    function failure(error) { return { type: userConstants.ADD_RATING_ACCEPT_DATA_FAILURE, error } }
};





function logout() {
    logoutFunction()
    return { type: userConstants.LOGOUT };
}

