import React from 'react'
import { MdOutlineClose } from 'react-icons/md';

function FeedbackModel({ feedbackModal, setFeedbackModal, setRating, setNewRemark, newRemark, setHover, rating, hover, handlePositive }) {
    return feedbackModal && (
        <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40">
                <div className="relative w-11/12 mx-auto lg:w-[600px]">
                    <button
                        className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center"
                        onClick={() => setFeedbackModal(false)}
                    >
                        <span><MdOutlineClose /></span> Close
                    </button>
                    <div className="form_class border-0 rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        <div className="flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                            <h2 className="text-lg font-semibold">Feedback </h2>
                        </div>
                        <div className="relative lg:h-[320px] overflow-y-auto">
                            <div className="flex flex-col items-center p-4 space-y-4">
                                <p className="text-md font-semibold ">Rate your experience:</p>
                                <div className="flex ">
                                    {[...Array(5)].map((star, index) => {
                                        index += 1;
                                        return (
                                            <button
                                                type="button"
                                                key={index}
                                                className={index <= (hover || rating) ? "text-yellow-500" : "text-gray-400"}
                                                onClick={() => setRating(index)}
                                                onMouseEnter={() => setHover(index)}
                                                onMouseLeave={() => setHover(rating)}
                                            >
                                                <span className="text-2xl">&#9733;</span>
                                            </button>
                                        );
                                    })}
                                </div>
                                <textarea
                                    className="w-full p-2 border rounded"
                                    rows="4"
                                    placeholder="Write your remark here..."
                                    value={newRemark}
                                    onChange={(e) => setNewRemark(e.target.value)}
                                />
                            </div>

                            <div className="flex justify-center items-center py-2">
                                <button
                                    type="submit"
                                    className="w-60 mx-auto bg-[#023565] text-white font-semibold py-2 px-4 rounded-full "
                                    onClick={handlePositive}
                                >
                                    Submit
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default FeedbackModel