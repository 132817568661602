/* eslint-disable no-unused-vars */
import './Earning.css'
import moment from 'moment/moment'
import { useNavigate } from 'react-router-dom'

const EarningTable = ({ feeData }) => {
  const navigate = useNavigate()
  const handleFeeRecived = data => {
    navigate(`/app/feerecevied`, { state: { feeData: data } })
  }
  const handleReferencePaid = data => {
    navigate(`/app/referralpaid`, { state: { feeReferralData: data } })
  }
  const handleReferralPaid = data => {
    navigate(`/app/referencepaid`, { state: { feeReferenceData: data } })
  }
  const handleFeeRefund = data => {
    navigate(`/app/feerefund`, { state: { feeRefundData: data } })
  }
  const handleNoFee = data => {
    navigate(`/app/NoFee`, { state: { feeData: data } })
  }
  const handletutorFeePaid = data => {
    navigate(`/app/tutorfeepaid`, { state: { tutorfeepaidData: data } })
  }
  const handletutorFeePending = data => {
    navigate(`/app/fee-pending`, { state: { tutorfeependingData: data } })
  }
  return (
    <>
      <div className='overflow-x-auto overflow-y-auto overflow-hidden'>
        <table className=' table-auto min-w-full text-center h-4/5 tableForMobile  bg-white rounded-lg  '>
          <thead className='border-b '>
            <tr className='text-sm'>
              <th className='py-4 px-6 whitespace-nowrap table_head text-[#313131]'>
                Month-Year
              </th>
              <th className='py-4 px-6 whitespace-nowrap table_head text-[#313131]'>
                Tuition Fee
              </th>
              <th className='py-4 px-6 whitespace-nowrap table_head text-[#313131]'>
                Tutor Fee
              </th>
              <th className='py-4 px-6 whitespace-nowrap table_head text-[#313131]'>
                Reference Fee
              </th>
              <th className='py-4 px-6 whitespace-nowrap table_head text-[#313131]'>
                Refund Fee
              </th>
              <th className='py-4 px-6 whitespace-nowrap table_head text-[#313131]'>
                No Fee
              </th>
              <th className='py-4 px-6 whitespace-nowrap table_head text-[#313131]'>
                <p>Fee Received </p>
                <p>Student Fee Refund</p>
              </th>
              <th className='py-4 px-6 whitespace-nowrap table_head text-[#313131]'>
                <p> Fee Paid</p>
                <p>Tutor Fee Refund</p>
              </th>
              <th className='py-4 px-6 whitespace-nowrap table_head text-[#313131]'>
                Fee Pending
              </th>
              <th className='py-4 px-6 whitespace-nowrap table_head text-[#313131]'>
                Referral Paid
              </th>
              <th className='py-4 px-6 whitespace-nowrap table_head text-[#313131]'>
                Revenue
              </th>
            </tr>
          </thead>
          <tbody>
            {feeData && feeData.length > 0 ? (
              feeData?.map((item, index) => (
                <tr key={index} className='my-2  border-b border-[#D3D3D3] '>
                  <td>{moment(item.month).format('MMMM - YYYY ')}</td>
                  <td className='py-5'>
                    <div className='forfontsmall text-[#313131]'>
                      {item?.tuitionFee}{' '}
                    </div>
                    <div className='underline text-[#00AFE2]'></div>
                  </td>
                  <td className='py-5'>
                    <div className='forfontsmall text-[#313131]'>
                      {item?.tutorFee}{' '}
                    </div>
                    <div className='underline text-[#00AFE2]'></div>
                  </td>
                  <td className='py-5'>
                    <div className='forfontsmall text-[#313131]'>
                      {item?.referenceFee}{' '}
                    </div>
                    <div className='underline text-[#00AFE2]'></div>
                  </td>
                  <td className='py-5'>
                    <div className='flex items-center justify-center space-x-2'>
                      <div
                        onClick={() => handleFeeRefund(item)}
                        className='forfontsmall text-[#00AFE2] underline cursor-pointer'
                      >
                        {item?.refundTuitionFee}{' '}
                      </div>
                    </div>
                  </td>
                  <td className='py-5'>
                    <div className='flex items-center justify-center space-x-2'>
                      <div
                        onClick={() => handleNoFee(item)}
                        className='forfontsmall text-[#00AFE2] underline cursor-pointer'
                      >
                        {item?.noTuitionFee}{' '}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className='flex items-center justify-center space-x-2'>
                      <div
                        onClick={() => handleFeeRecived(item)}
                        className='forfontsmall text-[#00AFE2] underline cursor-pointer'
                      >
                        {' '}
                        <p>{item?.feeReceived}</p>{' '}
                        <p>
                          Total: {item?.feeReceived - item?.refundTuitionFee}
                        </p>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className='flex items-center justify-center space-x-2'>
                      <div
                        onClick={() => handletutorFeePaid(item)}
                        className='forfontsmall text-[#00AFE2] underline cursor-pointer'
                      >
                        {' '}
                        <p>{item.feePaid} </p>
                        <p>Total: {item?.feePaid - item?.refundTutorFee}</p>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className='flex items-center justify-center space-x-2'>
                      <div
                        onClick={() => handletutorFeePending(item)}
                        className='forfontsmall text-[#00AFE2] underline cursor-pointer'
                      >
                        {' '}
                        {item?.feePending}{' '}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className='flex items-center justify-center space-x-2'>
                      <div
                        onClick={() => handleReferencePaid(item)}
                        className='forfontsmall text-[#00AFE2] underline cursor-pointer'
                      >
                        {' '}
                        {item?.referralPaid}
                      </div>
                    </div>
                  </td>
                  <td className='py-5'>
                    <div className='forfontsmall text-[#313131]'>
                      {item?.Revenue}{' '}
                    </div>
                    <div className='underline text-[#00AFE2]'></div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={14} className='text-center text-[#313131] py-5'>
                  Data Not Available
                </td>
              </tr>
            )}
          </tbody>
        </table>

        <div className='formobile_viewonly'>
          {feeData && feeData.length > 0 ? (
            feeData.map((item, id) => (
              <div className='rounded-[10px] border my-4  bg-white border-[#033565]'>
                <table key={id} className='w-full  divide-y divide-gray-300  '>
                  <tr className=''>
                    <th className='text-left px-4 py-4'>
                      <span className='text-[16px] font-[700]'>Month-Year</span>
                    </th>
                    <td className='text-auto  py-4 px-4'>
                      <p className='font-[400] text-[14px]'>
                        {moment(item.month).format('MMMM - YYYY')}
                      </p>
                    </td>
                  </tr>
                  <tr className=''>
                    <th className='text-left py-4 px-4'>
                      <span className='text-[16px] font-[700]'>
                        Tuition Fee{' '}
                      </span>
                    </th>
                    <td className='text-auto py-4  px-4 gap-2'>
                      <p className='font-[400] text-[14px]'>
                        {item?.tuitionFee}
                      </p>
                    </td>
                  </tr>
                  <tr className=''>
                    <th className='text-left py-4 px-4'>
                      <span className='text-[16px] font-[700]'>Tutor Fee </span>
                    </th>
                    <td className='text-auto py-4  px-4 gap-2'>
                      <p className='font-[400] text-[14px]'>{item?.tutorFee}</p>
                    </td>
                  </tr>
                  <tr className=''>
                    <th className='text-left py-4 px-4'>
                      <span className='text-[16px] font-[700]'>
                        Reference Fee{' '}
                      </span>
                    </th>
                    <td className='text-auto py-4  px-4 gap-2'>
                      <p className='font-[400] text-[14px]'>
                        {item?.referenceFee}
                      </p>
                    </td>
                  </tr>
                  <tr className=''>
                    <th className='text-left py-4 px-4'>
                      <span className='text-[16px] font-[700]'>Refund Fee</span>
                    </th>
                    <td className='text-auto py-4  px-4 gap-2'>
                      <p
                        className='font-[400] text-[14px] text-[#00AFE2] underline'
                        onClick={() => handleFeeRefund(item)}
                      >
                        {item?.refundTuitionFee}
                      </p>
                    </td>
                  </tr>
                  <tr className=''>
                    <th className='text-left py-4 px-4'>
                      <span className='text-[16px] font-[700]'>No Fee</span>
                    </th>
                    <td className='text-auto py-4  px-4 gap-2'>
                      <p
                        className='font-[400] text-[14px] text-[#00AFE2] underline'
                        onClick={() => handleNoFee(item)}
                      >
                        {item?.noTuitionFee}
                      </p>
                    </td>
                  </tr>
                  <tr className=''>
                    <th className='text-left py-4 px-4'>
                      <p className='text-[16px] font-[700]'>Fee Received</p>
                      <p className='text-[16px] font-[700]'>
                        Student Fee Refund
                      </p>
                    </th>
                    <td
                      className='text-auto py-4  px-4 gap-2'
                      onClick={() => handleFeeRecived(item)}
                    >
                      <p className='font-[400] text-[14px] text-[#00AFE2] underline'>
                        {item?.feeReceived}
                      </p>
                      <p className='font-[400] text-[14px] text-[#00AFE2] underline'>
                        Total: {item?.feeReceived - item?.refundTuitionFee}
                      </p>
                    </td>
                  </tr>
                  <tr className=''>
                    <th className='text-left py-4 px-4'>
                      <p className='text-[16px] font-[700]'>Fee Paid</p>
                      <p className='text-[16px] font-[700]'>Tutor Fee Refund</p>
                    </th>
                    <td
                      className='text-auto py-4  px-4 gap-2'
                      onClick={() => handletutorFeePaid(item)}
                    >
                      <p className='font-[400] text-[14px] text-[#00AFE2] underline'>
                        {item.feePaid}
                      </p>
                      <p className='font-[400] text-[14px] text-[#00AFE2] underline'>
                        Total: {item?.feePaid - item?.refundTutorFee}
                      </p>
                    </td>
                  </tr>
                  <tr className=''>
                    <th className='text-left py-4 px-4'>
                      <span className='text-[16px] font-[700]'>
                        Fee Pending
                      </span>
                    </th>
                    <td className='text-auto py-4  px-4 gap-2'>
                      <p
                        className='font-[400] text-[14px] text-[#00AFE2] underline'
                        onClick={() => handletutorFeePending(item)}
                      >
                        {item?.feePending}
                      </p>
                    </td>
                  </tr>
                  <tr className=''>
                    <th className='text-left py-4 px-4'>
                      <span className='text-[16px] font-[700]'>
                        Referral Paid{' '}
                      </span>
                    </th>
                    <td className='text-auto py-4  px-4 gap-2'>
                      <p
                        className='font-[400] text-[14px] text-[#00AFE2] underline'
                        onClick={() => handleReferencePaid(item)}
                      >
                        {item?.referralPaid}
                      </p>
                    </td>
                  </tr>
                  <tr className=''>
                    <th className='text-left py-4 px-4'>
                      <span className='text-[16px] font-[700]'>Revenue</span>
                    </th>
                    <td className='text-auto py-4  px-4 gap-2'>
                      <p className='font-[400] text-[14px]'>{item?.Revenue}</p>
                    </td>
                  </tr>
                </table>
              </div>
            ))
          ) : (
            <div className='text-center my-4 rounded-[10px] border border-[#033565]'>
              <p className='text-gray-500 font-[500] text-[16px] p-4'>
                Data Not Found
              </p>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default EarningTable
