import React from 'react'
import { useParams } from 'react-router';

const HeaderHeading = ({label}) => {
  const params = useParams();
  return (
    <div className="flex items-center gap-10">
      <div>
        <h1 className="text-2xl font-[800] uppercase py-3">{label}</h1>
        {params["*"] === "dashboard" && (
          <p className="text-[#7E7E7E]">Management Modal</p>
        )}
      </div>
    </div>
  )
}

export default HeaderHeading