/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { attendanceAction, userListAction } from '../../../_actions'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'
import { MdOutlineClose } from 'react-icons/md'
import moment from 'moment/moment'
import { BiSolidCalendarEdit } from 'react-icons/bi'
import Loader from '../../../components/Loader'
import { FaFilterCircleXmark } from 'react-icons/fa6'
import Select from 'react-select'
import { truncateText } from '../../../_helpers'

const UnmarkedAttendance = ({
  currentPage,
  pageSize,
  setCurrentPage,
  searchText,
  users,
  handleReset,
  tutors
}) => {
  const dispatch = useDispatch()
  const selector = useSelector(state => state)
  const [absentTodayDataList, setabsentTodayDataList] = useState()
  const [totalPages, setTotalPages] = useState(1)
  const [changeAttendance, setChangeAttendance] = useState(false)
  const [remark, setRemark] = useState('')
  const [attendanceData, setAttendanceData] = useState({})
  const [dispute, setDispute] = useState('')
  const [tutorId, setTutorId] = useState({ label: 'Select Tutor', value: '' })
  const [parentId, setParentId] = useState({
    label: 'Select Parent',
    value: ''
  })

  useEffect(() => {
    let absentTodayDetails = {
      keyWord: searchText,
      tutorId: tutorId.value !== '' ? [tutorId.value] : null,
      parentId: parentId.value !== '' ? [parentId.value] : null,
      fromDate: '',
      toDate: '',
      sortOrder: 'desc',
      sortBy: 'createdAt',
      pageNo: currentPage,
      size: pageSize,
      dispute,
      marked: true
    }
    dispatch(attendanceAction.getAbsentTodayByAdmin(absentTodayDetails))
  }, [currentPage, searchText, users, tutorId, dispute, parentId])

  useEffect(() => {
    setabsentTodayDataList(
      () => selector?.attendance?.getAbsentTodayList?.data?.list
    )
    if (selector?.attendance?.getAbsentTodayList?.data?.total) {
      setTotalPages(
        Math.ceil(
          selector?.attendance?.getAbsentTodayList?.data?.total / pageSize
        )
      )
    } else {
      setTotalPages(1)
    }
  }, [selector])

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1)
  }

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1)
  }

  const handleChangeAttendance = data => {
    setChangeAttendance(true)
    let date = data?.date
    let formattedDate = moment(date).format('YYYY-MM-DD')
    let parentId = data?.parentId?._id
    let tutorId = data?.tutorId?._id
    let tuitionid = data?.tuitionId?._id

    let changeAttendanceData = {
      date: formattedDate,
      parentId: parentId,
      tutorId: tutorId,
      tuitionId: tuitionid
    }
    setAttendanceData(changeAttendanceData)
  }

  const handleMarkSubmit = e => {
    e.preventDefault()
    let dataForAdminView = {
      keyWord: searchText,
      fromDate: '',
      tutorId: tutorId.value !== '' ? [tutorId.value] : null,
      parentId: parentId.value !== '' ? [parentId.value] : null,
      toDate: '',
      sortOrder: 'desc',
      sortBy: 'createdAt',
      pageNo: currentPage,
      size: pageSize,
      dispute
    }
    let newChangeData = {
      remark: remark,
      ...attendanceData
    }
    const statusRes = 'Data'
    dispatch(
      attendanceAction.checkInOutByAdmin(
        newChangeData,
        dataForAdminView,
        statusRes
      )
    )
    setChangeAttendance(false)
    setRemark('')
  }

  const handleClose = () => {
    setChangeAttendance(false)
    setRemark('')
  }

  const handleResetFilters = () => {
    handleReset()
    setParentId({ label: 'Select Parent', value: '' })
    setTutorId({ label: 'Select Tutor', value: '' })
    setDispute('')
  }

  const customStyles = {
    container: provided => ({
      ...provided
    }),
    control: (provided, state) => ({
      ...provided,
      // border: '1px solid #000',
      boxShadow: 'none',
      '&:hover': {
        border: '1px solid #000'
      },
      borderRadius: '5px'
    }),
    menu: provided => ({
      ...provided
    }),
    placeholder: provided => ({
      ...provided,
      color: '#000 !important'
    })
  }

  useEffect(() => {
    dispatch(userListAction.getUserTypeList({ isManagement: true }))
  }, [])

  const tutorOptions = selector?.userList?.getUserListForSearch?.data
    ? selector?.userList?.getUserListForSearch?.data
        .filter(ele => ele?.userType === 'TUTOR')
        .map(ele => ({
          value: ele?._id,
          label: `${ele?.name} - ${ele?.mobNo}`
        }))
    : []

  const parentOptions = selector?.userList?.getUserListForSearch?.data
    ? selector?.userList?.getUserListForSearch?.data
        .filter(ele => ele?.userType === 'PARENT')
        .map(ele => ({
          value: ele?._id,
          label: `${ele?.name} - ${ele?.mobNo}`
        }))
    : []

  return (
    <>
      <div className='flex flex-wrap lg:justify-end items-center gap-5 lg:-mt-9 mt-4'>
        <div className='flex space-x-6 items-center justify-center'>
          <div className='grid md:grid-cols-2 grid-cols-2 gap-6'>
            {/* <select className="block w-44 py-2 px-4 text-sm text-gray-900 border border-gray-300 rounded-md shadow bg-white capitalize"
              value={dispute}
              onChange={(e) => setDispute(e.target.value)}
            >
              <option value={""}>Select Dispute</option>
              <option value={true}>Any Dispute</option>
              <option value={false}>No Dispute</option>

            </select> */}
            <div className='w-44'>
              <Select
                id='parentId'
                name='parentId'
                value={parentId}
                options={[
                  { label: 'Select Parent', value: '' },
                  ...parentOptions
                ]}
                className='capitalize text-black text-sm'
                styles={customStyles}
                classNamePrefix='react-select'
                placeholder='Parent Name'
                onChange={(selectedOption, actionMeta) => {
                  setParentId(selectedOption)
                }}
              />
            </div>
            <div className='w-44'>
              <Select
                id='tutor'
                name='tutor'
                value={tutorId}
                options={[
                  { label: 'Select Tutor', value: '' },
                  ...tutorOptions
                ]}
                className='capitalize text-black text-sm'
                styles={customStyles}
                classNamePrefix='react-select'
                placeholder='Tutor Name'
                onChange={(selectedOption, actionMeta) => {
                  setTutorId(selectedOption)
                }}
              />
            </div>
          </div>
          <button className='text-center font-bold md:pt-0  lg:pr-3'>
            <FaFilterCircleXmark
              size={24}
              className=' mx-auto text-[#023565] hover:text-[#E4006F] cursor-pointer'
              onClick={() => {
                handleResetFilters()
              }}
            />
          </button>
        </div>
      </div>

      <div className='overflow-x-auto py-6'>
        <Loader loading={selector?.attendance?.loading} />

        <div className='bg-white border lg:block hidden border-[#D3D3D3] rounded-lg'>
          <table className='min-w-full table-auto divide-y divide-gray-200 '>
            <thead className=''>
              <tr>
                <th className='px-6 py-3 whitespace-nowrap text-sm text-black text-left'>
                  Parent
                </th>
                <th className='px-6 py-3 whitespace-nowrap text-sm text-black'>
                  Student Name
                </th>
                <th className='px-6 py-3 whitespace-nowrap text-sm text-black'>
                  Assigned Tutor
                </th>
                <th className='px-6 py-3 whitespace-nowrap text-sm text-black'>
                  Attendance
                </th>
                <th className='px-6 py-3 whitespace-nowrap text-sm text-black'>
                  Any Dispute
                </th>
                <th className='px-6 py-3 whitespace-nowrap text-sm text-black'>
                  Date
                </th>
                <th className='px-6 py-3 whitespace-nowrap text-sm text-black'>
                  Remark
                </th>
                <th className='px-6 py-3 whitespace-nowrap text-sm text-black'>
                  Action
                </th>
              </tr>
            </thead>
            <tbody className='bg-white divide-y divide-gray-200'>
              {absentTodayDataList && absentTodayDataList.length > 0 ? (
                absentTodayDataList?.map((itm, id) => (
                  <tr key={id}>
                    <td className='px-4 py-3 flex items-center gap-4 text-sm text-gray-900'>
                      <div className='flex items-center justify-center gap-2'>
                        <span>
                          {itm?.parentId && itm?.parentId?.image ? (
                            <img
                              alt=''
                              src={
                                itm?.parentId && itm?.parentId?.image
                                  ? itm?.parentId?.image
                                  : ''
                              }
                              className='rounded-full my-3 max-w-[50px] max-h-[50px] min-w-[50px] min-h-[50px]'
                            />
                          ) : (
                            <div className='bg-gray-300 rounded-full h-14 w-14 flex justify-center items-center'>
                              <span className='text-base font-semibold text-[#033565]'>
                                {' '}
                                {itm?.parentId?.name
                                  ? itm.parentId.name
                                      .split(' ')
                                      .map(name => name.charAt(0))
                                      .join('')
                                  : ''}
                              </span>
                            </div>
                          )}
                        </span>
                        <div className='w-24'>
                          <p className='font-semibold whitespace-nowrap text-[#023565]'>
                            {itm?.parentId?.name ?? 'Not Available'}
                          </p>
                          <p className='text-gray-500 whitespace-nowrap'>
                            {itm?.parentId?.mobNo ?? 'Not Available'}
                          </p>
                        </div>
                      </div>
                    </td>

                    <td className='px-4 py-3 text-sm text-gray-900 text-center'>
                      {Array.isArray(itm?.tuitionId?.bookFreeDemo) ? (
                        itm.tuitionId.bookFreeDemo
                          .map(ele => ele?.studentId?.studentFullName)
                          .filter(name => name)
                          .join(', ')
                      ) : (
                        <small>No students found</small>
                      )}
                    </td>
                    <td className='px-4 py-3 text-sm text-gray-900 text-center'>
                      {itm?.tutorId?.name}
                    </td>
                    {/* <td className={`px-4 py-3 text-sm text-gray-900 text-center font-semibold ${(itm?.isOffByStudent === true || itm?.isMarkAbsent === true) ? 'text-red-500' : 'text-green-500'}`}>
                      {itm?.isOffByStudent === true || itm?.isMarkAbsent === true ? `Absent (${itm?.isOffByStudent === true ? 'By Student' : 'By Tutor'})` : itm?.isAttendance ? 'Present' : '-'}
                    </td> */}
                    <td
                      className={`px-4 py-3 text-sm text-gray-900 text-center font-semibold ${
                        itm?.isOffByStudent === false
                          ? 'text-red-500'
                          : 'text-green-500'
                      }`}
                    >
                      {itm?.isOffByStudent === false ? 'Missed' : 'Present'}
                    </td>
                    <td className='px-4 py-3 text-sm text-gray-900 text-center'>
                      {itm?.anyDispute ?? 'N/A'}
                    </td>
                    <td className='px-4 py-3 text-sm text-gray-900 text-center'>
                      {moment(itm?.createdAt).format('DD-MMM-YYYY')}
                    </td>
                    <td className='px-4 py-3 text-sm text-gray-900 text-center'>
                      {itm?.remark ? itm.remark : 'N/A'}
                    </td>
                    <td className='px-4 py-3 text-sm text-gray-900 text-center'>
                      <button
                        className='hover:underline'
                        onClick={() => handleChangeAttendance(itm)}
                      >
                        <BiSolidCalendarEdit size={24} />{' '}
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={7}
                    className='px-6 py-4 text-center text-gray-900 sm:px-4 sm:py-2'
                  >
                    Data Not Available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {changeAttendance && (
          <>
            <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40'>
              <div className='relative w-11/12 mx-auto lg:w-[600px]'>
                <button
                  className='z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center'
                  onClick={handleClose}
                >
                  <span>
                    <MdOutlineClose />
                  </span>{' '}
                  Close
                </button>
                <div className='form_class border-0 rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
                  <div className='flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t'>
                    <h2 className='text-lg font-semibold'>Mark Attendance </h2>
                  </div>
                  <div className='relative lg:h-[200px] overflow-y-auto'>
                    <form onSubmit={handleMarkSubmit} className='space-y-2 p-4'>
                      <div>
                        <label
                          htmlFor='subjectName'
                          className='block text-sm font-medium text-gray-700'
                        >
                          Remark
                        </label>
                        <textarea
                          type='text'
                          id='remark'
                          value={remark}
                          onChange={e => setRemark(e.target.value)}
                          className='mt-1 p-2 border block w-full border-gray-300 rounded-md'
                          required
                        />
                      </div>
                      <div className='flex justify-center items-center pt-2'>
                        <button
                          type='submit'
                          className='w-60 mx-auto bg-[#023565] text-white font-semibold py-2 px-4 rounded-full '
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        <section className='space-y-5 lg:hidden block'>
          {absentTodayDataList && absentTodayDataList.length > 0 ? (
            absentTodayDataList?.map((itm, id) => (
              <div
                key={id}
                className=' bg-white p-4 border-[#033565] border rounded-[10px]'
              >
                <>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[16px] font-[700] text-left'>
                        Parent Name
                      </p>
                    </div>
                    <div className='w-full'>
                      <div className='flex items-center  gap-2 capitalize'>
                        {itm?.parentId && itm?.parentId?.image ? (
                          <img
                            alt=''
                            src={
                              itm?.parentId && itm?.parentId?.image
                                ? itm?.parentId?.image
                                : ''
                            }
                            className='rounded  max-w-[50px] max-h-[50px] min-w-[50px] min-h-[50px]'
                          />
                        ) : (
                          <div className='bg-gray-300 rounded h-14 w-14 flex justify-center items-center'>
                            <span className='text-base font-semibold text-[#033565]'>
                              {' '}
                              {itm?.parentId?.name
                                ? itm.parentId.name
                                    .split(' ')
                                    .map(name => name.charAt(0))
                                    .join('')
                                : ''}
                            </span>
                          </div>
                        )}
                        <div>
                          <p className='text-[12px] font-semibold whitespace-nowrap text-[#023565]'>
                            {/* {itm?.parentId?.name ?? 'Not Available'} */}
                            {truncateText(itm?.parentId?.name, 12) ?? 'N/A'}
                          </p>
                          <p className='text-[12px] font-medium text-gray-500 whitespace-nowrap'>
                            {itm?.parentId?.mobNo ?? 'Not Available'}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[16px] font-[700] text-left'>
                        Student Name
                      </p>
                    </div>
                    <div className='w-full'>
                      <p className='text-[14px] font-[400]'>
                        {Array.isArray(itm?.tuitionId?.bookFreeDemo) ? (
                          itm.tuitionId.bookFreeDemo
                            .map(ele => ele?.studentId?.studentFullName)
                            .filter(name => name)
                            .join(', ')
                        ) : (
                          <small>No students found</small>
                        )}
                      </p>
                    </div>
                  </div>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[16px] font-[700] text-left'>
                        Assigned Tutor
                      </p>
                    </div>
                    <div className='w-full'>
                      <p className='text-[14px] font-[400]'>
                        {itm?.tutorId?.name}
                      </p>
                    </div>
                  </div>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[16px] font-[700] text-left'>
                        Attendance
                      </p>
                    </div>
                    <div className='w-full'>
                      <p
                        className={`text-[14px] font-[800] ${
                          itm?.isOffByStudent === false
                            ? 'text-red-500'
                            : 'text-green-500'
                        }`}
                      >
                        {itm?.isOffByStudent === false ? 'Missed' : 'Present'}
                      </p>
                    </div>
                  </div>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[16px] font-[700] text-left'>
                        Any Dispute
                      </p>
                    </div>
                    <div className='w-full'>
                      <p className='text-[14px] font-[400]'>
                        {itm?.anyDispute ?? 'N/A'}{' '}
                      </p>
                    </div>
                  </div>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[16px] font-[700] text-left'>Date</p>
                    </div>
                    <div className='w-full'>
                      <p className='text-[14px] font-[400]'>
                        {moment(itm?.createdAt).format('DD-MMM-YYYY')}
                      </p>
                    </div>
                  </div>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[16px] font-[700] text-left'>Remark</p>
                    </div>
                    <div className='w-full'>
                      <p className='text-[14px] font-[400]'>
                        {itm?.remark ?? 'N/A'}
                      </p>
                    </div>
                  </div>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[16px] font-[700] text-left'>Action</p>
                    </div>
                    <div className='w-full'>
                      <p className='text-[14px] font-[400]'>
                        <button
                          className='hover:underline'
                          onClick={() => handleChangeAttendance(itm)}
                        >
                          <BiSolidCalendarEdit size={24} color='#023565' />{' '}
                        </button>
                      </p>
                    </div>
                  </div>
                </>
              </div>
            ))
          ) : (
             <div  className=' bg-white p-4 rounded-[10px]'>
            <p className='text-center'>Data Not Found</p>
          </div>
          )}
        </section>
        {totalPages > 1 && (
          <div className='flex justify-end items-center py-2 px-4'>
            <button
              className={`px-4 py-2 border text-white bg-[#023565] rounded ${
                currentPage === 1 ? 'opacity-50 cursor-not-allowed' : ''
              }`}
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
            >
              <FaChevronLeft />
            </button>
            <span className='text-gray-700 mx-1'>
              <span className='border px-4 py-2 text-black  rounded'>
                {currentPage}
              </span>{' '}
              of{' '}
              <span className='border px-4 py-2 text-black  rounded'>
                {totalPages}
              </span>
            </span>
            <button
              className={`px-4 py-2 border text-white bg-[#023565] rounded ${
                currentPage === totalPages
                  ? 'opacity-50 cursor-not-allowed'
                  : ''
              }`}
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              <FaChevronRight />
            </button>
          </div>
        )}
      </div>
    </>
  )
}

export default UnmarkedAttendance
