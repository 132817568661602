import { notificationConstant } from "../_constants";

export default function notificationList(state = {}, action) {
    switch (action.type) {
        case notificationConstant.GET_ADMIN_NOTIFICATION_LIST_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case notificationConstant.GET_ADMIN_NOTIFICATION__LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                notificationList: action?.user

            };
        case notificationConstant.GET_ADMIN_NOTIFICATION__LIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case notificationConstant.CREATE_ADMIN_NOTIFICATION__LIST_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case notificationConstant.CREATE_ADMIN_NOTIFICATION__LIST_SUCCESS:
            return {
                ...state,
                loading: false,


            };
        case notificationConstant.CREATE_ADMIN_NOTIFICATION__LIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };


        case notificationConstant.GET_NOTIFICATION_COUNT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case notificationConstant.GET_NOTIFICATION_COUNT_SUCCESS:
            return {
                ...state,
                loading: false,
                getNotificationCount: action?.user


            };
        case notificationConstant.GET_NOTIFICATION_COUNT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };


















        case notificationConstant.ADMIN_NOTIFICATION_LIST_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case notificationConstant.ADMIN_NOTIFICATION_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                getAdminNotificationList: action?.user


            };
        case notificationConstant.ADMIN_NOTIFICATION_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };



        case notificationConstant.RED_NOTIFICATION_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case notificationConstant.RED_NOTIFICATION_SUCCESS:
            return {
                ...state,
                loading: false,


            };
        case notificationConstant.RED_NOTIFICATION_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };



        default:
            return state;

    }
}