export const classConstants = {
    GET_CLASS_LIST_REQUEST: "GET_CLASS_LIST_REQUEST",
    GET_CLASS_LIST_SUCCESS: "GET_CLASS_LIST_SUCCESS",
    GET_CLASS_LIST_FAILURE: "GET_CLASS_LIST_FAILURE",

    CREATE_CLASS_LIST_REQUEST: "CREATE_CLASS_LIST_REQUEST",
    CREATE_CLASS_LIST_SUCCESS: "CREATE_CLASS_LIST_SUCCESS",
    CREATE_CLASS_LIST_FAILURE: "CREATE_CLASS_LIST_FAILURE",

    UPDATE_CLASS_LIST_REQUEST: "UPDATE_CLASS_LIST_REQUEST",
    UPDATE_CLASS_LIST_SUCCESS: "UPDATE_CLASS_LIST_SUCCESS",
    UPDATE_CLASS_LIST_FAILURE: "UPDATE_CLASS_LIST_FAILURE",

    DELETE_CLASS_LIST_REQUEST: "DELETE_CLASS_LIST_REQUEST",
    DELETE_CLASS_LIST_SUCCESS: "DELETE_CLASS_LIST_SUCCESS",
    DELETE_CLASS_LIST_FAILURE: "DELETE_CLASS_LIST_FAILURE",

    ENA_DIS_CLASS_LIST_REQUEST: "ENA_DIS_CLASS_LIST_REQUEST",
    ENA_DIS_CLASS_LIST_SUCCESS: "ENA_DIS_CLASS_LIST_SUCCESS",
    ENA_DIS_CLASS_LIST_FAILURE: "ENA_DIS_CLASS_LIST_FAILURE",

    GET_ALL_SUBJECT_REQUEST: "GET_ALL_SUBJECT_REQUEST",
    GET_ALL_SUBJECT_SUCCESS: "GET_ALL_SUBJECT_SUCCESS",
    GET_ALL_SUBJECT_FAILURE: "GET_ALL_SUBJECT_FAILURE",

    ENA_DIS_CLASS_REQUEST: "ENA_DIS_CLASS_REQUEST",
    ENA_DIS_CLASS_SUCCESS: "ENA_DIS_CLASS_SUCCESS",
    ENA_DIS_CLASS_FAILURE: "ENA_DIS_CLASS_FAILURE",
}