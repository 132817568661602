/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router';
import FeeHeader from './FeeHeader';
import FeeManagement from './FeeManagement';



const Fee = () => {
  const roleId = JSON.parse(window.sessionStorage.getItem("adminuser"))?.roleAccess?.roleId
  const adminCity = JSON.parse(window.sessionStorage.getItem("adminuser"))?.city
  const [date, setdate] = useState("")
  const location = useLocation();
  const navigate = useNavigate()
  const [active, setActive] = useState("all")
  const [mainActive, setMainActive] = useState("Student")
  const activeStatus = null
  const activeUserType = ""
  const [headerCityId, setHeaderCityId] = useState(roleId !== 1 ? adminCity && adminCity.length ? adminCity[0] : "" : "")
  const [searchText, setSearchText] = useState("");
  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };


  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const newTab = queryParams.get('filter');
    const activeTab = queryParams.get('tab');
    if (newTab && activeTab) {
      setMainActive(newTab)
      navigate(`?filter=${newTab}&tab=${activeTab}`);
    }
    else {
      setActive(newTab)
      navigate(`?filter=${mainActive}&tab=all`);
    }

  }, []);





  return (
    <>
      <FeeHeader setdate={setdate} date={date} active={active} activeStatus={activeStatus} activeUserType={activeUserType} headerCityId={headerCityId} setHeaderCityId={setHeaderCityId} searchText={searchText} handleSearch={handleSearch} roleId={roleId} />

      <div className='overflow-hidden overflow-y-auto'>
        <FeeManagement headerCityId={headerCityId} searchText={searchText} setdate={setdate} date={date} />
      </div>
    </>
  )
}

export default Fee