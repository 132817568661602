import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { feeAction } from "../../../_actions/fee.action";
import { useLocation } from "react-router-dom";
import { FaEye } from "react-icons/fa";
import { downloadCSV } from "../../../_helpers/auth-header"
import { IoArrowBack } from "react-icons/io5";
import FeeReferenceHeader from './EarningHeader/FeeReferenceHeader'
import { MdOutlineClose, MdOutlineKeyboardArrowLeft } from 'react-icons/md';
import { FaChevronRight } from 'react-icons/fa';
import Loader from '../../../components/Loader';
const ReferencePaid = () => {
  const dispatch = useDispatch();
  const selector = useSelector((state) => state);
  let referencePaidListData = selector?.Fee?.referencePaidList?.data?.list
  let allReferencePaid = selector?.Fee?.allReferencePaid?.data
  const location = useLocation();
  const feeReferenceData = location.state?.feeReferenceData;
  let monthId = feeReferenceData?.month
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const pageSize = 10;

  useEffect(() => {

    let referencePaid = {
      "cityId": "",
      "month": monthId,
      "keyWord": "",
      "pageNo": currentPage,
      "size": pageSize
    }
    let referencePaid1 = {
      "cityId": "",
      "month": monthId
    }
    dispatch(feeAction.getReferencePaidList(referencePaid))
    dispatch(feeAction.getAllReferencePaid(referencePaid1))
  }, [currentPage])

  const fields = ['Parent Name', 'Mobile Number', 'Student / Class', 'Fee', 'Created At', 'Time'];
  const convertToCSV = (data) => {
    const array = typeof data !== 'object' ? JSON.parse(data) : data;
    let csv = fields.join(',') + '\r\n';

    array?.forEach(item => {
      const parentName = item.parentId ? item.parentId.name : '-';
      const mobNo = item.parentId ? item.parentId.mobNo : '-';
      const studentsClasses = item && item.bookFreeDemo ? item.bookFreeDemo.map(demo => {
        const subjects = demo.subjectId ? demo.subjectId.map(sub => sub.name).join(',') : '-';
        const className = demo.classId ? demo.classId.name : '-';
        return `${subjects} (${className})`;
      }).join(' | ') : 'N/A';

      const fee = item?.tuitionFee ?? '';
      const createdAt = item.createdAt ? new Date(item.createdAt).toLocaleString() : '';

      const row = [parentName, mobNo, studentsClasses, fee, createdAt].join(',');
      csv += row + '\r\n';
    });

    return csv;
  };

  const handleBack = () => {
    window.history.back();
  };

  const [fullDetailsModal, setfullDetailsModal] = useState(false);
  const [fullDetailsModalData, setfullDetailsModalData] = useState("");
  const handleFullDetails = (data) => {

    setfullDetailsModal(true);
    setfullDetailsModalData(data);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };
  useEffect(() => {
    if (selector?.Fee?.referencePaidList?.data?.total) {
      setTotalPages(Math.ceil(selector?.Fee?.referencePaidList?.data?.total / pageSize));
    }
  }, [selector]);



  return (
    <>
      <FeeReferenceHeader />
      <section className='overflow-y-auto overflow-hidden'>
        <div className='m-5'>
          <Loader loading={selector?.Fee?.loading} />

          <div className="flex justify-between">
            <div className="flex gap-2">
              <IoArrowBack
                className="text-[#023565] text-3xl border rounded-full border-black my-2 mx-3 cursor-pointer bg-white"
                onClick={handleBack}
              />
            </div>
            <button className="bg-white px-3 py-2 border flex justify-between rounded-lg" onClick={() => downloadCSV(convertToCSV(allReferencePaid))}>
              <span>Export</span>  <img src="/export 1.png" alt='' />
            </button>
          </div>
          <div class="relative overflow-x-auto bg-white rounded-lg border border-[#D3D3D3]">
            <table class="w-full  text-left rtl:text-right bg-white text-[#313131]">
              <thead class=" ">
                <tr className="capitalize border-b">
                  <th scope="col" class="px-6 py-3 whitespace-nowrap">
                    Parent name
                  </th>
                  <th scope="col" class="px-6 py-3 whitespace-nowrap">
                    subject
                  </th>
                  <th scope="col" class="px-6 py-4 whitespace-nowrap">
                    Tuition Fee
                  </th>
                  <th scope="col" class="px-6 py-4 whitespace-nowrap">
                    Reference Fee
                  </th>
                  <th scope="col" class="px-6 py-4 whitespace-nowrap">
                    Tutor Fee
                  </th>
                  <th scope="col" class="px-6 py-4 whitespace-nowrap">
                    Class Reference
                  </th>
                </tr>
              </thead>
              <tbody>
                {referencePaidListData &&
                  referencePaidListData?.map((item, id) => (
                    <tr class="capitalize  border-b dark:border-gray-700 forfontsmall">
                      <th
                        scope="row"
                        class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                      >
                        <div className="flex justify-start place-items-center gap-3 pla">
                          <div>
                            <img
                              src={
                                item?.parentId?.image ??
                                "https://thumbs.dreamstime.com/z/vector-illustration-avatar-dummy-logo-collection-image-icon-stock-isolated-object-set-symbol-web-137160339.jpg?ct=jpeg"
                              }
                              alt=''
                              className="rounded-full my-3 max-w-[40px] max-h-[40px] min-w-[40px] min-h-[40px]"
                            />
                          </div>
                          <div>
                            <p>{item?.parentId?.name}</p>
                            <p>{item?.parentId?.mobNo}</p>
                          </div>

                        </div>
                      </th>
                      {/* <td class="px-6 py-4">
                      {item?.bookFreeDemo?.map((subj, id) => (
                        <p key={id}>
                          {subj?.subjectId?.map((e) => e?.name).join(', ')}
                        </p>
                      ))}
                    </td>*/}
                      <td class="px-6 py-4">
                        <FaEye className="text-[#023565] text-[24px] cursor-pointer hover:text-[#C20E6F]"
                          onClick={() => handleFullDetails(item)}
                        />
                      </td>
                      <td class="px-6 py-4">{item?.tuitionFee ?? "0"}</td>
                      <td class="px-6 py-4">{item?.referenceFee ?? "0"}</td>

                      <td class="px-6 py-4">{item?.tutorFee ?? "0"}</td>

                      <td class="px-6 py-4 text-green-700 font-semibold">
                        {item?.classReference}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div>
            {totalPages > 1 && (
              <div className="flex justify-end items-center py-2">
                <button
                  className={`px-4 py-2 border text-black rounded ${currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""}`}
                  onClick={handlePreviousPage}
                  disabled={currentPage === 1}
                >
                  <MdOutlineKeyboardArrowLeft />
                </button>
                <span className="text-gray-700 mx-1">
                  <span className="border px-4 py-2 text-black rounded">{currentPage}</span> of {totalPages}
                </span>
                <button
                  className={`px-4 py-2 border text-black rounded ${currentPage === totalPages ? "opacity-50 cursor-not-allowed" : ""}`}
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                >
                  <FaChevronRight />
                </button>
              </div>
            )}
          </div>
        </div>
        {fullDetailsModal && (
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40">
            <div className="relative w-11/12 mx-auto lg:w-[800px]">
              <div className="flex justify-end">
                <button
                  onClick={() => setfullDetailsModal(false)}
                  className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center"
                >
                  <MdOutlineClose /> Close
                </button>
              </div>
              <div className="form_class border-0 overflow-hidden overflow-x-auto rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h2 className="text-lg font-semibold">Student Details </h2>
                </div>
                <div className="overflow-x-auto">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">
                          Student Name
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">
                          Class
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">
                          Subject
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">
                          Board
                        </th>

                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {fullDetailsModalData?.bookFreeDemo?.map((ele, index) => (
                        <tr className="capitalize" key={index}>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 flex gap-2 justify-start place-items-center">
                            <img
                              alt=""
                              src={
                                ele?.studentId?.image
                                  ? ele?.studentId?.image
                                  : "https://thumbs.dreamstime.com/z/vector-illustration-avatar-dummy-logo-collection-image-icon-stock-isolated-object-set-symbol-web-137160339.jpg?w=768"
                              }
                              className="rounded-full my-3 max-w-[40px] max-h-[40px] min-w-[40px] min-h-[40px]"
                            />
                            {ele?.studentId?.studentFullName ?? "N/A"}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {ele?.classId?.name ?? "N/A"}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {ele?.subjectId?.map((subject, subIndex) => (
                              <div key={subIndex}>{subject?.name}</div>
                            ))}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {ele?.board?.name ?? "N/A"}
                          </td>

                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
    </>
  )
}

export default ReferencePaid
