import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cityAction } from "../../../_actions/city.action";
import { useNavigate, useParams } from "react-router";
import { notificationAction } from "../../../_actions";
import HeaderProfileComp from "../../../components/Header/HeaderProfileComp";
import SearchIconComponent from "../../../components/Header/SearchIconComponent";
import { FaAngleRight } from "react-icons/fa";
import Paths from "./Paths.json"
import { GoUnread } from "react-icons/go";


const DashBoardHeader = () => {
  const dispatch = useDispatch();
  const selector = useSelector((state) => state);
  const params = useParams();
  const [darkMode, setDarkMode] = useState(false);
  const [notificationCount, stNotificationCount] = useState('')
  const [text, setText] = useState("")
  const [path, setPath] = useState([])
  const navigate = useNavigate()
  useEffect(() => {
    let cityDetails = {
      keyWord: "",
      fromDate: "",
      toDate: "",
      sortOrder: "asc",
      sortBy: "createdAt",
      pageNo: 1,
      size: 10,
    };

    setDarkMode(() => {
      return JSON.parse(window.sessionStorage.getItem("management"));
    });
    dispatch(cityAction.cityGet(cityDetails));
    dispatch(notificationAction.notificationCount())
  }, [dispatch]);

  useEffect(() => {
    stNotificationCount(selector?.notificationList?.getNotificationCount?.data)
  }, [selector]);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
    if (darkMode === true) {
      navigate("/app/dashboard-cb");
    } else {
      navigate("/app/dashboard");
    }
    window.sessionStorage.setItem("management", !darkMode);
  };




  const handleNotification = (location) => {
    navigate(location)
  }
  const searchByLabel = (array, searchTerm) => {
    const term = searchTerm.toLowerCase();
    return array.filter(item => item.label.toLowerCase().includes(term));
  }

  useEffect(() => {
    if (text !== "") {
      setPath(() => searchByLabel(Paths, text))

    } else {
      setPath([])
    }

  }, [text])


  return (
    <Fragment>
      <section className=" bg-[#ffffff] lg:block hidden border-r border pl-4 h-20">
        <div className="w-full py-3 px-10 flex justify-between items-center">
          <div className="flex items-center gap-10">
            <div>
              <h1 className="text-2xl font-[800] uppercase">Dashboard</h1>
              {params["*"] === "dashboard" && (
                <p className="text-[#7E7E7E]">Management Model</p>
              )}
            </div>
            <div>
              {JSON.parse(window.sessionStorage.getItem("adminuser"))
                ?.roleAccess?.roleId === 1 && (
                  <label className="inline-flex items-center cursor-pointer">
                    <input
                      type="checkbox"
                      value={darkMode}
                      className="sr-only peer"
                      onChange={toggleDarkMode}
                    />
                    <div className="relative w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-[#7E7E7E] peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600" />
                  </label>
                )}
            </div>
          </div>
          <div className="flex items-center gap-6 relative">
            <SearchIconComponent handleSearch={(e) => setText(e.target.value)} searchText={text} />
            {text &&
              <div className="absolute w-72 bg-white border border-gray-300 rounded-lg shadow-lg z-10 top-10 over">
                {path.length ?
                  <ul className="divide-y divide-gray-300 text-[#023565]">
                    {path.slice(0, 5).map((item, index) => (
                      <li
                        className="flex justify-between items-center cursor-pointer hover:bg-gray-100 hover:text-[#E4006F]  p-3 rounded-t-lg" key={index}
                        onClick={() => handleNotification(item.location)}
                      >
                        <span className=" text-sm font-semibold">
                          {item.label}
                        </span>
                        <FaAngleRight className="hover:text-[#C60E6B]" />
                      </li>
                    ))}
                  </ul>
                  : <li
                    className="flex justify-between items-center cursor-pointer hover:bg-gray-100 hover:text-[#E4006F]  p-3 rounded-t-lg" >
                    <span className=" text-sm font-semibold">
                      No matches.
                    </span>
                  </li>}
              </div>
            }
            <div className="flex relative city_select cursor-pointer items-center">
              <span className="mx-2">
                <GoUnread className="w-7 h-7 text-[#033565]" onClick={() => handleNotification("/app/NotificationList")} />
                {notificationCount > 0 && (
                  <span className="absolute flex justify-center items-center w-5 h-5 text-center -top-2 -right-1 bg-red-600 text-white rounded-full text-xs">
                    <span className="p-1 font-semibold">
                      {notificationCount}
                    </span>
                  </span>
                )}
              </span>
            </div>
          </div>
          <HeaderProfileComp />
        </div>
      </section>

      <section className="bg-white lg:hidden block">
        {/* <div className="flex justify-between items-center px-4">
          <div></div>
          <div className="mobile_view_header_img flex justify-center items-center"></div>
          <div>
            <div class="search-container">
              <form action="/search" method="get">
                <input
                  class="search expandright placeholder-white"
                  id="searchright"
                  type="search"
                  name="q"
                  placeholder="Search"
                />
                <label class="button-mob searchbutton" for="searchright">
                  <span class="mglass">&#9906;</span>
                </label>
              </form>
            </div>
          </div>
        </div> */}
        <div className="flex justify-between items-center px-4">
          <div className="mobile_view_header_img flex justify-start items-start"></div>
          <div>
            <div>
              <div class="search-container">
                <form action="/search" method="get">
                  <input
                    class="search expandright placeholder-white"
                    id="searchright"
                    type="search"
                    name=""
                    placeholder="Search"
                  />
                  <label class="button-mob searchbutton" for="searchright">
                    <span class="mglass">&#9906;</span>
                  </label>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>



    </Fragment>
  )
}

export default DashBoardHeader
