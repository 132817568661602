/* eslint-disable no-unused-vars */
import React from 'react';
import { IoIosCloseCircle, IoIosAddCircle } from 'react-icons/io';
import { MdOutlineClose } from 'react-icons/md';
import Select from "react-select";
import Multiselect from "multiselect-react-dropdown";

const RoleUpdateModal = ({
  roleUpdateModal,
  closeModal,
  editRole,
  setEditRole,
  allModule,
  options,
  selectedItems,
  setSelectedItems,
  submitUpdateData,
  customStyles,
  sidebarItems,
  errors,
}) => {
  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setEditRole({ ...editRole, [name]: value });
  };

  const handleModuleChange = (index, value) => {
    const updatedModules = [...editRole.accessModule];
    updatedModules[index].name = value;
    setEditRole({ ...editRole, accessModule: updatedModules });
  };

  const onSelect2 = (selectedList, selectedItem, index) => {
    const updatedModules = [...editRole.accessModule];
    updatedModules[index] = {
      ...updatedModules[index],
      access: selectedList.map((item) => item.label),
    };
    setEditRole({ ...editRole, accessModule: updatedModules });
  };

  const onRemove2 = (selectedList, removedItem, index) => {
    const updatedModules = [...editRole.accessModule];
    updatedModules[index] = {
      ...updatedModules[index],
      access: selectedList.map((item) => item.label),
    };
    setEditRole({ ...editRole, accessModule: updatedModules });
  };

  const removeModule = (index) => {
    const updatedModules = [...editRole.accessModule];
    updatedModules.splice(index, 1);
    setEditRole({ ...editRole, accessModule: updatedModules });
  };

  const addModule = () => {
    const newModule = { name: '', access: [] };
    setEditRole({ ...editRole, accessModule: [...editRole.accessModule, newModule] });
  };

  return (
    roleUpdateModal && (
      <>



        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40">
          <div className="relative w-11/12 mx-auto lg:w-[600px]">
            <button
              className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center"
              onClick={closeModal}
            >
              <span><MdOutlineClose /></span> Close
            </button>
            <div className="form_class border-0 rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              <div className="flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                <h2 className="text-lg font-semibold">Update Role</h2>
              </div>
              <div className="relative">
                <form className="p-4 space-y-2">
                  <div className="mb-4">
                    <label
                      htmlFor="role_name"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Role Name
                    </label>
                    <input
                      type="text"
                      id="role_name"
                      name="name"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      placeholder="Update Role Name"
                      required
                      value={editRole.name}
                      onChange={handleOnChange}
                    />
                    {errors.name && <p className="text-red-500 text-sm">{errors.name}</p>}

                  </div>
                  {/* <div>
                    <Select
                      id="permission"
                      name="permission"
                      placeholder="Select permissions..."
                      value={editRole.permissions}
                      options={sidebarItems.map((item) => ({
                        value: item,
                        label: item.replace(/_/g, ' '), // Replacing underscores with spaces
                      }))}
                      className="capitalize text-black "
                      styles={customStyles}
                      classNamePrefix="react-select"
                      onChange={(selectedOption, actionMeta) => {
                        setEditRole({ ...editRole, ["permissions"]: selectedOption });
                      }}
                      isMulti
                    />
                    {errors.sidebarPermission && <p className="text-red-500 text-sm">{errors.sidebarPermission}</p>}

                  </div> */}

                  <div>
                    <label
                      htmlFor="permission"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Select Permissions
                    </label>
                    <Multiselect
                      options={sidebarItems.map((item) => ({
                        value: item,
                        label: item.replace(/_/g, ' '), // Replacing underscores with spaces
                      }))}
                      selectedValues={editRole.permissions}
                      onSelect={(selectedList) =>
                        setEditRole({ ...editRole, permissions: selectedList })
                      }
                      onRemove={(removedList) =>
                        setEditRole({ ...editRole, permissions: removedList })
                      }
                      displayValue="label"
                      className="capitalize text-black"
                      style={customStyles}
                    />
                    {errors.sidebarPermission && (
                      <p className="text-red-500 text-sm">{errors.sidebarPermission}</p>
                    )}
                  </div>

                  <div className="flex justify-center">
                    <button
                      type="button"
                      className="w-60 mx-auto bg-[#023565] text-white font-semibold py-2 px-4 rounded-full "
                      onClick={submitUpdateData}
                    >
                      Update Role
                    </button>
                  </div>
                </form>
              </div>

            </div>
          </div>
        </div>
      </>
    )
  );
};

export default RoleUpdateModal;
