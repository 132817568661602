import Multiselect from 'multiselect-react-dropdown'
import React from 'react'
import { FaPlusCircle } from 'react-icons/fa'
import { IoIosRemoveCircleOutline } from 'react-icons/io'
import { MdOutlineClose } from 'react-icons/md'

function AddCity({ modal, closeModal, handleSubmit, name, selectedOptions, errorData, setErrorData, setName, options, setSelectedOptions, pinCodeState, inputFields, handleInputChange, handleRemove, addSelect, management, setManagement, handleOnChange, setPackageNotice, packageNotice, pckgid, selectedAmount,packageList }) {

    return (
        modal && (
            <>
                <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40">
                    <div className="relative w-11/12 mx-auto lg:w-[600px]">
                        <button
                            className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center"
                            onClick={closeModal}
                        >
                            <span><MdOutlineClose /></span> Close
                        </button>
                        <div className="form_class border-0 rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                            <div className="flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                <h2 className="text-lg font-semibold">New City</h2>
                            </div>
                            <div className="relative lg:max-h-[550px] h-[400px] overflow-y-auto">
                                <form onSubmit={handleSubmit} className="space-y-2 p-4">
                                    <div className="space-y-2">
                                        <label htmlFor="name" className="block text-sm font-medium text-gray-700">City Name</label>
                                        <input
                                            type="text"
                                            id="name"
                                            className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                                            placeholder="Enter city name..."
                                            value={name}
                                            onChange={(e) => {
                                                setName(e.target.value);
                                                if (errorData && errorData['name']) {
                                                    setErrorData((prevErrorData) => ({ ...prevErrorData, name: null }))
                                                }
                                            }}

                                        />
                                        {
                                            errorData && errorData['name'] ? <p className='text-red-600 text-sm'>{errorData['name']}</p> : null
                                        }
                                    </div>
                                    <div className="space-y-2">
                                        <label htmlFor="tuitionMode" className="block text-sm font-medium text-gray-700">Tuition Mode</label>
                                        <Multiselect
                                            options={options}
                                            selectedValues={selectedOptions}
                                            onSelect={(selectedList) => {
                                                setSelectedOptions(selectedList);
                                                if (errorData && errorData['selectedOptions']) {
                                                    setErrorData((prevErrorData) => ({ ...prevErrorData, selectedOptions: null }))
                                                }
                                            }}
                                            onRemove={(selectedList) => setSelectedOptions(selectedList)}
                                            displayValue="label"
                                            required
                                            className=" border-[#D9E1EC] text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full"
                                        />
                                        {
                                            errorData && errorData['selectedOptions'] ? <p className='text-red-600 text-sm'>{errorData['selectedOptions']}</p> : null
                                        }
                                    </div>

                                    {selectedOptions.find((ele) => ele.value === "HOME_TUITION") && pinCodeState && (
                                        <div className="space-y-2">
                                            <label htmlFor="pincode" className="block text-sm font-medium text-gray-700">Pincode</label>
                                            <div className="space-y-2">
                                                {Array(Math.ceil(inputFields.length / 2)).fill(0).map((_, rowIndex) => (
                                                    <div key={rowIndex} className="flex justify-between place-items-center gap-4 w-full mb-2">
                                                        {inputFields.slice(rowIndex * 2, rowIndex * 2 + 2).map((inputField, index) => (
                                                            <div key={index} className="flex items-center space-x-2">
                                                                <input
                                                                    type="number"
                                                                    value={inputField.value}
                                                                    onChange={(event) => {
                                                                        handleInputChange(rowIndex * 2 + index, event);
                                                                        if (errorData && errorData[`inputField_${rowIndex * 2 + index}`]) {
                                                                            setErrorData((prevErrorData) => ({ ...prevErrorData, [`inputField_${rowIndex * 2 + index}`]: null }))
                                                                        }
                                                                    }}
                                                                    className="mb-2 p-2 border border-gray-300 rounded"
                                                                />
                                                                {errorData && errorData[`inputField_${rowIndex * 2 + index}`] && (
                                                                    <p className='text-red-600 text-sm'>{errorData[`inputField_${rowIndex * 2 + index}`]}</p>
                                                                )}
                                                                <IoIosRemoveCircleOutline
                                                                    className="text-lg text-red-800 hover:text-red-900 cursor-pointer transition duration-300 ease-in-out"
                                                                    onClick={() => handleRemove(rowIndex * 2 + index)}
                                                                />

                                                            </div>
                                                        ))}
                                                    </div>
                                                ))}
                                                <button
                                                    type="button"
                                                    onClick={addSelect}
                                                    className="text-lg text-gray-500 hover:text-gray-700 transition duration-300 ease-in-out"
                                                >
                                                    <FaPlusCircle className="cursor-pointer text-lg" />
                                                </button>
                                            </div>
                                        </div>
                                    )}

                                    <div className="space-y-2">
                                        <label htmlFor="management" className="block text-sm font-medium text-gray-700"> Work Mode</label>
                                        <select
                                            id="management"
                                            required
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                            value={management}
                                            onChange={(e) => {
                                                setManagement(e.target.value);
                                                if (errorData && errorData['management']) {
                                                    setErrorData((prevErrorData) => ({ ...prevErrorData, management: null }))
                                                }
                                            }}
                                        >
                                            <option>Select</option>
                                            <option value="Full Management">Full Management</option>
                                            <option value="Credit Based">Credit Based</option>
                                        </select>
                                        {
                                            errorData && errorData['management'] ? <p className='text-red-600 text-sm'>{errorData['management']}</p> : null
                                        }
                                    </div>
                                    {management === "Full Management" && <div className="space-y-2">
                                        <div className="space-y-2">
                                            <label htmlFor="package" className="block text-sm font-medium text-gray-700">Package Name</label>
                                            <select
                                                id="package"
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 capitalize"
                                                onChange={handleOnChange}
                                                value={pckgid}
                                            >
                                                <option value={null}>Select Package</option>
                                                {packageList.map((item, id) => (
                                                    <option key={id} value={item._id}>
                                                        {item.name}
                                                    </option>
                                                ))}
                                            </select>
                                            {
                                                errorData && errorData['packageName'] ? <p className='text-red-600 text-sm'>{errorData['packageName']}</p> : null
                                            }
                                        </div>


                                        <div className="space-y-2">
                                            <label htmlFor="platformFee" className="block text-sm font-medium text-gray-700">Platform Fee</label>
                                            <input
                                                type="text"
                                                required
                                                disabled
                                                id="platformFee"
                                                value={selectedAmount}
                                                className=" border border-[#D9E1EC] text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                            />

                                        </div>

                                        <div className="space-y-2">
                                            <label htmlFor="packageNotice" className="block text-sm font-medium text-gray-700">Package Notice</label>
                                            <input
                                                type="text"
                                                id="packageNotice"

                                                value={packageNotice}
                                                onChange={(e) => { setPackageNotice(e.target.value) }}
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                                placeholder="Package notice..."

                                            />

                                        </div>
                                    </div>
                                    }
                                    <div className="flex justify-center pt-2">
                                        <button
                                            type="submit"
                                            className="w-60 mx-auto bg-[#023565] text-white font-semibold py-2 px-4 rounded-full "
                                        >
                                            Add City
                                        </button>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    )
}

export default AddCity
