/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { tutortoliveAction } from "../../_actions";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import { FaChevronRight } from "react-icons/fa";


const NewJoinTutorList = () => {

  const dispatch = useDispatch();
  const selector = useSelector(state => state);
  let [newJoinedTutorList, setNewJoinedTutor] = useState([])
  const [selectedPerson, setSelectedPerson] = useState(null);
  const [tutorData, setTutorData] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const pageSize = 10;


  useEffect(() => {
    let newJoindData = {
      "status": "NEW_TUTOR",
      "keyWord": "",
      "fromDate": "",
      "toDate": "",
      "sortOrder": "",
      "sortBy": "asc",
      pageNo: currentPage,
      size: pageSize,
    }
    dispatch(tutortoliveAction.getNewlyJoinTutorList(newJoindData))
  }, [currentPage]);

  useEffect(() => {
    if (selector?.TutorToLive?.getNewJoinTutorList?.data?.total) {
      setTotalPages(Math.ceil(selector?.TutorToLive?.getNewJoinTutorList?.data.total / pageSize));
    }
    setNewJoinedTutor(selector?.TutorToLive?.getNewJoinTutorList?.data?.demoList)
  }, [selector]);

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  return (

    <>
      <div>
        <p className="text-center pt-10 font-bold text-[#00AFE2]">All Tutor</p>
      </div>
      <div className="m-4 bg-white border border-[#D3D3D3] overflow-auto rounded-lg">
        <table className="w-full">
          <thead>
            <tr className="border-b">
              <th className="px-6 py-3 text-black tracking-wider">
                Name
              </th>
              <th className="px-6 py-3 text-black tracking-wider">
                Status
              </th>
              <th className="px-6 py-3 text-black tracking-wider">
                City
              </th>
              <th className="px-6 py-3 text-black tracking-wider">
                Address
              </th>
            </tr>
          </thead>
          <tbody>
            {newJoinedTutorList && newJoinedTutorList.map((tutor, id) => (
              <tr key={id} className="text-center capitalize border-b">
                <td className="px-6 py-3 whitespace-nowrap">
                  {tutor.name}
                </td>
                <td className={`px-6 py-3 whitespace-nowrap font-semibold ${tutor.kycStatus ? 'text-[#00AFE2]' : 'text-red-600'}`}>
                  {tutor.kycStatus ? 'Done' : 'Pending'}
                </td>
                <td className="px-6 py-3 whitespace-nowrap">
                  {tutor.city?.name ?? 'No Data'}
                </td>
                <td className="px-6 py-3 whitespace-nowrap">
                  {tutor.address ?? 'No Data'}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {totalPages > 1 &&
          <div className="flex justify-end items-center py-2">
            <button
              className={`px-4 py-2 border  text-black rounded ${currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""}`}
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
            >
              <MdOutlineKeyboardArrowLeft />
            </button>
            <span className="text-gray-700 mx-1">  <span className="border px-4 py-2  text-black rounded">{currentPage}</span>  of {totalPages}</span>
            <button
              className={`px-4 py-2 border  text-black rounded ${currentPage === totalPages ? "opacity-50 cursor-not-allowed" : ""}`}
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              <FaChevronRight />

            </button>
          </div>
        }
      </div>
    </>

  )
}

export default NewJoinTutorList
