import { APIcallFunction,headerForPrivateAPI } from "../_helpers"
import { termsAndConstant } from "../_constants"
import { alertActions } from "./alert.actions";

export const termsAndConditionAction={
   GetTermsAndConditionList,
    CreateTermsAndCondition,
    DeleteTermsAndCondition,
    UpdateTermsAndCondition,
    EnaDisTermsAndCondition
}


function GetTermsAndConditionList(data) {
    
    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: "termsAndCondition/getList"
    };
    return (dispatch) => {
        dispatch(request(data));
        APIcallFunction(credentials).then(
            (users) => {
                
               
                dispatch(success(users))
                
            },
            (error) => {
                
                dispatch(failure(error))
            }
        )

    }


    function request() { return { type: termsAndConstant.GET_TERMSANDCONDITION_LIST_REQUEST } }
    function success(users) { return { type: termsAndConstant.GET_TERMSANDCONDITION_LIST_SUCCESS, users } }
    function failure(error) { return { type: termsAndConstant.GET_TERMSANDCONDITION_LIST_FAILURE, error } }


};

function DeleteTermsAndCondition(data,listData) {
    
    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: "termsAndCondition/delete "
    };
    return (dispatch) => {
        dispatch(request(data));
        APIcallFunction(credentials).then(
            (users) => {
                
               
                dispatch(success(users))
                dispatch(alertActions.success("Success."))
                dispatch(GetTermsAndConditionList(listData))
            },
            (error) => {
                dispatch(alertActions.error("Please try again later."))

                dispatch(failure(error))
            }
        )

    }


    function request() { return { type: termsAndConstant.DELETE_TERMSANDCONDITION_LIST_REQUEST } }
    function success(users) { return { type: termsAndConstant.DELETE_TERMSANDCONDITION_LIST_SUCCESS, users } }
    function failure(error) { return { type: termsAndConstant.DELETE_TERMSANDCONDITION_LIST_FAILURE, error } }


};

function CreateTermsAndCondition(data,listData) {
    
    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: "termsAndCondition/create"
    };
    return (dispatch) => {
        dispatch(request(data));
        APIcallFunction(credentials).then(
            (users) => {
                
               
                dispatch(success(users))
                dispatch(alertActions.success("Success."))
                dispatch(GetTermsAndConditionList(listData))
            },
            (error) => {
                
                dispatch(failure(error))
                dispatch(alertActions.error("Please try again later."))

            }
        )

    }


    function request() { return { type: termsAndConstant.CREATE_TERMSANDCONDITIONT_LIST_REQUEST } }
    function success(users) { return { type: termsAndConstant.CREATE_TERMSANDCONDITION_LIST_SUCCESS, users } }
    function failure(error) { return { type: termsAndConstant.CREATE_TERMSANDCONDITION_LIST_FAILURE, error } }


};


function UpdateTermsAndCondition(data,listData) {
    
    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: "termsAndCondition/update"
    };
    return (dispatch) => {
        dispatch(request(data));
        APIcallFunction(credentials).then(
            (users) => {
                
               
                dispatch(success(users))
                dispatch(alertActions.success("Success."))
                dispatch(GetTermsAndConditionList(listData))
            },
            (error) => {
                dispatch(alertActions.error("Please try again later."))
                
                dispatch(failure(error))
            }
        )

    }


    function request() { return { type: termsAndConstant.UPDATE_TERMSANDCONDITION_LIST_REQUEST } }
    function success(users) { return { type: termsAndConstant.UPDATE_TERMSANDCONDITION_LIST_SUCCESS, users } }
    function failure(error) { return { type: termsAndConstant.UPDATE_TERMSANDCONDITION_LIST_FAILURE, error } }


};


function EnaDisTermsAndCondition(data,listData) {
    
    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: "termsAndCondition/enableDisable"
    };
    return (dispatch) => {
        dispatch(request(data));
        APIcallFunction(credentials).then(
            (users) => {
                
               
                dispatch(success(users))
                dispatch(alertActions.success("Success."))
                dispatch(GetTermsAndConditionList(listData))
            },
            (error) => {
                dispatch(alertActions.error("Please try again later."))

                dispatch(failure(error))
            }
        )

    }


    function request() { return { type: termsAndConstant.ENA_DIS_TERMSANDCONDITION_LIST_REQUEST } }
    function success(users) { return { type: termsAndConstant.ENA_DIS_TERMSANDCONDITION_LIST_SUCCESS, users } }
    function failure(error) { return { type: termsAndConstant.ENA_DIS_TERMSANDCONDITION_LIST_FAILURE, error } }


};