import { positiveNegativeConstant } from "../_constants";

export default function PositiveNegative(state = {}, action) {
    switch (action.type) {
        case positiveNegativeConstant.GET_POSITIVE_NEGATIVE_STATUS_LIST_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case positiveNegativeConstant.GET_POSITIVE_NEGATIVE_STATUS_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                positiveNagativeStatusList:action?.users
            };
        case positiveNegativeConstant.GET_POSITIVE_NEGATIVE_STATUS_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        default:
            return state;
    }
}