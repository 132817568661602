/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { tutionAction } from '../../_actions'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { FaChevronCircleRight, FaChevronLeft } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { FaChevronRight } from 'react-icons/fa'
import { connect } from 'react-redux'
import moment from 'moment'
import './tution.css'

const ButtonSecondTable = props => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const selector = useSelector(state => state)
  const [suggestedTutor, setSuggestedTutor] = useState()
  const [open, setOpen] = useState(false)
  const [assignTutorModal, setAssignTutorModal] = useState(false)
  const [assignTutorList, setAssignTutorList] = useState('')
  const [demoIdForAssign, setDemoIdForAssign] = useState({})
  const [assignTutorTab, setAssignTutorTab] = useState('Suggested Tutors')
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [listPages, setListPages] = useState({})
  const pageSize = 10
  const { filterData } = props
  const [tutorDetailsModal, setTutorDetailsModal] = useState(false)
  const [tutorDetailsModalData, setTutorDetailsModalData] = useState({})
  const menuRef = useRef(null)

  const handleClickOutside = event => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setOpen(null)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  let suggestTutorBtn = ['Applicants', 'Suggested Tutors', 'All Tutors']

  useEffect(() => {
    let listData = {
      status: [0, 1, 3],
      parentId: filterData?.length > 0 ? filterData : null,
      keyWord: '',
      fromDate: '',
      toDate: '',
      sortOrder: '',
      sortBy: '',
      pageNo: 1,
      size: 10
    }
    dispatch(tutionAction.getCreditBaseDemoClassListByStatus(listData))
  }, [currentPage])

  useEffect(() => {
    if (props?.getCreditBaseDemoClassList) {
      setListPages(props?.getCreditBaseDemoClassList?.data?.list)
      setTotalPages(
        Math.ceil(props?.getCreditBaseDemoClassList?.data?.total / pageSize)
      )
    }
    setSuggestedTutor(selector?.tution?.suggestedTutorList?.data)
  }, [selector])

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1)
    // filterData
    let listData = {
      status: [0, 1, 3],
      parentId: filterData ?? null,
      keyWord: '',
      fromDate: '',
      toDate: '',
      sortOrder: '',
      sortBy: '',
      pageNo: currentPage - 1,
      size: 10
    }
    dispatch(tutionAction.getCreditBaseDemoClassListByStatus(listData))
  }

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1)
    let listData = {
      status: [0, 1, 3],
      parentId: filterData ?? null,
      keyWord: '',
      fromDate: '',
      toDate: '',
      sortOrder: '',
      sortBy: '',
      pageNo: currentPage + 1,
      size: 10
    }
    dispatch(tutionAction.getCreditBaseDemoClassListByStatus(listData))
  }

  // useEffect(() => {
  //   setTutorAssign(() => selector?.tution?.demoList?.data?.list)
  // }, [selector])

  const openAction = itemId => {
    setOpen(prevItemId => (prevItemId === itemId ? null : itemId))
  }

  const handleAssignTutor = item => {
    setAssignTutorTab(item)
  }

  ///Handle Assign tutor=========================>>>>>>

  const handleAssign = data => {
    let Demodata = {
      status: 0,
      keyWord: '',
      fromDate: '',
      toDate: '',
      sortOrder: '',
      sortBy: '',
      pageNo: 1,
      size: 10
    }
    let assignTutorInfo = {
      demoId: demoIdForAssign,
      tutorId: data
    }
    dispatch(tutionAction.changeTutorByAdmin(assignTutorInfo, Demodata))
  }

  ///handle Reject====================>

  const handleRejectMsg = data => {
    let rejectDataDetails = {
      demoId: data._id
    }
    dispatch(tutionAction.revertDemoClass(rejectDataDetails))
  }

  const viewHistory = data => {
    navigate(`/app/viewhistory/${data?._id}`)
  }
  const handleview = data => {
    navigate(`/app/viewandupdate`, { state: { viewandupdate: data } })
  }
  ///Mark Demo Done=====================>
  const handleMarkDemoDone = data => {
    let Demodata = {
      status: [3],
      keyWord: '',
      fromDate: '',
      toDate: '',
      sortOrder: '',
      sortBy: '',
      pageNo: 1,
      size: 10
    }

    let demoDoneDetails = {
      demoId: data._id
    }
    dispatch(tutionAction.demoDoneByAdmin(demoDoneDetails, Demodata))
  }

  const handleReject = data => {
    let Demodata = {
      status: [3],
      keyWord: '',
      fromDate: '',
      toDate: '',
      sortOrder: '',
      sortBy: '',
      pageNo: 1,
      size: 10
    }
    let rejectData = {
      demoId: data._id,
      status: 'REJECTED'
    }
    dispatch(tutionAction.updateStatusDemoApproveOrReject(rejectData, Demodata))
  }

  const showTutorDetails = data => {
    setTutorDetailsModal(true)
    setTutorDetailsModalData(data)
  }

  return (
    <>
      <section className='lg:block hidden py-10'>
        <div className=' border border-[#D3D3D3] rounded-xl overflow-x-auto'>
          <table className='min-w-full bg-white rounded-xl'>
            <thead className='border-b border-[#D1D1D1]'>
              <tr className='text-[#313131]'>
                <th className='px-6 py-3 whitespace-nowrap text-left'>
                  Parent Name
                </th>
                <th className='px-6 py-3 whitespace-nowrap text-left'>City</th>
                <th className='px-6 py-3 whitespace-nowrap text-left'>
                  Posted On
                </th>
                <th className='px-6 py-3 whitespace-nowrap text-left'>
                  Confirmed Tutor
                </th>
                <th className='px-6 py-3 whitespace-nowrap text-left'>
                  Remark
                </th>
                <th className='px-6 py-3 whitespace-nowrap text-left'>
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {listPages && listPages.length > 0 ? (
                listPages?.map((item, index) => (
                  <tr
                    key={index}
                    className='border-b border-[#D1D1D1] capitalize'
                  >
                    <td className='flex items-center gap-4 px-6 py-4 text-sm text-gray-900 '>
                      <div className='flex items-center justify-center gap-2'>
                        <img
                          className='w-10 h-10 rounded-full'
                          src={
                            item?.parentId?.image ??
                            'https://thumbs.dreamstime.com/z/vector-illustration-avatar-dummy-logo-collection-image-icon-stock-isolated-object-set-symbol-web-137160339.jpg?ct=jpeg'
                          }
                          alt='User'
                        />
                        <div className='w-24'>
                          <p className='font-medium whitespace-nowrap'>
                            {item && item?.parentId?.name
                              ? item?.parentId?.name
                              : '--'}
                          </p>
                          <p className='text-gray-500 whitespace-nowrap'>
                            {item && item?.parentId?.mobNo
                              ? item?.parentId?.mobNo
                              : '--'}
                          </p>
                        </div>
                      </div>
                    </td>

                    <td className='px-6 py-4   text-gray-900'>
                      {item && item?.cityId?.name ? item?.cityId?.name : null}
                    </td>
                    <td className='px-6 py-4   text-gray-900'>
                      {moment(item?.createdAt).format('DD-MMM-YYYY')}
                    </td>

                    <td className='px-6 py-4   text-gray-900'>
                      {item?.tutorId?.name ?? '-'}
                    </td>
                    <td className='px-6 py-4   text-gray-900'>
                      {item?.remark ?? 'N/A'}{' '}
                    </td>
                    <td className='px-6 py-4   text-gray-900'>
                      <BsThreeDotsVertical
                        className='text-2xl text-red-600'
                        onClick={() => openAction(item._id)}
                      />
                      {open === item._id && (
                        <ul className='bg-white border rounded-md w-48 fixed right-8  py-1 text-start  text-sm font-bold shadow-lg'>
                          <li className='flex justify-between px-2 items-center text-[#023565] py-1 border-b'>
                            <button
                              className='w-full text-left cursor-pointer'
                              onClick={() => handleview(item)}
                            >
                              View & Update
                            </button>
                            <div>
                              <img src='/Group 1000001230.png' alt='' />
                            </div>
                          </li>

                          <li
                            className='cursor-pointer px-2  flex justify-between items-center text-[#023565] py-1 border-b'
                            onClick={() => handleRejectMsg(item)}
                          >
                            <button
                              className='w-full text-left'
                              onClick={() => handleReject(item)}
                            >
                              Reject Lead
                            </button>
                            <div>
                              <img src='/Group 1000001230.png' alt='' />
                            </div>
                          </li>

                          <li className='text-[#023565] px-2  flex justify-between items-center py-1 border-b'>
                            <button
                              className='w-full text-left cursor-pointer'
                              onClick={() => handleMarkDemoDone(item)}
                            >
                              Mark As Confirmed
                            </button>
                            <div>
                              <img src='/Group 1000001230.png' alt='' />
                            </div>
                          </li>

                          <li
                            className='relative cursor-pointer px-2 flex justify-between items-center text-[#023565] py-1 border-b'
                            onClick={() => viewHistory(item)}
                          >
                            <button className='w-full text-left'>
                              View History
                            </button>
                            <div>
                              <img src='/Group 1000001230.png' alt='' />
                            </div>
                          </li>
                        </ul>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={11} className='text-center'>
                    Data Not Available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {totalPages > 1 && (
          <div className='flex justify-end items-center py-2 px-4'>
            <button
              className={`px-4 py-2 border text-white bg-[#023565] rounded ${
                currentPage === 1 ? 'opacity-50 cursor-not-allowed' : ''
              }`}
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
            >
              <FaChevronLeft />
            </button>
            <span className='text-gray-700 mx-1'>
              <span className='border px-4 py-2 text-black  rounded'>
                {currentPage}
              </span>{' '}
              of{' '}
              <span className='border px-4 py-2 text-black  rounded'>
                {totalPages}
              </span>
            </span>
            <button
              className={`px-4 py-2 border text-white bg-[#023565] rounded ${
                currentPage === totalPages
                  ? 'opacity-50 cursor-not-allowed'
                  : ''
              }`}
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              <FaChevronRight />
            </button>
          </div>
        )}
      </section>

      {assignTutorModal && (
        <div className='fixed inset-0 bg-gray-500 bg-opacity-50 flex items-center justify-center'>
          <div className='bg-white p-5 rounded-md w-[30%] h-[40%]'>
            <div className='flex justify-end m-2'>
              <button onClick={() => setAssignTutorModal(false)}>Close</button>
            </div>
            <section className='flex justify-evenly'>
              {suggestTutorBtn.map((itm, id) => (
                <div key={id} className='m-2'>
                  <button
                    className={`px-3 text-white rounded-full text-sm py-2 font-bold ${
                      assignTutorTab === itm ? 'bg-[#023565]' : 'bg-[#9C9EAB]'
                    } 
    ${assignTutorTab === itm ? 'hover:bg-[#023565]' : 'hover:bg-[#023565]'}`}
                    onClick={() => handleAssignTutor(itm)}
                  >
                    {itm}
                  </button>
                </div>
              ))}
            </section>

            <section>
              {assignTutorTab === 'Applicants' && (
                <div class=''>
                  <div>
                    <form class='flex items-center max-w-sm mx-auto'>
                      <label for='simple-search' class='sr-only'>
                        Search
                      </label>
                      <div class='relative w-full'>
                        <div class='absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none'></div>
                        <input
                          type='text'
                          id='simple-search'
                          class='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                          placeholder='Search...'
                        />
                      </div>
                      <button
                        type='submit'
                        class='p-2.5 ms-2 text-sm font-medium text-white bg-blue-700 rounded-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800'
                      >
                        <svg
                          class='w-4 h-4'
                          aria-hidden='true'
                          xmlns='http://www.w3.org/2000/svg'
                          fill='none'
                          viewBox='0 0 20 20'
                        >
                          <path
                            stroke='currentColor'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                            stroke-width='2'
                            d='m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z'
                          />
                        </svg>
                        <span class='sr-only'>Search</span>
                      </button>
                    </form>
                  </div>
                  <table className='min-w-full shadow-md rounded-md overflow-hidden'>
                    <thead>
                      {assignTutorList &&
                        assignTutorList.tutorAppliedList &&
                        assignTutorList.tutorAppliedList.map(ele => (
                          <div className='border rounded-xl p-6'>
                            <div className='flex justify-between items-center'>
                              <div className='flex items-center space-x-6'>
                                <div className='flex items-center space-x-3'>
                                  <div>
                                    <img
                                      src={ele && ele.image ? ele.image : null}
                                      className='rounded-full w-10 h-10'
                                      alt=''
                                    />
                                  </div>
                                  <div>
                                    <p>{ele && ele.name ? ele.name : null}</p>
                                    <p>{ele && ele.mobNo ? ele.mobNo : null}</p>
                                  </div>
                                </div>
                                <div>
                                  <FaChevronCircleRight
                                    size={20}
                                    color='#7e57c2'
                                  />
                                </div>
                              </div>
                              <div>
                                <button
                                  className='font-bold text-lg hover:text-black underline'
                                  onClick={() => handleAssign(ele?._id)}
                                >
                                  Assign
                                </button>
                              </div>
                            </div>
                          </div>
                        ))}
                    </thead>
                  </table>
                </div>
              )}
            </section>

            <section>
              {assignTutorTab === 'Suggested Tutors' && (
                <div>
                  <div>
                    <form class='flex items-center max-w-sm mx-auto'>
                      <label for='simple-search' class='sr-only'>
                        Search
                      </label>
                      <div class='relative w-full'>
                        <div class='absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none'></div>
                        <input
                          type='text'
                          id='simple-search'
                          class='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                          placeholder='Search...'
                        />
                      </div>
                      <button
                        type='submit'
                        class='p-2.5 ms-2 text-sm font-medium text-white bg-blue-700 rounded-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800'
                      >
                        <svg
                          class='w-4 h-4'
                          aria-hidden='true'
                          xmlns='http://www.w3.org/2000/svg'
                          fill='none'
                          viewBox='0 0 20 20'
                        >
                          <path
                            stroke='currentColor'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                            stroke-width='2'
                            d='m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z'
                          />
                        </svg>
                        <span class='sr-only'>Search</span>
                      </button>
                    </form>
                  </div>
                  <div className=''>
                    <table className='min-w-full  shadow-md  rounded-md overflow-hidden'>
                      <tbody className='text-gray-600 text-sm font-light'>
                        {suggestedTutor ? (
                          suggestedTutor.map(ele => (
                            <div className='border rounded-xl p-6'>
                              <div className='flex justify-between items-center'>
                                <div className='flex items-center space-x-6'>
                                  <div className='flex items-center space-x-3'>
                                    <div>
                                      <img
                                        src={
                                          ele &&
                                          ele.userDetails &&
                                          ele.userDetails.image
                                            ? ele.userDetails.image
                                            : null
                                        }
                                        className='rounded-full w-10 h-10'
                                        alt=''
                                      />
                                    </div>
                                    <div>
                                      <p>
                                        {ele &&
                                        ele.userDetails &&
                                        ele.userDetails.name
                                          ? ele.userDetails.name
                                          : null}
                                      </p>
                                      <p>
                                        {ele &&
                                        ele.userDetails &&
                                        ele.userDetails.mobNo
                                          ? ele.userDetails.mobNo
                                          : null}
                                      </p>
                                    </div>
                                  </div>
                                  <div>
                                    <FaChevronCircleRight
                                      size={20}
                                      color='#7e57c2'
                                    />
                                  </div>
                                </div>
                                <div>
                                  <button
                                    className='font-bold text-lg hover:text-black underline'
                                    onClick={() =>
                                      handleAssign(ele?.userDetails?._id)
                                    }
                                  >
                                    Assign
                                  </button>
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className='text-black'>No Data</div>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </section>

            <section>
              {assignTutorTab === 'All Tutors' && (
                <div>
                  <div>
                    <form class='flex items-center max-w-sm mx-auto'>
                      <label for='simple-search' class='sr-only'>
                        Search
                      </label>
                      <div class='relative w-full'>
                        <div class='absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none'></div>
                        <input
                          type='text'
                          id='simple-search'
                          class='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                          placeholder='Search...'
                        />
                      </div>
                      <button
                        type='submit'
                        class='p-2.5 ms-2 text-sm font-medium text-white bg-blue-700 rounded-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800'
                      >
                        <svg
                          class='w-4 h-4'
                          aria-hidden='true'
                          xmlns='http://www.w3.org/2000/svg'
                          fill='none'
                          viewBox='0 0 20 20'
                        >
                          <path
                            stroke='currentColor'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                            stroke-width='2'
                            d='m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z'
                          />
                        </svg>
                        <span class='sr-only'>Search</span>
                      </button>
                    </form>
                  </div>
                  <table className='min-w-full shadow-md rounded-md overflow-hidden'>
                    <thead>
                      {assignTutorList && assignTutorList.tutorAppliedList ? (
                        assignTutorList.tutorAppliedList.map(ele => (
                          <div className='border rounded-xl p-6'>
                            <div className='flex justify-between items-center'>
                              <div className='flex items-center space-x-6'>
                                <div className='flex items-center space-x-3'>
                                  <div>
                                    <img
                                      src={ele && ele.image ? ele.image : null}
                                      className='rounded-full w-10 h-10'
                                      alt=''
                                    />
                                  </div>
                                  <div>
                                    <p>{ele && ele.name ? ele.name : null}</p>
                                    <p>{ele && ele.mobNo ? ele.mobNo : null}</p>
                                  </div>
                                </div>
                                <div>
                                  <FaChevronCircleRight
                                    size={20}
                                    color='#7e57c2'
                                    className='cursor-pointer'
                                    onClick={() => showTutorDetails(ele)}
                                  />
                                </div>
                              </div>
                              <div>
                                <button
                                  className='font-bold text-lg hover:text-black underline'
                                  onClick={() => handleAssign(ele?._id)}
                                >
                                  Assign
                                </button>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className='text-black'>No Data</div>
                      )}
                    </thead>
                  </table>
                </div>
              )}
            </section>
          </div>
        </div>
      )}

      {tutorDetailsModal && (
        <div className='fixed inset-0 flex justify-center items-center bg-black bg-opacity-50'>
          <div className='bg-white w-auto h-auto p-3 shadow-lg rounded'>
            <div className='flex justify-end'>
              <button
                className='bg-blue-600 text-white px-2 py-1 cursor-pointer'
                onClick={() => setTutorDetailsModal(false)}
              >
                Close
              </button>
            </div>
            <div>
              <table className='w-full table-auto'>
                <thead>
                  <tr className='bg-gray-200'>
                    <th className='px-4 py-2'>Name</th>
                    <th className='px-4 py-2'>Image</th>
                    <th className='px-4 py-2'>Mobile No</th>
                    <th className='px-4 py-2'>Email</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className='border px-4 py-2'>
                      {tutorDetailsModalData?.name || 'N/A'}
                    </td>
                    <td className='border px-4 py-2'>
                      {tutorDetailsModalData?.image ? (
                        <img
                          src={tutorDetailsModalData.image}
                          alt='Tutor'
                          className='h-10 w-10 rounded-full'
                        />
                      ) : (
                        'N/A'
                      )}
                    </td>
                    <td className='border px-4 py-2'>
                      {tutorDetailsModalData?.mobNo || 'N/A'}
                    </td>
                    <td className='border px-4 py-2'>
                      {tutorDetailsModalData?.email || 'N/A'}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}

      <section className='space-y-5 py-10 lg:hidden block'>
        {listPages && listPages.length > 0 ? (
          listPages?.map((item) => (
            <div className=' bg-white p-4 rounded-[10px]'>
              <>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>
                      {' '}
                      Parent Name
                    </p>
                  </div>
                  <div className='w-full'>
                    <div className='flex items-center  gap-2 capitalize'>
                      <img
                        className='w-12 h-12 rounded'
                        src={
                          item?.parentId?.image ??
                          'https://thumbs.dreamstime.com/z/vector-illustration-avatar-dummy-logo-collection-image-icon-stock-isolated-object-set-symbol-web-137160339.jpg?ct=jpeg'
                        }
                        alt='User'
                      />
                      <div>
                        <div className='text-xs font-medium text-black '>
                          {item && item?.parentId?.name
                            ? item?.parentId?.name
                            : '--'}
                        </div>
                        <div className='text-xs text-gray-500'>
                          {item && item?.parentId?.mobNo
                            ? item?.parentId?.mobNo
                            : '--'}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>City</p>
                  </div>
                  <div className='w-full'>
                    <p className='text-[14px] font-[400]'>
                      {item && item?.cityId?.name ? item?.cityId?.name : 'N/A'}
                    </p>
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>
                      Posted On
                    </p>
                  </div>
                  <div className='w-full'>
                    <p className='text-[14px] font-[400]'>
                      {moment(item?.createdAt).format('DD-MMM-YYYY')}
                    </p>
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>
                      Confirmed Tutor
                    </p>
                  </div>
                  <div className='w-full'>
                    <p className='text-[14px] font-[400]'>
                      {' '}
                      {item?.tutorId?.name ?? 'N/A'}
                    </p>
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>Remark</p>
                  </div>
                  <div className='w-full'>
                    <p className='text-[14px] font-[400]'>
                      {item?.remark ?? 'N/A'}
                    </p>
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>Action</p>
                  </div>
                  <div className='w-full'>
                    <p className='text-[14px] font-[400] relative'>
                      <BsThreeDotsVertical
                        className='text-2xl text-red-600'
                        onClick={() => openAction(item._id)}
                      />
                      {open === item._id && (
                        <ul className='bg-white border rounded-md w-48 absolute right-8  py-1 text-start  text-sm font-bold shadow-lg'>
                          <li className='flex justify-between px-2 items-center text-[#023565] py-1 border-b'>
                            <button
                              className='w-full text-left cursor-pointer'
                              onClick={() => handleview(item)}
                            >
                              View & Update
                            </button>
                            <div>
                              <img src='/Group 1000001230.png' alt='' />
                            </div>
                          </li>

                          <li
                            className='cursor-pointer px-2  flex justify-between items-center text-[#023565] py-1 border-b'
                            onClick={() => handleRejectMsg(item)}
                          >
                            <button
                              className='w-full text-left'
                              onClick={() => handleReject(item)}
                            >
                              Reject Lead
                            </button>
                            <div>
                              <img src='/Group 1000001230.png' alt='' />
                            </div>
                          </li>

                          <li className='text-[#023565] px-2  flex justify-between items-center py-1 border-b'>
                            <button
                              className='w-full text-left cursor-pointer'
                              onClick={() => handleMarkDemoDone(item)}
                            >
                              Mark As Confirmed
                            </button>
                            <div>
                              <img src='/Group 1000001230.png' alt='' />
                            </div>
                          </li>

                          <li
                            className='relative cursor-pointer px-2 flex justify-between items-center text-[#023565] py-1'
                            onClick={() => viewHistory(item)}
                          >
                            <button className='w-full text-left'>
                              View History
                            </button>
                            <div>
                              <img src='/Group 1000001230.png' alt='' />
                            </div>
                          </li>
                        </ul>
                      )}
                    </p>
                  </div>
                </div>
              </>
            </div>
          ))
        ) : (
           <div  className=' bg-white p-4 rounded-[10px]'>
            <p className='text-center'>Data Not Found</p>
          </div>
        )}
      </section>
    </>
  )
}

const mapStateToProps = state => ({
  getCreditBaseDemoClassList: state.tution.getCreditBaseDemoClassList
})

const mapDispatchToProps = dispatch => ({
  tutionAction: () => dispatch(tutionAction())
})

export default connect(mapStateToProps, mapDispatchToProps)(ButtonSecondTable)

// export default ButtonSecondTable;
