import { headerForPrivateAPI, APIcallFunction } from "../_helpers";
import { feedbackConstants } from "../_constants";

export const feedbackAction = {
  feedbackGet,
  updateFeedbackStatus,
  updateIssueResolve
}

function feedbackGet(data) {
 
    const credentials = {
      header: headerForPrivateAPI(),
      method: "POST",
      body: data ?? {},
      endPoint: "feedback/getList",
    };
  
    return (dispatch) => {
      dispatch(request(data));
      APIcallFunction(credentials).then(
        (user) => {
          
          dispatch(success(user));
          
        },
        (error) => {
          dispatch(failure(error));
          
        }
      );
    };
  
    function request() {
      return { type: feedbackConstants.GET_FEEDBACK_LIST_REQUEST };
    }
    function success(user) {
      return { type: feedbackConstants.GET_FEEDBACK_LIST_SUCCESS, user };
    }
    function failure(error) {
      return { type: feedbackConstants.GET_FEEDBACK_LIST_FAILURE, error };
    }
  }

  function updateFeedbackStatus(data,FeedbackData) {
 
    const credentials = {
      header: headerForPrivateAPI(),
      method: "POST",
      body: data ?? {},
      endPoint: "feedback/updateFeedbackStatus",
    };
  
    return (dispatch) => {
      dispatch(request(data));
      APIcallFunction(credentials).then(
        (user) => {
          
          dispatch(success(user));
          dispatch(feedbackGet(FeedbackData))
          
        },
        (error) => {
          dispatch(failure(error));
          
        }
      );
    };
  
    function request() {
      return { type: feedbackConstants.UPDATE_FEEDBACK_STATUS_REQUEST };
    }
    function success(user) {
      return { type: feedbackConstants.UPDATE_FEEDBACK_STATUS_SUCCESS, user };
    }
    function failure(error) {
      return { type: feedbackConstants.UPDATE_FEEDBACK_STATUS_FAILURE, error };
    }
  }


  function updateIssueResolve(data,FeedbackData) {
 
    const credentials = {
      header: headerForPrivateAPI(),
      method: "POST",
      body: data ?? {},
      endPoint: "feedback/updateIssueResolve",
    };
  
    return (dispatch) => {
      dispatch(request(data));
      APIcallFunction(credentials).then(
        (user) => {
          
          dispatch(success(user));
          dispatch(feedbackGet(FeedbackData))
          
        },
        (error) => {
          dispatch(failure(error));
          
        }
      );
    };
  
    function request() {
      return { type: feedbackConstants.UPDATE_FEEDBACK_ISSUE_RESOLVE_REQUEST };
    }
    function success(user) {
      return { type: feedbackConstants.UPDATE_FEEDBACK_ISSUE_RESOLVE_SUCCESS, user };
    }
    function failure(error) {
      return { type: feedbackConstants.UPDATE_FEEDBACK_ISSUE_RESOLVE_FAILURE, error };
    }
  }
  
  