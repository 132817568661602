import { walletConstant } from "../_constants";

export default function Wallet(state = {}, action) {
    switch (action.type) {
        case walletConstant.GET_WALLET_LIST_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case walletConstant.GET_WALLET_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                walletList: action?.users
            }
        case walletConstant.GET_WALLET_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            }

        case walletConstant.ADD_BONUS_USER_WALLET_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case walletConstant.ADD_BONUS_USER_WALLET_SUCCESS:
            return {
                ...state,
                loading: false,

            }
        case walletConstant.ADD_BONUS_USER_WALLET_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            }

        case walletConstant.GET_WALLET_HISTORY_LIST_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case walletConstant.GET_WALLET_HISTORY_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                walletHistoryList: action?.users

            }
        case walletConstant.GET_WALLET_HISTORY_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            }

        case walletConstant.UPDATE_WALLET_STATUS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case walletConstant.UPDATE_WALLET_STATUS_SUCCESS:
            return {
                ...state,
                loading: false,


            }
        case walletConstant.UPDATE_WALLET_STATUS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            }


        case walletConstant.UPDATE_WALLET_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case walletConstant.UPDATE_WALLET_SUCCESS:
            return {
                ...state,
                loading: false,


            }
        case walletConstant.UPDATE_WALLET_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            }



        case walletConstant.UPDATE_WALLET_REWARD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case walletConstant.UPDATE_WALLET_REWARD_SUCCESS:
            return {
                ...state,
                loading: false,


            }
        case walletConstant.UPDATE_WALLET_REWARD_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            }

        default:
            return state;
    }
}