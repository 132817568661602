export const appSettingConstants={
    GET_APP_SETTING_LIST_REQUEST:"GET_APP_SETTING_LIST_REQUEST",
    GET_APP_SETTING_LIST_SUCCESS:"GET_APP_SETTING_LIST_SUCCESS",
    GET_APP_SETTING_LIST_FAILURE:"GET_APP_SETTING_LIST_FAILURE",

    CREATE_APP_SETTING_LIST_REQUEST:"CREATE_APP_SETTING_LIST_REQUEST",
    CREATE_APP_SETTING_LIST_SUCCESS:"CREATE_APP_SETTING_LIST_SUCCESS",
    CREATE_APP_SETTING_LIST_FAILURE:"CREATE_APP_SETTING_LIST_FAILURE",

    UPDATE_APP_SETTING_LIST_REQUEST:"UPDATE_APP_SETTING_LIST_REQUEST",
    UPDATE_APP_SETTING_LIST_SUCCESS:"UPDATE_APP_SETTING_LIST_SUCCESS",
    UPDATE_APP_SETTING_LIST_FAILURE:"UPDATE_APP_SETTING_LIST_FAILURE",

    DELETE_APP_SETTING_LIST_REQUEST:"DELETE_APP_SETTING_LIST_REQUEST",
    DELETE_APP_SETTING_LIST_SUCCESS:"DELETE_APP_SETTING_LIST_SUCCESS",
    DELETE_APP_SETTING_LIST_FAILURE:"DELETE_APP_SETTING_LIST_FAILURE",

    ENA_DIS_APP_SETTING_LIST_REQUEST:"ENA_DIS_APP_SETTING_LIST_REQUEST",
    ENA_DIS_APP_SETTING_LIST_SUCCESS:"ENA_DIS_APP_SETTING_LIST_SUCCESS",
    ENA_DIS_APP_SETTING_LIST_FAILURE:"ENA_DIS_APP_SETTING_LIST_FAILURE",
}