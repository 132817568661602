import moment from 'moment'
import React from 'react'
import { MdOutlineClose } from 'react-icons/md'

function KycViewModel({ kycDataShow, setKycDataShow, kycListData }) {

  return kycDataShow && (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40">
        <div className="relative w-11/12 mx-auto lg:w-[700px]">
          <button
            className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center"
            onClick={() => setKycDataShow(false)}
          >
            <span><MdOutlineClose /></span> Close
          </button>
          <div className="form_class border-0 rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <div className="flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t">
              <h2 className="text-lg font-semibold"> KYC (Know Your Customer) </h2>
            </div>
            <div className="relative h-[400px] overflow-y-auto">
              <div className="grid md:grid-cols-2 gap-4 p-4">
                <div className="w-full text-center">
                  <img
                    src={kycListData?.selfie}
                    className="w-64 h-7w-64 mx-auto "
                    alt="Document Selfie"
                  />
                </div>
                <div className="w-full capitalize h-[350px] Pokercardbar overflow-hidden overflow-y-auto space-y-2 pr-14">
                  <div className="w-full flex items-center">
                    <p className="font-semibold w-[40%]">Aadhaar No</p>
                    <span className="w-[20%]">:</span>
                    <p className="w-[40%]">{kycListData?.govtIdNumber ?? 'N/A'}</p>
                  </div>
                  <div className="w-full flex justify-between items-center">
                    <p className="font-semibold w-[40%]">Name</p>
                    <span className="w-[20%]">:</span>
                    <p className="w-[40%]">{kycListData?.name ?? 'N/A'}</p>
                  </div>
                  <div className="w-full flex justify-between items-center">
                    <p className="font-semibold w-[40%]">Date Of Birth</p>
                    <span className="w-[20%]">:</span>
                    <p className="w-[40%]">{kycListData?.dob}</p>
                  </div>
                  <div className="w-full flex justify-between items-center">
                    <p className="font-semibold w-[40%]">Gender</p>
                    <span className="w-[20%]">:</span>
                    <p className="w-[40%]">{kycListData?.gender === 'M' ? 'MALE' : kycListData?.gender === 'F' ? 'FEMALE' : 'N/A'}</p>
                  </div>
                  <div className="w-full flex justify-between items-center">
                    <p className="font-semibold w-[40%]">House</p>
                    <span className="w-[20%]">:</span>
                    <p className="w-[40%]">{kycListData?.house ?? 'N/A'}</p>
                  </div>
                  <div className="w-full flex justify-between items-center">
                    <p className="font-semibold w-[40%]">Street</p>
                    <span className="w-[20%]">:</span>
                    <p className="w-[40%]">{kycListData?.street ?? 'N/A'}</p>
                  </div>
                  <div className="w-full flex justify-between">
                    <p className="font-semibold w-[40%] break-all">Village/Town/City</p>
                    <span className="w-[20%]">:</span>
                    <p className="w-[40%]">{kycListData?.vtc ?? 'N/A'}</p>
                  </div>
                  <div className="w-full flex justify-between">
                    <p className="font-semibold w-[40%]">Location</p>
                    <span className="w-[20%]">:</span>
                    <p className="w-[40%]">{kycListData?.loc ?? 'N/A'}</p>
                  </div>
                  <div className="w-full flex justify-between items-center">
                    <p className="font-semibold w-[40%]">Landmark</p>
                    <span className="w-[20%]">:</span>
                    <p className="w-[40%]">{kycListData?.landmark ?? 'N/A'}</p>
                  </div>
                  <div className="w-full flex justify-between items-center">
                    <p className="font-semibold w-[40%]">Post Office</p>
                    <span className="w-[20%]">:</span>
                    <p className="w-[40%]">{kycListData?.po ?? 'N/A'}</p>
                  </div>
                  <div className="w-full flex justify-between items-center">
                    <p className="font-semibold w-[40%]">District</p>
                    <span className="w-[20%]">:</span>
                    <p className="w-[40%]">{kycListData?.dist ?? 'N/A'}</p>
                  </div>
                  <div className="w-full flex justify-between items-center">
                    <p className="font-semibold w-[40%]">State</p>
                    <span className="w-[20%]">:</span>
                    <p className="w-[40%]">{kycListData?.state ?? 'N/A'}</p>
                  </div>
                  <div className="w-full flex justify-between items-center">
                    <p className="font-semibold w-[40%]">Country</p>
                    <span className="w-[20%]">:</span>
                    <p className="w-[40%]">{kycListData?.country ?? 'N/A'}</p>
                  </div>
                  <div className="w-full flex justify-between items-center">
                    <p className="font-semibold w-[40%]">Pincode</p>
                    <span className="w-[20%]">:</span>
                    <p className="w-[40%]">{kycListData?.pinCode ?? 'N/A'}</p>
                  </div>
                  <div className="w-full flex justify-between ">
                    <p className="font-semibold w-[40%]">KYC Date</p>
                    <span className="w-[20%]">:</span>
                    <p className="w-[40%]">{kycListData?.createdAt ? moment(kycListData?.createdAt).format('DD/MM/YYYY') : ""}</p>
                  </div>
                  <div className="w-full flex justify-between ">
                    <p className="font-semibold w-[40%]">Time</p>
                    <span className="w-[20%]">:</span>
                    <p className="w-[40%]">{kycListData?.createdAt ? moment(kycListData?.createdAt).format('hh:mm A') : ""}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default KycViewModel