import { cityConstants } from "../_constants";
import { alertActions } from "./alert.actions";
import { headerForPrivateAPI, APIcallFunction } from "../_helpers";

export const cityAction = {
  cityGet,
  cityCreate,
  cityDelete,
  cityUpdate,
  cityEnaDis,
  getCityById,
  cityGetList,
  getPackageAmountListByPromise
};


function getPackageAmountListByPromise(data) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "package/getPackageAmountList"
  };

  return APIcallFunction(credentials)

}

function cityGet(data) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "city/getAllCityList",
  };

  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (users) => {

        dispatch(success(users));
      },
      (error) => {

        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: cityConstants.GET_CITY_LIST_REQUEST };
  }
  function success(users) {
    return { type: cityConstants.GET_CITY_LIST_SUCCESS, users };
  }
  function failure(error) {
    return { type: cityConstants.GET_CITY_LIST_FAILURE, error };
  }
}


function cityGetList(data) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "city/getList",
  };

  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (users) => {

        dispatch(success(users));
      },
      (error) => {

        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: cityConstants.GET_CITY_LIST_PAGE_REQUEST };
  }
  function success(users) {
    return { type: cityConstants.GET_CITY_LIST_PAGE_SUCCESS, users };
  }
  function failure(error) {
    return { type: cityConstants.GET_CITY_LIST_PAGE_FAILURE, error };
  }
}

function getCityById(data) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "city/getCityById",
  };

  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (users) => {

        dispatch(success(users));
      },
      (error) => {

        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: cityConstants.GET_CITY_DETAILS_REQUEST };
  }
  function success(users) {
    return { type: cityConstants.GET_CITY_DETAILS_SUCCESS, users };
  }
  function failure(error) {
    return { type: cityConstants.GET_CITY_DETAILS_FAILURE, error };
  }
}



function cityCreate(data, cityData) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "city/create",
  };

  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (users) => {
        dispatch(success(users));
        dispatch(cityGet(cityData));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: cityConstants.CREATE_CITY_LIST_REQUEST };
  }
  function success(users) {
    return { type: cityConstants.CREATE_CITY_LIST_SUCCESS, users };
  }
  function failure(error) {
    return { type: cityConstants.CREATE_CITY_LIST_FAILURE, error };
  }
}

function cityDelete(data, citydata) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "city/delete",
  };

  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (users) => {
        dispatch(success(users));
        dispatch(cityGetList(citydata));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: cityConstants.CREATE_CITY_LIST_REQUEST };
  }
  function success(users) {
    return { type: cityConstants.CREATE_CITY_LIST_SUCCESS, users };
  }
  function failure(error) {
    return { type: cityConstants.CREATE_CITY_LIST_FAILURE, error };
  }
}

function cityUpdate(data, citydata) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "city/update",
  };

  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (users) => {
        dispatch(success(users));
        dispatch(cityGetList(citydata));
        dispatch(alertActions.success("City update successfully"))
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: cityConstants.UPDATE_CITY_LIST_REQUEST };
  }
  function success(users) {
    return { type: cityConstants.UPDATE_CITY_LIST_SUCCESS, users };
  }
  function failure(error) {
    return { type: cityConstants.UPDATE_CITY_LIST_FAILURE, error };
  }
}

function cityEnaDis(data, cityDetails) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "city/enableDisable",
  };

  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (users) => {
        // dispatch(alertActions.success("Action Successfull"))
        dispatch(success(users));
        dispatch(cityGetList(cityDetails))
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: cityConstants.ENA_DIS_CITY_REQUEST };
  }
  function success(users) {
    return { type: cityConstants.ENA_DIS_CITY_SUCCESS, users };
  }
  function failure(error) {
    return { type: cityConstants.ENA_DIS_CITY_FAILURE, error };
  }
}
