import { boardConstants } from '../../src/_constants'
import { APIcallFunction, headerForPrivateAPI } from '../_helpers'
import { alertActions } from './alert.actions'
export const boardAction = {
  boardGet,
  boardDelete,
  createBoard,
  updateBoard,
  EnaDisBoard
}

function boardGet (data) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: 'POST',
    body: data ?? {},
    endPoint: 'board/getBoardList'
  }
  return dispatch => {
    dispatch(request(data))
    APIcallFunction(credentials).then(
      users => {
        dispatch(success(users))
      },
      error => {
        dispatch(failure(error))
      }
    )
  }

  function request () {
    return { type: boardConstants.GET_BOARD_LIST_REQUEST }
  }
  function success (users) {
    return { type: boardConstants.GET_BOARD_LIST_SUCCESS, users }
  }
  function failure (error) {
    return { type: boardConstants.GET_BOARD_LIST_FAILURE, error }
  }
}

function boardDelete (data, BoardList) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: 'POST',
    body: data ?? {},
    endPoint: 'board/delete'
  }
  return dispatch => {
    dispatch(request(data))
    APIcallFunction(credentials).then(
      users => {
        dispatch(success(users))
        dispatch(boardGet(BoardList))
      },
      error => {
        dispatch(failure(error))
      }
    )
  }

  function request () {
    return { type: boardConstants.DELETE_BOARD_LIST_REQUEST }
  }
  function success (users) {
    return { type: boardConstants.DELETE_BOARD_LIST_SUCCESS, users }
  }
  function failure (error) {
    return { type: boardConstants.DELETE_BOARD_LIST_FAILURE, error }
  }
}

function createBoard (data, BoardList) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: 'POST',
    body: data ?? {},
    endPoint: 'board/create'
  }
  return dispatch => {
    dispatch(request(data))
    APIcallFunction(credentials).then(
      users => {
        dispatch(alertActions.success('Board created'))
        dispatch(success(users))
        dispatch(boardGet(BoardList))
      },
      error => {
        dispatch(failure(error))
      }
    )
  }

  function request () {
    return { type: boardConstants.CREATE_BOARD_LIST_REQUEST }
  }
  function success (users) {
    return { type: boardConstants.CREATE_BOARD_LIST_SUCCESS, users }
  }
  function failure (error) {
    return { type: boardConstants.CREATE_BOARD_LIST_FAILURE, error }
  }
}

function updateBoard (data, boardDetails) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: 'POST',
    body: data ?? {},
    endPoint: 'board/update'
  }
  return dispatch => {
    dispatch(request(data))
    APIcallFunction(credentials).then(
      users => {
        dispatch(success(users))
        dispatch(boardGet(boardDetails))
        dispatch(alertActions.success('Board Updated'))
      },
      error => {
        dispatch(failure(error))
      }
    )
  }

  function request () {
    return { type: boardConstants.UPDATE_BOARD_LIST_REQUEST }
  }
  function success (users) {
    return { type: boardConstants.UPDATE_BOARD_LIST_SUCCESS, users }
  }
  function failure (error) {
    return { type: boardConstants.UPDATE_BOARD_LIST_FAILURE, error }
  }
}

function EnaDisBoard (data, boardDetails) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: 'POST',
    body: data ?? {},
    endPoint: 'board/enableDisable'
  }
  return dispatch => {
    dispatch(request(data))
    APIcallFunction(credentials).then(
      users => {
        // dispatch(alertActions.success(""))
        dispatch(success(users))
        dispatch(boardGet(boardDetails))
      },
      error => {
        dispatch(failure(error))
      }
    )
  }

  function request () {
    return { type: boardConstants.ENA_DIS_BOARD_LIST_REQUEST }
  }
  function success (users) {
    return { type: boardConstants.ENA_DIS_BOARD_LIST_SUCCESS, users }
  }
  function failure (error) {
    return { type: boardConstants.ENA_DIS_BOARD_LIST_FAILURE, error }
  }
}
