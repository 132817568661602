/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { termsAndConditionAction } from "../../_actions/termsAndCondition.action";
import Button from "./Button";
import { BsThreeDotsVertical } from "react-icons/bs";
import { MdOutlineAdd, MdOutlineClose, MdOutlineKeyboardArrowLeft } from "react-icons/md";
import { FaChevronRight } from "react-icons/fa";
import moment from "moment";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import TermsCbHeader from "./TermsCbHeader";
import Loader from "../../components/Loader";

const TermsConditions = () => {
  const dispatch = useDispatch();
  const selector = useSelector((state) => state);
  const [value, setValue] = useState('');
  const [value2, setValue2] = useState('');
  const [searchText, setSearchText] = useState("");

  const [actionData, setActionData] = useState("");
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);


  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const pageSize = 10

  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [type, setType] = useState("");
  const [management, setManagement] = useState("");
  const [errors, setErrors] = useState('')
  const [profile, setProfile] = useState("");

  const menuRef = useRef(null);

  const [editInput, setEditInput] = useState({
    id: "",
    title: "",
    content: "",
    type: "",
    profile: "",
    // workingMode: "",
  });

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setActionData("");
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);



  let [termsAndConditionData, setTermsAndConditionData] = useState();
  useEffect(() => {
    let termsAndConditionData = {
      keyWord: searchText ?? "",
      workingMode: 'PAY_AND_VIEW_MODEL',
      fromDate: "",
      toDate: "",
      sortOrder: "",
      sortBy: "",
      pageNo: currentPage,
      size: pageSize,
    };
    dispatch(termsAndConditionAction.GetTermsAndConditionList(termsAndConditionData));
  }, []);


  useEffect(() => {
    if (selector?.TermsAndCondition?.termsandconditionTotal?.data?.total) {
      setTotalPages(Math.ceil(selector?.TermsAndCondition?.termsandconditionTotal?.data?.total / pageSize));
    }
    setTermsAndConditionData(selector?.TermsAndCondition?.termsAndConditionList)
  }, [selector]);

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };


  const handleActionModal = (resDataId) => {
    setActionData((prevresDataId) =>
      prevresDataId === resDataId ? null : resDataId
    );
  };

  const deleteTermsCondition = (id) => {
    let listData = {
      keyWord: searchText ?? "",
      fromDate: "",
      workingMode: 'PAY_AND_VIEW_MODEL',
      toDate: "",
      sortOrder: "",
      sortBy: "",
      pageNo: 1,
      size: 10,
    };

    let deleteID = {
      id: id,
    };

    dispatch(
      termsAndConditionAction.DeleteTermsAndCondition(deleteID, listData)
    );
  };
  const handleAddNewModal = () => {
    setAddModal(true);
  };

  const validateEditForm = () => {
    let errorsData = {}


    if (!editInput.title) {
      errorsData['title'] = "Title is required."
    }
    if (!value2) {
      errorsData['content'] = "content is required."
    }
    if (!editInput.type) {
      errorsData['type'] = "Type is required."
    }
    if (!editInput.profile) {
      errorsData['profile'] = "Profile is required."
    }
    setErrors(errorsData);
    return Object.keys(errorsData).length === 0;
  }

  const handleNewCreateForm = (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return
    }
    let listData = {
      keyWord: searchText ?? "",
      fromDate: "",
      workingMode: 'PAY_AND_VIEW_MODEL',
      toDate: "",
      sortOrder: "",
      sortBy: "",
      pageNo: 1,
      size: 10,
    };

    let newCreateData = {
      title: title,
      content: value,
      type: type,
      workingMode: 'PAY_AND_VIEW_MODEL',
      profile: profile,
    };
    dispatch(
      termsAndConditionAction.CreateTermsAndCondition(newCreateData, listData)
    );
    setAddModal(false);
  };

  const handleClassEditForm = (data) => {
    setEditModal(true);
    setEditInput(data);
    setValue2(data.content)
  };

  const handleEditModal = () => {
    setEditModal(false);
    setEditInput({
      id: "",
      title: "",
      content: "",
      type: "",
      profile: "",
      // workingMode: "",
    });
    setErrors({})

  };

  const handleOnChnage = (e) => {
    const { name, value } = e.target;
    setEditInput({
      ...editInput,
      [name]: value,
    });

  };

  const validateForm = () => {

    let errorsData = {}


    if (!title) {
      errorsData['title'] = "Title is required."
    }
    if (!value) {
      errorsData['content'] = "content is required."
    }
    if (!type) {
      errorsData['type'] = "Type is required."
    }
    if (!profile) {
      errorsData['profile'] = "Profile is required."
    }
    setErrors(errorsData);
    return Object.keys(errorsData).length === 0;

  }

  const handleEditForm = (e) => {

    e.preventDefault();
    if (!validateEditForm()) {
      return
    }

    let listData = {
      keyWord: searchText ?? "",
      fromDate: "",
      workingMode: 'PAY_AND_VIEW_MODEL',
      toDate: "",
      sortOrder: "",
      sortBy: "",
      pageNo: 1,
      size: 10,
    };

    let EditData = {
      id: editInput._id,
      title: editInput.title,
      content: value2,
      type: editInput.type,
      profile: editInput.profile,
      workingMode: 'PAY_AND_VIEW_MODEL',
    };

    dispatch(
      termsAndConditionAction.UpdateTermsAndCondition(EditData, listData)
    );
    setEditModal(false);
  };

  const handleActionEnabDis = (data) => {
    let listData = {
      keyWord: searchText ?? "",
      fromDate: "",
      toDate: "",
      workingMode: 'PAY_AND_VIEW_MODEL',
      sortOrder: "",
      sortBy: "",
      pageNo: 1,
      size: 10,
    };

    let id = {
      id: data._id,
    };
    dispatch(termsAndConditionAction.EnaDisTermsAndCondition(id, listData));
  };


  function WorkingModeData(mode) {
    switch (mode) {
      case "MANAGEMENT_MODEL":
        return "Management Model";
      case "PAY_AND_VIEW_MODEL":
        return "Pay and View Model";
      default:
        return "Unknown Model";
    }
  }

  function typeData(type) {
    switch (type) {
      case "TUTOR":
        return "Tutor";
      case "PARENT":
        return "Parent";
      case "PRIVACY_POLICY":
        return "Privacy Policy";
      case "Terms&Condition":
        return "Terms & Condition";
      case "REFERRAL":
        return "Referral";
      default:
        return "Unknown Model";
    }
  }

  const handleClose = () => {
    setAddModal(false)
    setTitle("")
    setContent("")
    setValue("")
    setType("")
    setManagement("")
    setProfile("")
    setErrors({})
  }
  const handleSearch = (e) => {
    setSearchText(e?.target?.value)
    let termsAndConditionData = {
      keyWord: e?.target?.value,
      workingMode: 'PAY_AND_VIEW_MODEL',
      fromDate: "",
      toDate: "",
      sortOrder: "",
      sortBy: "",
      pageNo: currentPage,
      size: pageSize,
    };
    dispatch(termsAndConditionAction.GetTermsAndConditionList(termsAndConditionData));
  }





  return (
    <>
      <Loader loading={selector?.TermsAndCondition?.loading} />
      <TermsCbHeader handleSearch={handleSearch} searchText={searchText} />
      <section className="overflow-hidden overflow-y-auto">
        <div className="m-5 relative">
          <section className="flex justify-end  ">
            <div
              className="flex bg-white justify-center place-items-center text-[#C60E6B] rounded-lg border border-[#D9D9D9]  px-3 py-2"
              onClick={() => handleAddNewModal()}
            >
              <span>
                <MdOutlineAdd className="text-xl mx-1 cursor-pointer" />
              </span>
              <Button name="Add New" />
            </div>
          </section>
          <section className="mt-5">
            <div class="relative overflow-x-auto  sm:rounded-lg border border-[#D3D3D3]">
              <table class="w-full  text-left rtl:text-right bg-white text-[#313131]">
                <thead class="">
                  <tr className="border-b">
                    <th scope="col" class="px-3 py-4">
                      S.No
                    </th>
                    <th scope="col" class="px-6 py-4">
                      Title
                    </th>
                    <th scope="col" class="px-6 py-4 ">
                      Content
                    </th>
                    <th scope="col" class="px-6 py-4 ">
                      Type
                    </th>
                    <th scope="col" class="px-6 py-4 ">
                      Working Mode
                    </th>
                    <th scope="col" class="px-6 py-4">
                      Profile
                    </th>
                    <th scope="col" class="px-6 py-4">
                      Date
                    </th>
                    <th scope="col" class="px-6 py-4">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {termsAndConditionData && termsAndConditionData.length > 0 ? (
                    termsAndConditionData?.map((item, id) => (
                      <tr class=" capitalize  border-b dark:border-gray-700" key={id}>
                        <th scope="row" class="px-3 py-4 font-medium whitespace-nowrap">
                          {id + 1}
                        </th>
                        <th scope="row" class="px-6 py-4 font-medium whitespace-nowrap">
                          {item.title}
                        </th>
                        <td class="px-6 py-4">
                          <div className="container mx-auto">
                            <div className="line-clamp-2" dangerouslySetInnerHTML={{ __html: item.content }} />
                          </div>
                          {/* <div className="container mx-auto">
                          <div className={isExpanded ? 'line-clamp-2' : ''} dangerouslySetInnerHTML={{ __html: content }} />
                          <button onClick={toggleExpand} className="text-blue-500 hover:underline">
                            {isExpanded ? 'Read Less' : 'Read More'}
                          </button>
                        </div> */}
                        </td>
                        <td class="px-6 py-4 whitespace-nowrap">{typeData(item.type)}</td>
                        <td class="px-6 py-4 whitespace-nowrap">{WorkingModeData(item.workingMode)}</td>
                        <td class="px-6 py-4 whitespace-nowrap">
                          {typeData(item.profile)}
                        </td>
                        <td class="px-6 py-4  whitespace-nowrap">{item.createdAt ? moment(item.createdAt).format("DD-MM-YYYY") : moment().format("DD-MM-YYYY")}</td>

                        <td class="px-6 py-4 cursor-pointer relative">
                          <BsThreeDotsVertical className="text-xl  mx-auto text-[#C60E6B]"
                            onClick={() => handleActionModal(item._id)}
                          />
                          {actionData && actionData === item._id && (
                            <div ref={menuRef} className="fixed right-[1%] w-auto bg-white border border-gray-300 rounded shadow-lg z-10">
                              <ul className="p-2 space-y-2">
                                <li class="flex items-center justify-between gap-4 cursor-pointe font-semibold text-sm r hover:bg-gray-100 p-1 rounded text-[#023565]"
                                  onClick={() => deleteTermsCondition(item._id)}>
                                  <span class="mr-2">Remove</span>
                                  <img src="/Group 1000001230.png" alt="" />
                                </li>
                                <li className="  cursor-pointer hover:bg-gray-100 p-1 rounded">
                                  <td
                                    className={` cursor-pointer flex items-center justify-between  ${item.isDisable
                                      ? "text-red-600"
                                      : "text-green-600"
                                      }`}
                                    onClick={() => handleActionEnabDis(item)}
                                  >
                                    <span>{item.isDisable ? "Enable" : "Disable"}</span>
                                    <img src="/Group 1000001172.png" alt="" />
                                  </td>
                                </li>
                                <li
                                  className=" flex items-center justify-between gap-4 cursor-pointer text-sm font-semibold hover:bg-gray-100 p-1 rounded text-[#023565]"
                                  onClick={() => handleClassEditForm(item)}
                                >
                                  <span> View & Update</span>
                                  <img src="/Group 1000001230.png" alt="" />
                                </li>
                              </ul>
                            </div>
                          )}
                        </td>
                      </tr>
                    ))

                  ) : (
                    <tr>
                      <td colSpan="7" className="px-6 py-4 text-center">
                        Data Not Available
                      </td>
                    </tr>
                  )
                  }
                </tbody>
              </table>
              {totalPages > 1 && (
                <div className="flex justify-end items-center py-2">
                  <button
                    className={`px-4 py-2 border text-black rounded ${currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""}`}
                    onClick={handlePreviousPage}
                    disabled={currentPage === 1}
                  >
                    <MdOutlineKeyboardArrowLeft />
                  </button>
                  <span className="text-gray-700 mx-1">
                    <span className="border px-4 py-2 text-black rounded">{currentPage}</span> of {totalPages}
                  </span>
                  <button
                    className={`px-4 py-2 border text-black rounded ${currentPage === totalPages ? "opacity-50 cursor-not-allowed" : ""}`}
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                  >
                    <FaChevronRight />
                  </button>
                </div>
              )}
              {addModal && (
                <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40">
                  <div className="relative w-11/12 mx-auto lg:w-[600px]">
                    <button
                      className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center"
                      onClick={handleClose}
                    >
                      <span><MdOutlineClose /></span> Close
                    </button>
                    <div className="form_class border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                      <div className="flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                        <h2 className="text-lg font-semibold">Create Terms & Condition </h2>
                      </div>
                      <div className="relative lg:h-[520px] h-[450px] overflow-y-auto">
                        <form onSubmit={handleNewCreateForm} className="space-y-2 p-4">
                          <div className="space-y-2 w-full">
                            <label
                              htmlFor="title"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Title:
                            </label>
                            <input
                              type="text"
                              id="title"
                              value={title}
                              onChange={(e) => setTitle(e.target.value)}
                              className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                            />
                            {errors.title && <p className="text-red-500 text-sm">{errors.title}</p>}

                          </div>
                          <div className="container mx-auto space-y-2 w-full">
                            <label htmlFor="type" className="block text-sm font-medium text-gray-700" >
                              Content:
                            </label>
                            <ReactQuill value={value} onChange={setValue} />
                            {errors.content && <p className="text-red-500 text-sm">{errors.content}</p>}

                          </div>
                          <div className="space-y-2 w-full">
                            <label
                              htmlFor="type"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Type:
                            </label>
                            <select
                              id="type"
                              className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                              onChange={(e) => setType(e.target.value)}
                              value={type}
                            >
                              <option value="">Select type</option>
                              <option value="TUTOR">TUTOR</option>
                              <option value="PARENT">PARENT</option>
                              <option value="PRIVACY_POLICY">Privacy Policy</option>
                              <option value="Terms&Condition">Terms & Condition</option>
                              <option value="REFERRAL">Referral</option>
                            </select>
                            {errors.type && <p className="text-red-500 text-sm">{errors.type}</p>}

                          </div>
                          {/* <div className="space-y-2 w-full">
                            <label
                              htmlFor="management"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Working Mode:
                            </label>
                            <select
                              id="management"
                              className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                              required
                              onChange={(e) => setManagement(e.target.value)}
                              value={management}
                            >
                              <option value="">Select Mode</option>
                              <option value="MANAGEMENT_MODEL">Management Model</option>
                              <option value="PAY_AND_VIEW_MODEL">Pay & View Model</option>
                            </select>
                          </div> */}
                          <div className="space-y-2 w-full">
                            <label
                              htmlFor="profile"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Profile:
                            </label>
                            <select
                              id="profile"
                              className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                              onChange={(e) => setProfile(e.target.value)}
                            >
                              <option value="">Select Profile</option>
                              <option value="TUTOR">Tutor</option>
                              <option value="PARENT">Student</option>
                            </select>
                            {errors.profile && <p className="text-red-500 text-sm">{errors.profile}</p>}

                          </div>
                          <div className="flex justify-center items-center pt-2">
                            <button
                              type="submit"
                              className="w-60 mx-auto bg-[#023565] text-white font-semibold py-2 px-4 rounded-full "
                            >
                              Create
                            </button>
                          </div>
                        </form>
                      </div>

                    </div>
                  </div>
                </div>
              )}


              {editModal && (
                <>
                  <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40">
                    <div className="relative w-11/12 mx-auto lg:w-[600px]">
                      <button
                        className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center"
                        onClick={handleEditModal}
                      >
                        <span><MdOutlineClose /></span> Close
                      </button>
                      <div className="form_class border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        <div className="flex items-center justify-between p-2 border-b border-solid border-slate-200 rounded-t">
                          <h2 className="text-lg font-semibold">Edit Terms & Condition </h2>
                        </div>
                        <div className="relative lg:h-[520px] h-[450px] overflow-y-auto">
                          <form onSubmit={handleEditForm} className="space-y-2 p-4">
                            <div className="space-y-2 w-full ">
                              <label
                                htmlFor="className"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Title:
                              </label>
                              <input
                                type="text"
                                id="className"
                                name="title"
                                value={editInput.title}
                                onChange={handleOnChnage}
                                className="mt-1 p-2 border block w-full border-gray-300 rounded-md"

                              />
                              {errors.title && <p className="text-red-500 text-sm">{errors.title}</p>}

                            </div>
                            <div className="container mx-auto space-y-2 w-full ">
                              <label htmlFor="type" className="block text-sm font-medium text-gray-700" >
                                Content:
                              </label>
                              <ReactQuill value={value2} onChange={setValue2} />
                              {errors.content && <p className="text-red-500 text-sm">{errors.content}</p>}

                            </div>

                            <div className="space-y-2 w-full ">
                              <label
                                htmlFor="sortName"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Type:
                              </label>
                              <select
                                className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                                name="type"
                                onChange={handleOnChnage}
                                value={editInput.type}
                              >
                                <option value="">select a option</option>

                                <option value="TUTOR">TUTOR</option>
                                <option value="PARENT">PARENT</option>
                                <option value="PRIVACY_POLICY">Privacy Policy</option>
                                <option value="Terms&Condition">Terms & Condition</option>

                                <option value="REFERRAL">Referral </option>
                              </select>
                              {errors.type && <p className="text-red-500 text-sm">{errors.type}</p>}

                            </div>
                            {/* <div className="space-y-2 w-full ">
                              <label
                                htmlFor="sortName"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Working Mode:
                              </label>
                              <select
                                className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                                required
                                name="workingMode"
                                onChange={handleOnChnage}
                                value={editInput.workingMode}
                              >
                                <option value="">select a option</option>
                                <option value="MANAGEMENT_MODEL">Management Model</option>
                                <option value="PAY_AND_VIEW_MODEL">
                                  Pay & View Model
                                </option>
                              </select>
                            </div> */}
                            <div className="space-y-2 w-full ">
                              <label
                                htmlFor="sortName"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Profile :
                              </label>
                              <select
                                className="mt-1 p-2 border block w-full border-gray-300 rounded-md"

                                name="profile"
                                onChange={handleOnChnage}
                                value={editInput.profile}
                              >
                                <option value="">select a option</option>
                                <option value="TUTOR">Tutor</option>
                                <option value="PARENT">Student</option>
                              </select>
                              {errors.profile && <p className="text-red-500 text-sm">{errors.profile}</p>}

                            </div>
                            <div className="flex justify-center items-center pt-2">
                              <button
                                type="submit"
                                className="w-60 mx-auto bg-[#023565] text-white font-semibold py-2 px-4 rounded-full "
                              >
                                Update
                              </button>
                            </div>
                          </form>
                        </div>

                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </section>
        </div>
      </section>
    </>

  );
};

export default TermsConditions;
