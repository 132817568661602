import React from 'react'

const ButtonComponent = ({ name, onclick, value = 0 }) => {
    return (
        <div>
            {value !== 0 ? <button onClick={onclick} className='py-1'>{name} ({value})</button> : <button onClick={onclick} className='py-1'>{name}</button>}
        </div>
    )
}

export default ButtonComponent