import { tutionConstant } from "../_constants";

export default function tution(state = {}, action) {
    switch (action.type) {

        case tutionConstant.GETDEMO_CLASSLIST_BYSTATUS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case tutionConstant.GETDEMO_CLASSLIST_BYSTATUS_SUCCESS:
            return {
                ...state,
                loading: false,
                isStateMangerRes: false,
                demoList: action?.user
            }
        case tutionConstant.GETDEMO_CLASSLIST_BYSTATUS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            }

        case tutionConstant.GET_DEMO_CLASSLIST_BY_STATUS_1_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case tutionConstant.GET_DEMO_CLASSLIST_BY_STATUS_1_SUCCESS:
            return {
                ...state,
                loading: false,
                isStateMangerRes: false,
                demoList1: action?.user
            }
        case tutionConstant.GET_DEMO_CLASSLIST_BY_STATUS_1_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            }

        case tutionConstant.GET_SUGGESTED_TUTOR_LIST_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case tutionConstant.GET_SUGGESTED_TUTOR_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                isStateMangerRes: false,
                suggestedTutorList: action?.user
            }
        case tutionConstant.GET_SUGGESTED_TUTOR_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            }


        case tutionConstant.GET_DEMO_BYID_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case tutionConstant.GET_DEMO_BYID_SUCCESS:
            return {
                ...state,
                loading: false,
                isStateMangerRes: true,
                isStateMangerResponse: true,
                getDemoById: action?.user
            }
        case tutionConstant.GET_DEMO_BYID_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            }



        case tutionConstant.UPDATE_STATUS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case tutionConstant.UPDATE_STATUS_SUCCESS:
            return {
                ...state,
                loading: false,
                isStateMangerRes: true,

            }
        case tutionConstant.UPDATE_STATUS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            }



        case tutionConstant.REVERT_DEMO_CLASS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case tutionConstant.REVERT_DEMO_CLASS_SUCCESS:
            return {
                ...state,
                loading: false,

            }
        case tutionConstant.REVERT_DEMO_CLASS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            }



        case tutionConstant.ASSIGN_TUTOR_BYADMIN_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case tutionConstant.ASSIGN_TUTOR_BYADMIN_SUCCESS:
            return {
                ...state,
                loading: false,
                isStateMangerRes: true,

            }
        case tutionConstant.ASSIGN_TUTOR_BYADMIN_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            }


        case tutionConstant.CREATE_COPY_BYADMIN_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case tutionConstant.CREATE_COPY_BYADMIN_SUCCESS:
            return {
                ...state,
                loading: false,
                isStateMangerRes: true,

            }
        case tutionConstant.CREATE_COPY_BYADMIN_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            }


        case tutionConstant.DEMO_DONE_BYADMIN_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case tutionConstant.DEMO_DONE_BYADMIN_SUCCESS:
            return {
                ...state,
                loading: false,


            }
        case tutionConstant.DEMO_DONE_BYADMIN_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            }


        case tutionConstant.TUITION_REMOVE_HOLD_BY_ADMIN_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case tutionConstant.TUITION_REMOVE_HOLD_BY_ADMIN_SUCCESS:
            return {
                ...state,
                loading: false,


            }
        case tutionConstant.TUITION_REMOVE_HOLD_BY_ADMIN_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            }

        case tutionConstant.CHANGE_TUTOR_BY_ADMIN_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case tutionConstant.CHANGE_TUTOR_BY_ADMIN_SUCCESS:
            return {
                ...state,
                loading: false,


            }
        case tutionConstant.CHANGE_TUTOR_BY_ADMIN_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            }

        case tutionConstant.GET_LOG_HISTORY_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                getLogHistoryList: action?.user


            }
        case tutionConstant.GET_LOG_HISTORY_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            }

        case tutionConstant.TUITION_HOLD_BY_ADMIN_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case tutionConstant.TUITION_HOLD_BY_ADMIN_SUCCESS:
            return {
                ...state,
                loading: false,



            }
        case tutionConstant.TUITION_HOLD_BY_ADMIN_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            }



        case tutionConstant.TUITION_CLOSE_BY_ADMIN_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case tutionConstant.TUITION_CLOSE_BY_ADMIN_SUCCESS:
            return {
                ...state,
                loading: false,



            }
        case tutionConstant.TUITION_CLOSE_BY_ADMIN_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            }


        case tutionConstant.UPDATE_TUITION_MODE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case tutionConstant.UPDATE_TUITION_MODE_SUCCESS:
            return {
                ...state,
                loading: false,



            }
        case tutionConstant.UPDATE_TUITION_MODE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            }

        case tutionConstant.ACTIVE_DEMO_LIST_BY_PERENTID_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case tutionConstant.ACTIVE_DEMO_LIST_BY_PERENTID_SUCCESS:
            return {
                ...state,
                loading: false,
                activeDemoList: action?.user


            }
        case tutionConstant.ACTIVE_DEMO_LIST_BY_PERENTID_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            }

        case tutionConstant.CREATE_TUITION_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case tutionConstant.GET_ALL_TUTOR_LIST_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case tutionConstant.GET_ALL_TUTOR_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                getAllTutorList: action?.user
            }
        case tutionConstant.GET_ALL_TUTOR_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            }



        case tutionConstant.GET_ALL_PARENT_LIST_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case tutionConstant.GET_ALL_PARENT_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                getAllParentList: action?.user



            }
        case tutionConstant.GET_ALL_PARENT_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            }


        case tutionConstant.GET_PARENT_ID_GET_PIN_CODE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case tutionConstant.GET_PARENT_ID_GET_PIN_CODE_SUCCESS:
            return {
                ...state,
                loading: false,
                pinCodeDataRes: action?.user
            }
        case tutionConstant.GET_PARENT_ID_GET_PIN_CODE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            }



        case tutionConstant.GET_STUDENT_LIST_BY_PARENT_ID_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case tutionConstant.GET_STUDENT_LIST_BY_PARENT_ID_SUCCESS:
            return {
                ...state,
                loading: false,
                isStateMangerResponse: false,
                getStudentListByParentId: action?.user
            }
        case tutionConstant.GET_STUDENT_LIST_BY_PARENT_ID_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            }


        case tutionConstant.UPATE_DEMO_INFO_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case tutionConstant.UPATE_DEMO_INFO_SUCCESS:
            return {
                ...state,
                loading: false,

            }
        case tutionConstant.UPATE_DEMO_INFO_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            }



        case tutionConstant.GET_ALL_CITY_LIST_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case tutionConstant.GET_ALL_CITY_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                getAllCityList: action?.user

            }
        case tutionConstant.GET_ALL_CITY_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            }

        case tutionConstant.GET_CITY_PINCODE_LIST_REQUEST:
            return {
                ...state,
                // loading: true,
            };
        case tutionConstant.GET_CITY_PINCODE_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                getPincodeList: action?.user

            }
        case tutionConstant.GET_CITY_PINCODE_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            }

        case tutionConstant.CREATE_DEMO_BY_ADMIN_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case tutionConstant.CREATE_DEMO_BY_ADMIN_SUCCESS:
            return {
                ...state,
                loading: false,


            }
        case tutionConstant.CREATE_DEMO_BY_ADMIN_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            }



        case tutionConstant.GET_ALL_CLASS_LIST_BY_ADMIN_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case tutionConstant.GET_ALL_CLASS_LIST_BY_ADMIN_SUCCESS:
            return {
                ...state,
                loading: false,
                getAllClassList: action?.user


            }
        case tutionConstant.GET_ALL_CLASS_LIST_BY_ADMIN_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case tutionConstant.GET_TUITION_COUNT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case tutionConstant.GET_TUITION_COUNT_SUCCESS:
            return {
                ...state,
                loading: false,
                getTuitionCount: action?.user


            }
        case tutionConstant.GET_TUITION_COUNT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            }






        case tutionConstant.GET_ALL_BOARD_LIST_BY_ADMIN_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case tutionConstant.GET_ALL_BOARD_LIST_BY_ADMIN_SUCCESS:
            return {
                ...state,
                loading: false,
                getAllBoardByAdminList: action?.user


            }
        case tutionConstant.GET_ALL_BOARD_LIST_BY_ADMIN_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            }



        case tutionConstant.GET_ONGOING_TUITION_LIST_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case tutionConstant.GET_ONGOING_TUITION_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                getOngoungTutionList: action?.user


            }
        case tutionConstant.GET_ONGOING_TUITION_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            }




        case tutionConstant.GET_CREDIT_BASE_DEMO_CLASS_LIST_BY_STATUS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case tutionConstant.GET_CREDIT_BASE_DEMO_CLASS_LIST_BY_STATUS_SUCCESS:
            return {
                ...state,
                loading: false,
                getCreditBaseDemoClassList: action?.user


            }
        case tutionConstant.GET_CREDIT_BASE_DEMO_CLASS_LIST_BY_STATUS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            }




        case tutionConstant.TUITION_DENIE_BYADMIN_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case tutionConstant.TUITION_DENIE_BYADMIN_SUCCESS:
            return {
                ...state,
                loading: false,


            }
        case tutionConstant.TUITION_DENIE_BYADMIN_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            }










        case tutionConstant.ADD_REMARK_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case tutionConstant.ADD_REMARK_SUCCESS:
            return {
                ...state,
                loading: false,


            }
        case tutionConstant.ADD_REMARK_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            }



        case tutionConstant.GET_TUTOR_DETAILS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case tutionConstant.GET_TUTOR_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                getTutorDetails: action?.user
            }
        case tutionConstant.GET_TUTOR_DETAILS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            }
        case tutionConstant.UPDATE_INFO_ACTIVE_ON_APP_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case tutionConstant.UPDATE_INFO_ACTIVE_ON_APP_SUCCESS:
            return {
                ...state,
                loading: false,
            }
        case tutionConstant.UPDATE_INFO_ACTIVE_ON_APP_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            }


        case tutionConstant.GET_TUITION_TUTOR_CHANGE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case tutionConstant.GET_TUITION_TUTOR_CHANGE_SUCCESS:
            return {
                ...state,
                loading: false,
                getChangeTutorData: action?.user
            }
        case tutionConstant.GET_TUITION_TUTOR_CHANGE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            }

        case tutionConstant.CLASS_REFERENCE_LIST_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case tutionConstant.CLASS_REFERENCE_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                classReferenceList: action?.user
            }
        case tutionConstant.CLASS_REFERENCE_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            }


        case tutionConstant.GET_ALL_LEAD_MANAGER_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case tutionConstant.GET_ALL_LEAD_MANAGER_SUCCESS:
            return {
                ...state,
                loading: false,
                getAllLeadManger: action?.user
            }
        case tutionConstant.GET_ALL_LEAD_MANAGER_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            }

        case tutionConstant.GET_STUDENT_LIST_BY_PARENT_ID_FOR_DEMO_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case tutionConstant.GET_STUDENT_LIST_BY_PARENT_ID_FOR_DEMO_SUCCESS:
            return {
                ...state,
                loading: false,
                isStateMangerResponse: false,
                getStudentListByParentIdForDemo: action?.user
            }
        case tutionConstant.GET_STUDENT_LIST_BY_PARENT_ID_FOR_DEMO_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            }

        case tutionConstant.DENIED_CHANGE_TUTOR_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case tutionConstant.DENIED_CHANGE_TUTOR_SUCCESS:
            return {
                ...state,
                loading: false,

            }
        case tutionConstant.DENIED_CHANGE_TUTOR_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            }

        default:
            return state;
    }


}
