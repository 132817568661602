import { dashboardConstant } from "../_constants";

export default function Dashboard(state = {}, action) {
  switch (action.type) {
    case dashboardConstant.GET_DASHBOARD_COUNT_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case dashboardConstant.GET_DASHBOARD_COUNT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        dashboardCountList: action?.users,
      };
    case dashboardConstant.GET_DASHBOARD_COUNT_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };


    case dashboardConstant.GET_DASHBOARD_COUNT_CREDIT_BASE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case dashboardConstant.GET_DASHBOARD_COUNT_CREDIT_BASE_SUCCESS:
      return {
        ...state,
        loading: false,
        dashboardCreditBaseCount: action?.users,
      };
    case dashboardConstant.GET_DASHBOARD_COUNT_CREDIT_BASE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case dashboardConstant.GET_NEW_TICKET_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case dashboardConstant.GET_NEW_TICKET_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        getNewTicket: action?.users,
      };
    case dashboardConstant.GET_NEW_TICKET_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case dashboardConstant.GET_LATEST_DEMO_DONE_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case dashboardConstant.GET_LATEST_DEMO_DONE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        getLatestDemodDoneList: action?.users,
      };
    case dashboardConstant.GET_LATEST_DEMO_DONE_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case dashboardConstant.GET_CONTACT_SEEN_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case dashboardConstant.GET_CONTACT_SEEN_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        getContactList: action?.users,
      };
    case dashboardConstant.GET_CONTACT_SEEN_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case dashboardConstant.GET_LATEST_DEMO_REQUEST_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case dashboardConstant.GET_LATEST_DEMO_REQUEST_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        getLatestDemoRequestList: action?.users,
      };
    case dashboardConstant.GET_LATEST_DEMO_REQUEST_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case dashboardConstant.GET_NEW_REFERRAL_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case dashboardConstant.GET_NEW_REFERRAL_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        getLatestReferralList: action?.users,
      };
    case dashboardConstant.GET_NEW_REFERRAL_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case dashboardConstant.GET_LATEST_TUTOR_CHANGE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case dashboardConstant.GET_LATEST_TUTOR_CHANGE_SUCCESS:
      return {
        ...state,
        loading: false,
        getlatestTutorChnageList: action?.users,
      };
    case dashboardConstant.GET_LATEST_TUTOR_CHANGE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
}
