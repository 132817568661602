export const cityConstants = {
    GET_CITY_LIST_REQUEST: "GET_CITY_LIST_REQUEST",
    GET_CITY_LIST_SUCCESS: "GET_CITY_LIST_SUCCESS",
    GET_CITY_LIST_FAILURE: "GET_CITY_LIST_FAILURE",
    CREATE_CITY_LIST_REQUEST: "CREATE_CITY_LIST_FAILURE",
    CREATE_CITY_LIST_SUCCESS: "CREATE_CITY_LIST_SUCCESS",
    CREATE_CITY_LIST_FAILURE: "CREATE_CITY_LIST_SUCCESS",
    DELETE_CITY_LIST_REQUEST: "DELETE_CITY_LIST_REQUEST",
    DELETE_CITY_LIST_SUCCESS: "DELETE_CITY_LIST_SUCCESS",
    DELETE_CITY_LIST_FAILURE: "DELETE_CITY_LIST_FAILURE",
    UPDATE_CITY_LIST_REQUEST: "UPDATE_CITY_LIST_REQUEST",
    UPDATE_CITY_LIST_SUCCESS: "UPDATE_CITY_LIST_SUCCESS",
    UPDATE_CITY_LIST_FAILURE: "UPDATE_CITY_LIST_FAILURE",
    ENA_DIS_CITY_REQUEST: "ENA_DIS_CITY_REQUEST",
    ENA_DIS_CITY_SUCCESS: "ENA_DIS_CITY_SUCCESS",
    ENA_DIS_CITY_FAILURE: "ENA_DIS_CITY_FAILURE",


    GET_CITY_DETAILS_REQUEST: "GET_CITY_DETAILS_REQUEST",
    GET_CITY_DETAILS_SUCCESS: "GET_CITY_DETAILS_SUCCESS",
    GET_CITY_DETAILS_FAILURE: "GET_CITY_DETAILS_FAILURE",


    GET_CITY_LIST_PAGE_REQUEST: "GET_CITY_LIST_PAGE_REQUEST",
    GET_CITY_LIST_PAGE_SUCCESS: "GET_CITY_LIST_PAGE_SUCCESS",
    GET_CITY_LIST_PAGE_FAILURE: "GET_CITY_LIST_PAGE_FAILURE",

}