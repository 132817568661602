import { packageConstants } from "../_constants";
export default function Package(state = {}, action) {
    switch (action.type) {
        case packageConstants.GET_PACKAGE_LIST_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case packageConstants.GET_PACKAGE_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                packageList: action?.users
            };
        case packageConstants.GET_PACKAGE_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case packageConstants.CREATE_PACKAGE_LIST_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case packageConstants.CREATE_PACKAGE_LIST_SUCCESS:
            return {
                ...state,
                loading: false,

            };
        case packageConstants.CREATE_PACKAGE_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case packageConstants.DELETE_PACKAGE_LIST_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case packageConstants.DELETE_PACKAGE_LIST_SUCCESS:
            return {
                ...state,
                loading: false,

            };
        case packageConstants.DELETE_PACKAGE_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case packageConstants.UPDATE_PACKAGE_LIST_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case packageConstants.UPDATE_PACKAGE_LIST_SUCCESS:
            return {
                ...state,
                loading: false,

            };
        case packageConstants.UPDATE_PACKAGE_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case packageConstants.ENA_DIS_PACKAGE_LIST_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case packageConstants.ENA_DIS_PACKAGE_LIST_SUCCESS:
            return {
                ...state,
                loading: false,

            };
        case packageConstants.ENA_DIS_PACKAGE_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };


        default:
            return state;
    }
}
