import React, { Fragment } from "react";
import { FaSearch, FaAngleDown } from "react-icons/fa";
import { useSelector } from "react-redux";
import HeaderProfileComp from "../../components/Header/HeaderProfileComp";
import HeaderHeading from "../../components/Header/HeaderHeading";
import MobileSearchHeader from "../../components/Header/MobileSearchHeader";


const TutorToLiveHeader = ({ handleSearch, searchText, handleSearchCity, headerCity, roleId }) => {
  const selector = useSelector((state) => state);
  return (
    <Fragment>
      <section className="w-full bg-[#ffffff] lg:block hidden border-r border pl-4">
        <div className="w-full py-3 px-10 flex justify-between items-center">
          <HeaderHeading label={"Tutor To Live"} />
          <div className="flex items-center gap-6">
            <div className="search_bar  relative text-center flex justify-start lg:justify-start  lg:w-auto pt-2 lg:pt-0">
              {searchText === "" && (
                <div className="absolute inset-y-0 right-0 flex items-center pr-3 pb-3">
                  <FaSearch className="text-gray-400 mt-3 search_icon" />
                </div>
              )}
              <input
                type="search"
                className={`border-2 w-full lg:w-80 h-9  rounded-lg py-1 px-3 capitalize text-black ${searchText ? "search-active" : ""
                  }`}
                onChange={handleSearch}
                placeholder=""
                id="search"
                value={searchText}
              />
            </div>

            <div className="flex relative  city_select cursor-pointer items-center">
              <select onChange={handleSearchCity} className="flex items-center justify-center bg-white border-2 text-black rounded-lg w-full lg:w-36 px-4 h-9 capitalize"
                value={headerCity}
                disabled={roleId !== 1 ? true : false}
              >
                <option className="text-black align-middle" value={""}>
                  {" "}
                  All Cities <FaAngleDown className="ml-3" color="#D9D9D9" />
                </option>
                {selector?.Citys?.cityGetList?.map((city, id) => (
                  <option className="text-black capitalize" value={city._id} key={id}>
                    {city.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <HeaderProfileComp />
        </div>
      </section>

      <section className="bg-white lg:hidden block">
        <MobileSearchHeader handleSearch={handleSearch} searchText={searchText} />
      </section>
    </Fragment>
  )
}

export default TutorToLiveHeader
