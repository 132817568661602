/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from "react";
import { FaAngleDown } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { downloadCSV } from "../../../../_helpers";
import { tutionAction } from "../../../../_actions";
import HeaderProfileComp from "../../../../components/Header/HeaderProfileComp";
import SearchIconComponent from "../../../../components/Header/SearchIconComponent";
import HeaderHeading from "../../../../components/Header/HeaderHeading";
import MobileSearchHeader from "../../../../components/Header/MobileSearchHeader";
import { cityAction } from "../../../../_actions/city.action";
import { BiExport } from "react-icons/bi";



function removeCommas(input) {
  if (typeof input !== "string") {
    throw new TypeError("Input must be a string");
  }
  return input.replace(/,/g, ""); // Replace all commas with an empty string
}
const TuitionHeader = ({ active, handleCity, handleSearch, searchText, headerCity, roleId }) => {
  const dispatch = useDispatch();
  const selector = useSelector((state) => state);
  const [onGoingTuitionData, setOnGoingTuitionData] = useState([])
  const [demoRequestTuitionData, setDemoRequestTuitionData] = useState([])
  const [tutorAssignedTuitionData, setTutorAssignedTuitionData] = useState([])
  const [onDemoTuitionData, setOnDemoTuitionData] = useState([])
  const [demoDoneTuitionData, setDemoDoneTuitionData] = useState([])
  const [onHoldTuitionData, setOnHoldTuitionData] = useState([])
  const [completedTuitionData, setCompletedTuitionData] = useState([])
  const [rejectedTuitionData, setRejectedTuitionData] = useState([])

  useEffect(() => {
    if (active === "on-going-tuition") {
      let data = {
        pageNo: 1,
        size: 1000000000,
      }
      tutionAction.getOnGoingTuitionListPromise(data, headerCity).then((res) => {
        if (res?.data)
          setOnGoingTuitionData(res.data.list)
      })
    }
    if (active === "demo-request") {
      let demoData = {
        status: [0],
        keyWord: "",
        fromDate: "",
        toDate: "",
        sortOrder: "",
        sortBy: "",
        pageNo: 1,
        size: 1000000000000,
      };
      tutionAction.getDemoClassListStatusPromise(demoData).then((res) => {
        if (res.data)
          setDemoRequestTuitionData(res.data.list)
      })
    }
    if (active === "tutor-assigned") {
      let listData = {
        status: [3],
        keyWord: "",
        fromDate: "",
        toDate: "",
        sortOrder: "",
        sortBy: "",
        pageNo: 1,
        size: 10000,
      };
      tutionAction.getDemoClassListStatusPromise(listData).then((res) => {
        if (res.data)
          setTutorAssignedTuitionData(res.data.list)
      })
    }
    if (active === "on-demo") {
      let listData = {
        status: [3, 4, 5],
        keyWord: "",
        fromDate: "",
        toDate: "",
        sortOrder: "",
        sortBy: "",
        pageNo: 1,
        size: 1000000000000,
      };
      tutionAction.getDemoClassListStatusPromise(listData).then((res) => {
        if (res.data)
          setOnDemoTuitionData(res.data.list)
      })
    }
    if (active === "demo-done") {
      let listData = {
        status: [6, 7, 8],
        keyWord: "",
        fromDate: "",
        toDate: "",
        sortOrder: "",
        sortBy: "",
        pageNo: 1,
        size: 1000,
      };
      tutionAction.getDemoClassListStatusPromise(listData).then((res) => {
        if (res.data)
          setDemoDoneTuitionData(res.data.list)
      })
    }
    if (active === "on-hold") {
      let tutionDetails = {
        "status": [12],
        "keyWord": "",
        "fromDate": "",
        "toDate": "",
        "sortOrder": "",
        "sortBy": "",
        pageNo: 1,
        size: 1000,
      }
      tutionAction.getDemoClassListStatusPromise(tutionDetails).then((res) => {
        if (res.data)
          setOnHoldTuitionData(res.data.list)
      })
    }
    if (active === "completed") {
      let tutionData = {
        status: [11],
        keyWord: "",
        fromDate: "",
        toDate: "",
        sortOrder: "",
        sortBy: "",
        pageNo: 1,
        size: 1000,
      };

      tutionAction.getDemoClassListStatusPromise(tutionData).then((res) => {
        if (res.data)
          setCompletedTuitionData(res.data.list)
      })
    }
    if (active === "rejected") {
      let listData = {
        status: [2],
        keyWord: "",
        fromDate: "",
        toDate: "",
        sortOrder: "",
        sortBy: "",
        pageNo: 1,
        size: 1000,
      };
      tutionAction.getDemoClassListStatusPromise(listData).then((res) => {
        if (res.data)
          setRejectedTuitionData(res.data.list)
      })
    }
  }, [active]);

  // const onGoingTuitionData = selector?.tution?.getOngoungTutionList?.data.list
  const onGoingTuition = ['Parent Name', "Student Name", "Class-Subject", 'Parent Mobile Number', "Tutor Name", "Tutor Mobile Number", 'Address', 'City', 'Tuition Fee', 'Reference Fee', 'Tutor Fee', "Company share", "Remark", "Class reference", "Tuition Start Date", "Tuition Fee Due Date", "Tutor Fee Due Date", "Class Mode"];

  const demoRequest = ['Parent Name', 'Mobile Number', 'Class/Subject', 'City ', 'Date/Time ', 'Gender', "Lead Sourece", "Remark"];
  const tutorAssign = ['Parent Name', 'Mobile Number', 'Class/Subject', 'City ', 'Address', 'Date/Time ', 'Assigned Tutor', "Remark"];
  const onDemo = ['Parent Name', 'Mobile Number', 'Class/Subject', 'City ', 'address', 'Date/Time ', 'Assigned Tutor', "Remark"];
  const DemoDone = ['Parent Name', 'Mobile Number', 'Class/Subject', 'City ', 'address', 'Date/Time ', 'Assigned Tutor', "Feedback ", "Remark"];
  const onHold = ['Parent Name', 'Mobile Number', 'Address', 'City ', 'Tuition Fee ', 'Reference', "Tutor’s Fee", "Company", "Hold On", "Remark"];
  const completed = ['Parent Name', 'Mobile Number', 'Address', 'City ', 'Tuition Fee ', 'Reference', "Tutor’s Fee", "Company", "Remark"];
  const rejected = ['Parent Name', 'Mobile Number', 'Class/Subject', 'City ', 'address', 'Date/Time ', 'Gender', "Remark"];
  const convertToCSV = (data, active) => {
    console.log("data, active", data, active)
    if (active === "on-going-tuition") {
      const array = typeof data !== 'object' ? JSON.parse(data) : data;
      let csv = onGoingTuition.join(',') + '\r\n';
      array?.forEach(item => {
        const parentName = item?.parentInfo?.name || '-';
        const studentNames = Array.isArray(item?.studentsInfo) && item?.studentsInfo.length > 0
          ? item.studentsInfo.map(student => student.name).join('-') // Join with a newline character
          : '';
        const classSubject = Array.isArray(item?.studentsInfo) && item?.studentsInfo.length > 0
          ? item.studentsInfo.map(student => student?.class[0] || "").join('-') // Join with a newline character
          : '';
        const mobNo = item?.parentInfo?.mobNo || '-';
        const city = Array.isArray(item?.cityInfo) ? item?.cityInfo[0]?.name : '-';
        const Address = (item.parentInfo.address ? removeCommas(`"${item.parentInfo.address}"`) : '-');
        const tuitionFee = item.tuitionFee ? item.tuitionFee : '-';
        const ReferenceFee = item?.referenceFee || '0';
        const TutorFee = item.tutorFee ? item.tutorFee : '0';
        const company = item.companyShare ? item.companyShare : '-';
        const classReference = item?.classReference ? item.classReference : '-';
        const Remark = item?.remark ? item.remark : '-';
        const TuitionFeeDueDate = item?.dueDate ? item.dueDate : '-';
        const TuitionStartDate = item?.startDate ? item.startDate : '-';
        const TutorFeeDueDate = item?.tutorDueDate ? item.tutorDueDate : '-';
        const TutorName = item?.tutorDetails?.name ? item?.tutorDetails?.name : '-';
        const TutorMobileNumber = item?.tutorDetails?.mobNo ? item?.tutorDetails?.mobNo : '-';
        const classMode = item?.preferredTeachMode ? item.preferredTeachMode : '-';
        const row = [parentName, studentNames, classSubject, mobNo, TutorName, TutorMobileNumber, Address, city, tuitionFee, ReferenceFee, TutorFee, company, Remark, classReference, TuitionStartDate, TuitionFeeDueDate, TutorFeeDueDate, classMode].join(',');
        csv += row + '\r\n';
      });
      return csv
    }
    if (active === "demo-request") {
      const array = typeof data !== 'object' ? JSON.parse(data) : data;
      let csv = demoRequest.join(',') + '\r\n';
      array?.forEach(item => {
        const parentName = item?.parentId?.name ? item?.parentId?.name : '-';
        const mobNo = item?.parentId?.mobNo ? item?.parentId?.mobNo : '-';
        const studentsClasses = item && item.bookFreeDemo ? item.bookFreeDemo.map(demo => {
          const subjects = demo.subjectId ? demo.subjectId.map(sub => sub.name).join(';') : '-';
          const className = demo.classId ? demo.classId.name : '-';
          return `${subjects}/(${className})`;
        }).join(' | ') : 'N/A';
        const city = item.cityId?.name ? item.cityId?.name : '-';
        const DateTime = item?.demoTime
        const Gender = item.requiredGender ? item.requiredGender : '-';
        const LeadSource = item.leadSource ? item.leadSource : '-'
        const Remark = item.reason ? item.reason : '-';
        const row = [parentName, mobNo, studentsClasses, city, DateTime, Gender, LeadSource, Remark].join(',');
        csv += row + '\r\n';
      });
      return csv
    }
    if (active === "tutor-assigned") {
      const array = typeof data !== 'object' ? JSON.parse(data) : data;
      let csv = tutorAssign.join(',') + '\r\n';
      array?.forEach(item => {
        const parentName = item?.parentId?.name || '-';
        const mobNo = item?.parentId?.mobNo || '-';
        const studentsClasses = item?.bookFreeDemo?.map(demo => {
          const subjects = demo.subjectId ? demo.subjectId.map(sub => sub.name).join(';') : '-';
          const className = demo.classId ? demo.classId.name : '-';
          return `${subjects} (${className})`;
        }).join(' | ');
        const Address = item.address ? removeCommas(`"${item.address}"`) : '';
        const city = item.cityId?.name ? item.cityId?.name : '-';
        const DateTime = item?.demoDate
        const assignedTutor = item?.tutorId?.name || '-';
        const Remark = item?.remark || '-';
        const row = [parentName, mobNo, studentsClasses, city, Address, DateTime, assignedTutor, Remark].join(',');
        csv += row + '\r\n';
      });
      return csv
    }
    if (active === "on-demo") {
      const array = typeof data !== 'object' ? JSON.parse(data) : data;
      let csv = onDemo.join(',') + '\r\n';
      array?.forEach(item => {
        const parentName = item?.parentId
          ?.name ? item?.ParentId?.name : '-';
        const mobNo = item?.ParentId?.mobNo ? item?.ParentId?.mobNo : '-';
        const studentsClasses = item && item.bookFreeDemo ? item.bookFreeDemo.map(demo => {
          const subjects = demo.subjectId ? demo.subjectId.map(sub => sub.name).join(';') : '-';
          const className = demo.classId ? demo.classId.name : '-';
          return `${subjects} (${className})`;
        }).join(' | ') : 'N/A';
        const city = item.cityId?.name ? item.cityId?.name : '-';
        const address = item.address ? removeCommas(`"${item.address}"`) : '-';

        const DateTime = item?.demoTime
        const assignedTutor = item?.tutorId?.name ? item?.tutorId?.name : '-';
        const Remark = item.remark ? item.remark : '-';
        const row = [parentName, mobNo, studentsClasses, city, address, DateTime, assignedTutor, Remark].join(',');
        csv += row + '\r\n';
      });
      console.log("csv_______________", csv)
      return csv
    }
    if (active === "demo-done") {
      const array = typeof data !== 'object' ? JSON.parse(data) : data;
      let csv = DemoDone.join(',') + '\r\n';
      array?.forEach(item => {
        const parentName = item?.parentId?.name || '-';
        const mobNo = item?.parentId?.mobNo || '-';
        const studentsClasses = item && item.bookFreeDemo ? item.bookFreeDemo.map(demo => {
          const subjects = demo.subjectId ? demo.subjectId.map(sub => sub.name).join(';') : '-';
          const className = demo.classId ? demo.classId.name : '-';
          return `${subjects} (${className})`;
        }).join(' | ') : 'N/A';
        const city = item.cityId?.name ? item.cityId?.name : '-';
        const address = item.address ? removeCommas(`"${item.address}"`) : '-';
        const DateTime = item?.demoTime
        const assignedTutor = item?.tutorId?.name ? item?.tutorId?.name : '-';
        const Feedback = item &&
          item.processStatus === 7 ? 'Approve' :
          item.processStatus === 8 ? 'Change Tutor Request' :
            item.processStatus === 6 ? 'Pending for Student' :
              null
        const Remark = item.remark ? item.remark : '-';
        const row = [parentName, mobNo, studentsClasses, city, address, DateTime, assignedTutor, Feedback, Remark].join(',');
        csv += row + '\r\n';
      });
      console.log("csv_______________", csv)
      return csv
    }
    if (active === "on-hold") {
      const array = typeof data !== 'object' ? JSON.parse(data) : data;
      let csv = onHold.join(',') + '\r\n';
      array?.forEach(item => {
        const parentName = item?.parentId
          ?.name ? item?.parentId?.name : '-';
        const mobNo = item?.parentId?.mobNo ? item?.parentId?.mobNo : '-';
        const address = item.address ? removeCommas(`"${item.address}"`) : '-';
        const city = item.cityId?.name ? item.cityId?.name : '-';
        const tutionFee = item.tuitionFee ? item.tuitionFee : '-';
        const Reference = item.referenceFee ? item.referenceFee : '-';
        const tutorFee = item?.tutorFee ? item?.tutorFee : '-';
        const Company = item?.companyShare ? item?.companyShare : '-';
        const holdOn = item?.companyShare ? item?.companyShare : '-';
        const Remark = item.reason ? item.reason : '-';
        const row = [parentName, mobNo, address, city, tutionFee, Reference, tutorFee, Company, holdOn, Remark].join(',');
        csv += row + '\r\n';
      });
      return csv
    }
    if (active === "completed") {
      const array = typeof data !== 'object' ? JSON.parse(data) : data;
      let csv = completed.join(',') + '\r\n';
      array?.forEach(item => {

        const parentName = item?.parentId?.name ? item?.parentId?.name : '-';
        const mobNo = item?.parentId?.mobNo ? item?.parentId?.mobNo : '-';
        const address = item.address ? removeCommas(`"${item.address}"`) : '-';
        const city = item.cityId?.name ? item.cityId?.name : '-';
        const tutionFee = item.tuitionFee ? item.tuitionFee : '-';
        const Reference = item.referenceFee ? item.referenceFee : '-';
        const tutorFee = item?.tutorFee ? item?.tutorFee : '-';
        const Company = item?.companyShare ? item?.companyShare : '-';
        const Remark = item.reason ? item.reason : '-';

        const row = [parentName, mobNo, address, city, tutionFee, Reference, tutorFee, Company, Remark].join(',');
        csv += row + '\r\n';
      });
      return csv;
    }

    if (active === "rejected") {
      const array = typeof data !== 'object' ? JSON.parse(data) : data;
      let csv = rejected.join(',') + '\r\n';
      array?.forEach(item => {
        const parentName = item?.parentId?.name ? item?.parentId?.name : '-';
        const mobNo = item?.parentId?.mobNo ? item?.parentId?.mobNo : '-';
        const studentsClasses = item && item.bookFreeDemo ? item.bookFreeDemo.map(demo => {
          const subjects = demo.subjectId ? demo.subjectId.map(sub => sub.name).join(';') : '-';
          const className = demo.classId ? demo.classId.name : '-';
          return `${subjects} (${className})`;
        }).join(' | ') : 'N/A';
        const city = item.cityId?.name ? item.cityId?.name : '-';
        const address = item.address ? removeCommas(`"${item.address}"`) : '-';

        const DateTime = item?.demoTime
        const Gender = item.requiredGender ? item.requiredGender : '-';
        const Remark = item.remark ? item.remark : '-';
        const row = [parentName, mobNo, studentsClasses, city, address, DateTime, Gender, Remark].join(',');
        csv += row + '\r\n';
      });
      console.log("csv____________", csv)
      return csv
    }

    ;
  };

  useEffect(() => {
    dispatch(cityAction.cityGet({ isManagement: true }))
  }, [])

  return (
    <Fragment>
      <section className=" bg-[#ffffff] lg:block hidden border-r border pl-4">
        <div className="w-full py-3 px-10 flex justify-between items-center">
          <HeaderHeading label={"Tuition Management"} />
          <div className="flex items-center gap-6">
            <SearchIconComponent handleSearch={handleSearch} searchText={searchText} />
            <div className="flex relative  city_select cursor-pointer items-center">
              <select onChange={(e) => handleCity(e)} className="flex items-center capitalize	 justify-center bg-white border-2 border-black text-black rounded-lg w-full lg:w-36 px-4 h-9" value={headerCity}
                disabled={roleId !== 1 ? true : false}
              >
                <option className="text-black align-middle" value={""}>
                  {" "}
                  <option value=''>All Cities</option>  <FaAngleDown className="ml-4" color="#D9D9D9" />
                </option>
                {selector?.Citys?.cityGetList?.map((city, id) => (
                  <option className="text-black capitalize" value={city._id} key={id}>
                    {" "}
                    {city.name}
                  </option>
                ))}
              </select>
            </div>
            {roleId === 1 &&
              <div className="flex justify-end">
                {
                  active === "on-going-tuition" ? (
                    <button className="bg-white px-4 py-1.5 border-2 border-black gap-2  flex justify items-center hover:text-white hover:bg-[#023565] hover:scale-105 transition-all duration-300 ease-in-out-center rounded-lg" onClick={() => downloadCSV(convertToCSV(onGoingTuitionData, active))}>
                      <span>Export</span>
                      <span><BiExport size={20} /></span>
                    </button>
                  ) : active === "demo-request" ? (
                    <button className="bg-white px-4 py-1.5 border-2 border-black gap-2 flex justify- items-center hover:text-white hover:bg-[#023565] hover:scale-105 transition-all duration-300 ease-in-outcenter rounded-lg" onClick={() => downloadCSV(convertToCSV(demoRequestTuitionData, active))}>
                      <span>Export</span>    <span><BiExport size={20} /></span>
                    </button>
                  ) : active === "tutor-assigned" ? (
                    <button className="bg-white px-4 py-1.5 border-2 border-black flex justify-center items-center hover:text-white hover:bg-[#023565] hover:scale-105 transition-all duration-300 ease-in-out rounded-lg" onClick={() => downloadCSV(convertToCSV(tutorAssignedTuitionData, active))}>
                      <span>Export</span>    <span><BiExport size={20} /></span>
                    </button>
                  ) : active === "on-demo" ? (
                    <button className="bg-white px-4 py-1.5 border-2 border-black flex justify-center items-center hover:text-white hover:bg-[#023565] hover:scale-105 transition-all duration-300 ease-in-out rounded-lg" onClick={() => downloadCSV(convertToCSV(onDemoTuitionData, active))}>
                      <span>Export</span>    <span><BiExport size={20} /></span>
                    </button>
                  ) : active === "demo-done" ? (
                    <button className="bg-white px-4 py-1.5 border-2 border-black flex justify-center items-center hover:text-white hover:bg-[#023565] hover:scale-105 transition-all duration-300 ease-in-out rounded-lg" onClick={() => downloadCSV(convertToCSV(demoDoneTuitionData, active))}>
                      <span>Export</span>    <span><BiExport size={20} /></span>
                    </button>
                  ) : active === "on-hold" ? (
                    <button className="bg-white px-4 py-1.5 border-2 border-black flex justify-center items-center hover:text-white hover:bg-[#023565] hover:scale-105 transition-all duration-300 ease-in-out rounded-lg" onClick={() => downloadCSV(convertToCSV(onHoldTuitionData, active))}>
                      <span>Export</span>    <span><BiExport size={20} /></span>
                    </button>
                  ) : active === "completed" ? (
                    <button className="bg-white px-4 py-1.5 border-2 border-black flex justify-center items-center hover:text-white hover:bg-[#023565] hover:scale-105 transition-all duration-300 ease-in-out rounded-lg" onClick={() => downloadCSV(convertToCSV(completedTuitionData, active))}>
                      <span>Export</span>    <span><BiExport size={20} /></span>
                    </button>
                  ) : active === "rejected" ? (
                    <button className="bg-white px-4 py-1.5 border-2 border-black flex justify-center items-center hover:text-white hover:bg-[#023565] hover:scale-105 transition-all duration-300 ease-in-out rounded-lg" onClick={() => downloadCSV(convertToCSV(rejectedTuitionData, active))}>
                      <span>Export</span>    <span><BiExport size={20} /></span>
                    </button>
                  ) : null
                }

              </div>
            }
          </div>
          <HeaderProfileComp />
        </div>
      </section>

      <section className="bg-white lg:hidden block">
        <MobileSearchHeader handleSearch={handleSearch} searchText={searchText} />
      </section>
    </Fragment>
  )
}

export default TuitionHeader
