import { myprofileConstant } from "../_constants";
export default function MyProfile(state = {}, action) {
    switch (action.type) {

        case myprofileConstant.GET_ADMIN_PROFILE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case myprofileConstant.GET_ADMIN_PROFILE_SUCCESS:
            return {
                ...state,
                loading: false,
                getAdminProfile: action?.user

            };
        case myprofileConstant.GET_ADMIN_PROFILE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };




        case myprofileConstant.UPDATE_ADMIN_PROFILE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case myprofileConstant.UPDATE_ADMIN_PROFILE_SUCCESS:
            return {
                ...state,
                loading: false,
              

            };
        case myprofileConstant.UPDATE_ADMIN_PROFILE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };


            case myprofileConstant.UPDATE_PASSWORD_REQUEST:
                return {
                    ...state,
                    loading: true,
                };
            case myprofileConstant.UPDATE_PASSWORD_SUCCESS:
                return {
                    ...state,
                    loading: false,
                  
    
                };
            case myprofileConstant.UPDATE_PASSWORD_FAILURE:
                return {
                    ...state,
                    loading: false,
                    error: action.error,
                };
    


        default:
            return state;

    }

}