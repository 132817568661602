import React from 'react';
import { FaFilterCircleXmark } from 'react-icons/fa6';


const FilterComponent = ({ allPincodeList, allClassListByAdminData, subjectListData, filters, setFilters, handleFilterSearchSearch }) => {
    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters(prevFilters => ({
            ...prevFilters,
            [name]: value
        }));

        handleFilterSearchSearch({ [name]: value })
    };

    const handleFilterReset = () => {
        if (filters.hasOwnProperty('basicInfo')) {
            setFilters({
                pinCode: '',
                gender: '',
                classId: '',
                subjectId: '',
                teachingMode: '',
                basicInfo: ''
            })
            handleFilterSearchSearch({
                pinCode: '',
                gender: '',
                classId: '',
                subjectId: '',
                teachingMode: '',
                basicInfo: ''
            })
        } else {
            setFilters({
                pinCode: '',
                gender: '',
                classId: '',
                subjectId: '',
                teachingMode: ''
            })
            handleFilterSearchSearch({
                pinCode: '',
                gender: '',
                classId: '',
                subjectId: '',
                teachingMode: ''
            })
        }

    };

    return (
        <div className="filter flex justify-end mt-6 flex-col md:flex-row gap-4">
            <select
                name="pinCode"
                onChange={handleFilterChange}
                value={filters.pinCode}
                className="block w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 capitalize"
            >
                <option value=''>Pincode</option>
                {
                    Array.isArray(allPincodeList) && allPincodeList.length > 0
                        ? allPincodeList.map((pin) => (
                            <option key={pin} value={pin}>{pin}</option>
                        ))
                        : null
                }
            </select>

            <select
                name="gender"
                onChange={handleFilterChange}
                value={filters.gender}
                className="block w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 capitalize"
            >
                <option value=''>Gender</option>
                <option value="MALE">Male</option>
                <option value="FEMALE">Female</option>
            </select>

            <select
                name="classId"
                onChange={handleFilterChange}
                value={filters.classId}
                className="block w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 capitalize"
            >
                <option value=''>Class</option>
                {
                    allClassListByAdminData && allClassListByAdminData.length > 0 ? (
                        allClassListByAdminData.map((ele) => (
                            <option key={ele._id} value={ele._id}><span className="capitalize">{ele.name}</span></option>
                        ))
                    ) : []
                }
            </select>

            <select
                name="subjectId"
                onChange={handleFilterChange}
                value={filters.subjectId}
                className="block w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 capitalize"
            >
                <option value=''>Subject</option>
                {
                    subjectListData && subjectListData.length > 0 ? (
                        subjectListData.map((ele) => (
                            <option key={ele._id} value={ele._id} className="capitalize">{ele.name}</option>
                        ))
                    ) : []
                }
            </select>

            <select
                name="teachingMode"
                onChange={handleFilterChange}
                value={filters.teachingMode}
                className="block w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 capitalize"
            >
                <option value="">Teaching Mode</option>
                <option value="HOME_TUITION">In-Home</option>
                <option value="ONLINE_TUITION">Online Tuition</option>
            </select>
            {(filters.hasOwnProperty('basicInfo')) &&
                <select
                    name="basicInfo"
                    onChange={handleFilterChange}
                    value={filters.basicInfo}
                    className="block w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 capitalize"
                >
                    <option value="">Reason</option>
                    {/* <option value="PENDING">VERIFICATION PENDING</option> */}
                    <option value="PENDING">BASIC EDUCATION PENDING</option>
                    <option value="BASIC_EDUCATION">TEACHING DETAILS PENDING</option>
                    <option value="TEACHING_DETAILS">KYC PENDING</option>
                    <option value="PAYMENT_EXPIRE">PACKAGE EXPIRE</option>
                    <option value="PAYMENT_PENDING">PLATFORM FEE PENDING</option>
                    <option value="KYC_VERIFICATION">KYC VERIFICATION PENDING</option>
                    <option value="REJECTED_IN_INTERVIEW">REJECTED IN INTERVIEW</option>
                </select>
            }
            <div className='flex items-center' onClick={handleFilterReset}>
                <FaFilterCircleXmark size={20} />
            </div>

        </div>
    );
};

export default FilterComponent;
