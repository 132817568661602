/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { referralAction, userListAction } from '../../_actions'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'
import Loader from '../../components/Loader'
import { timeZoneReturnDateType } from '../../_helpers'
import Select from 'react-select'
import { FaFilterCircleXmark } from 'react-icons/fa6'
import DateRangePicker from '../../components/Atoms/DateRangePicker'
import { cityAction } from '../../_actions/city.action'

const ReferralComponent = ({ searchText }) => {
  const dispatch = useDispatch()

  const userData = JSON.parse(window?.sessionStorage?.getItem('adminuser'))
  const selector = useSelector(state => state)
  const [boardTotal, setBoardTotal] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const pageSize = 10
  const [selectedCity, setSelectedCity] = useState({
    label: 'Selected City',
    value: ''
  })
  const [referedSelectedList, setReferedSelectedList] = useState({
    label: 'Referral To',
    value: ''
  })
  const [referredBy, setReferredBy] = useState({
    label: 'Referral By',
    value: ''
  })
  const customStyles = {
    container: provided => ({
      ...provided
    }),
    control: (provided, state) => ({
      ...provided,
      boxShadow: 'none',
      '&:hover': {
        border: '1px solid #000'
      },
      borderRadius: '5px'
    }),
    menu: provided => ({
      ...provided
    }),
    placeholder: provided => ({
      ...provided,
      color: '#000 !important'
    })
  }

  const [dates, setDates] = useState({ fromDate: '', toDate: '' })

  const handleChange = (value, dateType) => {
    setDates(prevDates => ({ ...prevDates, [dateType]: value }))
  }

  useEffect(() => {
    let boardData = {
      keyWord: searchText,
      pageNo: currentPage,
      size: pageSize,
      refFrom: referredBy.value !== '' ? [referredBy.value] : null,
      userId:
        referedSelectedList.value !== '' ? [referedSelectedList.value] : null,
      ...dates
    }
    let details = {
      cityId: '',
      isManagement: true
    }

    if (selectedCity?.value) {
      boardData['cityId'] = [selectedCity?.value]
    }
    dispatch(cityAction.cityGet({ isManagement: true }))
    dispatch(referralAction.referralGet(boardData))
    dispatch(userListAction.getUserTypeList(details))
  }, [
    currentPage,
    searchText,
    referredBy,
    referedSelectedList,
    dates,
    selectedCity
  ])

  useEffect(() => {
    setBoardTotal(selector?.Referral?.referralList ?? [])
    if (selector?.Referral?.referralList) {
      setTotalPages(
        Math.ceil(selector?.Referral?.referralList.total / pageSize)
      )
    }
  }, [selector])

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1)
  }

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1)
  }

  const options = selector?.userList?.getUserListForSearch?.data
    ? selector?.userList?.getUserListForSearch?.data.map(ele => ({
        value: ele?._id,
        label: `${ele?.name} - ${ele?.mobNo}`
      }))
    : []

  const cityOptions =
    Array.isArray(selector?.Citys?.cityGetList) &&
    selector?.Citys?.cityGetList.length > 0
      ? selector?.Citys?.cityGetList?.map(ele => ({
          value: ele?._id,
          label: `${ele?.name}`
        }))
      : []

  const handleResetFilters = () => {
    setReferredBy({ label: 'Referral By', value: '' })
    setReferedSelectedList({ label: 'Referral To', value: '' })
    setDates({ fromDate: '', toDate: '' })
    setSelectedCity({ label: 'Selected City', value: '' })
  }

  return (
    <>
      <div className='grid lg:grid-cols-5 md:grid-cols-2 gap-4 pt-4'>
        {userData?.roleAccess?.roleId === 1 && (
          <div>
            <label className='invisible md:block hidden' for=''>
              dfs
            </label>
            <Select
              id='city'
              name='cityId'
              value={selectedCity}
              options={[{ label: 'Select City', value: '' }, ...cityOptions]}
              className='capitalize text-black text-sm w-full'
              styles={customStyles}
              classNamePrefix='react-select'
              onChange={(selectedOption, actionMeta) => {
                setSelectedCity(selectedOption)
              }}
            />
          </div>
        )}

        <div>
          <label className='invisible md:block hidden' for=''>
            dfs
          </label>
          <Select
            id='parentId'
            name='parentId'
            value={referredBy}
            options={[{ label: 'Referral By', value: '' }, ...options]}
            className='capitalize text-black text-sm w-full'
            styles={customStyles}
            classNamePrefix='react-select'
            onChange={(selectedOption, actionMeta) => {
              setReferredBy(selectedOption)
            }}
          />
        </div>

        <div>
          <label className='invisible md:block hidden' for=''>
            dfs
          </label>
          <Select
            id='parentId'
            name='parentId'
            value={referedSelectedList}
            options={[{ label: 'Referral To', value: '' }, ...options]}
            className='capitalize text-black text-sm  w-full'
            styles={customStyles}
            classNamePrefix='react-select'
            onChange={(selectedOption, actionMeta) => {
              setReferedSelectedList(selectedOption)
            }}
          />
        </div>
        <div>
          <DateRangePicker
            fromDate={dates.fromDate}
            toDate={dates.toDate}
            handleChange={handleChange}
          />
        </div>
        <div className=''>
          <label className='invisible lg:block hidden' for=''>
            dfs
          </label>
          <button
            className='text-center font-bold text-[#023565] w-full py-2 rounded'
            onClick={() => handleResetFilters()}
          >
            <FaFilterCircleXmark size={24} />
          </button>
        </div>
      </div>

      <Loader loading={selector?.Referral?.loading} />

      <section className='lg:block hidden'>
        <div className='mt-5 border border-[#D3D3D3] rounded-xl bg-white'>
          <table className='min-w-full table-autorounded-xl overflow-x-auto'>
            <thead className='border-b border-[#D1D1D1]'>
              <tr className='text-black'>
                {/* <th className='px-4 py-3 whitespace-nowrap text-sm text-left'>S.No</th> */}
                <th className='px-4 py-3 whitespace-nowrap text-sm text-left'>
                  Referred By
                </th>
                <th className='px-4 py-3 whitespace-nowrap text-sm text-left'>
                  Referred To
                </th>
                <th className='px-4 py-3 whitespace-nowrap text-sm text-left'>
                  Type
                </th>
                <th className='px-4 py-3 whitespace-nowrap text-sm text-left'>
                  Reward Amount
                </th>
                <th className='px-4 py-3 whitespace-nowrap text-sm text-left'>
                  Create Date
                </th>
                <th className='px-4 py-3 whitespace-nowrap text-sm text-left'>
                  Status
                </th>
              </tr>
            </thead>
            <tbody className='overflow-scroll'>
              {boardTotal && boardTotal.list && boardTotal.list.length > 0 ? (
                boardTotal.list.map((item, id) => (
                  <tr key={id} className='border-b capitalize'>
                    {/* <td className='px-4 py-4 text-sm text-gray-900 '>
                      {(currentPage - 1) * pageSize + id + 1}
                    </td> */}
                    <td className='px-4 py-4 text-sm text-gray-900 '>
                      <p className='font-semibold text-[#033565]'>
                        {item?.refFrom?.name ?? null}
                      </p>
                      <p className='text-gray-600'>
                        {item?.refFrom?.mobNo ?? null}
                      </p>
                      <p>
                        {item?.refFrom?.userType
                          ? item.refFrom.userType === 'TUTOR'
                            ? '(Tutor)'
                            : '(Parent)'
                          : null}
                      </p>
                      <p>
                        {item?.refFrom?.city ? item?.refFrom?.city?.name : null}
                      </p>
                    </td>
                    <td className='px-4 py-4 text-sm text-gray-900'>
                      <p className='font-semibold text-[#033565]'>
                        {item?.userId?.name ?? null}
                      </p>
                      <p className='text-gray-600'>
                        {item?.userId?.mobNo ?? null}
                      </p>
                      <p>
                        {item?.userId?.userType
                          ? item.userId.userType === 'TUTOR'
                            ? '(Tutor)'
                            : '(Parent)'
                          : null}
                      </p>
                      <p>
                        {item?.userId?.city ? item?.userId?.city?.name : null}
                      </p>
                    </td>
                    <td className='px-4 py-4 text-sm text-gray-900 '>
                      {item?.type ?? null}
                    </td>
                    <td className='px-4 py-4 text-sm text-gray-900 '>
                      {item?.rewards ?? null}
                    </td>
                    <td className='px-4 py-4 text-sm text-gray-900 '>
                      {timeZoneReturnDateType(item?.createdAt)}
                    </td>
                    <td
                      className={`px-4 py-4 text-sm text-gray-900 font-semibold ${
                        item.status ? 'text-green-700' : 'text-red-600'
                      }`}
                    >
                      {item.status === 1 ? 'Done' : 'Pending'}
                    </td>
                  </tr>
                ))
              ) : (
                <tr className=''>
                  <td
                    colSpan={7}
                    className='px-6 py-4 text-base text-[#313131] whitespace-nowrap text-center'
                  >
                    Data Not Available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </section>

      <section className='overflow-y-auto space-y-4 lg:hidden block'>
        {boardTotal && boardTotal.list && boardTotal.list.length > 0 ? (
          boardTotal.list.map((item, id) => (
            <div className=' bg-white border-2 border-[#023565] rounded-[10px]'>
              <div className='flex justify-between p-2 w-full'>
                <div className='w-full'>
                  <p className='text-[16px] font-[700] text-left'>
                  Referred By	:
                  </p>
                </div>
                <div className='w-full'>
                  <div className='text-[14px] font-[400] text-left capitalize'>
                    <p className='text-[#023565] font-semibold'>{item?.refFrom?.name ?? null}</p>
                    <p className='text-gray-500'>{item?.refFrom?.mobNo ?? null}</p>
                    <p className='text-gray-500'>
                      {' '}
                      {item?.refFrom?.userType
                        ? item.refFrom.userType === 'TUTOR'
                          ? '(Tutor)'
                          : '(Parent)'
                        : null}
                    </p>
                    <p className='text-gray-500'> {item?.refFrom?.city ? item?.refFrom?.city?.name : null}</p>
                  </div>
                </div>
              </div>
              <div className='flex justify-between p-2 w-full'>
                <div className='w-full'>
                  <p className='text-[16px] font-[700] text-left'>
                  Referred To :	
                  </p>
                </div>
                <div className='w-full'>
                  <p className='text-[14px] font-[400] text-left'>
                  <div className='font-semibold text-[#033565]'>
                        {item?.userId?.name ?? null}
                      </div>
                      <p className='text-gray-500'>
                        {item?.userId?.mobNo ?? null}
                      </p>
                      <p className='text-gray-500'>
                        {item?.userId?.userType
                          ? item.userId.userType === 'TUTOR'
                            ? '(Tutor)'
                            : '(Parent)'
                          : null}
                      </p>
                      <p className='text-gray-500'>
                        {item?.userId?.city ? item?.userId?.city?.name : null}
                      </p>
                  </p>
                </div>
              </div>
              <div className='flex justify-between p-2 w-full'>
                <div className='w-full'>
                  <p className='text-[16px] font-[700] text-left'>Type :</p>
                </div>
                <div className='w-full'>
                  <p>{item?.type ?? null}</p>
                </div>
              </div>
              <div className='flex justify-between p-2 w-full'>
                <div className='w-full'>
                  <p className='text-[16px] font-[700] text-left'>
                    {' '}
                    Reward Amount :
                  </p>
                </div>
                <div className='w-full'>
                  <p className='text-[14px] font-[400] text-left'>
                    <p>{item?.rewards ?? null}</p>
                  </p>
                </div>
              </div>
              <div className='flex justify-between p-2 w-full'>
                <div className='w-full'>
                  <p className='text-[16px] font-[700] text-left'>
                    Created at :
                  </p>
                </div>
                <div className='w-full'>
                  <p className='text-[14px] font-[400] text-left'>
                    {' '}
                    {timeZoneReturnDateType(item?.createdAt)}
                  </p>
                </div>
              </div>
              <div className='flex justify-between p-2 w-full'>
                <div className='w-full'>
                  <p className='text-[16px] font-[700] text-left'>Status :</p>
                </div>
                <div className='w-full'>
                  <p className='text-[14px] font-[400] text-left'>
                    <span
                      className={`cursor-pointer font-semibold ${
                        item.isDisable ? 'text-red-600' : 'text-[#023565]'
                      }`}
                    >
                      {item.status === 1 ? 'Done' : 'Pending'}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className='rounded-[10px] bg-white p-6 border border-[#023565]'>
            <p className='text-[16px] font-[700] text-center'>
              Data Not Available
            </p>
          </div>
        )}
      </section>

      {totalPages > 1 && (
        <div className='flex justify-end items-center py-2'>
          <button
            className={`px-4 py-2 border text-white bg-[#023565] rounded ${
              currentPage === 1 ? 'opacity-50 cursor-not-allowed' : ''
            }`}
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
          >
            <FaChevronLeft />
          </button>
          <span className='text-gray-700 mx-1'>
            <span className='border px-4 py-2 text-black  rounded'>
              {currentPage}
            </span>{' '}
            of{' '}
            <span className='border px-4 py-2 text-black  rounded'>
              {totalPages}
            </span>
          </span>
          <button
            className={`px-4 py-2 border text-white bg-[#023565] rounded ${
              currentPage === totalPages ? 'opacity-50 cursor-not-allowed' : ''
            }`}
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            <FaChevronRight />
          </button>
        </div>
      )}
    </>
  )
}

export default ReferralComponent
