import React, { useState } from 'react'
import { useDispatch } from "react-redux";
import Button from './Button'
import ActiveTutor from './ActiveTutor'
import InActiveTutor from './InActiveTutor'
import BlockedTutors from './BlockedTutors'
import TutorCbHeader from './TutorCbHeader'
import { userListAction } from '../../_actions';

const TutorProfile = () => {
  const dispatch = useDispatch();
  const [active, setActive] = useState("Active Tutors")
  const buttonName = ["Active Tutors", "Inactive Tutors", "Blocked Tutors"]
  const [searchText, setSearchText] = useState("");


  const handleClick = (data) => {
    setActive(data)
    setSearchText('')
  }
  const handleSearch = (e) => {
    setSearchText(e.target.value);

    let status;
    if (active === "Active Parent") status = 'ACTIVE'
    if (active === "Inactive Parent") status = 'INACTIVE'
    if (active === "Blocked Parent") status = 'BLOCK'
    let parentUserList = {
      status: status,
      userType: "TUTOR",
      isManagement: false,
      keyWord: e.target.value,
      fromDate: "",
      toDate: "",
      sortOrder: "asc",
      sortBy: "createdAt",
      pageNo: 1,
      size: 10,
    };
    dispatch(userListAction.getUserList(parentUserList));
  };
  return (
    <>
      <TutorCbHeader handleSearch={handleSearch} searchText={searchText} />

      <section className='overflow-hidden overflow-y-auto'>

        <div className="m-5">
          <section className='flex gap-3'>

            {
              buttonName.map((item, id) => (
                <div className={`px-3 py-2 rounded-full lg:text-sm text-[10px]  font-semibold ${active === item ? 'bg-[#023565] text-white' : 'bg-gray-400 text-white'}`} onClick={() => handleClick(item)}>
                  <Button key={id} name={item} />
                </div>
              ))
            }
          </section>
          <section>
            {active === "Active Tutors" && <ActiveTutor searchText={searchText} />}
            {active === "Inactive Tutors" && <InActiveTutor searchText={searchText} />}
            {active === "Blocked Tutors" && <BlockedTutors searchText={searchText} />}
          </section>
        </div>
      </section>
    </>
  )
}

export default TutorProfile