import { uploadImagConstants } from "../_constants";

export default function ImageUpload(state = {}, action) {
    switch (action.type) {
        case uploadImagConstants.UPLOAD_IMAGE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case uploadImagConstants.UPLOAD_IMAGE_SUCCESS:
            return {
                ...state,
                loading: false,
                fileDetails: action.user.data
            };
        case uploadImagConstants.UPLOAD_IMAGE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };


        default:
            return state;

    }
}