/* eslint-disable no-unused-vars */
import React, { Fragment } from 'react'
import HeaderProfileComp from '../../components/Header/HeaderProfileComp';
import SearchIconComponent from '../../components/Header/SearchIconComponent';
import HeaderHeading from '../../components/Header/HeaderHeading';
import MobileSearchHeader from '../../components/Header/MobileSearchHeader';
import { useSelector } from 'react-redux';

const FeedbackHeader = ({ handleSearch, searchText }) => {
  const selector = useSelector((state) => state);

  return (
    <Fragment>
      <section className=" bg-[#ffffff] lg:block hidden border-r border pl-4">
        <div className="w-full py-3 px-10 flex justify-between items-center">
          <HeaderHeading label={"Feedback"} />
          <div className="flex items-center gap-6">
            <SearchIconComponent handleSearch={handleSearch} searchText={searchText} />
            {/* <div className="flex gap-2 relative city_select cursor-pointer items-center">
              <select className="flex items-center justify-center capitalize bg-white border-2 text-black rounded-lg w-full lg:w-36 px-4 h-9"
                value="{headerCity}">
                <option className="text-black align-middle" value="">
                All Cities <FaAngleDown className="ml-3" color="#D9D9D9" />
                </option>
                {selector?.Citys?.cityGetList?.map((city, id) => (
                  <option className="text-black capitalize" value={city._id} key={id}>
                    {city.name}
                  </option>
                ))}
              </select>

            </div> */}
          </div>
          <HeaderProfileComp />
        </div>
      </section>
      <section className="bg-white lg:hidden block">
        <MobileSearchHeader handleSearch={handleSearch} searchText={searchText} />
      </section>
    </Fragment>
  )
}

export default FeedbackHeader
