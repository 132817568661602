import React from 'react'

const ButtonCom = ({ name, onClick, value }) => {
  return (
    <>
      <button className='' onClick={onClick}>{name} ({value})</button>
    </>
  )
}

export default ButtonCom