/* eslint-disable react-hooks/exhaustive-deps */
import Multiselect from 'multiselect-react-dropdown'
import React, { useEffect, useState } from 'react'
import { MdOutlineClose } from 'react-icons/md'
import { tutionAction, userListAction } from '../../../_actions';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { tutorUpdateInitialState } from '../../../_helpers/dataInitialState';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';



const CustomDatePicker = ({ selectedDate, onDateChange, value }) => {
  return (
    <div className="relative shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
      <DatePicker
        selected={selectedDate}
        onChange={onDateChange}
        value={value}
        dateFormat="DD/MM/YYYY"
        className="focus:outline-none focus:shadow-outline"
      />
      <div className="absolute inset-y-0 right-0 pr-4 flex items-center pointer-events-none" selected={selectedDate}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5 text-gray-400"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M8 7V3m8 4V3m-9 8h10M5 8h14a2 2 0 012 2v10a2 2 0 01-2 2H5a2 2 0 01-2-2V10a2 2 0 012-2z"
          />
        </svg>
      </div>
    </div>
  );
};



const teachModeOption = [
  { _id: "HOME_TUITION", name: "In-Home" },
  { _id: "ONLINE_TUITION", name: "Online" },
];

function uniqueDataFunc(array = []) {
  const set = new Set();
  return array.filter(item => {
    if (set.has(item?._id)) {
      return false;
    } else {
      set.add(item?._id);
      return true;
    }
  });
}


function DobFormat(date) {
  const guessedTimezone = moment.tz.guess();
  const DOB = date ? moment(date).tz(guessedTimezone).format('DD/MM/YYYY')
    : "--/--/--";
  return DOB
}



function UpdateTutor({ closeModal, newAcceptData, acceptModal, currentPage, pageSize, setNewAcceptData, selectedTeachMode, setSelectedTeachMode,
  selectedPinCode, setSelectedPinCode,
  subjectYouCanTeach, setSubjectYouCanTeach,
  selectedClass, setSelectedClass, status }) {

  const [subjectOptions, setSubjectOptions] = useState([])
  const [cityData, setCityData] = useState({
    _id: "",
    name: "",
    pinCode: []
  })
  const selector = useSelector(state => state)
  const [errors, setErrors] = useState({});

  useEffect(() => {
    dispatch(tutionAction.gitAllClassListByAdmin())
  }, [])

  useEffect(() => {
    if (selector?.tution?.getAllClassList?.data && selector?.tution?.getAllClassList?.data.length > 0) {
      const subjectOptions = selector?.tution?.getAllClassList?.data.reduce((acc, ele) => {
        return acc.concat(ele.subjectId);
      }, []);
      setSubjectOptions(subjectOptions);
    } else {
      setSubjectOptions([]);
    }
  }, [selector?.tution?.getAllClassList?.data])

  useEffect(() => {
    const selectedCity = selector?.Citys?.cityGetList?.find(city => city._id === newAcceptData.city._id);
    if (selectedCity) {
      setCityData({
        _id: selectedCity._id,
        name: selectedCity.name,
        pinCode: selectedCity.pinCode
      });

    } else {
      setCityData({
        _id: "",
        name: "",
        pinCode: []
      });
    }

  }, [newAcceptData])



  const dispatch = useDispatch();
  const handleTeachModelSelect = (selectedList, selectedItem) => {

    setSelectedTeachMode(selectedList);
  };

  const handleRemoveTeachMode = (selectedList, removedItem) => {
    setSelectedTeachMode(selectedList);
  };

  const handelPinCodeYouCanGoForTeach = (selectedList, selectedItem) => {
    setSelectedPinCode(selectedList);
  };

  const removePinCodeYouCanGoForTeach = (selectedList, removedItem) => {
    setSelectedPinCode(selectedList);
  };

  const handleSubjectYouCanTeach = (selectedList, selectedItem) => {
    setSubjectYouCanTeach(selectedList);
  };

  const handleRemoveSubject = (selectedList, removedItem) => {
    setSubjectYouCanTeach(selectedList);
  };

  const handelSelectClassGoForTeach = (selectedList, selectedItem) => {
    setSelectedClass(selectedList);
  };

  const handleRemoveMultiSelect = (selectedList, removedItem) => {
    setSelectedClass(selectedList);
  };


  const handelOnChangeInput = (e) => {
    const { name, value } = e.target;
    setNewAcceptData(prevState => ({
      ...prevState,
      [name]: value
    }));
    setErrors(prevState => ({
      ...prevState,
      [name]: ""
    }));
  };


  const handleOnSelect = (event) => {
    const selectedCityId = event.target.value;
    const selectedCity = selector?.Citys?.cityGetList.find(city => city._id === selectedCityId);
    if (selectedCity) {
      setCityData({
        _id: selectedCity._id,
        name: selectedCity.name,
        pinCode: selectedCity.pinCode
      });
      setNewAcceptData(prevState => ({
        ...prevState,
        city: selectedCity,
        pinCode: ""
      }));
      setSelectedPinCode([])
    } else {
      setCityData({});
      setNewAcceptData(prevState => ({
        ...prevState,
        city: "",
        pinCode: ""
      }));
      setSelectedPinCode([])


    }
    setErrors((prev) => ({
      ...prev,
      [event.target.name]: ""
    }))
  };

  const handleValidation = () => {
    let tempErrors = {};
    let isValid = true;

    if (!newAcceptData?.email || !/\S+@\S+\.\S+/.test(newAcceptData.email)) {
      tempErrors.email = "Valid email is required";
      isValid = false;
    }

    if (!newAcceptData?.name) {
      tempErrors.name = "Name is required";
      isValid = false;
    }

    if (!newAcceptData?.whatsappNo || !/^\d+$/.test(newAcceptData.whatsappNo)) {
      tempErrors.whatsappNo = "Valid WhatsApp number is required";
      isValid = false;
    }

    if (!newAcceptData?.address) {
      tempErrors.address = "Address is required";
      isValid = false;
    }

    if (!newAcceptData?.city?._id) {
      tempErrors.city = "City is required";
      isValid = false;
    }

    if (!newAcceptData?.pinCode) {
      tempErrors.pinCode = "Pin code is required";
      isValid = false;
    }

    if (!newAcceptData?.dob) {
      tempErrors.dob = "Date of birth is required";
      isValid = false;
    }

    if (!newAcceptData?.gender) {
      tempErrors.gender = "Gender is required";
      isValid = false;
    }

    if (!newAcceptData?.schoolName) {
      tempErrors.schoolName = "School name is required";
      isValid = false;
    }
    if (!newAcceptData?.schoolPassOutYear) {
      tempErrors.schoolPassOutYear = "Year is required";
      isValid = false;
    }
    if (!newAcceptData?.ugCollegeName) {
      tempErrors.ugCollegeName = "UG collage name is required";
      isValid = false;
    }

    if (!newAcceptData?.ugPassOutYear) {
      tempErrors.ugPassOutYear = "UG passout year is required";
      isValid = false;
    }

    if (!newAcceptData?.teachingExp) {
      tempErrors.teachingExp = "Experience is required";
      isValid = false;
    }



    if (!selectedTeachMode?.length) {
      tempErrors.teachingMode = "Select at least one teaching mode";
      isValid = false;
    }

    if (!selectedClass?.length) {
      tempErrors.classYouCanTeach = "Select at least one class";
      isValid = false;
    }

    if (!subjectYouCanTeach?.length) {
      tempErrors.subjectYouCanTeach = "Select at least one subject";
      isValid = false;
    }

    if (!selectedPinCode?.length) {
      tempErrors.pinCodeYouCanGo = "Select at least one pinCode";
      isValid = false;
    }

    setErrors(tempErrors);
    return isValid;
  };




  const handleAccept = () => {
    if (!handleValidation()) {
      return
    }
    let updatedData = {
      id: newAcceptData?._id,
      email: newAcceptData?.email,
      name: newAcceptData?.name,
      whatsappNo: newAcceptData?.whatsappNo,
      address: newAcceptData?.address,
      city: newAcceptData?.city._id,
      pinCode: newAcceptData?.pinCode,
      dob: new Date(newAcceptData?.dob).toUTCString(),
      gender: newAcceptData?.gender,
      schoolName: newAcceptData?.schoolName,
      schoolPassOutYear: newAcceptData?.schoolPassOutYear,
      ugCourse: newAcceptData?.ugCourse,
      ugCollegeName: newAcceptData?.ugCollegeName,
      ugPassOutYear: newAcceptData?.ugPassOutYear,
      pgCourse: newAcceptData?.pgCourse,
      pgPassOutYear: newAcceptData?.pgPassOutYear,
      pgCollegeName: newAcceptData?.pgCollegeName,
      teachingExp: newAcceptData?.teachingExp,
      teachingMode: selectedTeachMode?.map((ele) => (ele?._id)),
      classYouCanTeach: selectedClass?.map((ele) => ele?._id) || [],
      subYouCanTeach: subjectYouCanTeach?.map((ele) => (ele?._id)) || [],
      pinCodeYouCanGo: selectedPinCode?.map((ele) => ele?._id) || []
    };

    let tutorData = {
      status: status,
      userType: "TUTOR",
      keyWord: "",
      fromDate: "",
      toDate: "",
      sortOrder: "desc",
      sortBy: "createdAt",
      pageNo: currentPage,
      size: pageSize,
      isManagement: false
    };
    closeModal()

    dispatch(userListAction.updateTutorByAdmin(updatedData, tutorData))
  };



  const [selectedDate, setSelectedDate] = useState(null);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };


  return acceptModal && (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40">
        <div className="relative w-11/12 mx-auto lg:w-[900px]">
          <button
            className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center"
            onClick={() => {
              closeModal()
              setNewAcceptData(tutorUpdateInitialState)
              setErrors({})
            }}
          >
            <span><MdOutlineClose /></span> Close
          </button>
          <div className="form_class border-0 rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <div className="flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t">
              <h2 className="text-lg font-semibold">Tutor Profile</h2>
            </div>
            <div className="relative lg:h-[550px] h-[400px] Pokercardbar overflow-hidden overflow-y-auto">
              <div className="p-4">
                <div className="grid lg:grid-cols-2 gap-4">
                  <div className="w-full">
                    <label className="text-[12px] font-[700] text-left text-[#313131]">
                      Name:
                    </label>
                    <input
                      type="text"
                      name="name"
                      value={newAcceptData?.name}
                      onChange={handelOnChangeInput}
                      className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                    />
                    {errors.name && <p className="text-red-500 text-sm">{errors.name}</p>}

                  </div>
                  <div className="w-full">
                    <label className="text-[12px] font-[700] text-left text-[#313131]">
                      Email ID:{" "}
                    </label>
                    <input
                      type="text"
                      name="email"
                      value={newAcceptData?.email}
                      onChange={handelOnChangeInput}
                      className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                    />
                    {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}

                  </div>

                  <div className="w-full">
                    <label className="text-[12px] font-[700] text-left text-[#313131]">
                      WhatsApp No.:{" "}
                    </label>
                    <input
                      type="text"
                      name="whatsappNo"
                      value={newAcceptData?.whatsappNo}
                      onChange={handelOnChangeInput}
                      className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                    />
                    {errors.whatsappNo && <p className="text-red-500 text-sm">{errors.whatsappNo}</p>}

                  </div>

                  <div className="w-full">
                    <label className="text-[12px] font-[700] text-left text-[#313131]">
                      Address:
                    </label>

                    <input
                      type="text"
                      name="address"
                      value={newAcceptData?.address}
                      onChange={handelOnChangeInput}
                      className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                    />
                    {errors.address && <p className="text-red-500 text-sm">{errors.address}</p>}

                  </div>
                  <div className="w-full">
                    <label className="text-[12px] font-[700] text-left text-[#313131]">
                      City:
                    </label>
                    <select
                      name="city"
                      value={cityData?._id || newAcceptData?.city?._id}
                      onChange={handleOnSelect}
                      className="mt-1 p-2 border block w-full border-gray-300 bg-white rounded-md capitalize"
                    >
                      <option value={""} className="capitalize" > Select city</option>
                      {selector?.Citys?.cityGetList && selector?.Citys?.cityGetList.length > 0 ? selector?.Citys?.cityGetList.map((city) => (
                        <option key={city._id} className="capitalize" value={city._id}>{city.name}</option>
                      )) : null}
                    </select>
                    {errors.city && <p className="text-red-500 text-sm">{errors.city}</p>}


                  </div>
                  <div className="w-full">
                    <label className="text-[12px] font-[700] text-left text-[#313131]">
                      PinCode:{" "}
                    </label>
                    <input
                      type="text"
                      name="pinCode"
                      value={newAcceptData?.pinCode}
                      onChange={handelOnChangeInput}
                      className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                    />
                    {errors.pinCode && <p className="text-red-500 text-sm">{errors.pinCode}</p>}

                  </div>
                  <div className="w-full flex flex-col space-y-2">
                    <label className="text-[12px] font-[700] text-left text-[#313131]">
                      Date Of Birth:{" "}
                    </label>
                    {/* <input
                      type="date"
                      name="dob"
                      onChange={handelOnChangeInput}
                      value={DobFormat(newAcceptData?.dob)}
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    /> */}
                    <CustomDatePicker selectedDate={selectedDate} value={DobFormat(newAcceptData?.dob)} onDateChange={handleDateChange} />
                    {errors.dob && <p className="text-red-500 text-sm">{errors.dob}</p>}

                  </div>
                  <div className="w-full">
                    <label className="text-[12px] font-[700] text-left text-[#313131]">
                      Gender:{" "}
                    </label>
                    <select
                      name="gender"
                      value={newAcceptData?.gender}
                      onChange={handelOnChangeInput}
                      className="mt-1 p-2 border block w-full bg-white border-gray-300 rounded-md"
                    >
                      <option value="">Select gender</option>
                      <option value="MALE">Male</option>
                      <option value="FEMALE">Female</option>
                      <option value="OTHER">Other</option>
                    </select>
                    {errors.gender && <p className="text-red-500 text-sm">{errors.gender}</p>}

                  </div>

                  <div className="w-full ">
                    <label className="text-[12px] font-[700] text-left text-[#313131]">
                      School Name:{" "}
                    </label>

                    <input
                      type="text"
                      name="schoolName"
                      value={newAcceptData?.schoolName}
                      onChange={handelOnChangeInput}
                      className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                    />
                    {errors.schoolName && <p className="text-red-500 text-sm">{errors.schoolName}</p>}

                  </div>

                  <div className="w-full">

                    <label className="text-[12px] font-[700] text-left text-[#313131]">
                      Passout Year:{" "}
                    </label>
                    <input
                      type="number"
                      name="schoolPassOutYear"
                      value={newAcceptData?.schoolPassOutYear}
                      onChange={handelOnChangeInput}
                      className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                    />
                    {errors.schoolPassOutYear && <p className="text-red-500 text-sm">{errors.schoolPassOutYear}</p>}

                  </div>
                  <div className="w-full">
                    <label className="text-[12px] font-[700] text-left text-[#313131]">
                      UG Course:{" "}
                    </label>
                    <input
                      type="text"
                      name="ugCourse"
                      value={newAcceptData?.ugCourse}
                      onChange={handelOnChangeInput}
                      className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                    />
                    {errors.ugCourse && <p className="text-red-500 text-sm">{errors.ugCourse}</p>}

                  </div>

                  <div className="w-full">
                    <label className="text-[12px] font-[700] text-left text-[#313131]">
                      UG College Name:{" "}
                    </label>
                    <input
                      type="text"
                      name="ugCollegeName"
                      value={newAcceptData?.ugCollegeName}
                      onChange={handelOnChangeInput}
                      className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                    />
                    {errors.ugCollegeName && <p className="text-red-500 text-sm">{errors.ugCollegeName}</p>}

                  </div>


                  <div className="w-full">
                    <label className="text-[12px] font-[700] text-left text-[#313131]">
                      Passout Year:{" "}
                    </label>
                    <input
                      type="number"
                      name="ugPassOutYear"
                      value={newAcceptData?.ugPassOutYear}
                      onChange={handelOnChangeInput}
                      className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                    />
                    {errors.ugPassOutYear && <p className="text-red-500 text-sm">{errors.ugPassOutYear}</p>}

                  </div>

                  <div className="w-full">
                    <label className="text-[12px] font-[700] text-left text-[#313131]">
                      PG College Name: (Optional){" "}
                    </label>
                    <input
                      type="text"
                      name="pgCollegeName"
                      value={newAcceptData?.pgCollegeName}
                      onChange={handelOnChangeInput}
                      className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                    />
                  </div>
                  <div className="w-full">
                    <label className="text-[12px] font-[700] text-left text-[#313131]">
                      PG Course:(Optional){" "}
                    </label>
                    <input
                      type="text"
                      name="pgCourse"
                      value={newAcceptData?.pgCourse}
                      onChange={handelOnChangeInput}

                      className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                    />
                  </div>

                  <div className="w-full">
                    <label className="text-[12px] font-[700] text-left text-[#313131]">
                      PG Pass Out Year: (Optional){" "}
                    </label>
                    <input
                      type="number"
                      name="pgPassOutYear"
                      value={newAcceptData?.pgPassOutYear}
                      onChange={handelOnChangeInput}
                      className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                    />
                  </div>

                  <div className="w-full">
                    <label className="text-[12px] font-[700] text-left text-[#313131]">
                      Teaching Experience:
                    </label>
                    <input
                      type="number"
                      name="teachingExp"
                      value={newAcceptData?.teachingExp}
                      onChange={handelOnChangeInput}
                      className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                    />
                    {errors.teachingExp && <p className="text-red-500 text-sm">{errors.teachingExp}</p>}

                  </div>

                  <div className="w-full">
                    <label className="text-[12px] font-[700] text-left text-[#313131]">
                      Selected Classes:
                    </label>
                    <Multiselect
                      options={selector?.tution?.getAllClassList?.data}
                      selectedValues={selectedClass}
                      onSelect={handelSelectClassGoForTeach}
                      onRemove={handleRemoveMultiSelect}
                      displayValue="name"
                      className='capitalize'
                    />
                    {errors.classYouCanTeach && <p className="text-red-500 text-sm">{errors.classYouCanTeach}</p>}

                  </div>


                  <div className="w-full">
                    <label className="text-[12px] font-[700] text-left text-[#313131]">
                      Selected Subject:
                    </label>
                    <Multiselect
                      options={uniqueDataFunc(subjectOptions)}
                      selectedValues={subjectYouCanTeach}
                      onSelect={handleSubjectYouCanTeach}
                      onRemove={handleRemoveSubject}
                      displayValue="name"
                      className='capitalize'

                    />
                    {errors.subjectYouCanTeach && <p className="text-red-500 text-sm">{errors.subjectYouCanTeach}</p>}

                  </div>

                  <div className="w-full">
                    <label className="text-[12px] font-[700] text-left text-[#313131]">
                      Teaching Pincode:
                    </label>
                    <Multiselect
                      options={cityData.pinCode?.map((ele) => ({ name: ele, _id: ele }))}
                      selectedValues={selectedPinCode}
                      onSelect={handelPinCodeYouCanGoForTeach}
                      onRemove={removePinCodeYouCanGoForTeach}
                      displayValue="name"
                      className='capitalize'

                    />
                    {errors.pinCodeYouCanGo && <p className="text-red-500 text-sm">{errors.pinCodeYouCanGo}</p>}

                  </div>

                  <div className="w-full">
                    <label className="text-[12px] font-[700] text-left text-[#313131]">
                      Preferred Teaching Mode:{" "}
                    </label>
                    <Multiselect
                      options={teachModeOption}
                      selectedValues={selectedTeachMode}
                      onSelect={handleTeachModelSelect}
                      onRemove={handleRemoveTeachMode}
                      displayValue="name"
                      className='capitalize'

                    />
                    {errors.teachingMode && <p className="text-red-500 text-sm">{errors.teachingMode}</p>}

                  </div>

                </div>
                <div className="flex justify-center pt-4">
                  <button
                    className="w-60 mx-auto bg-[#023565] text-white font-semibold py-2 px-4 rounded-full "
                    onClick={() => handleAccept()}
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default UpdateTutor