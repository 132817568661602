import { headerForPrivateAPI, APIcallFunction } from "../_helpers";
import { notificationConstant } from "../_constants";

export const notificationAction = {
  getNotificationListByAdmin,
  createCustomNotification,
  notificationCount,
  adminNotificationsList,
  readNotificationsByAdmin,
  readNotificationsByAdminByPromise
}

function readNotificationsByAdminByPromise(data) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "notification/readNotificationsByAdmin"
  };

  return APIcallFunction(credentials)

}


function getNotificationListByAdmin(data) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "notification/getAdminNotificationList"
  };
  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (user) => {
        dispatch(success(user));

      },
      (error) => {
        dispatch(failure(error));

      }
    );

  };
  function request() {
    return { type: notificationConstant.GET_ADMIN_NOTIFICATION_LIST_REQUEST };
  }
  function success(user) {
    return { type: notificationConstant.GET_ADMIN_NOTIFICATION__LIST_SUCCESS, user };
  }
  function failure(error) {
    return { type: notificationConstant.GET_ADMIN_NOTIFICATION__LIST_FAILURE, error };
  }
}

function createCustomNotification(data, notificationDetals) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "notification/createCustomNotification"
  };
  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (user) => {
        dispatch(success(user));
        dispatch(getNotificationListByAdmin(notificationDetals))

      },
      (error) => {
        dispatch(failure(error));

      }
    );

  };
  function request() {
    return { type: notificationConstant.CREATE_ADMIN_NOTIFICATION__LIST_REQUEST };
  }
  function success(user) {
    return { type: notificationConstant.CREATE_ADMIN_NOTIFICATION__LIST_SUCCESS, user };
  }
  function failure(error) {
    return { type: notificationConstant.CREATE_ADMIN_NOTIFICATION__LIST_FAILURE, error };
  }
}




function notificationCount(data, notificationDetals) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "notification/notificationCount "
  };
  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (user) => {
        dispatch(success(user));
        // dispatch(getNotificationListByAdmin(notificationDetals))

      },
      (error) => {
        dispatch(failure(error));

      }
    );

  };
  function request() {
    return { type: notificationConstant.GET_NOTIFICATION_COUNT_REQUEST };
  }
  function success(user) {
    return { type: notificationConstant.GET_NOTIFICATION_COUNT_SUCCESS, user };
  }
  function failure(error) {
    return { type: notificationConstant.GET_NOTIFICATION_COUNT_FAILURE, error };
  }
}



function adminNotificationsList(data, notificationDetals) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "notification/adminNotificationsList "
  };
  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (user) => {
        dispatch(success(user));
        // dispatch(getNotificationListByAdmin(notificationDetals))

      },
      (error) => {
        dispatch(failure(error));

      }
    );

  };
  function request() {
    return { type: notificationConstant.ADMIN_NOTIFICATION_LIST_REQUEST };
  }
  function success(user) {
    return { type: notificationConstant.ADMIN_NOTIFICATION_LIST_SUCCESS, user };
  }
  function failure(error) {
    return { type: notificationConstant.ADMIN_NOTIFICATION_LIST_REQUEST, error };
  }
}





function readNotificationsByAdmin(data, notificationDetals) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "notification/readNotificationsByAdmin "
  };
  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (user) => {
        dispatch(success(user));
        // dispatch(getNotificationListByAdmin(notificationDetals))
        dispatch(notificationAction.adminNotificationsList(notificationDetals))
        dispatch(notificationCount())

      },
      (error) => {
        dispatch(failure(error));

      }
    );

  };
  function request() {
    return { type: notificationConstant.RED_NOTIFICATION_REQUEST };
  }
  function success(user) {
    return { type: notificationConstant.RED_NOTIFICATION_SUCCESS, user };
  }
  function failure(error) {
    return { type: notificationConstant.RED_NOTIFICATION_FAILURE, error };
  }
}










