export const termsAndConstant={
    GET_TERMSANDCONDITION_LIST_REQUEST:"GET_TERMSANDCONDITION_LIST_REQUEST",
    GET_TERMSANDCONDITION_LIST_SUCCESS:"GET_TERMSANDCONDITION_LIST_SUCCESS",
    GET_TERMSANDCONDITION_LIST_FAILURE:"GET_TERMSANDCONDITION_LIST_FAILURE",
    
    CREATE_TERMSANDCONDITIONT_LIST_REQUEST:"CREATE_TERMSANDCONDITION_LIST_REQUEST",
    CREATE_TERMSANDCONDITION_LIST_SUCCESS:"CREATE_TERMSANDCONDITION_LIST_SUCCESS",
    CREATE_TERMSANDCONDITION_LIST_FAILURE:"CREATE_TERMSANDCONDITION_LIST_FAILURE", 

    DELETE_TERMSANDCONDITION_LIST_REQUEST:"DELETE_TERMSANDCONDITION_LIST_REQUEST",
    DELETE_TERMSANDCONDITION_LIST_SUCCESS:"DELETE_TERMSANDCONDITION_LIST_SUCCESS",
    DELETE_TERMSANDCONDITION_LIST_FAILURE:"DELETE_TERMSANDCONDITION_LIST_FAILURE", 

    UPDATE_TERMSANDCONDITION_LIST_REQUEST:"UPDATE_TERMSANDCONDITION_LIST_REQUEST",
    UPDATE_TERMSANDCONDITION_LIST_SUCCESS:"UPDATE_TERMSANDCONDITION_LIST_SUCCESS",
    UPDATE_TERMSANDCONDITION_LIST_FAILURE:"UPDATE_TERMSANDCONDITION_LIST_FAILURE",

    ENA_DIS_TERMSANDCONDITION_LIST_REQUEST:"ENA_DIS_TERMSANDCONDITION_LIST_REQUEST",
    ENA_DIS_TERMSANDCONDITION_LIST_SUCCESS:"ENA_DIS_TERMSANDCONDITION_LIST_SUCCESS",
    ENA_DIS_TERMSANDCONDITION_LIST_FAILURE:"ENA_DIS_TERMSANDCONDITION_LIST_FAILURE",
}