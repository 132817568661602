import moment from 'moment'
import React from 'react'
import { MdOutlineClose } from 'react-icons/md'

function DetailsPreViewModel({ isModalOpen, closeModal, response, isKyc = false }) {
    function teachModeViewData(data) {
        switch (data) {
            case "HOME_TUITION":
                return "Home Tuition"
            case "ONLINE_TUITION":
                return "Online Tuition"
            default:
                return null
        }
    }
    return isModalOpen && (
        <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40">
                <div className="relative w-11/12 mx-auto lg:w-[800px]">
                    <button
                        className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center"
                        onClick={() => closeModal()}
                    >
                        <span><MdOutlineClose /></span> Close
                    </button>
                    <div className="form_class border-0 rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        <div className="flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                            <h2 className="text-lg font-semibold">Tutor Details</h2>
                        </div>
                        <div className="relative lg:h-[450px] h-[300px] Pokercardbar overflow-hidden overflow-y-auto">
                            <div className="space-y-1 p-4">
                                <div className="flex justify-between w-full">
                                    <div className="w-full">
                                        <p className="text-[14px] font-[700] text-left text-[#313131]">
                                            Email ID{" "}
                                        </p>
                                    </div>
                                    <div className="w-full">
                                        <p className="text-[12px] font-[400] text-left text-[#313131]">{response?.email}</p>
                                    </div>
                                </div>

                                <div className="flex justify-between w-full">
                                    <div className="w-full">
                                        <p className="text-[14px] font-[700] text-left text-[#313131]">
                                            WhatsApp No.{" "}
                                        </p>
                                    </div>
                                    <div className="w-full">
                                        <p className="text-[12px] font-[400] text-left text-[#313131] break-all">
                                            {response?.whatsappNo}
                                        </p>
                                    </div>
                                </div>
                                <div className="flex justify-between w-full">
                                    <div className="w-full">
                                        <p className="text-[14px] font-[700] text-left text-[#313131]">
                                            Address
                                        </p>
                                    </div>
                                    <div className="w-full">
                                        <p className="text-[12px] font-[400] text-left text-[#313131] break-all">
                                            {response?.address}
                                        </p>
                                    </div>
                                </div>
                                <div className="flex justify-between w-full">
                                    <div className="w-full">
                                        <p className="text-[14px] font-[700] text-left text-[#313131]">
                                            Pincode{" "}
                                        </p>
                                    </div>
                                    <div className="w-full">
                                        <p className="text-[12px] font-[400] text-left text-[#313131] break-all">
                                        {response?.pinCode?.join(", ") ?? "N/A"}{" "}
                                        </p>
                                    </div>
                                </div>
                                <div className="flex justify-between w-full">
                                    <div className="w-full">
                                        <p className="text-[14px] font-[700] text-left text-[#313131]">
                                            DOB{" "}
                                        </p>
                                    </div>
                                    <div className="w-full">
                                        <p className="text-[12px] font-[400] text-left text-[#313131] break-all">
                                            {response?.tutorInfoId?.dob ? moment(response.tutorInfoId.dob).format("DD-MMM-YYYY") : "N/A"}
                                        </p>
                                    </div>
                                </div>
                                <div className="flex justify-between w-full">
                                    <div className="w-full">
                                        <p className="text-[14px] font-[700] text-left text-[#313131]">
                                            Gender{" "}
                                        </p>
                                    </div>
                                    <div className="w-full">
                                        <p className="text-[12px] font-[400] text-left text-[#313131] break-all">
                                            {response?.tutorInfoId?.gender === 'MALE' ? 'Male' : 'Female'}
                                        </p>
                                    </div>
                                </div>
                                <div className="flex justify-between w-full">
                                    <div className="w-full">
                                        <p className="text-[14px] font-[700] text-left text-[#313131]">
                                            School Name{" "}
                                        </p>
                                    </div>
                                    <div className="w-full">
                                        <p className="text-[12px] font-[400] text-left text-[#313131] break-all">
                                            {response?.tutorInfoId?.schoolName}
                                        </p>
                                    </div>
                                </div>
                                <div className="flex justify-between w-full">
                                    <div className="w-full">
                                        <p className="text-[14px] font-[700] text-left text-[#313131]">
                                            Passout Year{" "}
                                        </p>
                                    </div>
                                    <div className="w-full">
                                        <p className="text-[12px] font-[400] text-left text-[#313131] break-all">
                                            {response?.tutorInfoId?.schoolPassOutYear}
                                        </p>
                                    </div>
                                </div>
                                <div className="flex justify-between w-full">
                                    <div className="w-full">
                                        <p className="text-[14px] font-[700] text-left text-[#313131]">
                                            UG Course{" "}
                                        </p>
                                    </div>
                                    <div className="w-full">
                                        <p className="text-[12px] font-[400] text-left text-[#313131] break-all">
                                            {response?.tutorInfoId?.ugCourse}
                                        </p>
                                    </div>
                                </div>
                                <div className="flex justify-between w-full">
                                    <div className="w-full">
                                        <p className="text-[14px] font-[700] text-left text-[#313131]">
                                            UG College Name{" "}
                                        </p>
                                    </div>
                                    <div className="w-full">
                                        <p className="text-[12px] font-[400] text-left text-[#313131] break-all">
                                            {response?.tutorInfoId?.ugCollegeName}
                                        </p>
                                    </div>
                                </div>

                                <div className="flex justify-between w-full">
                                    <div className="w-full">
                                        <p className="text-[14px] font-[700] text-left text-[#313131]">
                                            UG Passout Year
                                        </p>
                                    </div>
                                    <div className="w-full">
                                        <p className="text-[12px] font-[400] text-left text-[#313131] break-all">
                                            {response?.tutorInfoId?.ugPassOutYear}
                                        </p>
                                    </div>
                                </div>
                                <div className="flex justify-between w-full">
                                    <div className="w-full">
                                        <p className="text-[14px] font-[700] text-left text-[#313131]">
                                            PG Course (Optional)
                                        </p>
                                    </div>
                                    <div className="w-full">
                                        <p className="text-[12px] font-[400] text-left text-[#313131] break-all">
                                            {response?.tutorInfoId?.pgCourse || "--"}
                                        </p>
                                    </div>
                                </div>


                                <div className="flex justify-between w-full">
                                    <div className="w-full">
                                        <p className="text-[14px] font-[700] text-left text-[#313131]">
                                            PG College Name (Optional)
                                        </p>
                                    </div>
                                    <div className="w-full">
                                        <p className="text-[12px] font-[400] text-left text-[#313131] break-all">
                                            {response?.tutorInfoId?.pgCollegeName || "--"}
                                        </p>
                                    </div>
                                </div>
                                <div className="flex justify-between w-full">
                                    <div className="w-full">
                                        <p className="text-[14px] font-[700] text-left text-[#313131]">
                                            PG Passout Year (Optional)
                                        </p>
                                    </div>
                                    <div className="w-full">
                                        <p className="text-[12px] font-[400] text-left text-[#313131] break-all">
                                            {response?.tutorInfoId?.pgPassOutYear || "--"}
                                        </p>
                                    </div>
                                </div>
                                <div className="flex justify-between w-full">
                                    <div className="w-full">
                                        <p className="text-[14px] font-[700] text-left text-[#313131]">
                                            Teaching Experience{" "}
                                        </p>
                                    </div>
                                    <div className="w-full">
                                        <p className="text-[12px] font-[400] text-left text-[#313131] break-all">
                                            {response?.tutorInfoId?.teachingExp}
                                        </p>
                                    </div>
                                </div>

                                <div className="flex justify-between w-full">
                                    <div className="w-full">
                                        <p className="text-[14px] font-[700] text-left text-[#313131]">
                                            Class{" "}
                                        </p>
                                    </div>
                                    <div className="w-full">
                                        <p className="text-[12px] font-[400] text-left text-[#313131] break-all">


                                            {Array.isArray(response?.tutorInfoId?.classYouCanTeach) &&
                                                response.tutorInfoId.classYouCanTeach.length > 0 ? (
                                                <span>
                                                    {response.tutorInfoId.classYouCanTeach
                                                        .map((cls, id) => cls?.name)
                                                        .join(", ")
                                                        .toUpperCase()}
                                                </span>
                                            ) : (
                                                <span>N/A</span>
                                            )}

                                        </p>
                                    </div>
                                </div>
                                <div className="flex justify-between w-full">
                                    <div className="w-full">
                                        <p className="text-[14px] font-[700] text-left text-[#313131]">
                                            Subject {" "}
                                        </p>
                                    </div>
                                    <div className="w-full">
                                        <p className="text-[12px] font-[400] text-left text-[#313131] break-all">


                                            {Array.isArray(response?.tutorInfoId?.subYouCanTeach) &&
                                                response.tutorInfoId.subYouCanTeach?.length > 0 ? (
                                                <span>
                                                    {response.tutorInfoId.subYouCanTeach
                                                        .map((cls, id) => cls?.name)
                                                        .join(", ")}
                                                </span>
                                            ) : (
                                                <span>N/A</span>
                                            )}

                                        </p>
                                    </div>
                                </div>




                                <div className="flex justify-between w-full">
                                    <div className="w-full">
                                        <p className="text-[14px] font-[700] text-left text-[#313131]">
                                            Preferred Teaching Mode{" "}
                                        </p>
                                    </div>
                                    <div className="w-full">
                                        <p className="text-[12px] font-[400] text-left text-[#313131] break-all">
                                            {Array.isArray(response?.tutorInfoId?.teachingMode)
                                                ? (response.tutorInfoId.teachingMode.map((ele) => teachModeViewData(ele))).join(", ")
                                                : response?.tutorInfoId?.teachingMode}
                                        </p>
                                    </div>
                                </div>
                                <div className="flex justify-between w-full">
                                    <div className="w-full">
                                        <p className="text-[14px] font-[700] text-left text-[#313131]">
                                            Teaching PinCode
                                        </p>
                                    </div>
                                    <div className="w-full">
                                        <p className="text-[12px] font-[400] text-left text-[#313131] break-all">
                                            {response?.tutorInfoId?.pinCodeYouCanGo?.join(", ")}
                                        </p>
                                    </div>
                                </div>
                                {!isKyc && <>
                                <div className="flex justify-between w-full">
                                    <div className="w-full">
                                        <p className="text-[14px] font-[700] text-left text-[#313131]">
                                        Platform Package Name
                                        </p>
                                    </div>
                                    <div className="w-full">
                                        <p className="text-[12px] font-[400] text-left text-[#313131] break-all capitalize">
                                            {response?.package?.packageId?.name ?? "N/A"}
                                        </p>
                                    </div>
                                </div>
                                <div className="flex justify-between w-full">
                                    <div className="w-full">
                                        <p className="text-[14px] font-[700] text-left text-[#313131]">
                                        Package Active Date
                                        </p>
                                    </div>
                                    <div className="w-full">
                                        <p className="text-[12px] font-[400] text-left text-[#313131] break-all capitalize">
                                            {response?.package?.createdAt ? moment(response?.package?.createdAt).format("DD-MMM-YYYY") : "N/A"}
                                        </p>
                                    </div>
                                </div>
                                <div className="flex justify-between w-full">
                                    <div className="w-full">
                                        <p className="text-[14px] font-[700] text-left text-[#313131]">
                                        Package Renewal Date
                                        </p>
                                    </div>
                                    <div className="w-full">
                                        <p className="text-[12px] font-[400] text-left text-[#313131] break-all capitalize">
                                        {response?.package?.renewalDate ? moment(response?.package?.renewalDate).format("DD-MMM-YYYY") : "N/A"}
                                        </p>
                                    </div>
                                </div>
                                </>}
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DetailsPreViewModel