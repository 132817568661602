import React, { useState } from 'react'
import { useDispatch } from "react-redux";
import ButtonComponent from '../Manage/ButtonComponent'
import TutorData from './TutorData'
import ParentStudentData from './ParentStudentData'
import RedemptionData from './RedemptionData'
import WalletCbHeader from './WalletCbHeader'
import { walletAction } from '../../_actions'

const Wallet = () => {
  const dispatch = useDispatch();
  const [active, setActive] = useState("Tutor")
  const [searchText, setSearchText] = useState("")
  const buttonName = ["Tutor", "Parent/Student", "Redemption"]
  const handleClick = (data) => {
    setActive(data)
    setSearchText('')
  }
  const handleSearch = (e) => {
    setSearchText(e.target.value);
    if (active === "Tutor") {
      let walletDetails = {
        isManagement: false,
        userType: "TUTOR",
        keyWord: e.target.value,
        fromDate: "",
        toDate: "",
        sortOrder: "",
        sortBy: "",
        pageNo: 1,
        size: 10,
      };
      dispatch(walletAction.getWalletList(walletDetails));

    }
    if (active === "Parent/Student") {
      let walletDetails = {
        "userType": "PARENT",
        "isManagement": false,
        "keyWord": e.target.value,
        "fromDate": "",
        "toDate": "",
        "sortOrder": "",
        "sortBy": "",
        "pageNo": 1,
        "size": 10
      }
      dispatch(walletAction.getWalletList(walletDetails))
    }
    if (active === "Redemption") {
      let walletDetails = {
        userId: "",
        type: "REDEEM",
        isManagement: false,
        keyWord: e.target.value,
        fromDate: "",
        toDate: "",
        sortOrder: "",
        sortBy: "",
        pageNo: 1,
        size: 10,
      };
      dispatch(walletAction.getWalletHistoryList(walletDetails));
    }



  };

  return (
    <>
      <WalletCbHeader handleSearch={handleSearch} searchText={searchText} />

      <section className='overflow-hidden overflow-y-auto'>
        <div className='m-5 '>
          <section className="flex flex-col sm:flex-row gap-3 sm:text-center">
            {buttonName.map((item, id) => (
              <div
                key={id}
                className={`px-10 py-2 rounded-full text-white ${active === item ? 'bg-[#023565] text-white text-center' : 'bg-gray-400 text-center'
                  }`}
                onClick={() => handleClick(item)}
              >
                <ButtonComponent name={item} />
              </div>
            ))}
          </section>
          <section>
            {active === "Tutor" && <TutorData searchText={searchText} />}
            {active === "Parent/Student" && <ParentStudentData searchText={searchText} />}
            {active === "Redemption" && <RedemptionData searchText={searchText} />}
          </section>
        </div>
      </section>
    </>
  )
}

export default Wallet