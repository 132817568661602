import React from 'react'
import { MdOutlineClose } from 'react-icons/md'

function BlockPopUp({ blockedPoup, setBlockpouUp, name, setName, handleSubmit, nameBlock}) {
    return blockedPoup && (
        <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40">
                <div className="relative w-11/12 mx-auto lg:w-[600px]">
                    <button
                        className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center"
                        onClick={() => setBlockpouUp(false)}
                    >
                        <span><MdOutlineClose /></span> Close
                    </button>
                    <div className="form_class border-0 rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        <div className="flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                            <h2 className="text-lg font-semibold">Are you sure. Do you want to block "<span className="text-red-500 underline">{nameBlock}</span>" ?</h2>
                        </div>
                        <div className="relative lg:h-[200px] overflow-y-auto">
                            <form className="space-y-2 p-4">
                                <div>
                                    <label
                                        htmlFor="subjectName"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Reason:
                                    </label>
                                    <textarea
                                        type="text"
                                        id="subjectName"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                                        required
                                    />
                                </div>
                                <div className="flex justify-center items-center pt-2">
                                    <button
                                        onClick={handleSubmit}
                                        className="w-60 mx-auto bg-[#023565] text-white font-semibold py-2 px-4 rounded-full "
                                    >
                                        Blocked
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BlockPopUp