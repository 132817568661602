import React from 'react'

const Button = ({name,onClick,value}) => {
  return (
    <div>
        <button className='px-2' onClick={onClick}>{name} {value ? `(${value})` : ""}</button>
    </div>
  )
}

export default Button