/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect } from "react";
import { FaAngleDown } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { cityAction } from "../../_actions/city.action";
import HeaderProfileComp from "../../components/Header/HeaderProfileComp";
import SearchIconComponent from "../../components/Header/SearchIconComponent";
import HeaderHeading from "../../components/Header/HeaderHeading";
import MobileSearchHeader from "../../components/Header/MobileSearchHeader";


const WalletHeader = ({ searchText, handleSearch, handleSearchCity,headerCity,roleId }) => {
  const dispatch = useDispatch();
  const selector = useSelector((state) => state);
  useEffect(() => {
    let cityDetails = {
      isManagement : true
    };
    dispatch(cityAction.cityGet(cityDetails));
  }, []);



  return (
    <Fragment>
      <section className=" bg-[#ffffff] lg:block hidden border-r border pl-4">
        <div className="w-full py-3 px-10 flex justify-between items-center">
          <HeaderHeading label={"Wallet"} />
          <div className="flex items-center gap-6">
            <SearchIconComponent handleSearch={handleSearch} searchText={searchText} />

            <div  className="flex relative gap-2 city_select cursor-pointer items-center">
              <select className="flex items-center justify-center bg-white capitalize border-2 text-[#000] rounded-lg w-full lg:w-36 px-4 h-9"
              onChange={handleSearchCity}
              value={headerCity}
              disabled={roleId !== 1 ? true : false}
              >
                <option className="text-black align-middle" value={""}>
                  {" "}
                  All Cities <FaAngleDown className="ml-3" color="#D9D9D9" />
                </option>
                {selector?.Citys?.cityGetList?.map((city, id) => (
                  <option className="text-black capitalize" value={city?._id} key={id}>
                    {" "}
                    {city.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <HeaderProfileComp />
        </div>
      </section>

      <section className="bg-white lg:hidden block">
        <MobileSearchHeader handleSearch={handleSearch} searchText={searchText} />
      </section>
    </Fragment>
  )
}

export default WalletHeader
