/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect } from "react";
import "../Earning.css";
import HeaderProfileComp from "../../../../components/Header/HeaderProfileComp";
import HeaderHeading from "../../../../components/Header/HeaderHeading";
import MobileSearchHeader from "../../../../components/Header/MobileSearchHeader";
import { FaAngleDown } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { cityAction } from "../../../../_actions/city.action";

const EarningHeader = ({
  handleDateSearch, currentMonth, headerCityId, setHeaderCityId, roleId
}) => {

  const dispatch = useDispatch();
  const selector = useSelector((state) => state);
  useEffect(() => {
    let cityDetails = {
      isManagement: true
    };
    dispatch(cityAction.cityGet(cityDetails));
  }, []);


  return (
    <Fragment>
      <section className=" bg-[#ffffff] lg:block hidden border-l pl-4">
        <div className="w-full py-3 px-10 flex justify-between items-center">
          <HeaderHeading label={"Earning"} />
          <div className="flex items-center gap-6">
            <div className="flex relative gap-2 city_select cursor-pointer items-center">
              <input
                type="month"
                onChange={handleDateSearch}
                className="py-1.5 px-2 bg-white border-2 text-black rounded-lg shadow appearance-none"
                value={currentMonth}
                style={{ color: currentMonth ? 'black' : 'transparent' }}
              />
              {!currentMonth && (
                <span className="absolute inset-y-0 left-3 flex items-center pointer-events-none text-gray-400">
                  MM-YYYY
                </span>
              )}
            </div>
            <select className="flex items-center capitalize justify-center bg-white border-2 text-[#000] rounded-lg w-full lg:w-36 px-4 h-9"
              value={headerCityId}
              onChange={(e) => { setHeaderCityId(e.target.value) }}
              disabled={roleId !== 1 ? true : false}
            >
              <option className="text-black align-middle" value={""}>
                {" "}
                All Cities <FaAngleDown className="ml-3" color="#D9D9D9" />
              </option>
              {selector?.Citys?.cityGetList?.map((city, id) => (
                <option className="text-black capitalize" value={city._id} key={id}>
                  {" "}
                  {city.name}
                </option>
              ))}
            </select>
          </div>

          <HeaderProfileComp />
        </div>
      </section>

      <section className="bg-white lg:hidden block">
        <MobileSearchHeader handleSearch={null} searchText={
          ""
        } />
      </section>
    </Fragment>
  );
};

export default EarningHeader;
