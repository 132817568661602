/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import KycStatus from "../dashboard/KycStatus.json";
import { RiArrowRightDoubleLine } from "react-icons/ri";
import "./Dashboard.css";
import { useNavigate } from "react-router";
const KycHeader = () => {
  const navigate = useNavigate();
  const [allNewJoinedTutor, setAllNewJoinedTutor] = useState(false)
  const handleAllNewJoinedTutor = () => {
    setAllNewJoinedTutor(true)
    navigate('/app/tutor')
  }
  return (
    <>
      <section className="kycHeader px-5 py-3 flex text-wrap justify-between">
        <div className="">
          <p className="Heading text-white font-bold">
            {KycStatus.KycStaus.HeadingText}
          </p>
          <p className="text-gray-200 text-xs pt-1">{KycStatus.KycStaus.SubHeading}</p> <span></span>
        </div>
        <div>
          <span className="cursor-pointer" onClick={() => handleAllNewJoinedTutor()}><RiArrowRightDoubleLine size={24} color="#fff" /></span>
        </div>
      </section>
    </>
  );
};

export default KycHeader;
