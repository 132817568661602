/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Button } from './Button'
import { IoIosCloseCircle } from 'react-icons/io'
import { AllEmployees } from './AllEmployees'
import { Teams } from './Teams'
import { Roles } from './Roles'
import EmployeProfileHeader from './EmployeProfileHeader'
import { employprofileAction, roleAction } from '../../_actions'
import { cityAction } from '../../_actions/city.action'

const EmploysProfile = () => {
  const dispatch = useDispatch()
  const [active, setActive] = useState('All Employees')
  const [modal, setModal] = useState(false)
  const [name, setName] = useState('')
  const [mobile, setMobile] = useState('')
  const [email, setEmail] = useState('')
  const [searchText, setSearchtext] = useState('')

  const handleActive = data => {
    setActive(data)
  }

  const closeModal = () => {
    setModal(false)
  }

  const handleSubmit = e => {
    e.preventDefault()
    alert('Your data is:-' + name + email + mobile)
    setName('')
    setMobile('')
    setEmail('')
  }
  const buttonName = ['All Employees', 'Teams', 'Roles']

  const handleSearch = e => {
    setSearchtext(e.target.value)

    if (active === 'Recent Hires') {
      let keyWord = e.target.value
      let emplyDetails = {
        keyWord: keyWord,
        fromDate: '',
        toDate: '',
        sortOrder: '',
        sortBy: '',
        pageNo: 1,
        size: 1000000000
      }

      dispatch(employprofileAction.getSubAdminList(emplyDetails))
    } else if (active === 'All Employees') {
      let keyWord = e.target.value
      if (keyWord) {
        let emplyDetails = {
          keyWord: keyWord,
          fromDate: '',
          toDate: '',
          sortOrder: '',
          sortBy: '',
          pageNo: '1',
          size: 1000000000
        }
        dispatch(employprofileAction.getSubAdminList(emplyDetails))
      }
    } else if (active === 'Teams') {
      let keyWord = e.target.value
      let teamDetails = {
        keyWord: keyWord,
        fromDate: '',
        toDate: '',
        sortOrder: '',
        sortBy: '',
        pageNo: 1,
        size: 1000000000
      }

      dispatch(employprofileAction.getTeamList(teamDetails))
    } else if (active === 'Roles') {
      let keyWord = e.target.value
      let roleData = {
        keyWord: keyWord,
        fromDate: '',
        toDate: '',
        pageNo: 1,
        size: 100000000000
      }
      dispatch(roleAction.roleGet(roleData))
    }
  }

  useEffect(() => {
    dispatch(cityAction.cityGet())
  }, [])

  return (
    <>
      <EmployeProfileHeader
        handleSearch={handleSearch}
        searchText={searchText}
      />
      <section className='overflow-hidden overflow-y-auto h-screen'>
        <div className='m-5 '>
          <section className='flex gap-3 '>
            {buttonName.map((item1, index) => (
              <div
                className={`font-semibold text-sm  px-6  py-2 text-white rounded-full ${
                  active === item1 ? 'bg-[#023565]' : 'bg-gray-400'
                }`}
                onClick={() => handleActive(item1)}
                key={index}
              >
                <Button name={item1} />
              </div>
            ))}
          </section>
          {active === 'All Employees' && <AllEmployees />}
          {active === 'Teams' && <Teams />}
          {active === 'Roles' && <Roles />}

          {modal && (
            <div className='fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center'>
              <div className='w-2/5 bg-white p-5 rounded-lg shadow-md'>
                <div className='text-right pb-5'>
                  <IoIosCloseCircle
                    className='text-purple-500 cursor-pointer'
                    onClick={closeModal}
                  />
                </div>
                <form onSubmit={handleSubmit}>
                  <div className='flex items-center mb-4 mt-3'>
                    <label
                      htmlFor='name'
                      className='w-1/4 ps-4 text-sm font-medium text-gray-700'
                    >
                      Name:
                    </label>
                    <input
                      type='text'
                      id='name'
                      className='ml-2 flex-grow p-1 border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500'
                      placeholder='Enter your name'
                      value={name}
                      onChange={e => setName(e.target.value)}
                    />
                  </div>
                  <div className='flex items-center mb-4'>
                    <label
                      htmlFor='mobile'
                      className='w-1/4 ps-4 text-sm font-medium text-gray-700'
                    >
                      Mobile Number:
                    </label>
                    <input
                      type='text'
                      id='mobile'
                      className='ml-2 flex-grow p-1 border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500'
                      placeholder='Enter your Mobile Number'
                      value={mobile}
                      onChange={e => setMobile(e.target.value)}
                    />
                  </div>
                  <div className='flex items-center mb-4'>
                    <label
                      htmlFor='email'
                      className='w-1/4 ps-4 text-sm font-medium text-gray-700'
                    >
                      Email:
                    </label>
                    <input
                      type='text'
                      id='email'
                      className='ml-2 flex-grow p-1 border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500'
                      placeholder='Enter your Email'
                      value={email}
                      onChange={e => setEmail(e.target.value)}
                    />
                  </div>
                  <div className='text-center'>
                    <button
                      type='submit'
                      className='bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded'
                    >
                      Add data
                    </button>
                  </div>
                </form>
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  )
}

export default EmploysProfile
