export const tutorUpdateInitialState = {
    _id: "",
    name: "",
    email: "",
    whatsappNo: "",
    address: "",
    city: "",
    pinCode: "",
    dob: "",
    gender: "",
    schoolName: "",
    schoolPassOutYear: "",
    ugCourse: "",
    ugCollegeName: "",
    ugPassOutYear: "",
    pgCourse: "",
    pgCollegeName: "",
    pgPassOutYear: "",
    teachingExp: ""
}


export const noOfClasses = ["Full Week", "Weekly 6 Days", "Weekly 5 Days", " Weekly 4 Days", "Weekly 3 Days", "Weekly 2 Days", "Once in a Week"]

export let suggestTutorBtn = ["Applicants", "Suggested Tutors", "All Tutors"];