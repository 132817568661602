/* eslint-disable eqeqeq */
/* eslint-disable no-mixed-operators */
/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { IoIosArrowDroprightCircle, IoIosArrowDropupCircle } from 'react-icons/io'
import { MdOutlineClose } from 'react-icons/md'
import { tutortoliveAction } from '../../../../_actions'
import { filterData } from '../../../../_helpers'


const Star = () => (
  <svg
    className="w-4 h-4 ms-1 cursor-pointer text-[#FF8C00]"
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 22 20"

  >
    <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
  </svg>
);


function DetailDataShow(fullData) {
  return <div className="w-full capitalize space-y-2 bg-gray-100 p-4 mx-auto transition-transform duration-300 ease-in-out transform scale-100 text-sm rounded-lg">

    <div className="w-full flex justify-between items-center">
      <p className="font-semibold w-[40%] text-sm text-[#023565]">DOB</p>
      <span className="w-[20%]">:</span>
      <p className="w-[40%] text-gray-500 text-sm">{fullData?.tutorInfoId?.dob ? moment(fullData?.tutorInfoId?.dob).format("DD-MM-YYYY") : "N/A"}</p>
    </div>
    <div className="w-full flex justify-between items-center">
      <p className="font-semibold w-[40%] text-sm text-[#023565]">Gender</p>
      <span className="w-[20%]">:</span>
      <p className="w-[40%] text-gray-500 text-sm">{fullData?.tutorInfoId?.gender ?? "N/A"}</p>
    </div>
    <div className="w-full flex justify-between items-center">
      <p className="font-semibold w-[40%] text-sm text-[#023565]">Teaching Exp</p>
      <span className="w-[20%]">:</span>
      <p className="w-[40%] text-gray-500 text-sm">{fullData?.tutorInfoId?.teachingExp ?? "N/A"}</p>
    </div>
    <div className="w-full flex justify-between items-center">
      <p className="font-semibold w-[40%] text-sm text-[#023565]">Address</p>
      <span className="w-[20%]">:</span>
      <p className="w-[40%] text-gray-500 text-sm">{fullData?.address ?? "N/A"}</p>
    </div>
    <div className="w-full flex justify-between items-center">
      <p className="font-semibold w-[40%] text-sm text-[#023565]">Teach PinCode</p>
      <span className="w-[20%]">:</span>
      <p className="w-[40%] text-gray-500 text-sm">{(Array.isArray(fullData?.tutorInfoId?.pinCodeYouCanGo) && fullData?.tutorInfoId?.pinCodeYouCanGo.length > 0 && (fullData?.tutorInfoId?.pinCodeYouCanGo).join(", ")) ?? "N/A"}</p>
    </div>
    <div className="w-full flex justify-between items-center">
      <p className="font-semibold w-[40%] text-sm text-[#023565]">Mobile No</p>
      <span className="w-[20%]">:</span>
      <p className="w-[40%] text-gray-500 text-sm">{fullData?.mobNo ?? "N/A"}</p>
    </div>
    <div className="w-full flex justify-between items-center">
      <p className="font-semibold w-[40%] text-sm text-[#023565]">Email</p>
      <span className="w-[20%]">:</span>
      <p className="w-[40%] text-gray-500 text-sm">{fullData?.email ?? "N/A"}</p>
    </div>
    <div className="w-full flex justify-between items-center">
      <p className="font-semibold w-[40%] text-sm text-[#023565]">School name</p>
      <span className="w-[20%]">:</span>
      <p className="w-[40%] text-gray-500 text-sm">{fullData?.tutorInfoId?.schoolName ?? "N/A"}</p>
    </div>
    <div className="w-full flex justify-between items-center">
      <p className="font-semibold w-[40%] text-sm text-[#023565]">UG Course</p>
      <span className="w-[20%]">:</span>
      <p className="w-[40%] text-gray-500 text-sm">{fullData?.tutorInfoId?.ugCourse ?? "N/A"}</p>
    </div>
    <div className="w-full flex justify-between items-center">
      <p className="font-semibold w-[40%] text-sm text-[#023565]">PG Course</p>
      <span className="w-[20%]">:</span>
      <p className="w-[40%] text-gray-500 text-sm">{fullData?.tutorInfoId?.pgCourse ?? "N/A"}</p>
    </div>
    <div className="w-full flex justify-between items-center">
      <p className="font-semibold w-[40%] text-sm text-[#023565]">Classes</p>
      <span className="w-[20%]">:</span>
      <p className="w-[40%] text-gray-500 text-sm">
        {fullData?.tutorInfoId?.classYouCanTeach
          ?.map((ele) => ele?.name)
          .join(", ") ?? "N/A"}
      </p>
    </div>

    <div className="w-full flex justify-between items-center">
      <p className="font-semibold w-[40%] text-sm text-[#023565] capitalize">Subjects</p>
      <span className="w-[20%]">:</span>
      <p className="w-[40%] text-gray-500 text-sm">
        {fullData?.tutorInfoId?.subYouCanTeach
          ?.map((ele) => ele?.name)
          .join(", ") ?? "N/A"}
      </p>
    </div>
    <div className="w-full flex justify-between items-center">
      <p className="font-semibold w-[40%] text-sm text-[#023565] capitalize">Rating</p>
      <span className="w-[20%]">:</span>
      <p className="w-[40%] flex">
        {fullData?.avgRating ?
          Array.from({ length: Math.floor(fullData?.avgRating || 0) }, (_, index) => (
            <Star key={index} />
          ))
          : "N/A"}
      </p>
    </div>
  </div>
}

function returnButtonTypeState(key) {
  switch (key) {
    case "Applicants":
      return "applicantCount";
    case "Suggested Tutors":
      return "suggestCount";
    case "All Tutors":
      return "allCount";
    default:
      return "allCount";
  }
}


function AssignTutorPopUp({ assignTutorModal, setAssignTutorModal, suggestTutorBtn, assignTutorTab, handleAssignTutor, assignTutorList, allTutorDataList, handleAssign, suggestedTutor }) {

  const [tutorId, setTutorId] = useState("")
  const [fullData, setFullData] = useState(null)

  const [searchState, setSearchState] = useState({
    applicantSearch: "",
    suggestSearch: "",
    allSearch: ""
  });

  const [filteredLists, setFilteredLists] = useState({
    filteredApplicantList: [],
    filteredSuggestList: [],
    filteredAllList: []
  });

  const [countState, setCountState] = useState({
    applicantCount: 0,
    suggestCount: 0,
    allCount: 0
  });

  useEffect(() => {
    const valueData = {
      applicantCount: assignTutorList?.tutorAppliedList?.length || 0,
      suggestCount: suggestedTutor?.length || 0,
      allCount: allTutorDataList?.length || 0
    }
    setCountState(() => valueData)
  }, [suggestedTutor, allTutorDataList, assignTutorList])



  const showTutorDetails = (id) => {
    setTutorId(tutorId === id ? null : id);
    let res = {
      id
    }
    if (tutorId === id ? null : id) {
      tutortoliveAction.getTutorByPromiseId(res).then((response) => {
        setFullData(response?.data)
      })
    }
  }

  const handleSearchChange = (key, value) => {
    setSearchState(prevState => ({
      ...prevState,
      [key]: value
    }));
    if (key === "applicantSearch") {

      const applicantsArray = filterData(assignTutorList?.tutorAppliedList, ["name", "mobNo"], value)
      setFilteredLists(prevState => ({
        ...prevState,
        filteredApplicantList: applicantsArray
      }));
    }

    if (key === "allSearch") {

      const allArray = filterData(allTutorDataList, ["name", "mobNo"], value)
      setFilteredLists(prevState => ({
        ...prevState,
        filteredAllList: allArray
      }));
    }

    if (key === "suggestSearch") {
      const suggestArray = filterData(suggestedTutor, ["userDetails.name", "userDetails.mobNo"], value)
      setFilteredLists(prevState => ({
        ...prevState,
        filteredSuggestList: suggestArray
      }));
    }
  };

  const renderTutorCards = (list, type) => {
    return list.map((ele) => {

      // Determine image, name, and mobNo based on the type
      const image = (type === 'suggest') ? ele?.userDetails?.image || 'https://pinnacle.works/wp-content/uploads/2022/06/dummy-image-300x298.jpg'
        : ele?.image || 'https://pinnacle.works/wp-content/uploads/2022/06/dummy-image-300x298.jpg';

      const name = (type === 'suggest') ? ele?.userDetails?.name : ele?.name

      const mobNo = (type === 'suggest') ? ele?.userDetails?.mobNo : ele?.mobNo

      const _id = (type === 'suggest') ? ele?.userDetails?._id : ele?._id;

      return (
        <div key={_id} className="border rounded-md px-6">
          <div className="flex justify-between items-center">
            <div className="flex items-center space-x-3">
              <div className="flex items-center space-x-3">
                <div>
                  <img
                    src={image}
                    className="rounded-full my-3 max-w-[40px] max-h-[40px] min-w-[40px] min-h-[40px]"
                    alt={name || "Tutor image"}
                  />
                </div>
                <div>
                  <p className="font-semibold text-[#023565] text-sm">{name || "No Name"}</p>
                  <p className="text-gray-500 text-sm">{mobNo || "No Phone Number"}</p>
                </div>
              </div>
              <div>
                {tutorId === _id ? (
                  <IoIosArrowDropupCircle
                    size={20}
                    className="cursor-pointer text-blue-700"
                    onClick={() => showTutorDetails(_id)}
                  />
                ) : (
                  <IoIosArrowDroprightCircle
                    size={20}
                    className="cursor-pointer text-[#023565]"
                    onClick={() => showTutorDetails(_id)}
                  />
                )}
              </div>
            </div>
            <div>
              <button
                className="font-bold text-sm bg-[#C60E6B] py-1 px-4 text-white rounded-full"
                onClick={() => handleAssign(_id)}
              >
                Assign
              </button>
            </div>
          </div>
          {tutorId === _id && (
            DetailDataShow(fullData)
          )}
        </div>
      );
    });
  };

  return assignTutorModal && (
    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40">
      <div className="relative w-11/12 mx-auto lg:w-[550px] ">
        <div className="flex justify-end m-2">
          <button
            className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center"
            onClick={() => setAssignTutorModal(false)}
          >
            {" "}
            <span>
              <MdOutlineClose />
            </span>
            Close
          </button>
        </div>
        <div className="form_class border-0 rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          <div className="flex items-center justify-between p-3 border-b border-solid border-slate-200 rounded-t">
            <h2 className="text-lg font-semibold"> Assign Tutor</h2>
          </div>
          <section className={`grid md:grid-cols-${suggestTutorBtn.length > 2 ? "3" : "2"} grid-cols-1  w-full`}>
            {suggestTutorBtn.map((itm, id) => (
              <div key={id} className="m-2 ">
                <button
                  className={`  text-sm py-2 w-full font-bold ${assignTutorTab === itm
                    ? "bg-[#023565] text-[#fff] border border-[#023565] rounded-md"
                    : "bg-[#D3D3D3]"
                    } `}
                  onClick={() => {
                    handleAssignTutor(itm)
                    setFullData(null)
                    setTutorId("")
                  }}
                >
                  {itm} ({countState[returnButtonTypeState(itm)]})
                </button>
              </div>
            ))}
          </section>

          <div className="form_class border-0 rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <section>
              {assignTutorTab === "Applicants" && (
                <div class="h-[45vh] overflow-hidden overflow-y-auto">
                  <div>
                    <div class="flex items-center max-w-sm mx-auto">
                      <label for="simple-search" class="sr-only">
                        Search
                      </label>
                      <div class="relative w-full">
                        <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none"></div>
                        <input
                          type="text"
                          id="applicantSearch"
                          name="applicantSearch"
                          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-full block w-full py-2 px-3 "
                          placeholder="Search..."
                          value={searchState.applicantSearch}
                          onChange={(e) => handleSearchChange(e.target.name, e.target.value)}
                        />
                      </div>
                    </div>

                  </div>
                  <div className='m-3'>
                    <table className="min-w-full rounded-md overflow-hidden">
                      <thead className='space-y-2'>
                        {searchState.applicantSearch != "" ?
                          filteredLists.filteredApplicantList && filteredLists.filteredApplicantList.length > 0 ?
                            renderTutorCards(assignTutorList.tutorAppliedList, "applicant") : <div className="text-black flex justify-center">No Data</div>
                          : assignTutorList &&
                          assignTutorList.tutorAppliedList && assignTutorList.tutorAppliedList.length > 0 &&
                          renderTutorCards(assignTutorList.tutorAppliedList) || <div className="text-black flex justify-center">No Data</div>}
                      </thead>
                    </table>
                  </div>
                </div>
              )}
            </section>

            <section>
              {assignTutorTab === "Suggested Tutors" && (
                <div className="h-[45vh] overflow-hidden overflow-y-auto">
                  <div>
                    <div class="flex items-center max-w-sm mx-auto">
                      <label for="simple-search" class="sr-only">
                        Search
                      </label>
                      <div class="relative w-full">
                        <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none"></div>
                        <input
                          type="text"
                          id="suggestSearch"
                          name="suggestSearch"
                          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-full block w-full py-2 px-3 "
                          placeholder="Search..."
                          value={searchState.suggestSearch}
                          onChange={(e) => handleSearchChange(e.target.name, e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="m-3">
                    <table className="min-w-full rounded-md overflow-hidden">
                      <thead className='space-y-2'>
                        {
                          searchState.suggestSearch != "" ?
                            filteredLists.filteredSuggestList.length > 0 ?
                              renderTutorCards(filteredLists.filteredSuggestList, "suggest") :
                              <div className="text-black flex justify-center">No Data</div>

                            : suggestedTutor && suggestedTutor.length > 0 ? (
                              renderTutorCards(suggestedTutor, "suggest")
                            ) : (
                              <div className="text-black flex justify-center">No Data</div>
                            )}
                      </thead>
                    </table>
                  </div>
                </div>
              )}
            </section>

            <section>
              {assignTutorTab === "All Tutors" && (
                <div className="h-[45vh] overflow-hidden overflow-y-auto">
                  <div>
                    <div class="flex items-center max-w-sm mx-auto">
                      <label for="simple-search" class="sr-only">
                        Search
                      </label>
                      <div class="relative w-full">
                        <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none"></div>
                        <input
                          type="text"
                          id="allSearch"
                          name="allSearch"
                          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-full block w-full py-2 px-3 "
                          placeholder="Search..."
                          value={searchState.allSearch}
                          onChange={(e) => handleSearchChange(e.target.name, e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="m-3">
                    <table className="min-w-full rounded-md overflow-hidden ">
                      <thead className='space-y-2'>
                        {searchState.allSearch != "" ?
                          filteredLists.filteredAllList && filteredLists.filteredAllList.length > 0 ?
                            renderTutorCards(filteredLists.filteredAllList, "all") : <div className="text-black flex justify-center">No Data</div>
                          : allTutorDataList && allTutorDataList.length > 0 ?
                            renderTutorCards(allTutorDataList, "all")
                            : (
                              <div className="text-black flex justify-center">No Data</div>
                            )}
                      </thead>
                    </table>
                  </div>
                </div>
              )}
            </section>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AssignTutorPopUp