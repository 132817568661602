import { feeListConstant } from "../_constants";
export default function FeeList(state = {}, action) {
    switch (action.type) {
        case feeListConstant.GET_FEE_HISTORY_LIST_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case feeListConstant.GET_FEE_HISTORY_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                FeeHistoryList: action?.users
            };
        case feeListConstant.GET_FEE_HISTORY_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case feeListConstant.MARK_PAID_OR_UNPAID_BYADMIN_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case feeListConstant.MARK_PAID_OR_UNPAID_BYADMIN_SUCCESS:
            return {
                ...state,
                loading: false,

            };
        case feeListConstant.MARK_PAID_OR_UNPAID_BYADMIN_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };


        case feeListConstant.FEE_REMINDER_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case feeListConstant.FEE_REMINDER_SUCCESS:
            return {
                ...state,
                loading: false,

            };
        case feeListConstant.FEE_REMINDER_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        case feeListConstant.GET_FEE_HISTORY_LIST_BY_ADMIN_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case feeListConstant.GET_FEE_HISTORY_LIST_BY_ADMIN_SUCCESS:
            return {
                ...state,
                loading: false,
                feeHistoryList: action?.users
            };
        case feeListConstant.GET_FEE_HISTORY_LIST_BY_ADMIN_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        default:
            return state
    }
}
