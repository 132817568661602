import { allModuleConstant } from "../_constants";



export default function allModule(state = {},action) {
    switch (action.type) {
      case allModuleConstant.ALLMODULE_GET_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case allModuleConstant.ALLMODULE_GET_SUCCESS:
        return {
          ...state,
          moduleList:action.users
          ,
          loading: false,
        };
      case allModuleConstant.ALLMODULE_GET_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.error,
        };
        default:
        return state;
    }
}       