import React from 'react';
import LoadingOverlay from 'react-loading-overlay-nextgen';
//this is comment for build
//this is comment for build


export default function Loader({ loading }) {
  return (
    <div>
      {loading && (
        <div className="fixed inset-0 bg-black opacity-50 z-50"></div>
      )}
     <div className={`absolute inset-0 bg-black opacity-70 backdrop-filter backdrop-blur-lg justify-center flex items-center ${loading ? 'z-[1000]' : 'z-[-10]'}`}>
        <LoadingOverlay
          active={loading || false}
          className="temp001"
          spinner
          text='Please wait...'
        />
      </div>
    </div>
  );
}