/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../../../components/Loader'
import {
  alertActions,
  feelistAction,
  parentAction,
  tutionAction,
  userListAction
} from '../../../_actions'
import moment from 'moment'
import StudentDetail from '../Tution/Models/StudentDetail'
import {
  FaAngleRight,
  FaChevronCircleRight,
  FaChevronLeft,
  FaChevronRight
} from 'react-icons/fa'
import { feeAction } from '../../../_actions/fee.action'
import { MdOutlineClose } from 'react-icons/md'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { confirmAlert } from 'react-confirm-alert'
import { FaFilterCircleXmark, FaRotate } from 'react-icons/fa6'
import { PiExportLight } from 'react-icons/pi'
import Select from 'react-select'
import UpdateFeeDueDate from './Models/UpdateFeedueDate'
import UpdateFeeRefund from './Models/UpdateFeeRefund'
import { RiEqualizerFill } from 'react-icons/ri'
import { truncateText } from '../../../_helpers'
const customStyles = {
  container: provided => ({
    ...provided
  }),
  control: (provided, state) => ({
    ...provided,
    border: '1px solid #000',
    boxShadow: 'none',
    '&:hover': {
      border: '1px solid #000'
    },
    borderRadius: '5px'
  }),
  menu: provided => ({
    ...provided
  }),
  placeholder: provided => ({
    ...provided,
    color: '#000 !important'
  })
}

const FeeManagement = ({ headerCityId, searchText, date, setdate }) => {
  const dispatch = useDispatch()
  const selector = useSelector(state => state)
  const [feeListData, setFeeListData] = useState()
  const [currentPage, setCurrentPage] = useState(1)
  const pageSize = 10
  const totalPages =
    Math.ceil(
      selector?.FeeList?.feeHistoryList?.data?.data?.total / pageSize
    ) ?? 0
  const [currentMonth, setCurrentMonth] = useState(
    moment().startOf('month').format('YYYY-MM')
  )
  const [classReference, setClassReference] = useState({
    label: 'Class Reference',
    value: ''
  })
  const [classReferenceList, setClassReferenceList] = useState([])
  const [tutorList, setTutorList] = useState([])
  const [assignTutor, setAssignTutor] = useState({
    label: 'Select Tutor',
    value: ''
  })
  const [status, setStatus] = useState('')
  const [viewStudentModal, setViewStudentModal] = useState(false)
  const [studentListdata, setStudentListData] = useState([])
  const [noteModal, setNoteModal] = useState(false)
  const [noteId, setNoteId] = useState('')
  const [note, setNote] = useState('')
  const [openDetailsId, setOpenDetailsId] = useState(null)
  const menuRef = useRef(null)
  const [openDetailsTutorId, setOpenDetailsTutorId] = useState(null)
  const menuTutorRef = useRef(null)
  const [openActionId, setOpenActionId] = useState(null)
  const actionMenuRef = useRef(null)
  const [tutorStatus, setTutorStatus] = useState('')
  const [feeData, setFeeData] = useState({})
  const [openDate, setOpenDate] = useState(false)
  const [dateError, setDateError] = useState({})
  const [openRefund, setOpenRefund] = useState(false)
  const [noFeeModal, setNoFeeModal] = useState(false)
  const [noFee, setNoFee] = useState({
    userType: '',
    feeStatus: 4,
    disabled: true,
    error: ''
  })
  const [parentId, setParentId] = useState({
    label: 'Select Parent',
    value: ''
  })

  useEffect(() => {
    dispatch(tutionAction.classReferenceList({}))
    dispatch(userListAction.getAllTutorListWithNameOrId({}))
    dispatch(
      userListAction.getUserTypeList({ isManagement: true, userType: 'PARENT' })
    )
  }, [])

  useEffect(() => {
    setTutorList(() => selector?.userList?.getAllTutorListWithNameOrId?.data)
    setClassReferenceList(() => selector?.tution?.classReferenceList?.data)
  }, [selector])

  useEffect(() => {
    let feeListDetails = {
      keyWord: searchText,
      calenderData: new Date(currentMonth),
      pageNo: currentPage,
      size: pageSize,
      dueDate: date !== '' ? [Number(date)] : [],
      cityId: headerCityId !== '' ? [headerCityId] : [],
      status: status !== '' ? Number(status) : '',
      classReference: classReference.value,
      assignTutor: assignTutor.value,
      tutorStatus: tutorStatus !== '' ? Number(tutorStatus) : '',
      parentId: parentId.value !== '' ? [parentId.value] : null
    }
    dispatch(feelistAction.getFeeHistoryListByAdmin(feeListDetails))
  }, [
    currentPage,
    currentMonth,
    headerCityId,
    searchText,
    classReference,
    assignTutor,
    date,
    status,
    tutorStatus,
    parentId
  ])

  useEffect(() => {
    setFeeListData(() => selector?.FeeList?.feeHistoryList?.data?.data?.list)
  }, [selector])

  const handleFullDetails = data => {
    let studentListDetails = {
      parentId: data.demoClassId.parentId._id,
      demoId: data.demoClassId._id
    }
    parentAction
      .getStudentListByParentIdPromise(studentListDetails)
      .then(resData => {
        setViewStudentModal(true)
        setStudentListData(resData.data)
      })
      .catch(err => {})
  }

  const userLoginDetails = JSON.parse(
    window.sessionStorage.getItem('adminuser')
  )

  const handleNote = data => {
    setNoteModal(true)
    let idForNote = {
      id: data?._id
    }
    setNote(data?.remark)
    setNoteId(idForNote)
  }

  const handleUpdateNote = e => {
    e.preventDefault()
    let feelistDetails = {
      keyWord: searchText,
      calenderData: new Date(currentMonth),
      pageNo: currentPage,
      size: pageSize,
      dueDate: date !== '' ? [Number(date)] : [],
      cityId: headerCityId !== '' ? [headerCityId] : [],
      status: status !== '' ? Number(status) : '',
      classReference: classReference.value,
      assignTutor: assignTutor.value,
      tutorStatus: tutorStatus !== '' ? Number(tutorStatus) : '',
      parentId: parentId.value !== '' ? [parentId.value] : null
    }
    let Data = {
      ...noteId,
      remark: note
    }
    dispatch(feeAction.feeRemark(Data, feelistDetails))
    setNote('')
    setNoteModal(false)
  }

  const amountClose = () => {
    setNoteModal(false)
    setNote('')
  }

  const OpenDetails = (resDataId, type) => {
    if (type === 'parent') {
      setOpenDetailsId(openDetailsId !== resDataId ? resDataId : null)
    } else {
      setOpenDetailsTutorId(openDetailsTutorId !== resDataId ? resDataId : null)
    }
  }

  const handleClickOutside = event => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setOpenDetailsId(null)
    }

    if (menuTutorRef.current && !menuTutorRef.current.contains(event.target)) {
      setOpenDetailsTutorId(null)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const handelActionButtonOutSideClick = event => {
    console.log('event', event, actionMenuRef)
    if (
      actionMenuRef.current &&
      !actionMenuRef.current.contains(event.target)
    ) {
      setOpenActionId('')
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handelActionButtonOutSideClick)
    return () => {
      document.removeEventListener('mousedown', handelActionButtonOutSideClick)
    }
  }, [])

  const handlePushReminder = data => {
    let feelistDetails = {
      keyWord: searchText,
      calenderData: new Date(currentMonth),
      pageNo: currentPage,
      size: pageSize,
      dueDate: date !== '' ? [Number(date)] : [],
      cityId: headerCityId !== '' ? [headerCityId] : [],
      status: status !== '' ? Number(status) : '',
      classReference: classReference.value,
      assignTutor: assignTutor.value,
      tutorStatus: tutorStatus !== '' ? Number(tutorStatus) : '',
      parentId: parentId.value !== '' ? [parentId.value] : null
    }

    let userId = {
      userId: data?.parentId?._id
    }

    confirmAlert({
      title: 'Confirm to Reminder?',
      message: `Are you sure to Push Reminder ?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () =>
            dispatch(feelistAction.feeReminder(userId, feelistDetails))
        },
        {
          label: 'No'
        }
      ]
    })

    setOpenActionId(false)
  }

  const handleRefreshFeeHistory = () => {
    const feelistDetails = {
      keyWord: searchText,
      calenderData: new Date(currentMonth),
      pageNo: currentPage,
      size: pageSize,
      dueDate: date !== '' ? [Number(date)] : [],
      cityId: headerCityId !== '' ? [headerCityId] : [],
      status: status !== '' ? Number(status) : '',
      classReference: classReference.value,
      assignTutor: assignTutor.value,
      tutorStatus: tutorStatus !== '' ? Number(tutorStatus) : '',
      parentId: parentId.value !== '' ? [parentId.value] : null
    }
    feeAction
      .refreshFeeHistoryByPromise()
      .then(res => {
        dispatch(alertActions.success('Fee history updated successfully.'))
        dispatch(feelistAction.getFeeHistoryListByAdmin(feelistDetails))
      })
      .catch(error => {
        dispatch(alertActions.error('Please try again later.'))
        dispatch(feelistAction.getFeeHistoryListByAdmin(feelistDetails))
      })
  }
  const handleResetFilter = () => {
    setStatus('')
    setTutorStatus('')
    setdate('')
    setCurrentMonth(moment().startOf('month').format('YYYY-MM'))
    setClassReference({ label: 'Class Reference', value: '' })
    setAssignTutor({ label: 'Select Tutor', value: '' })
    setParentId({ label: 'Select Parent', value: '' })
  }

  const handleMarkPaidTuitionFee = (data, type, feeStatus) => {
    let feeListDetails = {
      keyWord: searchText,
      calenderData: new Date(currentMonth),
      pageNo: currentPage,
      size: pageSize,
      dueDate: date !== '' ? [Number(date)] : [],
      cityId: headerCityId !== '' ? [headerCityId] : [],
      status: status !== '' ? Number(status) : '',
      classReference: classReference.value,
      assignTutor: assignTutor.value,
      tutorStatus: tutorStatus !== '' ? Number(tutorStatus) : '',
      parentId: parentId.value !== '' ? [parentId.value] : null
    }
    let reqData = {
      id: data._id,
      status: feeStatus,
      userType: type
    }
    const msg = `Are you sure to Mark Paid ${
      type === 'PARENT'
        ? 'Tuition Fee'
        : feeStatus === 2
        ? 'Tutor Fee-Advance'
        : 'Tutor Fee-Month End'
    } ?`

    confirmAlert({
      title: 'Confirm to Mark Paid?',
      message: msg,
      buttons: [
        {
          label: 'Yes',
          onClick: () =>
            dispatch(
              feelistAction.markPaidOrUnPaidByAdmin(reqData, feeListDetails)
            )
        },
        {
          label: 'No'
        }
      ]
    })
    setOpenActionId(null)
  }

  const handleDueDate = data => {
    console.log('data_________', data)
    setFeeData({
      demoId: data.demoClassId._id,
      dueDate: data.demoClassId.dueDate,
      tutorDueDate: data.demoClassId.tutorDueDate
    })
    setDateError({})
    setOpenDate(true)
  }

  const handleCloseFeeDateModal = () => {
    setFeeData({})
    setDateError({})
    setOpenDate(false)
  }

  const handleSubmitFeeDateModal = e => {
    e.preventDefault()
    let feeListDetails = {
      keyWord: searchText,
      calenderData: new Date(currentMonth),
      pageNo: currentPage,
      size: pageSize,
      dueDate: date !== '' ? [Number(date)] : [],
      cityId: headerCityId !== '' ? [headerCityId] : [],
      status: status !== '' ? Number(status) : '',
      classReference: classReference.value,
      assignTutor: assignTutor.value,
      tutorStatus: tutorStatus !== '' ? Number(tutorStatus) : '',
      parentId: parentId.value !== '' ? [parentId.value] : null
    }

    let errorData = {}

    if (!feeData.dueDate) {
      errorData.dueDate = 'Please select tuition fee due date.'
    }
    if (!feeData.tutorDueDate) {
      errorData.tutorDueDate = 'Please select tutor fee due date.'
    }
    setDateError(errorData)
    if (Object.keys(errorData).length === 0) {
      feelistAction
        .updateFeeDueDateByPromise({
          ...feeData,
          dueDate: String(feeData.dueDate),
          tutorDueDate: String(feeData.tutorDueDate)
        })
        .then(res => {
          handleCloseFeeDateModal()
          dispatch(alertActions.success('Fee due date successfully updated.'))
          dispatch(feelistAction.getFeeHistoryListByAdmin(feeListDetails))
        })
        .catch(err => {
          handleCloseFeeDateModal()
          dispatch(alertActions.error('please try again later.'))
          dispatch(feelistAction.getFeeHistoryListByAdmin(feeListDetails))
        })
    }
  }

  const handleRefundFee = data => {
    setFeeData({
      demoId: data.demoClassId._id,
      id: data._id,
      tuitionFeeRefundedPaid:
        data.tuitionFeeRefundedPaid > 0
          ? data.tuitionFeeRefundedPaid
          : data.tuitionFee,
      tutorFeeRefundedReceived:
        data.tutorFeeRefundedReceived > 0
          ? data.tutorFeeRefundedReceived
          : data.tutorFee,
      referenceFeeRefundedReceived:
        data.referenceFeeRefundedReceived > 0
          ? data.referenceFeeRefundedReceived
          : data.referenceFee,
      tuitionFee: data.tuitionFee,
      tutorFee: data.tutorFee,
      referenceFee: data.referenceFee
    })
    setDateError({})
    setOpenRefund(true)
    setOpenActionId(null)
  }

  const handleCloseRefundFeeModal = () => {
    setFeeData({})
    setDateError({})
    setOpenRefund(false)
  }

  const validateRefundModal = () => {
    console.log('feeData', feeData)
    let errorData = {}

    if (!feeData.tuitionFeeRefundedPaid) {
      errorData.tuitionFeeRefundedPaid = 'Please enter amount.'
    }
    if (!feeData.tutorFeeRefundedReceived) {
      errorData.tutorFeeRefundedReceived = 'Please enter amount.'
    }
    if (!feeData.referenceFeeRefundedReceived && feeData.referenceFee !== 0) {
      errorData.referenceFeeRefundedReceived = 'Please enter amount.'
    }

    if (feeData.tuitionFeeRefundedPaid > feeData.tuitionFee) {
      errorData.tuitionFeeRefundedPaid =
        'Amount can not be grater than tuition fee.'
    }
    if (feeData.tutorFeeRefundedReceived > feeData.tutorFee) {
      errorData.tutorFeeRefundedReceived =
        'Amount can not be grater than tutor fee.'
    }
    if (feeData.referenceFeeRefundedReceived > feeData.referenceFee) {
      errorData.referenceFeeRefundedReceived =
        'Amount can not be grater than reference fee.'
    }

    setDateError(errorData)
    return Object.keys(errorData).length === 0
  }

  const handleSubmitRefundFeeModal = e => {
    e.preventDefault()
    if (!validateRefundModal()) {
      return
    }
    // return false
    const feeListDetails = {
      keyWord: searchText,
      calenderData: new Date(currentMonth),
      pageNo: currentPage,
      size: pageSize,
      dueDate: date !== '' ? [Number(date)] : [],
      cityId: headerCityId !== '' ? [headerCityId] : [],
      status: status !== '' ? Number(status) : '',
      classReference: classReference.value,
      assignTutor: assignTutor.value,
      tutorStatus: tutorStatus !== '' ? Number(tutorStatus) : '',
      parentId: parentId.value !== '' ? [parentId.value] : null
    }

    const reqData = {
      demoId: feeData.demoId,
      id: feeData.id,
      tuitionFeeRefundedPaid: Number(feeData.tuitionFeeRefundedPaid),
      tutorFeeRefundedReceived: Number(feeData.tutorFeeRefundedReceived),
      referenceFeeRefundedReceived: Number(feeData.referenceFeeRefundedReceived)
    }

    feelistAction
      .updateRefundFeeByPromise(reqData)
      .then(res => {
        handleCloseRefundFeeModal()
        dispatch(alertActions.success('Refund fee successfully updated.'))
        dispatch(feelistAction.getFeeHistoryListByAdmin(feeListDetails))
      })
      .catch(err => {
        handleCloseRefundFeeModal()
        dispatch(alertActions.error(err))
        dispatch(feelistAction.getFeeHistoryListByAdmin(feeListDetails))
      })
  }

  const handleNoFee = data => {
    // setNoFee((prev) => ({
    //     ...prev,
    //     id: data._id,
    //     status: data.status,
    //     tutorStatus: data.tutorStatus
    // }))
    // setNoFeeModal(true)

    let feeListDetails = {
      keyWord: searchText,
      calenderData: new Date(currentMonth),
      pageNo: currentPage,
      size: pageSize,
      dueDate: date !== '' ? [Number(date)] : [],
      cityId: headerCityId !== '' ? [headerCityId] : [],
      status: status !== '' ? Number(status) : '',
      classReference: classReference.value,
      assignTutor: assignTutor.value,
      tutorStatus: tutorStatus !== '' ? Number(tutorStatus) : '',
      parentId: parentId.value !== '' ? [parentId.value] : null
    }
    let reqData = {
      id: data._id,
      status: 4,
      userType: null
    }

    dispatch(feelistAction.markPaidOrUnPaidByAdmin(reqData, feeListDetails))
    setOpenActionId(null)
  }

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1)
  }

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1)
  }

  const handleChangeType = e => {
    const { value } = e.target
    let error = ''
    let disabled = false

    if (value === 'PARENT' && [1, 2, 3].includes(noFee.status)) {
      error = 'No fee action not available.'
      disabled = true
    }
    if (value === 'TUTOR' && [1, 2, 3].includes(noFee.tutorStatus)) {
      error = 'No fee action not available.'
      disabled = true
    }
    if (
      value === 'BOTH' &&
      ([1, 2, 3].includes(noFee.status) ||
        [1, 2, 3].includes(noFee.tutorStatus))
    ) {
      error = 'No fee action not available.'
      disabled = true
    }
    if (value === '') {
      error = 'Please select type.'
      disabled = true
    }

    setNoFee(prev => ({
      ...prev,
      userType: value,
      error: error,
      disabled: disabled
    }))
  }

  const handleSubmitNoFee = e => {
    e.preventDefault()
    let feeListDetails = {
      keyWord: searchText,
      calenderData: new Date(currentMonth),
      pageNo: currentPage,
      size: pageSize,
      dueDate: date !== '' ? [Number(date)] : [],
      cityId: headerCityId !== '' ? [headerCityId] : [],
      status: status !== '' ? Number(status) : '',
      classReference: classReference.value,
      assignTutor: assignTutor.value,
      tutorStatus: tutorStatus !== '' ? Number(tutorStatus) : '',
      parentId: parentId.value !== '' ? [parentId.value] : null
    }
    let reqData = {
      id: noFee.id,
      status: noFee.feeStatus,
      userType: noFee.userType !== 'BOTH' ? noFee.userType : null
    }

    dispatch(feelistAction.markPaidOrUnPaidByAdmin(reqData, feeListDetails))
    setNoFeeModal(false)
    setNoFee({ userType: '', feeStatus: 4, disabled: true, error: '' })
  }

  const parentOptions = selector?.userList?.getUserListForSearch?.data
    ? selector?.userList?.getUserListForSearch?.data
        .filter(ele => ele?.userType === 'PARENT')
        .map(ele => ({
          value: ele?._id,
          label: `${ele?.name} - ${ele?.mobNo}`
        }))
    : []

  const [isOpen, setIsOpen] = useState(false)

  const toggleDropdown = () => {
    setIsOpen(!isOpen)
  }

  const handleRevertOperation = data => {
    const feeListDetails = {
      keyWord: searchText,
      calenderData: new Date(currentMonth),
      pageNo: currentPage,
      size: pageSize,
      dueDate: date !== '' ? [Number(date)] : [],
      cityId: headerCityId !== '' ? [headerCityId] : [],
      status: status !== '' ? Number(status) : '',
      classReference: classReference.value,
      assignTutor: assignTutor.value,
      tutorStatus: tutorStatus !== '' ? Number(tutorStatus) : '',
      parentId: parentId.value !== '' ? [parentId.value] : null
    }
    confirmAlert({
      title: 'Confirm to revert',
      message: 'Are you sure to revert all operation?',
      buttons: [
        {
          label: 'Yes',
          onClick: () =>
            feelistAction
              .revertOperationByPromise({ id: data._id })
              .then(res => {
                dispatch(alertActions.success('Success.'))
                dispatch(feelistAction.getFeeHistoryListByAdmin(feeListDetails))
              })
              .catch(err => {
                handleCloseRefundFeeModal()
                dispatch(alertActions.error(err))
                dispatch(feelistAction.getFeeHistoryListByAdmin(feeListDetails))
              })
        },
        {
          label: 'No'
        }
      ]
    })
    setOpenActionId(null)
  }

  const exportToCSV = () => {
    const details = {
      keyWord: '',
      calenderData: '',
      pageNo: 1,
      size: 1000,
      dueDate: '',
      cityId: '',
      status: '',
      classReference: '',
      assignTutor: '',
      tutorStatus: '',
      parentId: ''
    }

    function removeCommas (input) {
      if (typeof input !== 'string') {
        throw new TypeError('Input must be a string')
      }
      return input.replace(/,/g, '') // Replace all commas with an empty string
    }
    const csvHeaders = [
      'User Parent/Student Name',
      'Address',
      'City',
      'Assigned Tutor',
      'Class Reference',
      'Tuition Fee',
      'Reference Fee',
      'Tutor Fee',
      'Tuition Fee Due Date',
      'Tutor Fee Due Date',
      'Tuition Fee Status',
      'Tutor Fee Status'
    ]
    feelistAction
      .FeeListPromise(details)
      .then(res => {
        const mappedData =
          Array.isArray(res?.data?.data?.list) &&
          res?.data?.data?.list.length > 0
            ? res?.data?.data?.list.map(ele => {
                const returnDataObj = {
                  userName: `${ele?.parentId?.name || ''}`,
                  address: removeCommas(ele.demoClassId?.address) || '',
                  city: ele.demoClassId?.cityId?.name || '',
                  assignedTutor: ele.demoClassId?.tutorId?.name || '',
                  classReference:
                    removeCommas(ele.demoClassId?.classReference) || '',
                  tuitionFee: ele.tuitionFee || '',
                  referenceFee: ele.referenceFee || '0',
                  tutorFee: ele.tutorFee || '',
                  tuitionFeeDueDate: ele.demoClassId?.dueDate || '',
                  tutorFeeDueDate: ele.demoClassId?.tutorDueDate || '',
                  tuitionFeeStatus:
                    ele.status === 0
                      ? 'unpaid'
                      : ele.status === 1
                      ? 'Paid'
                      : ele.status === 2
                      ? 'Paid in Advance'
                      : ele.status === 3
                      ? 'Refund'
                      : ele.status === 4
                      ? 'No Fee this Month'
                      : '-',
                  tutorFeeStatus:
                    ele.tutorStatus === 0
                      ? 'unpaid'
                      : ele.tutorStatus === 1
                      ? 'Paid'
                      : ele.tutorStatus === 2
                      ? 'Paid in Advance'
                      : ele.tutorStatus === 3
                      ? 'Refund'
                      : ele.tutorStatus === 4
                      ? 'No Fee this Month'
                      : 'Fee Request'
                }
                return returnDataObj
              })
            : []

        const csvRows = mappedData.map(row => [
          row.userName,
          row.address,
          row.city,
          row.assignedTutor,
          row.classReference,
          row.tuitionFee,
          row.referenceFee,
          row.tutorFee,
          row.tuitionFeeDueDate,
          row.tutorFeeDueDate,
          row.tuitionFeeStatus,
          row.tutorFeeStatus
        ])

        const csvContent = [
          csvHeaders.join(','),
          ...csvRows.map(row => row.join(','))
        ].join('\n')

        const blob = new Blob([csvContent], { type: 'text/csv' })
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.setAttribute('hidden', '')
        a.setAttribute('href', url)
        a.setAttribute('download', 'Fee_data.csv')
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
      })
      .catch(error => {
        console.error('Error fetching fee list:', error)
      })
  }

  return (
    <>
      <Loader loading={selector?.FeeList?.loading} />

      <div className='lg:flex hidden justify-end place-items-center gap-5 mx-9  md:space-y-0 space-y-4 mt-5'>
        <div className='flex items-center flex-wrap gap-2'>
          <Select
            id='classReference'
            name='classReference'
            className='capitalize text-black text-sm w-40'
            styles={customStyles}
            classNamePrefix='react-select'
            value={classReference || { label: 'Class Reference', value: '' }}
            options={
              classReferenceList && classReferenceList.length > 0
                ? [
                    { label: 'Class Reference', value: '' },
                    ...classReferenceList.map(refer => ({
                      label: refer,
                      value: refer
                    }))
                  ]
                : []
            }
            onChange={(selectedOption, actionMeta) => {
              setClassReference(selectedOption)
            }}
          />
          <Select
            id='tutorId'
            name='Tutor Name'
            className='capitalize text-black text-sm w-40'
            styles={customStyles}
            classNamePrefix='react-select'
            value={assignTutor || { label: 'Select Tutor', value: '' }}
            options={
              tutorList && tutorList.length > 0
                ? [
                    { label: 'Select Tutor', value: '' },
                    ...tutorList.map(tutor => ({
                      label: tutor.name,
                      value: tutor._id
                    }))
                  ]
                : []
            }
            onChange={(selectedOption, actionMeta) => {
              setAssignTutor(selectedOption)
            }}
            placeholder='Tutor Name'
          />

          <Select
            id='parentId'
            name='parentId'
            value={parentId}
            options={[{ label: 'Select Parent', value: '' }, ...parentOptions]}
            className='capitalize text-black text-sm w-40'
            styles={customStyles}
            classNamePrefix='react-select'
            placeholder='Parent Name'
            onChange={(selectedOption, actionMeta) => {
              setParentId(selectedOption)
            }}
          />

          <select
            onChange={e => setdate(e.target.value)}
            className='items-center justify-center bg-white border-2 text-[#000] rounded-lg w-36 px-2 py-2.5'
            value={date}
          >
            <option value=''>Due Date</option>
            {Array.from({ length: 31 }, (_, i) => i + 1).map(day => (
              <option key={day} value={day}>
                {day}
              </option>
            ))}
          </select>
          <select
            className='flex items-center justify-center bg-white border-2 text-[#000] rounded-lg w-36 px-2 py-2.5'
            value={status}
            onChange={e => setStatus(e.target.value)}
          >
            <option value=''>Tuition Fee Status</option>
            <option value={1}>Paid </option>
            <option value={0}>Unpaid </option>
            <option value={4}>No Fee </option>
            <option value={3}>Fee Refunded</option>
          </select>
          <select
            className='flex items-center justify-center bg-white border-2 text-[#000] rounded-lg w-36 px-2 py-2.5'
            value={tutorStatus}
            onChange={e => setTutorStatus(e.target.value)}
          >
            <option value=''>Tutor Fee Status</option>
            <option value={0}>Unpaid </option>
            <option value={1}>Paid End </option>
            <option value={2}>Paid Advance </option>
            <option value={4}>No Fee </option>
            <option value={3}>Fee Refunded</option>
            <option value={5}>Fee Request</option>
          </select>
          <input
            type='month'
            name=''
            className='py-1.5 px-2 bg-white border-2 text-[#000] rounded-lg'
            value={currentMonth}
            onChange={e =>
              e.target.value !== ''
                ? setCurrentMonth(e.target.value)
                : setCurrentMonth(moment().startOf('month').format('YYYY-MM'))
            }
          />
          <button className='text-center font-bold'>
            <FaFilterCircleXmark
              size={24}
              className=' mx-auto text-[#023565] hover:text-[#E4006F] cursor-pointer'
              onClick={e => {
                handleResetFilter()
              }}
            />
          </button>
          {userLoginDetails?.roleAccess?.roleId === 1 && (
            <div>
              <button
                onClick={exportToCSV}
                type=''
                className='border rounded-md px-6 border-[#D9D9D9] py-2 bg-white inline-flex items-center gap-4'
              >
                Export{' '}
                <span>
                  <PiExportLight />
                </span>
              </button>
            </div>
          )}
          <button
            className='text-center font-bold'
            onClick={() => handleRefreshFeeHistory()}
            title='Refresh fee history'
          >
            <FaRotate
              size={24}
              className='mx-auto text-[#023565] hover:text-[#E4006F] cursor-pointer'
            />
          </button>
        </div>
      </div>

      <div className='relative lg:hidden block m-5'>
        <div className='flex justify-end items-center gap-4'>
          <div>
            <button
              type=''
              onClick={exportToCSV}
              className='border rounded-md px-6 border-[#D9D9D9] py-1 bg-white hover:bg-[#023565] hover:text-white text-[#023565] flex items-center gap-2'
            >
              Export{' '}
              <span>
                <PiExportLight />
              </span>
            </button>
          </div>
          <div>
            <RiEqualizerFill
              color='#023565'
              onClick={toggleDropdown}
              size={24}
            />
          </div>
          <div>
            <FaFilterCircleXmark
              color='#023565'
              onClick={e => {
                handleResetFilter()
              }}
              size={24}
            />
          </div>
          <FaRotate
            size={24}
            color='#023565'
            onClick={() => handleRefreshFeeHistory()}
          />
        </div>

        {isOpen && (
          <div
            className='origin-top-right   mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 p-4'
            role='menu'
            aria-orientation='vertical'
            aria-labelledby='menu-button'
          >
            <div className='filter flex justify-end  flex-col gap-4'>
              <Select
                id='classReference'
                name='classReference'
                className='capitalize text-black text-sm'
                styles={customStyles}
                classNamePrefix='react-select'
                value={
                  classReference || { label: 'Class Reference', value: '' }
                }
                options={
                  classReferenceList && classReferenceList.length > 0
                    ? [
                        { label: 'Class Reference', value: '' },
                        ...classReferenceList.map(refer => ({
                          label: refer,
                          value: refer
                        }))
                      ]
                    : []
                }
                onChange={(selectedOption, actionMeta) => {
                  setClassReference(selectedOption)
                }}
              />
              <Select
                id='tutorId'
                name='Tutor Name'
                className='capitalize text-black text-sm'
                styles={customStyles}
                classNamePrefix='react-select'
                value={assignTutor || { label: 'Select Tutor', value: '' }}
                options={
                  tutorList && tutorList.length > 0
                    ? [
                        { label: 'Select Tutor', value: '' },
                        ...tutorList.map(tutor => ({
                          label: tutor.name,
                          value: tutor._id
                        }))
                      ]
                    : []
                }
                onChange={(selectedOption, actionMeta) => {
                  setAssignTutor(selectedOption)
                }}
                placeholder='Tutor Name'
              />

              <Select
                id='parentId'
                name='parentId'
                value={parentId}
                options={[
                  { label: 'Select Parent', value: '' },
                  ...parentOptions
                ]}
                className='capitalize text-black text-sm'
                styles={customStyles}
                classNamePrefix='react-select'
                placeholder='Parent Name'
                onChange={(selectedOption, actionMeta) => {
                  setParentId(selectedOption)
                }}
              />

              <select
                onChange={e => setdate(e.target.value)}
                className='items-center justify-center bg-white border-2 text-[#000] rounded-lg w-full px-2 py-2.5'
                value={date}
              >
                <option value=''>Due Date</option>
                {Array.from({ length: 31 }, (_, i) => i + 1).map(day => (
                  <option key={day} value={day}>
                    {day}
                  </option>
                ))}
              </select>
              <select
                className='flex items-center justify-center bg-white border-2 text-[#000] rounded-lg w-full px-2 py-2.5'
                value={status}
                onChange={e => setStatus(e.target.value)}
              >
                <option value=''>Tuition Fee Status</option>
                <option value={1}>Paid </option>
                <option value={0}>Unpaid </option>
                <option value={4}>No Fee </option>
                <option value={3}>Fee Refunded</option>
              </select>
              <select
                className='flex items-center justify-center bg-white border-2 text-[#000] rounded-lg w-full px-2 py-2.5'
                value={tutorStatus}
                onChange={e => setTutorStatus(e.target.value)}
              >
                <option value=''>Tutor Fee Status</option>
                <option value={0}>Unpaid </option>
                <option value={1}>Paid End </option>
                <option value={2}>Paid Advance </option>
                <option value={4}>No Fee </option>
                <option value={3}>Fee Refunded</option>
                <option value={5}>Fee Request</option>
              </select>
              <input
                type='month'
                name=''
                className='py-1.5 px-2 bg-white border-2 text-[#000] rounded-lg w-full'
                value={currentMonth}
                onChange={e =>
                  e.target.value !== ''
                    ? setCurrentMonth(e.target.value)
                    : setCurrentMonth(
                        moment().startOf('month').format('YYYY-MM')
                      )
                }
              />
            </div>
          </div>
        )}
      </div>

      <div className='m-5'>
        <section className='lg:block hidden  overflow-hidden overflow-x-auto'>
          <table className='text-left w-full rtl:text-right bg-white text-[#313131] rounded-md'>
            <thead>
              <tr className='capitalize border-b '>
                <th className='px-6 py-3 whitespace-nowrap text-sm text-black text-center'>
                  Parent/Student
                  <br /> Name
                </th>
                <th className='px-6 py-3 whitespace-nowrap text-sm text-black text-center'>
                  Address <br /> City
                </th>
                <th className='px-6 py-3 whitespace-nowrap text-sm text-black text-center'>
                  Assigned <br /> Tutor
                </th>
                <th className='px-6 py-3 whitespace-nowrap text-sm text-black text-center'>
                  Class <br />
                  Reference
                </th>
                <th className='px-6 py-3 whitespace-nowrap text-sm text-black text-center'>
                  Tuition <br />
                  Fee
                </th>
                <th className='px-6 py-3 whitespace-nowrap text-sm text-black text-center'>
                  Reference <br />
                  Fee
                </th>
                <th className='px-6 py-3 whitespace-nowrap text-sm text-black text-center'>
                  Tutor <br />
                  Fee
                </th>
                <th className='px-6 py-3 whitespace-nowrap text-sm text-black text-center'>
                  Tuition Fee
                  <br />
                  Due Date
                </th>
                <th className='px-6 py-3 whitespace-nowrap text-sm text-black text-center'>
                  Tutor Fee
                  <br />
                  Due Date
                </th>
                <th className='px-6 py-3 whitespace-nowrap text-sm text-black text-center'>
                  Note
                </th>
                <th className='px-6 py-3 whitespace-nowrap text-sm text-black text-center'>
                  Tuition Fee <br />
                  Status
                </th>
                <th className='px-6 py-3 whitespace-nowrap text-sm text-black text-center'>
                  Tutor Fee <br />
                  Status
                </th>
                <th className='px-6 py-3 whitespace-nowrap text-sm text-black text-center'>
                  Action
                </th>
              </tr>
            </thead>
            <tbody className='divide-y divide-gray-200'>
              {feeListData && feeListData.length > 0 ? (
                feeListData?.map((item, id) => (
                  <tr className='capitalize' key={id}>
                    <td className='px-4 py-4 flex items-center gap-4 text-sm text-gray-900'>
                      <div className='flex items-center justify-center gap-2'>
                        <span>
                          {item?.parentId && item?.parentId?.image ? (
                            <img
                              alt=''
                              src={
                                item?.parentId?.image ??
                                'https://thumbs.dreamstime.com/z/vector-illustration-avatar-dummy-logo-collection-image-icon-stock-isolated-object-set-symbol-web-137160339.jpg?ct=jpeg'
                              }
                              className='rounded-full my-3 max-w-[50px] max-h-[50px] min-w-[50px] min-h-[50px]'
                            />
                          ) : (
                            <div className='bg-gray-300 rounded-full h-14 w-14 flex justify-center items-center'>
                              <span className='text-base font-semibold text-[#033565]'>
                                {' '}
                                {item?.parentId?.name
                                  ? item.parentId.name
                                      .split(' ')
                                      .map(name => name.charAt(0))
                                      .join('')
                                  : ''}
                              </span>
                            </div>
                          )}
                        </span>
                        <div className='w-24'>
                          <p className='font-semibold whitespace-nowrap text-[#023565]'>
                            {item?.parentId?.name ?? 'N/A'}
                          </p>
                          <p className='text-gray-500 whitespace-nowrap'>
                            {item?.parentId?.mobNo ?? 'N/A'}
                          </p>
                          <div
                            className='flex items-center  gap-1 cursor-pointer'
                            onClick={() => handleFullDetails(item)}
                          >
                            <p className='text-base font-normal cursor-pointer'>
                              More
                            </p>
                            <FaChevronCircleRight className='text-[#023565] text-base cursor-pointer' />
                          </div>
                        </div>
                      </div>
                    </td>

                    <td
                      className='px-4 py-4 text-sm text-gray-900 text-center capitalize'
                      title={item?.demoClassId?.address ?? 'No Address'}
                    >
                      {item?.demoClassId?.cityId?.name ?? 'No City'}
                    </td>

                    <td className='px-4 py-4 text-sm'>
                      <span className='text-center flex items-center justify-center text-[#023565] font-semibold'>
                        {item?.demoClassId?.tutorId?.name}
                      </span>
                      <span className='text-center flex items-center justify-center text-gray-500'>
                        {item?.demoClassId?.tutorId?.mobNo}
                      </span>
                    </td>

                    <td className='px-4 py-4 text-sm text-gray-900 text-center'>
                      {item?.demoClassId?.classReference
                        ? item?.demoClassId?.classReference
                        : '-'}
                    </td>
                    <td className='px-4 py-4 text-sm text-gray-900 text-center'>
                      {item?.tuitionFee ?? 'No Fee'}
                    </td>
                    <td className='px-4 py-4 text-sm text-gray-900 text-center'>
                      {item?.referenceFee ?? 'No Fee'}
                    </td>
                    <td className='px-4 py-4 text-sm text-gray-900 text-center'>
                      {item?.tutorFee ?? 'No Fee'}
                    </td>
                    <td className='px-4 py-4 text-sm text-gray-900 text-center'>
                      {item?.demoClassId?.dueDate ?? '-'}
                    </td>
                    <td className='px-4 py-4 text-sm text-gray-900 text-center'>
                      {item?.demoClassId?.tutorDueDate ?? '-'}
                    </td>
                    <td className='px-4 py-4 text-sm text-[#023565] text-center cursor-pointer font-semibold'>
                      {item?.remark ? (
                        <p onClick={() => handleNote(item)}>{'View'}</p>
                      ) : (
                        <p onClick={() => handleNote(item)}>{'Add'}</p>
                      )}
                    </td>

                    <td
                      className={`px-4 py-4 text-sm relative  font-semibold ${
                        item?.status === 0
                          ? 'text-[#f39a26]'
                          : item?.status === 1
                          ? 'text-[#1ea33f]'
                          : item?.status === 2
                          ? 'text-red-600'
                          : item?.status === 3
                          ? 'text-black'
                          : item?.status === 4
                          ? 'text-gray-500'
                          : 'text-black'
                      }`}
                    >
                      <p
                        className='cursor-pointer text-center'
                        onClick={() =>
                          item?.status !== 0
                            ? OpenDetails(item._id, 'parent')
                            : null
                        }
                      >
                        {item?.status === 0
                          ? 'unpaid'
                          : item?.status === 1
                          ? 'Paid'
                          : item?.status === 2
                          ? 'Paid in Advance'
                          : item?.status === 3
                          ? 'Refund'
                          : item?.status === 4
                          ? 'No Fee this Month'
                          : '-'}
                      </p>

                      {openDetailsId === item._id &&
                        (item?.userId ||
                          item?.payDate ||
                          item?.adminId?.roleId ||
                          item?.userId?.name ||
                          item?.adminId?.userName) && (
                          <div
                            className='absolute right-20 bottom-0 w-52 p-2  bg-white border border-gray-300 rounded-lg shadow-lg z-50'
                            ref={menuRef}
                          >
                            {item?.payDate && (
                              <p>
                                {`Payment date: ${moment(item?.payDate).format(
                                  'DD-MMM-YYYY'
                                )}`}
                              </p>
                            )}
                            {item?.status === 3 ? (
                              <div>
                                <p>
                                  Refunded date:{' '}
                                  {moment(item?.tuitionFeeRefundDate).format(
                                    'DD-MMM-YYYY'
                                  )}
                                </p>
                                <p>
                                  Refunded amount: {item.tuitionFeeRefundedPaid}
                                </p>
                              </div>
                            ) : (
                              ''
                            )}
                            <p>
                              {item &&
                              item.adminId &&
                              item.adminId.roleId &&
                              item.adminId.roleId === 1
                                ? `Done by: Super Admin`
                                : item &&
                                  item.adminId &&
                                  item.adminId.roleId &&
                                  item.adminId.roleId !== 1
                                ? `Done by: Sub Admin`
                                : item && item.userId && item.userId.name
                                ? `Done by: Parent`
                                : null}
                            </p>
                            <p>
                              {item?.adminId?.userName
                                ? `Login Id: ${item?.adminId?.userName}`
                                : item?.userId?.name
                                ? `Login Id: ${item?.userId?.name}`
                                : null}
                            </p>
                          </div>
                        )}
                    </td>

                    <td
                      className={`px-4 py-4 relative text-sm font-semibold ${
                        item?.tutorStatus === 0
                          ? 'text-[#f39a26]'
                          : item?.tutorStatus === 1
                          ? 'text-[#1ea33f]'
                          : item?.tutorStatus === 2
                          ? 'text-red-600'
                          : item?.tutorStatus === 3
                          ? 'text-black'
                          : item?.tutorStatus === 4
                          ? 'text-gray-500'
                          : 'text-black'
                      }`}
                    >
                      <p
                        className='cursor-pointer text-center'
                        onClick={() =>
                          item?.tutorStatus !== 0
                            ? OpenDetails(item._id, 'tutor')
                            : null
                        }
                      >
                        {item?.tutorStatus === 0
                          ? 'unpaid'
                          : item?.tutorStatus === 1
                          ? 'Paid'
                          : item?.tutorStatus === 2
                          ? 'Paid in Advance'
                          : item?.tutorStatus === 3
                          ? 'Refund'
                          : item?.tutorStatus === 4
                          ? 'No Fee this Month'
                          : 'Fee Request'}
                      </p>

                      {openDetailsTutorId === item._id &&
                        (item?.userId ||
                          item?.tutorPayDate ||
                          item?.adminId?.roleId ||
                          item?.userId?.name ||
                          item?.adminId?.userName ||
                          item?.feeRequestDate) && (
                          <div
                            className='absolute right-20 bottom-0 w-52 p-2  bg-white border border-gray-300 rounded-lg shadow-lg z-10'
                            ref={menuTutorRef}
                          >
                            {item?.feeRequestDate && (
                              <p>{`Request Date: ${moment(
                                item.feeRequestDate
                              ).format('DD MMM YYYY')}`}</p>
                            )}

                            {item?.tutorPayDate && (
                              <p>
                                {`Payment date: ${moment(
                                  item?.tutorPayDate
                                ).format('DD-MMM-YYYY')}`}
                              </p>
                            )}
                            {item?.tutorStatus === 3 ? (
                              <span>
                                <p>
                                  Refunded date:{' '}
                                  {moment(item?.tutorFeeRefundDate).format(
                                    'DD-MMM-YYYY'
                                  )}
                                </p>
                                <p>
                                  Refunded amount:{' '}
                                  {item.tutorFeeRefundedReceived}
                                </p>
                              </span>
                            ) : (
                              ''
                            )}

                            <p>
                              {item &&
                              item.adminId &&
                              item.adminId.roleId &&
                              item.adminId.roleId === 1
                                ? `Done by: Super Admin`
                                : item &&
                                  item.adminId &&
                                  item.adminId.roleId &&
                                  item.adminId.roleId !== 1
                                ? `Done by: Sub Admin`
                                : item && item.userId && item.userId.name
                                ? `Done by: Parent`
                                : null}
                            </p>
                            <p>
                              {item?.adminId?.userName
                                ? `Login Id: ${item?.adminId?.userName}`
                                : item?.userId?.name
                                ? `Login Id: ${item?.userId?.name}`
                                : null}
                            </p>
                          </div>
                        )}
                    </td>

                    <td className='px-4 py-4 text-gray-900 relative table-cell'>
                      <button
                        className='flex justify-center items-center'
                        id='actionId'
                        type='button'
                      >
                        <BsThreeDotsVertical
                          onClick={() => {
                            console.log('setOpenActionId', item._id)
                            setOpenActionId(item._id)
                          }}
                          className='text-2xl cursor-pointer text-red-500'
                        />
                      </button>
                      {openActionId === item._id && (
                        <ul
                          htmlFor='actionId'
                          className='bg-white border text-[#023565] rounded-md font-bold  absolute w-fit whitespace-nowrap right-20 bottom-2  py-4 max-h-40 overflow-hidden overflow-y-auto Pokercardbar text-start px-2 text-sm shadow-lg z-50'
                          ref={actionMenuRef}
                        >
                          <li
                            className=' px-2 py-1 border-b  flex justify-between items-center hover:text-[#C60E6B]'
                            onClick={e => handlePushReminder(item, e)}
                          >
                            Tuition Fee Reminder
                            <FaAngleRight className='hover:text-[#C60E6B]' />
                          </li>
                          <li
                            className=' px-2 py-1 border-b  flex justify-between items-center hover:text-[#C60E6B]'
                            onClick={() =>
                              handleMarkPaidTuitionFee(item, 'PARENT', 1)
                            }
                          >
                            Mark Paid Tuition Fee
                            <FaAngleRight className='hover:text-[#C60E6B]' />
                          </li>
                          <li
                            className=' px-2 py-1 border-b  flex justify-between items-center hover:text-[#C60E6B]'
                            onClick={() =>
                              handleMarkPaidTuitionFee(item, 'TUTOR', 2)
                            }
                          >
                            Mark Paid Tutor Fee-Advance
                            <FaAngleRight className='hover:text-[#C60E6B]' />
                          </li>
                          <li
                            className=' px-2 py-1 border-b  flex justify-between items-center hover:text-[#C60E6B]'
                            onClick={() =>
                              handleMarkPaidTuitionFee(item, 'TUTOR', 1)
                            }
                          >
                            Mark Paid Tutor Fee-Month End
                            <FaAngleRight className='hover:text-[#C60E6B]' />
                          </li>
                          {item.status !== 4 && item.tutorStatus !== 4 && (
                            <li
                              className=' px-2 py-1 border-b  flex justify-between items-center hover:text-[#C60E6B]'
                              onClick={() => handleNoFee(item)}
                            >
                              Mark No Fee This Month
                              <FaAngleRight className='hover:text-[#C60E6B]' />
                            </li>
                          )}
                          <li
                            className=' px-2 py-1 border-b  flex justify-between items-center hover:text-[#C60E6B]'
                            onClick={() => handleDueDate(item)}
                          >
                            Update Due Date
                            <FaAngleRight className='hover:text-[#C60E6B]' />
                          </li>
                          {[1, 2].includes(item?.status) &&
                            [1, 2].includes(item?.tutorStatus) &&
                            item.status !== 3 &&
                            item.tutorStatus !== 3 && (
                              <li
                                className='px-2 py-1 border-b  flex justify-between items-center hover:text-[#C60E6B]'
                                onClick={() => handleRefundFee(item)}
                              >
                                Refund Fee
                                <FaAngleRight className='hover:text-[#C60E6B]' />
                              </li>
                            )}

                          {([1, 2, 3, 4].includes(item?.status) ||
                            [1, 2, 3, 4].includes(item?.tutorStatus)) && (
                            <li
                              className='px-2 py-1 border-b  flex justify-between items-center hover:text-[#C60E6B]'
                              onClick={() => {
                                handleRevertOperation(item)
                              }}
                            >
                              Revert Operation
                              <FaAngleRight className='hover:text-[#C60E6B]' />
                            </li>
                          )}
                        </ul>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={9} className='text-center'>
                    Data Not Available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </section>

        <section className='space-y-5 lg:hidden block'>
          {feeListData && feeListData.length > 0 ? (
            feeListData.map((item, id) => (
              <div
                key={id}
                className=' bg-white p-4 rounded-[10px] border border-[#023565]'
              >
                <>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[14px] font-[600] text-left'>
                        Parent/Student Name
                      </p>
                    </div>
                    <div className='w-full flex items-start'>
                      <div className='flex justify-start  gap-2 w-[95%]'>
                        <div>
                          {item?.parentId && item?.parentId?.image ? (
                            <img
                              alt=''
                              src={
                                item?.parentId?.image ??
                                'https://thumbs.dreamstime.com/z/vector-illustration-avatar-dummy-logo-collection-image-icon-stock-isolated-object-set-symbol-web-137160339.jpg?ct=jpeg'
                              }
                              className='rounded  max-w-[50px] max-h-[50px] min-w-[50px] min-h-[50px]'
                            />
                          ) : (
                            <div className='bg-gray-300 rounded h-14 w-14 flex justify-center items-center'>
                              <span className='text-base font-semibold text-[#033565]'>
                                {' '}
                                {item?.parentId?.name
                                  ? item.parentId.name
                                      .split(' ')
                                      .map(name => name.charAt(0))
                                      .join('')
                                  : ''}
                              </span>
                            </div>
                          )}
                        </div>
                        <div className=''>
                          <p className='text-[12px] font-semibold whitespace-nowrap text-[#023565]'>
                            {/* {item?.parentId?.name ?? 'Not Available'} */}
                            {truncateText(item?.parentId?.name, 10) ?? 'N/A'}
                          </p>
                          <p className='text-[12px] font-medium text-gray-500 whitespace-nowrap'>
                            {item?.parentId?.mobNo ?? 'Not Available'}
                          </p>
                          <div className='flex items-center  gap-3 cursor-pointer'>
                            <div
                              className='flex items-center  gap-1'
                              onClick={() => handleFullDetails(item)}
                            >
                              <p className='text-[12px] font-medium cursor-pointer'>
                                More
                              </p>
                              <FaChevronCircleRight className='text-[#023565] text-base cursor-pointer' />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='relative w-[5%]'>
                        <BsThreeDotsVertical
                          onClick={() => setOpenActionId(id)}
                          size={24}
                          className='text-2xl cursor-pointer text-red-500'
                        />
                        {openActionId === id && (
                          <div
                            className='absolute right-0 w-60 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50'
                            ref={actionMenuRef}
                          >
                            <ul className='cursor-pointer text-sm font-semibold p-3 space-y-1'>
                              <li
                                className='flex items-center cursor-pointer border-b pb-1 hover:bg-gray-100 rounded-t-lg text-[#023565] hover:text-[#C60E6B]'
                                onClick={() => handlePushReminder(item)}
                              >
                                Tuition Fee Reminder
                                <FaAngleRight className='hover:text-[#C60E6B]' />
                              </li>

                              <li
                                className='flex items-center cursor-pointer border-b pb-1 hover:bg-gray-100 rounded-t-lg text-[#023565] hover:text-[#C60E6B]'
                                onClick={() =>
                                  handleMarkPaidTuitionFee(item, 'PARENT', 1)
                                }
                              >
                                Mark Paid Tuition Fee
                                <FaAngleRight className='hover:text-[#C60E6B]' />
                              </li>

                              <li
                                className='flex items-center cursor-pointer border-b pb-1 hover:bg-gray-100 rounded-t-lg text-[#023565] hover:text-[#C60E6B]'
                                onClick={() =>
                                  handleMarkPaidTuitionFee(item, 'TUTOR', 2)
                                }
                              >
                                Mark Paid Tutor Fee-Advance
                                <FaAngleRight className='hover:text-[#C60E6B]' />
                              </li>

                              <li
                                className='flex items-center cursor-pointer border-b pb-1 hover:bg-gray-100 rounded-t-lg text-[#023565] hover:text-[#C60E6B]'
                                onClick={() =>
                                  handleMarkPaidTuitionFee(item, 'TUTOR', 1)
                                }
                              >
                                Mark Paid Tutor Fee-Month End
                                <FaAngleRight className='hover:text-[#C60E6B]' />
                              </li>

                              {item.status !== 4 && item.tutorStatus !== 4 && (
                                <li
                                  className='flex items-center cursor-pointer border-b pb-1 hover:bg-gray-100 rounded-t-lg text-[#023565] hover:text-[#C60E6B]'
                                  onClick={() => handleNoFee(item)}
                                >
                                  Mark No Fee This Month
                                  <FaAngleRight className='hover:text-[#C60E6B]' />
                                </li>
                              )}

                              <li
                                className='flex items-center cursor-pointer border-b pb-1 hover:bg-gray-100 rounded-t-lg text-[#023565] hover:text-[#C60E6B]'
                                onClick={() => handleDueDate(item)}
                              >
                                Update Due Date
                                <FaAngleRight className='hover:text-[#C60E6B]' />
                              </li>

                              {[1, 2].includes(item?.status) &&
                                [1, 2].includes(item?.tutorStatus) &&
                                item.status !== 3 &&
                                item.tutorStatus !== 3 && (
                                  <li
                                    className='flex items-center cursor-pointer border-b pb-1 hover:bg-gray-100 rounded-t-lg text-[#023565] hover:text-[#C60E6B]'
                                    onClick={() => handleRefundFee(item)}
                                  >
                                    Refund Fee
                                    <FaAngleRight className='hover:text-[#C60E6B]' />
                                  </li>
                                )}
                              {([1, 2, 3, 4].includes(item?.status) ||
                                [1, 2, 3, 4].includes(item?.tutorStatus)) && (
                                <li
                                  className='flex items-center cursor-pointer border-b pb-1 hover:bg-gray-100 rounded-t-lg text-[#023565] hover:text-[#C60E6B]'
                                  onClick={() => {
                                    handleRevertOperation(item)
                                  }}
                                >
                                  Revert Operation
                                  <FaAngleRight className='hover:text-[#C60E6B]' />
                                </li>
                              )}
                            </ul>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[14px] font-[600] text-left'>
                        Address
                      </p>
                    </div>
                    <div className='w-full'>
                      <p
                        className='text-[14px] font-[400] break-all'
                        title={item?.demoClassId?.address ?? 'No Address'}
                      >
                        {item?.demoClassId?.address ?? 'No Address'}
                      </p>
                    </div>
                  </div>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[14px] font-[600] text-left'>City</p>
                    </div>
                    <div className='w-full'>
                      <p className='text-[14px] font-[400]'>
                        {item?.demoClassId?.cityId?.name ?? 'No City'}
                      </p>
                    </div>
                  </div>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[14px] font-[600] text-left'>
                        Assigned Tutor
                      </p>
                    </div>
                    <div className='w-full'>
                      <p className='text-[12px] font-semibold whitespace-nowrap text-[#023565]'>
                        {item?.demoClassId?.tutorId?.name}
                      </p>
                      <p className='text-[14px] font-[400] text-gray-500'>
                        {item?.demoClassId?.tutorId?.mobNo}
                      </p>
                    </div>
                  </div>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[14px] font-[600] text-left'>
                        Class Reference
                      </p>
                    </div>
                    <div className='w-full'>
                      <p className='text-[14px] font-[400] capitalize'>
                        {item?.demoClassId?.classReference
                          ? item?.demoClassId?.classReference
                          : '-'}
                      </p>
                    </div>
                  </div>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[14px] font-[600] text-left'>
                        Tuition Fee
                      </p>
                    </div>
                    <div className='w-full'>
                      <p className='text-[14px] font-[400] capitalize'>
                        {item?.tuitionFee ?? 'No Fee'}
                      </p>
                    </div>
                  </div>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[14px] font-[600] text-left'>
                        Reference Fee
                      </p>
                    </div>
                    <div className='w-full'>
                      <p className='text-[14px] font-[400] capitalize'>
                        {item?.referenceFee ?? 'No Fee'}
                      </p>
                    </div>
                  </div>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[14px] font-[600] text-left'>
                        Tutor Fee
                      </p>
                    </div>
                    <div className='w-full'>
                      <p className='text-[14px] font-[400] capitalize'>
                        {item?.tutorFee ?? 'No Fee'}
                      </p>
                    </div>
                  </div>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[14px] font-[600] text-left'>
                        Tuition Fee Due Date
                      </p>
                    </div>
                    <div className='w-full'>
                      <p className='text-[14px] font-[400] capitalize'>
                        {item?.demoClassId?.dueDate ?? '-'}
                      </p>
                    </div>
                  </div>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[14px] font-[600] text-left'>
                        Tutor Fee Due Date
                      </p>
                    </div>
                    <div className='w-full'>
                      <p className='text-[14px] font-[400] capitalize'>
                        {item?.demoClassId?.tutorDueDate ?? '-'}
                      </p>
                    </div>
                  </div>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[14px] font-[600] text-left'>Note</p>
                    </div>
                    <div className='w-full'>
                      <p className='text-[14px] font-[600] capitalize text-[#023565]'>
                        {item?.remark ? (
                          <p onClick={() => handleNote(item)}>{'View'}</p>
                        ) : (
                          <p onClick={() => handleNote(item)}>{'Add'}</p>
                        )}
                      </p>
                    </div>
                  </div>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[14px] font-[600] text-left'>
                        Tuition Fee Status
                      </p>
                    </div>
                    <div
                      className={`w-full relative font-semibold ${
                        item?.status === 0
                          ? 'text-[#f39a26]'
                          : item?.status === 1
                          ? 'text-[#1ea33f]'
                          : item?.status === 2
                          ? 'text-red-600'
                          : item?.status === 3
                          ? 'text-black'
                          : item?.status === 4
                          ? 'text-gray-500'
                          : 'text-black'
                      }`}
                    >
                      <p
                        className='text-[14px] font-[600] capitalize '
                        onClick={() =>
                          item?.status !== 0
                            ? OpenDetails(item._id, 'parent')
                            : null
                        }
                      >
                        {item?.status === 0
                          ? 'unpaid'
                          : item?.status === 1
                          ? 'Paid'
                          : item?.status === 2
                          ? 'Paid in Advance'
                          : item?.status === 3
                          ? 'Refund'
                          : item?.status === 4
                          ? 'No Fee this Month'
                          : '-'}
                      </p>

                      {openDetailsId === item._id &&
                        (item?.userId ||
                          item?.payDate ||
                          item?.adminId?.roleId ||
                          item?.userId?.name ||
                          item?.adminId?.userName) && (
                          <div
                            className='absolute right-0 w-52 p-2  bg-white border border-gray-300 rounded-lg shadow-lg z-50'
                            ref={menuRef}
                          >
                            {item?.payDate && (
                              <p className='text-sm'>
                                {`Payment date: ${moment(item?.payDate).format(
                                  'DD-MMM-YYYY'
                                )}`}
                              </p>
                            )}
                            {item?.status === 3 ? (
                              <div>
                                <p className='text-sm'>
                                  Refunded date:{' '}
                                  {moment(item?.tuitionFeeRefundDate).format(
                                    'DD-MMM-YYYY'
                                  )}
                                </p>
                                <p className='text-sm'>
                                  Refunded amount: {item.tuitionFeeRefundedPaid}
                                </p>
                              </div>
                            ) : (
                              ''
                            )}
                            <p className='text-sm'>
                              {item &&
                              item.adminId &&
                              item.adminId.roleId &&
                              item.adminId.roleId === 1
                                ? `Done by: Super Admin`
                                : item &&
                                  item.adminId &&
                                  item.adminId.roleId &&
                                  item.adminId.roleId !== 1
                                ? `Done by: Sub Admin`
                                : item && item.userId && item.userId.name
                                ? `Done by: Parent`
                                : null}
                            </p>
                            <p className='text-sm'>
                              {item?.adminId?.userName
                                ? `Login Id: ${item?.adminId?.userName}`
                                : item?.userId?.name
                                ? `Login Id: ${item?.userId?.name}`
                                : null}
                            </p>
                          </div>
                        )}
                    </div>
                  </div>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[14px] font-[600] text-left'>
                        Tutor Fee Status
                      </p>
                    </div>
                    <div
                      className={`w-full relative font-semibold ${
                        item?.tutorStatus === 0
                          ? 'text-[#f39a26]'
                          : item?.tutorStatus === 1
                          ? 'text-[#1ea33f]'
                          : item?.tutorStatus === 2
                          ? 'text-red-600'
                          : item?.tutorStatus === 3
                          ? 'text-black'
                          : item?.tutorStatus === 4
                          ? 'text-gray-500'
                          : 'text-black'
                      }`}
                    >
                      <p
                        className='text-[14px] font-[600] capitalize '
                        onClick={() =>
                          item?.tutorStatus !== 0
                            ? OpenDetails(item._id, 'tutor')
                            : null
                        }
                      >
                        {item?.tutorStatus === 0
                          ? 'unpaid'
                          : item?.tutorStatus === 1
                          ? 'Paid'
                          : item?.tutorStatus === 2
                          ? 'Paid in Advance'
                          : item?.tutorStatus === 3
                          ? 'Refund'
                          : item?.tutorStatus === 4
                          ? 'No Fee this Month'
                          : 'Fee Request'}
                      </p>

                      {openDetailsTutorId === item._id &&
                        (item?.userId ||
                          item?.tutorPayDate ||
                          item?.adminId?.roleId ||
                          item?.userId?.name ||
                          item?.adminId?.userName ||
                          item?.feeRequestDate) && (
                          <div
                            className='absolute right-0 w-52 p-2  bg-white border border-gray-300 rounded-lg shadow-lg z-50'
                            ref={menuTutorRef}
                          >
                            {item?.feeRequestDate && (
                              <p className='text-sm'>{`Request Date: ${moment(
                                item.feeRequestDate
                              ).format('DD-MMM-YYYY')}`}</p>
                            )}

                            {item?.tutorPayDate && (
                              <p className='text-sm'>
                                {`Payment date: ${moment(
                                  item?.tutorPayDate
                                ).format('DD-MMM-YYYY')}`}
                              </p>
                            )}
                            {item?.tutorStatus === 3 ? (
                              <span>
                                <p className='text-sm'>
                                  Refunded date:{' '}
                                  {moment(item?.tutorFeeRefundDate).format(
                                    'DD-MMM-YYYY'
                                  )}
                                </p>
                                <p className='text-sm'>
                                  Refunded amount:{' '}
                                  {item.tutorFeeRefundedReceived}
                                </p>
                              </span>
                            ) : (
                              ''
                            )}

                            <p className='text-sm'>
                              {item &&
                              item.adminId &&
                              item.adminId.roleId &&
                              item.adminId.roleId === 1
                                ? `Done by: Super Admin`
                                : item &&
                                  item.adminId &&
                                  item.adminId.roleId &&
                                  item.adminId.roleId !== 1
                                ? `Done by: Sub Admin`
                                : item && item.userId && item.userId.name
                                ? `Done by: Parent`
                                : null}
                            </p>
                            <p className='text-sm'>
                              {item?.adminId?.userName
                                ? `Login Id: ${item?.adminId?.userName}`
                                : item?.userId?.name
                                ? `Login Id: ${item?.userId?.name}`
                                : null}
                            </p>
                          </div>
                        )}
                    </div>
                  </div>
                </>
              </div>
            ))
          ) : (
             <div  className=' bg-white p-4 rounded-[10px]'>
            <p className='text-center'>Data Not Found</p>
          </div>
          )}
        </section>

        {totalPages > 1 && (
          <div className='flex justify-end items-center py-2'>
            <button
              className={`px-4 py-2 border text-white bg-[#023565] rounded ${
                currentPage === 1 ? 'opacity-50 cursor-not-allowed' : ''
              }`}
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
            >
              <FaChevronLeft />
            </button>
            <span className='text-gray-700 mx-1'>
              <span className='border px-4 py-2 text-black  rounded'>
                {currentPage}
              </span>{' '}
              of{' '}
              <span className='border px-4 py-2 text-black  rounded'>
                {totalPages}
              </span>
            </span>
            <button
              className={`px-4 py-2 border text-white bg-[#023565] rounded ${
                currentPage === totalPages
                  ? 'opacity-50 cursor-not-allowed'
                  : ''
              }`}
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              <FaChevronRight />
            </button>
          </div>
        )}
        <StudentDetail
          setViewStudentModal={setViewStudentModal}
          viewStudentModal={viewStudentModal}
          setStudentListData={setStudentListData}
          studentListdata={studentListdata}
        />
        {noteModal && (
          <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40'>
            <div className='relative w-11/12 mx-auto lg:w-[400px]'>
              <button
                className='z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center'
                onClick={amountClose}
              >
                <span>
                  <MdOutlineClose />
                </span>{' '}
                Close
              </button>
              <div className='form_class border-0 rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
                <div className='flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t'>
                  <h2 className='text-lg font-semibold'> Note </h2>
                </div>
                <div className='relative h-[180px] overflow-y-auto'>
                  <form className='space-y-2 p-4'>
                    <div>
                      <label
                        htmlFor='subjectName'
                        className='block text-sm font-medium text-gray-700'
                      >
                        Note:
                      </label>
                      <input
                        type='text'
                        id='subjectName'
                        value={note}
                        onChange={e => setNote(e.target.value)}
                        className='mt-1 p-2 border block w-full border-gray-300 rounded-md'
                        required
                      />
                    </div>
                    <div className='flex justify-center items-center pt-2'>
                      <button
                        onClick={handleUpdateNote}
                        className='w-60 mx-auto bg-[#023565] text-white font-semibold py-2 px-4 rounded-full '
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        )}

        {openDate && (
          <UpdateFeeDueDate
            setFeeData={setFeeData}
            feeData={feeData}
            dateError={dateError}
            setDateError={setDateError}
            handleSubmitFeeData={handleSubmitFeeDateModal}
            handleCloseFeeData={handleCloseFeeDateModal}
          />
        )}

        {openRefund && (
          <UpdateFeeRefund
            setFeeData={setFeeData}
            feeData={feeData}
            dateError={dateError}
            setDateError={setDateError}
            handleSubmitFeeData={handleSubmitRefundFeeModal}
            handleCloseFeeData={handleCloseRefundFeeModal}
          />
        )}

        {noFeeModal && (
          <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40'>
            <div className='relative w-11/12 mx-auto lg:w-[400px]'>
              <button
                className='z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center'
                onClick={() => {
                  setNoFeeModal(false)
                  setNoFee({
                    userType: '',
                    feeStatus: 4,
                    disabled: true,
                    error: ''
                  })
                }}
              >
                <span>
                  <MdOutlineClose />
                </span>{' '}
                Close
              </button>
              <div className='form_class border-0 rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
                <div className='flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t'>
                  <h2 className='text-lg font-semibold'> No Fee </h2>
                </div>
                <div className='relative h-[180px] overflow-y-auto'>
                  <form className='space-y-2 p-4'>
                    <div>
                      <label
                        htmlFor='subjectName'
                        className='block text-sm font-medium text-gray-700'
                      >
                        Select No Fee:
                      </label>
                      <select
                        name='tutorDueDate'
                        onChange={e => {
                          handleChangeType(e)
                        }}
                        className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                        value={noFee.userType}
                      >
                        <option value=''>Select Type</option>
                        <option value='TUTOR'>For Tutor</option>
                        <option value='PARENT'>For Parent</option>
                        <option value='BOTH'>Both</option>
                      </select>
                      {noFee.error && (
                        <p className='text-red-500 text-xs'>{noFee.error}</p>
                      )}
                    </div>
                    <div className='flex justify-center items-center pt-2'>
                      <button
                        onClick={handleSubmitNoFee}
                        className='w-60 mx-auto bg-[#023565] text-white font-semibold py-2 px-4 rounded-full'
                        disabled={noFee.disabled}
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default FeeManagement
