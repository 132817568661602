/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { alertActions, tutionAction, userListAction } from "../../_actions";
import { useLocation } from "react-router-dom";
import Multiselect from "multiselect-react-dropdown";
import Loader from "../../components/Loader";
import { MdArrowBack } from "react-icons/md";


const TutorProfileUpdate = () => {
    const dispatch = useDispatch();
    const selector = useSelector((state) => state);
    const [updateData, setUpDateData] = useState("");
    const [city, setCity] = useState('')
    const [allCityListData, setAllCityListData] = useState('')
    const [allPincodeList, setAllPincodeList] = useState("");
    const [gender, setGender] = useState('')
    const [selectedTeachMode, setSelectedTeachMode] = useState('')
    const [pinCodeYoucanGo, setPinCodeYoucanGo] = useState([])
    let [allClassListByAdminData, setAllClassListByAdminData] = useState([]);
    const [updatedSubject, setUpdatedSubject] = useState([]);
    const [newUpdatedClass, setNewUpdatedClass] = useState([])
    const [dob, setDob] = useState('')
    const [pincodeUpdateData, setPinCodeUpdateData] = useState([])

    const [newPinCode, setNewPinCode] = useState([])

    // const navigate = useNavigate();
    const location = useLocation()

    useEffect(() => {
        dispatch(tutionAction.getAllCityList());
        dispatch(tutionAction.gitAllClassListByAdmin());
        dispatch(userListAction.gitAllSubjectListBySelectedData())
    }, []);

    useEffect(() => {

        setAllCityListData(selector?.tution?.getAllCityList?.data);
        setAllPincodeList(() => selector?.tution?.getPincodeList?.data);
        setAllClassListByAdminData(selector?.tution?.getAllClassList?.data);

    }, [selector]);


    useEffect(() => {
        setUpDateData(location.state);
        setGender(location.state?.tutorInfoId?.gender)
        setUpdatedSubject(location.state?.tutorInfoId?.subYouCanTeach?.map((e) => ({ id: e?._id, name: e?.name })))
        setNewUpdatedClass(location.state?.tutorInfoId?.classYouCanTeach?.map((e) => ({ id: e?._id, name: e?.name })))
        setPinCodeYoucanGo(location.state?.tutorInfoId?.pinCodeYouCanGo?.map((e) => ({ id: e, name: e })))
        setSelectedTeachMode(location.state?.tutorInfoId?.teachingMode?.map((e) => ({ id: e, name: techModeShow(e) })))
        setDob(location.state?.tutorInfoId?.dob)
        setCity(location.state?.city?._id)
    }, [])

    useEffect(() => {
        // Initialize pincodeUpdateData with existing pin codes if available
        if (updateData?.pinCode && updateData.pinCode.length > 0) {
            setPinCodeUpdateData(updateData.pinCode);
        }
    }, [updateData]);


    const handleOnSelect = (e) => {
        const { value } = e.target;
        setCity(value);
        if (value) {
            let cityID = {
                id: value,
            };
            dispatch(tutionAction.getCityPinCodeList(cityID));
        }
        setPinCodeYoucanGo([])
    };

    const handlePinCode = (e) => {
        const selectedPinCode = e.target.value;
        setPinCodeUpdateData([selectedPinCode]);
        setNewPinCode([selectedPinCode]);
    };

    const handleOnChnageGender = (e) => {
        setGender(e.target.value)
    }
    const options =
        Array.isArray(allPincodeList) && allPincodeList.length > 0
            ? allPincodeList.map((pin) => ({
                id: pin.pinCode,
                name: pin?.pinCode
            }))
            : [];

    const handlePinCodeYouCanGo = (selectedList) => {
        setPinCodeYoucanGo(selectedList);
    };

    const handleRemovePincodeYouCanGo = (selectedList) => {
        setPinCodeYoucanGo(selectedList);
    };


    function techModeShow(state) {
        switch (state) {
            case "HOME_TUITION":
                return "Home Tuition"
            case "ONLINE_TUITION":
                return "Online Tuition"
            default: return state
        }
    }


    const option = [
        { id: "HOME_TUITION", name: techModeShow("HOME_TUITION") },
        { id: "ONLINE_TUITION", name: techModeShow("ONLINE_TUITION") },
    ];

    const handleSelectMultiSelect = (selectedList) => {
        setSelectedTeachMode(selectedList);

    };

    const handleRemoveMultiSelect = (selectedList) => {
        setSelectedTeachMode(selectedList);
    };

    const classOptions = Array.isArray(allClassListByAdminData)
        ? allClassListByAdminData.map((ele) => ({ id: ele?._id, name: ele?.name }))
        : [];

    const handleUpdatedClasss = (selectedList) => {
        setNewUpdatedClass(selectedList);

    };

    const handleRemoveClasss = (selectedList) => {
        setNewUpdatedClass(selectedList);
    };

    const subjectoptions = Array.isArray(allClassListByAdminData)
        ? allClassListByAdminData.flatMap((ele) =>
            ele.subjectId
                ? ele.subjectId.map((subj) => ({ id: subj._id, name: subj.name }))
                : []
        )
        : [];
    const handleSubjectYouCanTeach = (selectedList) => {
        setUpdatedSubject(selectedList);
    };

    // Event handler for removing an option
    const handleRemoveSubject = (selectedList) => {
        setUpdatedSubject(selectedList);
    };

    const handleOnChange = (e) => {
        const { name, value } = e.target;

        if (name === "dob") {
            setDob(value);
        }

        setUpDateData((prevState) => {
            if (name === "dob") {
                return {
                    ...prevState,
                    tutorInfoId: {
                        ...prevState.tutorInfoId,
                        [name]: value,
                    },
                };
            }
            return {
                ...prevState,
                [name]: value,
                tutorInfoId: {
                    ...prevState.tutorInfoId,
                    [name]: value,
                },
            };
        });
    };

    const handleUpdateSubmit = (event) => {
        event.preventDefault()
        let upatedData = {
            id: updateData._id,
            name: updateData.name,
            email: updateData.email,
            city: city,
            pinCode: newPinCode.length > 0 ? newPinCode : pincodeUpdateData,
            address: updateData.address,
            whatsappNo: updateData.whatsappNo,
            dob: updateData.tutorInfoId.dob,
            pgPassOutYear: updateData.tutorInfoId.pgPassOutYear,
            ugPassOutYear: updateData.tutorInfoId.ugPassOutYear,
            ugCourse: updateData.tutorInfoId.ugCourse,
            pgCourse: updateData.tutorInfoId.pgCourse,
            schoolName: updateData.tutorInfoId.schoolName,
            ugCollegeName: updateData.tutorInfoId.ugCollegeName,
            pgCollegeName: updateData.tutorInfoId.pgCollegeName,
            teachingExp: updateData.tutorInfoId.teachingExp,
            teachingMode: selectedTeachMode?.map((ele) => ele.id) || [],
            classYouCanTeach: newUpdatedClass?.map((ele) => ele?.id) || [],
            subYouCanTeach: updatedSubject?.map((ele) => ele?.id) || [],
            pinCodeYouCanGo: pinCodeYoucanGo?.map((ele) => ele?.id) || [],
            gender: gender,
        };


        dispatch(userListAction.updateTutorByAdmin(upatedData));
        dispatch(alertActions.success('Update successful! Tutor details have been saved.'))

    };

    const handleBack = () => {
        window.history.back();
    }




    return (
        <>
            <div className="">
                <MdArrowBack className="text-blue-800 text-xl mt-8 ms-10 w-8 h-8 border-1 rounded-full cursor-pointer" onClick={handleBack} />
            </div>
            <Loader loading={selector?.userList?.loading} />
            <div className="m-5 bg-white p-6 rounded-lg shadow-lg overflow-hidden overflow-y-auto">
                <form className="mx-auto space-y-6 gap-10 grid grid-cols-3 gal-2">
                    <div className="relative z-0 w-full group  place-items-center gap-2">
                        <label className="w-28 text-sm font-semibold">Name  </label>
                        <input
                            type="text"
                            name="name"
                            value={updateData?.name}
                            onChange={handleOnChange}
                            className="block py-2.5 px-4 w-full text-sm border border-1-gray-300 rounded-md"
                            placeholder=" "
                            id=''
                        />

                    </div>

                    <div className="relative z-0 w-full group  place-items-center gap-2">
                        <label className="capitalize w-28 text-sm font-semibold">whatsapp no</label>
                        <input
                            type="number"
                            name="whatsappNo"
                            value={updateData?.whatsappNo}
                            onChange={handleOnChange}
                            className="block py-2.5 px-4 w-full text-sm border border-1-gray-300 rounded-md"
                            placeholder=" "
                            id=''
                        />

                    </div>

                    <div className="relative z-0 w-full group  place-items-center gap-2">
                        <label className="capitalize w-28 text-sm font-semibold">Email</label>
                        <input
                            type="text"
                            name="email"
                            value={updateData?.email}
                            onChange={handleOnChange}
                            className="block py-2.5 px-4 w-full text-sm border border-1-gray-300 rounded-md"
                            placeholder=" "
                            id=''
                        />

                    </div>

                    <div className="relative z-0 w-full group  place-items-center gap-2">
                        <label className="capitalize w-28 text-sm font-semibold">Address</label>
                        <input
                            type="text"
                            name="address"
                            value={updateData?.address}
                            onChange={handleOnChange}
                            className="block py-2.5 px-4 w-full text-sm border border-1-gray-300 rounded-md"
                            placeholder=" "
                            id=''
                        />

                    </div>
                    <div className=" place-items-center gap-2">
                        <label htmlFor="dob" className="capitalize w-28 text-sm font-semibold">Date of Birth</label>
                        <input
                            type="text"
                            id="dob"
                            name="dob"
                            value={dob}
                            onChange={handleOnChange}
                            className="block py-2.5 px-4 w-full text-sm border border-1-gray-300 rounded-md"
                            placeholder="Date of Birth"
                        />
                    </div>
                    <div className=" place-items-center gap-2">
                        <label htmlFor="pgCourse" className="capitalize w-28 text-sm font-semibold">Gender</label>
                        <select
                            className="block py-2.5 px-4 w-full text-sm border border-1-gray-300 rounded-md"
                            value={gender}
                            onChange={handleOnChnageGender}
                        >
                            <option value="">{gender}</option>
                            <option value="MALE">Male</option>
                            <option value="FEMALE">Female</option>
                        </select>
                    </div>
                    <div className=" place-items-center gap-2">
                        <label htmlFor="ugCourse" className="capitalize w-28 text-sm font-semibold">schoolName</label>
                        <input
                            type="text"
                            id="ugCourse"
                            name="schoolName"
                            value={updateData?.tutorInfoId?.schoolName || ""}
                            onChange={handleOnChange}
                            className="block py-2.5 px-4 w-full text-sm border border-1-gray-300 rounded-md"
                            placeholder="UG Course"
                        />
                    </div>

                    <div className=" place-items-center gap-2">
                        <label htmlFor="ugCourse" className="capitalize w-28 text-sm font-semibold">ugCollegeName</label>
                        <input
                            type="text"
                            id="ugCourse"
                            name="ugCollegeName"
                            value={updateData?.tutorInfoId?.ugCollegeName || ""}
                            onChange={handleOnChange}
                            className="block py-2.5 px-4 w-full text-sm border border-1-gray-300 rounded-md"
                            placeholder="UG Course"
                        />
                    </div>
                    <div className=" place-items-center gap-2">
                        <label htmlFor="ugCourse" className="capitalize w-28 text-sm font-semibold"> pgCollegeName</label>
                        <input
                            type="text"
                            id="ugCourse"
                            name="pgCollegeName"
                            value={updateData?.tutorInfoId?.pgCollegeName || ""}
                            onChange={handleOnChange}
                            className="block py-2.5 px-4 w-full text-sm border border-1-gray-300 rounded-md"
                            placeholder="UG Course"
                        />
                    </div>
                    <div className=" place-items-center gap-2">
                        <label htmlFor="pgCourse" className="capitalize w-28 text-sm font-semibold">PG Course(Optional)</label>
                        <input
                            type="text"
                            id="pgCourse"
                            name="pgCourse"
                            value={updateData?.tutorInfoId?.pgCourse || ""}
                            onChange={handleOnChange}
                            className="block py-2.5 px-4 w-full text-sm border border-1-gray-300 rounded-md"
                            placeholder="PG Course"
                        />
                    </div>


                    <div className="relative z-0 w-full group  place-items-center gap-2">
                        <label htmlFor="pgCourse" className="capitalize w-28 text-sm font-semibold">City</label>

                        <select
                            name="city"
                            onChange={handleOnSelect}
                            className="block py-2.5 px-4 w-full text-sm border border-1-gray-300 rounded-md"
                            id=''
                            value={city}
                        >
                            <option value="" disabled selected>Select City</option>
                            {allCityListData && allCityListData.map(city => (
                                <option key={city._id} value={city._id}>{city.name}</option>
                            ))}
                        </select>
                    </div>

                    <div className="relative z-0 w-full group  place-items-center gap-2">
                        <label htmlFor="pgCourse" className="capitalize w-28 text-sm font-semibold">pincode</label>

                        <select
                            onChange={handlePinCode}
                            value={pincodeUpdateData.length > 0 ? pincodeUpdateData[0] : ''}
                            className="block py-2.5 px-4 w-full text-sm border border-1-gray-300 rounded-md"
                        >
                            {allPincodeList && allPincodeList.map((pin, index) => (
                                <option key={index} value={pin.pinCode}>{pin.pinCode}</option>
                            ))}
                        </select>
                    </div>


                    <div className="relative z-0 w-full group  place-items-center gap-2">
                        <label className="capitalize w-28 text-sm font-semibold">PinCode you can teach</label>
                        <Multiselect
                            options={options}
                            selectedValues={pinCodeYoucanGo}
                            onSelect={handlePinCodeYouCanGo}
                            onRemove={handleRemovePincodeYouCanGo}
                            displayValue="name"
                            placeholder="Select Pin Codes"
                            closeOnSelect={false}
                            className="border-1-gray-300 rounded-md"
                            style={{
                                multiselectContainer: { width: "100%" },
                                optionContainer: { maxHeight: "100px" },
                            }}
                        />
                    </div>

                    <div className="relative z-0 w-full group  place-items-center gap-2">
                        <label className="capitalize w-28 text-sm font-semibold">Class</label>
                        <Multiselect
                            options={classOptions}
                            selectedValues={newUpdatedClass}
                            onSelect={handleUpdatedClasss}
                            onRemove={handleRemoveClasss}
                            displayValue="name"
                            placeholder="Select Classes"
                            closeOnSelect={false}
                            className="border-1-gray-300 rounded-md"
                            style={{
                                multiselectContainer: { width: "100%" },
                                optionContainer: { maxHeight: "100px" },
                            }}
                        />
                    </div>

                    <div className="relative z-0 w-full group  place-items-center gap-2">
                        <label className="capitalize w-28 text-sm font-semibold">Subject</label>
                        <Multiselect
                            options={subjectoptions}
                            selectedValues={updatedSubject}
                            onSelect={handleSubjectYouCanTeach}
                            onRemove={handleRemoveSubject}
                            displayValue="name"
                            placeholder="Select Subjects"
                            closeOnSelect={false}
                            className="border-1-gray-300 rounded-md"
                            style={{
                                multiselectContainer: { width: "100%" },
                                optionContainer: { maxHeight: "100px" },
                            }}
                        />
                    </div>


                    <div className="relative z-0 w-full group  place-items-center gap-2">
                        <label
                            htmlFor="floating_pgPassOutYear"
                            className="capitalize w-28 text-sm font-semibold"
                        >
                            PG Pass Out Year
                        </label>
                        <input
                            type="text"
                            name="pgPassOutYear"
                            value={updateData?.tutorInfoId?.pgPassOutYear || ""}
                            onChange={handleOnChange}
                            className="block py-2.5 px-4 w-full text-sm border border-1-gray-300 rounded-md"
                            placeholder=" "
                            id=''
                        />

                    </div>

                    <div className="relative z-0 w-full group  place-items-center gap-2">
                        <label
                            htmlFor="floating_ugPassOutYear"
                            className="capitalize w-28 text-sm font-semibold"
                        >
                            UG Pass Out Year
                        </label>
                        <input
                            type="text"
                            name="ugPassOutYear"
                            value={updateData?.tutorInfoId?.ugPassOutYear || ""}
                            onChange={handleOnChange}
                            className="block py-2.5 px-4 w-full text-sm border border-1-gray-300 rounded-md"
                            placeholder=" "
                            id=''
                        />

                    </div>
                    <div className=" place-items-center gap-2">
                        <label htmlFor="teachingMode" className="capitalize w-28 text-sm font-semibold">Teaching Mode</label>
                        <Multiselect
                            options={option}
                            selectedValues={selectedTeachMode}
                            onSelect={handleSelectMultiSelect}
                            onRemove={handleRemoveMultiSelect}
                            displayValue="name"
                            className="border-1-gray-300 rounded-md"
                            style={{
                                multiselectContainer: { width: "100%" },
                                optionContainer: { maxHeight: "100px" },
                            }}
                        />
                    </div>
                    <div className="flex items-center justify-end mt-4">
                        <button
                            type="submit"
                            onClick={handleUpdateSubmit}
                            className="inline-flex items-center px-4 py-2 bg-blue-600 border-1 border-1-transparent rounded-md font-semibold text-xs text-white uppercase tracking-widest hover:bg-blue-500 active:bg-blue-700 focus:outline-none focus:border-1-blue-700 focus:ring ring-blue-300 disabled:opacity-25 transition ease-in-out duration-150"
                        >
                            Submit
                        </button>
                    </div>
                </form>
            </div>

        </>
    );
};

export default TutorProfileUpdate;
