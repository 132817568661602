/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { cityAction } from '../../_actions/city.action'
import { alertActions, packageAction } from '../../_actions'
import Button from './Button'
import { BsThreeDotsVertical } from 'react-icons/bs'
import {
  FaAngleRight,
  FaChevronLeft,
  FaChevronRight,
  FaEdit,
  FaPlus
} from 'react-icons/fa'
import { confirmAlert } from 'react-confirm-alert'
import Loader from '../../components/Loader'
import { authHeader, backendUrl, timeZoneReturnDateType } from '../../_helpers'
import AddCity from './Models/AddCity'
import EditCity from './Models/EditCity'
import FullTable from './TableView/FullTable'
import { FaFilterCircleXmark } from 'react-icons/fa6'

function selectTuitionMode (val) {
  switch (val) {
    case 'HOME_TUITION':
      return { label: 'Home Tuition', value: 'HOME_TUITION' }
    case 'ONLINE_TUITION':
      return { label: 'Online Tuition', value: 'ONLINE_TUITION' }
    default:
      return null
  }
}

const RecentCity = ({ searchText }) => {
  const dispatch = useDispatch()
  const selector = useSelector(state => state)

  let [packageList, setPackageList] = useState()

  const [actionCityId, setActionCityId] = useState(null)
  const [name, setName] = useState('')
  const [management, setManagement] = useState('')

  const [pinCodeState, setPinCodeState] = useState(false)
  const [packageName, setPackageName] = useState('')
  const [modal, setModal] = useState(false)
  const [editModal, setEditModal] = useState(false)
  const [pckgid, setPckgId] = useState('')
  const [inputFields, setInputFields] = useState([{ value: '' }])
  const [inputFieldsEdit, setInputFieldsEdit] = useState([{ value: '' }])

  const [packageNotice, setPackageNotice] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const pageSize = 10
  const [editInput, setEditInput] = useState()
  const [filters, setFilters] = useState({
    isManagement: '',
    platformFee: '',
    isDisable: ''
  })

  const [tuitionMode, setTuitionMode] = useState('')

  const [packages, setPackages] = useState([])

  useEffect(() => {
    cityAction
      .getPackageAmountListByPromise({})
      .then(resData => {
        setPackages(resData?.data)
      })
      .catch(err => {})
  }, [])

  useEffect(() => {
    if (selector?.Citys?.getCityList?.data?.total) {
      setTotalPages(
        Math.ceil(selector?.Citys?.getCityList?.data?.total / pageSize)
      )
    } else {
      setTotalPages(0)
    }
    // setPackageList(selector?.Package?.packageList?.data?.list)
  }, [selector])

  const handlePreviousPage = () => {
    let packageList = {
      keyWord: searchText ?? '',
      fromDate: '',
      toDate: '',
      sortOrders: 'desc',
      sortBy: 'createdAt',
      pageNo: 1,
      size: 1000
    }

    dispatch(packageAction.packageGet(packageList))
    if (currentPage > 1) setCurrentPage(currentPage - 1)
  }

  const handleNextPage = () => {
    let packageList = {
      keyWord: searchText ?? '',
      fromDate: '',
      toDate: '',
      sortOrders: 'desc',
      sortBy: 'createdAt',
      pageNo: 1,
      size: 1000
    }

    dispatch(packageAction.packageGet(packageList))
    if (currentPage < totalPages) setCurrentPage(currentPage + 1)
  }

  const [selectedAmount, setSelectedAmount] = useState('')
  const options = [
    { label: 'Home Tuition', value: 'HOME_TUITION' },
    { label: 'Online Tuition', value: 'ONLINE_TUITION' }
  ]

  const [selectedOptions, setSelectedOptions] = useState([])
  const selectedTuitionMode = selectedOptions.map(select => select.value)

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])
  useEffect(() => {
    if (selectedOptions.some(option => option.value === 'HOME_TUITION')) {
      setPinCodeState(true)
    } else {
      setPinCodeState(false)
    }
  }, [selectedOptions])

  const handleAction = cityId => {
    setActionCityId(prevCityId => (prevCityId === cityId ? null : cityId))
  }

  const menuRef = useRef(null)

  const mobileRef = useRef(null)

  const handleClickOutside = event => {
    if (
      menuRef.current &&
      !menuRef.current.contains(event.target) &&
      mobileRef.current &&
      !mobileRef.current.contains(event.target)
    ) {
      setActionCityId(null)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    let cityDetails = {
      keyWord: searchText ?? '',
      fromDate: '',
      toDate: '',
      sortOrder: 'asc',
      sortBy: 'name',
      pageNo: currentPage,
      size: pageSize,
      tuitionMode,
      ...filters
    }
    dispatch(cityAction.cityGetList(cityDetails))
    packageAction
      .getAllPackageByPromise()
      .then(res => {
        setPackageList(() => res?.data)
      })
      .catch(err => {
        setPackageList(() => [])
      })
  }, [currentPage, filters, tuitionMode])

  //Create City-------------------->  value={editInput?.packageId || null}

  const handleOnChange = e => {
    e.preventDefault()
    const selectedPackageId = e.target.value
    setPackageName(selectedPackageId)
    const selectedPackage = packageList.find(
      item => item._id === selectedPackageId
    )
    setSelectedAmount(selectedPackage ? selectedPackage.amount : '')
    setEditInput({ ...editInput, packageId: selectedPackage?.name })
    setPckgId(selectedPackageId)
    if (errorData && errorData['packageName']) {
      setErrorData(prevErrorData => ({ ...prevErrorData, packageName: null }))
    }
  }

  const handelOnChangeEditInput = e => {
    let { name, value } = e.target
    setEditInput(() => {
      return {
        ...editInput,
        [name]: value
      }
    })
  }

  const handleInputChange = (index, event) => {
    const values = [...inputFields]
    values[index].value = event.target.value
    setInputFields(values)
  }
  const [selectedOptionsEdit, setSelectedOptionsEdit] = useState([])

  const handleRemove = index => {
    const values = [...inputFields]
    values.splice(index, 1)
    setInputFields(values)
  }

  const handleSelect = selectedList => {
    setSelectedOptionsEdit(selectedList)
  }

  const handleRemoveEdit = selectedList => {
    setSelectedOptionsEdit(selectedList)
  }

  const [errorData, setErrorData] = useState()

  const handleValidation = () => {
    const errors = {}

    if (!name) {
      errors['name'] = 'City Name Is required'
    }
    if (!management) {
      errors['management'] = 'Choose a management'
    }
    if (selectedOptions.length === 0) {
      errors['selectedOptions'] = 'Please select at least one option'
    }
    if (!packageName) {
      errors['packageName'] = 'Please select at least one option'
    }

    if (
      selectedOptions.find(ele => ele.value === 'HOME_TUITION') &&
      pinCodeState
    ) {
      inputFields.forEach((inputField, index) => {
        if (!inputField.value) {
          errors[`inputField_${index}`] = 'Pincode is required'
        }
      })
    }

    setErrorData(errors)
    return Object.keys(errors).length === 0
  }

  const handleSubmit = e => {
    e.preventDefault()
    if (!handleValidation()) {
      return
    }
    const pincodeArray = inputFields.map(field => field.value)
    let citydata = {
      keyWord: '',
      fromDate: '',
      toDate: '',
      sortOrder: 'desc',
      sortBy: 'createdAt',
      pageNo: 1,
      size: 10
    }
    let cityCreate = {
      name: name,
      packageId: packageName,
      platformFee: selectedAmount.toString(),
      pinCode: pincodeArray,
      packageNotice: packageNotice,
      tuitionMode: selectedTuitionMode,
      isManagement: management === 'Full Management'
    }
    dispatch(cityAction.cityCreate(cityCreate, citydata))

    setName('')
    setModal(false)
    setManagement('')
    setSelectedOptions([])
    setInputFields([])
  }

  const handleNewCity = () => {
    setModal(true)
  }

  const addSelect = () => {
    setInputFields([...inputFields, { value: '' }])
  }
  const addSelectEdit = () => {
    setInputFieldsEdit([...inputFieldsEdit, { value: '' }])
  }

  const handleInputChangeEdit = (index, event) => {
    const values = [...inputFieldsEdit]
    if (values[index]) {
      values[index].value = event.target.value
      setInputFieldsEdit(values)
    }
  }

  const handleRemoveEditInput = index => {
    const values = [...inputFieldsEdit]
    values.splice(index, 1)
    setInputFieldsEdit(values)
  }

  /////City Update=======================>>>>>>>>>>>>>>>>>>>
  useEffect(() => {
    if (selectedOptionsEdit?.some(option => option.value === 'HOME_TUITION')) {
      setPinCodeState(true)
    } else {
      setPinCodeState(false)
    }
  }, [selectedOptionsEdit])

  const handleEditCity = async data => {
    setEditModal(true)

    // dispatch(cityAction.getCityById({ id: data._id }));

    if (data?._id) {
      const response = await fetch(`${backendUrl}/v1/city/getCityById`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: authHeader()['Authorization']
        },
        body: JSON.stringify({
          id: data._id
        })
      })
      const cityData = await response.json()
      if (cityData.data) {
        const city = cityData.data
        const selectedModes = city.tuitionMode
          ?.map(item => selectTuitionMode(item))
          .filter(Boolean)
        setSelectedOptionsEdit(() => selectedModes)

        setEditInput(() => ({
          id: city._id,
          name: city.name,
          management: city.isManagement,
          packageId: city?.packageId?._id || '',
          pinCode: city.pinCode,
          packageNotice: city.packageNotice || ''
        }))
        if (
          city.pinCode &&
          Array.isArray(city.pinCode) &&
          city.pinCode.length > 0
        ) {
          setInputFieldsEdit(
            city.pinCode.filter(pin => pin !== '').map(pin => ({ value: pin }))
          )
        } else {
          setInputFieldsEdit([{ value: '' }])
        }
      }
    }
  }

  const handleValidationEdit = () => {
    let err = {}

    if (!editInput.name) {
      err['name'] = 'City Name Is required'
    }
    if (editInput.management === '') {
      err['management'] = 'Choose a management'
    }
    if (selectedOptionsEdit.length === 0) {
      err['selectedOptions'] = 'Please select at least one option'
    }
    if (
      (editInput.management === true || editInput.management === 'true') &&
      !editInput.packageId
    ) {
      err['packageName'] = 'Please select at least one option'
    }

    setErrorData(() => err)
    return Object.keys(err).length === 0
  }

  const submitEdit = e => {
    e.preventDefault()

    if (!handleValidationEdit()) {
      return
    }
    let citydata = {
      keyWord: searchText ?? '',
      fromDate: '',
      toDate: '',
      sortOrder: 'asc',
      sortBy: 'name',
      pageNo: currentPage,
      size: pageSize,
      ...filters
    }

    const platformAmount =
      Array.isArray(packageList) && packageList.length > 0
        ? packageList.find(
            item => String(item._id) === String(editInput?.packageId)
          )?.amount
        : 0

    let editedCity = {
      id: editInput.id,
      name: editInput.name,
      isManagement: editInput.management,
      pinCode: selectedOptionsEdit.find(ele => ele.value === 'HOME_TUITION')
        ? inputFieldsEdit.map(field => field.value)
        : [],
      tuitionMode: selectedOptionsEdit.map(field => field.value)
    }
    if (String(editInput.management) === 'true') {
      editedCity['packageId'] = editInput.packageId
      editedCity['packageNotice'] = editInput?.packageNotice || ''
      editedCity['platformFee'] = platformAmount.toString() ?? 0
    }
    if (editedCity['tuitionMode'].length === 0) {
      return dispatch(alertActions.error('Please select Tuition mode'))
    }
    if (selectedOptionsEdit.find(ele => ele.value === 'HOME_TUITION')) {
      if (editedCity['pinCode'].length === 0) {
        return dispatch(alertActions.error('Pin Code not be empty'))
      }
    }

    dispatch(cityAction.cityUpdate(editedCity, citydata))
    setEditModal(false)
  }

  const closeModal = () => {
    setModal(false)
    setSelectedAmount('')
    setSelectedOptions([])
    setName('')
    setPckgId('')
    setPackageNotice('')
    setManagement(false)
    setErrorData({})
  }

  const handleEnaDis = id => {
    let cityDetails = {
      keyWord: '',
      fromDate: '',
      toDate: '',
      sortOrder: 'asc',
      sortBy: 'name',
      pageNo: currentPage,
      size: pageSize,
      ...filters
    }

    const idforAction = {
      id: id._id
    }

    confirmAlert({
      title: `Confirm to ${id?.isDisable === false ? 'Inactive' : 'Active'}?`,
      // message: `Are you sure to Delete ?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () =>
            dispatch(cityAction.cityEnaDis(idforAction, cityDetails))
        },
        {
          label: 'No'
        }
      ]
    })
    // dispatch(cityAction.cityEnaDis(idforAction, citydata));
  }

  let { Citys } = selector ? selector : {}
  let { getCityList } = Citys ? Citys : {}

  return (
    <>
      <div className='m-5'>
        <div className='flex md:justify-end items-center flex-wrap gap-2 lg:-mt-16'>
          <div className='flex justify-end '>
            <div
              className='border  bg-white rounded font-semibold border-solid text-[#C60E6B] py-2 border-[#D9D9D9] shadow flex px-3 items-center'
              onClick={handleNewCity}
            >
              <div className='mr-2'>
                <FaPlus className='cursor-pointer' />
              </div>
              <Button className='text-[#C60E6B]' name={'New City'} />
            </div>
          </div>
          <div>
            <select
              className='block w-full py-3 px-4 text-sm text-gray-900 border border-gray-300 rounded-md shadow bg-white'
              value={filters.isManagement}
              onChange={e => {
                setFilters(prev => ({ ...prev, isManagement: e.target.value }))
              }}
            >
              <option value=''>Work Mode</option>
              <option value={true}>Full Management</option>
              <option value={false}>Credit Based</option>
            </select>
          </div>
          <div>
            <select
              className='block w-full py-3 px-4 text-sm text-gray-900 border border-gray-300 rounded-md shadow bg-white'
              value={tuitionMode}
              onChange={e => setTuitionMode(e.target.value)}
            >
              <option value=''>Tuition Mode</option>
              <option value={'HOME_TUITION'}>Home Tuition</option>
              <option value={'ONLINE_TUITION'}>Online Tuition</option>
            </select>
          </div>
          <div>
            <select
              className='block w-full py-3 px-4 text-sm text-gray-900 border border-gray-300 rounded-md shadow bg-white'
              value={filters.platformFee}
              onChange={e => {
                setFilters(prev => ({
                  ...prev,
                  platformFee: e.target.value
                }))
              }}
            >
              <option value=''>Platform Fee</option>
              {packages &&
                packages.map((pack, index) => (
                  <option value={pack} key={index}>
                    {pack}
                  </option>
                ))}
            </select>
          </div>
          <div>
            <select
              className='block w-full py-3 px-4 text-sm text-gray-900 border border-gray-300 rounded-md shadow bg-white'
              value={filters.isDisable}
              onChange={e => {
                setFilters(prev => ({
                  ...prev,
                  isDisable: e.target.value
                }))
              }}
            >
              <option value=''>Status</option>
              <option value={false}>Active</option>
              <option value={true}>Inactive</option>
            </select>
          </div>
          <button
            className='text-center font-bold'
            onClick={() =>
              setFilters(prev => ({
                ...prev,
                isManagement: '',
                platformFee: '',
                isDisable: ''
              }))
            }
          >
            <FaFilterCircleXmark
              size={24}
              className=' mx-auto text-[#023565] hover:text-[#E4006F] cursor-pointer'
            />
          </button>
        </div>

        <Loader loading={selector?.Citys?.loading} />

        <FullTable
          getCityList={getCityList}
          handleAction={e => handleAction(e)}
          actionCityId={actionCityId}
          handleEditCity={e => handleEditCity(e)}
          menuRef={menuRef}
          totalPages={totalPages}
          handleEnaDis={e => handleEnaDis(e)}
          handlePreviousPage={e => handlePreviousPage(e)}
          currentPage={currentPage}
          handleNextPage={e => handleNextPage(e)}
        />
        <AddCity
          modal={modal}
          closeModal={closeModal}
          handleSubmit={e => handleSubmit(e)}
          name={name}
          selectedOptions={selectedOptions}
          setName={e => setName(e)}
          options={options}
          errorData={errorData}
          setErrorData={setErrorData}
          setSelectedOptions={e => setSelectedOptions(e)}
          pinCodeState={pinCodeState}
          inputFields={inputFields}
          handleInputChange={(i, e) => handleInputChange(i, e)}
          handleRemove={e => handleRemove(e)}
          addSelect={e => addSelect(e)}
          management={management}
          setManagement={e => setManagement(e)}
          handleOnChange={e => handleOnChange(e)}
          setPackageNotice={e => setPackageNotice(e)}
          packageNotice={packageNotice}
          pckgid={pckgid}
          selector={selector}
          selectedAmount={selectedAmount}
          packageList={packageList}
        />

        <EditCity
          editModal={editModal}
          setEditModal={setEditModal}
          submitEdit={e => submitEdit(e)}
          editInput={editInput}
          handelOnChangeEditInput={e => handelOnChangeEditInput(e)}
          options={options}
          selectedOptionsEdit={selectedOptionsEdit}
          addSelectEdit={e => addSelectEdit(e)}
          handleSelect={e => handleSelect(e)}
          handleRemoveEdit={e => handleRemoveEdit(e)}
          inputFieldsEdit={inputFieldsEdit}
          handleInputChangeEdit={(i, e) => handleInputChangeEdit(i, e)}
          handleRemoveEditInput={e => handleRemoveEditInput(e)}
          selector={selector}
          packageList={packageList}
          errorData={errorData}
          setErrorData={setErrorData}
        />

        <section className=' lg:hidden block space-y-5 pt-6'>
          {getCityList?.data?.list && getCityList?.data?.list.length > 0 ? (
            getCityList?.data?.list?.map((item, id) => (
              <div className=' bg-white p-4 rounded-[10px] border border-[#023565]'>
                <>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[16px] font-[700] text-left'>
                        City Name
                      </p>
                    </div>
                    <div className='w-full'>
                      <p className='text-[14px] font-[400] capitalize'>
                        {item.name}
                      </p>
                    </div>
                  </div>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[16px] font-[700] text-left'>
                        Work Mode{' '}
                      </p>
                    </div>
                    <div className='w-full'>
                      <p className='text-[14px] font-[400]'>
                        {item.isManagement ? 'Full Management' : 'Credit Based'}
                      </p>
                    </div>
                  </div>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[16px] font-[700] text-left'>
                        Platform Fee{' '}
                      </p>
                    </div>
                    <div className='w-full'>
                      <p className='text-[14px] font-[400]'>
                        {' '}
                        {item && item.packageId && item.packageId.amount
                          ? item.packageId.amount
                          : 0}
                      </p>
                    </div>
                  </div>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[16px] font-[700] text-left'>
                        Created Date
                      </p>
                    </div>
                    <div className='w-full'>
                      <p className='text-[14px] font-[400]'>
                        {' '}
                        {timeZoneReturnDateType(item?.createdAt)}
                      </p>
                    </div>
                  </div>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[16px] font-[700] text-left'>
                        Status{' '}
                      </p>
                    </div>
                    <div className='w-full'>
                      <p className='text-[14px] font-[400]'>
                        {' '}
                        <button  onClick={() => handleEnaDis(item)}
                          className='font-bold rounded-md'
                          style={{
                            color: item.isDisable ? 'red' : '#00AFE2'
                          }}
                        >
                          {item.isDisable ? 'Inactive' : 'Active'}
                        </button>
                      </p>
                    </div>
                  </div>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[16px] font-[700] text-left'>Action</p>
                    </div>
                    <div className='w-full'>
                      <p className='text-[14px] font-[400] relative'>
                        <FaEdit
                          onClick={() => handleEditCity(item)}
                          className='cursor-pointer text-xl  text-[#C60E6B] '
                        />
                    
                      </p>
                    </div>
                  </div>
                </>
              </div>
            ))
          ) : (
            <div>
              <p className='md:hidden text-center'>Data Not Available</p>
            </div>
          )}
        </section>

        {totalPages > 1 && (
          <div className='flex justify-end items-center py-2 px-4'>
            <button
              className={`px-4 py-2 border text-white bg-[#023565] rounded ${
                currentPage === 1 ? 'opacity-50 cursor-not-allowed' : ''
              }`}
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
            >
              <FaChevronLeft />
            </button>
            <span className='text-gray-700 mx-1'>
              <span className='border px-4 py-2 text-black  rounded'>
                {currentPage}
              </span>{' '}
              of{' '}
              <span className='border px-4 py-2 text-black  rounded'>
                {totalPages}
              </span>
            </span>
            <button
              className={`px-4 py-2 border text-white bg-[#023565] rounded ${
                currentPage === totalPages
                  ? 'opacity-50 cursor-not-allowed'
                  : ''
              }`}
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              <FaChevronRight />
            </button>
          </div>
        )}
      </div>
    </>
  )
}

export default RecentCity
