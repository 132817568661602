import React from 'react'

const Button = ({ name, handleClick }) => {
  return (
    <div>
      <button className='l' onClick={handleClick}>{name}</button>
    </div>
  )
}

export default Button
