/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { alertActions, parentAction, userActions } from '../../_actions'
import {
  FaAngleRight,
  FaChevronCircleRight,
  FaChevronLeft
} from 'react-icons/fa'
import { BsThreeDotsVertical } from 'react-icons/bs'
import moment from 'moment/moment'
import { useNavigate } from 'react-router'
import { FaChevronRight } from 'react-icons/fa'
import { tutionAction } from '../../_actions'
import Loader from '../../components/Loader'
import BlockPopUp from '../Tutor-Profile/Model/BlockPopUp'
import UpdateParent from './model/UpdateParent'
import ImagePreviewModal from '../Tutor-Profile/Model/ImagePreview'
import ViewUpdate from './model/ViewUpdate'
import StudentDetail from './model/StudentDetail'
import { FaEye } from 'react-icons/fa'
import { FaFilterCircleXmark } from 'react-icons/fa6'
import AddEditStudentModel from './model/AddEditStudentModel'
import { truncateText } from '../../_helpers'

const mergeAndAddStatus = (firstArray, secondArray) => {
  return secondArray.map(item => {
    const found = firstArray.find(
      firstItem => firstItem.studentId === item.studentId
    )
    return {
      ...item,
      status: !!found,
      subjectDetails: found ? found.subjectDetails : item.subjectDetails
    }
  })
}

const studentInitialState = {
  studentFullName: '',
  dob: '',
  grade: { label: 'Select Grade', value: '' },
  schoolName: '',
  educationBoard: { label: 'Select Board', value: '' },
  medium: '',
  gender: '',
  studentId: ''
}

const InActiveParent = ({ headerCity, searchText, handleReset }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const selector = useSelector(state => state)

  const [inactiveParentUserList, setinactiveParentUserList] = useState()
  let [studentListdata, setStudentListData] = useState()

  const [action, setAction] = useState(null)
  const [name, setName] = useState('')
  const [blockedData, setNewBlockedData] = useState({})
  const [viewStudentModal, setViewStudentModal] = useState(false)
  const [blockedPoup, setBlockPouUp] = useState(false)
  const [fullDetails, setFullDetails] = useState(false)
  const [DetailsData, setFullDetailsData] = useState()
  let [allClassListByAdminData, setAllClassListByAdminData] = useState([])
  const [nameBlock, setNameBlock] = useState('')
  const [initialParentData, setInitialParentData] = useState({})
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const pageSize = 10

  const menuRef = useRef(null)

  const mobileRef = useRef(null)

  const handleClickOutside = event => {
    if (
      menuRef.current &&
      !menuRef.current.contains(event.target) &&
      mobileRef.current &&
      !mobileRef.current.contains(event.target)
    ) {
      setAction(null)
    }
  }
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const [pinCode, setPinCode] = useState('')

  const [isOpenAddEditModel, setIsAddEditModel] = useState(false)
  const [addEditData, setAddEditData] = useState({
    ...studentInitialState,
    parentId: ''
  })

  const [parentUpdateModal, setParentUpdateModal] = useState(false)
  useEffect(() => {
    let inactiveParentDetails = {
      status: 'INACTIVE',
      userType: 'PARENT',
      keyWord: searchText,
      cityId: headerCity !== '' ? [headerCity] : [],
      fromDate: '',
      toDate: '',
      sortOrder: 'asc',
      sortBy: 'createdAt',
      pageNo: currentPage,
      size: pageSize,
      pinCode: pinCode
    }

    dispatch(parentAction.getUserList(inactiveParentDetails))
    dispatch(tutionAction.getAllCityList())
    dispatch(tutionAction.gitAllClassListByAdmin())
  }, [currentPage, headerCity, searchText, pinCode])

  useEffect(() => {
    if (selector?.Parent?.parentuserList?.data?.total) {
      setTotalPages(
        Math.ceil(selector?.Parent?.parentuserList?.data?.total / pageSize)
      )
    }
    setinactiveParentUserList(
      () => selector?.Parent?.parentuserList?.data?.demoList
    )
    setAllClassListByAdminData(selector?.tution?.getAllClassList?.data)
  }, [selector])

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1)
  }
  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1)
  }
  const [allPincodeList, setAllPincodeList] = useState('')

  useEffect(() => {
    setAllCityListData(selector?.tution?.getAllCityList?.data)
  }, [selector])

  const handleAction = id => {
    setAction(action === id ? null : id)
  }

  const handleBlockParent = data => {
    setBlockPouUp(true)

    let blockedData = {
      status: 'BLOCK',
      id: data._id
    }
    setNameBlock(data.name)
    setNewBlockedData(blockedData)
  }

  const handleSubmit = () => {
    let resData = {
      status: 'INACTIVE',
      userType: 'PARENT',
      keyWord: searchText,
      cityId: headerCity !== '' ? [headerCity] : [],
      fromDate: '',
      toDate: '',
      sortOrder: 'asc',
      sortBy: 'createdAt',
      pageNo: currentPage,
      size: pageSize,
      pinCode: pinCode
    }
    let data = {
      ...blockedData,
      reason: name
    }

    dispatch(parentAction.blockByAdmin(data, resData))
    setBlockPouUp(false)
    setAction(null)
    setName('')
  }

  const handleActiveParent = data => {
    let resData = {
      status: 'INACTIVE',
      userType: 'PARENT',
      keyWord: searchText,
      cityId: headerCity !== '' ? [headerCity] : [],
      fromDate: '',
      toDate: '',
      sortOrder: 'asc',
      sortBy: 'createdAt',
      pageNo: currentPage,
      size: pageSize,
      pinCode: pinCode
    }
    let activeId = {
      id: data._id,
      status: 'ACTIVE'
    }
    dispatch(parentAction.blockByAdmin(activeId, resData))
  }

  const fetchStudents = data => {
    let students = []
    parentAction
      .getStudentListByParentIdPromise(data)
      .then(resData => {
        students = resData.data ?? []
      })
      .catch(err => { })
    parentAction
      .getStudentListByParentIdForDemoPromise(data)
      .then(resData => {
        const list = (resData?.data || []).map(item => ({
          subjectDetails: [],
          studentId: item?._id ?? '',
          studentDetails: {
            _id: item?._id ?? '',
            studentFullName: item?.studentFullName ?? '',
            dob: item?.dob ?? '',
            gender: item?.gender ?? '',
            schoolName: item?.schoolName ?? '',
            medium: item?.medium ?? ''
          },
          classDetails: {
            _id: item?.grade?._id ?? '',
            name: item?.grade?.name ?? ''
          },
          boardDetails: item?.educationBoard ?? {}
        }))
        const mergedArray = mergeAndAddStatus(students, list)
        setStudentListData(() => mergedArray)
      })
      .catch(err => { })
    setViewStudentModal(true)
  }

  const handleStudent = async (data) => {
    let studentListDetails = {
      parentId: data._id
    }
    // parentAction.getStudentListByParentIdPromise(studentListDetails).then((resData) => {
    //   setStudentListData(resData.data)
    //   setViewStudentModal(true);
    // }).catch((err) => {
    // });
    setAddEditData((prev) => ({
      ...prev,
      parentId: data._id
    }))
    fetchStudents(studentListDetails)
  }

  const handleFullDetails = (data) => {
    userActions.getUserByPromiseId({ id: data._id }).then(res => {
      setFullDetails(true)
      setFullDetailsData(res?.data)
    })
  }

  const handleParentUpdate = (data) => {
    try {
      setParentUpdateModal(true);
      setInitialParentData({ ...data, city: data?.city?.id });
    } catch (error) {
      console.error("Error in handleParentUpdate:", error);
    }
  };

  const [allCityListData, setAllCityListData] = useState()

  const handleViewHistory = (data) => {
    navigate(`/app/parentviewhistory/${data?._id}`)
  }

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [image, setImage] = useState('')
  const handleCloseFunctionImageModel = data => {
    if (data) {
      setImage(() => data)
    } else {
      setImage('')
    }
    setIsModalVisible(!isModalVisible)
  }

  const handleResetFilters = () => {
    handleReset()
    setPinCode('')
  }

  const handleAddEditDataChange = e => {
    const { name, value } = e.target
    setAddEditData(prev => ({
      ...prev,
      [name]: value
    }))
  }

  const handleCloseAddEditModal = () => {
    fetchStudents({ parentId: addEditData.parentId })
    setAddEditData(prev => ({
      ...prev, // Spread the previous state
      ...studentInitialState
    }))
    setIsAddEditModel(false)
    // setViewStudentModal(true);
  }

  const handleOpenAddEditModal = data => {
    if (Object.keys(data).length) {
      const values = {
        studentFullName: data?.studentDetails?.studentFullName,
        dob: data?.studentDetails?.dob,
        grade: {
          label: data?.classDetails?.name,
          value: data?.classDetails?._id
        },
        schoolName: data?.studentDetails?.schoolName,
        educationBoard: {
          label: data?.boardDetails?.name,
          value: data?.boardDetails?._id
        },
        medium: data?.studentDetails?.medium,
        gender: data?.studentDetails?.gender,
        studentId: data.studentId
      }
      setAddEditData(prev => ({
        ...prev,
        ...values
      }))
      setIsAddEditModel(true)
    } else {
      setIsAddEditModel(true)
    }
    setViewStudentModal(false)
  }

  const handleSubmitAddEditModal = () => {
    let studentData = {
      ...addEditData,
      educationBoard: addEditData.educationBoard.value,
      grade: addEditData.grade.value
    }

    parentAction
      .addEditStudentByAdminPromise(studentData)
      .then(res => {
        fetchStudents({ parentId: addEditData.parentId })

        setAddEditData(prev => ({
          ...prev, // Spread the previous state
          ...studentInitialState // Reset to initial state
        }))

        // Close the modal
        setIsAddEditModel(false)
        dispatch(alertActions.success(res.data))
      })
      .catch(err => {
        dispatch(
          alertActions.error(
            err === 'Server error.'
              ? 'Please try again later.'
              : 'Student name already exist.'
          )
        ) // Show error message to the user
        fetchStudents({ parentId: addEditData.parentId })

        setAddEditData(prev => ({
          ...prev, // Spread the previous state
          ...studentInitialState // Reset to initial state
        }))

        // Close the modal
        setIsAddEditModel(false)
      })
  }

  return (
    <section>
      <Loader loading={selector?.Parent?.loading} />

      <div className='lg:flex justify-end lg:-mt-9 mt-6'>
        <div className='filter flex items-center space-x-4'>
          <select
            onChange={e => setPinCode(e.target.value)}
            className='block lg:w-40 w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 '
            value={pinCode}
          >
            <option value=''>PinCode</option>
            {headerCity &&
              selector?.Citys?.cityGetList
                .filter(city => city._id === headerCity)?.[0]
                ?.pinCode?.map(pin => (
                  <option value={pin} key={pin}>
                    {pin}
                  </option>
                ))}
          </select>
          <button
            className='text-center font-bold'
            onClick={() => handleResetFilters()}
          >
            <FaFilterCircleXmark
              size={24}
              className=' mx-auto text-[#023565] hover:text-[#E4006F] cursor-pointer'
            />
          </button>
        </div>
      </div>
      <section className='lg:block hidden'>
        <div className='mt-5 border border-[#D3D3D3] rounded-xl bg-white'>
          <table className='min-w-full table-autorounded-xl overflow-x-auto'>
            <thead className='border-b border-[#D1D1D1]'>
              <tr className='text-[#313131]'>
                <th className='px-6 py-3 whitespace-nowrap text-left text-sm text-black'>
                  Parent Name
                </th>
                <th className='px-6 py-3 whitespace-nowrap text-sm text-black'>
                  Student Name
                </th>
                <th className='px-6 py-3 whitespace-nowrap text-sm text-black'>
                  City
                </th>
                <th className='px-6 py-3 whitespace-nowrap text-sm text-black'>
                  Signup Date
                </th>
                <th className='px-6 py-3 whitespace-nowrap text-sm text-black'>
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {inactiveParentUserList && inactiveParentUserList.length > 0 ? (
                inactiveParentUserList?.map((item, id) => (
                  <tr key={id} className='border-b border-[#D1D1D1] capitalize'>
                    <td className='px-4 py-4  text-sm text-gray-900'>
                      <div className='flex items-center  gap-2'>
                        <span
                          onClick={
                            item?.image
                              ? () => handleCloseFunctionImageModel(item?.image)
                              : null
                          }
                        >
                          {item?.image && item?.image ? (
                            <img
                              className='rounded-full my-3 max-w-[40px] max-h-[40px] min-w-[40px] min-h-[40px]'
                              src={item?.image}
                              alt='User'
                            />
                          ) : (
                            <div className='bg-gray-300 rounded-full h-14 w-14 flex justify-center items-center'>
                              <span className='text-base font-semibold text-[#033565]'>
                                {' '}
                                {item?.name
                                  ? item.name
                                    .split(' ')
                                    .map(name => name.charAt(0))
                                    .join('')
                                  : ''}
                              </span>
                            </div>
                          )}
                        </span>
                        <div className='w-24'>
                          <p className='font-semibold whitespace-nowrap text-[#023565]'>
                            {item?.name ?? 'N/A'}
                          </p>
                          <p className='text-gray-500 whitespace-nowrap'>
                            {item?.mobNo ?? 'N/A'}
                          </p>
                          <div
                            className='flex items-center gap-1 cursor-pointer pt-1'
                            onClick={() => handleFullDetails(item)}
                          >
                            <p className='text-base font-normal cursor-pointer'>
                              More
                            </p>
                            <FaChevronCircleRight className='text-[#023565] text-base cursor-pointer' />
                          </div>
                        </div>
                      </div>
                    </td>

                    <td className='px-4 py-4 text-sm text-gray-900 text-center'>
                      <span
                        className='hover:text-[#CD0C5C] text-center flex items-center justify-center'
                        onClick={() => handleStudent(item)}
                      >
                        {' '}
                        <FaEye size={24} />
                      </span>
                    </td>

                    <td className='px-4 py-4 text-sm text-gray-900 text-center capitalize'>
                      {item?.city?.name ?? 'No city'}
                    </td>

                    <td className='px-4 py-4 text-sm text-gray-900 text-center capitalize'>
                      {' '}
                      {item?.createdAt
                        ? moment(item.createdAt).format('DD-MMM-YYYY')
                        : 'No Date'}
                    </td>

                    <td className='px-4 py-4 text-gray-900 relative'>
                      <span className='flex justify-center items-center'>
                        <BsThreeDotsVertical
                          onClick={() => handleAction(item._id)}
                          className='text-2xl cursor-pointer text-red-500'
                        />
                      </span>
                      {action === item._id && (
                        <div
                          className='absolute right-28 w-48 text-start rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-40'
                        >
                          <div className='py-1 font-semibold text-[#023565]'>
                            <div
                              className="px-4 py-2 flex justify-between place-items-center hover:text-[#E4006F] text-sm hover:bg-gray-100"
                              onClick={() => handleParentUpdate(item)}
                            >
                              View & Update Info
                              <FaAngleRight className="hover:text-[#C60E6B]" />
                            </div>
                            <div
                              className='px-4 py-2 flex justify-between place-items-center hover:text-[#E4006F] text-sm text-[#023565] hover:bg-gray-100'
                              onClick={() => handleBlockParent(item)}
                            >
                              Block Parent
                              <FaAngleRight className='hover:text-[#C60E6B]' />
                            </div>
                            <div
                              className='px-4 py-2 flex justify-between place-items-center hover:text-[#E4006F] text-sm text-[#023565] hover:bg-gray-100 cursor-pointer'
                              onClick={() => handleViewHistory(item)}
                            >
                              View History
                              <FaAngleRight className='hover:text-[#C60E6B]' />
                            </div>
                          </div>
                        </div>
                      )}
                    </td>

                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={5}
                    className='px-6 py-4 font-[400] text-base text-[#313131] whitespace-nowrap text-center'
                  >
                    Data Not Available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </section>

      <section className='space-y-5 py-4 lg:hidden block'>
        {inactiveParentUserList && inactiveParentUserList.length > 0 ? (
          inactiveParentUserList?.map((item, id) => (
            <div className=' bg-white p-4 rounded-[10px] border border-[#023565]'>
              <>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[14px] font-[600] text-left'>
                      Parent Name
                    </p>
                  </div>
                  <div className='w-full flex items-start'>
                    <div className='flex justify-start  gap-2 w-[95%]'>
                      <div
                        onClick={
                          item?.image
                            ? () => handleCloseFunctionImageModel(item?.image)
                            : null
                        }
                      >
                        {item && item?.image ? (
                          <img
                            alt=''
                            src={item && item?.image ? item?.image : ''}
                            className='rounded  max-w-[50px] max-h-[50px] min-w-[50px] min-h-[50px]'
                          />
                        ) : (
                          <div className='bg-gray-300 rounded h-14 w-14 flex justify-center items-center'>
                            <span className='text-base font-semibold text-[#033565] capitalize'>
                              {' '}
                              {item?.name
                                ? item.name
                                  .split(' ')
                                  .map(name => name.charAt(0))
                                  .join('')
                                : ''}
                            </span>
                          </div>
                        )}
                      </div>
                      <div className=''>
                        <p className='text-[12px] font-semibold whitespace-nowrap text-[#023565]'>
                          {/* {item?.name ?? 'Not Available'} */}
                          {truncateText(item?.name, 10) ?? 'N/A'}
                        </p>
                        <p className='text-[12px] font-medium text-gray-500 whitespace-nowrap'>
                          {item?.mobNo ?? 'Not Available'}
                        </p>
                        <div className='flex items-center  gap-3 cursor-pointer'>
                          <div
                            className='flex items-center  gap-1'
                            onClick={() => handleFullDetails(item)}
                          >
                            <p className='text-[12px] font-medium cursor-pointer'>
                              More
                            </p>
                            <FaChevronCircleRight className='text-[#023565] text-base cursor-pointer' />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='relative w-[5%]'>
                      <BsThreeDotsVertical
                        size={24}
                        className='text-[#C60E6B] cursor-pointer relative'
                        onClick={() => handleAction(item._id)}
                      />{' '}
                      {action === item._id && (
                        <div
                          className='absolute right-2  w-48 text-start rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-40'
                          ref={mobileRef}
                        >
                          <div className='py-1 font-semibold'>
                            <button
                              className='block px-4 py-2 text-sm text-pink-600 hover:bg-gray-100'
                              onClick={() => handleParentUpdate(item)}
                            >
                              View & Update Info
                            </button>
                            <button
                              className='block px-4 py-2  text-sm text-[#023565] hover:bg-gray-100'
                              onClick={() => handleBlockParent(item)}
                            >
                              Block Parent
                            </button>
                            <button
                              className='block px-4 py-2  text-sm text-[#023565] hover:bg-gray-100'
                              onClick={() => handleViewHistory(item)}
                            >
                              View History
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>
                      Student Name
                    </p>
                  </div>
                  <div className='w-full'>
                    <p
                      className='text-[14px] font-[700] text-[#023565]'
                      onClick={() => handleStudent(item)}
                    >
                      View
                    </p>
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>City</p>
                  </div>
                  <div className='w-full'>
                    <p className='text-[14px] font-[400] capitalize'>
                      {item?.city?.name ?? 'No City'}
                    </p>
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>
                      Signup Date
                    </p>
                  </div>
                  <div className='w-full'>
                    <p className='text-[14px] font-[400]'>
                      {item?.createdAt
                        ? moment(item.createdAt).format('DD-MMM-YYYY')
                        : 'No Date'}
                    </p>
                  </div>
                </div>
              </>
            </div>
          ))
        ) : (
          <p className='text-center'>Data Not Found</p>
        )}
      </section>
      {totalPages > 1 && (
        <div className='flex justify-end items-center py-2'>
          <button
            className={`px-4 py-2 border text-white bg-[#023565] rounded ${currentPage === 1 ? 'opacity-50 cursor-not-allowed' : ''
              }`}
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
          >
            <FaChevronLeft />
          </button>
          <span className='text-gray-700 mx-1'>
            <span className='border px-4 py-2 text-black  rounded'>
              {currentPage}
            </span>{' '}
            of{' '}
            <span className='border px-4 py-2 text-black  rounded'>
              {totalPages}
            </span>
          </span>
          <button
            className={`px-4 py-2 border text-white bg-[#023565] rounded ${currentPage === totalPages ? 'opacity-50 cursor-not-allowed' : ''
              }`}
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            <FaChevronRight />
          </button>
        </div>
      )}

      <BlockPopUp
        blockedPoup={blockedPoup}
        setBlockpouUp={setBlockPouUp}
        name={name}
        setName={setName}
        handleSubmit={handleSubmit}
        nameBlock={nameBlock}
      />

      <ViewUpdate
        fullDetails={fullDetails}
        setFullDetails={setFullDetails}
        DetailsData={DetailsData}
      />

      <StudentDetail
        setViewStudentModal={setViewStudentModal}
        viewStudentModal={viewStudentModal}
        setStudentListData={setStudentListData}
        studentListdata={studentListdata}
        isAction={true}
        openModal={handleOpenAddEditModal}
      />

      <UpdateParent
        setParentUpdateModal={setParentUpdateModal}
        parentUpdateModal={parentUpdateModal}
        initialParentData={initialParentData}
        currentPage={currentPage}
        pageSize={pageSize}
        status={'INACTIVE'}
      />
      <ImagePreviewModal
        handleClose={handleCloseFunctionImageModel}
        showWarning={isModalVisible}
        image={image}
      />

      <AddEditStudentModel
        isView={isOpenAddEditModel}
        data={addEditData}
        handleChange={handleAddEditDataChange}
        handleCloseModal={handleCloseAddEditModal}
        handleSubmitModal={handleSubmitAddEditModal}
      />
    </section>
  )
}

export default InActiveParent
