/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { alertActions, walletAction } from '../../_actions'
import { MdOutlineClose } from 'react-icons/md'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'

import moment from 'moment/moment'
import './Wallet.css'
import Loader from '../../components/Loader'
import { FaFilterCircleXmark } from 'react-icons/fa6'
import Select from 'react-select'
import { truncateText } from '../../_helpers'

const TutorData = ({
  searchText,
  headerCity,
  tutorOptions,
  customStyles,
  handleReset
}) => {
  const dispatch = useDispatch()
  const selector = useSelector(state => state)
  const [walletData, setWalletData] = useState()

  let getWalletHistory = selector?.Wallet?.walletHistoryList?.data?.list

  const [addBonusModal, setAddBonusModal] = useState(false)
  const [historyModal, setHistoryModal] = useState(false)
  const [userId, setUserId] = useState('')
  const [bonus, setBonus] = useState()
  const [updateModal, setUpdateModal] = useState(false)
  const [updateVal, setUpdateVal] = useState('')
  const [totalPages, setTotalPages] = useState()
  const [currentPage, setCurrentPage] = useState(1)
  const pageSize = 10
  const [tutorId, setTutorId] = useState({ label: 'Select Tutor', value: '' })
  const [range, setRange] = useState('')

  useEffect(() => {
    let walletDetails = {
      userType: 'TUTOR',
      keyWord: searchText,
      cityId: headerCity !== '' ? [headerCity] : null,
      userId: tutorId.value !== '' ? [tutorId.value] : null,
      fromDate: '',
      toDate: '',
      sortOrder: '',
      sortBy: '',
      pageNo: currentPage,
      size: pageSize,
      range: range
    }
    dispatch(walletAction.getWalletList(walletDetails))
  }, [currentPage, searchText, headerCity, tutorId, range])

  useEffect(() => {
    setWalletData(() => selector?.Wallet?.walletList?.data?.list)
    if (selector?.Wallet?.walletList?.data?.total) {
      setTotalPages(
        Math.ceil(selector?.Wallet?.walletList?.data?.total / pageSize)
      )
    } else {
      setTotalPages(1)
    }
  }, [selector])

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1)
    let walletDetails = {
      userType: 'TUTOR',
      keyWord: searchText,
      cityId: headerCity !== '' ? [headerCity] : null,
      userId: tutorId.value !== '' ? [tutorId.value] : null,
      fromDate: '',
      toDate: '',
      sortOrder: '',
      sortBy: '',
      pageNo: currentPage,
      size: pageSize
    }
    dispatch(walletAction.getWalletList(walletDetails))
  }

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1)
    let walletDetails = {
      userType: 'TUTOR',
      keyWord: searchText,
      cityId: headerCity !== '' ? [headerCity] : null,
      userId: tutorId.value !== '' ? [tutorId.value] : null,
      fromDate: '',
      toDate: '',
      sortOrder: '',
      sortBy: '',
      pageNo: currentPage,
      size: pageSize
    }
    dispatch(walletAction.getWalletList(walletDetails))
  }

  const handleAddBonus = data => {
    setAddBonusModal(true)
    setUserId(data?.userId?._id)
    setBonus(data?.bonus)
  }

  const handleBonusUpdateForm = () => {
    let updateBonus = {
      userId: userId,
      bonus: parseInt(bonus)
    }
    let walletDetails = {
      userType: 'TUTOR',
      keyWord: searchText,
      cityId: headerCity !== '' ? [headerCity] : null,
      userId: tutorId.value !== '' ? [tutorId.value] : null,
      fromDate: '',
      toDate: '',
      sortOrder: '',
      sortBy: '',
      pageNo: 1,
      size: 10
    }

    dispatch(walletAction.addBonusUserWallet(updateBonus, walletDetails))
    setBonus('')
    setBonus('')
    setAddBonusModal(false)
  }

  const handleHistory = data => {
    let viewHistoryData = {
      userId: data?.userId?._id,
      keyWord: '',
      fromDate: '',
      toDate: '',
      sortOrder: '',
      sortBy: '',
      pageNo: 1,
      size: 10
    }
    setHistoryModal(true)
    dispatch(walletAction.getWalletHistoryList(viewHistoryData))
  }

  const handleUpdateWallet = data => {
    setUpdateModal(true)
    setUpdateVal(data)
  }

  const handleOnChange = e => {
    const { id, value } = e.target
    setUpdateVal(prevState => ({
      ...prevState,
      [id]: value
    }))
  }

  const handleUpdateForm = () => {
    let walletDetails = {
      userType: 'TUTOR',
      keyWord: searchText,
      cityId: headerCity !== '' ? [headerCity] : null,
      userId: tutorId.value !== '' ? [tutorId.value] : null,
      fromDate: '',
      toDate: '',
      sortOrder: '',
      sortBy: '',
      pageNo: 1,
      size: 10
    }

    let updatedDetails = {
      id: updateVal._id,
      bonus: updateVal.bonus
    }
    dispatch(walletAction.updateWallet(updatedDetails, walletDetails))
    setUpdateModal(false)
    dispatch(alertActions.success('Bonus Successfully Updated'))
  }

  const [updateBonusModal, setUpdateBonusModal] = useState(false)

  const handleUpdateRewardModal = data => {
    setUpdateBonusModal(true)
    setUpdateVal(data)
  }

  const handleUpdateRewards = e => {
    e.preventDefault()
    let walletDetails = {
      userType: 'TUTOR',
      keyWord: searchText,
      cityId: headerCity !== '' ? [headerCity] : null,
      userId: tutorId.value !== '' ? [tutorId.value] : null,
      fromDate: '',
      toDate: '',
      sortOrder: '',
      sortBy: '',
      pageNo: 1,
      size: 10
    }
    let updateReward = {
      id: updateVal._id,
      rewards: updateVal.rewards
    }

    dispatch(walletAction.updateWalletReward(updateReward, walletDetails))
    setUpdateBonusModal(false)
    dispatch(alertActions.success('Rewards Successfully Updated'))
  }

  const handleResetFilters = () => {
    handleReset()
    setTutorId({ label: 'Select Tutor', value: '' })
  }

  return (
    <>
      <Loader loading={selector?.Wallet?.loading} />
      <div className='flex md:justify-end justify-center items-center gap-5 lg:mx-9 my-4 lg:-mt-9'>
        <div className='lg:w-fit w-full'>
          <select
            className='py-2 px-2 bg-white border rounded-md shadow-sm border-gray-200 outline-none'
            onChange={e => setRange(e.target.value)}
          >
            <option value=''>Please Select</option>
            <option value='1-500'>1-500</option>
            <option value='501-1000'>501-1000</option>
            <option value='1001-2000'>1001-2000</option>
            <option value='2000+'>ABOVE 2000</option>
          </select>
        </div>
        <div className='lg:w-fit w-full'>
          <Select
            id='tutor'
            name='tutor'
            value={tutorId}
            options={[{ label: 'Select Tutor', value: '' }, ...tutorOptions]}
            className='capitalize text-black lg:w-40 w-full'
            styles={customStyles}
            classNamePrefix='react-select'
            placeholder='Tutor Name'
            onChange={(selectedOption, actionMeta) => {
              setTutorId(selectedOption)
            }}
          />
        </div>
        <div>
          <button className='text-center font-bold'>
            <FaFilterCircleXmark
              size={24}
              className=' mx-auto text-[#023565] hover:text-[#E4006F] cursor-pointer'
              onClick={() => {
                handleResetFilters()
              }}
            />
          </button>
        </div>
      </div>
      <section className='lg:block hidden'>
        <div className=' bg-white border border-[#D3D3D3] rounded-xl  overflow-x-auto '>
          <table className='min-w-full table-auto capitalize'>
            <thead className='table-auto'>
              <tr className='border-b capitalize'>
                <th className='px-6 py-3 whitespace-nowrap text-sm text-black'>
                  Tutor Name
                </th>
                <th className='px-6 py-3 whitespace-nowrap text-sm text-black'>
                  City
                </th>
                <th className='px-6 py-3 whitespace-nowrap text-sm text-black'>
                  Rewards Earned <br />
                  By Referral
                </th>
                <th className='px-6 py-3 whitespace-nowrap text-sm text-black'>
                  Rewards Added <br />
                  By Company
                </th>
                <th className='px-6 py-3 whitespace-nowrap text-sm text-black'>
                  Total Rewards
                </th>
                <th className='px-6 py-3 whitespace-nowrap text-sm text-black'>
                  user Balance
                </th>
                <th className='px-6 py-3 whitespace-nowrap text-sm text-black'>
                  Reward History
                </th>
                <th className='px-6 py-3 whitespace-nowrap text-sm text-black'>
                  Update
                </th>
              </tr>
            </thead>
            <tbody className=' divide-y divide-gray-200'>
              {walletData && walletData.length > 0 ? (
                walletData?.map((item, id) => (
                  <tr className='text-[#313131]' key={id}>
                    <td className='px-6 py-1 flex items-center gap-4 text-sm text-gray-900'>
                      <div className='flex items-center justify-center gap-2'>
                        <span>
                          {item?.userId && item?.userId?.image ? (
                            <img
                              alt=''
                              src={
                                item?.userId && item?.userId?.image
                                  ? item?.userId?.image
                                  : 'https://thumbs.dreamstime.com/z/vector-illustration-avatar-dummy-logo-collection-image-icon-stock-isolated-object-set-symbol-web-137160339.jpg?ct=jpeg'
                              }
                              onError={e => {
                                e.target.src =
                                  'https://thumbs.dreamstime.com/z/vector-illustration-avatar-dummy-logo-collection-image-icon-stock-isolated-object-set-symbol-web-137160339.jpg?ct=jpeg'
                              }}
                              className='rounded-full my-3 max-w-[50px] max-h-[50px] min-w-[50px] min-h-[50px]'
                            />
                          ) : (
                            <div className='bg-gray-300 rounded-full h-14 w-14 flex justify-center items-center'>
                              <span className='text-base font-semibold text-[#033565]'>
                                {' '}
                                {item?.userId?.name
                                  ? item.userId.name
                                      .split(' ')
                                      .map(name => name.charAt(0))
                                      .join('')
                                  : ''}
                              </span>
                            </div>
                          )}
                        </span>
                        <div className='w-24'>
                          <p className='font-semibold whitespace-nowrap text-[#023565]'>
                            {item && item?.userId?.name
                              ? item?.userId?.name
                              : 'No Data'}
                          </p>
                          <p className='text-gray-500 whitespace-nowrap'>
                            {item && item?.userId?.mobNo
                              ? item?.userId?.mobNo
                              : null}
                          </p>
                          <p className='text-gray-500 whitespace-nowrap'>
                            <small>
                              {item && item.statusDate ? item.statusDate : null}
                            </small>
                          </p>
                        </div>
                      </div>
                    </td>
                    <td className='px-6 py-1 whitespace-nowrap text-sm text-gray-900 text-center'>
                      {item?.city?.name}
                    </td>
                    <td className='px-6 py-1 whitespace-nowrap text-sm text-gray-900 text-center'>
                      {item?.rewards}
                    </td>
                    <td className='px-6 py-1 whitespace-nowrap text-sm text-gray-900 text-center'>
                      <p>{item?.bonus}</p>
                      <button
                        className='font-bold text-sm px-3 mt-0.5 text-[#023565] hover:text-[#023565]'
                        onClick={() => handleAddBonus(item)}
                      >
                        {' '}
                        Add Bonus
                      </button>
                    </td>
                    <td className='px-6 py-1 whitespace-nowrap text-sm text-gray-900 text-center'>
                      {item?.totalBalance}
                    </td>
                    <td className='px-6 py-1 whitespace-nowrap text-sm text-gray-900 text-center'>
                      {item?.balance ? item?.balance : '-'}
                    </td>
                    <td className='px-6 py-1 text-sm text-center text-[#313131]'>
                      <div className='flex justify-center items-center text-[#023565]'>
                        <button
                          className='font-bold'
                          onClick={() => handleHistory(item)}
                        >
                          {' '}
                          View
                        </button>
                      </div>
                    </td>
                    <td className='px-6 py-1 space-x-3 whitespace-nowrap text-center text-[#313131] forfontsmall '>
                      <button
                        className='text-[#023565] border border-[#023565] font-[700] rounded-lg text-sm px-3 py-2 hover:text-white hover:bg-[#023565] focus:ring-4 focus:ring-blue-300 '
                        onClick={() => handleUpdateRewardModal(item)}
                      >
                        Update Reward
                      </button>
                      <button
                        className='text-[#023565] border border-[#023565] font-[700] rounded-lg text-sm px-3 py-2 hover:text-white hover:bg-[#023565] focus:ring-4 focus:ring-blue-300 '
                        onClick={() => handleUpdateWallet(item)}
                      >
                        Update Bonus
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan='7'
                    className='px-6 py-4 text-center text-[#313131]'
                  >
                    Data Not Available
                  </td>
                </tr>
              )}
              <hr />
            </tbody>
          </table>
        </div>
      </section>
      {addBonusModal && (
        <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40'>
          <div className='relative w-11/12 mx-auto lg:w-[600px]'>
            <button
              className='z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center'
              onClick={() => setAddBonusModal(false)}
            >
              <span>
                <MdOutlineClose />
              </span>{' '}
              Close
            </button>
            <div className='form_class border-0 rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
              <div className='flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t'>
                <h2 className='text-lg font-semibold'>Add Bonus </h2>
              </div>
              <div className='relative overflow-y-auto'>
                <form className='space-y-2 p-4'>
                  <div className='board_name'>
                    <label
                      htmlFor='boardName'
                      className='block text-[#023565] table_head '
                    >
                      Add Bonus:
                    </label>
                    <input
                      type='text'
                      id='boardName'
                      value={bonus}
                      onChange={e => setBonus(e.target.value)}
                      className='mt-1 p-2 border block w-full border-gray-300 rounded-md'
                    />
                  </div>
                  <div className='flex justify-center items-center pt-2'>
                    <button
                      onClick={handleBonusUpdateForm}
                      className='w-60 mx-auto bg-[#023565] text-white font-semibold py-2 px-4 rounded-full '
                    >
                      Add
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}

      {updateModal && (
        <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40'>
          <div className='relative w-11/12 mx-auto lg:w-[600px]'>
            <button
              className='z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center'
              onClick={() => setUpdateModal(false)}
            >
              <span>
                <MdOutlineClose />
              </span>{' '}
              Close
            </button>
            <div className='form_class border-0 rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
              <div className='flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t'>
                <h2 className='text-lg font-semibold'>Update </h2>
              </div>
              <div className='relative overflow-y-auto'>
                <form className='space-y-2 p-4'>
                  <div className='board_name'>
                    <label
                      htmlFor='bonus'
                      className='block text-sm font-medium text-gray-700'
                    >
                      Bonus:
                    </label>
                    <input
                      type='text'
                      id='bonus'
                      value={updateVal?.bonus}
                      onChange={handleOnChange}
                      className='mt-1 p-2 border block w-full border-gray-300 rounded-md'
                    />
                  </div>

                  <div className='flex justify-center items-center pt-2'>
                    <button
                      onClick={handleUpdateForm}
                      className='w-60 mx-auto bg-[#023565] text-white font-semibold py-2 px-4 rounded-full '
                    >
                      Update
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}

      {updateBonusModal && (
        <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40'>
          <div className='relative w-11/12 mx-auto lg:w-[600px]'>
            <button
              className='z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center'
              onClick={() => setUpdateBonusModal(false)}
            >
              <span>
                <MdOutlineClose />
              </span>{' '}
              Close
            </button>
            <div className='form_class border-0 rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
              <div className='flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t'>
                <h2 className='text-lg font-semibold'>Update </h2>
              </div>
              <div className='relative overflow-y-auto'>
                <form className='space-y-2 p-4'>
                  <div className='board_name'>
                    <label
                      htmlFor='rewards'
                      className='block text-sm font-medium text-gray-700'
                    >
                      Rewards:
                    </label>
                    <input
                      type='text'
                      id='rewards'
                      value={updateVal?.rewards}
                      onChange={handleOnChange}
                      className='mt-1 p-2 border block w-full border-gray-300 rounded-md'
                    />
                  </div>

                  <div className='flex justify-center items-center pt-2'>
                    <button
                      onClick={handleUpdateRewards}
                      className='w-60 mx-auto bg-[#023565] text-white font-semibold py-2 px-4 rounded-full '
                    >
                      Update
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}

      {historyModal && (
        <>
          <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40'>
            <div className='relative w-11/12 mx-auto lg:w-[840px]'>
              <button
                className='z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center'
                onClick={() => setHistoryModal(false)}
              >
                <span>
                  <MdOutlineClose />
                </span>{' '}
                Close
              </button>
              <div className='form_class border-0 rounded-[10px] shadow-lg  flex flex-col w-full bg-white outline-none focus:outline-none'>
                <div className='flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t'>
                  <h2 className='text-lg font-semibold'>Reward History</h2>
                </div>
                <div className='w-full lg:h-[500px] overflow-y-auto'>
                  <table className=' bg-white border lg:w-[820px] mx-auto m-2'>
                    <thead>
                      <tr className='border text-left'>
                        <th className='px-4 py-2 text-[#023565] table_head border whitespace-nowrap'>
                          Name
                        </th>
                        <th className='px-4 py-2 text-[#023565] table_head border whitespace-nowrap'>
                          Number
                        </th>
                        <th className='px-4 py-2 text-[#023565] table_head border whitespace-nowrap'>
                          Email
                        </th>

                        <th className='px-4 py-2 text-[#023565] table_head border whitespace-nowrap'>
                          Reward Points
                        </th>

                        <th className='px-4 py-2 text-[#023565] table_head border whitespace-nowrap'>
                          Date
                        </th>
                        <th className='px-4 py-2 text-[#023565] table_head border whitespace-nowrap'>
                          {' '}
                          Type
                        </th>
                        <th className='px-4 py-2 text-[#023565] table_head border whitespace-nowrap'>
                          {' '}
                          Status
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {getWalletHistory && getWalletHistory.length ? (
                        getWalletHistory?.map((item, id) => (
                          <tr key={id} className='border capitalize'>
                            <td className='px-4 py-5  forfontsmall border whitespace-nowrap'>
                              {item?.userId?.name}
                            </td>
                            <td className='px-4 py-2  forfontsmall border whitespace-nowrap'>
                              {item?.userId?.mobNo}
                            </td>
                            <td className='px-4 py-2  forfontsmall border whitespace-nowrap'>
                              {item?.userId?.email}
                            </td>

                            <td className='px-4 py-2  forfontsmall border whitespace-nowrap'>
                              {item?.balance}
                            </td>

                            <td className='px-4 py-2  forfontsmall border whitespace-nowrap'>
                              {moment(item?.createdAt).format('DD-MMM-YYYY')}
                            </td>
                            <td className='px-4 py-2  text-green-600 forfontsmall border whitespace-nowrap'>
                              {item?.type}
                            </td>
                            {item?.status === 1 ? (
                              <td className='px-4 py-2  text-green-600 forfontsmall border whitespace-nowrap'>
                                PAID
                              </td>
                            ) : item?.status === 2 ? (
                              <td className='px-4 py-2  text-blue-600 forfontsmall border whitespace-nowrap'>
                                PAID CUSTOM
                              </td>
                            ) : item?.status === 3 ? (
                              <td className='px-4 py-2  text-red-600 forfontsmall border whitespace-nowrap'>
                                REJECT
                              </td>
                            ) : (
                              <td className='px-4 py-2  text-yellow-600 forfontsmall border whitespace-nowrap'>
                                PENDING
                              </td>
                            )}
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td
                            colSpan='7'
                            className='px-6 py-4 text-center text-[#313131]'
                          >
                            Data Not Available
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <section className='space-y-5 lg:hidden block'>
        {walletData && walletData.length > 0 ? (
          walletData?.map((item, id) => (
            <div className=' bg-white p-4 rounded-[10px] border border-[#023565]'>
              <>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>
                      Tutor Name
                    </p>
                  </div>
                  <div className='w-full'>
                    <div className='flex items-center  gap-2 capitalize'>
                      {item?.userId && item?.userId?.image ? (
                        <img
                          alt=''
                          src={
                            item?.userId && item?.userId?.image
                              ? item?.userId?.image
                              : ''
                          }
                          className='rounded  max-w-[50px] max-h-[50px] min-w-[50px] min-h-[50px]'
                        />
                      ) : (
                        <div className='bg-gray-300 rounded h-14 w-14 flex justify-center items-center'>
                          <span className='text-base font-semibold text-[#033565]'>
                            {' '}
                            {item?.userId?.name
                              ? item.userId.name
                                  .split(' ')
                                  .map(name => name.charAt(0))
                                  .join('')
                              : ''}
                          </span>
                        </div>
                      )}
                      <div>
                        <p className='text-[12px] font-semibold whitespace-nowrap text-[#023565]'>
                          {/* {item?.userId?.name ?? 'Not Available'} */}
                          {truncateText(item?.userId?.name, 12) ?? 'N/A'}
                        </p>
                        <p className='text-[12px] font-medium text-gray-500 whitespace-nowrap'>
                          {item?.userId?.mobNo ?? 'Not Available'}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>City</p>
                  </div>
                  <div className='w-full'>
                    <p className='text-[14px] font-[400]'>
                      {' '}
                      {item?.city?.name}{' '}
                    </p>
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>
                      Rewards Earned By Referral
                    </p>
                  </div>
                  <div className='w-full'>
                    <p className='text-[14px] font-[400]'> {item?.rewards}</p>
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>
                      Rewards Added By Company
                    </p>
                  </div>
                  <div className='w-full'>
                    <p className='text-[14px] font-[400]'>{item?.bonus}</p>
                    <p className='text-[14px] font-[400]'>
                      {' '}
                      <button
                        className='font-bold underline text-[#023565]'
                        onClick={() => handleAddBonus(item)}
                      >
                        Add Bonus
                      </button>
                    </p>
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>
                      Total Rewards
                    </p>
                  </div>
                  <div className='w-full'>
                    <p className='text-[14px] font-[400]'>
                      {item?.totalBalance}
                    </p>
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>
                      User Balance
                    </p>
                  </div>
                  <div className='w-full'>
                    <p className='text-[14px] font-[400]'>
                      {item?.balance ? item?.balance : '-'}
                    </p>
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>
                      Reward History{' '}
                    </p>
                  </div>
                  <div className='w-full'>
                    <p className='text-[14px] font-[400] relative'>
                      <button
                        className='font-bold text-[#023565] '
                        onClick={() => handleHistory(item)}
                      >
                        View
                      </button>{' '}
                    </p>
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>Update</p>
                  </div>
                  <div className='w-full space-y-2'>
                    <button
                      className='text-[#023565] border border-[#023565] font-[700] rounded-lg text-sm px-3 py-2 hover:text-white hover:bg-[#023565] focus:ring-4 focus:ring-blue-300'
                      onClick={() => handleUpdateRewardModal(item)}
                    >
                      Update Reward
                    </button>{' '}
                    <button
                      className='text-[#023565] border border-[#023565] font-[700] rounded-lg text-sm px-4 py-2 hover:text-white hover:bg-[#023565] focus:ring-4 focus:ring-blue-300'
                      onClick={() => handleUpdateWallet(item)}
                    >
                      Update Bonus
                    </button>{' '}
                  </div>
                </div>
              </>
            </div>
          ))
        ) : (
           <div  className=' bg-white p-4 rounded-[10px]'>
            <p className='text-center'>Data Not Found</p>
          </div>
        )}
      </section>

      {totalPages > 1 && (
        <div className='flex justify-end items-center py-2 '>
          <button
            className={`px-4 py-2 border text-white bg-[#023565] rounded ${
              currentPage === 1 ? 'opacity-50 cursor-not-allowed' : ''
            }`}
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
          >
            <FaChevronLeft />
          </button>
          <span className='text-gray-700 mx-1'>
            <span className='border px-4 py-2 text-black  rounded'>
              {currentPage}
            </span>{' '}
            of{' '}
            <span className='border px-4 py-2 text-black  rounded'>
              {totalPages}
            </span>
          </span>
          <button
            className={`px-4 py-2 border text-white bg-[#023565] rounded ${
              currentPage === totalPages ? 'opacity-50 cursor-not-allowed' : ''
            }`}
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            <FaChevronRight />
          </button>
        </div>
      )}
    </>
  )
}

export default TutorData
