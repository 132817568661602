import { parentConstant } from "../_constants";

export default function Parent(state = {}, action) {
  switch (action.type) {
    case parentConstant.GET_USERLIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case parentConstant.GET_USERLIST_SUCCESS:
      return {
        ...state,
        loading: false,
        parentuserList: action?.users,
      };
    case parentConstant.GET_USERLIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case parentConstant.GET_STUDENT_LIST_BY_PARENTID_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case parentConstant.GET_STUDENT_LIST_BY_PARENTID_SUCCESS:
      return {
        ...state,
        loading: false,
        studentListByid: action?.users,
      };
    case parentConstant.GET_STUDENT_LIST_BY_PARENTID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case parentConstant.GET_STUDENT_LIST_BY_PARENT_ID_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case parentConstant.GET_STUDENT_LIST_BY_PARENT_ID_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        studentListByUser: action?.users,
      };
    case parentConstant.GET_STUDENT_LIST_BY_PARENT_ID_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case parentConstant.GET_ONGOING_TUTION_LIST_BYID_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case parentConstant.GET_ONGOING_TUTION_LIST_BYID_SUCCESS:
      return {
        ...state,
        loading: false,
        getOnGoingTutionList: action?.users,
      };
    case parentConstant.GET_ONGOING_TUTION_LIST_BYID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };


    case parentConstant.GET_FEE_HISTORY_BYUSERID_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case parentConstant.GET_FEE_HISTORY_BYUSERID_SUCCESS:
      return {
        ...state,
        loading: false,
        getFeeHistoryById: action?.users,
      };
    case parentConstant.GET_FEE_HISTORY_BYUSERID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case parentConstant.BLOCK_BY_ADMIN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case parentConstant.BLOCK_BY_ADMIN_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case parentConstant.BLOCK_BY_ADMIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };


    case parentConstant.UPDATE_PARENT_BYADMIN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case parentConstant.UPDATE_PARENT_BYADMIN_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case parentConstant.UPDATE_PARENT_BYADMIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };





    case parentConstant.GET_LOG_HISTORY_BYID_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case parentConstant.GET_LOG_HISTORY_BYID_SUCCESS:
      return {
        ...state,
        loading: false,
        getLogHistoryList: action?.users
      };
    case parentConstant.GET_LOG_HISTORY_BYID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };



    default:
      return state;
  }
}
