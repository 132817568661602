import React from 'react'

const ButtonComponent = ({name,onClick}) => {
  return (
    <div>
        <button className='px-4 font-semibold' onClick={onClick}>{name}</button>
    </div>
  )
}

export default ButtonComponent