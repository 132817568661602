/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable react-hooks/exhaustive-deps */
import { BsThreeDotsVertical } from 'react-icons/bs'
import { FaChevronLeft, FaEye } from 'react-icons/fa'
import { IoIosCloseCircle } from 'react-icons/io'
import {
  parentAction,
  tutionAction,
  userActions,
  userListAction
} from '../../../_actions'
import { connect } from 'react-redux'
import { FaAngleRight, FaChevronCircleRight } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { FaChevronRight } from 'react-icons/fa'
import Loader from '../../../components/Loader'

import { MdOutlineClose } from 'react-icons/md'

import './tution.css'
import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { timeStanderFollowFunction, truncateText } from '../../../_helpers'
import StudentDetail from './Models/StudentDetail'
import ImagePreviewModal from '../../Tutor-Profile/Model/ImagePreview'
import UpdateNoteModel from './Models/UpdateNoteModel'
import UpdateTuitionModel from './Models/UpdateTuitionModel'
import ViewParent from './Models/ViewParent'
import AssignTutorPopUp from './Models/AssignTutorPopUp'
import ViewMoreTuitionModel from './Models/ViewMoreTuitionModel'

const ActiveTable = props => {
  const dispatch = useDispatch()
  const selector = useSelector(state => state)
  const navigate = useNavigate()

  const mobileRef = useRef(null)
  const { newStateStatus, filters, headerCity, searchText } = props

  let demoRequestData = selector?.tution?.demoList?.data?.list
  // const suggestedTutor = selector?.tution?.suggestedTutorList?.data
  //   ? selector?.tution?.suggestedTutorList?.data
  //   : null;

  const [open, setOpen] = useState(null)
  const [activate, setActivate] = useState(false)
  const [viewandUpdate, setViewAndUpdate] = useState(false)
  const [assignTutorModal, setAssignTutorModal] = useState(false)
  const [assignTutorList, setAssignTutorList] = useState('')
  const [demoIdForAssign, setDemoIdForAssign] = useState({})
  const [assignTutorTab, setAssignTutorTab] = useState('Suggested Tutors')
  const [tutorDetails, setTutorDetails] = useState(false)
  const [fullData, setFullData] = useState('')

  const [suggestedTutor, setSuggestedTutor] = useState(null)
  const [allTutorDataList, setAllTutorDataList] = useState([])

  // let allTutorDataList =
  //   props && props?.getAllTutorList && props?.getAllTutorList?.data
  //     ? props?.getAllTutorList?.data
  //     : [];

  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const pageSize = 6
  const [rejectResionModal, setRejectResionModal] = useState(false)
  const [rejectResion, setRejectResion] = useState('')
  const [rejectData, setRejectData] = useState({})

  let suggestTutorBtn = ['Applicants', 'Suggested Tutors', 'All Tutors']

  const menuRef = useRef(null)

  const handleClickOutside = event => {
    if (
      menuRef.current &&
      !menuRef.current.contains(event.target) &&
      mobileRef.current &&
      !mobileRef.current.contains(event.target)
    ) {
      setOpen(null)
    }
  }
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])


  // Similar to getDerivedStateFromProps
  useEffect(() => {
    if (props?.isStateMangerRes) {
      setAssignTutorModal(false)
      setOpen(null)
    }
  }, [props])

  useEffect(() => {
    let demoData = {
      status: [1],
      // leadCreate: 'CREATED_SELF',
      keyWord: searchText,
      cityId: headerCity ? [headerCity] : '',
      fromDate: '',
      toDate: '',
      sortOrder: '',
      sortBy: '',
      pageNo: currentPage,
      size: pageSize,
      ...filters,
      tutorId: filters.tutorId !== '' ? [filters.tutorId] : [],
      parentId: filters.parentId !== '' ? [filters.parentId] : []
    }
    dispatch(tutionAction.getDemoClassListStatus(demoData))
    newStateStatus('ACTIVE')
  }, [currentPage, filters, searchText, headerCity])

  useEffect(() => {
    if (selector?.tution?.demoList?.data?.total) {
      setTotalPages(
        Math.ceil(selector?.tution?.demoList?.data?.total / pageSize)
      )
    }

    // setdemoRequestData(() => selector?.tution?.demoList?.data?.list)
    setFullData(() => selector?.userList?.getTutorUpdateById?.data)
    setSuggestedTutor(selector?.tution?.suggestedTutorList?.data || [])
    setAllTutorDataList(selector?.tution?.getAllTutorList?.data || [])
  }, [selector])

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1)
  }

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1)
  }

  const openAction = itemId => {
    setOpen(prevItemId => (prevItemId === itemId ? null : itemId))
  }

  const closeActive = () => {
    setActivate(false)
    setViewAndUpdate(false)
  }

  const assignTutor = data => {
    let query = {
      teachingMode: data?.preferredTeachMode
    }
    if (data?.preferredTeachMode === 'HOME_TUITION') {
      query['city'] = data?.cityId?._id
    }
    dispatch(tutionAction.suggestedTutorListForAdmin({ demoId: data?._id }))
    dispatch(tutionAction.getAllTutorList(query))

    setAssignTutorModal(true)
    setAssignTutorList(data)
    setDemoIdForAssign(data._id)
  }

  const handleAssignTutor = item => {
    setAssignTutorTab(item)
  }

  const handleRejectMsg = data => {
    setRejectResionModal(true)

    let acceptedData = {
      status: 'REJECTED',
      demoId: data._id
    }
    setRejectData(acceptedData)

    // let acceptedData = {
    //   status: "REJECTED",
    //   demoId: data._id,
    // };

    // dispatch(
    //   tutionAction.updateStatusDemoApproveOrReject(acceptedData, demoData)
    // );
  }
  const viewHistory = data => {
    navigate(`/app/viewhistory/${data?._id}`)
  }
  const handleAssign = data => {
    let Demodata = {
      status: [1],
      keyWord: '',
      fromDate: '',
      toDate: '',
      sortOrder: '',
      sortBy: '',
      pageNo: 1,
      size: 10,
      ...filters,
      tutorId: filters.tutorId !== '' ? [filters.tutorId] : [],
      cityId: headerCity ? [headerCity] : '',
      parentId: filters.parentId !== '' ? [filters.parentId] : []
    }
    let assignTutorInfo = {
      demoId: demoIdForAssign,
      tutorId: data
    }
    dispatch(tutionAction.assignTutorByAdmin(assignTutorInfo, Demodata))
    dispatch(tutionAction.getDemoClassListStatus(Demodata))
  }

  ///Create copy=========================>>>>>>>>>>>
  // const handleCreateCopy = data => {
  //   let resData = {
  //     demoId: data._id
  //   }
  //   let demoData = {
  //     status: [1],
  //     keyWord: '',
  //     sortOrder: '',
  //     sortBy: '',
  //     pageNo: 1,
  //     size: 10,
  //     ...filters,
  //     tutorId: filters.tutorId !== '' ? [filters.tutorId] : [],
  //     cityId: headerCity ? [headerCity] : '',
  //     parentId: filters.parentId !== '' ? [filters.parentId] : []
  //   }

  //   dispatch(tutionAction.createCopyByAdmin(resData, demoData))
  // }

  const [remarkModal, setRemarkModal] = useState(false)
  const [remarkId, setRemarkId] = useState('')
  const [remark, setRemark] = useState('')

  const addRemark = data => {
    let remarkId = data?._id
    setRemarkId(remarkId)
    setRemarkModal(true)
    setRemark(data?.reason ?? 'N/A')
  }

  const handleRemarkSubmit = e => {
    e.preventDefault()
    let demoData = {
      status: [1],
      // leadCreate: 'CREATED_SELF',
      keyWord: '',
      fromDate: '',
      toDate: '',
      sortOrder: '',
      sortBy: '',
      pageNo: currentPage,
      size: pageSize,
      ...filters,
      tutorId: filters.tutorId !== '' ? [filters.tutorId] : [],
      parentId: filters.parentId !== '' ? [filters.parentId] : []
    }
    let remarkData = {
      demoId: remarkId,
      remark: remark ? remark : 'N/A'
    }
    dispatch(tutionAction.addUpdateRemark(remarkData, demoData))
  }

  const [fullDetailsModal, setfullDetailsModal] = useState(false)
  const fullDetailsModalData = ''

  const handleRejectResion = e => {
    e.preventDefault()

    let demoData = {
      status: [1],
      keyWord: searchText,
      cityId: headerCity ? [headerCity] : '',
      fromDate: '',
      toDate: '',
      sortOrder: '',
      sortBy: '',
      pageNo: currentPage,
      size: pageSize,
      ...filters,
      tutorId: filters.tutorId !== '' ? [filters.tutorId] : [],
      parentId: filters.parentId !== '' ? [filters.parentId] : []
    }

    let data = {
      ...rejectData,
      remark: rejectResion
    }

    dispatch(tutionAction.updateStatusDemoApproveOrReject(data, demoData))
    setRejectResionModal(false)
    setRejectResion('')

    setOpen(false)
  }

  const handlerejctCloseModal = () => {
    setRejectResionModal(false)
    setRejectResion('')
  }

  useEffect(() => {
    let details = {
      cityId: '',
      userType: 'PARENT',
      isDisable: false
    }
    dispatch(userListAction.getUserTypeList(details))
  }, [])

  const [viewStudentModal, setViewStudentModal] = useState(false)
  const [studentListdata, setStudentListData] = useState()
  const viewStudent = (data, demoId) => {
    let studentListDetails = {
      parentId: data._id,
      demoId: demoId
    }
    parentAction
      .getStudentListByParentIdAndDemoIdPromise(studentListDetails)
      .then(resData => {
        setViewStudentModal(true)
        setStudentListData(resData.data)
      })
      .catch(err => { })
  }

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [image, setImage] = useState('')
  const handleCloseFunctionImageModel = data => {
    if (data) {
      setImage(() => data)
    } else {
      setImage('')
    }
    setIsModalVisible(!isModalVisible)
  }

  const [fullDetails, setFullDetails] = useState(false)
  const [DetailsData, setFullDetailsData] = useState()
  const [tuitionDetails, setTuitionDetails] = useState({})
  const handleFullDetails = data => {
    userActions.getUserByPromiseId({ id: data.parentId._id }).then(res => {
      setFullDetails(true)
      setFullDetailsData(res?.data)
      setTuitionDetails({
        classDuration: data?.classDuration,
        tuitionFee: data?.tuitionFee,
        numberOfClasses: data?.numberOfClasses,
        tutorFee: data?.tutorFee,
        tuitionType: data?.tuitionType,
        location: data?.location,
        classReference: data?.classReference
      })
    })
  }

  const [updateDemoModel, setUpdateDemoModel] = useState(false)
  const [inputChangeField2, setInputChangeField2] = useState({})
  const [allStudentList, setAllStudentData] = useState([])
  function updateDemoModelFunction(data) {
    tutionAction.getDemoByIdPromise({ demoId: data._id }).then(res => {
      setUpdateDemoModel(true)
      let bookFreeDemo = [...res.data.bookFreeDemo]
      bookFreeDemo = bookFreeDemo.map(free => ({
        ...free,
        studentId: free.studentId.studentFullName
      }))

      setInputChangeField2({
        ...res.data,
        bookFreeDemo: bookFreeDemo
      })
    })

    let studentListDetails = {
      parentId: data.parentId._id
    }
    parentAction
      .getStudentListByParentIdForDemoPromise(studentListDetails)
      .then(resData => {
        setAllStudentData(resData.data)
      })
      .catch(err => { })
  }

  const [isViewMoreModel, setIsViewMoreModel] = useState(false)
  let tuitionId = ''

  return (
    <>
      <div className=''>
        <Loader loading={selector?.tution?.loading} />
        <div className='bg-white border lg:block hidden border-[#D3D3D3] rounded-lg overflow-hidden overflow-x-auto'>
          <table className='min-w-full table-auto divide-y divide-gray-200'>
            <thead className='min-w-full rounded-sm'>
              <tr className='table_head border-b '>
                <th className='px-6 py-3 whitespace-nowrap text-sm text-black'>
                  Parent Name
                </th>
                <th className='px-6 py-3 whitespace-nowrap text-sm text-black'>
                  Student
                </th>
                <th className='px-6 py-3 whitespace-nowrap text-sm text-black'>
                  <tr className='flex justify-center items-center'> Address</tr>
                  <tr className='flex justify-center items-center'> City</tr>
                </th>
                <th className='px-6 py-3 whitespace-nowrap text-sm text-black'>
                  <tr className='flex justify-center items-center'>
                    {' '}
                    Teaching
                  </tr>
                  <tr className='flex justify-center items-center'>Location</tr>
                </th>
                <th className='px-6 py-3 whitespace-nowrap text-sm text-black'>
                  <tr className='flex justify-center items-center'>
                    {' '}
                    Demo Date
                  </tr>
                  <tr className='flex justify-center items-center'> Time</tr>
                </th>
                <th className='px-6 py-3 whitespace-nowrap text-sm text-black'>
                  <tr className='flex justify-center items-center'>
                    Preferred
                  </tr>
                  <tr className='flex justify-center items-center'>Gender</tr>
                </th>
                <th className='px-6 py-3 whitespace-nowrap text-sm text-black'>
                  <tr className='flex justify-center items-center'>
                    Lead Source
                  </tr>
                  <tr className='flex justify-center items-center'>Manager</tr>
                </th>
                <th className='px-6 py-3 whitespace-nowrap text-sm text-black'>
                  Mode
                </th>
                <th className='px-6 py-3 whitespace-nowrap text-sm text-black'>
                  Note
                </th>
                <th className='px-6 py-3 whitespace-nowrap text-sm'>Action</th>
              </tr>
            </thead>
            <tbody className='bg-white divide-y divide-gray-200'>
              {demoRequestData && demoRequestData.length > 0 ? (
                demoRequestData.map((item, index) => (
                  <tr
                    key={index}
                    className={`border-b capitalize ${item?.tutorAppliedList && item.tutorAppliedList.length > 0
                      ? 'bg-slate-200'
                      : ''
                      }`}
                  >
                    <td className='px-4 py-2.5 flex items-center gap-4 text-sm text-gray-900'>
                      <div className='flex items-center justify-center gap-2'>
                        <span
                          onClick={
                            item?.parentId?.image
                              ? () =>
                                handleCloseFunctionImageModel(
                                  item?.parentId?.image
                                )
                              : null
                          }
                        >
                          {item?.parentId && item?.parentId?.image ? (
                            <img
                              alt=''
                              src={
                                item?.parentId && item?.parentId?.image
                                  ? item?.parentId?.image
                                  : 'https://thumbs.dreamstime.com/z/vector-illustration-avatar-dummy-logo-collection-image-icon-stock-isolated-object-set-symbol-web-137160339.jpg?ct=jpeg'
                              }
                              onError={e => {
                                e.target.src =
                                  'https://thumbs.dreamstime.com/z/vector-illustration-avatar-dummy-logo-collection-image-icon-stock-isolated-object-set-symbol-web-137160339.jpg?ct=jpeg'
                              }}
                              className='rounded-full my-3 max-w-[50px] max-h-[50px] min-w-[50px] min-h-[50px]'
                            />
                          ) : (
                            <div className='bg-gray-300 rounded-full h-14 w-14 flex justify-center items-center'>
                              <span className='text-base font-semibold text-[#033565]'>
                                {' '}
                                {item?.parentId?.name
                                  ? item.parentId.name
                                    .split(' ')
                                    .map(name => name.charAt(0))
                                    .join('')
                                  : ''}
                              </span>
                            </div>
                          )}
                        </span>
                        <div className='w-24'>
                          <p className='font-semibold whitespace-nowrap text-[#023565]'>
                            {item?.parentId?.name ?? 'Not Available'}
                          </p>
                          <p className='text-gray-500 whitespace-nowrap'>
                            {item?.parentId?.mobNo ?? 'Not Available'}
                          </p>
                          <div
                            className='flex items-center gap-1 cursor-pointer pt-1'
                            onClick={() => handleFullDetails(item)}
                          >
                            <p className='text-base font-normal cursor-pointer'>
                              More
                            </p>
                            <FaChevronCircleRight className='text-[#023565] text-base cursor-pointer' />
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className='px-4 py-2.5 text-sm'>
                      <span className='flex items-center justify-center'>
                        <FaEye
                          size={24}
                          className='cursor-pointer text-[#023565] hover:text-[#E4006F] '
                          onClick={() => viewStudent(item?.parentId, item._id)}
                        />
                      </span>
                    </td>
                    <td className='px-4 py-2.5 text-sm text-gray-900'>
                      <span
                        className='flex justify-center items-center text-center cursor-pointer'
                        title={item?.address || 'N/A'}
                      >
                        {' '}
                        {truncateText(item?.address, 15) ?? 'N/A'}
                      </span>
                      <span className='flex justify-center items-center'>
                        {item.cityId?.name ?? 'N/A'}
                      </span>
                    </td>
                    <td className='px-4 py-2.5 text-sm text-gray-900 text-center'>
                      {item?.teachingLocation ? item?.teachingLocation : '---'}
                    </td>
                    <td className='px-4 py-2.5 text-sm text-gray-900'>
                      <span className='text-center flex items-center justify-center'>
                        {item?.demoDate ? item?.demoDate : null}
                      </span>
                      <span className='text-center flex items-center justify-center'>
                        {item?.demoTime
                          ? timeStanderFollowFunction(item?.demoTime)
                          : null}
                      </span>
                    </td>
                    <td className='px-4 py-2.5 text-sm text-gray-900 capitalize'>
                      <span className='flex items-center justify-center'>
                        {item?.requiredGender === 'MALE'
                          ? 'Male'
                          : item?.requiredGender === 'Male'
                            ? 'Male'
                            : item?.requiredGender === 'NO_PREFERENCE'
                              ? 'No '
                              : 'Female'}
                      </span>
                    </td>
                    <td className='px-4 py-2.5 text-sm text-gray-900'>
                      <span className='flex items-center justify-center'>
                        {item?.leadSource === 'CREATED_SELF'
                          ? 'Mobile'
                          : item?.leadSource
                            ? 'Admin'
                            : ''}
                      </span>
                      <span className='flex items-center justify-center'>
                        {item?.assignToEmployee
                          ? item.assignToEmployee.userName
                          : 'N/A'}
                      </span>
                    </td>
                    <td className='px-4 py-2.5 text-sm text-gray-900 text-center'>
                      {item?.preferredTeachMode === 'ONLINE_TUITION'
                        ? 'Online'
                        : 'In-Home'}
                    </td>
                    <td
                      className={`px-4 py-2.5 cursor-pointer font-semibold  text-center ${item.reason ? 'text-[#00AFE2]' : 'text-[#023565]'
                        }`}
                      onClick={() => addRemark(item)}
                    >
                      {item?.reason ? 'View' : 'Add'}
                    </td>
                    <td className='px-4 py-2.5 text-gray-900 relative table-cell'>
                      <span className='flex justify-center items-center'>
                        {' '}
                        <BsThreeDotsVertical
                          onClick={() => openAction(item._id)}
                          className='text-2xl cursor-pointer text-red-500 '
                        />
                      </span>
                      {open === item._id && (
                        <ul
                          className='bg-white border text-[#023565] rounded-md font-bold  absolute w-48 right-16 mt-2 bottom-1 py-2 text-start px-2 text-sm shadow-lg z-50'
                          ref={menuRef}
                        >
                          <li
                            className=' px-2 py-1 border-b  flex justify-between items-center hover:text-[#C60E6B]'
                            onClick={() => assignTutor(item)}
                          >
                            <button className='w-full text-left'>
                              Assign Tutor
                            </button>
                            <FaAngleRight className='text-md' />
                          </li>
                          <li
                            className=' px-2 py-1 border-b  flex justify-between items-center hover:text-[#C60E6B]'
                            onClick={() => updateDemoModelFunction(item)}
                          >
                            <button className='w-full text-left'>
                              View & Update Info
                            </button>
                            <FaAngleRight className='text-md' />
                          </li>
                          {/* <li
                            className=' px-2 py-1 border-b  flex justify-between items-center hover:text-[#C60E6B]'
                            onClick={() => handleCreateCopy(item)}
                          >
                            <button className='w-full text-left'>
                              Make A Copy
                            </button>
                            <FaAngleRight className='text-md' />
                          </li> */}
                          <li
                            className=' px-2 py-1 border-b  flex justify-between items-center hover:text-[#C60E6B]'
                            onClick={() => handleRejectMsg(item)}
                          >
                            <button className='w-full text-left'>Reject</button>
                            <FaAngleRight className='text-md' />
                          </li>
                          <li
                            className=' px-2 py-1 border-b  flex justify-between items-center hover:text-[#C60E6B]'
                            onClick={() => viewHistory(item)}
                          >
                            <button className='w-full text-left'>
                              View History
                            </button>
                            <FaAngleRight className='text-md' />
                          </li>
                        </ul>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <p className='text-center'>Data not found.</p>
              )}
            </tbody>
          </table>
        </div>

        <section className='space-y-5 lg:hidden block'>
          {demoRequestData && demoRequestData.length > 0 ? (
            demoRequestData.map((item, index) => (
              <div
                key={index}
                className={`${item?.tutorAppliedList && item.tutorAppliedList.length > 0 ? "bg-slate-200" : "bg-white"} p-4 rounded-[10px] border border-[#023565]`}
              >
                <>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[14px] font-[600] text-left'>
                        Parent Name
                      </p>
                    </div>
                    <div className='w-full flex items-start'>
                      <div className='flex justify-start  gap-2 w-[95%]'>
                        <div
                          onClick={
                            item?.parentId?.image
                              ? () =>
                                handleCloseFunctionImageModel(
                                  item?.parentId?.image
                                )
                              : null
                          }
                        >
                          {item?.parentId && item?.parentId?.image ? (
                            <img
                              alt=''
                              src={
                                item?.parentId && item?.parentId?.image
                                  ? item?.parentId?.image
                                  : ''
                              }
                              className='rounded  max-w-[50px] max-h-[50px] min-w-[50px] min-h-[50px]'
                            />
                          ) : (
                            <div className='bg-gray-300 rounded h-14 w-14 flex justify-center items-center'>
                              <span className='text-base font-semibold text-[#033565]'>
                                {' '}
                                {item?.parentId?.name
                                  ? item.parentId.name
                                    .split(' ')
                                    .map(name => name.charAt(0))
                                    .join('')
                                  : ''}
                              </span>
                            </div>
                          )}
                        </div>
                        <div className=''>
                          <p className='text-[12px] font-semibold whitespace-nowrap text-[#023565]'>
                            {/* {item?.parentId?.name ?? 'Not Available'} */}
                            {truncateText(item?.parentId?.name, 10) ?? 'N/A'}
                          </p>
                          <p className='text-[12px] font-medium text-gray-500 whitespace-nowrap'>
                            {item?.parentId?.mobNo ?? 'Not Available'}
                          </p>
                          <div className='flex items-center  gap-3 cursor-pointer'>
                            <div
                              className='flex items-center  gap-1'
                              onClick={() => handleFullDetails(item)}
                            >
                              <p className='text-[12px] font-medium cursor-pointer'>
                                More
                              </p>
                              <FaChevronCircleRight className='text-[#023565] text-base cursor-pointer' />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='w-[5%] relative inline-block ' >
                        <BsThreeDotsVertical
                          onClick={() => openAction(item._id)}
                          size={24}
                          id="dropdownDefaultButton"
                          className='text-2xl cursor-pointer text-red-500 '
                        />
                        {open === item._id && (
                          <div id="dropdown" className='absolute right-0 mt-2 w-48 text-start rounded-md  text-[#023565] shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-40'  ref={mobileRef}>
                            <div >
                              <div className='py-2 px-3 text-[#E4006F] hover:text-[#E4006F] cursor-pointer'>
                                <div
                                  className='flex justify-between items-center'
                                  onClick={() => assignTutor(item)}
                                >
                                  Assign Tutor{' '}
                                  <FaAngleRight className='hover:text-[#C60E6B]' />
                                </div>
                              </div>
                              <div className='py-2 px-3  cursor-pointer flex justify-between hover:text-[#E4006F] '>
                                <button
                                  className='flex justify-between items-center gap-3'
                                  onClick={() => updateDemoModelFunction(item)}
                                >
                                  View & Update Info
                                </button>
                                <div>
                                  <FaAngleRight className='hover:text-[#C60E6B]' />
                                </div>
                              </div>
                              {/* <div className='py-2 px-3  cursor-pointer hover:text-[#E4006F] '>
                              <div
                                className='flex justify-between items-center'
                                onClick={() => handleCreateCopy(item)}
                              >
                                Make A Copy{' '}
                                <FaAngleRight className='hover:text-[#C60E6B]' />
                              </div>
                            </div> */}
                              <div className='py-2 px-3  cursor-pointer flex justify-between hover:text-[#E4006F] '>
                                <button
                                  className='flex justify-between items-center'
                                  onClick={() => handleRejectMsg(item)}
                                >
                                  Reject
                                </button>
                                <div>
                                  <FaAngleRight className='hover:text-[#C60E6B]' />
                                </div>
                              </div>
                              <div className='py-2 px-3 cursor-pointer relative flex justify-between hover:text-[#E4006F'>
                                <button
                                  className='flex justify-between items-center'
                                  onClick={() => viewHistory(item)}
                                >
                                  View History
                                </button>
                                <FaAngleRight />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[14px] font-[600] text-left'>
                        Student Name
                      </p>
                    </div>
                    <div className='w-full'>
                      <p
                        className='text-[14px] font-[700] text-[#023565] hover:text-[#E4006F]'
                        onClick={() => viewStudent(item?.parentId, item._id)}
                      >
                        View
                      </p>
                    </div>
                  </div>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[14px] font-[600] text-left'>
                        Address
                      </p>
                    </div>
                    <div className='w-full'>
                      <p
                        className='text-[14px] font-[400] break-all'
                        title={item?.address || 'N/A'}
                      >
                        {/* {truncateText(item?.address, 20) ?? 'N/A'} */}
                        {item?.address || 'N/A'}
                      </p>
                    </div>
                  </div>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[14px] font-[600] text-left'>City</p>
                    </div>
                    <div className='w-full'>
                      <p className='text-[14px] font-[400]'>
                        {item?.cityId?.name}
                      </p>
                    </div>
                  </div>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[14px] font-[600] text-left'>
                        Teaching Location
                      </p>
                    </div>
                    <div className='w-full'>
                      <p className='text-[14px] font-[400]'>
                        {item?.teachingLocation
                          ? item?.teachingLocation
                          : '---'}
                      </p>
                    </div>
                  </div>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[14px] font-[600] text-left'>
                        Demo Date Time
                      </p>
                    </div>
                    <div className='w-full'>
                      <p className='text-[14px] font-[400]'>
                        {item?.demoDate ? item?.demoDate : null} /{' '}
                        {item?.demoTime
                          ? timeStanderFollowFunction(item?.demoTime)
                          : null}
                      </p>
                    </div>
                  </div>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[14px] font-[600] text-left'>
                        Preferred Gender
                      </p>
                    </div>
                    <div className='w-full'>
                      <p className='text-[14px] font-[400]'>
                        {item?.requiredGender === 'MALE'
                          ? 'Male'
                          : item?.requiredGender === 'Male'
                            ? 'Male'
                            : item?.requiredGender === 'NO_PREFERENCE'
                              ? 'No '
                              : 'Female'}{' '}
                      </p>
                    </div>
                  </div>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[14px] font-[600] text-left'>
                        Lead Source
                      </p>
                    </div>
                    <div className='w-full'>
                      <p className='text-[14px] font-[400]'>
                        {item.leadSource === 'CREATED_SELF' ? 'App' : 'Admin'} /{' '}
                        {item?.assignToEmployee
                          ? item.assignToEmployee.userName
                          : 'N/A'}
                      </p>
                    </div>
                  </div>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[14px] font-[600] text-left'>Mode</p>
                    </div>
                    <div className='w-full'>
                      <p className='text-[14px] font-[400]'>
                        {item?.preferredTeachMode === 'ONLINE_TUITION'
                          ? 'Online'
                          : 'In-Home'}{' '}
                      </p>
                    </div>
                  </div>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[14px] font-[600] text-left'>Note</p>
                    </div>
                    <div className='w-full'>
                      <p
                        className={`text-[14px] font-[700] ${item.reason ? 'text-[#00AFE2]' : 'text-[#023565]'
                          }`}
                        onClick={() => addRemark(item)}
                      >
                        {item?.reason ? 'View' : 'Add'}
                      </p>
                    </div>
                  </div>
                </>
              </div>
            ))
          ) : (
            <div className=' bg-white p-4 rounded-[10px]'>
              <p className='text-center'>Data Not Found</p>
            </div>
          )}
        </section>

        {totalPages > 1 && (
          <div className='flex justify-end items-center lg:py-2 py-4'>
            <button
              className={`px-4 py-2 border text-white bg-[#023565] rounded ${currentPage === 1 ? 'opacity-50 cursor-not-allowed' : ''
                }`}
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
            >
              <FaChevronLeft />
            </button>
            <span className='text-gray-700 mx-1'>
              <span className='border px-4 py-2 text-black  rounded'>
                {currentPage}
              </span>{' '}
              of{' '}
              <span className='border px-4 py-2 text-black  rounded'>
                {totalPages}
              </span>
            </span>
            <button
              className={`px-4 py-2 border text-white bg-[#023565] rounded ${currentPage === totalPages
                ? 'opacity-50 cursor-not-allowed'
                : ''
                }`}
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              <FaChevronRight />
            </button>
          </div>
        )}

        <UpdateTuitionModel
          handleBack={() => {
            setUpdateDemoModel(false)
            setInputChangeField2({})
          }}
          updateDemoModel={updateDemoModel}
          inputChangeField={inputChangeField2}
          allStudentList={allStudentList}
          setInputChangeField={setInputChangeField2}
          listPayload={{
            status: [1],
            keyWord: searchText,
            cityId: headerCity ? [headerCity] : '',
            fromDate: '',
            toDate: '',
            sortOrder: '',
            sortBy: '',
            pageNo: currentPage,
            size: pageSize,
            ...filters,
            tutorId: filters.tutorId !== '' ? [filters.tutorId] : []
          }}
        />

        <UpdateNoteModel
          remarkClose={() => setRemarkModal(false)}
          remarkModal={remarkModal}
          Heading={'Update Note'}
          remark={remark}
          setRemark={setRemark}
          handleRemarkSubmit={handleRemarkSubmit}
          btnName={`${remark !== 'N/A' || remark ? 'Edit' : 'Add'} Note`}
        />

        <ViewParent
          fullDetails={fullDetails}
          setFullDetails={setFullDetails}
          DetailsData={DetailsData}
          tuitionDetails={tuitionDetails}
        />
        <StudentDetail
          setViewStudentModal={setViewStudentModal}
          viewStudentModal={viewStudentModal}
          setStudentListData={setStudentListData}
          studentListdata={studentListdata}
        />

        <ImagePreviewModal
          handleClose={handleCloseFunctionImageModel}
          showWarning={isModalVisible}
          image={image}
        />
        {activate && (
          <div className='border rounded-md justify-center w-1/2 h-1/2 bg-white p-5 m-auto my-5'>
            <div className='flex justify-end m-5'>
              <IoIosCloseCircle
                className='text-purple-500 text-2xl cursor-pointer'
                onClick={closeActive}
              />
            </div>
            <hr />
            <div className='Teaching_location text-center'>
              <iframe
                src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3153.019467255256!2d144.96305771531676!3d-37.8136279797517!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad642af0f11fd81%3A0xfff32c8cb3ecf90!2sMelbourne%20VIC%2C%20Australia!5e0!3m2!1sen!2sus!4v1602654478033!5m2!1sen!2sus'
                allowFullScreen=''
                loading='lazy'
              ></iframe>
            </div>
            <hr />
            <div className='no_of_class'>
              <p>No Of Class in a month is:- 20days</p>
            </div>
            <hr />
            <div className='class_duration'>
              <p>Class Duration is:-30 minutes</p>
            </div>
            <hr />
            <div className='Tuition_fee'>
              <p>Tuition Fee</p>
            </div>
            <hr />
            <div className='Tutor_fee'>
              <p>Tutor Fee:-</p>
            </div>
            <hr />
            <div className='Remark'>
              <button>Note</button>
            </div>
          </div>
        )}
        {viewandUpdate && (
          <div className='fixed inset-0 bg-gray-500 bg-opacity-50 flex items-center justify-center'>
            <div className='bg-white p-5 rounded-md '>
              <div className='flex justify-end m-5'>
                <IoIosCloseCircle
                  className='text-purple-500 text-2xl cursor-pointer'
                  onClick={closeActive}
                />
              </div>
              <div></div>
            </div>
          </div>
        )}

        {assignTutorModal && (
          <AssignTutorPopUp
            assignTutorModal={assignTutorModal}
            setAssignTutorModal={setAssignTutorModal}
            suggestTutorBtn={suggestTutorBtn}
            assignTutorTab={assignTutorTab}
            handleAssignTutor={handleAssignTutor}
            assignTutorList={assignTutorList}
            allTutorDataList={allTutorDataList}
            handleAssign={handleAssign}
            suggestedTutor={suggestedTutor}
          />
        )}

        {rejectResionModal && (
          <>
            <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40'>
              <div className='relative w-11/12 mx-auto lg:w-[600px]'>
                <button
                  className='z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center'
                  onClick={handlerejctCloseModal}
                >
                  <span>
                    <MdOutlineClose />
                  </span>{' '}
                  Close
                </button>
                <div className='form_class border-0 rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
                  <div className='flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t'>
                    <h2 className='text-lg font-semibold'>Reason</h2>
                  </div>
                  <div className='relative  overflow-y-auto'>
                    <form onSubmit={handleRejectResion} className='p-4'>
                      <div className='mb-4'>
                        <label
                          htmlFor='skipResion'
                          className='block text-gray-700 font-bold mb-2'
                        >
                          Reason for Reject
                        </label>
                        <input
                          type='text'
                          id='skipResion'
                          value={rejectResion}
                          onChange={e => setRejectResion(e.target.value)}
                          className='w-full p-2 border border-gray-300 rounded-md'
                          placeholder='Enter reason'
                        />
                      </div>
                      <div className='flex justify-center space-x-2'>
                        <button
                          type='submit'
                          className='w-60 mx-auto bg-[#023565] text-white font-semibold py-2 px-4 rounded-full '
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {fullDetailsModal && (
          <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40'>
            <div className='relative w-11/12 mx-auto lg:w-[800px]'>
              <div className='flex justify-end'>
                <button
                  onClick={() => setfullDetailsModal(false)}
                  className='z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center'
                >
                  <MdOutlineClose /> Close
                </button>
              </div>
              <div className='form_class border-0 overflow-hidden overflow-x-auto rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
                <div className='flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t'>
                  <h2 className='text-lg font-semibold'>Student Details </h2>
                </div>
                <div className='overflow-x-auto'>
                  <table className='min-w-full divide-y divide-gray-200'>
                    <thead className='bg-gray-50'>
                      <tr>
                        <th className='px-6 py-3 text-left text-xs font-medium uppercase tracking-wider'>
                          Student Name
                        </th>
                        <th className='px-6 py-3 text-left text-xs font-medium uppercase tracking-wider'>
                          Class
                        </th>
                        <th className='px-6 py-3 text-left text-xs font-medium uppercase tracking-wider'>
                          Subject
                        </th>
                        <th className='px-6 py-3 text-left text-xs font-medium uppercase tracking-wider'>
                          Board
                        </th>
                      </tr>
                    </thead>
                    <tbody className='bg-white divide-y divide-gray-200'>
                      {fullDetailsModalData?.bookFreeDemo?.map((ele, index) => (
                        <tr className='capitalize' key={index}>
                          <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500 flex justify-start place-items-center'>
                            <img
                              alt=''
                              src={
                                ele?.studentId?.image
                                  ? ele?.studentId?.image
                                  : 'https://thumbs.dreamstime.com/z/vector-illustration-avatar-dummy-logo-collection-image-icon-stock-isolated-object-set-symbol-web-137160339.jpg?w=768'
                              }
                              className='w-8 h-8 rounded-full'
                            />
                            {ele?.studentId?.studentFullName ?? 'N/A'}
                          </td>
                          <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>
                            {ele?.classId?.name ?? 'N/A'}
                          </td>
                          <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>
                            {ele?.subjectId?.map((subject, subIndex) => (
                              <div key={subIndex}>{subject?.name}</div>
                            ))}
                          </td>
                          <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>
                            {ele?.board?.name ?? 'N/A'}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}

        {tutorDetails && (
          <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40'>
            <div className='relative w-11/12 mx-auto lg:w-[800px]'>
              <div className='flex justify-end'>
                <button
                  onClick={() => setTutorDetails(false)}
                  className='z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center'
                >
                  <MdOutlineClose /> Close
                </button>
              </div>

              <div className='form_class border-0 overflow-hidden overflow-x-auto rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
                <div className='flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t'>
                  <h2 className='text-lg font-semibold'>Tutor Details </h2>
                </div>

                <div className='grid md:grid-cols-2 gap-4 p-2'>
                  <div className='w-full text-center'>
                    <img
                      src={
                        fullData?.image ??
                        'https://thumbs.dreamstime.com/z/vector-illustration-avatar-dummy-logo-collection-image-icon-stock-isolated-object-set-symbol-web-137160339.jpg?ct=jpeg'
                      }
                      alt='Tutor'
                      className='w-56 h-56 mx-auto '
                    />
                  </div>
                  <div className='w-full capitalize space-y-2'>
                    <div className='w-full flex justify-between items-center'>
                      <p className='font-semibold w-[40%]'>Name</p>
                      <span className='w-[20%]'>:</span>
                      <p className='w-[40%]'>{fullData?.name ?? 'N/A'}</p>
                    </div>
                    <div className='w-full flex justify-between items-center'>
                      <p className='font-semibold w-[40%]'>Gender</p>
                      <span className='w-[20%]'>:</span>
                      <p className='w-[40%]'>
                        {fullData?.tutorInfoId?.gender ?? 'N/A'}
                      </p>
                    </div>
                    <div className='w-full flex justify-between items-center'>
                      <p className='font-semibold w-[40%]'>Teaching Exp</p>
                      <span className='w-[20%]'>:</span>
                      <p className='w-[40%]'>
                        {fullData?.tutorInfoId?.teachingExp ?? 'N/A'}
                      </p>
                    </div>
                    <div className='w-full flex justify-between items-center'>
                      <p className='font-semibold w-[40%]'>Address</p>
                      <span className='w-[20%]'>:</span>
                      <p className='w-[40%]'>{fullData?.address ?? 'N/A'}</p>
                    </div>
                    <div className='w-full flex justify-between items-center'>
                      <p className='font-semibold w-[40%]'>Mobile No</p>
                      <span className='w-[20%]'>:</span>
                      <p className='w-[40%]'>{fullData?.mobNo ?? 'N/A'}</p>
                    </div>
                    <div className='w-full flex justify-between items-center'>
                      <p className='font-semibold w-[40%]'>Email</p>
                      <span className='w-[20%]'>:</span>
                      <p className='w-[40%]'>{fullData?.email ?? 'N/A'}</p>
                    </div>
                    <div className='w-full flex justify-between items-center'>
                      <p className='font-semibold w-[40%]'>School </p>
                      <span className='w-[20%]'>:</span>
                      <p className='w-[40%]'>
                        {fullData?.tutorInfoId?.schoolName ?? 'N/A'}
                      </p>
                    </div>

                    <div className='w-full flex justify-between items-center'>
                      <p className='font-semibold w-[40%]'>UG Course</p>
                      <span className='w-[20%]'>:</span>
                      <p className='w-[40%]'>
                        {fullData?.tutorInfoId?.ugCourse ?? 'N/A'}
                      </p>
                    </div>
                    <div className='w-full flex justify-between items-center'>
                      <p className='font-semibold w-[40%]'>PG Course </p>
                      <span className='w-[20%]'>:</span>
                      <p className='w-[40%]'>
                        {fullData?.tutorInfoId?.pgCourse ?? 'N/A'}
                      </p>
                    </div>
                    <div className='w-full flex justify-between items-center'>
                      <p className='font-semibold w-[40%]'>Classes </p>
                      <span className='w-[20%]'>:</span>
                      <p className='w-[40%]'>
                        <p>
                          {fullData?.tutorInfoId?.classYouCanTeach
                            ?.map(ele => ele?.name)
                            .join(', ') ?? 'N/A'}
                        </p>
                      </p>
                    </div>

                    <div className='w-full flex justify-between items-center'>
                      <p className='font-semibold w-[40%] capitalize'>
                        Subjects
                      </p>
                      <span className='w-[20%]'>:</span>
                      <p className='w-[40%]'>
                        {fullData?.tutorInfoId?.subYouCanTeach
                          ?.map(ele => ele?.name)
                          .join(', ') ?? 'N/A'}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {isViewMoreModel && (
        <ViewMoreTuitionModel
          isViewMoreModel={isViewMoreModel}
          setIsViewMoreModel={setIsViewMoreModel}
          tuitionId={tuitionId}
        />
      )}
    </>
  )
}

// export default ActiveTable;

const mapStateToProps = state => ({
  isStateMangerRes: state.tution.isStateMangerRes,
  suggestedTutorList: state.tution.suggestedTutorList,
  getAllTutorList: state.tution.getAllTutorList
})

const mapDispatchToProps = dispatch => ({
  tutionAction: () => dispatch(tutionAction())
})

export default connect(mapStateToProps, mapDispatchToProps)(ActiveTable)
