/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FiChevronsRight } from "react-icons/fi";
import { FaChevronCircleRight, FaChevronLeft, FaRegCheckCircle } from "react-icons/fa";
import { tutortoliveAction } from "../../_actions/tutortolive.action";
import { AiOutlineCloseCircle } from "react-icons/ai";
import moment from "moment/moment";
import { MdDelete } from "react-icons/md";
import { confirmAlert } from "react-confirm-alert";
import { MdOutlineClose } from "react-icons/md";
import { FaChevronRight } from "react-icons/fa";
import { tutionAction } from "../../_actions";
import { userListAction } from "../../_actions";
import Loader from "../../components/Loader";
import 'react-datepicker/dist/react-datepicker.css';
import ImagePreviewModal from "./Model/ImagePreviewModal";
import DetailsPreViewModel from "./Model/DetailsPreviewModel";
import KycViewModel from "./Model/KycViewModel";
import AcceptInterView from "./Model/AcceptInterView";
import { classAction } from "../../_actions/class.action";
import { tutorUpdateInitialState } from "../../_helpers/dataInitialState";
import "./Manage.css"



const TableFirst = ({ newSetState ,searchText }) => {
  const dispatch = useDispatch();
  const selector = useSelector((state) => state);
  const [newJoinedTutorList, setNewJoinedTutor] = useState([]);
  const [skipModal, setSkipModal] = useState(false);
  const [fullData, setFullData] = useState();
  const [rejectModal, setrejectModal] = useState(false);
  const [rejectResion, setRejectResion] = useState("");
  const [oldRejectData, setOldRejectData] = useState("");
  const [skipResion, setSkipResion] = useState("");
  const [acceptModal, setAcceptModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const pageSize = 10;
  const [kycDataShow, setKycDataShow] = useState(false);
  const [kycListData, setKycListData] = useState();
  const [newAcceptData, setNewAcceptData] = useState(tutorUpdateInitialState);
  const [selectedTeachMode, setSelectedTeachMode] = useState([]);
  const [selectedPinCode, setSelectedPinCode] = useState([]);
  const [subjectYouCanTeach, setSubjectYouCanTeach] = useState([]);
  const [selectedClass, setSelectedClass] = useState([]);


  useEffect(() => {
    if (selector?.TutorToLive?.getNewJoinTutorList?.data.total) {
      setTotalPages(Math.ceil(selector?.TutorToLive?.getNewJoinTutorList?.data?.total / pageSize));
      setKycListData(selector?.userList?.userKycListByUserId?.data);
    }
    setNewJoinedTutor(selector?.TutorToLive?.getNewJoinTutorList?.data?.demoList);
  }, [selector.TutorToLive, selector.userList]);


  useEffect(() => {
    setFullData(() => selector?.TutorToLive?.getTutorByIdList?.data)
  }, [selector.TutorToLive, selector.tution]);




  const handleNextPage = (e) => {
    setCurrentPage(e);
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };



  useEffect(() => {
    let newJoinedTutorData = {
      status: "NEW_TUTOR",
      keyWord: searchText ?? "",
      fromDate: "",
      toDate: "",
      sortOrder: "",
      sortBy: "createdAt",
      pageNo: currentPage,
      size: pageSize,
      isManagement : false
    };
    const ClassList = {
      keyWord: "",
      fromDate: "",
      toDate: "",
      sortOrder: "asc",
      sortBy: "createdAt",
      pageNo: 1,
      size: 100,
    };
    dispatch(tutortoliveAction.getNewlyJoinTutorList(newJoinedTutorData));
    dispatch(tutionAction.getAllCityList());
    dispatch(tutionAction.gitAllClassListByAdmin());
    dispatch(classAction.classGet(ClassList));
    newSetState('NEW_TUTOR')
  }, [currentPage,searchText]);



  const closeModal = () => {
    setAcceptModal(false);
    setNewAcceptData(tutorUpdateInitialState)
  };




  function teachModeViewData(data) {
    switch (data) {
      case "HOME_TUITION":
        return "Home Tuition"
      case "ONLINE_TUITION":
        return "Online Tuition"
      default:
        return null
    }
  }

  const showAccept = async (user) => {
    tutortoliveAction.getTutorByPromiseId({
      id: user._id
    }).then(async (data) => {
      let user = data.data
      setNewAcceptData({
        _id: user._id,
        name: user?.name,
        email: user?.email,
        whatsappNo: user?.whatsappNo,
        address: user?.address,
        city: user?.city,
        pinCode: user?.pinCode,
        dob: user?.tutorInfoId?.dob,
        gender: user?.tutorInfoId?.gender,
        schoolName: user?.tutorInfoId?.schoolName,
        schoolPassOutYear: user?.tutorInfoId?.schoolPassOutYear,
        ugCourse: user?.tutorInfoId?.ugCourse,
        ugCollegeName: user?.tutorInfoId?.ugCollegeName,
        ugPassOutYear: user?.tutorInfoId?.ugPassOutYear,
        pgCourse: user?.tutorInfoId?.pgCourse,
        pgCollegeName: user?.tutorInfoId?.pgCollegeName,
        pgPassOutYear: user?.tutorInfoId?.pgPassOutYear,
        teachingExp: user?.tutorInfoId?.teachingExp,
      })
      setSelectedTeachMode(user?.tutorInfoId?.teachingMode.map((ele) => ({ name: teachModeViewData(ele), _id: ele })))
      setSelectedPinCode(user?.tutorInfoId?.pinCodeYouCanGo?.map((ele) => ({ name: ele, _id: ele })) || [])
      setSubjectYouCanTeach(user?.tutorInfoId?.subYouCanTeach)
      setSelectedClass(user?.tutorInfoId?.classYouCanTeach)
      setAcceptModal((prev) => !prev);
    })

  }

  const handleReject = (data) => {
    setrejectModal(true);

    let requiredData = {
      id: data._id,
      interviewStatus: 2,
    };
    setOldRejectData(requiredData);
  };

  //// handle Skip========================>>

  const handleSkiped = (data) => {
    let newJoinedTutorData = {
      status: "NEW_TUTOR",
      keyWord: searchText ?? "",
      fromDate: "",
      toDate: "",
      sortOrder: "",
      sortBy: "createdAt",
      pageNo: currentPage,
      size: pageSize,
      isManagement : false

    };

    let dataSkip = {
      id: data._id,
      interviewStatus: 3,
    };

    confirmAlert({
      title: "Confirm to skip?",
      message: `Are you sure to skip ?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            dispatch(
              tutortoliveAction.interviewStatusUpdateByAdmin(
                dataSkip,
                newJoinedTutorData
              )
            );
          },
        },
        {
          label: "No",
        },
      ],
    });

    dispatch(tutortoliveAction.getNewlyJoinTutorCount({ isManagement: false }));
  };




  const handleSkipResion = () => {
    let newJoinedTutorData = {
      status: "NEW_TUTOR",
      keyWord: searchText ?? "",
      fromDate: "",
      toDate: "",
      sortOrder: "",
      sortBy: "createdAt",
      pageNo: currentPage,
      size: pageSize,
      isManagement : false

    };

    let newSkipData = {
      resion: skipResion,
    };
    dispatch(
      tutortoliveAction.interviewStatusUpdateByAdmin(
        newSkipData,
        newJoinedTutorData
      )
    );
    dispatch(tutortoliveAction.getNewlyJoinTutorCount({ isManagement: false }));
    setSkipModal(false);
  };

  const handleRejectResion = () => {
    let newJoinedTutorData = {
      status: "NEW_TUTOR",
      keyWord: searchText ?? "",
      fromDate: "",
      toDate: "",
      sortOrder: "",
      sortBy: "createdAt",
      pageNo: currentPage,
      size: pageSize,
      isManagement : false

    };

    let newRejctData = {
      reason: rejectResion,
      ...oldRejectData,
    };

    dispatch(
      tutortoliveAction.interviewStatusUpdateByAdmin(
        newRejctData,
        newJoinedTutorData
      )
    );
    dispatch(tutortoliveAction.getNewlyJoinTutorCount({ isManagement: false }));

    setrejectModal(false);
  };

  const handleCancel = () => {
    setSkipModal(false);
    setrejectModal(false);
  };

  const kycDetailModal = (data) => {
    let kycData = {
      id: data?._id,
    };
    setKycDataShow(true);
    dispatch(userListAction.getKycByUserId(kycData))
  }

  const handleRemoveProfile = (data) => {
    let newJoinedTutorData = {
      "status": "NEW_TUTOR",
      keyWord: searchText ?? "",
      "fromDate": "",
      "toDate": "",
      "sortOrder": "",
      "sortBy": "asc",
      pageNo: currentPage,
      size: pageSize,
      isManagement : false

    };
    let removeData = {
      id: data._id
    }


    confirmAlert({
      title: 'Confirm to Remove?',
      message: `Are you sure to Remove ?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => dispatch(tutortoliveAction.removeProfile(removeData, newJoinedTutorData))

        },
        {
          label: 'No'
        }
      ]
    });
    newSetState('NEW_TUTOR')
  }
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [image, setImage] = useState("")
  const handleCloseFunctionImageModel = (data) => {
    if (data) {
      setImage(() => data)
    }
    else {
      setImage("")
    }
    setIsModalVisible(!isModalVisible);
  };

  const [isModalDetails, setIsModalDetails] = useState(false);
  const handelDetailsModel = (data) => {
    if (data) {
      let tutorId = {
        id: data._id
      }
      tutortoliveAction.getTutorByPromiseId(tutorId).then((response) => {
        if (response.data) {
          setFullData(response.data)
          setIsModalDetails(!isModalDetails)
        }
      })
    } else {
      setFullData({})
      setIsModalDetails(!isModalDetails)
    }
  }


  return (
    <>
      <section className="py-10 ">
        <Loader loading={selector?.TutorToLive?.loading} />
        <div className="border border-[#D3D3D3] rounded-xl overflow-x-auto lg:block hidden">
          <table className="min-w-full bg-white rounded-xl">
            <thead className="border-b border-[#D1D1D1]">
              <tr className="border-b  ">
                <th className="px-6 py-3 whitespace-nowrap text-left">
                  Tutor Name
                </th>
                <th className="px-6 py-3 whitespace-nowrap text-left">
                  Address
                </th>
                <th className="px-6 py-3 whitespace-nowrap text-left">
                  City
                </th>
                <th className="px-6 py-3 whitespace-nowrap text-left">
                  KYC
                </th>
                <th className="px-6 py-3 whitespace-nowrap text-left">
                  Interview
                </th>
                <th className="px-6 py-3 whitespace-nowrap text-left">
                  Action
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200 relative">
              {newJoinedTutorList && newJoinedTutorList.length > 0 ? (
                newJoinedTutorList?.map((item, index) => (
                  <tr key={index} className=" capitalize  ">
                    <td className="flex items-center gap-4 px-6 py-4 text-sm text-gray-900 ">
                      <div className="flex items-center justify-center gap-2">
                        <span onClick={item?.image ? () => handleCloseFunctionImageModel(item?.image) : null}>
                          <img
                            className="w-10 h-10 rounded-full"
                            src={
                              item?.image ??
                              "https://thumbs.dreamstime.com/z/vector-illustration-avatar-dummy-logo-collection-image-icon-stock-isolated-object-set-symbol-web-137160339.jpg?ct=jpeg"
                            }
                            alt="User"
                          />
                        </span>
                        <div className="w-26">
                          <p className="font-medium whitespace-nowrap">{item?.name ?? "No Data"}</p>
                          <p className="text-gray-500 whitespace-nowrap">{item?.mobNo ?? "No Contact"}</p>
                          <p className="text-xs text-gray-500">
                            {moment(item?.kycId?.createdAt).format(
                              "MMMM Do YYYY"
                            )}
                          </p>
                        </div>
                      </div>
                      <FaChevronCircleRight
                        className="text-[#023565] text-lg cursor-pointer"
                        onClick={() => handelDetailsModel(item)}
                      />
                    </td>

                    <td className="px-6 py-4 text-sm  text-gray-900 capitalize">
                      {item?.address ?? "No Data"}
                    </td>
                    <td className="px-6 py-4 text-sm  text-gray-900 capitalize">
                      {item?.city?.name ?? "No Data"}
                    </td>
                    <td className="px-6 py-4 text-sm  text-gray-900 capitalize font-bold underline" onClick={() => kycDetailModal(item)}>
                      View
                    </td>
                    <td className="forfontsmall py-4 space-y-1 ">
                      <button
                        className="border px-3 py-1 inline-flex items-center gap-2 font-[600] text-[#2C9408] border-[#2C9408] rounded-full"
                        onClick={() => showAccept(item)}
                        type=""
                      >
                        <span>
                          <FaRegCheckCircle size={20} />
                        </span>{" "}
                        Accept
                      </button>{" "}
                      <br />
                      <button
                        className="border px-4 py-1 inline-flex items-center gap-2 font-[600] text-[#E70909] border-[#E70909] rounded-full"
                        type=""
                        onClick={() => handleReject(item)}
                      >
                        <span>
                          <AiOutlineCloseCircle size={20} />
                        </span>{" "}
                        Reject
                      </button>

                    </td>
                    <td className="forfontsmall py-4 space-y-1 ">
                      <button
                        className="border px-5 py-1 inline-flex items-center gap-2 font-[600] text-[#2C9408] border-[#2C9408] rounded-full"
                        onClick={() => handleSkiped(item)}
                        type=""
                      >
                        <span>
                          <FiChevronsRight size={20} />
                        </span>{" "}
                        Skip
                      </button>{" "}
                      <br />
                      <button
                        className="border px-4 py-1 inline-flex items-center gap-2 font-[600] text-[#E70909] border-[#E70909] rounded-full"
                        type=""
                        onClick={() => handleRemoveProfile(item)}>
                        <span>
                          <MdDelete size={20} />
                        </span>{" "}
                        Delete
                      </button>

                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={7} className="px-6 py-4 text-center">
                    Data Not Available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {totalPages > 1 && (
            <div className="flex justify-end items-center py-2 px-4">
              <button
                className={`px-4 py-2 border text-white bg-[#023565] rounded ${currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""}`}
                onClick={handlePreviousPage}
                disabled={currentPage === 1}
              >
                <FaChevronLeft />
              </button>
              <span className="text-gray-700 mx-1">
                <span className="border px-4 py-2 text-black  rounded">{currentPage}</span> of <span className="border px-4 py-2 text-black  rounded">{totalPages}</span>
              </span>
              <button
                className={`px-4 py-2 border text-white bg-[#023565] rounded ${currentPage === totalPages ? "opacity-50 cursor-not-allowed" : ""}`}
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
              >
                <FaChevronRight />
              </button>
            </div>
          )}
        </div>
        {rejectModal && (
          <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40">
              <div className="relative w-11/12 mx-auto lg:w-[600px]">
                <button
                  className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center"
                  onClick={handleCancel}
                >
                  <span><MdOutlineClose /></span> Close
                </button>
                <div className="form_class border-0 rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  <div className="flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                    <h2 className="text-lg font-semibold">Reason</h2>
                  </div>
                  <div className="relative  overflow-y-auto">
                    <form onSubmit={handleRejectResion} className="p-4">
                      <div className="mb-4">
                        <label htmlFor="skipResion" className="block text-gray-700 font-bold mb-2">Reason for Reject</label>
                        <input
                          type="text"
                          id="skipResion"
                          value={rejectResion}
                          onChange={(e) => setRejectResion(e.target.value)}
                          className="w-full p-2 border border-gray-300 rounded-md"
                          placeholder="Enter reason"
                        />
                      </div>
                      <div className="flex justify-center space-x-2">
                        <button
                          type="submit"
                          className="w-60 mx-auto bg-[#023565] text-white font-semibold py-2 px-4 rounded-full "
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>

                </div>
              </div>
            </div>

          </>
        )}
        {skipModal && (
          <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75">
            <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
              <form onSubmit={handleSkipResion}>
                <div className="mb-4">
                  <label
                    htmlFor="skipResion"
                    className="block text-gray-700 font-bold mb-2"
                  >
                    Reason for Skipping
                  </label>
                  <input
                    type="text"
                    id="skipResion"
                    value={skipResion}
                    onChange={(e) => setSkipResion(e.target.value)}
                    className="w-full p-2 border border-gray-300 rounded-md"
                    placeholder="Enter reason"
                  />
                </div>
                <div className="flex justify-end space-x-2">
                  <button
                    type="button"
                    onClick={handleCancel}
                    className="px-4 py-2 bg-gray-400 text-white rounded-md hover:bg-gray-500"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}

        <AcceptInterView
          closeModal={closeModal}
          newAcceptData={newAcceptData}
          acceptModal={acceptModal}
          setNewAcceptData={setNewAcceptData}
          currentPage={currentPage}
          pageSize={pageSize}
          setOldRejectData={setOldRejectData}
          selectedTeachMode={selectedTeachMode}
          setSelectedTeachMode={setSelectedTeachMode}
          selectedPinCode={selectedPinCode}
          setSelectedPinCode={setSelectedPinCode}
          subjectYouCanTeach={subjectYouCanTeach}
          setSubjectYouCanTeach={setSubjectYouCanTeach}
          selectedClass={selectedClass}
          setSelectedClass={setSelectedClass}
        />


        <ImagePreviewModal handleClose={handleCloseFunctionImageModel} showWarning={isModalVisible} image={image} />
        <DetailsPreViewModel isModalOpen={isModalDetails} closeModal={handelDetailsModel} response={fullData} />
        <KycViewModel kycDataShow={kycDataShow} setKycDataShow={setKycDataShow} kycListData={kycListData} />
        <section className="space-y-5 lg:hidden block">
          {newJoinedTutorList && newJoinedTutorList.length > 0 ? (
            newJoinedTutorList?.map((item, index) => (
              <div key={index} className=' bg-white p-4 rounded-[10px]'>
                <>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[16px] font-[700] text-left'>Tutor Name	</p>
                    </div>
                    <div className='w-full'>
                      <div className="flex items-center  gap-2 capitalize">
                        <img
                          className="w-12 h-12 rounded"
                          src={
                            item?.image ??
                            "https://thumbs.dreamstime.com/z/vector-illustration-avatar-dummy-logo-collection-image-icon-stock-isolated-object-set-symbol-web-137160339.jpg?ct=jpeg"
                          }
                          alt="User"
                        />
                        <div>
                          <p className="text-xs font-medium text-black ">{item?.name ?? "No Data"}</p>
                          <p className="text-xs text-gray-500">{item?.mobNo || 'No Number'}</p>
                          <p className="text-xs text-gray-500">
                            {moment(item?.createdAt).format(
                              "MMMM Do YYYY"
                            )}
                          </p>
                          <FaChevronCircleRight
                            className="text-[#023565] cursor-pointer"
                            onClick={() => handelDetailsModel(item)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[16px] font-[700] text-left'>City</p>
                    </div>
                    <div className='w-full'>
                      <p className='text-[14px] font-[400]' > {item?.city?.name ?? "No Data"}</p>
                    </div>
                  </div>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[16px] font-[700] text-left'>Class Selected	</p>
                    </div>
                    <div className='w-full'>
                      <p className='text-[14px] font-[400]'>
                        {Array.isArray(item?.tutorInfoId?.classYouCanTeach) &&
                          item.tutorInfoId.classYouCanTeach.length > 0 ? (
                          <span>
                            {item.tutorInfoId.classYouCanTeach
                              .map((cls, id) => cls?.name)
                              .join(", ")}
                          </span>
                        ) : (
                          <span>N/A</span>
                        )}
                      </p>
                    </div>
                  </div>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[16px] font-[700] text-left'>Subject Selected	</p>
                    </div>
                    <div className='w-full'>
                      <p className='text-[14px] font-[400]' > {Array.isArray(item?.tutorInfoId?.subYouCanTeach) &&
                        item.tutorInfoId.subYouCanTeach.length > 0 ? (
                        <span>
                          {item.tutorInfoId.subYouCanTeach
                            .map((sub) => sub?.name || "N/A")
                            .join(", ")}
                        </span>
                      ) : (
                        <span>N/A</span>
                      )}</p>
                    </div>
                  </div>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[16px] font-[700] text-left'>KYC</p>
                    </div>
                    <div className='w-full'>
                      <p className='text-[14px] font-[800] underline' onClick={() => kycDetailModal(item)}>View</p>
                    </div>
                  </div>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[16px] font-[700] text-left'>Interview</p>
                    </div>
                    <div className='w-full'>
                      <p className='text-[14px] font-[400] relative space-y-1'>
                        <button
                          className="border px-3 py-1 inline-flex items-center gap-2 font-[600] text-[#2C9408] border-[#2C9408] rounded-full"
                          onClick={() => showAccept(item)}
                          type=""
                        >
                          <span>
                            <FaRegCheckCircle size={20} />
                          </span>{" "}
                          Accept
                        </button>{" "}
                        <button
                          className="border px-4 py-1 inline-flex items-center gap-2 font-[600] text-[#E70909] border-[#E70909] rounded-full"
                          type=""
                          onClick={() => handleReject(item)}
                        >
                          <span>
                            <AiOutlineCloseCircle size={20} />
                          </span>{" "}
                          Reject
                        </button>  </p>
                    </div>
                  </div>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[16px] font-[700] text-left'>Action</p>
                    </div>
                    <div className='w-full'>
                      <p className='text-[14px] font-[400] relative space-y-1'>
                        <button
                          className="border px-5 py-1 inline-flex items-center gap-2 font-[600] text-[#2C9408] border-[#2C9408] rounded-full"
                          onClick={() => handleSkiped(item)}
                          type=""
                        >
                          <span>
                            <FiChevronsRight size={20} />
                          </span>{" "}
                          Skip
                        </button>{" "}
                        <br />
                        <button
                          className="border px-4 py-1 inline-flex items-center gap-2 font-[600] text-[#E70909] border-[#E70909] rounded-full"
                          type=""
                          onClick={() => handleRemoveProfile(item)}>
                          <span>
                            <MdDelete size={20} />
                          </span>{" "}
                          Delete
                        </button>

                      </p>
                    </div>
                  </div>
                </>
              </div>

            ))

          ) : (
            <p className="text-center">Data Not Found</p>
          )

          }
        </section>

      </section>
    </>
  );
};



export default TableFirst;
