export const employprofileConstant={
    GET_TEAM_LIST_REQUEST:"GET_TEAM_LIST_REQUEST",
    GET_TEAM_LIST_SUCCESS:"GET_TEAM_LIST_SUCCESS",
    GET_TEAM_LIST_FAILURE:"GET_TEAM_LIST_FAILURE",

    DELETE_TEAM_REQUEST:"DELETE_TEAM_REQUEST",
    DELETE_TEAM_SUCCESS:"DELETE_TEAM_SUCCESS",
    DELETE_TEAM_FAILURE:"DELETE_TEAM_FAILURE",

    CREATE_TEAM_REQUEST:"CREATE_TEAM_REQUEST",
    CREATE_TEAM_SUCCESS:"CREATE_TEAM_SUCCESS",
    CREATE_TEAM_FAILURE:"CREATE_TEAM_FAILURE",

    GET_ALL_ADMIN_LIST_REQUEST:"GET_ALL_ADMIN_LIST_REQUEST",
    GET_ALL_ADMIN_LIST_SUCCESS:"GET_ALL_ADMIN_LIST_SUCCESS",
    GET_ALL_ADMIN_LIST_FAILURE:"GET_ALL_ADMIN_LIST_FAILURE",

    UPDATE_TEAM_STATUS_REQUEST:"UPDATE_TEAM_STATUS_REQUEST",
    UPDATE_TEAM_STATUS_SUCCESS:"UPDATE_TEAM_STATUS_SUCCESS",
    UPDATE_TEAM_STATUS_FAILURE:"UPDATE_TEAM_STATUS_FAILURE",

    UPDATE_TEAM_REQUEST:"UPDATE_TEAM_REQUEST",
    UPDATE_TEAM_SUCCESS:"UPDATE_TEAM_SUCCESS",
    UPDATE_TEAM_FAILURE:"UPDATE_TEAM_FAILURE",


    GET_ALL_EMPLOY_REQUEST:"GET_ALL_EMPLOY_REQUEST",
    GET_ALL_EMPLOY_SUCCESS:"GET_ALL_EMPLOY_SUCCESS",
    GET_ALL_EMPLOY_FAILURE:"GET_ALL_EMPLOY_FAILURE",

    UPDATE_SUBADMIN_STATUS_REQUEST:"UPDATE_SUBADMIN_STATUS_REQUEST",
    UPDATE_SUBADMIN_STATUS_SUCCESS:"UPDATE_SUBADMIN_STATUS_SUCCESS",
    UPDATE_SUBADMIN_STATUS_FAILURE:"UPDATE_SUBADMIN_STATUS_FAILURE",

    UPDATE_SUBADMIN_REQUEST:"UPDATE_SUBADMIN_REQUEST",
    UPDATE_SUBADMIN_SUCCESS:"UPDATE_SUBADMIN_SUCCESS",
    UPDATE_SUBADMIN_FAILURE:"UPDATE_SUBADMIN_FAILURE",

    CREATE_SUBADMIN_REQUEST:"CREATE_SUBADMIN_REQUEST",
    CREATE_SUBADMIN_SUCCESS:"CREATE_SUBADMIN_SUCCESS",
    CREATE_SUBADMIN_FAILURE:"CREATE_SUBADMIN_FAILURE",
    
}