import React from 'react'
import { timeZoneReturnDateType } from '../../../_helpers'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'

function MobileTable({ NotificationData, viewImage, totalPages, pageSize, currentPage, handlePreviousPage, handleNextPage }) {
    return (
        <section className="lg:hidden block  space-y-4">
            {NotificationData && NotificationData.length > 0 ? (
                NotificationData.map((item, id) => (
                    <div className='bg-white border-2 border-[#D3D3D3] rounded-lg'>

                        <div className='flex justify-between p-2 w-full'>
                            <div className='w-full'>
                                <p className='text-[16px] font-[700] text-left'>S.No :</p>
                            </div>
                            <div className='w-full'>
                                <p className='text-[14px] font-[400] text-left'> {(currentPage - 1) * pageSize + id + 1}</p>
                            </div>
                        </div>
                        <div className='flex justify-between p-2 w-full'>
                            <div className='w-full'>
                                <p className='text-[16px] font-[700] text-left'>City :</p>
                            </div>
                            <div className='w-full flex flex-wrap gap-2'>
                                {item?.cityId?.map((cty, id) => (
                                    <p className="border border-[#023565]  text-[#023565] px-1.5 py-0.5 text-[12px] rounded-full" key={id}>{cty?.name ? cty?.name : "No City"}</p>
                                ))}
                            </div>
                        </div>
                        <div className='flex justify-between p-2 w-full'>
                            <div className='w-full'>
                                <p className='text-[16px] font-[700] text-left'>Title :</p>
                            </div>
                            <div className='w-full'>
                                <p className='text-[14px] font-[400] text-left'>{item?.name}</p>
                            </div>
                        </div>
                        <div className='flex justify-between p-2 w-full'>
                            <div className='w-full'>
                                <p className='text-[16px] font-[700] text-left'>Notification Message :</p>
                            </div>
                            <div className='w-full'>
                                <p className='text-[14px] font-[400] text-left'>{item.msg}</p>
                            </div>
                        </div>
                        <div className='flex justify-between p-2 w-full'>
                            <div className='w-full'>
                                <p className='text-[16px] font-[700] text-left'>Image :</p>
                            </div>
                            <div className='w-full'>
                                <p className='text-[14px] font-[400] text-left'><span className={` ${item.image ? "text-[#00AFE2]" : "text-neutral-900"} cursor-pointer`} onClick={item.image ? () => viewImage(item) : null}>
                                    {item.image ? "View" : "N/A"}
                                </span></p>
                            </div>
                        </div>
                        <div className='flex justify-between p-2 w-full'>
                            <div className='w-full'>
                                <p className='text-[16px] font-[700] text-left'>Create Date:</p>
                            </div>
                            <div className='w-full'>
                                <p className='text-[14px] font-[400] text-left'> {timeZoneReturnDateType(item?.createdAt)}</p>
                            </div>
                        </div>

                    </div>

                ))

            ) : (
                <div>
                    <p className=" md:hidden px-6 py-4 text-center">
                        Data Not Available
                    </p>
                </div>
            )
            }


            {totalPages > 1 && (
                <div className="flex justify-end items-center py-2 px-4">
                    <button
                        className={`px-4 py-2 border text-white bg-[#023565] rounded ${currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""}`}
                        onClick={handlePreviousPage}
                        disabled={currentPage === 1}
                    >
                        <FaChevronLeft />
                    </button>
                    <span className="text-gray-700 mx-1">
                        <span className="border px-4 py-2 text-black  rounded">{currentPage}</span> of <span className="border px-4 py-2 text-black  rounded">{totalPages}</span>
                    </span>
                    <button
                        className={`px-4 py-2 border text-white bg-[#023565] rounded ${currentPage === totalPages ? "opacity-50 cursor-not-allowed" : ""}`}
                        onClick={handleNextPage}
                        disabled={currentPage === totalPages}
                    >
                        <FaChevronRight />
                    </button>
                </div>
            )}
        </section>
    )
}

export default MobileTable