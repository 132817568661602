import React from 'react'
import { FaChevronRight } from 'react-icons/fa'
import { MdOutlineKeyboardArrowLeft } from 'react-icons/md'
import { TbUserEdit } from 'react-icons/tb'
import { timeZoneReturnDateType } from '../../../_helpers'

function TableBanner({ banner, handleChange, handleEdit, deleteUser, totalPages, currentPage, handlePreviousPage, handleNextPage }) {
  return (
    <>
      <section className="lg:block hidden">
        <div className="mt-5 border border-[#D3D3D3] rounded-xl bg-white">
          <table className="min-w-full table-autorounded-xl overflow-x-auto">
            <thead className="border-b border-[#D1D1D1]">
              <tr className="text-black">
                <th className="px-6 py-3 whitespace-nowrap text-sm">Name</th>
                <th className="px-6 py-3 whitespace-nowrap text-sm">Image</th>
                <th className="px-6 py-3 whitespace-nowrap text-sm">Date</th>
                <th className="px-6 py-3 whitespace-nowrap text-sm">Type</th>
                <th className="px-6 py-3 whitespace-nowrap text-sm">Status</th>
                <th className="px-6 py-3 whitespace-nowrap text-sm">Update</th>
                <th className="px-6 py-3 whitespace-nowrap text-sm">Delete</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {banner?.bannerGetList?.length > 0 ? (banner?.bannerGetList?.map((item, id) => (
                <tr key={id} className='capitalize'>
                  <td className="px-4 py-4 text-sm text-gray-900 text-center">{item.name}</td>
                  <td className="px-4 py-4 text-sm text-gray-900 text-center">
                    <img className="h-14 w-14 rounded" src={item.image} alt={item.name} />
                  </td>
                  <td className="px-4 py-4 text-sm text-gray-900 text-center">{timeZoneReturnDateType(item?.createdAt)}</td>
                  <td className="px-4 py-4 text-sm text-gray-900 text-center">{item.userType}</td>
                  <td className="px-4 py-4 text-sm text-gray-900 text-center cursor-pointer" title={item.isDisable ? "Enable" : "Disable"}>
                    <label className="inline-flex items-center cursor-pointer" >
                      <input type="checkbox" className="sr-only peer" checked={item.isDisable} onChange={() => handleChange(item._id)} />
                      {item.isDisable ?
                        <div class="relative w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-green-300 dark:peer-focus:ring-green-800 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-600"></div>
                        :
                        <div class="relative w-11 h-6 bg-red-500 rounded-full peer peer-focus:ring-4 peer-focus:ring-red-300 dark:peer-focus:ring-red-800 dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-600"></div>
                      }
                    </label>
                  </td>
                  <td className="px-4 py-4 text-sm text-gray-900 text-center">
                    <button onClick={() => handleEdit(item)}>
                      <TbUserEdit className="text-black text-2xl" />
                    </button>
                  </td>
                  <td className="px-4 py-4 text-sm text-gray-900 text-center ">
                    <button onClick={() => deleteUser(item._id)} className="text-red-600 hover:text-red-900">
                      <img src="/delete.png" className="cursor-pointer mx-auto" alt="" />
                    </button>
                  </td>
                </tr>
              ))
              ) : (
                <tr>
                  <td colSpan={8} className="px-6 py-4 text-base text-[#313131] whitespace-nowrap text-center">Data Not Available</td>
                </tr>
              )}
            </tbody>
          </table>
          {totalPages > 1 && (
            <div className="flex justify-end items-center py-2">
              <button
                className={`px-4 py-2 border text-black rounded ${currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""}`}
                onClick={handlePreviousPage}
                disabled={currentPage === 1}
              >
                <MdOutlineKeyboardArrowLeft />
              </button>
              <span className="text-gray-700 mx-1">
                <span className="border px-4 py-2 text-black rounded">{currentPage}</span> of {totalPages}
              </span>
              <button
                className={`px-4 py-2 border text-black rounded ${currentPage === totalPages ? "opacity-50 cursor-not-allowed" : ""}`}
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
              >
                <FaChevronRight />
              </button>
            </div>
          )}
        </div>
      </section>
    </>
  )
}

export default TableBanner
