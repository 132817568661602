import React from 'react'
import { MdOutlineClose } from 'react-icons/md'

const UpdateFeeRefund = ({ setFeeData, feeData, dateError, setDateError, handleSubmitFeeData, handleCloseFeeData }) => {

    const handleChange = (e) => {
        const { name, value } = e.target;

        setFeeData((prev) => ({
            ...prev,
            [name]: value
        }))

        setDateError((prev) => ({
            ...prev,
            [name]: ''
        }))
    }
    return (
        <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40">
                <div className="relative w-11/12 mx-auto lg:w-[600px]">
                    <button
                        className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center"
                        onClick={handleCloseFeeData}
                    >
                        <span><MdOutlineClose /></span> Close
                    </button>
                    <div className="form_class border-0 rounded-[10px] shadow-lg p-6 relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        <div className="flex items-center justify-between  border-b border-solid border-slate-200 rounded-t">
                            <h2 className="text-lg font-semibold">Refund Fee</h2>
                        </div>
                        <div className="relative overflow-y-auto">
                            <div className="board_name">
                                <label htmlFor="tuitionFeeRefundedPaid" className="block text-sm font-medium text-gray-700">
                                    Tuition Fee Refund Paid:
                                </label>
                                <input
                                    type="number"
                                    name='tuitionFeeRefundedPaid'
                                    value={feeData?.tuitionFeeRefundedPaid === 0 ? feeData?.tuitionFee : feeData?.tuitionFeeRefundedPaid}
                                    onChange={(e) => handleChange(e)}
                                    className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                                    min={0}
                                    max={feeData?.tuitionFee}
                                />
                                {dateError.tuitionFeeRefundedPaid && <p className="text-red-500 text-xs">{dateError.tuitionFeeRefundedPaid}</p>}
                            </div>

                            <div className="board_name">
                                <label htmlFor="tutorFeeRefundedReceived" className="block text-sm font-medium text-gray-700">
                                    Tutor Fee Refund Received:
                                </label>
                                <input
                                    type="number"
                                    name='tutorFeeRefundedReceived'
                                    value={feeData?.tutorFeeRefundedReceived === 0 ? feeData?.tutorFee : feeData?.tutorFeeRefundedReceived}
                                    onChange={(e) => handleChange(e)}
                                    className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                                    min={0}
                                    max={feeData?.tutorFee}
                                />
                                {dateError.tutorFeeRefundedReceived && <p className="text-red-500 text-xs">{dateError.tutorFeeRefundedReceived}</p>}

                            </div>
                            <div className="board_name">
                                <label htmlFor="referenceFeeRefundedReceived" className="block text-sm font-medium text-gray-700">
                                    Reference Fee Refund Received:
                                </label>
                                <input
                                    type="number"
                                    name='referenceFeeRefundedReceived'
                                    value={feeData?.referenceFeeRefundedReceived === 0 ? feeData?.referenceFee : feeData?.referenceFeeRefundedReceived}
                                    onChange={(e) => handleChange(e)}
                                    className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                                    min={0}
                                    max={feeData?.referenceFee}
                                />
                                {dateError.referenceFeeRefundedReceived && <p className="text-red-500 text-xs">{dateError.referenceFeeRefundedReceived}</p>}
                            </div>
                            <div className="flex justify-center items-center pt-6">
                                <button
                                    className="w-60 mx-auto bg-[#023565] text-white font-semibold py-2 px-4 rounded-full "
                                    onClick={handleSubmitFeeData}
                                >
                                    Submit
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </>
    )
}

export default UpdateFeeRefund