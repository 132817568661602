import { referralConstants, classConstants } from "../_constants";
export default function Referral(state = {}, action) {
  switch (action.type) {
    case referralConstants.GET_REFERRAL_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case referralConstants.GET_REFERRAL_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        referralList: action?.users?.data,
        referralTotal: action?.users?.data?.total,
      };

    case referralConstants.GET_REFERRAL_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case referralConstants.DELETE_REFERRAL_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case referralConstants.DELETE_REFERRAL_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case referralConstants.DELETE_REFERRAL_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case referralConstants.CREATE_REFERRAL_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case referralConstants.CREATE_REFERRAL_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        createReferral: action.users,
      };

    case referralConstants.CREATE_REFERRAL_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case referralConstants.UPDATE_REFERRAL_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case referralConstants.UPDATE_REFERRAL_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case referralConstants.UPDATE_REFERRAL_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case referralConstants.ENA_DIS_REFERRAL_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case referralConstants.ENA_DIS_REFERRAL_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case referralConstants.ENA_DIS_REFERRAL_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case classConstants.GET_CLASS_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case classConstants.GET_CLASS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        classList: action?.users?.data?.list,
      };
    case classConstants.GET_CLASS_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case classConstants.CREATE_CLASS_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case classConstants.CREATE_CLASS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        classCreate: action?.users,
      };
    case classConstants.CREATE_CLASS_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case classConstants.DELETE_CLASS_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case classConstants.DELETE_CLASS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case classConstants.DELETE_CLASS_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case classConstants.UPDATE_CLASS_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case classConstants.UPDATE_CLASS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case classConstants.UPDATE_CLASS_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case classConstants.ENA_DIS_CLASS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case classConstants.ENA_DIS_CLASS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case classConstants.ENA_DIS_CLASS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    default:
      return state;
  }
}
