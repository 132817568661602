import { SubjectConstants } from "../_constants/subject.constants";

export default function Subject(state = {}, action) {
  

  switch (action.type) {
    case SubjectConstants.GET_SUBJECT_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SubjectConstants.GET_SUBJECT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        SubjectList: action?.users?.data?.list,
        SubjectTotal: action?.users?.data?.total,
      };


    case SubjectConstants.GET_SUBJECT_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case SubjectConstants.CREATE_SUBJECT_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SubjectConstants.CREATE_SUBJECT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        subjectCreate: action?.users,
      };
    case SubjectConstants.CREATE_SUBJECT_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case SubjectConstants.DELETE_SUBJECT_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SubjectConstants.DELETE_SUBJECT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case SubjectConstants.DELETE_SUBJECT_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case SubjectConstants.UPDATE_SUBJECT_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SubjectConstants.UPDATE_SUBJECT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case SubjectConstants.UPDATE_SUBJECT_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case SubjectConstants.ENA_DIS_SUBJECT_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SubjectConstants.ENA_DIS_SUBJECT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case SubjectConstants.ENA_DIS_SUBJECT_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };


      
      

    default:
       return state;
  }
}
