export const referralConstants={
    GET_REFERRAL_LIST_REQUEST:"GET_REFERRAL_LIST_REQUEST",
    GET_REFERRAL_LIST_SUCCESS:"GET_REFERRAL_LIST_SUCCESS",
    GET_REFERRAL_LIST_FAILURE:"GET_REFERRAL_LIST_FAILURE",

    CREATE_REFERRAL_LIST_REQUEST:"CREATE_REFERRAL_LIST_REQUEST",
    CREATE_REFERRAL_LIST_SUCCESS:"CREATE_REFERRAL_LIST_SUCCESS",
    CREATE_REFERRAL_LIST_FAILURE:"CREATE_REFERRAL_LIST_FAILURE",

    UPDATE_REFERRAL_LIST_REQUEST:"UPDATE_REFERRAL_LIST_REQUEST",
    UPDATE_REFERRAL_LIST_SUCCESS:"UPDATE_REFERRAL_LIST_SUCCESS",
    UPDATE_REFERRAL_LIST_FAILURE:"UPDATE_REFERRAL_LIST_FAILURE",

    DELETE_REFERRAL_LIST_REQUEST:"DELETE_REFERRAL_LIST_REQUEST",
    DELETE_REFERRAL_LIST_SUCCESS:"DELETE_REFERRAL_LIST_SUCCESS",
    DELETE_REFERRAL_LIST_FAILURE:"DELETE_REFERRAL_LIST_FAILURE",

    ENA_DIS_REFERRAL_LIST_REQUEST:"ENA_DIS_REFERRAL_LIST_REQUEST",
    ENA_DIS_REFERRAL_LIST_SUCCESS:"ENA_DIS_REFERRAL_LIST_SUCCESS",
    ENA_DIS_REFERRAL_LIST_FAILURE:"ENA_DIS_REFERRAL_LIST_FAILURE",
}