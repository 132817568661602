import { roleConstants } from "../_constants/role.constants";

export default function Roles(state = {}, action) {
  switch (action.type) {
    case roleConstants.GET_ROLE_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case roleConstants.GET_ROLE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        roleList: action?.users,
      };
    case roleConstants.GET_ROLE_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case roleConstants.DELETE_ROLE_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case roleConstants.DELETE_ROLE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case roleConstants.DELETE_ROLE_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case roleConstants.CREATE_ROLE_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case roleConstants.CREATE_ROLE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case roleConstants.CREATE_ROLE_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

      case roleConstants.UPDATE_ROLE_LIST_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case roleConstants.UPDATE_ROLE_LIST_SUCCESS:
        return {
          ...state,
          loading: false,
        };
      case roleConstants.UPDATE_ROLE_LIST_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.error,
        };
    default:
      return state;
  }
}
