export const bannerConstants = {
  BANNER_CREATE_REQUEST:"BANNER_CREATE_REQUEST",
  BANNER_CREATE_SUCCESS:"BANNER_CREATE_SUCCESS",
  BANNER_CREATE_FAILURE:"BANNER_CREATE_FAILURE",
  BANNER_GET_REQUEST:"BANNER_GET_REQUEST",
  BANNER_GET_SUCCESS:"BANNER_GET_SUCCESS",
  BANNER_GET_FAILURE:"BANNER_GET_FAILURE",
  BANNER_DELETE_REQUEST:"BANNER_DELETE_REQUEST",
  BANNER_DELETE_SUCCESS:"BANNER_DELETE_SUCCESS",
  BANNER_DELETE_FAILURE:"BANNER_DELETE_FAILURE",
  BANNER_UPDATE_REQUEST:"BANNER_UPDATE_REQUEST",
  BANNER_UPDATE_SUCCESS:"BANNER_UPDATE_SUCCESS",
  BANNER_UPDATE_FAILURE:"BANNER_UPDATE_FAILURE",
  BANNER_ENA_DIS_REQUEST:"BANNER_ENA_DIS_REQUEST",
  BANNER_ENA_DIS_SUCCESS:"BANNER_ENA_DIS_SUCCESS",
  BANNER_ENA_DIS_FAILURE:"BANNER_ENA_DIS_FAILURE",
  



};
