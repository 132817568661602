import React from 'react'

const Button = ({ name, onclick, value = 0 }) => {
  return (
    <div>
      {name !== "New Leads" ?
        <button className='px-3 text-center font-bold capitalize' onClick={onclick}>{name} {value ? `(${value})` : null}</button>
        : <button className='px-3 text-center font-bold capitalize' onClick={onclick}>{name} {value ? `(${value})` : null}</button>
      }
    </div >
  )
}

export default Button