/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { packageAction } from '../../_actions'
import { FaAngleRight, FaChevronLeft, FaEdit } from 'react-icons/fa'
import { MdOutlineKeyboardArrowLeft, MdOutlineClose } from 'react-icons/md'
import { FaChevronRight, FaPlus } from 'react-icons/fa'
import Button from './Button'
import { IoMdAdd } from 'react-icons/io'
import { confirmAlert } from 'react-confirm-alert'
import Loader from '../../components/Loader'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { FaDeleteLeft } from 'react-icons/fa6'
import { RiDeleteBin5Fill } from 'react-icons/ri'

const Package = () => {
  const dispatch = useDispatch()
  const selector = useSelector(state => state)
  let [packageList, setPackageList] = useState()

  const [packageId, setPackageId] = useState(null)
  const [modal, setModal] = useState(false)
  const [editModal, setEditModal] = useState(false)

  const [packageName, setPackageName] = useState('')
  const [amount, setAmount] = useState('')
  const [session, setSession] = useState('')
  const [cityId, setCityID] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const pageSize = 10
  const [ediInput, setEditInput] = useState({
    id: '',
    name: '',
    amount: '',
    session: '',
    cityId: ''
  })

  const [errors, setErrors] = useState({
    packageName: '',
    amount: '',
    session: ''
  })

  useEffect(() => {
    let packageList = {
      keyWord: '',
      fromDate: '',
      toDate: '',
      sortOrders: 'asc',
      sortBy: 'createdAt',
      pageNo: currentPage,
      size: pageSize
    }
    dispatch(packageAction.packageGet(packageList))
  }, [currentPage])

  useEffect(() => {
    if (selector?.Package?.packageList?.data) {
      setTotalPages(Math.round(selector?.Package?.packageList?.data / pageSize))
    }
    setPackageList(selector?.Package?.packageList?.data?.list)
  }, [selector])

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1)
  }

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1)
  }

  const menuRef = useRef(null)

  const handleClickOutside = event => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setPackageId(null)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const openCreatePackageModal = () => {
    setModal(true)
  }

  const handleDelete = data => {
    const packageList = {
      keyWord: '',
      fromDate: '',
      toDate: '',
      sortOrders: 'desc',
      sortBy: 'createdAt',
      pageNo: 1,
      size: 10
    }

    const id = {
      id: data
    }

    confirmAlert({
      title: 'Confirm to Delete',
      message: 'Are you sure you want to delete?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => dispatch(packageAction.deletePackage(id, packageList))
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    })
  }

  const handlePackageCreate = () => {
    let valid = true
    let newErrors = { packageName: '', amount: '', session: '' }

    if (!packageName) {
      newErrors.packageName = 'Package name is required'
      valid = false
    }
    if (!amount) {
      newErrors.amount = 'Amount is required'
      valid = false
    }
    if (!session) {
      newErrors.session = 'Session is required'
      valid = false
    }

    setErrors(newErrors)

    if (!valid) return
    let packageList = {
      keyWord: '',
      fromDate: '',
      toDate: '',
      sortOrders: 'desc',
      sortBy: 'createdAt',
      pageNo: currentPage,
      size: pageSize
    }

    let packageCreateData = {
      name: packageName,
      amount: amount,
      session: session
      // cityId: [cityId],
    }
    dispatch(packageAction.packageCreate(packageCreateData, packageList))
    setModal(false)
    setPackageName('')
    setAmount('')
    setSession('')
    setCityID('')
  }

  const handleAction = data => {
    let packageList = {
      keyWord: '',
      fromDate: '',
      toDate: '',
      sortOrders: 'desc',
      sortBy: 'createdAt',
      pageNo: 1,
      size: 10
    }
    let enadisData = {
      id: data._id
    }

    confirmAlert({
      title: `Confirm to ${data?.isDisable === false ? 'Inactive' : 'Active'}?`,
      // message: 'Are you sure you want to delete?',
      buttons: [
        {
          label: 'Yes',
          onClick: () =>
            dispatch(packageAction.enadisPackage(enadisData, packageList))
        },
        {
          label: 'No',
          onClick: () => {} // Optionally handle the 'No' click
        }
      ]
    })
  }
  const handle = e => {
    e.preventDefault()
    setCityID(e.target.value)
  }

  const handleUpdate = data => {
    setEditModal(true)
    setEditInput(data)
  }

  const handleChnage = e => {
    const { name, value } = e.target
    setEditInput({ ...ediInput, [name]: value })
  }

  const handlePackageEdit = () => {
    let packageList = {
      keyWord: '',
      fromDate: '',
      toDate: '',
      sortOrders: 'desc',
      sortBy: 'createdAt',
      pageNo: currentPage,
      size: pageSize
    }
    let editData = {
      id: ediInput._id,

      name: ediInput.name,
      amount: ediInput.amount,
      session: ediInput.session
    }
    dispatch(packageAction.updatePackage(editData, packageList))
    setEditModal(false)
  }

  const closeModal = () => {
    setModal(false)
    setPackageName('')
    setAmount('')
    setSession('')
    setErrors(prevErrors => ({
      ...prevErrors,
      packageName: ''
    }))
    setErrors(prevErrors => ({
      ...prevErrors,
      amount: ''
    }))
    setErrors(prevErrors => ({
      ...prevErrors,
      session: ''
    }))
  }

  const handleMenuAction = data => {
    setPackageId(prevdata => (prevdata === data ? null : data))
  }
  return (
    <>
      <Loader loading={selector?.Package?.loading} />

      <div className='m-5'>
        <div className='lg:flex hidden justify-end place-items-center right-10 -mt-16'>
          <div
            className='border  bg-white rounded font-semibold border-solid text-[#C60E6B] py-2 border-[#D9D9D9] flex px-3  place-items-center'
            onClick={() => openCreatePackageModal()}
          >
            <div className=' mr-2 '>
              <FaPlus className='cursor-pointer' />
            </div>
            <Button className='text-[#C60E6B]' name={'New Package'} />
          </div>
        </div>
      <div className='flex lg:hidden justify-end -mt-14 pb-2'>  
      <button
          className='w-10 h-10 flex justify-center items-center font-bold text-[#C60E6B] border bg-white rounded-full'
          onClick={() => openCreatePackageModal()}
        >
        
            <IoMdAdd className='text-xl mx-1' />
        </button>
      </div>

        <section className='lg:block hidden'>
          <div className='mt-5 border border-[#D3D3D3] rounded-xl bg-white'>
            <table className='min-w-full table-autorounded-xl overflow-x-auto'>
              <thead className='border-b border-[#D1D1D1]'>
                <tr className='text-black'>
                  <th class='px-6 py-3 whitespace-nowrap text-sm'>S No</th>
                  <th class='px-6 py-3 whitespace-nowrap text-sm'>Name</th>
                  <th class='px-6 py-3 whitespace-nowrap text-sm'>Amount</th>
                  <th class='px-6 py-3 whitespace-nowrap text-sm'>Session</th>
                  <th class='px-6 py-3 whitespace-nowrap text-sm'>Status</th>
                  <th class='px-6 py-3 whitespace-nowrap text-sm'>Action</th>
                </tr>
              </thead>
              <tbody>
                {packageList && packageList.length > 0 ? (
                  packageList?.map((item, id) => {
                    return (
                      <>
                        <tr class='bg-white border-b capitalize' key={id}>
                          <th class='px-4 py-2 text-sm text-gray-900 text-center'>
                            {id + 1}
                          </th>
                          <td class='px-4 py-2 text-sm text-gray-900 text-center whitespace-nowrap'>
                            {item.name}
                          </td>
                          <td class='px-4 py-2 text-sm text-gray-900 text-center'>
                            {item.amount}
                          </td>
                          <td class='px-4 py-2 text-sm text-gray-900 text-center'>
                            {item.session}
                          </td>
                          <td
                            className={`px-4 py-2 text-sm text-gray-900 text-center font-semibold cursor-pointer ${
                              item.isDisable ? 'text-red-600' : 'text-green-600'
                            }`}
                            onClick={() => handleAction(item)}
                          >
                            {item.isDisable ? 'Inactive' : 'Active'}
                          </td>
                          <td className='px-4 py-2 text-gray-900'>
                            <span className='flex justify-center gap-2 items-center'>
                              <FaEdit
                                onClick={() => handleUpdate(item)}
                                className='text-2xl cursor-pointer text-[#023565] '
                              />
                              <RiDeleteBin5Fill  onClick={() => handleDelete(item._id)} className='text-2xl cursor-pointer text-red-500 '/>
                            </span>
                          
                          </td>
                        </tr>
                      </>
                    )
                  })
                ) : (
                  <tr>
                    <td colSpan='7' className='text-center'>
                      Data Not Available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </section>

        <section className='lg:hidden block  space-y-5'>
          {packageList && packageList.length > 0 ? (
            packageList?.map((item, id) => {
              return (
                <div className=' bg-white p-4 rounded-[10px] border-[#023565] border'>
                  <>
                    <div className='flex justify-between p-2 w-full'>
                      <div className='w-full'>
                        <p className='text-[16px] font-[700] text-left'>S No</p>
                      </div>
                      <div className='w-full'>
                        <p className='text-[14px] font-[400]'>{id + 1}</p>
                      </div>
                    </div>
                    <div className='flex justify-between p-2 w-full'>
                      <div className='w-full'>
                        <p className='text-[16px] font-[700] text-left'>Name</p>
                      </div>
                      <div className='w-full'>
                        <p className='text-[14px] font-[400]'>{item.name}</p>
                      </div>
                    </div>
                    <div className='flex justify-between p-2 w-full'>
                      <div className='w-full'>
                        <p className='text-[16px] font-[700] text-left'>
                          Amount
                        </p>
                      </div>
                      <div className='w-full'>
                        <p className='text-[14px] font-[400]'> {item.amount}</p>
                      </div>
                    </div>
                    <div className='flex justify-between p-2 w-full'>
                      <div className='w-full'>
                        <p className='text-[16px] font-[700] text-left'>
                          Session{' '}
                        </p>
                      </div>
                      <div className='w-full'>
                        <p className='text-[14px] font-[400]'>{item.session}</p>
                      </div>
                    </div>
                    <div className='flex justify-between p-2 w-full'>
                      <div className='w-full'>
                        <p className='text-[16px] font-[700] text-left'>
                          Status{' '}
                        </p>
                      </div>
                      <div className='w-full'>
                        <p className='text-[14px] font-[400]'>
                          {' '}
                          <button
                            className='font-bold rounded-md'
                            onClick={() => handleAction(item)}
                            style={{
                              color: item.isDisable ? 'red' : '#00AFE2'
                            }}
                          >
                            {item.isDisable ? 'Inactive' : 'Active'}
                          </button>
                        </p>
                      </div>
                    </div>
                    <div className='flex justify-between p-2 w-full'>
                      <div className='w-full'>
                        <p className='text-[16px] font-[700] text-left'>
                          Action
                        </p>
                      </div>
                      <div className='w-full'>
                        <ul className='flex gap-2  items-center '>
                          <li
                            className='cursor-pointer'
                           
                          >
                             <FaEdit
                                onClick={() => handleUpdate(item)}
                                className='text-2xl cursor-pointer text-[#023565] '
                              />
                          </li>
                          <li
                            className='cursor-pointer'
                          >
                            <RiDeleteBin5Fill  onClick={() => handleDelete(item._id)} className='text-2xl cursor-pointer text-red-500 '/>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </>
                </div>
              )
            })
          ) : (
            <div className=' bg-white p-4 rounded-[10px] border-[#023565] border'>
              <p className='text-center'>Data Not Available</p>
            </div>
          )}
        </section>

        {totalPages > 1 && (
          <div className='flex justify-end items-center py-2 px-4'>
            <button
              className={`px-4 py-2 border text-white bg-[#023565] rounded ${
                currentPage === 1 ? 'opacity-50 cursor-not-allowed' : ''
              }`}
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
            >
              <FaChevronLeft />
            </button>
            <span className='text-gray-700 mx-1'>
              <span className='border px-4 py-2 text-black  rounded'>
                {currentPage}
              </span>{' '}
              of{' '}
              <span className='border px-4 py-2 text-black  rounded'>
                {totalPages}
              </span>
            </span>
            <button
              className={`px-4 py-2 border text-white bg-[#023565] rounded ${
                currentPage === totalPages
                  ? 'opacity-50 cursor-not-allowed'
                  : ''
              }`}
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              <FaChevronRight />
            </button>
          </div>
        )}

        {modal && (
          <>
            <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40'>
              <div className='relative w-11/12 mx-auto lg:w-[600px]'>
                <button
                  className='z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center'
                  onClick={closeModal}
                >
                  <span>
                    <MdOutlineClose />
                  </span>{' '}
                  Close
                </button>
                <div className='form_class border-0 rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
                  <div className='flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t'>
                    <h2 className='text-lg font-semibold'>Create Package</h2>
                  </div>
                  <div className='relative lg:h-[340px] overflow-y-auto'>
                    <div className='p-4 space-y-2'>
                      <div className='space-y-2'>
                        <label>Package Name</label>
                        <input
                          type='text'
                          value={packageName}
                          onChange={e => {
                            setPackageName(e.target.value)
                            setErrors(prevErrors => ({
                              ...prevErrors,
                              packageName: ''
                            }))
                          }}
                          className='mt-1 p-2 border block w-full border-gray-300 rounded-md'
                          placeholder=''
                          required
                        />
                        {errors.packageName && (
                          <p className='text-red-500 text-xs mt-1'>
                            {errors.packageName}
                          </p>
                        )}
                      </div>
                      <div className='space-y-2'>
                        <label>Amount</label>
                        <input
                          type='number'
                          value={amount}
                          onChange={e => {
                            setAmount(e.target.value)
                            setErrors(prevErrors => ({
                              ...prevErrors,
                              amount: ''
                            }))
                          }}
                          className='mt-1 p-2 border block w-full border-gray-300 rounded-md'
                          placeholder=''
                          required
                        />
                        {errors.amount && (
                          <p className='text-red-500 text-xs mt-1'>
                            {errors.amount}
                          </p>
                        )}
                      </div>
                      <div className='space-y-2'>
                        <label>Session</label>
                        <input
                          type='number'
                          value={session}
                          onChange={e => {
                            setSession(e.target.value)
                            setErrors(prevErrors => ({
                              ...prevErrors,
                              session: ''
                            }))
                          }}
                          className='mt-1 p-2 border block w-full border-gray-300 rounded-md'
                          placeholder=''
                          required
                        />
                        {errors.session && (
                          <p className='text-red-500 text-xs mt-1'>
                            {errors.session}
                          </p>
                        )}
                      </div>
                      <div className='flex justify-center items-center pt-2'>
                        <button
                          onClick={handlePackageCreate}
                          className='w-60 mx-auto bg-[#023565] text-white font-semibold py-2 px-4 rounded-full '
                        >
                          Create
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {editModal && (
          <>
            <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40'>
              <div className='relative w-11/12 mx-auto lg:w-[600px]'>
                <button
                  className='z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center'
                  onClick={() => setEditModal(false)}
                >
                  <span>
                    <MdOutlineClose />
                  </span>{' '}
                  Close
                </button>
                <div className='form_class border-0 rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
                  <div className='flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t'>
                    <h2 className='text-lg font-semibold'>Update Package</h2>
                  </div>
                  <div className='relative lg:h-[340px] overflow-y-auto'>
                    <div className='p-4 space-y-2'>
                      <div className='space-y-2'>
                        <label>Package Name</label>
                        <input
                          type='text'
                          name='name'
                          value={ediInput?.name}
                          onChange={handleChnage}
                          className='mt-1 p-2 border block w-full border-gray-300 rounded-md'
                          placeholder='Enter pincode'
                        />
                      </div>
                      <div className='space-y-2'>
                        <label>Amount</label>
                        <input
                          type='text'
                          name='amount'
                          value={ediInput?.amount}
                          onChange={handleChnage}
                          className='mt-1 p-2 border block w-full border-gray-300 rounded-md'
                          placeholder=''
                        />
                      </div>
                      <div className='space-y-2'>
                        <label>Session</label>
                        <input
                          type='text'
                          name='session'
                          value={ediInput?.session}
                          onChange={handleChnage}
                          className='mt-1 p-2 border block w-full border-gray-300 rounded-md'
                          placeholder=''
                        />
                      </div>
                      <div className='flex justify-center items-center pt-2'>
                        <button
                          onClick={handlePackageEdit}
                          className='w-60 mx-auto bg-[#023565] text-white font-semibold py-2 px-4 rounded-full '
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

       
      </div>
    </>
  )
}

export default Package
