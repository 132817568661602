/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import Button from './Button'
import { useDispatch } from 'react-redux'
import ActiveTutor from './ActiveTutor'
import InActiveTutor from './InActiveTutor'
import BlockedTutors from './BlockedTutors'
import TutorHeader from './TutorHeader'
import { userListAction } from '../../_actions'
import DeletedTutors from './DeletedTutors'

const TutorProfile = () => {
  const roleId = JSON.parse(window.sessionStorage.getItem("adminuser"))?.roleAccess?.roleId
  const adminCity = JSON.parse(window.sessionStorage.getItem("adminuser"))?.city
  const dispatch = useDispatch()
  const [active, setActive] = useState("Active Tutors")
  const buttonName = ["Active Tutors", "Inactive Tutors", "Blocked Tutors", "Deleted Tutors"]

  const handleClick = (data) => {
    setActive(data)
  }

  const [selectedCities, setSelectedCities] = useState(roleId !== 1 ? adminCity && adminCity.length ? adminCity[0] : "" : "")
  const currentPage = 1;
  const pageSize = 10;

  const [searchText, setSearchText] = useState()
  const [counts, setCounts] = useState({ "active": 0, "inActive": 0, "blocked": 0, "deleted": 0 })




  useEffect(() => {
    let details = {
      "cityId": "",
      "userType": "TUTOR",
      "isDisable": false
    }
    dispatch(userListAction.getUserTypeList(details))
  }, [])

  // const onSelect = (selectedList) => {
  //   setSelectCity(selectedList)

  // }


  function ParamDataSendFunc(active) {
    switch (active) {
      case "Active Tutors":
        return {
          status: "ACTIVE",
          userType: "TUTOR",
          fromDate: "",
          toDate: "",
          cityId: selectedCities ? [selectedCities] : "",
          sortOrder: "desc",
          sortBy: "createdAt",
          pageNo: currentPage,
          size: pageSize,
        }
      case "Inactive Tutors":
        return {
          status: "INACTIVE",
          userType: "TUTOR",
          fromDate: "",
          cityId: selectedCities ? [selectedCities] : "",
          toDate: "",
          sortOrder: "desc",
          sortBy: "createdAt",
          pageNo: currentPage,
          size: pageSize,
        }
      case "Blocked Tutors":
        return {
          status: "BLOCK",
          userType: "TUTOR",
          cityId: selectedCities ? [selectedCities] : "",
          fromDate: "",
          toDate: "",
          sortOrder: "desc",
          sortBy: "createdAt",
          pageNo: currentPage,
          size: pageSize,
        }
      default:
        return {}
    }
  }


  const handleSearch = (e) => {
    setSearchText(e.target.value)

    dispatch(userListAction.getUserList({ ...ParamDataSendFunc(active), keyWord: e.target.value }));
  }
  const getValue = (label) => {
    switch (label) {
      case "Active Tutors":
        return counts.active || 0;
      case "Inactive Tutors":
        return counts.inActive || 0;
      case "Blocked Tutors":
        return counts.blocked || 0;
      case "Deleted Tutors":
        return counts.deleted || 0;
      default:
        return 0; // Default value if the category is not found
    }
  }

  useEffect(() => {
    userListAction.getTutorCountByPromise({ cityId: selectedCities !== "" ? [selectedCities] : [], isManagement: true })
      .then(res => {
        setCounts(res.data)
      })
      .catch(error => setCounts({ "active": 0, "inActive": 0, "blocked": 0, "deleted": 0 }))
  }, [selectedCities])

  return (
    <>
      <TutorHeader searchText={searchText} handleSearch={handleSearch} selectedCities={selectedCities} setSelectedCities={setSelectedCities} roleId={roleId} />

      <section className='overflow-hidden overflow-y-auto h-screen'>
        <div className="m-5">
          <section className='flex flex-wrap gap-3'>

            {
              buttonName.map((item, id) => (
                <div className={`px-3 py-2 rounded-full text-sm  font-semibold ${active === item ? 'bg-[#023565] text-white' : 'bg-gray-400 text-white'}`} onClick={() => handleClick(item)}>
                  <Button key={id} name={item} value={getValue(item)} />
                </div>
              ))
            }
          </section>
          <section>
            {active === "Active Tutors" && <ActiveTutor selectedCities={selectedCities} />}
            {active === "Inactive Tutors" && <InActiveTutor selectedCities={selectedCities} />}
            {active === "Blocked Tutors" && <BlockedTutors selectedCities={selectedCities} />}
            {active === "Deleted Tutors" && <DeletedTutors selectedCities={selectedCities} />}
          </section>
        </div>
      </section>
    </>
  )
}

export default TutorProfile