import { combineReducers } from 'redux';
import usersReducer from './users.reducer';
import alertReducer from './alert.reducer';
import bannerReducer from './banner.reducer';
import cityReducer from './city.reducer'
import subjectReducer from './subject.reducer'
import boardReducer from './board.reducer'
import classReducer from './class.reducer'
import holidaysReducer from './holiday.reducer';
import pincodeReducer from './pincode.reducers'
import roleReducer from './role.reducer'
import allModuleReducer from './allModule.reducer'
import attendanceReducer from './attendance.reducer';
import assignmentReducer from './assignment.reducer';
import ticketReducer from './ticket.reducers'
import packageReducers from './package.reducer'
import uploadImage from './imageUpload.reducer'
import userListReducer from './userList.reducer'
import feedbackReducer from './feedabck.reducer'
import positiveNegativeReducer from './positiveNegativeFeedback.reducer'
import tutionReducer from './tution.reducer'
import termsAndConditionReducer from './termsAndCondition.reducer'
import notificationReducer from './notification.reducer'
import walletReducer from './wallet.reducer'
import feeReducer from './fee.reducer'
import dashboardReducer from './dashboard.reducer';
import parentReducer from './parent.reducer'
import tutorToLiveReducer from './tutortolive.reducer'
import feeListReducer from './feelist.reducer'
import myprofileReducer from './myprofile.reducer'
import employprofileReducer from './employprofile.reducer'
import appSetting from './appSetting.reducer';
import referral from './referral.reducer';
import userPackage from './userPackage.reducer';

const rootReducer = combineReducers({
  users: usersReducer,
  alert: alertReducer,
  banner: bannerReducer,
  Citys: cityReducer,
  Subject: subjectReducer,
  Board: boardReducer,
  Class: classReducer,
  Holidays: holidaysReducer,
  Pincode: pincodeReducer,
  Roles: roleReducer,
  allModule: allModuleReducer,
  attendance: attendanceReducer,
  assignment: assignmentReducer,
  Ticket: ticketReducer,
  Package: packageReducers,
  ImageUpload: uploadImage,
  userList: userListReducer,
  Feedback: feedbackReducer,
  PositiveNegative: positiveNegativeReducer,
  tution: tutionReducer,
  TermsAndCondition: termsAndConditionReducer,
  notificationList: notificationReducer,
  Wallet: walletReducer,
  Fee: feeReducer,
  Dashboard: dashboardReducer,
  Parent: parentReducer,
  TutorToLive: tutorToLiveReducer,
  FeeList: feeListReducer,
  MyProfile: myprofileReducer,
  EmplyProfile: employprofileReducer,
  AppSetting: appSetting,
  Referral: referral,
  userPackage: userPackage,
});

export default rootReducer;
