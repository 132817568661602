/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Button from "./Button";
import { tutionAction, userListAction } from "../../_actions";
import ButtonFirstTable from "./ButtonFirstTable";
import ButtonSecondTable from "./ButtonSecondTable";
import ButtonThirdTable from "./ButtonThirdTable";
import './tution.css'
import { useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import TuitionCbHeaders from "./TuitionCbHeaders";

const Tuition = () => {

  const dispatch = useDispatch();
  const parms = useParams()
  const [searchParams] = useSearchParams();
  const tab = searchParams.get('tab');
  const selector = useSelector(state => state)
  const [tuitionCount, setTuitionCount] = useState()
  const [active, showActive] = useState("Confirmed Requirements");

  useEffect(() => {
    dispatch(tutionAction.getTuitionCount())

    tab ? showActive(tab) : parms["*"] == "tution" ? showActive("Confirmed Requirements") : showActive("Confirmed Requirements")

  }, [parms])

  useEffect(() => {
    setTuitionCount(() => selector?.tution?.getTuitionCount?.data)
  }, [selector])



  const handleClick = (data) => {
    showActive(data);
    setSelectCity([])
    setFilterData([])
  };





  const buttonName = [
    "Confirmed Requirements",
    "Pending Requirements",
    "Rejected Requirements"
  ];



  function valueReturnData(data) {
    switch (data) {
      case "Confirmed Requirements":
        return 0;
      case "Pending Requirements":
        return 0;
      case "Rejected Requirements":
        return 0;
      default:
        return null;
    }
  }
  const [selectedCities, setSelectCity] = useState([])
  const [filterData, setFilterData] = useState([])
  const [selectedData, setSelectedData] = useState('')



  const onSelect = (selectedList) => {
    setSelectCity(selectedList)
  }

  useEffect(() => {
    let details = {
      "cityId": "",
      "isManagement": false,
      "userType": "PARENT",
      "isDisable": false
    }
    dispatch(userListAction.getUserTypeList(details))
  }, [])

  useEffect(() => {
    setSelectedData(() => selector?.userList?.getUserListForSearch?.data)
  }, [selector])
  const onRemove = (selectedList) => {
    setSelectCity(selectedList)
  }


  const options = selectedData ? selectedData.map((ele) => ({
    id: ele?._id,
    name: ele?.name,
    mob: ele?.mobNo,
    display: `${ele?.name} - ${ele?.mobNo}`
  })) : [];


  const searchHandle = () => {
    let status
    if (active == "Confirmed Requirements") status = [9]
    if (active == "Pending Requirements") status = [0, 1, 3]
    if (active == "Rejected Requirements") status = [2]
    const userData = (selectedCities && selectedCities.length > 0)
      ? selectedCities.map((ele) => ele.id)
      : [];
    setFilterData(userData)

    if (userData) {
      let listData = {
        "status": status,
        "parentId": userData,
        "keyWord": "",
        "fromDate": "",
        "toDate": "",
        "sortOrder": "",
        "sortBy": "",
        pageNo: 1,
        size: 10
      }
      dispatch(tutionAction.getCreditBaseDemoClassListByStatus(listData))
    }
  }


  const handlePreviousPage = () => {
    let status
    if (active == "Confirmed Requirements") status = [9]
    if (active == "Pending Requirements") status = [0, 1, 3]
    if (active == "Rejected Requirements") status = [2]
    const userData = (selectedCities && selectedCities.length > 0)
      ? selectedCities.map((ele) => ele.id)
      : [];
    let listData = {
      "status": status,
      "parentId": filterData ?? null,
      "keyWord": "",
      "fromDate": "",
      "toDate": "",
      "sortOrder": "",
      "sortBy": "",
      pageNo: 1 - 1,
      size: 10,
    }
    dispatch(tutionAction.getCreditBaseDemoClassListByStatus(listData))
  };

  const handleNextPage = () => {
    let status
    if (active == "Confirmed Requirements") status = [9]
    if (active == "Pending Requirements") status = [0, 1, 3]
    if (active == "Rejected Requirements") status = [2]
    const userData = (selectedCities && selectedCities.length > 0)
      ? selectedCities.map((ele) => ele.id)
      : [];
    let listData = {
      "status": status,
      "parentId": filterData ?? null,
      "keyWord": "",
      "fromDate": "",
      "toDate": "",
      "sortOrder": "",
      "sortBy": "",
      pageNo: 1 + 1,
      size: 10,
    }
    dispatch(tutionAction.getCreditBaseDemoClassListByStatus(listData))
  };

  return (
    <>
      <TuitionCbHeaders searchHandle={searchHandle} onSelect={onSelect} onRemove={onRemove} options={options} selectedCities={selectedCities} />
      <section className="overflow-hidden overflow-y-auto">

        <div className="m-5">
          <section className="w-full forbig_screen flex flex-wrap  space-y-3 md:justify-start md:space-x-2 md:space-y-0 sm:flex-row xl:-flex-row flex-col">

            {buttonName.map((item, index) => {
              return (
                <div
                  key={index}
                  onClick={() => handleClick(item)}
                  className={`cursor-pointer adjust_button text-gray-100 px-2 py-2 text-center text-sm rounded-full ${active === item ? "bg-[#023565]" : "bg-[#9C9EAB]"
                    }`}
                >
                  <Button onClick={() => handleClick(item)} name={item} value={valueReturnData(item)} />
                </div>
              )
            })}
          </section>
          <section className="mt-4 overflow-auto">
            {active == "Confirmed Requirements" && <ButtonFirstTable filterData={filterData} />}
            {active === "Pending Requirements" && <ButtonSecondTable filterData={filterData} />}
            {active === "Rejected Requirements" && <ButtonThirdTable filterData={filterData} />}
          </section>
        </div>
      </section>
    </>

  );
};

export default Tuition;
