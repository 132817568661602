/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { dashboardAction } from "../../_actions/dashboard.action.js";
import Card from "./Card.js";
import ButtonFour from "./ButtonFour.js";
import Dashboardjson2 from "../dashboard/Dashboard2.json";
import KycHeader from "./KycHeader.js";
import KycTabel from "./KycTabel.js";
import "./Dashboard.css";
import DashBoardHeader from "./DashboardHeader/DashBoardHeader.js";


const Dashboard = () => {
  const roleId = JSON.parse(window.sessionStorage.getItem("adminuser"))?.roleAccess?.roleId
  const adminCity = JSON.parse(window.sessionStorage.getItem("adminuser"))?.city
  const dispatch = useDispatch();
  const selector = useSelector((state) => state);
  const [dashBoardCount, setDashBoardCount] = useState({ "onGoingTuition": 0, "onDemoTuition": 0, "demoDoneTuition": 0, "latestDemoDone": 0, "demoRequest": 0, "referral": 0, "newTicket": 0, "tutorChange": 0 })
  const [response, setResponse] = useState([]);
  const [cardActive, setCardActive] = useState();
  const [newTicketList, setNewTicketList] = useState()
  const currentPage = 1
  const pageSize = 5

  useEffect(() => {
    const cityData = roleId !== 1 ? adminCity && adminCity.length ? adminCity : [] : []
    dispatch(dashboardAction.getDashboardCount({ cityId: cityData }));
  }, []);

  useEffect(() => {
    const newReferralDetails = {
      "cityId": "",
      "keyWord": "",
      pageNo: currentPage,
      size: pageSize,
    }
    const resData = {
      "cityId": "",
      "keyWord": "",
      pageNo: currentPage,
      size: pageSize,
    }
    dispatch(dashboardAction.getNewReferralsList(newReferralDetails))
    dispatch(dashboardAction.getTicketList(resData))
  }, [currentPage]);

  useEffect(() => {
    setNewTicketList(() => selector?.Dashboard?.getNewTicket?.data?.list)
    setDashBoardCount(() => selector?.Dashboard?.dashboardCountList?.data)
  }, [selector])

  const handleResponse = (data) => {
    setResponse(data)
  }


  return (
    <>
      <DashBoardHeader />
      <section className="p-5 overflow-hidden overflow-y-auto">
        <div className="flex flex-col lg:flex-row gap-5">
          {/* left side */}
          <div className="w-full lg:w-4/5 mx-auto lg:px-0 ">
            <section className="grid lg:grid-cols-3 grid-cols-1 gap-4">
              {Dashboardjson2.map((item, index) => (
                <div
                  key={index}
                  className={`rounded-3xl w-full ${cardActive === index ? "bg-[#C60E6B] text-white"
                    : "bg-white text-[#023565] hover:bg-[#C60E6B] hover:text-white transition duration-300 ease-in-out"
                    }`}
                >
                  <Card
                    Group3={item.img}
                    index={cardActive}
                    title={item.title}
                    numbers={item.numbers}
                    buttonName={item.buttonName}
                    data={dashBoardCount}
                    onClick={() => handleResponse(item)}
                    link={item.url}
                  />
                </div>
              ))}
            </section>

            <div className="w-full pt-2">
              <ButtonFour newTicketList={newTicketList} />
            </div>

          </div>

          {/* Right side */}
          <div className="w-full lg:w-2/5 2xl:w-[25%] lg:px-0 ">
            <div className="rounded-lg shadow-md">
              <div className="bg-[#033565] rounded-t-lg shadow-md py-2">
                <KycHeader />
              </div>
              <div className="bg-white rounded-b-lg shadow-md p-4 ">
                <KycTabel />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Dashboard;
