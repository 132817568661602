import { holidaysConstants } from "../_constants/holidays.constants";
import { APIcallFunction, headerForPrivateAPI } from "../_helpers";
import { alertActions } from "./alert.actions";

export const holidaysAction = {
  holidaysGet,
  holidaysDelete,
  holidayCreate,
  holidayUpdate,
  holidayEnaDis,
};

function holidaysGet(data) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "holiday/getHolidayList ",
  };
  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (users) => {
        dispatch(success(users));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: holidaysConstants.GET_HOLIDAY_LIST_REQUEST };
  }
  function success(users) {
    return { type: holidaysConstants.GET_HOLIDAY_LIST_SUCCESS, users };
  }
  function failure(error) {
    return { type: holidaysConstants.GET_HOLIDAY_LIST_FAILURE, error };
  }
}

function holidaysDelete(data, holidaysData) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "holiday/delete",
  };
  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (users) => {
        dispatch(alertActions.success("Holiday Deleted"));
        dispatch(success(users));
        // dispatch(holidaysGet(holidaysData));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
  function request() {
    return { type: holidaysConstants.DELETE_HOLIDAY_LIST_REQUEST };
  }
  function success(users) {
    return { type: holidaysConstants.DELETE_HOLIDAY_LIST_SUCCESS, users };
  }
  function failure(error) {
    return { type: holidaysConstants.DELETE_HOLIDAY_LIST_FAILURE, error };
  }
}

function holidayCreate(data,holidaysData) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "holiday/create",
  };
  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (users) => {
        dispatch(alertActions.success("Holiday Created"));
        dispatch(success(users));
        dispatch(holidaysGet(holidaysData));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: holidaysConstants.CREATE_HOLIDAY_LIST_REQUEST };
  }
  function success(users) {
    return { type: holidaysConstants.CREATE_HOLIDAY_LIST_SUCCESS, users };
  }
  function failure(error) {
    return { type: holidaysConstants.CREATE_HOLIDAY_LIST_FAILURE, error };
  }
}

function holidayUpdate(data, holidaysData) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "holiday/update",
  };
  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (users) => {
        dispatch(alertActions.success("holiday Updated"));
        dispatch(success(users));
        dispatch(holidaysGet(holidaysData));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: holidaysConstants.UPDATE_HOLIDAY_LIST_REQUEST };
  }
  function success(users) {
    return { type: holidaysConstants.UPDATE_HOLIDAY_LIST_SUCCESS, users };
  }
  function failure(error) {
    return { type: holidaysConstants.UPDATE_HOLIDAY_LIST_FAILURE, error };
  }
}

function holidayEnaDis(data, holidaysData) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "holiday/enable-disable",
  };
  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (users) => {
        dispatch(alertActions.success("Action Successfully"));
        dispatch(success(users));
        dispatch(holidaysGet(holidaysData));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: holidaysConstants.ENA_DIS_HOLIDAY_LIST_REQUEST };
  }
  function success(users) {
    return { type: holidaysConstants.ENA_DIS_HOLIDAY_LIST_SUCCESS, users };
  }
  function failure(error) {
    return { type: holidaysConstants.ENA_DIS_HOLIDAY_LIST_FAILURE, error };
  }
}
