import React, { useState } from 'react'
import ReferralComponent from './Referral'
import AppSettingComponent from './AppSetting'
import Button from './Button'
import ReferalAppSettingsHeader from './ReferalAppSettingsHeader'

const ReferralAppSetting = () => {
  const [active, setActive] = useState('Referrals')
  const buttonName = ['Referrals', 'APP Settings']
  const handleClick = data => {
    setActive(data)
  }

  const [searchText, setSearchText] = useState('')

  const handleSearch = e => {
    setSearchText(e.target.value)
  }
  return (
    <>
      <ReferalAppSettingsHeader
        handleSearch={handleSearch}
        searchText={searchText}
      />
      <section className='overflow-hidden overflow-y-auto h-screen px-5 py-8'>
        <section className='flex gap-6'>
          {buttonName.map((btnName, index) => {
            return (
              <div
                key={index}
                className={`flex font-semibold text-sm text-white px-3 lg:px-10 py-2 rounded-full ${
                  active === btnName ? 'bg-[#023565]' : 'bg-[#9C9EAB]'
                }`}
                onClick={() => handleClick(btnName)}
              >
                <Button name={btnName} />
              </div>
            )
          })}
        </section>
        <section className=''>
          {active === 'Referrals' && (
            <ReferralComponent searchText={searchText} />
          )}
          {active === 'APP Settings' && (
            <AppSettingComponent searchText={searchText} />
          )}
        </section>
      </section>
    </>
  )
}

export default ReferralAppSetting
