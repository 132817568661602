import { userpackageConstant } from "../_constants";
export default function userPackage(state = {}, action) {
    switch (action.type) {
        case userpackageConstant.GET_USER_PACKAGE_LIST_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case userpackageConstant.GET_USER_PACKAGE_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                getUserPackageList: action?.users,
            };
        case userpackageConstant.GET_USER_PACKAGE_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case userpackageConstant.USER_PACKAGE_PURCHASED_BY_ADMIN_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case userpackageConstant.USER_PACKAGE_PURCHASED_BY_ADMIN_SUCCESS:
            return {
                ...state,
                loading: false,
                getUserPackageList: action?.users,
            };
        case userpackageConstant.USER_PACKAGE_PURCHASED_BY_ADMIN_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        default:
            return state;
    }
}
