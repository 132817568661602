/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { parentAction, tutionAction, userActions } from '../../../_actions'
import {
  FaAngleRight,
  FaChevronCircleRight,
  FaChevronLeft,
  FaEye
} from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { MdOutlineClose } from 'react-icons/md'
import { FaChevronRight } from 'react-icons/fa'
import { confirmAlert } from 'react-confirm-alert'
import './tution.css'
import Loader from '../../../components/Loader'
import StudentDetail from './Models/StudentDetail'
import ImagePreviewModal from '../../Tutor-Profile/Model/ImagePreview'
import UpdateFeeModel from './Models/UpdateFeeModel'
import UpdateNoteModel from './Models/UpdateNoteModel'
import ViewParent from './Models/ViewParent'
import { truncateText } from '../../../_helpers'
import TuitionFilters from './TuitionFilters'
import ViewMoreTuitionModel from './Models/ViewMoreTuitionModel'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { IoIosArrowDropdownCircle } from 'react-icons/io'
import AssignTutorPopUp from './Models/AssignTutorPopUp'
import UpdateTuitionModel from './Models/UpdateTuitionModel'

const ButtonFirstTabel = ({ searchText, headerCity }) => {
  const dispatch = useDispatch()
  const selector = useSelector(state => state)

  const navigate = useNavigate()
  let [demoData, setDemoData] = useState()

  let allTutorDataList = selector?.tution?.getAllTutorList?.data
    ? selector?.tution?.getAllTutorList?.data
    : []
  const [inputChangeField, setInputChangeField] = useState({})
  const [getDemoById, setGetDemoById] = useState()
  const [suggestedTutor, setSuggestedTutor] = useState()
  const [updateDemoModel, setUpdateDemoModel] = useState(false)
  const [studentListdata, setStudentListData] = useState([])
  const [viewStudentModal, setViewStudentModal] = useState(false)
  const [data, setData] = useState('')
  const [open, setOpen] = useState(false)
  const [assignTutorModal, setAssignTutorModal] = useState(false)
  const [assignTutorList, setAssignTutorList] = useState('')
  const [demoIdForAssign, setDemoIdForAssign] = useState({})
  const [classmodeModal, setClassmodeModal] = useState(false)
  const [demoIdForClassMode, setDemoIdForClassMode] = useState('')
  const [assignTutorTab, setAssignTutorTab] = useState('Suggested Tutors')
  const [parentId, setParentId] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const pageSize = 10
  const [filters, setFilters] = useState({
    tuitionFee: '',
    tutorFee: '',
    preferredTeachMode: '',
    tutorId: '',
    classId: '',
    subjectId: '',
    classReference: '',
    parentId: ''
  })
  const [allStudentList, setAllStudentData] = useState([])
  const [isViewMoreModel, setIsViewMoreModel] = useState(false)
  let tuitionId = ''
  const menuRef = useRef(null)
  const [openMenu, setOpenMenu] = useState(null)
  const [viewBatch, setViewBatch] = useState(null)
  const [showStudentDetails, setShowStudentDetails] = useState(null)
  const studentRef = useRef(null)

  const handleClickOutside = event => {
    if (studentRef.current && !studentRef.current.contains(event.target)) {
      setShowStudentDetails(null)
    }
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setShowStudentDetails(null)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  let suggestTutorBtn = ['Applicants', 'Suggested Tutors', 'All Tutors']

  useEffect(() => {
    let data = {
      pageNo: currentPage,
      size: pageSize,
      ...filters,
      cityId: headerCity ? headerCity : ''
    }
    dispatch(tutionAction.getOnGoingTuitionList(data, data.cityId))
  }, [currentPage, filters, searchText, headerCity])

  useEffect(() => {
    if (selector?.tution?.getOngoungTutionList?.data?.total) {
      setTotalPages(
        Math.ceil(
          selector?.tution?.getOngoungTutionList?.data?.total / pageSize
        )
      )
    }
    setGetDemoById(selector?.tution?.getDemoById?.data)
    setSuggestedTutor(selector?.tution?.suggestedTutorList?.data)
    setDemoData(selector?.tution?.getOngoungTutionList?.data?.list)
  }, [selector])

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1)
  }

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1)
  }

  const handleClickData = datas => {
    setParentId(datas._id)

    let resData = {
      parentId: datas._id
    }
    dispatch(tutionAction.activeDemoListByParentId(resData))

    setOpen(true)
  }

  useEffect(() => {
    setData(() => selector?.tution?.activeDemoList?.data)
  }, [selector])

  const closeModal = () => {
    setOpen(false)
  }

  const assignTutor = data => {
    let query = {
      teachingMode: data?.preferredTeachMode
    }
    if (data?.preferredTeachMode === 'HOME_TUITION') {
      query['city'] = data?.cityId?._id
    }
    dispatch(tutionAction.suggestedTutorListForAdmin({ demoId: data?._id }))
    dispatch(tutionAction.getAllTutorList(query))
    setOpenMenu(null)
    setAssignTutorModal(true)
    setAssignTutorList(data)
    setDemoIdForAssign(data._id)
  }

  const viewStudent = data => {
    let studentListDetails = {
      parentId: data.id
    }
    parentAction
      .getStudentListByParentIdPromise(studentListDetails)
      .then(resData => {
        setViewStudentModal(true)
        setStudentListData(resData.data)
      })
      .catch(err => { })
  }

  const handleAssignTutor = item => {
    setAssignTutorTab(item)
  }
  const handleAssign = data => {
    let Demodata = {
      status: 0,
      keyWord: '',
      fromDate: '',
      toDate: '',
      sortOrder: '',
      sortBy: '',
      pageNo: 1,
      size: 10,
      ...filters,
      tutorId: filters.tutorId !== '' ? [filters.tutorId] : [],
      cityId: headerCity ? [headerCity] : '',
      parentId: filters.parentId !== '' ? [filters.parentId] : []
    }
    let assignTutorInfo = {
      demoId: demoIdForAssign,
      tutorId: data
    }

    dispatch(tutionAction.assignTutorByAdmin(assignTutorInfo, Demodata))
    setAssignTutorModal(false)
  }

  const handleViewHistory = data => {
    navigate(`/app/viewhistory/${data?._id}`)
  }

  const handlePutOnHold = data => {
    let datas = {
      pageNo: currentPage,
      size: pageSize,
      ...filters,
      cityId: headerCity ? headerCity : ''
    }
    let classHoldDetails = {
      tuitionId: data._id
    }
    dispatch(tutionAction.tuitionHoldByAdmin(classHoldDetails, datas))
    const alertBox = document.getElementById('alert-box')
    alertBox.style.display = 'block'
    setTimeout(() => {
      alertBox.style.display = 'none'
    }, 3000)

    setOpen(false)
  }
  const handleCloseClass = data => {
    let closeClassDetails = {
      demoId: data._id
    }

    let demoData = {
      pageNo: currentPage,
      size: pageSize,
      ...filters,
      cityId: headerCity ? headerCity : ''
    }

    confirmAlert({
      title: 'Confirm to Close Class?',
      message: `Are you sure to Close Class ?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () =>
            dispatch(
              tutionAction.closeTuitionClassByAdmin(closeClassDetails, demoData)
            )
        },
        {
          label: 'No'
        }
      ]
    })

    setOpen(false)
    setOpenMenu(null)
  }

  const handleClassMode = data => {
    setClassmodeModal(true)
    setDemoIdForClassMode(data._id)
    setOpenMenu(null)
  }
  const handleHomeClassMode = data => {
    alert(`Are you sure Want to change class mode`)

    let parentIdSet = {
      parentId
    }
    const resData = {
      demoId: demoIdForClassMode,
      preferredTeachMode: data.status
    }
    dispatch(tutionAction.updateTuitionMode(resData, parentIdSet))
    setClassmodeModal(false)
  }

  const [updateFeeModal, setUpdateFeeModal] = useState(false)
  const [updatedFeeData, setUpdateFeeData] = useState('')

  ////Update Fee==================>>>>>>>>>>>>>>>>>>>>>>

  const handleview = data => {
    setUpdateFeeModal(true)
    setUpdateFeeData(data)
    setOpenMenu(null)
  }

  const handleupdateFeeData = e => {
    const { name, value } = e.target
    setUpdateFeeData({ ...updatedFeeData, [name]: value })
  }

  const handleFeeDataSubmit = e => {
    e.preventDefault()
    let dataForUpdate = {
      demoId: updatedFeeData._id,
      tuitionFee: updatedFeeData.tuitionFee,
      numberOfClasses: updatedFeeData.numberOfClasses,
      referenceFee: updatedFeeData.referenceFee,
      tutorFee: updatedFeeData.tutorFee,
      classDuration: updatedFeeData.classDuration,
      dueDate: updatedFeeData.dueDate,
      tutorDueDate: updatedFeeData.tutorDueDate,
      tuitionType: updatedFeeData.tuitionType
    }

    let parentIdSet = {
      parentId
    }
    dispatch(tutionAction.UpdateInfoActiveOnApp(dataForUpdate, parentIdSet))
    setUpdateFeeModal(false)
    setOpenMenu(null)
  }

  const handleSendToDemoRequest = data => {
    let Demodata = {
      status: [3],
      keyWord: '',
      fromDate: '',
      toDate: '',
      sortOrder: '',
      sortBy: '',
      pageNo: 1,
      size: 10
    }

    let sendToDemoDetails = {
      demoId: data._id
    }
    setOpenMenu(null)
    dispatch(tutionAction.revertDemoClass(sendToDemoDetails, Demodata))
  }

  const [remarkModal, setRemarkModal] = useState(false)
  const [remarkId, setRemarkId] = useState('')
  const [remark, setRemark] = useState('')

  const viewNoteData = data => {
    let remarkId = data?._id
    setRemarkId(remarkId)
    setRemarkModal(true)
    setRemark(data?.reason ?? 'N/A')
  }

  const handleRemarkSubmit = e => {
    e.preventDefault()
    let demoData = {
      pageNo: currentPage,
      size: pageSize,
      ...filters,
      cityId: headerCity ? headerCity : ''
    }
    let remarkData = {
      demoId: remarkId,
      remark: remark
    }
    let parentIdSet = {
      parentId
    }
    dispatch(tutionAction.addUpdateRemark(remarkData, demoData, parentIdSet))
    setRemarkModal(false)
    setRemark('')
  }

  const remarkClose = () => {
    setRemark('')
    setRemarkModal(false)
  }

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [image, setImage] = useState('')
  const handleCloseFunctionImageModel = data => {
    if (data) {
      setImage(() => data)
    } else {
      setImage('')
    }
    setIsModalVisible(!isModalVisible)
  }
  const [fullDetails, setFullDetails] = useState(false)
  const [DetailsData, setFullDetailsData] = useState()
  const [tuitionDetails, setTuitionDetails] = useState({})
  const handleFullDetails = data => {
    userActions.getUserByPromiseId({ id: data?.parentInfo?.id }).then(res => {
      setFullDetails(true)
      setFullDetailsData(res?.data)
      setTuitionDetails({
        classDuration: data?.classDuration,
        tuitionFee: data?.tuitionFee,
        numberOfClasses: data?.numberOfClasses,
        tutorFee: data?.tutorFee,
        tuitionType: data?.tuitionType,
        dueDate: data?.dueDate
      })
    })
  }

  const handleOpenMenu = id => {
    setOpenMenu(openMenu === id ? null : id)
  }

  const handleOpenViewBatch = id => {
    setViewBatch(viewBatch === id ? null : id)
  }

  function updateDemoModelFunction(data) {
    tutionAction.getDemoByIdPromise({ demoId: data._id }).then(res => {
      setUpdateDemoModel(true)
      let bookFreeDemo = [...res.data.bookFreeDemo]
      bookFreeDemo = bookFreeDemo.map(free => ({
        ...free,
        studentId: free.studentId.studentFullName
      }))
      setOpenMenu(null)
      setInputChangeField({
        ...res.data,
        bookFreeDemo: bookFreeDemo
      })
    })

    let studentListDetails = {
      parentId: data.parentId._id
    }
    parentAction
      .getStudentListByParentIdForDemoPromise(studentListDetails)
      .then(resData => {
        setAllStudentData(resData.data)
      })
      .catch(err => { })
  }
  return (
    <section>
      <Loader loading={selector?.tution?.loading} />
      <TuitionFilters filters={filters} setFilters={setFilters} />
      <section className='lg:block hidden py-6'>
        <div className=' border border-[#D3D3D3] rounded-xl overflow-x-auto'>
          <table className='w-full bg-white rounded-xl'>
            <thead className='min-w-full rounded-sm'>
              <tr className='table_head border-b '>
                <th className='px-6 py-3 whitespace-nowrap text-left text-sm text-black'>
                  Parent Name
                </th>
                <th className='px-6 py-3 whitespace-nowrap text-sm text-black'>
                  Student
                </th>
                <th className='px-6 py-3 whitespace-nowrap text-sm text-black'>
                  Address / City
                </th>
                <th className='px-6 py-3 whitespace-nowrap text-sm text-black'>
                  Tuition Fee
                </th>
                <th className='px-6 py-3 whitespace-nowrap text-sm text-black'>
                  Reference
                </th>
                <th className='px-6 py-3 whitespace-nowrap text-sm text-black'>
                  Tutor fee
                </th>
                <th className='px-6 py-3 whitespace-nowrap text-sm text-black'>
                  Company
                </th>
                <th className='px-6 py-3 whitespace-nowrap text-sm text-black'>
                  Mode
                </th>
                <th className='px-6 py-3 whitespace-nowrap text-sm text-black'>
                  Action
                </th>
              </tr>
            </thead>

            <tbody className='bg-white divide-y divide-gray-200'>
              {demoData && demoData.length > 0 ? (
                demoData?.map((item, index) => (
                  <tr key={index} className=' border-b capitalize'>
                    <td className='px-4 py-2 flex items-center gap-4 text-sm text-gray-900'>
                      <div className='flex items-center justify-center gap-2'>
                        <span
                          onClick={
                            item?.parentInfo?.image
                              ? () =>
                                handleCloseFunctionImageModel(
                                  item?.parentInfo?.image
                                )
                              : null
                          }
                        >
                          {item?.parentInfo && item?.parentInfo?.image ? (
                            <img
                              alt=''
                              src={
                                item?.parentInfo && item?.parentInfo?.image
                                  ? item?.parentInfo?.image
                                  : 'https://thumbs.dreamstime.com/z/vector-illustration-avatar-dummy-logo-collection-image-icon-stock-isolated-object-set-symbol-web-137160339.jpg?ct=jpeg'
                              }
                              onError={e => {
                                e.target.src =
                                  'https://thumbs.dreamstime.com/z/vector-illustration-avatar-dummy-logo-collection-image-icon-stock-isolated-object-set-symbol-web-137160339.jpg?ct=jpeg'
                              }}
                              className='rounded-full my-3 max-w-[50px] max-h-[50px] min-w-[50px] min-h-[50px]'
                            />
                          ) : (
                            <div className='bg-gray-300 rounded-full h-14 w-14 flex justify-center items-center'>
                              <span className='text-base font-semibold text-[#033565]'>
                                {' '}
                                {item?.parentInfo?.name
                                  ? item.parentInfo.name
                                    .split(' ')
                                    .map(name => name.charAt(0))
                                    .join('')
                                  : ''}
                              </span>
                            </div>
                          )}
                        </span>
                        <div className='w-24'>
                          <p className='font-semibold whitespace-nowrap text-[#023565]'>
                            {item?.parentInfo?.name ?? 'Not Available'}
                          </p>
                          <p className='text-gray-500 whitespace-nowrap'>
                            {item?.parentInfo?.mobNo ?? 'Not Available'}
                          </p>
                          <div
                            className='flex items-center  gap-1 cursor-pointer'
                            onClick={() => handleFullDetails(item)}
                          >
                            <p className='text-base font-normal cursor-pointer'>
                              More
                            </p>
                            <FaChevronCircleRight className='text-[#023565] text-base cursor-pointer' />
                          </div>
                        </div>
                      </div>
                    </td>

                    <td className='px-4 py-2 text-sm'>
                      <span className='flex items-center justify-center'>
                        <FaEye
                          size={24}
                          className='cursor-pointer text-[#023565] hover:text-[#E4006F]'
                          onClick={() => viewStudent(item?.parentInfo)}
                        />
                      </span>
                    </td>

                    <td className='px-4 py-2 text-sm text-gray-900'>
                      <span
                        className='flex justify-center items-center text-center cursor-pointer'
                        title={item?.parentInfo?.address || 'N/A'}
                      >
                        {' '}
                        {truncateText(item?.parentInfo?.address, 15) ?? 'N/A'}
                      </span>
                      <span className='flex justify-center items-center'>
                        {item?.cityInfo?.map(e => <span>{e?.name}</span>) ??
                          'No City'}
                      </span>
                    </td>

                    <td className='px-4 py-2 text-sm text-gray-900 text-center'>
                      {item.tuitionFee}
                    </td>
                    <td className='px-4 py-2 text-sm text-gray-900 text-center'>
                      {item.referenceFee}
                    </td>
                    <td className='px-4 py-2 text-sm text-gray-900 text-center'>
                      {item.tutorFee}
                    </td>
                    <td className='px-4 py-2 text-sm text-gray-900 text-center'>
                      {item.companyShare}
                    </td>
                    <td className='px-4 py-2 text-sm text-gray-900 text-center'>
                      {item?.preferredTeachMode === 'ONLINE_TUITION'
                        ? 'Online'
                        : 'In-Home'}
                    </td>

                    <td className='px-4 py-2 text-sm'>
                      <span className='flex items-center justify-center'>
                        <FaEye
                          size={24}
                          className='cursor-pointer text-[#023565] hover:text-[#E4006F]'
                          onClick={() => handleClickData(item)}
                        />
                      </span>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={11}
                    className='px-6 py-4 font-[400] text-base text-[#313131] whitespace-nowrap text-center'
                  >
                    Data Not Available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </section>

      <div
        id='alert-box'
        class='hidden fixed top-1/2 z-50 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-[#023565] p-6 rounded-lg shadow-lg text-center'
      >
        <img
          src='/tuition-on-hold.png'
          alt='Alert Icon'
          class='w-12 h-12 mx-auto rounded shadow-md'
        />
        <h1 class='text-xl font-bold text-[#fff] mt-4'>
          {' '}
          Tuition put on hold!
        </h1>
        <p class='text-gray-200 mt-2'>Your tuition has been put on hold</p>
      </div>

      {open && (
        <>
          <div className='fixed inset-0 flex items-center justify-center bg-gray-700 bg-opacity-50 z-50'>
            <div className='lg:w-[40%] w-full p-4'>
              <div className='flex justify-end'>
                <button
                  className='z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center'
                  onClick={closeModal}
                >
                  <span>
                    <MdOutlineClose />
                  </span>{' '}
                  Close
                </button>
              </div>
              <div className='bg-white rounded-lg overflow-hidden shadow-xl max-h-[90vh] overflow-y-auto'>
                <div className='p-4 '>
                  {data && data.length > 0 ? (
                    data.map((item, index) => (
                      <div key={index} className=''>
                        <div className='border-b pb-2 mb-2 flex justify-between items-center bg-[#F1F1F1] p-2 rounded-lg'>
                          <strong>Batch {index + 1}</strong>
                          <div className='flex justify-between '>
                            <IoIosArrowDropdownCircle
                              size={22}
                              className='cursor-pointer text-[#023565] hover:text-[#E4006F]'
                              onClick={() => {
                                handleOpenViewBatch(item._id)
                              }}
                            />

                            <BsThreeDotsVertical
                              onClick={() => {
                                handleOpenMenu(item._id)
                              }}
                              className='text-xl text-[#C60E6B] cursor-pointer relative'
                            />
                            {openMenu && openMenu === item._id && (
                              <div
                                className='bg-white  border w-48 mt-6 rounded-md text-[#023565] py-2 px-2 text-start text-sm font-semibold absolute z-50'
                                ref={menuRef}
                              >
                                <div>
                                  <div
                                    className='flex justify-between place-items-center gap-2 hover:text-[#E4006F] hover:bg-gray-100 cursor-pointer border-b'
                                    onClick={() => handleview(item)}
                                  >
                                    <button className='flex items-center  justify-between py-2 p text-[#C60E6B]'>
                                      Update Info
                                    </button>
                                    <FaAngleRight className='hover:text-[#C60E6B]' />
                                  </div>
                                  <div
                                    className='flex justify-between place-items-center hover:text-[#E4006F] hover:bg-gray-100 cursor-pointer'
                                    onClick={() => updateDemoModelFunction(item)}
                                  >
                                    <button className='flex items-center  justify-between py-2  text-black'>
                                      Update Tuition{' '}
                                    </button>
                                    <FaAngleRight className='hover:text-[#C60E6B]' />
                                  </div>
                                  <div
                                    className='flex justify-between place-items-center hover:text-[#E4006F] hover:bg-gray-100 cursor-pointer'
                                    onClick={() => handleClassMode(item)}
                                  >
                                    <button className='flex items-center  justify-between py-2  text-black'>
                                      Class Mode{' '}
                                    </button>
                                    <FaAngleRight className='hover:text-[#C60E6B]' />
                                  </div>
                                  <div
                                    className='flex justify-between place-items-center hover:text-[#E4006F] hover:bg-gray-100 cursor-pointer'
                                    onClick={() => handleCloseClass(item)}
                                  >
                                    <button className='flex items-center  justify-between py-2  text-black'>
                                      Class Close
                                    </button>
                                    <FaAngleRight className='hover:text-[#C60E6B]' />
                                  </div>
                                  <div
                                    className='flex justify-between place-items-center hover:text-[#E4006F] hover:bg-gray-100 cursor-pointer'
                                    onClick={() => handlePutOnHold(item)}
                                  >
                                    <button className='flex items-center  justify-between py-2  text-black'>
                                      Put On Hold
                                    </button>
                                    <FaAngleRight className='hover:text-[#C60E6B]' />
                                  </div>
                                  <div
                                    className='flex justify-between place-items-center hover:text-[#E4006F] hover:bg-gray-100 cursor-pointer'
                                    onClick={() => assignTutor(item)}
                                  >
                                    <button className='flex items-center  justify-between py-2  text-black'>
                                      Change Tutor
                                    </button>
                                    <FaAngleRight className='hover:text-[#C60E6B]' />
                                  </div>
                                  <div
                                    className='flex justify-between place-items-center hover:text-[#E4006F] hover:bg-gray-100 cursor-pointer'
                                    onClick={() => handleViewHistory(item)}
                                  >
                                    <button className='flex items-center  justify-between py-2  text-black'>
                                      View History
                                    </button>
                                    <FaAngleRight className='hover:text-[#C60E6B]' />
                                  </div>
                                  <div
                                    className='flex justify-between place-items-center hover:text-[#E4006F] hover:bg-gray-100 cursor-pointer'
                                    onClick={() =>
                                      handleSendToDemoRequest(item)
                                    }
                                  >
                                    <button className='flex items-center  justify-between py-2  text-black'>
                                      Send to Demo
                                    </button>
                                    <FaAngleRight className='hover:text-[#C60E6B]' />
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>

                        {viewBatch && viewBatch === item._id && (
                          <div className='space-y-2'>
                            {item?.bookFreeDemo?.map((itm, id) => (
                              <div className='flex justify-between'>
                                <p>
                                  <strong> Student Name:</strong>
                                </p>
                                <p className='capitalize'>
                                  <div className='flex items-center gap-2'>
                                    {itm?.studentId?.studentFullName || 'N/A'}{' '}
                                    <IoIosArrowDropdownCircle
                                      size={18}
                                      className='cursor-pointer text-[#023565] hover:text-[#E4006F]'
                                      onClick={() => {
                                        setShowStudentDetails(
                                          showStudentDetails === itm?.studentId
                                            ? null
                                            : itm?.studentId
                                        )
                                      }}
                                    />
                                  </div>
                                </p>
                              </div>
                            ))}
                            {item?.bookFreeDemo?.map((itm, id) => (
                              <div>
                                {showStudentDetails &&
                                  showStudentDetails === itm?.studentId && (
                                    <div
                                      className='w-full p-2 border rounded'
                                      ref={studentRef}
                                    >
                                      <div className='flex justify-between'>
                                        <p>
                                          <strong>Class:</strong>
                                        </p>
                                        <p>{itm?.classId?.name || 'N/A'}</p>
                                      </div>
                                      <div className='flex justify-between'>
                                        <p>
                                          <strong>Subject:</strong>
                                        </p>
                                        <p>
                                          {itm?.subjectId
                                            ?.map(ele => ele?.name)
                                            .join(', ') || 'N/A'}
                                        </p>
                                      </div>
                                      <div className='flex justify-between'>
                                        <p>
                                          <strong>Board:</strong>
                                        </p>
                                        <p>{itm?.board?.name || 'N/A'}</p>
                                      </div>
                                    </div>
                                  )}
                              </div>
                            ))}

                            <div className='flex justify-between'>
                              <p>
                                <strong>Class Duration:</strong>
                              </p>
                              <p>{item?.classDuration ?? '-'} Minutes</p>
                            </div>

                            <div className='flex justify-between'>
                              <p>
                                <strong>Assigned Tutor:</strong>
                              </p>
                              <p className='font-semibold whitespace-nowrap text-[#023565]'>
                                {item?.tutorId?.name ?? 'N/A'}
                              </p>
                            </div>
                            <div className='flex justify-between'>
                              <p>
                                <strong> Tutor Mob:</strong>
                              </p>
                              <p className='font-semibold whitespace-nowrap text-[#023565]'>
                                {item?.tutorId?.mobNo ?? 'N/A'}
                              </p>
                            </div>
                            <div className='flex justify-between'>
                              <p>
                                <strong>Tuition Fee:</strong>
                              </p>
                              <p>{item?.tuitionFee ?? '0'}</p>
                            </div>
                            <div className='flex justify-between'>
                              <p>
                                <strong>Tutor Fee:</strong>
                              </p>
                              <p>{item?.tutorFee ?? '0'}</p>
                            </div>
                            <div className='flex justify-between'>
                              <p>
                                <strong>Class Reference:</strong>
                              </p>
                              <p>{item.classReference}</p>
                            </div>
                            <div className='flex justify-between'>
                              <p>
                                <strong>Reference Fee:</strong>
                              </p>
                              <p>{item.referenceFee}</p>
                            </div>
                            <div className='flex justify-between'>
                              <p>
                                <strong>Company Share:</strong>
                              </p>
                              <p>{item.companyShare}</p>
                            </div>
                            <div className='flex justify-between'>
                              <p>
                                <strong>Tuition Start Date:</strong>
                              </p>
                              <p>{item.demoDate}</p>
                            </div>
                            <div className='flex justify-between'>
                              <p>
                                <strong>Tuition Fee Due Date:</strong>
                              </p>
                              <p>{item?.dueDate ?? 'N/A'}</p>
                            </div>
                            <div className='flex justify-between'>
                              <p>
                                <strong>Tutor Fee Due Date:</strong>
                              </p>
                              <p>{item?.tutorDueDate ?? 'N/A'}</p>
                            </div>

                            <div className='flex justify-between'>
                              <p>
                                <strong>Sessions Details:</strong>
                              </p>
                              <p>{item?.numberOfClasses}</p>
                            </div>
                            <div className='flex justify-between'>
                              <p>
                                <strong>Class Mode:</strong>
                              </p>
                              <p>
                                {item &&
                                  item?.preferredTeachMode &&
                                  item?.preferredTeachMode === 'HOME_TUITION'
                                  ? 'Home Tuition'
                                  : getDemoById &&
                                    getDemoById?.preferredTeachMode &&
                                    getDemoById?.preferredTeachMode ===
                                    'ONLINE_TUITION'
                                    ? 'Online Tuition'
                                    : 'Online Tuition'}
                              </p>
                            </div>
                            <div className='flex justify-between'>
                              <p>
                                <strong>Note:</strong>
                              </p>
                              <p
                                className={`cursor-pointer font-semibold ${item.reason
                                  ? 'text-[#00AFE2]'
                                  : 'text-[#023565]'
                                  }`}
                                onClick={() => viewNoteData(item)}
                              >
                                {item?.reason ? 'View' : 'Add'}
                              </p>
                            </div>
                          </div>
                        )}
                        {classmodeModal && (
                          <div>
                            <div className='fixed inset-0 flex justify-center items-center bg-gray-500 bg-opacity-50'>
                              <div>
                                <div className='flex justify-end'>
                                  <button
                                    className='z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center'
                                    onClick={() => setClassmodeModal(false)}
                                  >
                                    <span>
                                      <MdOutlineClose />
                                    </span>{' '}
                                    Close
                                  </button>
                                </div>
                                <div className='bg-white p-5 rounded-md shadow-lg'>
                                  <div className='text-center mb-4'>
                                    <h2 className='text-lg font-semibold'>
                                      Class mode
                                    </h2>
                                  </div>
                                  <div>
                                    <div className='mt-4 flex justify-between gap-2'>
                                      <button
                                        className='bg-[#023565] text-white px-4 py-2 rounded-full'
                                        onClick={() =>
                                          handleHomeClassMode({
                                            status: 'HOME_TUITION'
                                          })
                                        }
                                      >
                                        Home class
                                      </button>
                                      <button
                                        className='bg-[#9C9EAB] text-white px-4 py-2 rounded-full'
                                        onClick={() =>
                                          handleHomeClassMode({
                                            status: 'ONLINE_TUITION'
                                          })
                                        }
                                      >
                                        Online class
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    ))
                  ) : (
                    <div className='flex items-center justify-center border bg-gray-100'>
                      <div className='flex items-center justify-center w-64 h-32 bg-white border border-gray-300 rounded-lg shadow-lg'>
                        <span className='text-black'>Data Not Available</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {assignTutorModal && (
        <AssignTutorPopUp
          assignTutorModal={assignTutorModal}
          setAssignTutorModal={setAssignTutorModal}
          suggestTutorBtn={suggestTutorBtn}
          assignTutorTab={assignTutorTab}
          handleAssignTutor={handleAssignTutor}
          assignTutorList={assignTutorList}
          allTutorDataList={allTutorDataList}
          handleAssign={handleAssign}
          suggestedTutor={suggestedTutor}
        />
      )}

      <UpdateFeeModel
        updateFeeModal={updateFeeModal}
        setUpdateFeeModal={setUpdateFeeModal}
        handleFeeDataSubmit={handleFeeDataSubmit}
        updatedFeeData={updatedFeeData}
        handleupdateFeeData={handleupdateFeeData}
      />

      <UpdateNoteModel
        remarkModal={remarkModal}
        remarkClose={remarkClose}
        Heading={'Update Note'}
        remark={remark}
        setRemark={setRemark}
        handleRemarkSubmit={handleRemarkSubmit}
        btnName={`${remark ? 'Update' : 'Add'} `}
      />

      <StudentDetail
        setViewStudentModal={setViewStudentModal}
        viewStudentModal={viewStudentModal}
        setStudentListData={setStudentListData}
        studentListdata={studentListdata}
      />

      <ImagePreviewModal
        handleClose={handleCloseFunctionImageModel}
        showWarning={isModalVisible}
        image={image}
      />

      <ViewParent
        fullDetails={fullDetails}
        setFullDetails={setFullDetails}
        DetailsData={DetailsData}
        tuitionDetails={tuitionDetails}
      />

      <section className='space-y-5 lg:hidden block py-4'>
        {demoData && demoData.length > 0 ? (
          demoData?.map((item, index) => (
            <div key={index} className=' bg-white p-4 rounded-[10px] border border-[#023565]'>
              <>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>
                      Parent Name
                    </p>
                  </div>
                  <div className='w-full'>
                    <div className='flex items-center  gap-2 capitalize'>
                      <span
                        onClick={
                          item?.parentInfo?.image
                            ? () =>
                              handleCloseFunctionImageModel(
                                item?.parentInfo?.image
                              )
                            : null
                        }
                      >
                        {item?.parentInfo && item?.parentInfo?.image ? (
                          <img
                            alt=''
                            src={
                              item?.parentInfo && item?.parentInfo?.image
                                ? item?.parentInfo?.image
                                : ''
                            }
                            className='rounded max-w-[50px] max-h-[50px] min-w-[50px] min-h-[50px]'
                          />
                        ) : (
                          <div className='bg-gray-300 rounded h-14 w-14 flex justify-center items-center'>
                            <span className='text-base font-semibold text-[#033565]'>
                              {' '}
                              {item?.parentInfo?.name
                                ? item.parentInfo.name
                                  .split(' ')
                                  .map(name => name.charAt(0))
                                  .join('')
                                : ''}
                            </span>
                          </div>
                        )}
                      </span>
                      <div>
                        <p className='text-[12px] font-semibold whitespace-nowrap text-[#023565]'>
                          {/* {item?.parentInfo?.name ?? 'Not Available'} */}
                          {truncateText(item?.parentInfo?.name, 12) ?? 'N/A'}
                        </p>
                        <p className='text-[12px] font-[400] text-gray-500'>
                          {item?.parentInfo?.mobNo ?? 'N/A'}
                        </p>
                        <FaChevronCircleRight
                          className='text-[#023565] text-lg cursor-pointer'
                          onClick={() => handleFullDetails(item)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>Student</p>
                  </div>
                  <div className='w-full'>
                    <p
                      className='text-[14px] font-[600] text-[#023565]'
                      onClick={() => viewStudent(item?.parentInfo)}
                    >
                      View
                    </p>
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>Address</p>
                  </div>
                  <div className='w-full'>
                    <p className='text-[14px] font-[400]'>
                      {' '}
                      {item?.parentInfo?.address ?? 'N/A'}
                    </p>
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>City</p>
                  </div>
                  <div className='w-full'>
                    <p className='text-[14px] font-[400]'>
                      {' '}
                      {item?.cityInfo?.name ?? 'No City'}
                    </p>
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>
                      Tuition Fee
                    </p>
                  </div>
                  <div className='w-full'>
                    <p className='text-[14px] font-[400]'>
                      {' '}
                      {item.tuitionFee}{' '}
                    </p>
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>
                      Reference
                    </p>
                  </div>
                  <div className='w-full'>
                    <p className='text-[14px] font-[400]'>
                      {' '}
                      {item.referenceFee}
                    </p>
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>
                      Tutor Fee
                    </p>
                  </div>
                  <div className='w-full'>
                    <p className='text-[14px] font-[400]'>{item.tutorFee}</p>
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>
                      Tuition Mode
                    </p>
                  </div>
                  <div className='w-full'>
                    <p className='text-[14px] font-[400]'>
                      {' '}
                      {item?.preferredTeachMode === 'ONLINE_TUITION'
                        ? 'Online Tuition'
                        : 'Home Tuition'}
                    </p>
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>Company</p>
                  </div>
                  <div className='w-full'>
                    <p className='text-[14px] font-[400]'>
                      {item.companyShare}
                    </p>
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>Action</p>
                  </div>
                  <div className='w-full'>
                    <p
                      className='text-[14px] font-[700] text-[#023565]'
                      onClick={() => handleClickData(item)}
                    >
                      {' '}
                      View & Manage
                    </p>
                  </div>
                </div>
              </>
            </div>
          ))
        ) : (
          <p className='text-center md:hidden'>Data Not Found</p>
        )}
      </section>
      {totalPages > 1 && (
        <div className='flex justify-end items-center lg:py-2 pb-4'>
          <button
            className={`px-4 py-2 border text-white bg-[#023565] rounded ${currentPage === 1 ? 'opacity-50 cursor-not-allowed' : ''
              }`}
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
          >
            <FaChevronLeft />
          </button>
          <span className='text-gray-700 mx-1'>
            <span className='border px-4 py-2 text-black  rounded'>
              {currentPage}
            </span>{' '}
            of{' '}
            <span className='border px-4 py-2 text-black  rounded'>
              {totalPages}
            </span>
          </span>
          <button
            className={`px-4 py-2 border text-white bg-[#023565] rounded ${currentPage === totalPages ? 'opacity-50 cursor-not-allowed' : ''
              }`}
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            <FaChevronRight />
          </button>
        </div>
      )}

      <UpdateTuitionModel
        handleBack={() => {
          setUpdateDemoModel(false)
          setInputChangeField({})
        }}
        updateDemoModel={updateDemoModel}
        inputChangeField={inputChangeField}
        allStudentList={allStudentList}
        setInputChangeField={setInputChangeField}
        listPayload={{
          pageNo: currentPage,
          size: pageSize,
          ...filters,
          cityId: headerCity ? headerCity : ''
        }}
        type={"OnGoing"}
      />
      {isViewMoreModel && (
        <ViewMoreTuitionModel
          isViewMoreModel={isViewMoreModel}
          setIsViewMoreModel={setIsViewMoreModel}
          tuitionId={tuitionId}
        />
      )}
    </section>
  )
}

export default ButtonFirstTabel
