/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Button from "./Button";
import { tutionAction } from "../../../_actions";
import ButtonFirstTabel from "./ButtonFirstTabel";
import ButtonSecondTabel from "./ButtonSecondTable";
import ButtonThirdTabel from "./ButtonThirdTabel";
import ButtonFourTabel from "./ButtonFourTable";
import ButtonFiveTabel from "./ButtonFiveTable";
import ButtonSixTabel from "./ButtonSixTable";
import ButtonSevenTabel from "./ButtonSevenTable";
import ButtonEightTabel from "./ButtonEightTable";
import ChangeTutorRequest from "./ChangeTutorRequest";
import './tution.css'
import { useLocation, useNavigate } from "react-router";
import TuitionHeader from "./TuitionHeader/TuitionHeader";

const Tution = () => {
  const roleId = JSON.parse(window.sessionStorage.getItem("adminuser"))?.roleAccess?.roleId
  const adminCity = JSON.parse(window.sessionStorage.getItem("adminuser"))?.city
  const dispatch = useDispatch();
  const selector = useSelector(state => state)

  const [tuitionCount, setTutionCount] = useState()
  const [active, showActive] = useState("demo-request");
  const [activeButton, showActiveButton] = useState("demo");
  const [menuButton, setMenuButton] = useState("lead")
  const [searchText, setSearchText] = useState('')
  const [headerCity, setHeaderCity] = useState(roleId !== 1 ? adminCity && adminCity.length ? adminCity[0] : "" : "")

  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const currentTab = queryParams.get('tab') || "demo-request";
    const currentButton = queryParams.get('button') || "demo";
    const currentMenu = queryParams.get('menu') || "lead";

    const expectedUrl = `?tab=${currentTab}&button=${currentButton}${currentMenu ? `&menu=${currentMenu}` : ""}`;


    if (location.search !== expectedUrl) {
      navigate(expectedUrl, { replace: true });
      return;
    }
    console.log("location.search", location.search)
    dispatch(tutionAction.getTuitionCount());

    setMenuButton(currentMenu)
    showActive(currentTab);
    showActiveButton(currentButton);
  }, [location.search, navigate, dispatch]);



  useEffect(() => {
    setTutionCount(() => selector?.tution?.getTuitionCount?.data)
  }, [selector])





  const handleClick = (data) => {
    showActive(onTabValueFixed(data));
    navigate(`?tab=${onTabValueFixed(data)}&button=${activeButton}&menu=lead`)
  };


  const buttonName = [
    "Demo Request",
    "Tutor Assigned",
    "On Demo",
    "Demo Done",
    "Rejected",
  ];


  function onTabValueFixed(state) {
    switch (state) {
      case "On Going tuition":
        return "on-going-tuition";
      case "Demo Request":
        return "demo-request";
      case "Tutor Assigned":
        return "tutor-assigned";
      case "On Demo":
        return "on-demo";
      case "Demo Done":
        return "demo-done";
      case "On Hold":
        return "on-hold";
      case "Completed":
        return "completed";
      case "Rejected":
        return "rejected";
      case "Change Tutor Request":
        return "Change-Tutor-Request";
      default:
        return "";
    }
  }

  function valueReturnData(data) {
    switch (data) {
      case "On Going tuition":
        return tuitionCount?.onGoingTuition;
      case "Demo Request":
        return tuitionCount?.demoRequest;
      case "Tutor Assigned":
        return tuitionCount?.tutorAssign;
      case "On Demo":
        return tuitionCount?.onDemo;
      case "Demo Done":
        return tuitionCount?.demoDone;
      case "On Hold":
        return tuitionCount?.onHold;
      case "Completed":
        return tuitionCount?.completed;
      case "Rejected":
        return tuitionCount?.rejected;
      case "Change Tutor Request":
        return tuitionCount?.changeTutor;
      default:
        return 0;
    }
  }

  const newStateStatus = (data) => {
  }

  const handleCity = async (e) => {
    setHeaderCity(e.target.value)
    dispatch(tutionAction.getTuitionCount({
      cityId: e.target.value
    }));
  };


  const handleSearch = (e) => {
    setSearchText(e.target.value);

  };

  const buttonName2 = [
    "On Going tuition",
    "Completed",
    "On Hold",
    "Change Tutor Request"
  ];

  const handelOnChangeLeadAndActiveOnApp = (data) => {
    setMenuButton(data)
    navigate(`?tab=demo-request&button=demo&menu=${data}`)
  }

  return (
    <>
      <TuitionHeader active={active} handleCity={handleCity} handleSearch={handleSearch} searchText={searchText} headerCity={headerCity} roleId={roleId} />

      <section className="overflow-hidden overflow-y-auto">
        <div className="mx-5">
          <section className="py-4 flex items-center justify-center lg:gap-6 gap-0">
            <button
              onClick={() => {
                showActive(onTabValueFixed("Demo Request"));
                showActiveButton("demo")
                navigate(`?tab=${onTabValueFixed("Demo Request")}&button=demo&menu=lead`)
              }}
              className={`cursor-pointer adjust_button text-gray-100 font-bold px-2 py-2 text-center lg:w-96 w-full text-lg lg:rounded-full rounded-l-full  ${String(activeButton) === "demo" ? "bg-[#023565]" : "bg-[#9C9EAB]"}`}
              type=""
            >
              Demo
            </button>
            <button
              onClick={() => {
                showActive(onTabValueFixed("On Going tuition"));
                showActiveButton("tuition")
                navigate(`?tab=${onTabValueFixed("On Going tuition")}&button=tuition&menu=lead`)
              }}
              className={`cursor-pointer adjust_button text-gray-100 font-bold px-2 py-2 text-center lg:w-96 w-full text-lg lg:rounded-full rounded-r-full ${String(activeButton) === "tuition" ? "bg-[#023565]" : "bg-[#9C9EAB]"}`}
              type=""
            >
              Tuition
            </button>
          </section>


          <section className="w-full forbig_screen flex flex-wrap gap-2 space-y-0 md:justify-start md:space-x-2 md:space-y-0 md:flex-row flex-col">
            {String(activeButton) !== "tuition" ? buttonName.map((item, index) => {
              return (
                <div key={index} onClick={() => handleClick(item)} className={`cursor-pointer adjust_button text-gray-100 px-2 py-2 text-center text-sm rounded-full ${active === onTabValueFixed(item) ? "bg-[#023565]" : "bg-[#9C9EAB]"}`}>
                  <Button onClick={() => handleClick(item)} name={item} value={valueReturnData(item)} />
                </div>
              )
            })
              :
              buttonName2.map((item, index) => {
                return (
                  <div key={index} onClick={() => handleClick(item)} className={`cursor-pointer adjust_button text-gray-100 px-2 py-2 text-center text-sm rounded-full ${active === onTabValueFixed(item) ? "bg-[#023565]" : "bg-[#9C9EAB]"}`}>
                    <Button onClick={() => handleClick(item)} name={item} value={valueReturnData(item)} />
                  </div>
                )
              })
            }
          </section>

          <section className="mt-4">
            {/* demo  */}
            {active === onTabValueFixed("Demo Request") && <ButtonSecondTabel searchText={searchText} newStateStatus={newStateStatus} headerCity={headerCity} menuButton={menuButton} handelOnChangeLeadAndActiveOnApp={handelOnChangeLeadAndActiveOnApp} />}
            {active === onTabValueFixed("Tutor Assigned") && <ButtonThirdTabel headerCity={headerCity} searchText={searchText} />}
            {active === onTabValueFixed("On Demo") && <ButtonFourTabel headerCity={headerCity} searchText={searchText} />}
            {active === onTabValueFixed("Demo Done") && <ButtonFiveTabel headerCity={headerCity} searchText={searchText} />}
            {active === onTabValueFixed("Rejected") && <ButtonEightTabel headerCity={headerCity} searchText={searchText} />}
{/* tuition  */}
            {active === onTabValueFixed("On Going tuition") && <ButtonFirstTabel headerCity={headerCity} searchText={searchText} />}
            {active === onTabValueFixed("Completed") && <ButtonSevenTabel headerCity={headerCity} searchText={searchText} />}
            {active === onTabValueFixed("On Hold") && <ButtonSixTabel headerCity={headerCity} searchText={searchText} />}
            {active === onTabValueFixed("Change Tutor Request") && <ChangeTutorRequest headerCity={headerCity} searchText={searchText} />}
          </section>
        </div>
      </section>
    </>
  );
};

export default Tution;
