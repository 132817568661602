import React from "react";
import KycStatus from "../dashboard/KycStatus.json";
import "./Dashboard.css";
import './Dashboard.css'
const KycHeader = () => {
  return (
    <>
      <section className="pt-2 kycHeader px-5 flex text-wrap justify-between place-items-center">
        <div className="text py-4">
          <p className="Heading text-black font-bold">
            {KycStatus.KycStaus.HeadingText}
          </p>
          <p className="text-gray-600 text-xs">{KycStatus.KycStaus.SubHeading}</p> <span></span>
        </div>
        {/* <div className="button three_dot  border-2 border-[#023565] rounded flex items-center justify-center w-8 h-8 ">
                <button className="">
                      <img src="/right .png" alt=""/>
                </button>
        </div> */}
      </section>
    </>
  );
};

export default KycHeader;
