/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router';
import Loader from '../../components/Loader';
import { FaLongArrowAltRight, FaRocketchat } from 'react-icons/fa';
import { ticktAction, } from "../../_actions";
import { timeZoneReturnDateType, truncateText } from '../../_helpers';
import { MdOutlineClose, MdRefresh } from 'react-icons/md';

const ButtonFour = ({ newTicketList }) => {
  const [msgData, setMstData] = useState([]);
  const [msgModal, setMsgModal] = useState(false);
  const [reply, setReply] = useState("");
  const dispatch = useDispatch();
  const selector = useSelector(state => state);
  const navigate = useNavigate()
  let [ticketReply, setTicketReply] = useState();
  let endmsgUseRef = useRef(null)
  let [ticketData, setTicketData] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const pageSize = 10;
  const handleChats = (data) => {
    setMstData([data]);
    handelOnClickChatIcon(data?._id);
    setMsgModal(true);
  };

  const handleSubmit = (e, data) => {
    e.preventDefault();
    let replyData = {
      ticketId: data._id,
      msg: reply,
    };
    dispatch(ticktAction.ticketReply(replyData));
    setReply("");
  };
  useEffect(() => {
    if (endmsgUseRef.current) {
      endmsgUseRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [ticketReply]);

  const handelOnClickChatIcon = (id) => {
    dispatch(ticktAction.getMsgListTicketById({ ticketId: id }));
  };

  useEffect(() => {
    if (selector?.Ticket?.getTicketList?.data?.total) {
      setTotalPages(Math.ceil(selector?.Ticket?.getTicketList?.data?.total / pageSize));
    }
    setTicketData(selector?.Ticket?.getTicketList?.data?.list)
    setTicketReply(selector?.Ticket?.ticketReplyList?.data)
  }, [selector]);


  const refreshChat = (id) => {
    dispatch(ticktAction.getMsgListTicketById({ ticketId: id }));
  }

  return (
    <div className=''>
      <Loader loading={selector?.Dashboard?.loading} />
      <section className='lg:block hidden'>
        <div className='flex justify-between items-center p-2 bg-[#033565] rounded-t-lg'>
          <h2 className='text-base font-semibold text-white'>Latest Issues</h2>
          <button className="text-end font-semibold bg-white rounded px-4 py-2 text-blue-900 cursor-pointer hover:scale-95 transition-all duration-300 ease-in-out flex items-center gap-2" onClick={() => navigate("/app/help&support")}>View All <span><FaLongArrowAltRight /></span></button>
        </div>

        <table class="w-full text-left text-sm rtl:text-right bg-white text-[#313131] rounded-b-lg">
          <thead class=" text-[#313131] font-semibold border-b border-[#D1D1D1]">
            <tr className="border-b">
              <th className="px-6 py-4 whitespace-nowrap">
                Name
              </th>
              <th className="px-6 py-4 whitespace-nowrap">
                Profile
              </th>
              <th className="px-6 py-4 whitespace-nowrap">
                City
              </th>
              <th className="px-6 py-4 whitespace-nowrap">
                Subject
              </th>
              <th className="px-6 py-4 whitespace-nowrap">
                Chat
              </th>
              <th className="px-6 py-4 whitespace-nowrap">
                Status
              </th>
            </tr>
          </thead>
          <tbody className='rounded-lg divide-y-2'>
            {
              newTicketList && newTicketList.length > 0 ? (newTicketList?.map((item, id) => (
                <tr className="bg-white capitalize " key={id}>
                  <td className="px-6 py-4 font-[400] text-[12px] text-[#313131] whitespace-nowrap">
                    <p className="font-semibold whitespace-nowrap text-[#023565]">
                      {item?.userId?.name ?? "Not Available"}
                    </p>
                    <p className="text-gray-500 whitespace-nowrap">
                      {item?.userId?.mobNo ?? "Not Available"}
                    </p>
                  </td>
                  <td className="px-6 py-4 font-medium text-[#313131] whitespace-nowrap">
                    {item?.userId?.userType ?? 'No Data'}
                  </td>
                  <td className="px-6 py-3 font-medium">
                    {item?.cityId?.name ?? 'No Data'}
                  </td>
                  <td className="px-6 py-3 font-medium" title={item?.title ?? 'No Title'}>
                    {truncateText(item?.title, 15) ?? 'N/A'}
                  </td>
                  <td className="px-8 py-4  text-center">
                    <FaRocketchat size={30}
                      className="text-blue-500 cursor-pointer "
                      onClick={() => handleChats(item)}
                    />
                  </td>
                  <td className="px-6 py-3 font-medium">
                    <button
                      className={
                        item.status === 0 ? "bg-[#9C9EAB] cursor-pointer  text-red-700 px-3 py-1 rounded-full" : "bg-[#023565] text-white px-3 py-1 rounded-full"
                      }
                      disabled={item.status !== 0}
                    >
                      {item.status === 0 ? "Pending" : "Resolved"}
                    </button>
                  </td>
                </tr>
              ))
              ) : (
                <tr>
                  <td colSpan={5} className="px-6 py-4 font-[400] text-base text-[#313131] whitespace-nowrap text-center">Data Not Available</td>
                </tr>
              )
            }
          </tbody>
        </table>
      </section>
      {msgModal && (

        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40">
          <div className="relative w-11/12 mx-auto lg:w-[600px]">
            <button
              className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center"
              onClick={() => {
                setMsgModal(false)
                setReply("")
              }}
            >
              <span><MdOutlineClose /></span> Close
            </button>
            <div className="form_class border-0 rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              {msgData?.map((item, id) => (
                <>
                  <div className="flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                    <h2 className="text-lg font-semibold">Chat</h2>
                    <p className="cursor-pointer" onClick={() => refreshChat(item._id)}>
                      <MdRefresh color="#023565" size={26} />
                    </p>
                  </div>
                  <div className="relative overflow-y-auto h-[400px] p-4 border-b">
                    <div className="flex flex-col w-full max-w-[320px] p-4 border-gray-200  rounded-xl bg-gray-200">
                      <div className="flex justify-start space-x-2  rtl:space-x-reverse">
                        <span className="text-sm font-semibold  text-[#023565] capitalize">
                          {item?.userId?.name}

                        </span>
                      </div>
                      <div className="">
                        <p className="text-sm text-start font-normal text-[#023565]">
                          {item?.msg}
                        </p>
                        <span className="flex justify-end text-sm font-normal text-[#023565]">
                          {timeZoneReturnDateType(item?.createdAt)}
                        </span>
                      </div>

                    </div>

                    <div className="flex flex-col">
                      <div className="w-3/6 flex flex-col space-y-2">
                        {ticketReply &&
                          ticketReply.map((reply, id) => {
                            return String(reply.isAdmin) === "1" ? (
                              <div className="flex  justify-start flex-col w-full max-w-[320px] p-4 border-gray-200  rounded-xl bg-gray-200" key={id}>
                                <div className="flex justify-start space-x-2  rtl:space-x-reverse">
                                  <span className="text-sm font-semibold  text-[#023565]">
                                    {reply?.userId?.name}

                                  </span>

                                </div>
                                <div className="">
                                  <p className="text-sm font-semibold  text-[#65798b]">
                                    {reply?.msg}
                                  </p>
                                  <span className="flex justify-end text-[#023565]">
                                    {timeZoneReturnDateType(reply?.createdAt)}
                                  </span>
                                </div>

                              </div>
                            ) :
                              (<div
                                key={id}
                                className="flex border-2 justify-end ms-[100%] flex-col w-full max-w-[320px] p-4 mt-1 bg-[#023565] rounded-xl"
                              >
                                <span className="font-bold text-[#fff] table_head">
                                  Admin
                                </span>
                                <div>
                                  <p className="text-sm font-normal text-white dark:text-white break-all">
                                    {reply?.msg}
                                    <span className="flex justify-end">
                                      {timeZoneReturnDateType(reply?.createdAt)}

                                    </span>
                                    <span ref={endmsgUseRef} />
                                  </p>
                                </div>

                              </div>)
                          })}
                      </div>
                    </div>
                  </div>

                  <div className="m-4">
                    <form
                      className="flex items-center justify-center max-w-lg mx-auto"
                      onSubmit={(e) => handleSubmit(e, item)}
                    >
                      <div className="flex items-center gap-4 relative w-full">

                        <textarea
                          rows={1}
                          type="text"
                          value={reply}
                          className=" p-2 border block w-full border-gray-300 rounded-md"
                          placeholder="Reply..."
                          onChange={(e) => {
                            const textarea = e.target;
                            textarea.style.height = "auto"; // Reset height to calculate content height
                            const maxHeight = parseInt(window.getComputedStyle(textarea).lineHeight) * 4; // Maximum height for 4 rows
                            const newHeight = Math.min(textarea.scrollHeight, maxHeight); // Set height based on scrollHeight, capped at maxHeight
                            textarea.style.height = `${newHeight}px`; // Apply the new height
                            setReply(e.target.value)
                          }}
                          required
                        />
                        <button
                          type="submit"
                          className="w-24 mx-auto bg-[#023565] text-white font-semibold py-2 px-6 rounded-full "
                        >
                          Send
                        </button>
                      </div>
                    </form>
                  </div>
                </>
              ))}
            </div>
          </div>
        </div>
      )}
      <section className="space-y-5 py-10 lg:hidden block">

        <div className='flex justify-between items-center p-2 bg-[#033565] rounded-t-lg'>
          <h2 className='text-base font-semibold text-white'>Latest Issues</h2>
          <button className="text-end font-semibold bg-white rounded px-4 py-2 text-blue-900 cursor-pointer hover:scale-95 transition-all duration-300 ease-in-out flex items-center gap-2" onClick={() => navigate("/app/help&support")}>View All <span><FaLongArrowAltRight /></span></button>
        </div>

        {newTicketList && newTicketList.length > 0 ? (
          newTicketList?.map((item) => (
            <div className=' bg-white p-4 rounded-[10px] border border-[#023565]'>
              <>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>	Name </p>
                  </div>
                  <div className='w-full'>
                    <p className='font-semibold whitespace-nowrap text-[#023565]'>{item?.userId?.name ?? 'No Data'}</p>
                    <p className='text-[12px] '>{item?.userId?.mobNo ?? 'No Data'}</p>
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>Profile</p>
                  </div>
                  <div className='w-full'>
                    <p>{item?.userId?.userType ?? 'No Data'}</p>
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>	City</p>
                  </div>
                  <div className='w-full'>
                    <p>{item?.cityId?.name ?? 'No Data'}</p>
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>
                      Subject
                    </p>
                  </div>
                  <div className='w-full'>
                    <p>{item?.title ?? 'No Title'}</p>
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>
                      Status
                    </p>
                  </div>
                  <div className='w-full'>
                    <p> <button
                      className={
                        item.status === 0 ? "bg-[#9C9EAB] cursor-pointer  text-red-700 px-3 py-1 rounded-full" : "bg-[#023565] text-white px-3 py-1 rounded-full"
                      }
                      disabled={item.status !== 0}
                    >
                      {item.status === 0 ? "Pending" : "Resolved"}
                    </button></p>
                  </div>
                </div>
              </>
            </div>

          ))

        ) : (
          <p className="text-center">Data Not Available</p>
        )

        }

      </section>


    </div>
  )
}

export default ButtonFour
