import React from 'react'
import { MdOutlineClose } from 'react-icons/md'

function EditBanner({ edit, handleDelete, handleSubmitUpdate, editItem, inputUpdate, uploadEditImage, error }) {
    return (
        <div>
            {edit && (
                <>

                    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40">
                        <div className="relative w-11/12 mx-auto lg:w-[600px]">
                            <button
                                className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center"
                                onClick={handleDelete}
                            >
                                <span><MdOutlineClose /></span> Close
                            </button>
                            <div className="form_class border-0 rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                <div className="flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                    <h2 className="text-lg font-semibold">Edit Banner</h2>
                                </div>
                                <div className="relative  overflow-y-auto">
                                    <form onSubmit={handleSubmitUpdate} className="space-y-2 p-4">
                                        <div className="space-y-2">
                                            <label htmlFor="name" className="block mb-1">Banner Name:</label>
                                            <input
                                                name="name"
                                                id="name"
                                                type="text"
                                                value={editItem.name}
                                                required
                                                placeholder="Enter name"
                                                onChange={inputUpdate}
                                                className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                                            />
                                        </div>
                                        <div className="space-y-2">
                                            <label
                                                htmlFor="countries"
                                                className="block mb-1"
                                            >
                                                Select Type:
                                            </label>
                                            <select
                                                onChange={inputUpdate}
                                                id="countries"
                                                name="userType"
                                                required
                                                value={editItem.userType}
                                                className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                                            >
                                                <option selected="">Choose Type</option>
                                                <option value="PARENT">Parent</option>
                                                <option value="TUTOR">Tutor</option>
                                            </select>
                                        </div>
                                        <div className="mb-4 border-b border-black">
                                            <label
                                                htmlFor="countries"
                                                className="block mb-1"
                                            >
                                                Upload Image :
                                            </label>
                                            <div className="p-2">

                                                <input
                                                    type="file"
                                                    id="image"
                                                    accept="image/*"
                                                    onChange={uploadEditImage}
                                                    className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                                                />
                                                {error && <p className="text-red-500 mt-2">{error}</p>}
                                                {editItem.image && (
                                                    <div className="mt-4">
                                                        <img
                                                            src={editItem.image}
                                                            alt={editItem.name}
                                                            className="w-[120px] h-auto rounded"
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="flex justify-center items-center pt-2">
                                            <button
                                                type="submit"
                                                className="w-60 mx-auto bg-[#023565] text-white font-semibold py-2 px-4 rounded-full "
                                            >
                                                Update
                                            </button>
                                        </div>
                                    </form>
                                </div>

                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}

export default EditBanner
