export const walletConstant = {
    GET_WALLET_LIST_REQUEST: "GET_WALLET_LIST_REQUEST",
    GET_WALLET_LIST_SUCCESS: "GET_WALLET_LIST_SUCCESS",
    GET_WALLET_LIST_FAILURE: "GET_WALLET_LIST_FAILURE",

    ADD_BONUS_USER_WALLET_REQUEST: "ADD_BONUS_USER_WALLET_REQUEST",
    ADD_BONUS_USER_WALLET_SUCCESS: "ADD_BONUS_USER_WALLET_SUCCESS",
    ADD_BONUS_USER_WALLET_FAILURE: "ADD_BONUS_USER_WALLET_FAILURE",

    GET_WALLET_HISTORY_LIST_REQUEST: "GET_WALLET_HISTORY_LIST_REQUEST",
    GET_WALLET_HISTORY_LIST_SUCCESS: "GET_WALLET_HISTORY_LIST_SUCCESS",
    GET_WALLET_HISTORY_LIST_FAILURE: "GET_WALLET_HISTORY_LIST_FAILURE",

    UPDATE_WALLET_STATUS_REQUEST: "UPDATE_WALLET_STATUS_REQUEST",
    UPDATE_WALLET_STATUS_SUCCESS: "UPDATE_WALLET_STATUS_SUCCESS",
    UPDATE_WALLET_STATUS_FAILURE: "UPDATE_WALLET_STATUS_FAILURE",

    UPDATE_WALLET_REQUEST: "UPDATE_WALLET_REQUEST",
    UPDATE_WALLET_SUCCESS: "UPDATE_WALLET_SUCCESS",
    UPDATE_WALLET_FAILURE: "UPDATE_WALLET_FAILURE",

    UPDATE_WALLET_REWARD_REQUEST: "UPDATE_WALLET_REWARD_REQUEST",
    UPDATE_WALLET_REWARD_SUCCESS: "UPDATE_WALLET_REWARD_SUCCESS",
    UPDATE_WALLET_REWARD_FAILURE: "UPDATE_WALLET_REWARD_FAILURE",


}