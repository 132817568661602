import { cityConstants } from "../_constants";

export default function Citys(state = {}, action) {
  switch (action.type) {
    case cityConstants.GET_CITY_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case cityConstants.GET_CITY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        cityGetList: action?.users?.data,
        getCityToal: action?.users
      };
    case cityConstants.GET_CITY_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case cityConstants.CREATE_CITY_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case cityConstants.CREATE_CITY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        cityCreate: action?.users
      };
    case cityConstants.CREATE_CITY_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case cityConstants.DELETE_CITY_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case cityConstants.DELETE_CITY_LIST_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case cityConstants.DELETE_CITY_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case cityConstants.UPDATE_CITY_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case cityConstants.UPDATE_CITY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case cityConstants.UPDATE_CITY_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      }
    case cityConstants.ENA_DIS_CITY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case cityConstants.ENA_DIS_CITY_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case cityConstants.ENA_DIS_CITY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      }
    case cityConstants.GET_CITY_DETAILS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case cityConstants.GET_CITY_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        getCityById: action.users
      };
    case cityConstants.GET_CITY_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      }
    case cityConstants.GET_CITY_LIST_PAGE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case cityConstants.GET_CITY_LIST_PAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        getCityList: action.users
      };
    case cityConstants.GET_CITY_LIST_PAGE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      }
    default:
      return state;
  };

}