/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { parentAction, tutionAction, userActions } from '../../../_actions'
import {
  FaAngleRight,
  FaChevronCircleRight,
  FaChevronLeft,
  FaEye
} from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { MdOutlineClose } from 'react-icons/md'
import { FaChevronRight } from 'react-icons/fa'
import { confirmAlert } from 'react-confirm-alert'
import './tution.css'
import Loader from '../../../components/Loader'
import StudentDetail from './Models/StudentDetail'
import ImagePreviewModal from '../../Tutor-Profile/Model/ImagePreview'
import UpdateFeeModel from './Models/UpdateFeeModel'
import UpdateNoteModel from './Models/UpdateNoteModel'
import ViewParent from './Models/ViewParent'
import TuitionFilters from './TuitionFilters'
import ViewMoreTuitionModel from './Models/ViewMoreTuitionModel'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { truncateText } from '../../../_helpers'
import AssignTutorPopUp from './Models/AssignTutorPopUp'

const ChangeTutorRequest = ({ searchText, headerCity }) => {
  const dispatch = useDispatch()
  const selector = useSelector(state => state)

  const navigate = useNavigate()
  let [demoData, setDemoData] = useState()

  let allTutorDataList = selector?.tution?.getAllTutorList?.data
    ? selector?.tution?.getAllTutorList?.data
    : []

  const [getDemoById, setGetDemoById] = useState()
  const [suggestedTutor, setSuggestedTutor] = useState()

  const [studentListdata, setStudentListData] = useState([])
  const [viewStudentModal, setViewStudentModal] = useState(false)
  const [data, setData] = useState('')
  const [open, setOpen] = useState(false)
  const [assignTutorModal, setAssignTutorModal] = useState(false)
  const [assignTutorList, setAssignTutorList] = useState('')
  const [demoIdForAssign, setDemoIdForAssign] = useState({})
  const [classmodeModal, setClassmodeModal] = useState(false)
  const [demoIdForClassMode, setDemoIdForClassMode] = useState('')
  const [assignTutorTab, setAssignTutorTab] = useState('Suggested Tutors')
  const [parentId, setParentId] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const pageSize = 10
  const [filters, setFilters] = useState({
    tuitionFee: '',
    tutorFee: '',
    preferredTeachMode: '',
    tutorId: '',
    classId: '',
    subjectId: '',
    classReference: '',
    parentId: ''
  })

  const [openMenu, setOpenMenu] = useState(null)
  const menuRef = useRef(null)
  const mobileRef = useRef(null)

  const handleClickOutside = event => {
    if (
      menuRef.current &&
      !menuRef.current.contains(event.target) &&
      mobileRef.current &&
      !mobileRef.current.contains(event.target)
    ) {
      setOpenMenu(null)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const openAction = id => {
    setOpenMenu(id === openMenu ? null : id)
  }

  let suggestTutorBtn = ['Applicants', 'Suggested Tutors', 'All Tutors']

  useEffect(() => {
    let data = {
      status: [10],
      pageNo: currentPage,
      size: pageSize,
      ...filters,
      keyWord: searchText,
      cityId: headerCity ? [headerCity] : '',
      tutorId: filters.tutorId !== '' ? [filters.tutorId] : [],
      parentId: filters.parentId !== '' ? [filters.parentId] : []
    }
    // dispatch(tutionAction.getChangeTutorRequestTuitionList(data))
    dispatch(tutionAction.getDemoClassListStatus(data, data.cityId))
  }, [currentPage, filters, searchText, headerCity])

  useEffect(() => {
    // if (selector?.tution?.getChangeTutorData?.data?.total) {
    //   setTotalPages(Math.ceil(selector?.tution?.getChangeTutorData?.data?.total / pageSize));
    // }
    // setGetDemoById(selector?.tution?.getDemoById?.data)
    // setSuggestedTutor(selector?.tution?.suggestedTutorList?.data)
    // setDemoData(selector?.tution?.getChangeTutorData?.data?.list)
    // setFullData(() => selector?.userList?.getTutorUpdateById?.data)
    if (selector?.tution?.demoList?.data?.total) {
      setTotalPages(
        Math.ceil(selector?.tution?.demoList?.data?.total / pageSize)
      )
    }
    setGetDemoById(selector?.tution?.getDemoById?.data)
    setSuggestedTutor(selector?.tution?.suggestedTutorList?.data)
    setDemoData(selector?.tution?.demoList?.data?.list)
  }, [selector])

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1)
  }

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1)
  }

  const handleClickData = datas => {
    setParentId(datas._id)

    let resData = {
      parentId: datas._id
    }
    dispatch(tutionAction.activeDemoListByParentId(resData))
    setOpen(true)
  }

  useEffect(() => {
    setData(() => selector?.tution?.activeDemoList?.data)
  }, [selector])

  const closeModal = () => {
    setOpen(false)
  }

  const assignTutor = data => {
    let query = {
      teachingMode: data?.preferredTeachMode
    }
    if (data?.preferredTeachMode === 'HOME_TUITION') {
      query['city'] = data?.cityId?._id
    }
    dispatch(tutionAction.suggestedTutorListForAdmin({ demoId: data?._id }))
    dispatch(tutionAction.getAllTutorList(query))

    setAssignTutorModal(true)
    setAssignTutorList(data)
    setDemoIdForAssign(data._id)
  }

  const viewStudent = (data, demoId) => {
    let studentListDetails = {
      parentId: data._id,
      demoId: demoId
    }
    parentAction
      .getStudentListByParentIdAndDemoIdPromise(studentListDetails)
      .then(resData => {
        setViewStudentModal(true)
        setStudentListData(resData.data)
      })
      .catch(() => {})
  }

  const handleAssignTutor = item => {
    setAssignTutorTab(item)
  }
  const handleAssign = data => {
    let Demodata = {
      status: [10],
      keyWord: '',
      fromDate: '',
      toDate: '',
      sortOrder: '',
      sortBy: '',
      pageNo: 1,
      size: 10
    }
    let assignTutorInfo = {
      demoId: demoIdForAssign,
      tutorId: data
    }

    dispatch(tutionAction.assignTutorByAdmin(assignTutorInfo, Demodata))
    setAssignTutorModal(false)
  }

  const handleViewHistory = data => {
    navigate(`/app/viewhistory/${data?._id}`)
  }

  const handlePutOnHold = data => {
    let datas = {
      pageNo: currentPage,
      size: pageSize,
      ...filters,
      status: [10],
      keyWord: searchText,
      cityId: headerCity ? [headerCity] : '',
      tutorId: filters.tutorId !== '' ? [filters.tutorId] : [],
      parentId: filters.parentId !== '' ? [filters.parentId] : []
    }
    let classHoldDetails = {
      tuitionId: data._id
    }
    dispatch(tutionAction.tuitionHoldByAdmin(classHoldDetails, datas))
    const alertBox = document.getElementById('alert-box')
    alertBox.style.display = 'block'
    setTimeout(() => {
      alertBox.style.display = 'none'
    }, 3000)

    setOpen(false)
  }
  const handleCloseClass = data => {
    let closeClassDetails = {
      demoId: data._id
    }

    let demoData = {
      pageNo: currentPage,
      size: pageSize,
      ...filters,
      status: [10],
      keyWord: searchText,
      cityId: headerCity ? [headerCity] : '',
      tutorId: filters.tutorId !== '' ? [filters.tutorId] : [],
      parentId: filters.parentId !== '' ? [filters.parentId] : []
    }

    confirmAlert({
      title: 'Confirm to Close Class?',
      message: `Are you sure to Close Class ?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () =>
            dispatch(
              tutionAction.closeTuitionClassByAdmin(closeClassDetails, demoData)
            )
        },
        {
          label: 'No'
        }
      ]
    })
    setOpen(false)
  }

  const handleClassMode = data => {
    setClassmodeModal(true)
    setDemoIdForClassMode(data._id)
  }
  const handleHomeClassMode = data => {
    alert(`Are you sure Want to change class mode`)

    let parentIdSet = {
      parentId
    }
    const resData = {
      demoId: demoIdForClassMode,
      preferredTeachMode: data.status
    }
    dispatch(tutionAction.updateTuitionMode(resData, parentIdSet))
    setClassmodeModal(false)
  }

  const [updateFeeModal, setUpdateFeeModal] = useState(false)
  const [updatedFeeData, setUpdateFeeData] = useState('')

  const handleview = data => {
    setUpdateFeeModal(true)
    setUpdateFeeData(data)
  }

  const handleupdateFeeData = e => {
    const { name, value } = e.target
    setUpdateFeeData({ ...updatedFeeData, [name]: value })
  }

  const handleFeeDataSubmit = e => {
    e.preventDefault()
    let dataForUpdate = {
      demoId: updatedFeeData._id,
      tuitionFee: updatedFeeData.tuitionFee,
      numberOfClasses: updatedFeeData.numberOfClasses,
      referenceFee: String(updatedFeeData.referenceFee),
      tutorFee: updatedFeeData.tutorFee,
      companyShare: String(updatedFeeData.companyShare),
      classDuration: updatedFeeData.classDuration
    }

    let parentIdSet = {
      parentId
    }
    dispatch(tutionAction.UpdateInfoActiveOnApp(dataForUpdate, parentIdSet))
    setUpdateFeeModal(false)
  }

  const handleSendToDemoRequest = data => {
    let Demodata = {
      status: [10],
      keyWord: '',
      fromDate: '',
      toDate: '',
      sortOrder: '',
      sortBy: '',
      pageNo: 1,
      size: 10,
      ...filters,
      tutorId: filters.tutorId !== '' ? [filters.tutorId] : [],
      cityId: headerCity ? [headerCity] : '',
      parentId: filters.parentId !== '' ? [filters.parentId] : []
    }

    let sendToDemoDetails = {
      demoId: data._id
    }

    dispatch(tutionAction.revertDemoClass(sendToDemoDetails, Demodata))
  }

  const [remarkModal, setRemarkModal] = useState(false)
  const [remarkId, setRemarkId] = useState('')
  const [remark, setRemark] = useState('')

  const viewNoteData = data => {
    let remarkId = data?._id
    setRemarkId(remarkId)
    setRemarkModal(true)
    setRemark(data?.reason ?? 'N/A')
  }

  const handleRemarkSubmit = e => {
    e.preventDefault()
    let demoData = {
      pageNo: currentPage,
      size: pageSize,
      ...filters,
      status: [10],
      keyWord: searchText,
      cityId: headerCity ? [headerCity] : '',
      tutorId: filters.tutorId !== '' ? [filters.tutorId] : [],
      parentId: filters.parentId !== '' ? [filters.parentId] : []
    }
    let remarkData = {
      demoId: remarkId,
      remark: remark
    }
    let parentIdSet = {
      parentId
    }
    dispatch(tutionAction.addUpdateRemark(remarkData, demoData, parentIdSet))
    setRemarkModal(false)
    setRemark('')
  }

  const remarkClose = () => {
    setRemark('')
    setRemarkModal(false)
  }

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [image, setImage] = useState('')
  const handleCloseFunctionImageModel = data => {
    if (data) {
      setImage(() => data)
    } else {
      setImage('')
    }
    setIsModalVisible(!isModalVisible)
  }
  const [fullDetails, setFullDetails] = useState(false)
  const [DetailsData, setFullDetailsData] = useState()
  const [tuitionDetails, setTuitionDetails] = useState({})
  const handleFullDetails = data => {
    userActions.getUserByPromiseId({ id: data?.parentId?._id }).then(res => {
      setFullDetails(true)
      setFullDetailsData(res?.data)
      setTuitionDetails({
        classDuration: data?.classDuration,
        tuitionFee: data?.tuitionFee,
        numberOfClasses: data?.numberOfClasses,
        tutorFee: data?.tutorFee,
        tuitionType: data?.tuitionType,
        location: data?.location
      })
    })
  }

  const [isViewMoreModel, setIsViewMoreModel] = useState(false)
  const tuitionId = ''

  const handleDeniedRequest = data => {
    let deniedDetails = {
      demoId: data._id
    }

    let demoData = {
      pageNo: currentPage,
      size: pageSize,
      ...filters,
      status: [10],
      keyWord: searchText,
      cityId: headerCity ? [headerCity] : '',
      tutorId: filters.tutorId !== '' ? [filters.tutorId] : [],
      parentId: filters.parentId !== '' ? [filters.parentId] : []
    }

    confirmAlert({
      title: 'Confirm to Denied Request?',
      message: `Are you sure to denied this request ?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () =>
            dispatch(
              tutionAction.deniedChangeTutorRequest(deniedDetails, demoData)
            )
        },
        {
          label: 'No'
        }
      ]
    })
    setOpenMenu(null)
  }

  return (
    <section>
      <Loader loading={selector?.tution?.loading} />

      <TuitionFilters filters={filters} setFilters={setFilters} />

      <section className='lg:block hidden py-6'>
        <div className=' border border-[#D3D3D3] rounded-xl overflow-x-auto'>
          <table className='w-full bg-white rounded-xl'>
            <thead className='border-b border-[#D1D1D1]'>
              <tr className='text-[#313131]'>
                {/* <th className="px-6 py-3 whitespace-nowrap text-sm text-black">S.No.</th> */}
                <th className='px-6 py-3 whitespace-nowrap text-sm text-black'>
                  Parent Name
                </th>
                <th className='px-6 py-3 whitespace-nowrap text-sm text-black'>
                  Student
                </th>
                <th className='px-6 py-3 whitespace-nowrap text-sm text-black'>
                  Address / City
                </th>
                <th className='px-6 py-3 whitespace-nowrap text-sm text-black'>
                  Tuition Fee
                </th>
                <th className='px-6 py-3 whitespace-nowrap text-sm text-black'>
                  Reference
                </th>
                <th className='px-6 py-3 whitespace-nowrap text-sm text-black'>
                  Tutor fee
                </th>
                <th className='px-6 py-3 whitespace-nowrap text-sm text-black'>
                  Tuition Mode
                </th>
                <th className='px-6 py-3 whitespace-nowrap text-sm text-black'>
                  Company
                </th>
                <th className='px-6 py-3 whitespace-nowrap text-sm text-black'>
                  Action
                </th>
              </tr>
            </thead>
            <tbody className='bg-white divide-y divide-gray-200'>
              {demoData && demoData.length > 0 ? (
                demoData?.map((item, index) => (
                  <tr key={index} className=' border-b capitalize'>
                    {/* <td className="px-4 py-4 text-sm text-gray-900 text-center">{index + 1}</td> */}
                    <td className='px-4 py-4 flex items-center gap-4 text-sm text-gray-900'>
                      <div className='flex items-center justify-center gap-2'>
                        <span
                          onClick={
                            item?.parentId?.image
                              ? () =>
                                  handleCloseFunctionImageModel(
                                    item?.parentId?.image
                                  )
                              : null
                          }
                        >
                          {item?.parentId && item?.parentId?.image ? (
                            <img
                              alt=''
                              src={
                                item?.parentId && item?.parentId?.image
                                  ? item?.parentId?.image
                                  : 'https://thumbs.dreamstime.com/z/vector-illustration-avatar-dummy-logo-collection-image-icon-stock-isolated-object-set-symbol-web-137160339.jpg?ct=jpeg'
                              }
                              onError={e => {
                                e.target.src =
                                  'https://thumbs.dreamstime.com/z/vector-illustration-avatar-dummy-logo-collection-image-icon-stock-isolated-object-set-symbol-web-137160339.jpg?ct=jpeg'
                              }}
                              className='rounded-full my-3 max-w-[50px] max-h-[50px] min-w-[50px] min-h-[50px]'
                            />
                          ) : (
                            <div className='bg-gray-300 rounded-full h-14 w-14 flex justify-center items-center'>
                              <span className='text-base font-semibold text-[#033565]'>
                                {' '}
                                {item?.parentId?.name
                                  ? item.parentId.name
                                      .split(' ')
                                      .map(name => name.charAt(0))
                                      .join('')
                                  : ''}
                              </span>
                            </div>
                          )}
                        </span>
                        <div className='w-24'>
                          <p className='font-semibold whitespace-nowrap text-[#023565]'>
                            {item && item?.parentId?.name
                              ? item?.parentId?.name
                              : 'No Data'}
                          </p>
                          <p className='text-gray-500 whitespace-nowrap'>
                            {item && item?.parentId?.mobNo
                              ? item?.parentId?.mobNo
                              : null}
                          </p>

                          <p className='text-gray-500 whitespace-nowrap'>
                            <small>
                              {item && item.statusDate ? item.statusDate : null}
                            </small>
                          </p>
                          <div
                            className='flex items-center  gap-1 cursor-pointer'
                            onClick={() => handleFullDetails(item)}
                          >
                            <p className='text-base font-normal cursor-pointer'>
                              More
                            </p>
                            <FaChevronCircleRight className='text-[#023565] text-base cursor-pointer' />
                          </div>
                        </div>
                      </div>
                    </td>

                    <td className='px-4 py-4 text-sm'>
                      <span className='flex items-center justify-center'>
                        <FaEye
                          size={24}
                          className='cursor-pointer text-[#023565] hover:text-[#E4006F]'
                          onClick={() => viewStudent(item?.parentId, item._id)}
                        />
                      </span>
                    </td>
                    <td className='px-4 py-4 text-sm text-gray-900'>
                      <span
                        className='flex justify-center items-center text-center cursor-pointer'
                        title={item?.address || 'N/A'}
                      >
                        {' '}
                        {truncateText(item?.address, 15) ?? 'N/A'}
                      </span>
                      <span className='flex justify-center items-center'>
                        {item.cityId?.name ?? 'N/A'}
                      </span>
                    </td>
                    <td className='px-4 py-4 text-sm text-gray-900 text-center'>
                      {item.tuitionFee}
                    </td>
                    <td className='px-4 py-4 text-sm text-gray-900 text-center'>
                      {item.referenceFee}
                    </td>
                    <td className='px-4 py-4 text-sm text-gray-900 text-center'>
                      {item.tutorFee}
                    </td>
                    <td className='px-4 py-4 text-sm text-gray-900 text-center'>
                      {item?.preferredTeachMode === 'ONLINE_TUITION'
                        ? 'Online Tuition'
                        : 'In-Home'}
                    </td>
                    <td className='px-4 py-4 text-sm text-gray-900 text-center'>
                      {item.companyShare}
                    </td>

                    <td
                      className='px-4 py-4 text-sm table-cell'
                      overflow-x-scroll
                    >
                      <span className='flex items-center justify-center'>
                        <BsThreeDotsVertical
                          size={24}
                          className='cursor-pointer text-[#023565] hover:text-[#E4006F]'
                          onClick={() => openAction(item._id)}
                        />
                      </span>

                      {openMenu === item._id && (
                        <ul
                          className='bg-white text-[#023565] border rounded-lg w-auto fixed right-16 mt-1 text-start p-3 text-sm font-bold'
                          ref={menuRef}
                        >
                          <li
                            className=' border-b py-1 flex justify-between gap-5 place-items-center hover:text-[#E4006F]'
                            onClick={() => assignTutor(item)}
                          >
                            <button>Change Tutor</button>
                            <FaAngleRight className='hover:text-[#C60E6B]' />
                          </li>
                          <li
                            className='text-[#023565] border-b py-1 flex justify-between place-items-center hover:text-[#E4006F]'
                            onClick={() => handleSendToDemoRequest(item)}
                          >
                            <button>Send to demo request</button>
                            <FaAngleRight className='hover:text-[#C60E6B]' />
                          </li>
                          <li
                            className='text-[#023565] flex justify-between place-items-center border-b py-1 hover:text-[#E4006F]'
                            onClick={() => handleDeniedRequest(item)}
                          >
                            <button>Deny Request</button>
                            <FaAngleRight className='hover:text-[#C60E6B]' />
                          </li>
                          <li
                            className='text-[#023565] flex justify-between place-items-center border-b py-1 hover:text-[#E4006F]'
                            onClick={() => handleCloseClass(item)}
                          >
                            <button>Close Class</button>
                            <FaAngleRight className='hover:text-[#C60E6B]' />
                          </li>
                        </ul>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan='11' className='text-center'>
                    Data Not Available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </section>

      <section className='space-y-5 lg:hidden block py-6'>
        {demoData && demoData.length > 0 ? (
          demoData?.map((item, index) => (
            <div key={index} className=' bg-white p-4 rounded-[10px]'>
              <>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[14px] font-[600] text-left'>
                      Parent Name
                    </p>
                  </div>
                  <div className='w-full flex items-start'>
                    <div className='flex justify-start  gap-2 w-[95%]'>
                      <div
                        onClick={
                          item?.parentId?.image
                            ? () =>
                                handleCloseFunctionImageModel(
                                  item?.parentId?.image
                                )
                            : null
                        }
                      >
                        {item?.parentId && item?.parentId?.image ? (
                          <img
                            alt=''
                            src={
                              item?.parentId && item?.parentId?.image
                                ? item?.parentId?.image
                                : ''
                            }
                            className='rounded  max-w-[50px] max-h-[50px] min-w-[50px] min-h-[50px]'
                          />
                        ) : (
                          <div className='bg-gray-300 rounded h-14 w-14 flex justify-center items-center'>
                            <span className='text-base font-semibold text-[#033565]'>
                              {' '}
                              {item?.parentId?.name
                                ? item.parentId.name
                                    .split(' ')
                                    .map(name => name.charAt(0))
                                    .join('')
                                : ''}
                            </span>
                          </div>
                        )}
                      </div>
                      <div className=''>
                        <p className='text-[12px] font-semibold whitespace-nowrap text-[#023565]'>
                          {/* {item?.parentId?.name ?? 'Not Available'} */}
                          {truncateText(item?.parentId?.name, 10) ?? 'N/A'}
                        </p>
                        <p className='text-[12px] font-medium text-gray-500 whitespace-nowrap'>
                          {item?.parentId?.mobNo ?? 'Not Available'}
                        </p>
                        <div className='flex items-center  gap-3 cursor-pointer'>
                          <div
                            className='flex items-center  gap-1'
                            onClick={() => handleFullDetails(item)}
                          >
                            <p className='text-[12px] font-medium cursor-pointer'>
                              More
                            </p>
                            <FaChevronCircleRight className='text-[#023565] text-base cursor-pointer' />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='w-[5%] relative'>
                      <BsThreeDotsVertical
                        onClick={() => openAction(item._id)}
                        className='text-2xl text-[#C60E6B]'
                      />
                      {openMenu === item._id && (
                        <div
                          className='bg-white absolute w-48 right-2 space-y-2 divide-y border rounded-md text-[#023565] py-2 px-2 text-start text-sm font-semibold'
                          ref={mobileRef}
                        >
                          <div
                            className='flex justify-between place-items-center hover:text-[#E4006F] hover:bg-gray-100 cursor-pointer'
                            onClick={() => assignTutor(item)}
                          >
                            <button>Change Tutor</button>
                            <FaAngleRight className='hover:text-[#C60E6B]' />
                          </div>
                          <div className='flex justify-between place-items-center hover:text-[#E4006F] hover:bg-gray-100 cursor-pointer'>
                            <button
                              onClick={() => handleSendToDemoRequest(item)}
                            >
                              Send To Demo Request
                            </button>
                            <FaAngleRight className='hover:text-[#C60E6B]' />
                          </div>
                          <div className='flex justify-between place-items-center hover:text-[#E4006F] hover:bg-gray-100 cursor-pointer'>
                            <button onClick={() => handleDeniedRequest(item)}>
                              Deny Request
                            </button>
                            <FaAngleRight className='hover:text-[#C60E6B]' />
                          </div>
                          <div className='flex justify-between place-items-center hover:text-[#E4006F] hover:bg-gray-100 cursor-pointer'>
                            <button onClick={() => handleCloseClass(item)}>
                              Close Class
                            </button>
                            <FaAngleRight className='hover:text-[#C60E6B]' />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>Student</p>
                  </div>
                  <div className='w-full'>
                    <p
                      className='text-[14px] font-[700] text-[#023565]'
                      onClick={() => viewStudent(item?.parentId, item._id)}
                    >
                      {' '}
                      View
                    </p>
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>Address</p>
                  </div>
                  <div className='w-full'>
                    <p className='text-[14px] font-[400]'>
                      {' '}
                      {item?.address || 'N/A'}
                    </p>
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>City</p>
                  </div>
                  <div className='w-full'>
                    <p className='text-[14px] font-[400] capitalize'>
                      {' '}
                      {item?.cityId?.name ?? 'No City'}
                    </p>
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>
                      Tuition Fee
                    </p>
                  </div>
                  <div className='w-full'>
                    <p className='text-[14px] font-[400]'>
                      {' '}
                      {item.tuitionFee}{' '}
                    </p>
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>
                      Reference
                    </p>
                  </div>
                  <div className='w-full'>
                    <p className='text-[14px] font-[400]'>
                      {' '}
                      {item.referenceFee}
                    </p>
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>
                      Tutor Fee
                    </p>
                  </div>
                  <div className='w-full'>
                    <p className='text-[14px] font-[400]'>{item.tutorFee}</p>
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>Company</p>
                  </div>
                  <div className='w-full'>
                    <p className='text-[14px] font-[400]'>
                      {item.companyShare}
                    </p>
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>
                      Tuition Mode{' '}
                    </p>
                  </div>
                  <div className='w-full'>
                    <p className='text-[14px] font-[400]'>
                      {item?.preferredTeachMode === 'ONLINE_TUITION'
                        ? 'Online Tuition'
                        : 'In-Home'}
                    </p>
                  </div>
                </div>
              </>
            </div>
          ))
        ) : (
          <p className='text-center md:hidden'>Data Not Found</p>
        )}
      </section>

      <div
        id='alert-box'
        class='hidden fixed top-1/2 z-50 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-[#023565] p-6 rounded-lg shadow-lg text-center'
      >
        <img
          src='/tuition-on-hold.png'
          alt='Alert Icon'
          class='w-12 h-12 mx-auto rounded shadow-md'
        />
        <h1 class='text-xl font-bold text-[#fff] mt-4'>
          {' '}
          Tuition put on hold!
        </h1>
        <p class='text-gray-200 mt-2'>Your tuition has been put on hold</p>
      </div>

      {open && (
        <>
          <div className='fixed inset-0 flex items-center justify-center bg-gray-700 bg-opacity-50 z-50'>
            <div>
              <div className='flex justify-end'>
                <button
                  className='z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center'
                  onClick={closeModal}
                >
                  <span>
                    <MdOutlineClose />
                  </span>{' '}
                  Close
                </button>
              </div>
              <div className='bg-white rounded-lg overflow-hidden shadow-xl max-h-[90vh] overflow-y-auto'>
                <div className='p-4 grid  lg:grid-cols-2   gap-4'>
                  {data && data.length > 0 ? (
                    data.map((item, index) => (
                      <div
                        key={index}
                        className='border border-[#C7C4C4] rounded-lg p-4'
                      >
                        <div className='border-b pb-2 mb-2 flex justify-between items-center bg-[#F1F1F1] p-2 rounded-t-lg'>
                          <strong>Batch {index + 1}</strong>
                        </div>
                        <div className='space-y-2'>
                          <div className='flex justify-between'>
                            <p>
                              <strong>Student Name:</strong>
                            </p>
                            <p className='capitalize'>
                              {item?.bookFreeDemo?.map((itm, id) => (
                                <div key={id}>
                                  {itm?.studentId?.studentFullName}
                                </div>
                              ))}
                            </p>
                          </div>
                          <div className='flex justify-between'>
                            <p>
                              <strong>Class:</strong>
                            </p>
                            <p>
                              {item?.bookFreeDemo?.map((cls, id) => (
                                <span key={id}>
                                  {cls?.classId?.name ?? 'No Class'}
                                </span>
                              ))}
                            </p>
                          </div>
                          <div className='flex justify-between'>
                            <p>
                              <strong>Class Duration:</strong>
                            </p>
                            <p>{item?.classDuration ?? '-'} Minutes</p>
                          </div>
                          <div className='flex justify-between'>
                            <p>
                              <strong>Subject:</strong>
                            </p>
                            <p className='capitalize'>
                              {item?.bookFreeDemo?.map((itm, id) => (
                                <div key={id}>
                                  {itm?.subjectId
                                    ?.map(ele => ele?.name)
                                    .join(', ')}
                                </div>
                              ))}
                            </p>
                          </div>
                          <div className='flex justify-between'>
                            <p>
                              <strong>Board:</strong>
                            </p>
                            <p className='capitalize'>
                              {item?.bookFreeDemo?.map((itm, id) => (
                                <div key={id}>{itm?.board?.name ?? 'N/A'}</div>
                              ))}
                            </p>
                          </div>
                          <div className='flex justify-between'>
                            <p>
                              <strong>Assigned Tutor:</strong>
                            </p>
                            <p>{item?.tutorId?.name ?? 'N/A'}</p>
                          </div>
                          <div className='flex justify-between'>
                            <p>
                              <strong> Tutor Mob:</strong>
                            </p>
                            <p>{item?.tutorId?.mobNo ?? 'N/A'}</p>
                          </div>
                          <div className='flex justify-between'>
                            <p>
                              <strong>Tuition Fee:</strong>
                            </p>
                            <p>{item?.tuitionFee ?? '0'}</p>
                          </div>
                          <div className='flex justify-between'>
                            <p>
                              <strong>Tutor Fee:</strong>
                            </p>
                            <p>{item?.tutorFee ?? '0'}</p>
                          </div>
                          <div className='flex justify-between'>
                            <p>
                              <strong>Class Reference:</strong>
                            </p>
                            <p>{item.classReference}</p>
                          </div>
                          <div className='flex justify-between'>
                            <p>
                              <strong>Reference Fee:</strong>
                            </p>
                            <p>{item.referenceFee}</p>
                          </div>
                          <div className='flex justify-between'>
                            <p>
                              <strong>Company Share:</strong>
                            </p>
                            <p>{item.companyShare}</p>
                          </div>
                          <div className='flex justify-between'>
                            <p>
                              <strong>Tuition Start Date:</strong>
                            </p>
                            <p>{item.demoDate}</p>
                          </div>
                          <div className='flex justify-between'>
                            <p>
                              <strong>Fee Due Date:</strong>
                            </p>
                            <p>{item?.dueDate ?? 'N/A'}</p>
                          </div>

                          <div className='flex justify-between'>
                            <p>
                              <strong>Sessions Details:</strong>
                            </p>
                            <p>{item?.numberOfClasses}</p>
                          </div>
                          <div className='flex justify-between'>
                            <p>
                              <strong>Class Mode:</strong>
                            </p>
                            <p>
                              {item &&
                              item?.preferredTeachMode &&
                              item?.preferredTeachMode === 'HOME_TUITION'
                                ? 'Home Tuition'
                                : getDemoById &&
                                  getDemoById?.preferredTeachMode &&
                                  getDemoById?.preferredTeachMode ===
                                    'ONLINE_TUITION'
                                ? 'Online Tuition'
                                : 'Online Tuition'}
                            </p>
                          </div>
                          <div className='flex justify-between'>
                            <p>
                              <strong>Note:</strong>
                            </p>
                            <p
                              className={`cursor-pointer font-semibold ${
                                item.reason
                                  ? 'text-[#00AFE2]'
                                  : 'text-[#023565]'
                              }`}
                              onClick={() => viewNoteData(item)}
                            >
                              {item?.reason ? 'View' : 'Add'}
                            </p>
                          </div>
                        </div>
                        <div className='grid lg:grid-cols-7 text-sm md:grid-cols-3 grid-cols-1  gap-2 mt-4 font-semibold'>
                          <button
                            className='bg-[#023565] rounded-full text-white px-2 py-1 hover:bg-gray-600'
                            onClick={() => handleview(item)}
                          >
                            Update Info
                          </button>
                          <button
                            className='bg-[#023565] rounded-full text-white px-2 py-1 hover:bg-gray-600'
                            onClick={() => handleClassMode(item)}
                          >
                            Class Mode
                          </button>
                          <button
                            className='bg-[#023565] rounded-full text-white px-2 py-1 hover:bg-gray-600'
                            onClick={() => handleCloseClass(item)}
                          >
                            Class Close
                          </button>
                          <button
                            className='bg-[#023565] rounded-full text-white px-2 py-1 hover:bg-gray-600'
                            onClick={() => handlePutOnHold(item)}
                          >
                            Put On Hold
                          </button>
                          <button
                            className='bg-[#023565] rounded-full text-white px-2 py-1 hover:bg-gray-600'
                            onClick={() => assignTutor(item)}
                          >
                            Change Tutor
                          </button>
                          <button
                            className='bg-[#023565] rounded-full text-white px-2 py-1 hover:bg-gray-600'
                            onClick={() => handleViewHistory(item)}
                          >
                            View History
                          </button>

                          <button
                            className='bg-[#023565] rounded-full text-white px-2 py-1 hover:bg-gray-600 text-sm'
                            onClick={() => handleSendToDemoRequest(item)}
                          >
                            Send to Demo Request
                          </button>
                        </div>
                        {classmodeModal && (
                          <div>
                            <div className='fixed inset-0 flex justify-center items-center bg-gray-500 bg-opacity-50'>
                              <div>
                                <div className='flex justify-end'>
                                  <button
                                    className='z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center'
                                    onClick={() => setClassmodeModal(false)}
                                  >
                                    <span>
                                      <MdOutlineClose />
                                    </span>{' '}
                                    Close
                                  </button>
                                </div>
                                <div className='bg-white p-5 rounded-md shadow-lg'>
                                  <div className='text-center mb-4'>
                                    <h2 className='text-lg font-semibold'>
                                      Class mode
                                    </h2>
                                  </div>
                                  <div>
                                    <div className='mt-4 flex justify-between gap-2'>
                                      <button
                                        className='bg-[#023565] text-white px-4 py-2 rounded-full'
                                        onClick={() =>
                                          handleHomeClassMode({
                                            status: 'HOME_TUITION'
                                          })
                                        }
                                      >
                                        Home class
                                      </button>
                                      <button
                                        className='bg-[#9C9EAB] text-white px-4 py-2 rounded-full'
                                        onClick={() =>
                                          handleHomeClassMode({
                                            status: 'ONLINE_TUITION'
                                          })
                                        }
                                      >
                                        Online class
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    ))
                  ) : (
                    <div className='flex items-center justify-center border bg-gray-100'>
                      <div className='flex items-center justify-center w-64 h-32 bg-white border border-gray-300 rounded-lg shadow-lg'>
                        <span className='text-black'>Data Not Available</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {assignTutorModal && (
        <AssignTutorPopUp
          assignTutorModal={assignTutorModal}
          setAssignTutorModal={setAssignTutorModal}
          suggestTutorBtn={suggestTutorBtn}
          assignTutorTab={assignTutorTab}
          handleAssignTutor={handleAssignTutor}
          assignTutorList={assignTutorList}
          allTutorDataList={allTutorDataList}
          handleAssign={handleAssign}
          suggestedTutor={suggestedTutor}
        />
      )}

      <UpdateFeeModel
        updateFeeModal={updateFeeModal}
        setUpdateFeeModal={setUpdateFeeModal}
        handleFeeDataSubmit={handleFeeDataSubmit}
        updatedFeeData={updatedFeeData}
        handleupdateFeeData={handleupdateFeeData}
      />

      <UpdateNoteModel
        remarkModal={remarkModal}
        remarkClose={remarkClose}
        Heading={'Update Note'}
        remark={remark}
        setRemark={setRemark}
        handleRemarkSubmit={handleRemarkSubmit}
        btnName={`${remark ? 'Update' : 'Add'} Note`}
      />

      <StudentDetail
        setViewStudentModal={setViewStudentModal}
        viewStudentModal={viewStudentModal}
        setStudentListData={setStudentListData}
        studentListdata={studentListdata}
      />

      <ImagePreviewModal
        handleClose={handleCloseFunctionImageModel}
        showWarning={isModalVisible}
        image={image}
      />

      <ViewParent
        fullDetails={fullDetails}
        setFullDetails={setFullDetails}
        DetailsData={DetailsData}
        tuitionDetails={tuitionDetails}
      />

      {totalPages > 1 && (
        <div className='flex justify-end items-center py-2 px-4'>
          <button
            className={`px-4 py-2 border text-white bg-[#023565] rounded ${
              currentPage === 1 ? 'opacity-50 cursor-not-allowed' : ''
            }`}
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
          >
            <FaChevronLeft />
          </button>
          <span className='text-gray-700 mx-1'>
            <span className='border px-4 py-2 text-black  rounded'>
              {currentPage}
            </span>{' '}
            of{' '}
            <span className='border px-4 py-2 text-black  rounded'>
              {totalPages}
            </span>
          </span>
          <button
            className={`px-4 py-2 border text-white bg-[#023565] rounded ${
              currentPage === totalPages ? 'opacity-50 cursor-not-allowed' : ''
            }`}
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            <FaChevronRight />
          </button>
        </div>
      )}
      {isViewMoreModel && (
        <ViewMoreTuitionModel
          isViewMoreModel={isViewMoreModel}
          setIsViewMoreModel={setIsViewMoreModel}
          tuitionId={tuitionId}
        />
      )}
    </section>
  )
}

export default ChangeTutorRequest
