/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { tutortoliveAction } from "../../_actions";
import TutorToLiveHeader from "./TutorToLiveHeader";
import { useNavigate } from "react-router";
import ButtonComponent from "./ButtonComponent";
import TableFirst from "./TableFirst";
import TableSecond from "./TableSecond";
import TableThird from "./TableThird";
const TutorToLive = () => {
    const dispatch = useDispatch();
    const selector = useSelector((state) => state);
    const navigate = useNavigate()
    const buttonName = ['Newly Joined Tutors',
        'Rejected In Interview',
        'Skipped Tutors',]
    const [searchText, setSearchText] = useState("");

    const [tutorCount, setTutorCount] = useState({});
    const [activeTab, setActiveTab] = useState('Newly Joined Tutors');
    const [activeStatus, setActiveStatus] = useState('NEW_TUTOR');


    const handleSearch = (e) => {
        setSearchText(e.target.value);
    };

    const handleTabClick = (tabName) => {
        if (tabName == 'Newly Joined Tutors') setActiveStatus('NEW_TUTOR')
        if (tabName == 'Rejected In Interview') setActiveStatus('REJECT_INTERVIEW')
        if (tabName == 'Skipped Tutors') setActiveStatus('SKIP_TUTOR')
        setActiveTab(tabName);
        navigate(`?tab=${tabName}`);
    };

    function valueReturnData(dataState) {

        switch (dataState) {
            case "Newly Joined Tutors":
                return tutorCount?.newTutor;
            case 'Rejected In Interview':
                return tutorCount?.rejected;
            case 'Skipped Tutors':
                return tutorCount?.skip
            default:
                return null
        }

    }
    useEffect(() => {
        dispatch(tutortoliveAction.getNewlyJoinTutorCount({ isManagement: false }));
    }, []);

    useEffect(() => {
        setTutorCount(() => selector?.TutorToLive?.getNewJoinTutorListCount?.data);
    }, [selector]);

    const newSetState = (data) => {
        setActiveStatus(data)
      }

    return (
        <>
            <TutorToLiveHeader handleSearch={handleSearch} searchText={searchText} />
            <section className="overflow-hidden overflow-y-auto ">
                <div className="m-5">
                    <section className="w-full flex flex-wrap gap-6">
                        {buttonName.map((item, index) => {
                            return (
                                <div
                                    key={index}
                                    onClick={() => handleTabClick(item)}
                                    className={`cursor-pointer text-gray-100 px-6 py-2 text-center lg:text-sm text-[12px] rounded-full ${activeTab === item ? 'bg-[#023565]' : 'bg-[#9C9EAB]'
                                        }`}
                                >
                                    <ButtonComponent
                                        onClick={() => handleTabClick(item)}

                                        name={item}
                                        value={valueReturnData(item)}
                                    />
                                </div>
                            )
                        })}
                    </section>

                    <section className="overflow-auto">
                        {activeTab === 'Newly Joined Tutors' && <TableFirst searchText={searchText} newSetState={newSetState} />}
                        {activeTab === 'Rejected In Interview' && <TableSecond searchText={searchText} newSetState={newSetState} />}
                        {activeTab === 'Skipped Tutors' && <TableThird searchText={searchText} newSetState={newSetState} />}
                    </section>
                </div>
            </section>
        </>
    )
}

export default TutorToLive