/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { feeAction } from "../../../_actions/fee.action";
import { useLocation } from "react-router-dom";
import { downloadCSV } from "../../../_helpers/auth-header"
import { IoArrowBack } from "react-icons/io5";
import { FaChevronLeft, FaEye } from "react-icons/fa";
import FeereceviedHeader from "./EarningHeader/FeereceviedHeader";
import { FaChevronRight } from "react-icons/fa";
import { MdOutlineClose } from "react-icons/md";
import Loader from "../../../components/Loader";
import moment from "moment"
import { alertActions } from "../../../_actions";
import { PiExport } from "react-icons/pi";


const FeeReciedList = () => {
  const dispatch = useDispatch();
  const selector = useSelector((state) => state);
  const location = useLocation();
  const feeData = location.state?.feeData;
  let monthId = feeData?.month
  const [transactionModal, setTransactionModa] = useState(false)
  const [transData, setTransData] = useState({})

  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const pageSize = 10;
  let FeeData = selector?.Fee?.feeRecevied?.data?.list;
  let allFeeData = selector?.Fee?.allFeeReceived?.data;
  const menuRef = useRef(null);

  const [openDetailsId, setOpenDetailsId] = useState(null);
  const OpenDetails = (resDataId) => {
    setOpenDetailsId(openDetailsId !== resDataId ? resDataId : null)
  };


  const fields = ['Parent Name', 'Parent Mobile Number', 'Tutor Name', 'Tutor Mobile Number', 'Student / Classes', 'Tuition Fee Due Date', 'Tutor Fee Due Date', 'Tuition Fee', 'Tutor Fee', 'Reference Fee', 'Created At', 'Time'];

  const convertToCSV = (data) => {

    const array = typeof data !== 'object' ? JSON.parse(data) : data;
    let csv = fields.join(',') + '\r\n';

    array?.forEach(item => {
      const parentName = item.parentId ? item.parentId.name : '-';
      const parentMobNo = item.parentId ? item.parentId.mobNo : '-';
      const tutorName = item.tutorId ? item.tutorId.name : '-';
      const tutorMobNo = item.tutorId ? item.tutorId.mobNo : '-';
      const studentsClasses = item.demoClassId && item.demoClassId.bookFreeDemo ? item.demoClassId.bookFreeDemo.map(demo => {
        return `${demo.studentId.studentFullName} / ${demo.classId.name}`;
      }).join(' | ') : 'N/A';

      const tuitionFeeDueDate = item.demoClassId?.dueDate ?? '';
      const tutorFeeDueDate = item.demoClassId?.tutorDueDate ?? '';
      const tuitionFee = item?.tuitionFee ?? '';
      const tutorFee = item?.tutorFee ?? '';
      const referenceFee = item?.referenceFee ?? '';
      const createdAt = item.createdAt ? new Date(item.createdAt).toLocaleString() : '';
      const row = [parentName, parentMobNo, tutorName, tutorMobNo, studentsClasses, tuitionFeeDueDate, tutorFeeDueDate, tuitionFee, tutorFee, referenceFee, createdAt].join(',');
      csv += row + '\r\n';
    });

    return csv;
  };





  useEffect(() => {
    let feeData = {
      cityId: "",
      month: monthId,
      keyWord: "",
      pageNo: currentPage,
      size: pageSize,
    };
    let feeData1 = {
      cityId: "",
      month: monthId
    };
    dispatch(feeAction.getFeeReceivedList(feeData));
    dispatch(feeAction.getAllFeeReceived(feeData1));
  }, [currentPage]);
  useEffect(() => {
    if (selector?.Fee?.feeRecevied?.data?.total) {
      setTotalPages(Math.ceil(selector?.Fee?.feeRecevied?.data?.total / pageSize));
    }
  }, [selector]);

  const handleTraView = (data) => {
    setTransactionModa(true)
    let transId = {
      id: data?._id
    }
    dispatch(feeAction.gettransaction(transId))
  }

  const handleBack = () => {
    window.history.back();
  };

  const [fullDetailsModal, setfullDetailsModal] = useState(false);
  const [fullDetailsModalData, setfullDetailsModalData] = useState("");
  const handleFullDetails = (data) => {
    setfullDetailsModal(true);
    setfullDetailsModalData(data);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setOpenDetailsId(null);
    }

  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <FeereceviedHeader />
      <section className="overflow-hidden overflow-y-auto" >
        <div className="overflow-hidden overflow-y-auto"  >
          <div className="lg:m-5 m-2">
            <Loader loading={selector?.Fee?.loading} />

            <div className="flex justify-between">
              <div className="flex gap-2">
                <IoArrowBack
                  className="text-[#023565] text-3xl border rounded-full border-black my-2 mx-3 cursor-pointer bg-white"
                  onClick={handleBack}
                />
              </div>
              <button className="bg-white px-3 py-2 gap-2 border flex items-center rounded-lg" onClick={() => { !allFeeData.length ? dispatch(alertActions.error("No data to export.")) : downloadCSV(convertToCSV(allFeeData)) }}>
                <span>Export</span>  <span><PiExport size={18} /></span>
              </button>
            </div>


            <div className="relative overflow-x-auto bg-white rounded-lg border border-[#D3D3D3] mt-2 lg:block hidden">
              <table className="w-full  text-left rtl:text-right bg-white text-[#313131]">
                <thead className=" ">
                  <tr className="capitalize border-b">
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      Parent name
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      Student
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      Assigned Tutor
                    </th>
                    <th scope="col" className="px-6 py-4 whitespace-nowrap">
                      City
                    </th>
                    <th scope="col" className="px-6 py-4  whitespace-nowrap">
                      <tr>Tuition Fee</tr>
                      <tr>Student Fee Refund</tr>
                    </th>
                    <th scope="col" className="px-6 py-4 whitespace-nowrap">
                      Payment Type
                    </th>
                    <th scope="col" className="px-6 py-4 whitespace-nowrap">
                      Transaction
                    </th>
                    <th scope="col" className="px-6 py-4 whitespace-nowrap">
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {FeeData && FeeData.length > 0 ?
                    FeeData?.map((item, id) => (
                      <tr className=" capitalize  border-b dark:border-gray-700 forfontsmall" key={id}>
                        <th
                          scope="row"
                          className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                        >
                          <div className="flex items-center gap-2">
                            <span>
                              {item && item.parentId?.image ?
                                <img
                                  alt=""
                                  src={
                                    item && item.parentId?.image
                                      ? item.parentId?.image
                                      : ""
                                  }
                                  className="rounded-full max-w-[30px] max-h-[30px] min-w-[30px] min-h-[30px]"
                                />
                                : <div className="bg-gray-300 rounded-full h-14 w-14 flex justify-center items-center">
                                  <span className="text-base font-semibold text-[#033565]"> {item?.parentId?.name ? (item.parentId.name.split(' ').map(name => name.charAt(0)).join('')) : ('')}</span>
                                </div>}
                            </span>
                            <div className="w-24">
                              <p className="font-semibold whitespace-nowrap text-[#023565]">
                                {item?.parentId?.name}
                              </p>
                              <p className="text-gray-500 whitespace-nowrap">
                                {item?.parentId?.mobNo}
                              </p>
                            </div>
                          </div>
                        </th>


                        <td className="px-6 py-3">
                          <FaEye
                            className="text-[#023565] text-[25px] hover:text-[#C80C65] cursor-pointer"
                            onClick={() => handleFullDetails(item)}
                          />
                        </td>



                        <td className="px-6 py-3">
                          <tr className="font-bold">
                            {item?.demoClassId?.tutorId?.name}
                          </tr>
                          <tr>
                            {item?.demoClassId?.tutorId?.mobNo}
                          </tr>

                        </td>
                        <td className="px-6 py-3">{item?.cityId?.name ?? "-"}</td>
                        <td className="px-6 py-3">
                          <tr>{item?.tuitionFee ?? "0"}</tr>
                          {item?.status === 3 && <tr>Total: {item?.tuitionFee - item?.tuitionFeeRefundedPaid}</tr>}
                        </td>
                        <td className="px-6 py-3">{item?.demoClassId?.paymentMode ?? "-"}</td>
                        <td className="px-6 py-3 cursor-pointer" >
                          <span onClick={() => handleTraView(item)} className="underline font-bold" >
                            View
                          </span>

                        </td>



                        <td className={`px-6 py-3 ${item?.status === 0
                          ? "text-red-500"
                          : item?.status === 1
                            ? "text-green-500"
                            : item?.status === 2
                              ? "text-sky-500"
                              : item?.status === 3
                                ? "text-yellow-500"
                                : ""
                          }`} onClick={() => OpenDetails(id)}>
                          {item?.status === 0
                            ? " pending"
                            : item?.status === 1
                              ? " Received"
                              : item?.status === 2
                                ? " advance paid"
                                : item?.status === 3 ? 'Refund' : ''}
                          {openDetailsId === id && (item?.userId || item?.payDate || item?.adminId?.roleId || item?.userId?.name || item?.adminId?.userName) && (
                            <div className="absolute right-0 w-52 p-2  bg-white border border-gray-300 rounded-lg shadow-lg z-10"
                              ref={menuRef}>
                              {item?.payDate &&
                                <p>
                                  {`Payment date: ${moment(item?.payDate).format('YYYY-MM-DD')}`}
                                </p>
                              }
                              {item?.status === 3 ? <span>
                                <p>Refunded date: {moment(item?.tuitionFeeRefundDate).format('YYYY-MM-DD')}</p>
                                <p>Refunded amount: {item.tuitionFeeRefundedPaid}</p>
                              </span>
                                : ""}

                              <p>
                                {item && item.adminId && item.adminId.roleId && item.adminId.roleId === 1 ? `Done by: Super Admin` : item && item.adminId && item.adminId.roleId && item.adminId.roleId !== 1 ? `Done by: Sub Admin` : item && item.userId && item.userId.name ? `Done by: Parent` : null}
                              </p>
                              <p>
                                {item?.adminId?.userName ? `Login Id: ${item?.adminId?.userName}` : item?.userId?.name ? `Login Id: ${item?.userId?.name}` : null}
                              </p>
                            </div>
                          )}
                        </td>
                      </tr>
                    ))
                    : <tr>
                      <td colSpan={7} className="px-6 py-4 font-[400] text-base text-[#313131] whitespace-nowrap text-center">Data Not Available</td>
                    </tr>}
                </tbody>
              </table>
            </div>


            <div className="lg:hidden block  space-y-5 my-4">
              {FeeData && FeeData.length > 0 ?
                FeeData?.map((item, id) => (
                  <div className="border border-[#023565] rounded-[10px]">
                    <table key={id} className="w-full  bg-white  rounded-[10px]">
                      <tr className="border-b border-gray-300">
                        <th className="text-left py-4 px-4">
                          <span className="font-[500]">Parent name</span>
                        </th>
                        <td
                          class="px-4 py-4 font-medium text-gray-900"
                        >
                          <div className="flex items-center gap-3">
                            <div>
                              {item && item.parentId?.image ?
                                <img
                                  alt=""
                                  src={
                                    item && item.parentId?.image
                                      ? item.parentId?.image
                                      : ""
                                  }
                                  className="rounded max-w-[30px] max-h-[30px] min-w-[30px] min-h-[30px]"
                                />
                                : <div className="bg-gray-300 rounded h-10 w-10 flex justify-center items-center">
                                  <span className="text-base font-semibold text-[#033565]"> {item?.parentId?.name ? (item.parentId.name.split(' ').map(name => name.charAt(0)).join('')) : ('')}</span>
                                </div>}
                            </div>
                            <div>
                              <p className="text-[12px] text-left capitalize">{item?.parentId?.name} </p>
                              <p className="text-[12px] text-left capitalize">{item?.parentId?.mobNo}</p>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr className="border-b border-gray-300">
                        <th className="text-left py-4 px-4">
                          <span className="font-[500]">Student</span>
                        </th>
                        <td className="text-auto py-4  px-4 gap-2">
                          <p className="font-[400] text-[14px] capitalize">
                            <FaEye
                              className="text-[#023565] text-[25px] hover:text-[#C80C65] cursor-pointer"
                              onClick={() => handleFullDetails(item)}
                            />
                          </p>
                        </td>
                      </tr>
                      <tr className="border-b border-gray-300">
                        <th className="text-left py-4 px-4">
                          <span className="font-[500]">Assigned Tutor</span>
                        </th>
                        <td className="text-auto py-4  px-4 gap-2">
                          <p className="font-[400] text-[14px] capitalize">
                            {item?.tutorId?.name}
                          </p>
                          <p className="font-[400] text-[14px] capitalize">
                            {item?.tutorId?.mobNo}
                          </p>
                        </td>
                      </tr>
                      <tr className="border-b border-gray-300">
                        <th className="text-left py-4 px-4">
                          <span className="font-[500]">Tuition Fee</span>
                        </th>
                        <td className="text-auto py-4  px-4 gap-2">
                          <p className="font-[400] text-[14px] capitalize">
                            {item?.tuitionFee ?? "0"}
                          </p>
                        </td>
                      </tr>
                      <tr className="border-b border-gray-300">
                        <th className="text-left py-4 px-4">
                          <span className="font-[500]">Payment Type</span>
                        </th>
                        <td className="text-auto py-4  px-4 gap-2">
                          <p className="font-[400] text-[14px] capitalize">
                            {item?.demoClassId?.paymentMode ?? "-"}
                          </p>
                        </td>
                      </tr>
                      <tr className="border-b border-gray-300">
                        <th className="text-left py-4 px-4">
                          <span className="font-[500]">Transaction</span>
                        </th>
                        <td className="text-auto py-4  px-4 gap-2">
                          <p className="font-[400] text-[14px] capitalize" onClick={() => handleTraView(item)}>
                            view
                          </p>
                        </td>
                      </tr>
                      <tr className="border-b border-gray-300">
                        <th className="text-left py-4 px-4">
                          <span className="font-[500]">Status</span>
                        </th>
                        <td className={`px-6 py-3 ${item?.status === 0
                          ? "text-red-500"
                          : item?.status === 1
                            ? "text-green-500"
                            : item?.status === 2
                              ? "text-sky-500"
                              : item?.status === 3
                                ? "text-yellow-500"
                                : ""
                          }`} onClick={() => OpenDetails(id)}>
                          {item?.status === 0
                            ? " pending"
                            : item?.status === 1
                              ? " Received"
                              : item?.status === 2
                                ? " advance paid"
                                : item?.status === 3 ? 'Refund' : ''}
                          {openDetailsId === id && (item?.userId || item?.payDate || item?.adminId?.roleId || item?.userId?.name || item?.adminId?.userName) && (
                            <div className="absolute right-0 w-52 p-2  bg-white border border-gray-300 rounded-lg shadow-lg z-10"
                              ref={menuRef}>
                              {item?.payDate &&
                                <p>
                                  {`Payment date: ${moment(item?.payDate).format('YYYY-MM-DD')}`}
                                </p>
                              }
                              {item?.status === 3 ? <span>
                                <p>Refunded date: {moment(item?.tuitionFeeRefundDate).format('YYYY-MM-DD')}</p>
                                <p>Refunded amount: {item.tuitionFeeRefundedPaid}</p>
                              </span>
                                : ""}

                              <p>
                                {item && item.adminId && item.adminId.roleId && item.adminId.roleId === 1 ? `Done by: Super Admin` : item && item.adminId && item.adminId.roleId && item.adminId.roleId !== 1 ? `Done by: Sub Admin` : item && item.userId && item.userId.name ? `Done by: Parent` : null}
                              </p>
                              <p>
                                {item?.adminId?.userName ? `Login Id: ${item?.adminId?.userName}` : item?.userId?.name ? `Login Id: ${item?.userId?.name}` : null}
                              </p>
                            </div>
                          )}
                        </td>
                      </tr>
                    </table>
                  </div>
                ))
                : <tr>
                  <td colSpan={7} className="px-6 py-4 font-[400] text-base text-[#313131] whitespace-nowrap text-center">Data Not Available</td>
                </tr>
              }
            </div>


            <div>
              {totalPages > 1 && (
                <div className="flex justify-end items-center py-2 px-4">
                  <button
                    className={`px-4 py-2 border text-white bg-[#023565] rounded ${currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""}`}
                    onClick={handlePreviousPage}
                    disabled={currentPage === 1}
                  >
                    <FaChevronLeft />
                  </button>
                  <span className="text-gray-700 mx-1">
                    <span className="border px-4 py-2 text-black  rounded">{currentPage}</span> of <span className="border px-4 py-2 text-black  rounded">{totalPages}</span>
                  </span>
                  <button
                    className={`px-4 py-2 border text-white bg-[#023565] rounded ${currentPage === totalPages ? "opacity-50 cursor-not-allowed" : ""}`}
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                  >
                    <FaChevronRight />
                  </button>
                </div>
              )}
            </div>
          </div>
          {fullDetailsModal && (
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40">
              <div className="relative w-11/12 mx-auto lg:w-[800px]">
                <div className="flex justify-end">
                  <button
                    onClick={() => { setfullDetailsModal(false); setTransData({}); }}
                    className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center"
                  >
                    <MdOutlineClose /> Close
                  </button>
                </div>
                <div className="form_class border-0 overflow-hidden overflow-x-auto rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  <div className="flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                    <h2 className="text-lg font-semibold">Student Details </h2>
                  </div>
                  <div className="overflow-x-auto">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">
                            Student Name
                          </th>
                          <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">
                            Class
                          </th>
                          <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">
                            Subject
                          </th>
                          <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">
                            Board
                          </th>

                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                        {fullDetailsModalData?.demoClassId?.bookFreeDemo?.map((ele, index) => (
                          <tr className="capitalize border-b" key={index}>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 flex gap-2 justify-start place-items-center">
                              <img
                                alt=""
                                src={
                                  ele?.studentId?.image
                                    ? ele?.studentId?.image
                                    : "https://thumbs.dreamstime.com/z/vector-illustration-avatar-dummy-logo-collection-image-icon-stock-isolated-object-set-symbol-web-137160339.jpg?w=768"
                                }
                                className="w-8 h-8 rounded-full"
                              />
                              {ele?.studentId?.studentFullName ?? "N/A"}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                              {ele?.classId?.name.toUpperCase() ?? "N/A"}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                              {ele?.subjectId?.map((subject, subIndex) => (
                                <div key={subIndex}>{subject?.name}</div>
                              ))}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                              {ele?.board?.name ?? "N/A"}
                            </td>

                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}

          {
            transactionModal && (
              <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40">
                <Loader loading={selector?.Fee?.loading} />
                <div className="relative w-11/12 mx-auto lg:w-[800px]">
                  <div className="flex justify-end">
                    <button
                      onClick={() => setTransactionModa(false)}
                      className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center"
                    >
                      <MdOutlineClose /> Close
                    </button>
                  </div>
                  <div className="form_class border-0 overflow-hidden overflow-x-auto rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                    <div className="flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                      <h2 className="text-lg font-semibold">Transaction Details </h2>
                    </div>
                    <div className="overflow-x-auto">
                      <table className="min-w-full divide-y divide-gray-200">
                        <thead>
                          <tr>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Order ID</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Receipt ID</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Amount</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                          </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                          {selector?.Fee?.getTransactionList?.data?.map((ele, index) => (
                            <tr key={index}>
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                {ele.orderId ?? ''}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                {ele.receiptId ?? ''}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                {ele.amount ?? ''}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                {ele.status === 0 ? 'Pending' :
                                  ele.status === 1 ? 'Approved' :
                                    ele.status === 2 ? 'Rejected' : ''}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            )
          }

        </div>
      </section>
    </>
  );
};

export default FeeReciedList;
