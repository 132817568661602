import React, { Fragment, useState, useEffect } from "react";
import { FaAngleDown } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { cityAction } from "../../_actions/city.action";
import HeaderProfileComp from "../../components/Header/HeaderProfileComp";
import SearchIconComponent from "../../components/Header/SearchIconComponent";
import HeaderHeading from "../../components/Header/HeaderHeading";
import MobileSearchHeader from "../../components/Header/MobileSearchHeader";

const MyProfileHeader = () => {
  const dispatch = useDispatch();
  const selector = useSelector((state) => state);
  const [searchText, setSearchText] = useState("");
  useEffect(() => {
    let cityDetails = {
      keyWord: "",
      fromDate: "",
      toDate: "",
      sortOrder: "asc",
      sortBy: "createdAt",
      pageNo: 1,
      size: 10,
    };

    dispatch(cityAction.cityGet(cityDetails));
  }, [dispatch]);

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };



  return (
    <Fragment>
      <section className=" bg-[#ffffff] lg:block hidden border-r border pl-4">


        <div className="w-full py-3 px-10 flex justify-between items-center">
          <HeaderHeading label={"MY PROFILE"} />
          <div className="flex items-center gap-6">
            {/* <SearchIconComponent handleSearch={handleSearch} searchText={searchText} /> */}
            {/* <div className="flex relative  city_select cursor-pointer items-center">
              <select className="flex items-center justify-center capitalize bg-white border-2 text-[#000] rounded-lg w-full lg:w-36 px-4 h-9">
                <option className="text-black align-middle" value={""}>
                  {" "}
                  All Cities <FaAngleDown className="ml-3" color="#D9D9D9" />
                </option>
                {selector?.Citys?.cityGetList?.map((city, id) => (
                  <option className="text-black capitalize" value="" key={id}>
                    {" "}
                    {city.name}
                  </option>
                ))}
              </select>

            </div> */}
          </div>
          <HeaderProfileComp />
        </div>
      </section>

      <section className="bg-white lg:hidden block">
        <MobileSearchHeader handleSearch={handleSearch} searchText={searchText} />
      </section>
    </Fragment>
  );
};

export default MyProfileHeader;
