/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import Sidebar from '../Sidebar/Sidebar'
import Dashboard from '../../pages/dashboard/Dashboard'
import DashboardCB from '../../pagesCB/dashboard/Dashboard'
import EarningCB from '../../pagesCB/Manage/Earning/Earning'
import TuitionCB from '../../pagesCB/Tuition/Tuition'
import WalletCb from '../../pagesCB/Wallet/Wallet'
import TutorCb from '../../pagesCB/Tutor-Profile/TutorProfile'
import ParentCb from '../../pagesCB/Parent-Student-Profile/ParentStudentsProfile'
import TermsConditionsCB from '../../pagesCB/Terms&conditions/Terms&conditions'
import HelpAndSupportCB from '../../pagesCB/Help-And-Support/HelpAndSupport'
import FeeReciedListCB from '../../pagesCB/Manage/Earning/FeeReciedList'
import ReferencePaidCB from '../../pagesCB/Manage/Earning/ReferencePaid'
import FeeRefundCB from '../../pagesCB/Manage/Earning/FeeRefund'
import Tutor from '../../pages/Manage/Tutor'
import Earning from '../../pages/Manage/Earning/Earning'
import Tution from '../../pages/Manage/Tution/Tution'
import Fee from '../../pages/Manage/Fee/Fee'
import Attendence from '../../pages/Manage/Attendence/Attendence'
import Wallet from '../../pages/Wallet/Wallet'
import TutorProfile from '../../pages/Tutor-Profile/TutorProfile'
import ParentStudentsProfile from '../../pages/Parent-Student-Profile/ParentStudentsProfile'
import EmploysProfile from '../../pages/Employee-Profile/EmploysProfile'
import CitysProfile from '../../pages/City-Profile/CitysProfile'
import ClassSubjectBoard from '../../pages/Class-Subject-Board/ClassSubjectBoard'
import layoutJSON from './layout.json'
import Banner from '../../pages/Banner/Banner'
import NotificationList from '../../pages/NotificationList/NotificationList'
import HolidayCalender from '../../pages/HolidayCalander/HolidayCalender'
import Notification from '../../pages/Notification/Notification'
import Feedback from '../../pages/Feedback/Feedback'
import Termsconditions from '../../pages/Terms&conditions/Terms&conditions'
import HelpAndSupport from '../../pages/Help-And-Support/HelpAndSupport'
import FeepaymentHistory from '../../pages/Tutor-Profile/FeepaymentHistory'
import FeeReciedList from '../../pages/Manage/Earning/FeeReciedList'
import NoFee from '../../pages/Manage/Earning/NoFee'
import FeePaid from '../../pages/Manage/Earning/FeePaid'
import FeePending from '../../pages/Manage/Earning/FeePending'
import ReferencePaid from '../../pages/Manage/Earning/ReferencePaid'
import ReferralPaid from '../../pages/Manage/Earning/ReferralPaid'
import FeeRefund from '../../pages/Manage/Earning/FeeRefund'
import TutorFeePaid from '../../pages/Manage/Earning/TutorFeePaid'
import TutorFeePending from '../../pages/Manage/Earning/TutorFeePending'
import NewJoinTutorList from '../../pages/dashboard/NewJoinTutorList'
import TableFirst from '../../pages/Manage/TableFirst'
import MyProfile from '../../pages/My Profile/MyProfile'
import Assignment from '../../pages/Assignment/Assignment'
import ViewAndUpdate from '../../pages/Manage/Tution/ViewAndUpdate'
import ViewHistoy from '../../pages/Manage/Tution/ViewHistoy'
import ParentViewHistory from '../../pages/Parent-Student-Profile/ParentViewHistory'
import TutorViewHistory from '../../pages/Tutor-Profile/TutorViewHistory'
import UserPackage from '../../pages/UserPackage/UserPackage'
import ReferralAppSetting from '../../pages/referral-appSetting/ReferralAppSetting'
import TutorProfileUpdate from '../../pages/Tutor-Profile/TutorProfileUpdate'
import { myprofileAction } from '../../_actions'
import { useDispatch } from 'react-redux'
import TutorToLiVECB from '../../pagesCB/TutorToLive/TutorToLive'

function Layout () {
  let [navName, setNavName] = useState('Dashboard')

  const handelOnClickTab = name => {
    setNavName(name)
  }
  const [isOpen, setIsOpen] = useState(false)
  const menuRef = useRef(null)

  useEffect(() => {
    const path = window.location.pathname
    const labelName =
      layoutJSON?.sidebarJSON?.WebView.find(ele => {
        if (ele.location.toLowerCase() === path.toLowerCase()) {
          return ele.label
        } else {
          return (
            ele.subItems &&
            ele.subItems.find(
              item => item.location.toLowerCase() === path.toLowerCase()
            )
          )
        }
      })?.label || 'Dashboard'
    setNavName(labelName)
  }, [])

  const handleClickOutside = event => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsOpen(false)
    }
  }
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(myprofileAction.getAdminProfile())
  }, [])

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <div className=''>
      <div className='overflow-hidden bg-[#F0F1F7]'>
        <div className='min-h-screen  flex flex-col justify-center'>
          <div className='h-screen flex overflow-hidden'>
            <Sidebar
              SidebarJSON={layoutJSON.sidebarJSON}
              handelOnClickTab={handelOnClickTab}
            />
            <div className='flex flex-col w-0 flex-1 overflow-hidden'>
              <Routes>
                <Route path='/dashboard' element={<Dashboard />} />
                <Route path='/tutor' element={<Tutor />} />
                <Route path='/earning' element={<Earning />} />
                <Route path='/tution' element={<Tution />} />
                <Route path='/fees' element={<Fee />} />
                <Route path='/attendence' element={<Attendence />} />
                <Route path='/wallet' element={<Wallet />} />

                <Route path='/tutor-profile' element={<TutorProfile />} />
                <Route
                  path='/parent-student-profile'
                  element={<ParentStudentsProfile />}
                />
                <Route path='/employ-profile' element={<EmploysProfile />} />
                <Route path='/city-profile' element={<CitysProfile />} />

                <Route
                  path='/class-subject-board'
                  element={<ClassSubjectBoard />}
                />
                <Route
                  path='/referral-appSetting'
                  element={<ReferralAppSetting />}
                />
                <Route
                  path='/holiday-calaendar'
                  element={<HolidayCalender />}
                />
                <Route
                  path='/terms-and-condition'
                  element={<Termsconditions />}
                />
                <Route path='/assignment' element={<Assignment />} />
                <Route path='/banners' element={<Banner />} />
                <Route path='/feedback' element={<Feedback />} />
                <Route path='/notification' element={<Notification />} />
                <Route
                  path='/NotificationList'
                  element={<NotificationList />}
                />
                <Route path='/myprofile' element={<MyProfile />} />
                <Route path='/userpackage' element={<UserPackage />} />
                <Route path='/help&support' element={<HelpAndSupport />} />

                <Route path='/feepayment' element={<FeepaymentHistory />} />
                <Route path='/feerecevied' element={<FeeReciedList />} />
                <Route path='/feepaid' element={<FeePaid />} />
                <Route path='/NoFee' element={<NoFee />} />
                <Route path='/feepending' element={<FeePending />} />
                <Route path='/referencepaid' element={<ReferencePaid />} />
                <Route path='/referralpaid' element={<ReferralPaid />} />
                <Route path='/feerefund' element={<FeeRefund />} />
                <Route path='/tutorfeepaid' element={<TutorFeePaid />} />
                <Route path='/fee-pending' element={<TutorFeePending />} />
                <Route
                  path='/newjointutorlist'
                  element={<NewJoinTutorList />}
                />
                <Route path='/newJoinedtutor' element={<TableFirst />} />
                <Route
                  path='/viewandupdate/:id?/:parentIds'
                  element={<ViewAndUpdate />}
                />
                <Route path='/viewhistory/:id?' element={<ViewHistoy />} />
                <Route
                  path='/parentviewhistory/:id?'
                  element={<ParentViewHistory />}
                />
                <Route
                  path='/tutorviewhistory/:id?'
                  element={<TutorViewHistory />}
                />
                <Route
                  path='/updatetutorbyid/:id?'
                  element={<TutorProfileUpdate />}
                />

                {/* Credit Based Routes */}
                <Route path='/dashboard-cb' element={<DashboardCB />} />
                <Route path='/earning-cb' element={<EarningCB />} />
                <Route path='/tuition-cb' element={<TuitionCB />} />
                <Route path='/wallet-cb' element={<WalletCb />} />
                <Route path='/tutor-cb' element={<TutorCb />} />
                <Route path='/parent-cb' element={<ParentCb />} />
                <Route
                  path='/terms-conditions-cb'
                  element={<TermsConditionsCB />}
                />
                <Route
                  path='/help-&-support-cb'
                  element={<HelpAndSupportCB />}
                />
                <Route path='/feerecevied-cb' element={<FeeReciedListCB />} />
                <Route path='/referencepaid-cb' element={<ReferencePaidCB />} />
                <Route path='/feerefund-cb' element={<FeeRefundCB />} />
                <Route path='/tutor-to-live-cb' element={<TutorToLiVECB />} />

                {/* getCreditBaseTuitionEarning */}
              </Routes>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Layout
