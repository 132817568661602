export const myprofileConstant={
    GET_ADMIN_PROFILE_REQUEST:"GET_ADMIN_PROFILE_REQUEST",
    GET_ADMIN_PROFILE_SUCCESS:"GET_ADMIN_PROFILE_SUCCESS",
    GET_ADMIN_PROFILE_FAILURE:"GET_ADMIN_PROFILE_FAILURE",

    UPDATE_ADMIN_PROFILE_REQUEST:"UPDATE_ADMIN_PROFILE_REQUEST",
    UPDATE_ADMIN_PROFILE_SUCCESS:"UPDATE_ADMIN_PROFILE_SUCCESS",
    UPDATE_ADMIN_PROFILE_FAILURE:"UPDATE_ADMIN_PROFILE_FAILURE",

    UPDATE_PASSWORD_REQUEST:"UPDATE_PASSWORD_REQUEST",
    UPDATE_PASSWORD_SUCCESS:"UPDATE_PASSWORD_SUCCESS",
    UPDATE_PASSWORD_FAILURE:"UPDATE_PASSWORD_FAILURE",
}