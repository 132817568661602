import { dashboardConstant } from "../_constants";
import { APIcallFunction, headerForPrivateAPI } from "../_helpers";

export const dashboardAction = {
  getDashboardCountCreditBase,
  getDashboardCount,
  // getNewTicketList,
  getTicketList,
  getLatestDemoDoneList,
  getLatestDemoRequestList,
  getContactSeenList,
  getNewReferralsList,
  getLatestChangeTutorRequest
};

function getDashboardCount(data) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "dashboard/getDashboardCount ",
  };
  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (users) => {
        dispatch(success(users));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: dashboardConstant.GET_DASHBOARD_COUNT_LIST_REQUEST };
  }
  function success(users) {
    return { type: dashboardConstant.GET_DASHBOARD_COUNT_LIST_SUCCESS, users };
  }
  function failure(error) {
    return { type: dashboardConstant.GET_DASHBOARD_COUNT_LIST_FAILURE, error };
  }
}

function getDashboardCountCreditBase(data) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data,
    endPoint: "dashboard/getDashboardCountCreditBase",
  };
  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (users) => {
        dispatch(success(users));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: dashboardConstant.GET_DASHBOARD_COUNT_CREDIT_BASE_REQUEST };
  }
  function success(users) {
    return { type: dashboardConstant.GET_DASHBOARD_COUNT_CREDIT_BASE_SUCCESS, users };
  }
  function failure(error) {
    return { type: dashboardConstant.GET_DASHBOARD_COUNT_CREDIT_BASE_FAILURE, error };
  }
}

function getTicketList(data) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "ticket/getTicketList ",
  };
  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (users) => {
        dispatch(success(users));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: dashboardConstant.GET_NEW_TICKET_LIST_REQUEST };
  }
  function success(users) {
    return { type: dashboardConstant.GET_NEW_TICKET_LIST_SUCCESS, users };
  }
  function failure(error) {
    return { type: dashboardConstant.GET_NEW_TICKET_LIST_FAILURE, error };
  }
}

// function getNewTicketList(data) {
//   const credentials = {
//     header: headerForPrivateAPI(),
//     method: "POST",
//     body: data ?? {},
//     endPoint: "dashboard/getNewTicketList ",
//   };
//   return (dispatch) => {
//     dispatch(request(data));
//     APIcallFunction(credentials).then(
//       (users) => {
//         dispatch(success(users));
//       },
//       (error) => {
//         dispatch(failure(error));
//       }
//     );
//   };

//   function request() {
//     return { type: dashboardConstant.GET_NEW_TICKET_LIST_REQUEST };
//   }
//   function success(users) {
//     return { type: dashboardConstant.GET_NEW_TICKET_LIST_SUCCESS, users };
//   }
//   function failure(error) {
//     return { type: dashboardConstant.GET_NEW_TICKET_LIST_FAILURE, error };
//   }
// }

function getLatestDemoDoneList(data) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "dashboard/getLatestDemoDoneList ",
  };
  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (users) => {
        dispatch(success(users));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: dashboardConstant.GET_LATEST_DEMO_DONE_LIST_REQUEST };
  }
  function success(users) {
    return { type: dashboardConstant.GET_LATEST_DEMO_DONE_LIST_SUCCESS, users };
  }
  function failure(error) {
    return { type: dashboardConstant.GET_LATEST_DEMO_DONE_LIST_FAILURE, error };
  }
}

function getContactSeenList(data) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "dashboard/getContactSeenList ",
  };
  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (users) => {
        dispatch(success(users));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: dashboardConstant.GET_CONTACT_SEEN_LIST_REQUEST };
  }
  function success(users) {
    return {
      type: dashboardConstant.GET_CONTACT_SEEN_LIST_SUCCESS,
      users,
    };
  }
  function failure(error) {
    return {
      type: dashboardConstant.GET_CONTACT_SEEN_LIST_FAILURE,
      error,
    };
  }
}

function getLatestDemoRequestList(data) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "dashboard/getLatestDemoRequestList ",
  };
  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (users) => {
        dispatch(success(users));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: dashboardConstant.GET_LATEST_DEMO_REQUEST_LIST_REQUEST };
  }
  function success(users) {
    return {
      type: dashboardConstant.GET_LATEST_DEMO_REQUEST_LIST_SUCCESS,
      users,
    };
  }
  function failure(error) {
    return {
      type: dashboardConstant.GET_LATEST_DEMO_REQUEST_LIST_FAILURE,
      error,
    };
  }
}

function getNewReferralsList(data) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "dashboard/getNewReferralsList ",
  };
  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (users) => {
        dispatch(success(users));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: dashboardConstant.GET_NEW_REFERRAL_LIST_REQUEST };
  }
  function success(users) {
    return { type: dashboardConstant.GET_NEW_REFERRAL_LIST_SUCCESS, users };
  }
  function failure(error) {
    return { type: dashboardConstant.GET_NEW_REFERRAL_LIST_FAILURE, error };
  }
}


function getLatestChangeTutorRequest(data) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "dashboard/getLatestChangeTutorRequest ",
  };
  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (users) => {
        dispatch(success(users));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: dashboardConstant.GET_LATEST_TUTOR_CHANGE_REQUEST };
  }
  function success(users) {
    return { type: dashboardConstant.GET_LATEST_TUTOR_CHANGE_SUCCESS, users };
  }
  function failure(error) {
    return { type: dashboardConstant.GET_LATEST_TUTOR_CHANGE_FAILURE, error };
  }
}

