/* eslint-disable no-whitespace-before-property */

import { bannerConstants } from "../_constants";

export default function banner(state = {},action) {
  switch (action.type) {
    case bannerConstants.BANNER_CREATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case bannerConstants.BANNER_CREATE_SUCCESS:
      return {
        ...state,
        bannerCreate: action?.user,
        isSuccessResponse: true,
        loading: false,
      };
    case bannerConstants.BANNER_CREATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case bannerConstants.BANNER_GET_REQUEST:
      return {
        ...state,
        loading: true
      };
    case bannerConstants.BANNER_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        isSuccessResponse: false,
        bannerGetTotal: action?.user?.data?.total,
        bannerGetList: action?.user?.data?.list

      };
    case bannerConstants.BANNER_GET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      }
      case bannerConstants.BANNER_DELETE_REQUEST:
        return {
          ...state,
          loading:true
        };
      case bannerConstants.BANNER_DELETE_SUCCESS:
       return{
        ...state,
        loading:false,
       };
      case bannerConstants .BANNER_DELETE_FAILURE:
        return {
          ...state,
          loading:false,
          error:action.error
        };
       case bannerConstants.BANNER_UPDATE_REQUEST:
        return {
          ...state,
          loading:true
        };
        case bannerConstants.BANNER_UPDATE_SUCCESS:
          return {
            ...state,
            loading:false,
          }
        case bannerConstants.BANNER_UPDATE_FAILURE:
          return {
            ...state,
            loading:false,
            error:action.error
          }  
         case bannerConstants.BANNER_ENA_DIS_REQUEST:
          return{
            ...state,
            loading:true
          };
          case bannerConstants.BANNER_ENA_DIS_SUCCESS:
            return{
              ...state,
              loading:false
              
            };
            case bannerConstants.BANNER_ENA_DIS_FAILURE:
              return{
                ...state,
                loading:false,
                error:action.error
              }  
    default:
      return state;
  }
}




