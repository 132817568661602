import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { feeAction } from "../../../_actions/fee.action";
import { useLocation } from "react-router-dom";
import { downloadCSV } from "../../../_helpers/auth-header"


const FeePaid = () => {
  const dispatch = useDispatch();
  const selector = useSelector((state) => state);
  let feePaidListData = selector?.Fee?.feePaidList?.data?.list
  const location = useLocation();
  const feePaidData = location.state?.feePaidData;
  const allFeeData = selector?.Fee?.allFeeReceived?.data
  let monthId = feePaidData?.month

  useEffect(() => {

    let feePaidData = {
      "cityId": "",
      "month": monthId,
      "keyWord": "",
      "pageNo": 1,
      "size": 10
    }

    let feePaidData1 = {
      cityId: "",
      month: monthId
    }
    dispatch(feeAction.getFeePaidList(feePaidData))
    dispatch(feeAction.getAllFeePaid(feePaidData1))
  }, [])

  const convertToCSV = (data) => {

  }
  return (
    <div className='m-5'>
      <div className="flex justify-end">
        <button className="bg-white px-3 py-2 border flex justify-between rounded-lg" onClick={() => downloadCSV(convertToCSV(allFeeData))}>
          <span>Export</span>  <img src="/export 1.png" alt='' />
        </button>
      </div>
      <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table class="w-full text-sm text-left rtl:text-right ">
          <thead class="text-xs text-blue-700 bg-white">
            <tr className="py-4 border-b">
              <th scope="col" class="px-6 py-4">
                Parent name
              </th>
              <th scope="col" class="px-6 py-3">
                subject
              </th>
              <th scope="col" class="px-6 py-3">
                Class
              </th>
              <th scope="col" class="px-6 py-3">
                Fee
              </th>
              <th scope="col" class="px-6 py-3">
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            {feePaidListData &&
              feePaidListData?.map((item, id) => (
                <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                  <th
                    scope="row"
                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    <div className="flex gap-3 pla">
                      <div>
                        <img
                          src={
                            item?.parentId?.image ??
                            "https://thumbs.dreamstime.com/z/vector-illustration-avatar-dummy-logo-collection-image-icon-stock-isolated-object-set-symbol-web-137160339.jpg?ct=jpeg"
                          }
                          alt=''
                          className="w-8 rounded-full"
                        />
                      </div>
                      <div>
                        <p>{item?.parentId?.name}</p>
                        <p>{item?.parentId?.mobNo}</p>
                      </div>
                    </div>
                  </th>
                  <td class="px-6 py-4">
                    {item?.demoClassId?.bookFreeDemo?.map((subj, id) => (
                      <p key={id}>{subj?.subjectId?.name ?? "No Subject"}</p>
                    ))}
                  </td>
                  <td class="px-6 py-4">
                    {item?.demoClassId?.classId?.map((item, index) => (
                      <p key={index}>{item?.name}</p>
                    ))}
                  </td>
                  <td class="px-6 py-4">{item?.fee ?? "0"}</td>
                  <td class="px-6 py-4">
                    {item?.status === 0
                      ? "Pending"
                      : item?.status === 1
                        ? "Received"
                        : item?.status === 2
                          ? " advance paid"
                          : "Unknown status"}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default FeePaid
