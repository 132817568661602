import React from 'react'
import { MdOutlineClose } from 'react-icons/md'

function AddBanner({ adduser, closeModal, handleSubmit, addBanner, handelOnChangeInput, uploadImage, error, errorData }) {
    return (
        <div>
            {adduser && (
                <>
                    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40">
                        <div className="relative w-11/12 mx-auto lg:w-[600px]">
                            <button
                                className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center"
                                onClick={closeModal}
                            >
                                <span><MdOutlineClose /></span> Close
                            </button>
                            <div className="form_class border-0 rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                <div className="flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                    <h2 className="text-lg font-semibold">Create Banner</h2>
                                </div>
                                <div className="relative lg:h-[340px] overflow-y-auto">
                                    <form onSubmit={handleSubmit} className="space-y-2 p-4" >
                                        <div className="space-y-2">
                                            <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900">
                                                Banner Name:
                                            </label>
                                            <input
                                                type="text"
                                                id="name"
                                                name="name"
                                                value={addBanner.name}
                                                placeholder="Enter Banner name"
                                                onChange={(e) => handelOnChangeInput(e)}
                                                className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                                            />
                                            {errorData && errorData["name"] ? <p className="text-red-500 mt-2">{errorData["name"]}</p> : null}
                                        </div>
                                        <div className="space-y-2">
                                            <label
                                                htmlFor="countries"
                                                className="block mb-2 text-sm font-medium text-gray-900 "
                                            >
                                                Select Type:
                                            </label>
                                            <select
                                                onChange={(e) => handelOnChangeInput(e)}
                                                id="countries"
                                                name="userType"
                                                value={addBanner.userType}
                                                className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                                            >
                                                <option selected="">Choose a User Type</option>
                                                <option value="PARENT">Parent</option>
                                                <option value="TUTOR">Tutor</option>
                                                <option value="BOTH">For Both User Types</option>
                                            </select>
                                            {errorData && errorData["userType"] ? <p className="text-red-500 mt-2">{errorData["userType"]}</p> : null}
                                        </div>
                                        <div className="space-y-2">
                                            <label htmlFor="image" className="block mb-2 text-sm font-medium text-gray-900">
                                                Image:
                                            </label>

                                            <input
                                                type="file"
                                                id="image"
                                                accept="image/*"
                                                onChange={(e) => uploadImage(e)}
                                                className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                                            />
                                            {error && <p className="text-red-500 mt-2">{error}</p>}
                                            {errorData && errorData["image"] ? <p className="text-red-500 mt-2">{errorData["image"]}</p> : null}
                                        </div>
                                        <div className="flex justify-center items-center pt-2">
                                            <button
                                                type="submit"
                                                className="w-60 mx-auto bg-[#023565] text-white font-semibold py-2 px-4 rounded-full "
                                            >
                                                Submit
                                            </button>
                                        </div>
                                    </form>
                                </div>

                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}

export default AddBanner
