export const attandanceConstant={
    GET_ATTENDANCE_LIST_REQUEST:"GET_ATTENDANCE_LIST_REQUEST",
    GET_ATTENDANCE_LIST_SUCCESS:"GET_ATTENDANCE_LIST_SUCCESS",
    GET_ATTENDANCE_LIST_FAILURE:"GET_ATTENDANCE_LIST_FAILURE",

    GET_ADMIN_VIEWATTENDANCE_REQUEST:"GET_ADMIN_VIEWATTENDANCE_REQUEST",
    GET_ADMIN_VIEWATTENDANCE_SUCCESS:"GET_ADMIN_VIEWATTENDANCE_SUCCESS",
    GET_ADMIN_VIEWATTENDANCE_FAILURE:"GET_ADMIN_VIEWATTENDANCE_FAILURE",

    UPDATE_ATTENDANCE_BYADMIN_REQUEST:"UPDATE_ATTENDANCE_BYADMIN_REQUEST",
    UPDATE_ATTENDANCE_BYADMIN_SUCCESS:"UPDATE_ATTENDANCE_BYADMIN_SUCCESS",
    UPDATE_ATTENDANCE_BYADMIN_FAILURE:"UPDATE_ATTENDANCE_BYADMIN_FAILURE",

    GET_ABSENT_TODAY_BY_ADMIN_REQUEST:"GET_ABSENT_TODAY_BY_ADMIN_REQUEST",
    GET_ABSENT_TODAY_BY_ADMIN_SUCCESS:"GET_ABSENT_TODAY_BY_ADMIN_SUCCESS",
    GET_ABSENT_TODAY_BY_ADMIN_FAILURE:"GET_ABSENT_TODAY_BY_ADMIN_FAILURE",

    CHECKIN_CHECKOUT_BYADMIN_REQUEST:"CHECKIN_CHECKOUT_BYADMIN_REQUEST",
    CHECKIN_CHECKOUT_BYADMIN_SUCCESS:"CHECKIN_CHECKOUT_BYADMIN_SUCCESS",
    CHECKIN_CHECKOUT_BYADMIN_FAILURE:"CHECKIN_CHECKOUT_BYADMIN_FAILURE",

    UPDATE_ATTENDANCE_BYADMIN_1_REQUEST:"UPDATE_ATTENDANCE_BYADMIN_1_REQUEST",
    UPDATE_ATTENDANCE_BYADMIN_1_SUCCESS:"UPDATE_ATTENDANCE_BYADMIN_1_SUCCESS",
    UPDATE_ATTENDANCE_BYADMIN_1_FAILURE:"UPDATE_ATTENDANCE_BYADMIN_1_FAILURE",


    



}