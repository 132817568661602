/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { userListAction } from '../../_actions'
import { IoArrowBack } from 'react-icons/io5'

import moment from 'moment'

const FeepaymentHistory = () => {
  const dispatch = useDispatch()
  const selector = useSelector(state => state)
  let [FeeData, setFeeData] = useState(
    selector?.userList?.getFeeHistoryById?.data
  )
  const location = useLocation()
  const tutorData = location.state?.tutorData

  useEffect(() => {
    let feeId = { userId: tutorData._id }
    dispatch(userListAction.getFeeHistoryByUserId(feeId))
  }, [])

  useEffect(() => {
    setFeeData(selector?.userList?.getFeeHistoryById?.data)
  }, [selector])
  const handleBack = () => {
    window.history.back()
  }

  return (
    <div className='m-5'>
      <div className='flex gap-2'>
        <IoArrowBack
          className='text-[#023565] text-3xl border rounded-full border-black my-2 mx-3 cursor-pointer bg-white'
          onClick={handleBack}
        />
      </div>
      <h1 className='text-center text-blue-600 font-semibold'>
        Fee Payment History
      </h1>
      {tutorData ? (
        <div className='mt-5 bg-white sm:rounded-lg border border-[#D3D3D3]'>
          <div className='my-3  p-5 border-b'>
            <p className='capitalize'>Tutor Name: {tutorData.name}</p>
            <p className='capitalize'>Address: {tutorData.address}</p>
            <p className='capitalize'>
              City: {tutorData.city?.name ?? 'No City'}
            </p>
          </div>

          <div class='mt-5 border border-[#D3D3D3] rounded-xl  bg-white'>
            <table class='min-w-full  table-autorounded-xl overflow-x-auto '>
              <thead class=' text-center'>
                <tr className='border-b border-[#D3D3D3] capitalize'>
                  <th
                    scope='col'
                    class='px-6 py-3  text-center text-[#313131] whitespace-nowrap  table_head'
                  >
                    tutor Fee
                  </th>
                  <th
                    scope='col'
                    class='px-6 py-3  text-center text-[#313131] whitespace-nowrap  table_head'
                  >
                    tuition Fee
                  </th>
                  <th
                    scope='col'
                    class='px-6 py-3  text-center text-[#313131] whitespace-nowrap  table_head'
                  >
                    reference Fee
                  </th>

                  <th
                    scope='col'
                    class='px-6 py-3  text-center text-[#313131] whitespace-nowrap  table_head'
                  >
                    payment Mode
                  </th>
                  <th
                    scope='col'
                    class='px-6 py-3  text-center text-[#313131] whitespace-nowrap  table_head '
                  >
                    Status
                  </th>
                  <th
                    scope='col'
                    class='px-6 py-3  text-center text-[#313131] whitespace-nowrap  table_head'
                  >
                    Created At
                  </th>
                </tr>
              </thead>
              <tbody>
                {FeeData &&
                  FeeData?.map((itm, id) => (
                    <tr
                      class='table_row capitalize border-b text-center'
                      key={id}
                    >
                      <td class='text-center text-sm text-gray-900 px-6 py-3 '>
                        {itm?.demoClassId?.tutorFee ?? 'N/A'}
                      </td>
                      <td class='px-6 py-4'>
                        {itm?.demoClassId?.tuitionFee ?? 'N/A'}
                      </td>
                      <td class='text-center text-sm text-gray-900 px-6 py-3'>
                        {itm?.demoClassId?.referenceFee ?? 'N/A'}
                      </td>

                      <td class='text-center text-sm text-gray-900 px-6 py-3'>
                        {itm?.demoClassId?.paymentMode ?? 'N/A'}
                      </td>
                      <td class='text-center text-sm text-gray-900 px-6 py-3text-green-800'>
                        {itm.status === 0
                          ? 'Unpaid'
                          : itm.status === 1
                          ? 'Paid'
                          : itm.status === 2
                          ? 'Paid in Advance'
                          : 'Refund'}
                      </td>
                      <td class='text-center text-sm text-gray-900 px-6 py-3'>
                        {itm?.createdAt
                          ? moment(itm?.createdAt).format('DD-MM-YYYY')
                          : 'N/A'}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <p>No tutor data available</p>
      )}
    </div>
  )
}

export default FeepaymentHistory
