export const tickectConstants={
    GET_TICKET_LIST_REQUEST:"GET_TICKET_LIST_REQUEST",
    GET_TICKET_LIST_SUCCESS:"GET_TICKET_LIST_SUCCESS",
    GET_TICKET_LIST_FAILURE:"GET_TICKET_LIST_FAILURE",

    CREATE_TICKET_REPLY_REQUEST:"CREATE_TICKET_REPLY_REQUEST",
    CREATE_TICKET_REPLY_SUCCESS:"CREATE_TICKET_REPLY_SUCCESS",
    CREATE_TICKET_REPLY_FAILURE:"CREATE_TICKET_REPLY_FAILURE",

    GET_MSG_TICKET_REPLY_REQUEST:"GET_MSG_TICKET_REPLY_REQUEST",
    GET_MSG_TICKET_REPLY_SUCCESS:"GET_MSG_TICKET_REPLY_SUCCESS",
    GET_MSG_TICKET_REPLY_FAILURE:"GET_MSG_TICKET_REPLY_FAILURE",

    RESOLVED_TICKET_REQUEST:"RESOLVED_TICKET_REQUEST",
    RESOLVED_TICKET_SUCCESS:"RESOLVED_TICKET_SUCCESS",
    RESOLVED_TICKET_FAILURE:"RESOLVED_TICKET_FAILURE",
}


