export const feeConstant = {
    GET_TUTION_EARNING_LIST_REQUEST: "GET_TUTION_EARNING_LIST_REQUEST",
    GET_TUTION_EARNING_LIST_SUCCESS: "GET_TUTION_EARNING_LIST_SUCCESS",
    GET_TUTION_EARNING_LIST_FAILURE: "GET_TUTION_EARNING_LIST_FAILURE",

    GET_FEE_RECEVIED_LIST_REQUEST: "GET_FEE_RECEVIED_LIST_REQUEST",
    GET_FEE_RECEVIED_LIST_SUCCESS: "GET_FEE_RECEVIED_LIST_SUCCESS",
    GET_FEE_RECEVIED_LIST_FAILURE: "GET_FEE_RECEVIED_LIST_FAILURE",

    GET_FEE_PAID_LIST_REQUEST: "GET_FEE_PAID_LIST_REQUEST",
    GET_FEE_PAID_LIST_SUCCESS: "GET_FEE_PAID_LIST_SUCCESS",
    GET_FEE_PAID_LIST_FAILURE: "GET_FEE_PAID_LIST_FAILURE",

    GET_ALL_TUTOR_FEE_PENDING_REQUEST: "GET_ALL_TUTOR_FEE_PENDING_REQUEST",
    GET_ALL_TUTOR_FEE_PENDING_SUCCESS: "GET_ALL_TUTOR_FEE_PENDING_SUCCESS",
    GET_ALL_TUTOR_FEE_PENDING_FAILURE: "GET_ALL_TUTOR_FEE_PENDING_FAILURE",

    GET_ALL_FEE_PAID_REQUEST: "GET_ALL_FEE_PAID_REQUEST",
    GET_ALL_FEE_PAID_SUCCESS: "GET_ALL_FEE_PAID_SUCCESS",
    GET_ALL_FEE_PAID_FAILURE: "GET_ALL_FEE_PAID_FAILURE",

    GET_TOTAL_REFUNDED_AMOUNT_LIST_REQUEST: "GET_TOTAL_REFUNDED_AMOUNT_LIST_REQUEST",
    GET_TOTAL_REFUNDED_AMOUNT_LIST_SUCCESS: "GET_TOTAL_REFUNDED_AMOUNT_LIST_SUCCESS",
    GET_TOTAL_REFUNDED_AMOUNT_LIST_FAILURE: "GET_TOTAL_REFUNDED_AMOUNT_LIST_FAILURE",

    GET_TOTAL_RECEIVED_AMOUNT_LIST_REQUEST: "GET_TOTAL_RECEIVED_AMOUNT_LIST_REQUEST",
    GET_TOTAL_RECEIVED_AMOUNT_LIST_SUCCESS: "GET_TOTAL_RECEIVED_AMOUNT_LIST_SUCCESS",
    GET_TOTAL_RECEIVED_AMOUNT_LIST_FAILURE: "GET_TOTAL_RECEIVED_AMOUNT_LIST_FAILURE",

    GET_ALL_TUTOR_FEE_PAID_REQUEST: "GET_ALL_TUTOR_FEE_PAID_REQUEST",
    GET_ALL_TUTOR_FEE_PAID_SUCCESS: "GET_ALL_TUTOR_FEE_PAID_SUCCESS",
    GET_ALL_TUTOR_FEE_PAID_FAILURE: "GET_ALL_TUTOR_FEE_PAID_FAILURE",

    GET_ALL_FEE_REFUND_REQUEST: "GET_ALL_FEE_REFUND_REQUEST",
    GET_ALL_FEE_REFUND_SUCCESS: "GET_ALL_FEE_REFUND_SUCCESS",
    GET_ALL_FEE_REFUND_FAILURE: "GET_ALL_FEE_REFUND_FAILURE",

    GET_LIST_REFERRAL_PAID_REQUEST: "GET_LIST_REFERRAL_PAID_REQUEST",
    GET_LIST_REFERRAL_PAID_SUCCESS: "GET_LIST_REFERRAL_PAID_SUCCESS",
    GET_LIST_REFERRAL_PAID_FAILURE: "GET_LIST_REFERRAL_PAID_FAILURE",

    GET_ALL_REFERENCE_PAID_REQUEST: "GET_ALL_REFERENCE_PAID_REQUEST",
    GET_ALL_REFERENCE_PAID_SUCCESS: "GET_ALL_REFERENCE_PAID_SUCCESS",
    GET_ALL_REFERENCE_PAID_FAILURE: "GET_ALL_REFERENCE_PAID_FAILURE",

    GET_ALL_FEE_RECEIVED_REQUEST: "GET_ALL_FEE_RECEIVED_REQUEST",
    GET_ALL_FEE_RECEIVED_SUCCESS: "GET_ALL_FEE_RECEIVED_SUCCESS",
    GET_ALL_FEE_RECEIVED_FAILURE: "GET_ALL_FEE_RECEIVED_FAILURE",

    GET_FEE_PENDING_LIST_REQUEST: "GET_FEE_PENDING_LIST_REQUEST",
    GET_FEE_PENDING_LIST_SUCCESS: "GET_FEE_PENDING_LIST_SUCCESS",
    GET_FEE_PENDING_LIST_FAILURE: "GET_FEE_PENDING_LIST_FAILURE",

    GET_ALL_FEE_PENDING_REQUEST: "GET_ALL_FEE_PENDING_REQUEST",
    GET_ALL_FEE_PENDING_SUCCESS: "GET_ALL_FEE_PENDING_SUCCESS",
    GET_ALL_FEE_PENDING_FAILURE: "GET_ALL_FEE_PENDING_FAILURE",

    GET_TUTOR_FEE_PAID_LIST_REQUEST: "GET_TUTOR_FEE_PAID_LIST_REQUEST",
    GET_TUTOR_FEE_PAID_LIST_SUCCESS: "GET_TUTOR_FEE_PAID_LIST_SUCCESS",
    GET_TUTOR_FEE_PAID_LIST_FAILURE: "GET_TUTOR_FEE_PAID_LIST_FAILURE",

    GET_TUTOR_FEE_PENDIGN_LIST_REQUEST: "GET_TUTOR_FEE_PENDIGN_LIST_REQUEST",
    GET_TUTOR_FEE_PENDIGN_LIST_SUCCESS: "GET_TUTOR_FEE_PENDIGN_LIST_SUCCESS",
    GET_TUTOR_FEE_PENDIGN_LIST_FAILURE: "GET_TUTOR_FEE_PENDIGN_LIST_FAILURE",

    GET_REFERENCE_PAID_LIST_REQUEST: "GET_REFERENCE_PAID_LIST_REQUEST",
    GET_REFERENCE_PAID_LIST_SUCCESS: "GET_REFERENCE_PAID_LIST_SUCCESS",
    GET_REFERENCE_PAID_LIST_FAILURE: "GET_REFERENCE_PAID_LIST_FAILURE",

    GET_REFERRAL_PAID_LIST_REQUEST: "GET_REFERRAL_PAID_LIST_REQUEST",
    GET_REFERRAL_PAID_LIST_SUCCESS: "GET_REFERRAL_PAID_LIST_SUCCESS",
    GET_REFERRAL_PAID_LIST_FAILURE: "GET_REFERRAL_PAID_LIST_FAILURE",

    GET_FEE_REFUND_LIST_REQUEST: "GET_FEE_REFUND_LIST_REQUEST",
    GET_FEE_REFUND_LIST_SUCCESS: "GET_FEE_REFUND_LIST_SUCCESS",
    GET_FEE_REFUND_LIST_FAILURE: "GET_FEE_REFUND_LIST_FAILURE",


    GET_FEE_HISTORY_COUNT_REQUEST: "GET_FEE_HISTORY_COUNT_REQUEST",
    GET_FEE_HISTORY_COUNT_SUCCESS: "GET_FEE_HISTORY_COUNT_SUCCESS",
    GET_FEE_HISTORY_COUNT_FAILURE: "GET_FEE_HISTORY_COUNT_FAILURE",

    GET_CREDIT_BASE_TUITION_EARNING_REQUEST: "GET_CREDIT_BASE_TUITION_EARNING_REQUEST",
    GET_CREDIT_BASE_TUITION_EARNING_SUCCESS: "GET_CREDIT_BASE_TUITION_EARNING_SUCCESS",
    GET_CREDIT_BASE_TUITION_EARNING_FAILURE: "GET_CREDIT_BASE_TUITION_EARNING_FAILURE",


    GET_TRANSACTION_REQUEST: "GET_TRANSACTION_REQUEST",
    GET_TRANSACTION_SUCCESS: "GET_TRANSACTION_SUCCESS",
    GET_TRANSACTION_FAILURE: "GET_TRANSACTION_FAILURE",

    FEE_REMARK_REQUEST: 'FEE_REMARK_REQUEST',
    FEE_REMARK_SUCCESS: 'FEE_REMARK_SUCCESS',
    FEE_REMARK_FAILURE: 'FEE_REMARK_FAILURE',

    UPDATE_FEE_DUE_DATE_REQUEST: "UPDATE_FEE_DUE_DATE_REQUEST",
    UPDATE_FEE_DUE_DATE_SUCCESS: "UPDATE_FEE_DUE_DATE_SUCCESS",
    UPDATE_FEE_DUE_DATE_FAILURE: "UPDATE_FEE_DUE_DATE_FAILURE",

    GET_NO_FEE_LIST_REQUEST: "GET_NO_FEE_LIST_REQUEST",
    GET_NO_FEE_LIST_SUCCESS: "GET_NO_FEE_LIST_SUCCESS",
    GET_NO_FEE_LIST_FAILURE: "GET_NO_FEE_LIST_FAILURE",

    GET_ALL_NO_FEE_REQUEST: "GET_ALL_NO_FEE_REQUEST",
    GET_ALL_NO_FEE_SUCCESS: "GET_ALL_NO_FEE_SUCCESS",
    GET_ALL_NO_FEE_FAILURE: "GET_ALL_NO_FEE_FAILURE",


}