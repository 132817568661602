export const tutortoliveConstant = {
    GET_NEWLY_JOIN_TUTOR_LIST_REQUEST: "GET_NEWLY_JOIN_TUTOR_LIST_REQUEST",
    GET_NEWLY_JOIN_TUTOR_LIST_SUCCESS: "GET_NEWLY_JOIN_TUTOR_LIST_SUCCESS",
    GET_NEWLY_JOIN_TUTOR_LIST_FAILURE: "GET_NEWLY_JOIN_TUTOR_LIST_FAILURE",

    INTERVIEW_STATUS_UPDATE_REQUEST: "INTERVIEW_STATUS_UPDATE_REQUEST",
    INTERVIEW_STATUS_UPDATE_SUCCESS: "INTERVIEW_STATUS_UPDATE_SUCCESS",
    INTERVIEW_STATUS_UPDATE_FAILURE: "INTERVIEW_STATUS_UPDATE_FAILURE",

    GET_NEW_JOINED_TUTOR_COUNT_REQUEST: "GET_NEW_JOINED_TUTOR_COUNT_REQUEST",
    GET_NEW_JOINED_TUTOR_COUNT_SUCCESS: "GET_NEW_JOINED_TUTOR_COUNT_SUCCESS",
    GET_NEW_JOINED_TUTOR_COUNT_FAILURE: "GET_NEW_JOINED_TUTOR_COUNT_FAILURE",


    GET_NEWLY_JOIN_2_TUTOR_LIST_REQUEST: "GET_NEWLY_JOIN_2_TUTOR_LIST_REQUEST",
    GET_NEWLY_JOIN_2_TUTOR_LIST_SUCCESS: "GET_NEWLY_JOIN_2_TUTOR_LIST_SUCCESS",
    GET_NEWLY_JOIN_2_TUTOR_LIST_FAILURE: "GET_NEWLY_JOIN_2_TUTOR_LIST_FAILURE",


    REMOVE_PROFILE_REQUEST: "REMOVE_PROFILE_REQUEST",
    REMOVE_PROFILE_SUCCESS: "REMOVE_PROFILE_SUCCESS",
    REMOVE_PROFILE_FAILURE: "REMOVE_PROFILE_FAILURE",

    UPDATE_TUTOR_BYADMIN_REQUEST: "UPDATE_TUTOR_BYADMIN_REQUEST",
    UPDATE_TUTOR_BYADMIN_SUCCESS: "UPDATE_TUTOR_BYADMIN_SUCCESS",
    UPDATE_TUTOR_BYADMIN_FAILURE: "UPDATE_TUTOR_BYADMIN_FAILURE",


    GET_NEWLY_JOIN_TUTOR_LIST3_REQUEST: "GET_NEWLY_JOIN_TUTOR_LIST3_REQUEST",
    GET_NEWLY_JOIN_TUTOR_LIST3_SUCCESS: "GET_NEWLY_JOIN_TUTOR_LIST3_SUCCESS",
    GET_NEWLY_JOIN_TUTOR_LIST3_FAILURE: "GET_NEWLY_JOIN_TUTOR_LIST3_FAILURE",




    GET_NEWLY_JOIN_TUTOR_LIST2_REQUEST: "GET_NEWLY_JOIN_TUTOR_LIST2_REQUEST",
    GET_NEWLY_JOIN_TUTOR_LIST2_SUCCESS: "GET_NEWLY_JOIN_TUTOR_LIST2_SUCCESS",
    GET_NEWLY_JOIN_TUTOR_LIST2_FAILURE: "GET_NEWLY_JOIN_TUTOR_LIST2_FAILURE",


    GET_TUTOR_BY_ID_REQUEST: "GET_TUTOR_BY_ID_REQUEST",
    GET_TUTOR_BY_ID_SUCCESS: "GET_TUTOR_BY_ID_SUCCESS",
    GET_TUTOR_BY_ID_FAILURE: "GET_TUTOR_BY_ID_FAILURE",
}