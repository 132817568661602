import React from 'react'
import { MdOutlineClose } from 'react-icons/md'

const UpdateFeeDueDate = ({ setFeeData, feeData, dateError, setDateError, handleSubmitFeeData, handleCloseFeeData }) => {

    const handleChange = (e) => {
        const { name, value } = e.target;

        setFeeData((prev) => ({
            ...prev,
            [name]: value
        }))

        setDateError((prev) => ({
            ...prev,
            [name]: ''
        }))
    }
    return (
        <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40">
                <div className="relative w-11/12 mx-auto lg:w-[600px]">
                    <button
                        className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center"
                        onClick={handleCloseFeeData}
                    >
                        <span><MdOutlineClose /></span> Close
                    </button>
                    <div className="form_class border-0 rounded-[10px] shadow-lg p-6 relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        <div className="flex items-center justify-between  border-b border-solid border-slate-200 rounded-t">
                            <h2 className="text-lg font-semibold">Update Fee Due Date </h2>
                        </div>
                        <div className="relative overflow-y-auto">
                            <div className="board_name">
                                <label htmlFor="dueDate" className="block text-sm font-medium text-gray-700">
                                    Tuition Fee Due Date:
                                </label>
                                <select name='dueDate' onChange={(e) => { handleChange(e) }} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    value={feeData?.dueDate ? feeData?.dueDate : ""}
                                >
                                    <option value=''>Select Due Date</option>
                                    {Array.from(Array(31), (_, i) => (
                                        <option value={i + 1}>{i + 1}</option>
                                    ))}
                                </select>
                                {dateError.dueDate && <p className="text-red-500 text-xs">{dateError.dueDate}</p>}

                            </div>

                            <div className="board_name">
                                <label htmlFor="tutorDueDate" className="block text-sm font-medium text-gray-700">
                                    Tutor Fee Due Date:
                                </label>
                                <select name='tutorDueDate' onChange={(e) => { handleChange(e) }} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    value={feeData?.tutorDueDate ? feeData?.tutorDueDate : ""}
                                >
                                    <option value=''>Select Due Date</option>
                                    {Array.from(Array(31), (_, i) => (
                                        <option value={i + 1}>{i + 1}</option>
                                    ))}
                                </select>
                                {dateError.tutorDueDate && <p className="text-red-500 text-xs">{dateError.tutorDueDate}</p>}

                            </div>

                            <div className="flex justify-center items-center pt-6">
                                <button
                                    className="w-60 mx-auto bg-[#023565] text-white font-semibold py-2 px-4 rounded-full "
                                    onClick={handleSubmitFeeData}
                                >
                                    Submit
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </>
    )
}

export default UpdateFeeDueDate