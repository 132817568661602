import { boardConstants, classConstants } from "../_constants";
export default function Board(state = {}, action) {
  switch (action.type) {
    case boardConstants.GET_BOARD_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case boardConstants.GET_BOARD_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        bordList: action?.users?.data,
        bordTotal: action?.users?.data?.total,
      };

    case boardConstants.GET_BOARD_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case boardConstants.DELETE_BOARD_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case boardConstants.DELETE_BOARD_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case boardConstants.DELETE_BOARD_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case boardConstants.CREATE_BOARD_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case boardConstants.CREATE_BOARD_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        createBoard: action.users,
      };

    case boardConstants.CREATE_BOARD_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case boardConstants.UPDATE_BOARD_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case boardConstants.UPDATE_BOARD_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case boardConstants.UPDATE_BOARD_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case boardConstants.ENA_DIS_BOARD_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case boardConstants.ENA_DIS_BOARD_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case boardConstants.ENA_DIS_BOARD_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case classConstants.GET_CLASS_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case classConstants.GET_CLASS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        classList: action?.users?.data?.list,
      };
    case classConstants.GET_CLASS_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case classConstants.CREATE_CLASS_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case classConstants.CREATE_CLASS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        classCreate: action?.users,
      };
    case classConstants.CREATE_CLASS_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case classConstants.DELETE_CLASS_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case classConstants.DELETE_CLASS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case classConstants.DELETE_CLASS_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case classConstants.UPDATE_CLASS_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case classConstants.UPDATE_CLASS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case classConstants.UPDATE_CLASS_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case classConstants.ENA_DIS_CLASS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case classConstants.ENA_DIS_CLASS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case classConstants.ENA_DIS_CLASS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    default:
      return state;
  }
}
