import { classConstants } from "../_constants";
import { APIcallFunction,headerForPrivateAPI } from "../_helpers"
import { alertActions } from "./alert.actions";


export const classAction={
    classGet,
    classDelete,
    classCreate,
    classEdit,
    classEnaDis,
    gitAllSubjectList,
    getCountForClassSubjectBoardByPromise
}

function getCountForClassSubjectBoardByPromise(data) {
    const credentials = {
      header: headerForPrivateAPI(),
      method: "POST",
      body: data ?? {},
      endPoint: "class/getCountForClassSubjectBoard"
    };
  
    return APIcallFunction(credentials)
  
  }


function classGet(data) {
    
    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: "class/getClassList"
    };
    return (dispatch) => {
        dispatch(request(data));
        APIcallFunction(credentials).then(
            (users) => {
                
                dispatch(success(users))
            },
            (error) => {
               
                dispatch(failure(error))
            }
        )

    }


    function request() { return { type: classConstants.GET_CLASS_LIST_REQUEST } }
    function success(users) { return { type: classConstants.GET_CLASS_LIST_SUCCESS, users } }
    function failure(error) { return { type: classConstants.GET_CLASS_LIST_FAILURE, error } }


};

function classDelete(data,ClassList) {
    
    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: "class/delete "
    };
    return (dispatch) => {
        dispatch(request(data));
        APIcallFunction(credentials).then(
            (users) => {
                dispatch(alertActions.success("Class Deleted"))
                dispatch(success(users))
                dispatch(classGet(ClassList))
            },
            (error) => {
               
                dispatch(failure(error))
            }
        )

    }


    function request() { return { type: classConstants.DELETE_CLASS_LIST_REQUEST } }
    function success(users) { return { type: classConstants.DELETE_CLASS_LIST_SUCCESS, users } }
    function failure(error) { return { type: classConstants.DELETE_CLASS_LIST_FAILURE, error } }


};

function classCreate(data,ClassList) {
    
    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: "class/create"
    };
    return (dispatch) => {
        dispatch(request(data));
        APIcallFunction(credentials).then(
            (users) => {
                dispatch(alertActions.success("Class Created"))
                dispatch(success(users))
                dispatch(classGet(ClassList))
            },
            (error) => {
               
                dispatch(failure(error))
            }
        )

    }


    function request() { return { type: classConstants.CREATE_CLASS_LIST_REQUEST } }
    function success(users) { return { type: classConstants.CREATE_CLASS_LIST_SUCCESS, users } }
    function failure(error) { return { type: classConstants.CREATE_CLASS_LIST_FAILURE, error } }


};

function classEdit(data,ClassList) {
    
    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: "class/update"
    };
    return (dispatch) => {
        dispatch(request(data));
        APIcallFunction(credentials).then(
            (users) => {
                dispatch(alertActions.success("Class Updated"))
                dispatch(success(users))
                dispatch(classGet(ClassList))
            },
            (error) => {
               
                dispatch(failure(error))
            }
        )

    }


    function request() { return { type: classConstants.CREATE_CLASS_LIST_REQUEST } }
    function success(users) { return { type: classConstants.CREATE_CLASS_LIST_SUCCESS, users } }
    function failure(error) { return { type: classConstants.CREATE_CLASS_LIST_FAILURE, error } }


};

function classEnaDis(data,ClassList) {
    
    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: "class/enable-disable "
    };
    return (dispatch) => {
        dispatch(request(data));
        APIcallFunction(credentials).then(
            (users) => {
                // dispatch(alertActions.success("Class Action Successfully"))
                dispatch(success(users))
                dispatch(classGet(ClassList))
            },
            (error) => {
               
                dispatch(failure(error))
            }
        )

    }


    function request() { return { type: classConstants.ENA_DIS_CLASS_LIST_REQUEST } }
    function success(users) { return { type: classConstants.ENA_DIS_CLASS_LIST_SUCCESS, users } }
    function failure(error) { return { type: classConstants.ENA_DIS_CLASS_LIST_FAILURE, error } }


};


function gitAllSubjectList(data,ClassList) {
    
    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: "subject/gitAllSubjectList "
    };
    return (dispatch) => {
        dispatch(request(data));
        APIcallFunction(credentials).then(
            (users) => {
                dispatch(alertActions.success("Class Action Successfully"))
                dispatch(success(users))
                dispatch(classGet(ClassList))
            },
            (error) => {
               
                dispatch(failure(error))
            }
        )

    }


    function request() { return { type: classConstants.GET_ALL_SUBJECT_REQUEST } }
    function success(users) { return { type: classConstants.GET_ALL_SUBJECT_SUCCESS, users } }
    function failure(error) { return { type: classConstants.GET_ALL_SUBJECT_FAILURE, error } }


};