import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { holidaysAction } from '../../_actions'
import { MdOutlineClose } from 'react-icons/md'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'
import './Holiday.css'
import MyCalendar from './CalenderView'
import moment from 'moment'
import Loader from '../../components/Loader'
import { GoDotFill } from 'react-icons/go'
import { RiDeleteBinLine } from 'react-icons/ri'

const Holidays = ({ handleNavigate, newFunction, dateStampNew }) => {
  const [modal, setModal] = useState(false)
  const [name, setName] = useState('')
  const [date, setDate] = useState('')
  const [type, setType] = useState('GOVT')
  const [editHoliday, setEditHoliday] = useState()

  const [editModal, setEditModal] = useState(false)
  const [editHolidayData, setEditHolidayData] = useState({})
  const [editDate, setEditDate] = useState({})
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const pageSize = 10
  const dispatch = useDispatch()
  const selector = useSelector(state => state)

  const [HolidayData, setHolidayData] = useState()
  const [events, setEvents] = useState([])

  useEffect(() => {
    if (selector?.Holidays?.holidaysList?.data?.total) {
      setTotalPages(
        Math.ceil(selector.Holidays.holidaysList.data.total / pageSize)
      )
    }

    // Ensure to use proper variable naming convention (camelCase)
    const holidayData = selector?.Holidays?.holidaysList?.data?.list
    setHolidayData(holidayData)
    if (Array.isArray(holidayData) && holidayData.length > 0) {
      const dataNew = holidayData.map(ele => ({
        title: ele.name,
        start: new Date(ele.calendarId.date),
        end: moment(ele.calendarId.date).endOf('day').toDate(),
        allDay: true
      }))
      setEvents(dataNew)
    } else {
      setEvents([])
    }
  }, [selector, pageSize])

  const onChangeRange = dates => {}
  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1)
  }

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1)
  }

  //Delete Holiday Function------------------------>
  const holidayDelete = (id, date) => {
    let dataRes = moment(date).startOf('month').valueOf()
    let holidaysData = {
      keyWord: '',
      fromDate: '',
      toDate: '',
      sortOrder: 'asc',
      sortBy: 'createdAt',
      pageNo: currentPage,
      size: pageSize,
      currentDate: dataRes
    }
    let DeleteHolidaysId = {
      id: id
    }
    dispatch(holidaysAction.holidaysDelete(DeleteHolidaysId, holidaysData))
    newFunction()
  }
  //Create Holiday Function----------------------->
  const openModal = () => {
    setModal(true)
  }
  const submitHoliday = async () => {
    let dataRes = moment(date).startOf('month').valueOf()
    let holidaysData = {
      keyWord: '',
      fromDate: '',
      toDate: '',
      sortOrder: 'asc',
      sortBy: 'createdAt',
      pageNo: currentPage,
      size: pageSize,
      currentDate: dataRes
    }
    let holidayCreateData = {
      name: name,
      date: date,
      type: type
    }
    await dispatch(
      holidaysAction.holidayCreate(holidayCreateData, holidaysData)
    )
    setModal(false)
    setDate('')
    setName('')
    setType('GOVT')
  }
  //Update Holiday Function---------------------->
  const handledateUpdate = e => {
    setEditDate(e.target.value)
  }
  const handleUpdate = event => {
    setEditHolidayData({ ...editHolidayData, name: event.target.value })
    setEditHoliday({ ...editHoliday, date: event.target.value })
  }
  const submitEditHoliday = () => {
    let dataRes = moment().startOf('month').valueOf()
    let holidaysData = {
      keyWord: '',
      fromDate: '',
      toDate: '',
      sortOrder: 'asc',
      sortBy: 'createdAt',
      pageNo: currentPage,
      size: pageSize,
      currentDate: dataRes
    }
    let data = {
      id: editHolidayData._id,
      name: editHolidayData.name,
      date: editDate
    }
    dispatch(holidaysAction.holidayUpdate(data, holidaysData))
    setEditModal(false)
  }

  const handleSelectSlot = slotInfo => {
    openModal()
    setDate(moment(slotInfo.start).format('YYYY-MM-DD'))
  }

  return (
    <>
      <Loader loading={selector?.Holidays?.loading} />

      <MyCalendar
        handleNavigate={handleNavigate}
        events={events}
        handleSelectSlot={data => handleSelectSlot(data)}
        onChangeRange={onChangeRange}
        dateStampNew={dateStampNew}
      />

      <section className='lg:block hidden'>
        <div className='mt-5 border border-[#D3D3D3] rounded-xl bg-white'>
          <table className='min-w-full table-autorounded-xl overflow-x-auto'>
            <thead className='border-b border-[#D1D1D1]'>
              <tr className='text-black'>
                <th className='px-6 py-3 whitespace-nowrap text-sm'>Date</th>
                <th className='px-6 py-3 whitespace-nowrap text-sm'>
                  Holiday Name
                </th>
                <th className='px-6 py-3 whitespace-nowrap text-sm'>
                  Holiday Type
                </th>
                <th className='px-6 py-3 whitespace-nowrap text-sm'>Action</th>
              </tr>
            </thead>

            <tbody className='divide-y-2  divide-gray-200'>
              {HolidayData && HolidayData.length > 0 ? (
                HolidayData?.map((holidays, id) => {
                  return (
                    <tr key={id} className='text-start '>
                      <td className='px-4 py-4 text-sm text-gray-900 text-center'>
                        {holidays.calendarId.DMY}
                      </td>
                      <td className='px-4 py-4 text-base font-semibold text-[#CD0C5C] text-center'>
                        {holidays?.name}
                      </td>
                      <td className='px-4 py-4 text-sm text-gray-900 text-center'>
                        <span className='hover:text-[#CD0C5C] text-center flex items-center justify-center text-base font-semibold capitalize'>
                          <GoDotFill size={20} color='#C60E6B' />
                          {holidays.type.toLowerCase()} Holiday
                        </span>
                      </td>
                      <td
                        className='px-4 py-4 text-sm text-gray-900 text-center'
                        onClick={() =>
                          holidayDelete(holidays._id, holidays.calendarId.date)
                        }
                      >
                        <span className='flex justify-center items-center'>
                          <RiDeleteBinLine size={24} color='#C60E6B' />
                        </span>
                      </td>
                    </tr>
                  )
                })
              ) : (
                <tr className='text-center forfontsmall'>
                  <td colSpan='3'>Data Not Available</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </section>
      <section className='space-y-5 lg:hidden block'>
        {HolidayData && HolidayData.length > 0 ? (
          HolidayData?.map((holidays, id) => (
            <div
              key={id}
              className=' bg-white p-4 rounded-[10px] border border-[#023565]'
            >
              <div className='flex justify-between p-2 w-full'>
                <div className='w-full'>
                  <p className='text-[14px] font-[600] text-left'>Date</p>
                </div>
                <div className='w-full'>
                  <p className='text-[14px] font-[400]'>
                    {holidays.calendarId.DMY}
                  </p>
                </div>
              </div>
              <div className='flex justify-between p-2 w-full'>
                <div className='w-full'>
                  <p className='text-[14px] font-[600] text-left'>
                    Holiday Name
                  </p>
                </div>
                <div className='w-full'>
                  <p className='text-[14px] font-[400]'>{holidays?.name}</p>
                </div>
              </div>
              <div className='flex justify-between p-2 w-full'>
                <div className='w-full'>
                  <p className='text-[14px] font-[600] text-left'>
                    Holiday Type
                  </p>
                </div>
                <div className='w-full'>
                  <p className='text-[14px] font-[400]'>
                    <span className='hover:text-[#CD0C5C] flex items-center capitalize'>
                      <GoDotFill size={20} color='#C60E6B' />
                      {holidays.type.toLowerCase()} Holiday
                    </span>
                  </p>
                </div>
              </div>
              <div className='flex justify-between p-2 w-full'>
                <div className='w-full'>
                  <p className='text-[14px] font-[600] text-left'>Action</p>
                </div>
                <div className='w-full'>
                  <p className='text-[14px] font-[400]'>
                    <RiDeleteBinLine size={24} color='#C60E6B' />
                  </p>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className=' bg-white p-4 rounded-[10px]'>
            <p className='text-center'>Data Not Found</p>
          </div>
        )}
      </section>

      {totalPages > 1 && (
        <div className='flex justify-end items-center py-2 '>
          <button
            className={`px-4 py-2 border text-white bg-[#023565] rounded ${
              currentPage === 1 ? 'opacity-50 cursor-not-allowed' : ''
            }`}
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
          >
            <FaChevronLeft />
          </button>
          <span className='text-gray-700 mx-1'>
            <span className='border px-4 py-2 text-black  rounded'>
              {currentPage}
            </span>{' '}
            of{' '}
            <span className='border px-4 py-2 text-black  rounded'>
              {totalPages}
            </span>
          </span>
          <button
            className={`px-4 py-2 border text-white bg-[#023565] rounded ${
              currentPage === totalPages ? 'opacity-50 cursor-not-allowed' : ''
            }`}
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            <FaChevronRight />
          </button>
        </div>
      )}

      {modal && (
        <>
          <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40'>
            <div className='relative w-11/12 mx-auto lg:w-[600px]'>
              <button
                className='z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center'
                onClick={() => {
                  setModal(false)
                  setDate('')
                  setName('')
                }}
              >
                <span>
                  <MdOutlineClose />
                </span>{' '}
                Close
              </button>
              <div className='form_class border-0 rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
                <div className='flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t'>
                  <h2 className='text-lg font-semibold'>Create Holiday </h2>
                </div>
                <div className='relative h-[360px] overflow-y-auto'>
                  <form onSubmit={submitHoliday} className='space-y-2 p-4'>
                    <div className='mb-4'>
                      <label
                        htmlFor='holidayName'
                        className='block mb-2 text-sm font-medium text-gray-900'
                      >
                        Holiday Name
                      </label>
                      <input
                        type='text'
                        id='holidayName'
                        value={name}
                        onChange={e => setName(e.target.value)}
                        className='mt-1 p-2 border block w-full border-gray-300 rounded-md'
                        placeholder='Enter holiday name...'
                        required
                      />
                    </div>
                    <div className='mb-4'>
                      <label
                        htmlFor='holidayDate'
                        className='block mb-2 text-sm font-medium text-gray-900'
                      >
                        Holiday Date
                      </label>
                      <input
                        type='date'
                        id='holidayDate'
                        value={date}
                        onChange={e => setDate(e.target.value)}
                        className='mt-1 p-2 border block w-full border-gray-300 rounded-md'
                        required
                      />
                    </div>
                    <div className='mb-4'>
                      <label
                        htmlFor='holidayDate'
                        className='block mb-2 text-sm font-medium text-gray-900'
                      >
                        Holiday Type
                      </label>
                      <select
                        id='countries'
                        className='mt-1 p-2 border block w-full border-gray-300 rounded-md bg-white'
                        onChange={e => setType(e.target.value)}
                      >
                        <option selected value='GOVT'>
                          Government Holiday
                        </option>
                        <option value='CUSTOM'>Customized Holiday</option>
                      </select>
                    </div>
                    <div className='flex justify-center items-center pt-4'>
                      <button
                        type='submit'
                        className='w-60 mx-auto bg-[#023565] text-white font-semibold py-2 px-4 rounded-full '
                      >
                        Create
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {editModal && (
        <div className='fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50'>
          <div className='bg-white p-8 rounded-lg'>
            <div className=' flex justify-end'>
              <button
                className='text-white my-2 flex justify-end bg-red-700 hover:bg-blue-800 focus:ring-4 
                  focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 
                  text-center dark:bg-red-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800'
                onClick={() => setEditModal(false)}
              >
                Close
              </button>
            </div>

            <br></br>
            <form className='my-3'>
              <input
                type='text'
                placeholder='Holiday Name'
                className='border-2 border-gray-700 rounded-md text-gray-800'
                name='name'
                value={editHolidayData.name}
                onChange={handleUpdate}
              />{' '}
              <br></br>
              <input
                name='name'
                type='date'
                value={editDate.date}
                onChange={handledateUpdate}
              />
            </form>
            <div className='flex justify-center'>
              <button
                className='text-white my-3 bg-blue-700 hover:bg-blue-800 focus:ring-4 
                  focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 
                  text-center dark:bg-blue-600 dark:hover:bg-blue-700
                   dark:focus:ring-blue-800'
                onClick={() => submitEditHoliday()}
              >
                Update
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Holidays
