/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { parentAction, tutionAction, userActions, userListAction } from '../../../_actions';
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaAngleRight, FaChevronCircleRight, FaChevronLeft, FaEye } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { FaChevronRight } from "react-icons/fa";
import { useRef } from 'react';
import Loader from '../../../components/Loader';
import moment from 'moment';
import StudentDetail from './Models/StudentDetail';
import ImagePreviewModal from '../../Tutor-Profile/Model/ImagePreview';
import UpdateNoteModel from './Models/UpdateNoteModel';
import UpdateTuitionModel from './Models/UpdateTuitionModel';
import ViewParent from './Models/ViewParent';
import { teachModeViewData, truncateText } from '../../../_helpers';
import TuitionFilters from './TuitionFilters';
import ViewMoreTuitionModel from './Models/ViewMoreTuitionModel';


const tableHead = ["Parent Name", "Student", "Address", "City", "tuition Fee", "Reference", "Tutor's Fee", "Company", "Hold On", "Mode", "Note", " Action"]
const ButtonSixTabel = ({ searchText, headerCity }) => {
  const dispatch = useDispatch();
  // const navigate=useNavigate()
  const selector = useSelector(state => state)
  const [open, setOpen] = useState(false);
  const [onHoldData, setOnHoldData] = useState();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const pageSize = 10;
  const [rejectResionModal, setRejectResionModal] = useState(false)
  const [rejectResion, setRejectResion] = useState('')
  const [rejectData, setRejectData] = useState({})
  const menuRef = useRef(null)
  const mobileRef = useRef(null)
  const [filters, setFilters] = useState({
    tuitionFee: "",
    tutorFee: "",
    preferredTeachMode: "",
    tutorId: "",
    classId: "",
    subjectId: "",
    classReference: "",
    parentId: ""
  })
  const [isViewMoreModel, setIsViewMoreModel] = useState(false)
  const tuitionId = ""

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target) && mobileRef.current && !mobileRef.current.contains(event.target)) {
      setOpen(null);
    }
  };


  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    let tutionDetails = {
      "status": [12],
      keyWord: searchText,
      cityId: headerCity ? [headerCity] : "", "fromDate": "",
      "toDate": "",
      "sortOrder": "desc",
      "sortBy": "createdAt",
      pageNo: currentPage,
      size: pageSize,
      ...filters,
      tutorId: (filters.tutorId !== "") ? [filters.tutorId] : [],
      parentId: (filters.parentId !== "") ? [filters.parentId] : [],

    }
    dispatch(tutionAction.getDemoClassListStatus(tutionDetails, tutionDetails.cityId))
  }, [currentPage, filters, searchText, headerCity])

  // useEffect(()=>{
  //     setOnHoldData=(()=>selector?.tution?.demoList?.data?.list)
  // },[selector])


  useEffect(() => {
    if (selector?.tution?.demoList?.data?.total) {
      setTotalPages(Math.ceil(selector?.tution?.demoList?.data?.total / pageSize));
    }

    setOnHoldData(() => selector?.tution?.demoList?.data?.list)
  }, [selector]);

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const openAction = (id) => {
    setOpen(id === open ? null : id);
  };

  const assignTutor = () => {
  };
  ///view and update=================>

  ///handle remove hold===============>
  const handleRemoveHold = (data) => {
    let demoData = {
      "status": [12],
      keyWord: searchText,
      cityId: headerCity ? [headerCity] : "", "fromDate": "",
      "toDate": "",
      "sortOrder": "",
      "sortBy": "",
      pageNo: currentPage,
      size: pageSize,
      ...filters,
      tutorId: (filters.tutorId !== "") ? [filters.tutorId] : [],
      parentId: (filters.parentId !== "") ? [filters.parentId] : [],

    }
    let demoIddetails = {
      tuitionId: data._id
    }
    dispatch(tutionAction.tuitionRemoveHoldByAdmin(demoIddetails, demoData))
    setOpen(false)
    const alertBox1 = document.getElementById("alert-box1");
    alertBox1.style.display = "block";
    setTimeout(() => {
      alertBox1.style.display = "none";
    }, 1000);
  }
  /// handle reject==================>




  const handleReject = (data) => {
    setRejectResionModal(true)
    let acceptedData = {
      status: "REJECTED",
      demoId: data._id,
    };
    setRejectData(acceptedData)
  }

  const [remarkModal, setRemarkModal] = useState(false)
  const [remarkId, setRemarkId] = useState('')
  const [remark, setRemark] = useState('')

  const addRemark = (data) => {
    let remarkId = data?._id
    setRemarkId(remarkId)
    setRemarkModal(true)
    setRemark(data?.reason ?? 'N/A')

  }

  const handleRemarkSubmit = (e) => {
    e.preventDefault()
    let demoData = {
      status: [12],
      // leadCreate: 'CREATED_SELF',
      keyWord: searchText,
      cityId: headerCity ? [headerCity] : "", fromDate: "",
      toDate: "",
      sortOrder: "",
      sortBy: "",
      pageNo: currentPage,
      size: pageSize,
      ...filters,
      tutorId: (filters.tutorId !== "") ? [filters.tutorId] : [],
      parentId: (filters.parentId !== "") ? [filters.parentId] : [],

    };
    let remarkData =
    {
      "demoId": remarkId,
      remark: remark ? remark : 'N/A',

    }
    let resStatus = 'Hold'
    dispatch(tutionAction.addUpdateRemark(remarkData, demoData, resStatus))
    setRemarkModal(false)
    setRemark('')
  }

  const remarkClose = () => {
    setRemark('')
    setRemarkModal(false)
  }
  const handleRejectResion = (e) => {
    e.preventDefault()

    let demoData = {
      "status": [12],
      keyWord: searchText,
      cityId: headerCity ? [headerCity] : "", "fromDate": "",
      "toDate": "",
      "sortOrder": "",
      "sortBy": "",
      pageNo: currentPage,
      size: pageSize,
      ...filters,
      tutorId: (filters.tutorId !== "") ? [filters.tutorId] : [],
      parentId: (filters.parentId !== "") ? [filters.parentId] : [],

    };

    let data = {
      ...rejectData,
      remark: rejectResion
    }

    dispatch(
      tutionAction.updateStatusDemoApproveOrReject(data, demoData)
    );
    setRejectResionModal(false)
    setRejectResion('')
    setOpen(false)
    const alertBox = document.getElementById("alert-box");
    alertBox.style.display = "block";
    setTimeout(() => {
      alertBox.style.display = "none";
    }, 1000);

  }




  const handlerejctCloseModal = () => {
    setRejectResionModal(false)
    setRejectResion('')
  }

  useEffect(() => {
    let details = {
      "cityId": "",
      "userType": "TUTOR",
      "isDisable": false
    }
    dispatch(userListAction.getUserTypeList(details))
  }, [])




  const [viewStudentModal, setViewStudentModal] = useState(false);
  const [studentListdata, setStudentListData] = useState();
  const viewStudent = (data, demoId) => {
    let studentListDetails = {
      parentId: data._id,
      demoId: demoId
    };
    parentAction.getStudentListByParentIdAndDemoIdPromise(studentListDetails).then((resData) => {
      setViewStudentModal(true);
      setStudentListData(resData.data)
    }).catch(() => {
    });
  };


  const [isModalVisible, setIsModalVisible] = useState(false);
  const [image, setImage] = useState("")
  const handleCloseFunctionImageModel = (data) => {
    if (data) {
      setImage(() => data)
    }
    else {
      setImage("")
    }
    setIsModalVisible(!isModalVisible);
  };

  const [fullDetails, setFullDetails] = useState(false);
  const [DetailsData, setFullDetailsData] = useState();
  const [tuitionDetails, setTuitionDetails] = useState({})
  const handleFullDetails = (data) => {
    userActions.getUserByPromiseId({ id: data.parentId._id }).then((res) => {
      setFullDetails(true);
      setFullDetailsData(res?.data);
      setTuitionDetails({
        classDuration: data?.classDuration,
        tuitionFee: data?.tuitionFee,
        numberOfClasses: data?.numberOfClasses,
        tutorFee: data?.tutorFee,
        tuitionType: data?.tuitionType,
        location: data?.location

      })
    })
  };


  const [updateDemoModel, setUpdateDemoModel] = useState(false)
  const [inputChangeField, setInputChangeField] = useState({})
  const [allStudentList, setAllStudentData] = useState([])
  function updateDemoModelFunction(data) {
    tutionAction.getDemoByIdPromise({ demoId: data._id }).then((res) => {
      setUpdateDemoModel(true)
      let bookFreeDemo = [...res.data.bookFreeDemo]
      bookFreeDemo = bookFreeDemo.map(free => ({ ...free, studentId: free.studentId.studentFullName }))

      setInputChangeField({
        ...res.data,
        bookFreeDemo: bookFreeDemo
      })
    })

    let studentListDetails = {
      parentId: data.parentId._id,
    };
    parentAction.getStudentListByParentIdForDemoPromise(studentListDetails).then((resData) => {
      setAllStudentData(resData.data)
    }).catch(() => {
    });
  }

  return (
    <>
      <div className='  rounded-lg'>
        <Loader loading={selector?.tution?.loading} />

        <TuitionFilters filters={filters} setFilters={setFilters} />

        <div className="overflow-auto  py-6">
          <div className="bg-white border lg:block hidden border-[#D3D3D3] rounded-lg overflow-x-auto">
            <table className="min-w-full table-auto divide-y divide-gray-200 ">
              <thead className="table-auto">
                <tr className='border-b  capitalize'>
                  {tableHead?.map(ele => {
                    return (<th className="px-6 py-3 whitespace-nowrap text-sm text-black">
                      {ele}
                    </th>)
                  })}
                </tr>
              </thead>
              <tbody>
                {onHoldData && onHoldData.length > 0 ? (onHoldData?.map((item, index) => (
                  <tr key={index} className="capitalize border-b">
                    {/* <td className="px-4 py-4 text-sm text-gray-900 text-center">{index + 1}</td> */}
                    <td className="px-4 py-4 flex items-center gap-4 text-sm text-gray-900">
                      <div className="flex items-center justify-center gap-2">
                        <span onClick={item?.parentId?.image ? () => handleCloseFunctionImageModel(item?.parentId?.image) : null}>
                          {item?.parentId && item?.parentId?.image ?
                            <img
                              alt=""
                              src={
                                item?.parentId && item?.parentId?.image
                                  ? item?.parentId?.image
                                  : "https://thumbs.dreamstime.com/z/vector-illustration-avatar-dummy-logo-collection-image-icon-stock-isolated-object-set-symbol-web-137160339.jpg?ct=jpeg"
                              }
                              onError={(e) => {
                                e.target.src =
                                  "https://thumbs.dreamstime.com/z/vector-illustration-avatar-dummy-logo-collection-image-icon-stock-isolated-object-set-symbol-web-137160339.jpg?ct=jpeg";
                              }}
                              className="rounded-full my-3 max-w-[50px] max-h-[50px] min-w-[50px] min-h-[50px]"
                            />
                            : <div className="bg-gray-300 rounded-full h-14 w-14 flex justify-center items-center">
                              <span className="text-base font-semibold text-[#033565]"> {item?.parentId?.name ? (item.parentId.name.split(' ').map(name => name.charAt(0)).join('')) : ('')}</span>
                            </div>}
                        </span>
                        <div className="w-24">
                          <p className="font-semibold whitespace-nowrap text-[#023565]">
                            {item && item?.parentId?.name ? item?.parentId?.name : "No Data"}
                          </p>
                          <p className="text-gray-500 whitespace-nowrap">
                            {item && item?.parentId?.mobNo ? item?.parentId?.mobNo : null}
                          </p>
                          <p className="text-gray-500 whitespace-nowrap">
                            <small>{item && item.statusDate ? item.statusDate : null}</small>
                          </p>
                          <div className="flex items-center  gap-1 cursor-pointer" onClick={() => handleFullDetails(item)} >
                            <p className="text-base font-normal cursor-pointer">More</p>
                            <FaChevronCircleRight
                              className="text-[#023565] text-base cursor-pointer" />
                          </div>
                        </div>
                      </div>
                    </td>

                    <td className="px-4 py-4 text-sm">
                      <span className="flex items-center justify-center"><FaEye size={24} className="cursor-pointer text-[#023565] hover:text-[#E4006F]" onClick={() => viewStudent(item?.parentId, item._id)} /></span>
                    </td>

                    <td className="px-4 py-4 text-sm text-gray-900 text-center cursor-pointer" title={item && item.address}>{item && item.address ? truncateText(item.address, 12) : null}</td>
                    <td className="px-4 py-4 text-sm text-gray-900 text-center">{item && item?.cityId?.name ? item?.cityId?.name : 'N/A'}</td>
                    <td className="px-4 py-4 text-sm text-gray-900 text-center">{item && item.tuitionFee ? item.tuitionFee : 0}</td>
                    <td className="px-4 py-4 text-sm text-gray-900 text-center">{item && item.referenceFee ? item.referenceFee : 0}</td>
                    <td className="px-4 py-4 text-sm text-gray-900 text-center">{item && item?.tutorFee ? item?.tutorFee : 0}</td>
                    <td className="px-4 py-4 text-sm text-gray-900 text-center">{item && item.companyShare ? item.companyShare : 0}</td>
                    <td className='px-4 py-4 text-sm text-gray-900 text-center'>
                      {item && item.holdDate ? moment(item.holdDate).format('DD-MMM-YYYY') : '-'}
                    </td>
                    <td className="px-4 py-4 text-sm text-gray-900 text-center">
                      {teachModeViewData(item?.preferredTeachMode)}
                    </td>

                    <td className={`px-4 py-4 text-sm text-center cursor-pointer font-semibold ${item.remark ? 'text-[#00AFE2]' : 'text-[#023565]'}`} onClick={() => addRemark(item)}>
                      <span className='cursor-pointer'> {item?.reason ? 'View' : 'Add'}</span>
                    </td>

                    <td className="px-4 py-4 text-sm">
                      <span className="flex items-center justify-center"><BsThreeDotsVertical size={24} className="cursor-pointer text-[#023565] hover:text-[#E4006F]" onClick={() => openAction(item._id)} /></span>

                      {open === item._id && (
                        <ul className="bg-white text-[#023565] border rounded-lg  w-auto fixed  
                      right-5 text-start p-4 text-sm font-bold" ref={menuRef}>
                          <li className=" border-b py-1 flex justify-between gap-5 place-items-center hover:text-[#E4006F]  " >
                            <button onClick={() => updateDemoModelFunction(item)}>View & Update</button>
                            <FaAngleRight className="hover:text-[#C60E6B]" />
                          </li>
                          <li className="text-[#023565] border-b py-1 flex justify-between place-items-center hover:text-[#E4006F] " onClick={assignTutor}>
                            <button onClick={() => handleRemoveHold(item)} >
                              Remove Hold
                            </button>
                            <FaAngleRight className="hover:text-[#C60E6B]" />
                          </li>
                          <li className="text-[#023565] flex justify-between place-items-center -b py-1 hover:text-[#E4006F] " onClick={assignTutor}>
                            <button onClick={() => handleReject(item)}>
                              Reject
                            </button>
                            <FaAngleRight className="hover:text-[#C60E6B]" />
                          </li>
                        </ul>
                      )}
                    </td>
                  </tr>
                ))
                ) : (
                  <tr>
                    <td colSpan={12} className="px-6 py-4 font-[400] text-base text-[#313131] whitespace-nowrap text-center">Data Not Available</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>


<section className='space-y-5 lg:hidden block py-4'>
          {onHoldData && onHoldData.length > 0 ? (
            onHoldData?.map((item, index) => (
              <div key={index} className=' bg-white p-4 rounded-[10px]'>
                <>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[14px] font-[600] text-left'>
                        Parent Name
                      </p>
                    </div>
                    <div className='w-full flex items-start'>
                      <div className='flex justify-start  gap-2 w-[95%]'>
                        <div
                          onClick={
                            item?.parentId?.image
                              ? () =>
                                  handleCloseFunctionImageModel(
                                    item?.parentId?.image
                                  )
                              : null
                          }
                        >
                          {item?.parentId && item?.parentId?.image ? (
                            <img
                              alt=''
                              src={
                                item?.parentId && item?.parentId?.image
                                  ? item?.parentId?.image
                                  : ''
                              }
                              className='rounded  max-w-[50px] max-h-[50px] min-w-[50px] min-h-[50px]'
                            />
                          ) : (
                            <div className='bg-gray-300 rounded h-14 w-14 flex justify-center items-center'>
                              <span className='text-base font-semibold text-[#033565]'>
                                {' '}
                                {item?.parentId?.name
                                  ? item.parentId.name
                                      .split(' ')
                                      .map(name => name.charAt(0))
                                      .join('')
                                  : ''}
                              </span>
                            </div>
                          )}
                        </div>
                        <div className=''>
                          <p className='text-[12px] font-semibold whitespace-nowrap text-[#023565]'>
                            {/* {item?.parentId?.name ?? 'Not Available'} */}
                            {truncateText(item?.parentId?.name, 10) ?? 'N/A'}
                          </p>
                          <p className='text-[12px] font-medium text-gray-500 whitespace-nowrap'>
                            {item?.parentId?.mobNo ?? 'Not Available'}
                          </p>
                          <div className='flex items-center  gap-3 cursor-pointer'>
                            <div
                              className='flex items-center  gap-1'
                              onClick={() => handleFullDetails(item)}
                            >
                              <p className='text-[12px] font-medium cursor-pointer'>
                                More
                              </p>
                              <FaChevronCircleRight className='text-[#023565] text-base cursor-pointer' />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='w-[5%] relative'>
                        <BsThreeDotsVertical
                          onClick={() => openAction(item._id)}
                          className='text-2xl text-[#C60E6B]'
                        />
                        {open === item._id && (
                          <div
                            className='bg-white absolute w-64 right-2 space-y-2 divide-y border rounded-md text-[#023565] py-2 px-2 text-start text-sm font-semibold'
                            ref={mobileRef}
                          >
                            <div
                              className='flex justify-between place-items-center hover:text-[#E4006F] hover:bg-gray-100 cursor-pointer'
                              onClick={() => updateDemoModelFunction(item)}
                            >
                              <button>
                                View & Update
                              </button>
                              <FaAngleRight className='hover:text-[#C60E6B]' />
                            </div>
                            <div
                              className='flex justify-between place-items-center hover:text-[#E4006F] hover:bg-gray-100 cursor-pointer'
                            >
                               <button onClick={() => handleRemoveHold(item)} >
                              Remove Hold
                            </button>
                              <FaAngleRight className='hover:text-[#C60E6B]' />
                            </div>
                            <div
                              className='flex justify-between place-items-center hover:text-[#E4006F] hover:bg-gray-100 cursor-pointer'
                            >
                               <button onClick={() => handleReject(item)}>
                              Reject
                            </button>
                              <FaAngleRight className='hover:text-[#C60E6B]' />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[16px] font-[700] text-left'>
                        Student
                      </p>
                    </div>
                    <div className='w-full'>
                      <p
                        className='text-[14px] font-[700] text-[#023565]'
                        onClick={() => viewStudent(item?.parentId, item._id)}
                      >
                        View
                      </p>
                    </div>
                  </div>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[16px] font-[700] text-left'>
                        Address
                      </p>
                    </div>
                    <div className='w-full'>
                      <p className='text-[14px] font-[400]'>
                        {' '}
                        {item && item?.address ? item?.address : 'N/A'}
                      </p>
                    </div>
                  </div>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[16px] font-[700] text-left'>City</p>
                    </div>
                    <div className='w-full'>
                      <p className='text-[14px] font-[400]'>
                        {' '}
                        {item && item?.cityId?.name
                          ? item?.cityId?.name
                          : 'N/A'}
                      </p>
                    </div>
                  </div>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[16px] font-[700] text-left'>
                        Tuition Fee
                      </p>
                    </div>
                    <div className='w-full'>
                      <p className='text-[14px] font-[400]'>
                        {' '}
                        {item.tuitionFee}{' '}
                      </p>
                    </div>
                  </div>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[16px] font-[700] text-left'>
                        Reference
                      </p>
                    </div>
                    <div className='w-full'>
                      <p className='text-[14px] font-[400]'>
                        {' '}
                        {item.referenceFee}
                      </p>
                    </div>
                  </div>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[16px] font-[700] text-left'>
                        Tutor Fee
                      </p>
                    </div>
                    <div className='w-full'>
                      <p className='text-[14px] font-[400]'>{item.tutorFee}</p>
                    </div>
                  </div>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[16px] font-[700] text-left'>
                        Company
                      </p>
                    </div>
                    <div className='w-full'>
                      <p className='text-[14px] font-[400]'>
                        {item.companyShare}
                      </p>
                    </div>
                  </div>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[16px] font-[700] text-left'>
                      Hold On
                      </p>
                    </div>
                    <div className='w-full'>
                      <p className='text-[14px] font-[400]'>
                      {item && item.holdDate ? moment(item.holdDate).format('DD-MMM-YYYY') : '-'}
                      </p>
                    </div>
                  </div>

                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[16px] font-[700] text-left'>
                        Tuition Mode
                      </p>
                    </div>
                    <div className='w-full'>
                      <p className='text-[14px] font-[400]'>
                        {teachModeViewData(item?.preferredTeachMode)}
                      </p>
                    </div>
                  </div>
                  
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[16px] font-[700] text-left'>Note</p>
                    </div>
                    <div className='w-full'>
                      <p className='text-[14px] font-[400]'>
                        <td
                          className={`cursor-pointer font-semibold ${
                            item.remark ? 'text-[#00AFE2]' : 'text-[#023565]'
                          }`}
                          onClick={() => addRemark(item)}
                        >
                          <p> {item?.reason ? 'View' : 'Add'}</p>
                        </td>
                      </p>
                    </div>
                  </div>
                </>
              </div>
            ))
          ) : (
             <div  className=' bg-white p-4 rounded-[10px]'>
            <p className='text-center'>Data Not Found</p>
          </div>
          )}
        </section>
        </div>




        <ImagePreviewModal handleClose={handleCloseFunctionImageModel} showWarning={isModalVisible} image={image} />



        <UpdateNoteModel
          remarkModal={remarkModal}
          remarkClose={remarkClose}
          Heading={"Update Note"}
          remark={remark}
          setRemark={setRemark}
          handleRemarkSubmit={handleRemarkSubmit}
          btnName={`${remark !== "N/A" || remark ? "Update" : "Add"} `}
        />


        <UpdateTuitionModel
          handleBack={() => { setUpdateDemoModel(false); setInputChangeField({}) }}
          updateDemoModel={updateDemoModel}
          inputChangeField={inputChangeField}
          allStudentList={allStudentList}
          setInputChangeField={setInputChangeField}
          listPayload={{
            "status": [12],
            keyWord: searchText,
            cityId: headerCity ? [headerCity] : "", "fromDate": "",
            "toDate": "",
            "sortOrder": "",
            "sortBy": "",
            pageNo: currentPage,
            size: pageSize,
            ...filters,
            tutorId: (filters.tutorId !== "") ? [filters.tutorId] : []
          }}
        />

        <UpdateNoteModel
          remarkModal={rejectResionModal}
          remarkClose={handlerejctCloseModal}
          Heading={"Reject Reason"}
          remark={rejectResion}
          setRemark={setRejectResion}
          handleRemarkSubmit={handleRejectResion}
          btnName={`${rejectResion !== "N/A" || rejectResion ? "Edit" : "Add"} Reason`}
        />



        <ViewParent
          fullDetails={fullDetails}
          setFullDetails={setFullDetails}
          DetailsData={DetailsData}
          tuitionDetails={tuitionDetails}
        />
        <StudentDetail
          setViewStudentModal={setViewStudentModal} viewStudentModal={viewStudentModal} setStudentListData={setStudentListData} studentListdata={studentListdata}
        />
        <div id="alert-box" style={{ display: "none", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", backgroundColor: "yellow", padding: "10px", borderRadius: "10px" }}>
          Tuition Rejected!
        </div>

        <div id="alert-box1" style={{ display: "none", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", backgroundColor: "yellow", padding: "10px", borderRadius: "10px" }}>
          Tuition Remove Hold!
        </div>
        {totalPages > 1 && (
          <div className="flex justify-end items-center lg:py-2 pb-4">
            <button
              className={`px-4 py-2 border text-white bg-[#023565] rounded ${currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""}`}
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
            >
              <FaChevronLeft />
            </button>
            <span className="text-gray-700 mx-1">
              <span className="border px-4 py-2 text-black  rounded">{currentPage}</span> of <span className="border px-4 py-2 text-black  rounded">{totalPages}</span>
            </span>
            <button
              className={`px-4 py-2 border text-white bg-[#023565] rounded ${currentPage === totalPages ? "opacity-50 cursor-not-allowed" : ""}`}
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              <FaChevronRight />
            </button>
          </div>
        )}
      </div>

      {isViewMoreModel && <ViewMoreTuitionModel isViewMoreModel={isViewMoreModel} setIsViewMoreModel={setIsViewMoreModel} tuitionId={tuitionId} />}

    </>
  )

}

export default ButtonSixTabel;
