import React from 'react'
import { ImCross } from 'react-icons/im'
import { MdOutlineClose } from 'react-icons/md'

function ImageModel({ setImageModal, imgeData, imageModal }) {
    return (imageModal && (
        <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40">
                <div className="relative w-11/12 mx-auto lg:w-[600px]">
                    <button
                        className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center"
                        onClick={() => setImageModal(false)}
                    >
                        <span><MdOutlineClose /></span> Close
                    </button>
                    <div className="form_class border-0 rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        {/* <div className="flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                            <h2 className="text-lg font-semibold">Image </h2>
                        </div> */}
                        <div className="relative h-[360px] overflow-y-auto">
                            {Array.isArray(imgeData) && imgeData.length > 0 && imgeData.map((img, id) => (
                                <div key={id} className="bg-white rounded-md w-full max-w-4xl mx-auto border-2 p-4">
                                    <div className="flex justify-center items-center">
                                        <img
                                            className="w-[200px] h-[324px] object-contain"
                                            src={
                                                img?.image ??
                                                "https://thumbs.dreamstime.com/z/vector-illustration-avatar-dummy-logo-collection-image-icon-stock-isolated-object-set-symbol-web-137160339.jpg?ct=jpeg"
                                            }
                                            alt="Img"
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
    )
}

export default ImageModel