export const notificationConstant = {
    GET_ADMIN_NOTIFICATION_LIST_REQUEST: "GET_ADMIN_NOTIFICATION_LIST_REQUEST",
    GET_ADMIN_NOTIFICATION__LIST_SUCCESS: "GET_ADMIN_NOTIFICATION__LIST_SUCCESS",
    GET_ADMIN_NOTIFICATION__LIST_FAILURE: "GET_ADMIN_NOTIFICATION__LIST_FAILURE",

    CREATE_ADMIN_NOTIFICATION__LIST_REQUEST: "CREATE_ADMIN_NOTIFICATION__LIST_REQUEST",
    CREATE_ADMIN_NOTIFICATION__LIST_SUCCESS: "CREATE_ADMIN_NOTIFICATION__LIST_SUCCESS",
    CREATE_ADMIN_NOTIFICATION__LIST_FAILURE: "CREATE_ADMIN_NOTIFICATION__LIST_FAILURE",

    GET_NOTIFICATION_COUNT_REQUEST: "GET_NOTIFICATION_COUNT_REQUEST",
    GET_NOTIFICATION_COUNT_SUCCESS: "GET_NOTIFICATION_COUNT_SUCCESS",
    GET_NOTIFICATION_COUNT_FAILURE: "GET_NOTIFICATION_COUNT_FAILURE",

    ADMIN_NOTIFICATION_LIST_REQUEST: "ADMIN_NOTIFICATION_LIST_REQUEST",
    ADMIN_NOTIFICATION_LIST_SUCCESS: "ADMIN_NOTIFICATION_LIST_SUCCESS",
    ADMIN_NOTIFICATION_LIST_FAILURE: "ADMIN_NOTIFICATION_LIST_FAILURE",

    RED_NOTIFICATION_REQUEST: "RED_NOTIFICATION_REQUEST",
    RED_NOTIFICATION_SUCCESS: "RED_NOTIFICATION_SUCCESS",
    RED_NOTIFICATION_FAILURE: "RED_NOTIFICATION_FAILURE",
}