import { headerForPrivateAPI, APIcallFunction } from "../_helpers";
import { walletConstant } from "../_constants";

export const walletAction = {
  getWalletList,
  addBonusUserWallet,
  getWalletHistoryList,
  updateWalletStatus,
  updateWallet,
  updateWalletReward

}

function getWalletList(data) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "userWallet/getWalletList ",
  };

  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (user) => {

        dispatch(success(user));
      },
      (error) => {

        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: walletConstant.GET_WALLET_LIST_REQUEST };
  }
  function success(users) {
    return { type: walletConstant.GET_WALLET_LIST_SUCCESS, users };
  }
  function failure(error) {
    return { type: walletConstant.GET_WALLET_LIST_FAILURE, error };
  }
}

function addBonusUserWallet(data, walletDetails) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "userWallet/addBonusInUserWallet",
  };

  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (user) => {

        dispatch(success(user));
        dispatch(getWalletList(walletDetails));
      },
      (error) => {

        dispatch(failure(error));
        dispatch(success(getWalletList))
      }
    );
  };

  function request() {
    return { type: walletConstant.ADD_BONUS_USER_WALLET_REQUEST };
  }
  function success(users) {
    return { type: walletConstant.ADD_BONUS_USER_WALLET_SUCCESS, users };
  }
  function failure(error) {
    return { type: walletConstant.ADD_BONUS_USER_WALLET_FAILURE, error };
  }
}



function getWalletHistoryList(data) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "userWallet/getWalletHistoryList",
  };

  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (user) => {

        dispatch(success(user));
      },
      (error) => {

        dispatch(failure(error));
        dispatch(success(getWalletList))
      }
    );
  };

  function request() {
    return { type: walletConstant.GET_WALLET_HISTORY_LIST_REQUEST };
  }
  function success(users) {
    return { type: walletConstant.GET_WALLET_HISTORY_LIST_SUCCESS, users };
  }
  function failure(error) {
    return { type: walletConstant.GET_WALLET_HISTORY_LIST_FAILURE, error };
  }
}

function updateWalletStatus(data, walletDetails) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "userWallet/updateWalletStatus",
  };

  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (user) => {

        dispatch(success(user));
        dispatch(getWalletHistoryList(walletDetails))
      },
      (error) => {

        dispatch(failure(error));
        dispatch(success(getWalletList))
      }
    );
  };

  function request() {
    return { type: walletConstant.UPDATE_WALLET_STATUS_REQUEST };
  }
  function success(users) {
    return { type: walletConstant.UPDATE_WALLET_STATUS_SUCCESS, users };
  }
  function failure(error) {
    return { type: walletConstant.UPDATE_WALLET_STATUS_FAILURE, error };
  }
}


function updateWallet(data, walletDetails) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "userWallet/updateWalletBonus",
  };

  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (user) => {

        dispatch(success(user));
        dispatch(getWalletList(walletDetails))
      },
      (error) => {

        dispatch(failure(error));
        dispatch(success(getWalletList))
      }
    );
  };

  function request() {
    return { type: walletConstant.UPDATE_WALLET_REQUEST };
  }
  function success(users) {
    return { type: walletConstant.UPDATE_WALLET_SUCCESS, users };
  }
  function failure(error) {
    return { type: walletConstant.UPDATE_WALLET_FAILURE, error };
  }
}



function updateWalletReward(data, walletDetails) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "userWallet/updateWalletReward",
  };

  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (user) => {

        dispatch(success(user));
        dispatch(getWalletList(walletDetails))
      },
      (error) => {

        dispatch(failure(error));
        dispatch(success(getWalletList))
      }
    );
  };

  function request() {
    return { type: walletConstant.UPDATE_WALLET_REWARD_REQUEST };
  }
  function success(users) {
    return { type: walletConstant.UPDATE_WALLET_REWARD_SUCCESS, users };
  }
  function failure(error) {
    return { type: walletConstant.UPDATE_WALLET_REWARD_FAILURE, error };
  }
}