import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { feeAction } from "../../../_actions/fee.action";
import { useLocation } from "react-router-dom";
import { downloadCSV } from "../../../_helpers/auth-header"
import { IoArrowBack } from "react-icons/io5";
import FeeReferralHeader from './EarningHeader/FeeReferralHeader'
import moment from 'moment';
import { MdOutlineKeyboardArrowLeft } from 'react-icons/md';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import Loader from '../../../components/Loader';
import { alertActions } from '../../../_actions';

const ReferralPaid = () => {
  const dispatch = useDispatch();
  const selector = useSelector((state) => state);
  let referralPaidDataList = selector?.Fee?.referralPaidList?.data?.list
  const allReferralPaid = selector?.Fee?.allReferralPaid?.data
  const location = useLocation();
  const feeReferralData = location.state?.feeReferralData;
  let monthId = feeReferralData?.month
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const pageSize = 10;

  useEffect(() => {
    let referralPaidData = {
      "cityId": "",
      "month": monthId,
      "keyWord": "",
      "pageNo": currentPage,
      "size": pageSize
    }
    let referralPaidData1 = {
      "cityId": "",
      "month": monthId
    }
    dispatch(feeAction.getReferralPaidList(referralPaidData))
    dispatch(feeAction.getAllReferralPaid(referralPaidData1))
  }, [currentPage])

  const fields = ['Parent Name', 'Mobile Number', 'Student / Class', 'Due Date', 'Fee', 'Created At', 'Time'];
  const convertToCSV = (data) => {
    const array = typeof data !== 'object' ? JSON.parse(data) : data;
    let csv = fields.join(',') + '\r\n';

    array?.forEach(item => {
      const parentName = item.parentId ? item.parentId.name : '-';
      const mobNo = item.parentId ? item.parentId.mobNo : '-';
      const studentsClasses = item.demoClassId && item.demoClassId.bookFreeDemo ? item.demoClassId.bookFreeDemo.map(demo => {
        const subjects = demo.subjectId ? demo.subjectId.map(sub => sub.name).join(',') : '-';
        const className = demo.classId ? demo.classId.name : '-';
        return `${subjects} (${className})`;
      }).join(' | ') : 'N/A';

      const dueDate = item.demoClassId?.dueDate || '';
      const fee = item.fee || '';
      const createdAt = item.createdAt ? new Date(item.createdAt).toLocaleString() : '';

      const row = [parentName, mobNo, studentsClasses, dueDate, fee, createdAt].join(',');
      csv += row + '\r\n';
    });

    return csv;
  };

  const handleBack = () => {
    window.history.back();
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };
  useEffect(() => {
    if (selector?.Fee?.referralPaidList?.data?.total) {
      setTotalPages(Math.ceil(selector?.Fee?.referralPaidList?.data?.total / pageSize));
    }
  }, [selector]);

  return (
    <>
      <FeeReferralHeader />
      <section className='overflow-y-auto overflow-hidden'>
        <div className='lg:m-5 m-2'>
          <Loader loading={selector?.Fee?.loading} />

          <div className="flex justify-between">
            <div className="flex gap-2">
              <IoArrowBack
                className="text-[#023565] text-3xl border rounded-full border-black my-2 mx-3 cursor-pointer bg-white"
                onClick={handleBack}
              />
            </div>
            <button className="bg-white px-3 py-2 border flex justify-between rounded-lg" onClick={() => { !allReferralPaid.length ? dispatch(alertActions.error("No data to export.")) : downloadCSV(convertToCSV(allReferralPaid)) }}>
              <span>Export</span>  <img src="/export 1.png" alt='' />
            </button>
          </div>
          <div class="relative overflow-x-auto bg-white rounded-lg border border-[#D3D3D3] mt-4 lg:block hidden">
            <table class="w-full  text-left rtl:text-right bg-white text-[#313131]">
              <thead class=" ">
                <tr className="capitalize border-b">
                  <th scope="col" class="px-6 py-3 whitespace-nowrap">
                  Referred By
                  </th>
                  <th scope="col" class="px-6 py-3 whitespace-nowrap">
                  Referred To
                  </th>
                  <th scope="col" class="px-6 py-3 whitespace-nowrap">
                    City
                  </th>
                  <th scope="col" class="px-6 py-3 whitespace-nowrap">
                  Reward Credited
                  </th>
                  <th scope="col" class="px-6 py-4 whitespace-nowrap">
                    Type
                  </th>
                  <th scope="col" class="px-6 py-4 whitespace-nowrap">
                    Created at
                  </th>
                  <th scope="col" class="px-6 py-4 whitespace-nowrap">
                    Status
                  </th>
                </tr>
              </thead>
              <tbody>
                {referralPaidDataList && referralPaidDataList.length > 0 ?
                  referralPaidDataList?.map((item, id) => (
                    <tr class="bg-white  border-b dark:border-gray-700">
                      <th
                        scope="row"
                        class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                      >
                        <div className="flex gap-3 pla">
                          <div>
                            <img
                              src={
                                item?.refFrom
                                  ?.image ??
                                "https://thumbs.dreamstime.com/z/vector-illustration-avatar-dummy-logo-collection-image-icon-stock-isolated-object-set-symbol-web-137160339.jpg?ct=jpeg"
                              }
                              className="w-8 h-8 rounded-full"
                              alt=''
                            />
                          </div>
                          <div>
                            <p>{item?.refFrom
                              ?.name}</p>
                            <p>{item?.refFrom
                              ?.mobNo}</p>
                            <p>{item?.refFrom
                              ?.userType}</p>
                          </div>
                        </div>
                      </th>

                      <th
                        scope="row"
                        class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                      >
                        <div className="flex gap-3 pla">
                          <div>
                            <img
                              src={
                                item?.userId
                                  ?.image ??
                                "https://thumbs.dreamstime.com/z/vector-illustration-avatar-dummy-logo-collection-image-icon-stock-isolated-object-set-symbol-web-137160339.jpg?ct=jpeg"
                              }
                              className="w-8 h-8 rounded-full"
                              alt=''
                            />
                          </div>
                          <div>
                            <p>{item?.userId
                              ?.name}</p>
                            <p>{item?.userId
                              ?.mobNo}</p>
                            <p>{item?.refFrom
                              ?.userType}</p>
                          </div>
                        </div>
                      </th>
                      <td class="px-6 py-4 capitalize">
                        {item?.city?.name ?? '-'}
                      </td>
                      <td class="px-6 py-4">
                        {item?.balance ?? 0}
                      </td>
                      <td class="px-6 py-3">
                        {item?.type ?? '-'}
                      </td>
                      <td class="px-6 py-3">{item?.createdAt ? moment(item?.createdAt).format('YYYY-MM-DD') : null}</td>
                      <td class="px-6 py-3 text-green-700 font-semibold">
                        {item?.status === 0
                          ? " pending"
                          : item?.status === 1
                            ? " Received"
                            : item?.status === 2
                              ? " advance paid"
                              : "Unknown status"}
                      </td>
                    </tr>
                  )) :
                  <tr>
                    <td colSpan={7} className="px-6 py-4 font-[400] text-base text-[#313131] whitespace-nowrap text-center">Data Not Available</td>
                  </tr>}
              </tbody>
            </table>
          </div>

          <div className="lg:hidden block bg-white my-4 rounded-lg border border-[#023565]">
            {referralPaidDataList && referralPaidDataList.length > 0 ?
              referralPaidDataList?.map((item, id) => (
                <table key={id} className="w-full  bg-white rounded-[10px]">
                  <tr className="border-b border-gray-300">
                    <th className="text-left py-4 px-4">
                      <span className="font-[500]">User</span>
                    </th>
                    <td
                      class="px-4 py-4 font-medium text-gray-900"
                    >
                      <div className="flex items-center gap-3">
                        <div>
                          <img src={item?.refFrom?.image || "https://thumbs.dreamstime.com/z/vector-illustration-avatar-dummy-logo-collection-image-icon-stock-isolated-object-set-symbol-web-137160339.jpg?ct=jpeg"} alt='' className="w-8 h-8 lg:rounded-full rounded" />
                        </div>
                        <div>
                          <p className="text-[12px] text-left capitalize">{item?.refFrom?.name} </p>
                          <p className="text-[12px] text-left capitalize">{item?.refFrom?.mobNo}</p>
                          <p className="text-[12px] text-left capitalize">{item?.refFrom?.userType}</p>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr className="border-b border-gray-300">
                    <th className="text-left py-4 px-4">
                      <span className="font-[500]">Ref By User</span>
                    </th>
                    <td
                      class="px-4 py-4 font-medium text-gray-900"
                    >
                      <div className="flex items-center gap-3">
                        <div>
                          <img src={item?.userId?.image || "https://thumbs.dreamstime.com/z/vector-illustration-avatar-dummy-logo-collection-image-icon-stock-isolated-object-set-symbol-web-137160339.jpg?ct=jpeg"} alt='' className="w-8 h-8 lg:rounded-full rounded" />
                        </div>
                        <div>
                          <p className="text-[12px] text-left capitalize">{item?.userId?.name} </p>
                          <p className="text-[12px] text-left capitalize">{item?.userId?.mobNo}</p>
                          <p className="text-[12px] text-left capitalize">{item?.refFrom?.userType}</p>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr className="border-b border-gray-300">
                    <th className="text-left py-4 px-4">
                      <span className="font-[500]">City</span>
                    </th>
                    <td className="text-auto py-4  px-4 gap-2">
                      <p className="font-[400] text-[14px] capitalize">
                        {item?.city?.name ?? '-'}
                      </p>
                    </td>
                  </tr>
                  <tr className="border-b border-gray-300">
                    <th className="text-left py-4 px-4">
                      <span className="font-[500]">Reward Balance</span>
                    </th>
                    <td className="text-auto py-4  px-4 gap-2">
                      <p className="font-[400] text-[14px] capitalize">
                        {item?.balance ?? 0}
                      </p>
                    </td>
                  </tr>
                  <tr className="border-b border-gray-300">
                    <th className="text-left py-4 px-4">
                      <span className="font-[500]">Type</span>
                    </th>
                    <td className="text-auto py-4  px-4 gap-2">
                      <p className="font-[400] text-[14px] capitalize">
                        {item?.type ?? '-'}
                      </p>
                    </td>
                  </tr>
                  <tr className="border-b border-gray-300">
                    <th className="text-left py-4 px-4">
                      <span className="font-[500]">Created at</span>
                    </th>
                    <td className="text-auto py-4  px-4 gap-2">
                      <p className="font-[400] text-[14px] capitalize">
                        {item?.createdAt ? moment(item?.createdAt).format('YYYY-MM-DD') : null}
                      </p>
                    </td>
                  </tr>
                  <tr className="border-b border-gray-300">
                    <th className="text-left py-4 px-4">
                      <span className="font-[500]">Status</span>
                    </th>
                    <td className="text-auto py-4  px-4 gap-2">
                      <p className="font-[400] text-[14px] capitalize text-green-700">
                        {item?.status === 0
                          ? " pending"
                          : item?.status === 1
                            ? " Received"
                            : item?.status === 2
                              ? " advance paid"
                              : "Unknown status"}
                      </p>
                    </td>
                  </tr>

                </table>
              ))
              : <tr>
                <td colSpan={7} className="px-6 py-4 font-[400] text-base text-[#313131] whitespace-nowrap text-center">Data Not Available</td>
              </tr>
            }
          </div>

          <div>
            {totalPages > 1 && (
              <div className="flex justify-end items-center py-2 px-4">
                <button
                  className={`px-4 py-2 border text-white bg-[#023565] rounded ${currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""}`}
                  onClick={handlePreviousPage}
                  disabled={currentPage === 1}
                >
                  <FaChevronLeft />
                </button>
                <span className="text-gray-700 mx-1">
                  <span className="border px-4 py-2 text-black  rounded">{currentPage}</span> of <span className="border px-4 py-2 text-black  rounded">{totalPages}</span>
                </span>
                <button
                  className={`px-4 py-2 border text-white bg-[#023565] rounded ${currentPage === totalPages ? "opacity-50 cursor-not-allowed" : ""}`}
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                >
                  <FaChevronRight />
                </button>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  )
}

export default ReferralPaid
