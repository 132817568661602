import React from 'react'
import { MdOutlineClose } from 'react-icons/md'

function ViewUpdate({ setFullDetails, fullDetails, DetailsData }) {
    return fullDetails && (
        <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40">
                <div className="relative w-11/12 mx-auto lg:w-[600px]">
                    <button
                        className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center"
                        onClick={() => setFullDetails(false)}
                    >
                        <span><MdOutlineClose /></span> Close
                    </button>
                    <div className="form_class border-0 rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        <div className="flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                            <h2 className="text-lg font-semibold">Parents details</h2>
                        </div>
                        <div className="relative lg:h-[250px] h-[200px] overflow-y-auto">
                            <div className="space-y-1 p-4">
                                <div className="flex justify-between w-full">
                                    <div className="w-full">
                                        <p className="text-[14px] font-[700] text-left text-[#313131]">
                                            Parent Name{" "}
                                        </p>
                                    </div>
                                    <div className="w-full">
                                        <p className="text-[12px] font-[400] text-left text-[#313131]">{DetailsData?.name}</p>
                                    </div>
                                </div>

                                <div className="flex justify-between w-full">
                                    <div className="w-full">
                                        <p className="text-[14px] font-[700] text-left text-[#313131]">
                                            Mobile No   .{" "}
                                        </p>
                                    </div>
                                    <div className="w-full">
                                        <p className="text-[12px] font-[400] text-left text-[#313131] break-all">
                                            {DetailsData?.mobNo}
                                        </p>
                                    </div>
                                </div>
                                <div className="flex justify-between w-full">
                                    <div className="w-full">
                                        <p className="text-[14px] font-[700] text-left text-[#313131]">
                                            WhatsApp Number
                                        </p>
                                    </div>
                                    <div className="w-full">
                                        <p className="text-[12px] font-[400] text-left text-[#313131] break-all">
                                            {DetailsData?.whatsappNo}
                                        </p>
                                    </div>
                                </div>
                                <div className="flex justify-between w-full">
                                    <div className="w-full">
                                        <p className="text-[14px] font-[700] text-left text-[#313131]">
                                            Pincode{" "}
                                        </p>
                                    </div>
                                    <div className="w-full">
                                        <p className="text-[12px] font-[400] text-left text-[#313131] break-all">
                                            {DetailsData?.pinCode[0] || "N/A"}
                                        </p>
                                    </div>
                                </div>
                                <div className="flex justify-between w-full">
                                    <div className="w-full">
                                        <p className="text-[14px] font-[700] text-left text-[#313131]">
                                            Address{" "}
                                        </p>
                                    </div>
                                    <div className="w-full">
                                        <p className="text-[12px] font-[400] text-left text-[#313131] break-all">
                                            {DetailsData?.address}
                                        </p>
                                    </div>
                                </div>
                                <div className="flex justify-between w-full">
                                    <div className="w-full">
                                        <p className="text-[14px] font-[700] text-left text-[#313131]">
                                            Email{" "}
                                        </p>
                                    </div>
                                    <div className="w-full">
                                        <p className="text-[12px] font-[400] text-left text-[#313131] break-all">
                                            {DetailsData?.email}
                                        </p>
                                    </div>
                                </div>
                                <div className="flex justify-between w-full">
                                    <div className="w-full">
                                        <p className="text-[14px] font-[700] text-left text-[#313131]">
                                            City
                                        </p>
                                    </div>
                                    <div className="w-full">
                                        <p className="text-[12px] font-[400] text-left text-[#313131] break-all">
                                            {DetailsData?.city?.name}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ViewUpdate
