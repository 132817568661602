/* eslint-disable jsx-a11y/alt-text */
import React from 'react'

function SubItemCom({ subiconName }) {
    const subIconMap = {
        strem:"/stream.png" ,
        dollar:'/dollar.png',
        persent: '/presentation (2).png',
        earning:"/earning.png",
        calender:"/calendar1.png",
        wallet:"/wallet.png",
        tutorprofile:"/teacher.png",
        parents:"/teacher.png",
        Employ:"/check (1) (1).png",
        city:"/location.png"
        
    };

    const SubIconComponent = subIconMap[subiconName];

    if (!SubIconComponent) {
        return null;
    }

    return <img src={SubIconComponent}/>;
}

export default SubItemCom;
