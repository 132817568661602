export const SubjectConstants={
    GET_SUBJECT_LIST_REQUEST:"GET_SUBJECT_LIST_REQUEST",
    GET_SUBJECT_LIST_SUCCESS:"GET_SUBJECT_LIST_SUCCESS",
    GET_SUBJECT_LIST_FAILURE:"GET_SUBJECT_LIST_FAILURE",
    
    CREATE_SUBJECT_LIST_REQUEST:"CREATE_SUBJECT_LIST_REQUEST",
    CREATE_SUBJECT_LIST_SUCCESS:"CREATE_SUBJECT_LIST_SUCCESS",
    CREATE_SUBJECT_LIST_FAILURE:"CREATE_SUBJECT_LIST_FAILURE", 

    DELETE_SUBJECT_LIST_REQUEST:"DELETE_SUBJECT_LIST_REQUEST",
    DELETE_SUBJECT_LIST_SUCCESS:"DELETE_SUBJECT_LIST_SUCCESS",
    DELETE_SUBJECT_LIST_FAILURE:"DELETE_SUBJECT_LIST_FAILURE", 

    UPDATE_SUBJECT_LIST_REQUEST:"UPDATE_SUBJECT_LIST_REQUEST",
    UPDATE_SUBJECT_LIST_SUCCESS:"UPDATE_SUBJECT_LIST_SUCCESS",
    UPDATE_SUBJECT_LIST_FAILURE:"UPDATE_SUBJECT_LIST_FAILURE",

    ENA_DIS_SUBJECT_LIST_REQUEST:"ENA_DIS_SUBJECT_LIST_REQUEST",
    ENA_DIS_SUBJECT_LIST_SUCCESS:"ENA_DIS_SUBJECT_LIST_SUCCESS",
    ENA_DIS_SUBJECT_LIST_FAILURE:"ENA_DIS_SUBJECT_LIST_FAILURE",

} 