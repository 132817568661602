import { APIcallFunction, headerForPrivateAPI } from "../_helpers"
import { tickectConstants } from "../_constants"
import { alertActions } from "./alert.actions";

export const ticktAction = {
    ticketGet,
    ticketReply,
    getMsgListTicketById,
    resovleTicket
}


function ticketGet(data) {

    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: "ticket/getTicketList"
    };
    return (dispatch) => {
        dispatch(request(data));
        APIcallFunction(credentials).then(
            (users) => {


                dispatch(success(users))
            },
            (error) => {

                dispatch(failure(error))
            }
        )

    }


    function request() { return { type: tickectConstants.GET_TICKET_LIST_REQUEST } }
    function success(users) { return { type: tickectConstants.GET_TICKET_LIST_SUCCESS, users } }
    function failure(error) { return { type: tickectConstants.GET_TICKET_LIST_FAILURE, error } }


};

function ticketReply(data) {

    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: "ticket/createAdminTicketReply"
    };
    return (dispatch) => {
        dispatch(request(data));
        APIcallFunction(credentials).then(
            (users) => {

                dispatch(success(users))
                dispatch(ticktAction.getMsgListTicketById({ ticketId: data?.ticketId }))
            },
            (error) => {

                dispatch(failure(error))
            }
        )

    }


    function request() { return { type: tickectConstants.CREATE_TICKET_REPLY_REQUEST } }
    function success(users) { return { type: tickectConstants.CREATE_TICKET_REPLY_SUCCESS, users } }
    function failure(error) { return { type: tickectConstants.CREATE_TICKET_REPLY_FAILURE, error } }


};


function getMsgListTicketById(data) {


    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: "ticket/getMsgListTicketById"
    };
    return (dispatch) => {
        dispatch(request(data));
        APIcallFunction(credentials).then(
            (users) => {

                dispatch(success(users))
            },
            (error) => {

                dispatch(failure(error))
            }
        )

    }


    function request() { return { type: tickectConstants.GET_MSG_TICKET_REPLY_REQUEST } }
    function success(users) { return { type: tickectConstants.GET_MSG_TICKET_REPLY_SUCCESS, users } }
    function failure(error) { return { type: tickectConstants.GET_MSG_TICKET_REPLY_FAILURE, error } }


};


function resovleTicket(data, ticketData) {


    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: "ticket/resolvedTicket "
    };
    return (dispatch) => {
        dispatch(request(data));
        APIcallFunction(credentials).then(
            (users) => {

                dispatch(success(users))
                dispatch(alertActions.success("Ticket Resolved"));
                dispatch(ticketGet(ticketData))
            },
            (error) => {

                dispatch(failure(error))
            }
        )

    }


    function request() { return { type: tickectConstants.RESOLVED_TICKET_REQUEST } }
    function success(users) { return { type: tickectConstants.RESOLVED_TICKET_SUCCESS, users } }
    function failure(error) { return { type: tickectConstants.RESOLVED_TICKET_FAILURE, error } }


};