import { headerForPrivateAPI, APIcallFunction } from "../_helpers";
import { userpackageConstant } from "../_constants";
import { alertActions } from "./alert.actions";

export const userPackageAction = {
    getUserOrderHistoryList,
    purchasedPackageByAdmin
}


function getUserOrderHistoryList(data) {
    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: "userPackage/getUserOrderHistoryList",
    };

    return (dispatch) => {
        dispatch(request(data));
        APIcallFunction(credentials).then(
            (user) => {

                dispatch(success(user));
            },
            (error) => {
                dispatch(failure(error));
                dispatch(alertActions.error(error))
            }
        );
    };

    function request() {
        return { type: userpackageConstant.GET_USER_PACKAGE_LIST_REQUEST };
    }
    function success(users) {
        return { type: userpackageConstant.GET_USER_PACKAGE_LIST_SUCCESS, users };
    }
    function failure(error) {
        return { type: userpackageConstant.GET_USER_PACKAGE_LIST_FAILURE, error };
    }
}


function purchasedPackageByAdmin(data, userpackageDetails) {
    const credentials = {
        header: headerForPrivateAPI(),
        method: "POST",
        body: data ?? {},
        endPoint: "userPackage/purchasedPackageByAdmin",
    };

    return (dispatch) => {
        dispatch(request(data));
        APIcallFunction(credentials).then(
            (user) => {

                dispatch(success(user));
                dispatch(getUserOrderHistoryList(userpackageDetails))
            },
            (error) => {

                dispatch(failure(error));
                dispatch(alertActions.error(error))
            }
        );
    };

    function request() {
        return { type: userpackageConstant.USER_PACKAGE_PURCHASED_BY_ADMIN_REQUEST };
    }
    function success(users) {
        return { type: userpackageConstant.USER_PACKAGE_PURCHASED_BY_ADMIN_SUCCESS, users };
    }
    function failure(error) {
        return { type: userpackageConstant.USER_PACKAGE_PURCHASED_BY_ADMIN_FAILURE, error };
    }
}