export const roleConstants={
    GET_ROLE_LIST_REQUEST:"GET_ROLE_LIST_REQUEST",
    GET_ROLE_LIST_SUCCESS:"GET_ROLE_LIST_SUCCESS",
    GET_ROLE_LIST_FAILURE:"GET_ROLE_LIST_FAILURE",

    DELETE_ROLE_LIST_REQUEST:"DELETE_ROLE_LIST_REQUEST",
    DELETE_ROLE_LIST_SUCCESS:"DELETE_ROLE_LIST_SUCCESS",
    DELETE_ROLE_LIST_FAILURE:"DELETE_ROLE_LIST_FAILURE",

    CREATE_ROLE_LIST_REQUEST:"CREATE_ROLE_LIST_REQUEST",
    CREATE_ROLE_LIST_SUCCESS:"CREATE_ROLE_LIST_SUCCESS",
    CREATE_ROLE_LIST_FAILURE:"CREATE_ROLE_LIST_FAILURE",

    UPDATE_ROLE_LIST_REQUEST:"UPDATE_ROLE_LIST_REQUEST",
    UPDATE_ROLE_LIST_SUCCESS:"UPDATE_ROLE_LIST_SUCCESS",
    UPDATE_ROLE_LIST_FAILURE:"UPDATE_ROLE_LIST_FAILURE",
}