import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import IconComponent from "./Iconcompo";
import { useState } from "react";
import SubItemCom from "./SubItemCom";
import "./Sidebar.css";
import {
  IoIosArrowDropdownCircle,
  IoIosArrowDropleftCircle,
  IoIosArrowDropupCircle,
} from "react-icons/io";
import { useEffect } from "react";

function Sidebar({ SidebarJSON, handelOnClickTab, removeLabel }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [navbarOpen, setNavbarOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 1024) {  // You can adjust the width according to your needs
        setNavbarOpen(true);
      }
      if (window.innerWidth > 1024) {  // You can adjust the width according to your needs
        setNavbarOpen(false);
      }
    };

    // Initial check in case the component mounts with a small screen
    handleResize();

    // Add event listener to handle window resize
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);


  const [showManage, setShowManage] = useState(false);
  const [, setShowHeading] = useState("");

  const [isManagement, setIsManagement] = useState(false)
  const [roleAccess, setRoleAccess] = useState({})
  const [permission, setPermission] = useState([])

  useEffect(() => {
    setIsManagement(() => {
      return "true" === String(JSON.parse(window.sessionStorage.getItem("management")))
    })
    setRoleAccess(() => {
      return JSON.parse(window.sessionStorage.getItem("adminuser"))?.roleAccess || {}
    })
    setPermission(() => {
      return JSON.parse(window.sessionStorage.getItem("adminuser"))?.permissions || {}
    })
  }, [location])

  const onClickMenu = (url) => {
    navigate(url);

    setTimeout(() => {
      setNavbarOpen((prev) => !prev);
    }, 150);
  };

  const toggleMenu = () => {
    setShowManage(!showManage);
  };

  const handleClick = (label) => {
    handelOnClickTab(label);
    setShowHeading(label);
  };

  const handleNavbar = () => {
    setNavbarOpen((prev) => !prev);
  };



  return (
    <>
      <div className="-px-10">
        <button
          className={` ${navbarOpen === true ? "" : ""
            } absolute  lg:top-2 left-0  items-center justify-center  border-gray-200 text-black focus:outline-none z-50 lg:h-16 h-12 `}
          onClick={() => setNavbarOpen(!navbarOpen)}

        >
          <svg className="h-6 w-6 lg:ml-4 ml-16 lg:mt-0 mt-2" stroke="currentColor" fill="none" viewBox="0 0 24 24">  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h7"  ></path></svg>
        </button>
      </div>

      <div
        className={` ${navbarOpen === false ? "" : "-ml-72"
          } fixed left-0 inset-y-0 xl:static   w-72 z-50 flex-col `}
      >
        <div className="flex flex-col  relative flex-1 h-full max-h-full ">
          <div className="dashboardHeader flex justify-between  ">
            <div className="cursor-pointer  flex items-center h-20 bg-white flex-shrink-0 px-4  w-full z-50">
              <img src={`/${SidebarJSON.SidebarHeaderImage}`} alt="" className=" relative flex justify-center items-center mr-4 sidebar_img " />
              <h1 onClick={() => onClickMenu("/app/dashboard")} className="flex justify-between items-center">  {SidebarJSON.SidebarHeaderName}</h1>
              <span className="leftArrow" onClick={handleNavbar}>
                <IoIosArrowDropleftCircle className="text-gray-400 w-7 h-7 mt-4 hover:text-[#033565]" />
              </span>
            </div>
          </div>

          <nav className="overflow-y-auto Pokercardbar flex flex-col flex-wrap items-center justify-between relative w-72 z-10 px-0 h-full overflow-auto ">
            <div className="px-0 flex flex-col flex-wrap items-center w-full flex-1">
              <div className="relative z-40 flex-1 w-full space-y-1">
                {(!isManagement ? SidebarJSON.creditBased : SidebarJSON.WebView).map((ele, index) => {
                  if (!ele?.label) return null
                  else return (
                    ele.label === "Manage" ? (
                      <div className="group ">
                        <div className="">
                          <div
                            className="flex border-b border-[#01284E]  "
                            onClick={toggleMenu}
                          >
                            <span className="py-1 text-white ms-2 IconComponent flex justify-center  w-10  ">
                              <IconComponent iconName={ele.icon} />
                            </span>
                            <span className="px-2 py-1">
                              {ele.label}
                            </span>

                            {Array.isArray(ele.subItems) && ele.subItems.length > 0 && <div className="cursor-pointer text-xl ">
                              <span className="flex justify-end absolute pt-1 ps-28">
                                {showManage ? (
                                  <IoIosArrowDropupCircle />
                                ) : (
                                  <IoIosArrowDropdownCircle />
                                )}
                              </span>
                            </div>}

                          </div>
                        </div>
                        {showManage && (
                          <div className="dropdownmenu ">
                            {ele.subItems.map((subItem, index) => {
                              return (
                                roleAccess && roleAccess.roleId === 1 ?
                                  <Link
                                    key={index}
                                    to={subItem.location}
                                    className={
                                      "border-b border-[#01284E]  text-sm  text-white flex flex-direction: row px-2  py-2 text-dark " +
                                      (subItem.location === location.pathname
                                        ? "text-black  bg-#C60E6B p-2 hover:bg-[#C60E6B] bg-[#C60E6B]"
                                        : "text-white hover:bg-[#C60E6B] hover:text-white")
                                    }
                                    onClick={() => handleClick(subItem.label)}
                                  >
                                    <span className="w-7 ps-1 ms-1">
                                      <SubItemCom subiconName={subItem.icon} />
                                    </span>
                                    <span className="px-4">
                                      {subItem.label}

                                    </span>
                                  </Link> :
                                  // subItem.access && subItem.access.length > 0 && isLocationAvailable(access, subItem.access) ?
                                  permission.includes(subItem.accessKey) ?
                                    <Link
                                      key={index}
                                      to={subItem.location}
                                      className={
                                        "border-b border-[#01284E]  text-sm  text-white flex flex-direction: row px-2  py-2 text-dark " +
                                        (subItem.location === location.pathname
                                          ? "text-black  bg-#C60E6B p-2 hover:bg-[#C60E6B] bg-[#C60E6B]"
                                          : "text-white hover:bg-[#C60E6B] hover:text-white")
                                      }
                                      onClick={() => handleClick(subItem.label)}
                                    >
                                      <span className="w-7 ps-1 ms-1">
                                        <SubItemCom subiconName={subItem.icon} />
                                      </span>
                                      <span className="px-4">
                                        {subItem.label}

                                      </span>
                                    </Link> : null
                              );
                            })}
                          </div>
                        )}
                      </div>

                    ) : (
                      roleAccess && roleAccess.roleId === 1 ?
                        <ul
                          className="md:flex-col md:min-w-full justify-center place-content-center  flex flex-col list-none"
                          key={index}
                        >
                          <li
                            className={` list_element ${ele.location === location.pathname ? "" : ""
                              } `}
                          >
                            <Link
                              className={
                                "text-sm capitalize border-b border-[#01284E]  flex place-items-center px-2  py-1 text-white" +
                                (ele.location === location.pathname
                                  ? "text-white bg-[#C60E6B]  "
                                  : "hover:text-[#000]")
                              }
                              to={ele.location}
                              onClick={() => handleClick(ele.label)}
                            >
                              <span className="py-1 text-white IconComponent flex justify-center  w-10  ">
                                <IconComponent iconName={ele.icon} />
                              </span>
                              <span className="px-2 ">
                                {ele.label}

                              </span>
                            </Link>
                          </li>
                        </ul>
                        : permission.includes(ele.accessKey) &&
                        <ul
                          className="md:flex-col md:min-w-full justify-center place-content-center  flex flex-col list-none"
                          key={index}
                        >
                          <li
                            className={` list_element ${ele.location === location.pathname ? "" : ""
                              } `}
                          >
                            <Link
                              className={
                                "text-sm capitalize border-b border-[#01284E]  flex place-items-center px-2  py-1 text-white" +
                                (ele.location === location.pathname
                                  ? "text-white bg-[#C60E6B]  "
                                  : "hover:text-[#000]")
                              }
                              to={ele.location}
                              onClick={() => handleClick(ele.label)}
                            >
                              <span className="py-1 text-white IconComponent flex justify-center  w-10  ">
                                <IconComponent iconName={ele.icon} />
                              </span>
                              <span className="px-2 ">
                                {ele.label}

                              </span>
                            </Link>
                          </li>
                        </ul>
                    )
                  )
                })}
              </div>
            </div>
          </nav>
        </div >
      </div >
    </>
  );
}

export default Sidebar;
