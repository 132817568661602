import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { parentAction } from "../../_actions"
import Button from "./Button";
import { ActiveParent } from "./ActiveParent";
import InActiveParent from "./InActiveParent";
import BlockedParent from "./BlockedParent";
import ParrentCbheader from "./ParrentCbheader";
import ParentTutorFilter from "../Filters/ParentTutorFilter";

const ParentStudentsProfile = () => {
  const dispatch = useDispatch();
  const buttonName = ["Active Parent", "Inactive Parent", "Blocked Parent"];
  const [searchText, setSearchText] = useState("");
  const [active, setActive] = useState("Active Parent")
  const handleClick = (data) => {
    setActive(data)
    setSearchText('')
  }
  const handleSearch = (e) => {
    setSearchText(e.target.value);
    let status;
    if (active === "Active Parent") status = 'ACTIVE'
    if (active === "Inactive Parent") status = 'INACTIVE'
    if (active === "Blocked Parent") status = 'BLOCK'
    let parentUserList = {
      status: status,
      userType: "PARENT",
      isManagement: false,
      keyWord: e.target.value,
      fromDate: "",
      toDate: "",
      sortOrder: "asc",
      sortBy: "createdAt",
      pageNo: 1,
      size: 10,
    };
    dispatch(parentAction.getUserList(parentUserList));
  };

  const [filters, setFilters] = useState({
    cityId: "",
    gender: "",
    pinCode: ""
  })

  return (
    <>
      <ParrentCbheader handleSearch={handleSearch} searchText={searchText} />
      <section className="overflow-hidden overflow-y-auto">
        <div className="m-5">
          <section className="flex gap-3">
            {buttonName.map((item, index) => (
              <div key={index} onClick={() => handleClick(item)} className={` px-4 py-2 rounded-full lg:text-sm text-xs font-semibold ${active === item ? 'bg-[#023565] text-white' : 'bg-[#9C9EAB] text-white'}`}>
                <Button name={item} />
              </div>
            ))}
          </section>
          <ParentTutorFilter filters={filters} setFilters={setFilters} />

          <section>
            {active === "Active Parent" && <ActiveParent searchText={searchText} filters={{...filters,cityId: filters.cityId ? [filters.cityId] : []}} />}
            {active === "Inactive Parent" && <InActiveParent searchText={searchText}  filters={{...filters,cityId: filters.cityId ? [filters.cityId] : []}}/>}
            {active === "Blocked Parent" && <BlockedParent searchText={searchText} filters={{...filters,cityId: filters.cityId ? [filters.cityId] : []}}/>}
          </section>
        </div>
      </section>
    </>

  );
};

export default ParentStudentsProfile;
