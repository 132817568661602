export const packageConstants={
    GET_PACKAGE_LIST_REQUEST:"GET_PACKAGE_LIST_REQUEST",
    GET_PACKAGE_LIST_SUCCESS:"GET_PACKAGE_LIST_SUCCESS",
    GET_PACKAGE_LIST_FAILURE:"GET_PACKAGE_LIST_FAILURE",

    CREATE_PACKAGE_LIST_REQUEST:"CREATE_PACKAGE_LIST_REQUEST",
    CREATE_PACKAGE_LIST_SUCCESS:"CREATE_PACKAGE_LIST_SUCCESS",
    CREATE_PACKAGE_LIST_FAILURE:"CREATE_PACKAGE_LIST_FAILURE",

    DELETE_PACKAGE_LIST_REQUEST:"DELETE_PACKAGE_LIST_REQUEST",
    DELETE_PACKAGE_LIST_SUCCESS:"DELETE_PACKAGE_LIST_SUCCESS",
    DELETE_PACKAGE_LIST_FAILURE:"DELETE_PACKAGE_LIST_FAILURE",

    UPDATE_PACKAGE_LIST_REQUEST:"UPDATE_PACKAGE_LIST_REQUEST",
    UPDATE_PACKAGE_LIST_SUCCESS:"UPDATE_PACKAGE_LIST_SUCCESS",
    UPDATE_PACKAGE_LIST_FAILURE:"UPDATE_PACKAGE_LIST_FAILURE",

    ENA_DIS_PACKAGE_LIST_REQUEST:"ENA_DIS_PACKAGE_LIST_REQUEST",
    ENA_DIS_PACKAGE_LIST_SUCCESS:"ENA_DIS_PACKAGE_LIST_SUCCESS",
    ENA_DIS_PACKAGE_LIST_FAILURE:"ENA_DIS_PACKAGE_LIST_FAILURE",
}