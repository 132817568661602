/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import Button from "./Button";
import RecentCity from "./RecentCity";

import Package from './Package'
import CityProfileHeader from "./CityProfileHeader";
import { cityAction } from "../../_actions/city.action";
import { packageAction } from "../../_actions";
import { useLocation, useNavigate } from "react-router";



const CitysProfile = () => {
  const dispatch = useDispatch();
  const [active, setActive] = useState("Cities");
  const currentPage = 1;
  const pageSize = 10;


  const CityButton = ["Cities", "Package"];

  const handleActive = (data) => {
    setActive(data);
    navigate(`?tab=${data}`)
  };
  const location = useLocation()
  const navigate = useNavigate()
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const activeTab = queryParams.get('tab');
    if (activeTab) {
      setActive(activeTab);
      navigate(`?tab=${activeTab}`)
    } else {
      navigate(`?tab=Cities`)
    }
  }, [])

  const [searchText, setSearchText] = useState('')

  const handleSearch = (e) => {
    setSearchText(e.target.value)
    let keyWord = e.target.value

    // const resData=
    // setFetchData()
    let cityDetails = {
      "keyWord": keyWord,
      "fromDate": "",
      "toDate": "",
      "sortOrder": "asc",
      "sortBy": "name",
      pageNo: currentPage,
      size: pageSize,
    }

    dispatch(cityAction.cityGetList(cityDetails))

    let packageList = {

      keyWord: keyWord,

      fromDate: "",
      toDate: "",
      sortOrders: "asc",
      sortBy: "asc",
      pageNo: currentPage,
      size: pageSize,
    };
    dispatch(packageAction.packageGet(packageList));
  }


  return (
    <>
      <CityProfileHeader handleSearch={handleSearch} searchText={searchText} />
      <section className="overflow-hidden overflow-y-auto h-screen">
        <div className="m-5 flex justify-between ">
          <section className="flex gap-3">
            {CityButton.map((item, index) => (
              <div
                className={`px-3 py-2 rounded-full font-bold z-10 ${active === item
                  ? "bg-[#023565]  text-white"
                  : "bg-[#9C9EAB] text-white"
                  }`} key={index}
                onClick={() => handleActive(item)}
              >
                <Button name={item} />
              </div>
            ))}
          </section>

        </div>
        {active === "Cities" && <RecentCity searchText={searchText} />}
        {active === 'Package' && <Package />}
      </section>
    </>
  );
};

export default CitysProfile;
