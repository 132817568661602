import React, { Fragment } from 'react'
import HeaderProfileComp from '../../components/Header/HeaderProfileComp'
import SearchIconComponent from '../../components/Header/SearchIconComponent'
import HeaderHeading from '../../components/Header/HeaderHeading'
import MobileSearchHeader from '../../components/Header/MobileSearchHeader'

const CityProfileHeader = ({ searchText, handleSearch }) => {
  return (
    <Fragment>
      <section className=' bg-[#ffffff] lg:block hidden border-r border pl-4'>
        <div className='w-full py-3 px-10 flex justify-between items-center'>
          <HeaderHeading label={'Cities profile'} />
          <div className='flex items-center gap-6'>
            <SearchIconComponent
              handleSearch={handleSearch}
              searchText={searchText}
            />
            {/* <div className="flex gap-2 relative  city_select cursor-pointer items-center">
              <select className="flex items-center capitalize justify-center bg-white border-2 text-[#000] rounded-lg w-full lg:w-36 px-4 h-9">
                <option className="text-black align-middle" value={""}>
                  {" "}
                  All Cities <FaAngleDown className="ml-3" color="#D9D9D9" />
                </option>

              </select>

            </div> */}
          </div>
          <HeaderProfileComp />
        </div>
      </section>

      <section className='bg-white lg:hidden block'>
        <MobileSearchHeader
          handleSearch={handleSearch}
          searchText={searchText}
        />
      </section>
    </Fragment>
  )
}

export default CityProfileHeader
