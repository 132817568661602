import React, { useEffect } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { feeAction } from "../../../_actions/fee.action";
import { useLocation } from "react-router-dom";
import FeePendingHeader from './EarningHeader/FeePendingHeader'

const FeePending = () => {
  const dispatch = useDispatch();
  const selector = useSelector(state => state);
  let FeePendingData = selector?.Fee?.feePending?.data?.list
  const location = useLocation();
  const feePendingData = location.state?.feePendingData;
  let monthid = feePendingData?.month
  useEffect(() => {
    let feePendingData = {
      "cityId": "",
      "month": monthid,
      "keyWord": "",
      "pageNo": 1,
      "size": 10
    }
    dispatch(feeAction.getFeePendingList(feePendingData))
    dispatch(feeAction.getAllFeePending())
  }, [])
  return (
    <section>
      <FeePendingHeader />
      <div className='m-5'>
        <div className="flex justify-end">
          <button className="bg-white px-3 py-2 border flex justify-between rounded-lg">
            <span>Export</span>  <img src="/export 1.png" alt='' />
          </button>
        </div>
        <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table class="w-full text-sm text-left rtl:text-right ">
            <thead class="text-xs text-blue-700 bg-white">
              <tr className="py-4 border-b">
                <th scope="col" class="px-6 py-4">
                  Parent name
                </th>
                <th scope="col" class="px-6 py-3">
                  subject
                </th>
                <th scope="col" class="px-6 py-3">
                  Class
                </th>
                <th scope="col" class="px-6 py-3">
                  Fee
                </th>
                <th scope="col" class="px-6 py-3">
                  Status
                </th>
              </tr>
            </thead>
            <tbody>
              {FeePendingData &&
                FeePendingData?.map((item, id) => (
                  <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                    <th
                      scope="row"
                      class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      <div className="flex gap-3 pla">
                        <div>
                          <img
                            alt=''
                            src={
                              item?.parentId?.image ??
                              "https://thumbs.dreamstime.com/z/vector-illustration-avatar-dummy-logo-collection-image-icon-stock-isolated-object-set-symbol-web-137160339.jpg?ct=jpeg"
                            }
                            className="w-8 rounded-full"
                          />
                        </div>
                        <div>
                          <p>{item?.parentId?.name}</p>
                          <p>{item?.parentId?.mobNo}</p>
                        </div>
                      </div>
                    </th>
                    <td class="px-6 py-4">
                      {item?.demoClassId?.bookFreeDemo?.map((subj, id) => (
                        <p key={id}>
                          {subj?.subjectId?.map((e) => (
                            <span key={e._id}>{e.name}</span>
                          ))}
                        </p>
                      ))}
                    </td>
                    <td class="px-6 py-4">
                      {item?.demoClassId?.classId?.map((item, index) => (
                        <p key={index}>{item?.name}</p>
                      ))}
                    </td>
                    <td class="px-6 py-4">{item?.fee ?? "0"}</td>
                    <td class="px-6 py-4 text-green-700 font-semibold">
                      {item?.status === 0
                        ? " pending"
                        : item?.status === 1
                          ? "Received"
                          : item?.status === 2
                            ? " advance paid"
                            : "Unknown status"}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </section>
  )
}

export default FeePending
