import { APIcallFunction, headerForPrivateAPI } from "../_helpers";
import { feeConstant } from "../_constants";
import { feelistAction } from "./feelist.action";
import { alertActions } from "./alert.actions";

export const feeAction = {
  getTutionEarning,
  getCreditBaseTuitionEarning,
  getFeeReceivedList,
  getAllFeeReceived,
  getFeePaidList,
  getAllFeePaid,
  getFeePendingList,
  getAllFeePending,
  getReferencePaidList,
  getAllReferencePaid,
  getReferralPaidList,
  getAllReferralPaid,
  getFeeRefundedList,
  getAllFeeRefunded,
  getTutorFeePaidList,
  getAllTutorFeePaid,
  getTutorFeePendingList,
  getAllTutorFeePending,
  getFeeHistoryCount,
  getTotalReceivedAmountList,
  getTotalRefundedAmountList,
  gettransaction,
  feeRemark,
  updateFeeDueDate,
  refreshFeeHistoryByPromise,
  getNoFeeList,
  getAllNoFeeList,
  getAllFeeHistoryListByPromise,
}

function getAllFeeHistoryListByPromise(data) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "feeHistory/getAllFeeHistoryList"
  };

  return APIcallFunction(credentials)

}

function getAllNoFeeList(data) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "earning/getAllNoFeeList",
  };
  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (users) => {
        dispatch(success(users));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: feeConstant.GET_ALL_NO_FEE_REQUEST };
  }
  function success(users) {
    return { type: feeConstant.GET_ALL_NO_FEE_SUCCESS, users };
  }
  function failure(error) {
    return { type: feeConstant.GET_ALL_NO_FEE_FAILURE, error };
  }
}

function getNoFeeList(data) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "earning/getNoFeeList",
  };
  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (users) => {
        dispatch(success(users));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: feeConstant.GET_NO_FEE_LIST_REQUEST };
  }
  function success(users) {
    return { type: feeConstant.GET_NO_FEE_LIST_SUCCESS, users };
  }
  function failure(error) {
    return { type: feeConstant.GET_NO_FEE_LIST_FAILURE, error };
  }
}

function refreshFeeHistoryByPromise(data) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "feeHistory/refreshFeeHistory"
  };

  return APIcallFunction(credentials)

}

function updateFeeDueDate(data,listData) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "demo/updateFeeDueDate ",
  };
  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (users) => {
        dispatch(success(users));
        dispatch(feelistAction.getFeeHistoryList(listData))

      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: feeConstant.UPDATE_FEE_DUE_DATE_REQUEST };
  }
  function success(users) {
    return { type: feeConstant.UPDATE_FEE_DUE_DATE_SUCCESS, users };
  }
  function failure(error) {
    return { type: feeConstant.UPDATE_FEE_DUE_DATE_FAILURE, error };
  }
}

function getTutionEarning(data) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "earning/getTuitionEarning ",
  };
  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (users) => {
        dispatch(success(users));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: feeConstant.GET_TUTION_EARNING_LIST_REQUEST };
  }
  function success(users) {
    return { type: feeConstant.GET_TUTION_EARNING_LIST_SUCCESS, users };
  }
  function failure(error) {
    return { type: feeConstant.GET_TUTION_EARNING_LIST_FAILURE, error };
  }
}

function getCreditBaseTuitionEarning(data) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "earning/getCreditBaseTuitionEarning ",
  };
  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (users) => {
        dispatch(success(users));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: feeConstant.GET_CREDIT_BASE_TUITION_EARNING_REQUEST };
  }
  function success(users) {
    return { type: feeConstant.GET_CREDIT_BASE_TUITION_EARNING_SUCCESS, users };
  }
  function failure(error) {
    return { type: feeConstant.GET_CREDIT_BASE_TUITION_EARNING_FAILURE, error };
  }
}

function getFeeReceivedList(data) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "earning/getFeeReceivedList",
  };
  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (users) => {
        dispatch(success(users));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: feeConstant.GET_FEE_RECEVIED_LIST_REQUEST };
  }
  function success(users) {
    return { type: feeConstant.GET_FEE_RECEVIED_LIST_SUCCESS, users };
  }
  function failure(error) {
    return { type: feeConstant.GET_FEE_RECEVIED_LIST_FAILURE, error };
  }
}

function getAllFeeReceived(data) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "earning/getAllFeeReceived",
  };
  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (users) => {
        dispatch(success(users));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: feeConstant.GET_ALL_FEE_RECEIVED_REQUEST };
  }
  function success(users) {
    return { type: feeConstant.GET_ALL_FEE_RECEIVED_SUCCESS, users };
  }
  function failure(error) {
    return { type: feeConstant.GET_ALL_FEE_RECEIVED_FAILURE, error };
  }
}


function getFeePaidList(data) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "earning/getFeePaidList ",
  };
  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (users) => {
        dispatch(success(users));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: feeConstant.GET_FEE_PAID_LIST_REQUEST };
  }
  function success(users) {
    return { type: feeConstant.GET_FEE_PAID_LIST_SUCCESS, users };
  }
  function failure(error) {
    return { type: feeConstant.GET_FEE_PAID_LIST_FAILURE, error };
  }
}

function getAllFeePaid(data) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "earning/getAllFeePaid ",
  };
  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (users) => {
        dispatch(success(users));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: feeConstant.GET_ALL_FEE_PAID_REQUEST };
  }
  function success(users) {
    return { type: feeConstant.GET_ALL_FEE_PAID_SUCCESS, users };
  }
  function failure(error) {
    return { type: feeConstant.GET_ALL_FEE_PAID_FAILURE, error };
  }
}

function getFeePendingList(data) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "earning/getFeePendingList ",
  };
  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (users) => {
        dispatch(success(users));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: feeConstant.GET_FEE_PENDING_LIST_REQUEST };
  }
  function success(users) {
    return { type: feeConstant.GET_FEE_PENDING_LIST_SUCCESS, users };
  }
  function failure(error) {
    return { type: feeConstant.GET_FEE_PENDING_LIST_FAILURE, error };
  }
}

function getAllFeePending(data) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "earning/getAllFeePending ",
  };
  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (users) => {
        dispatch(success(users));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: feeConstant.GET_ALL_FEE_PENDING_REQUEST };
  }
  function success(users) {
    return { type: feeConstant.GET_ALL_FEE_PENDING_SUCCESS, users };
  }
  function failure(error) {
    return { type: feeConstant.GET_ALL_FEE_PENDING_FAILURE, error };
  }
}

function getReferencePaidList(data) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "earning/getReferencePaidList ",
  };
  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (users) => {
        dispatch(success(users));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: feeConstant.GET_REFERENCE_PAID_LIST_REQUEST };
  }
  function success(users) {
    return { type: feeConstant.GET_REFERENCE_PAID_LIST_SUCCESS, users };
  }
  function failure(error) {
    return { type: feeConstant.GET_REFERENCE_PAID_LIST_FAILURE, error };
  }
}

function getAllReferencePaid(data) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "earning/getAllReferencePaid ",
  };
  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (users) => {
        dispatch(success(users));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: feeConstant.GET_ALL_REFERENCE_PAID_REQUEST };
  }
  function success(users) {
    return { type: feeConstant.GET_ALL_REFERENCE_PAID_SUCCESS, users };
  }
  function failure(error) {
    return { type: feeConstant.GET_ALL_REFERENCE_PAID_FAILURE, error };
  }
}


function getReferralPaidList(data) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "earning/getReferralPaidList ",
  };
  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (users) => {
        dispatch(success(users));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: feeConstant.GET_REFERRAL_PAID_LIST_REQUEST };
  }
  function success(users) {
    return { type: feeConstant.GET_REFERRAL_PAID_LIST_SUCCESS, users };
  }
  function failure(error) {
    return { type: feeConstant.GET_REFERRAL_PAID_LIST_FAILURE, error };
  }
}

function getAllReferralPaid(data) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "earning/getAllReferralPaid ",
  };
  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (users) => {
        dispatch(success(users));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: feeConstant.GET_LIST_REFERRAL_PAID_REQUEST };
  }
  function success(users) {
    return { type: feeConstant.GET_LIST_REFERRAL_PAID_SUCCESS, users };
  }
  function failure(error) {
    return { type: feeConstant.GET_LIST_REFERRAL_PAID_FAILURE, error };
  }
}


function getFeeRefundedList(data) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "earning/getFeeRefundedList ",
  };
  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (users) => {
        dispatch(success(users));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: feeConstant.GET_FEE_REFUND_LIST_REQUEST };
  }
  function success(users) {
    return { type: feeConstant.GET_FEE_REFUND_LIST_SUCCESS, users };
  }
  function failure(error) {
    return { type: feeConstant.GET_FEE_REFUND_LIST_FAILURE, error };
  }
}


function getTotalReceivedAmountList(data) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "earning/getTotalReceivedAmountList ",
  };
  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (users) => {
        dispatch(success(users));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: feeConstant.GET_TOTAL_RECEIVED_AMOUNT_LIST_REQUEST };
  }
  function success(users) {
    return { type: feeConstant.GET_TOTAL_RECEIVED_AMOUNT_LIST_SUCCESS, users };
  }
  function failure(error) {
    return { type: feeConstant.GET_TOTAL_RECEIVED_AMOUNT_LIST_FAILURE, error };
  }
}

function getTotalRefundedAmountList(data) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "earning/getTotalRefundedAmountList ",
  };
  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (users) => {
        dispatch(success(users));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: feeConstant.GET_TOTAL_REFUNDED_AMOUNT_LIST_REQUEST };
  }
  function success(users) {
    return { type: feeConstant.GET_TOTAL_REFUNDED_AMOUNT_LIST_SUCCESS, users };
  }
  function failure(error) {
    return { type: feeConstant.GET_TOTAL_REFUNDED_AMOUNT_LIST_FAILURE, error };
  }
}

function getAllFeeRefunded(data) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "earning/getAllFeeRefunded ",
  };
  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (users) => {
        dispatch(success(users));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: feeConstant.GET_ALL_FEE_REFUND_REQUEST };
  }
  function success(users) {
    return { type: feeConstant.GET_ALL_FEE_REFUND_SUCCESS, users };
  }
  function failure(error) {
    return { type: feeConstant.GET_ALL_FEE_REFUND_FAILURE, error };
  }
}


function getTutorFeePaidList(data) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "earning/getTutorFeePaidList ",
  };
  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (users) => {
        dispatch(success(users));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: feeConstant.GET_TUTOR_FEE_PAID_LIST_REQUEST };
  }
  function success(users) {
    return { type: feeConstant.GET_TUTOR_FEE_PAID_LIST_SUCCESS, users };
  }
  function failure(error) {
    return { type: feeConstant.GET_TUTOR_FEE_PAID_LIST_FAILURE, error };
  }
}

function getAllTutorFeePaid(data) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "earning/getAllTutorFeePaid ",
  };
  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (users) => {
        dispatch(success(users));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: feeConstant.GET_ALL_TUTOR_FEE_PAID_REQUEST };
  }
  function success(users) {
    return { type: feeConstant.GET_ALL_TUTOR_FEE_PAID_SUCCESS, users };
  }
  function failure(error) {
    return { type: feeConstant.GET_ALL_TUTOR_FEE_PAID_FAILURE, error };
  }
}


function getTutorFeePendingList(data) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "earning/getTutorFeePendingList ",
  };
  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (users) => {
        dispatch(success(users));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: feeConstant.GET_TUTOR_FEE_PENDIGN_LIST_REQUEST };
  }
  function success(users) {
    return { type: feeConstant.GET_TUTOR_FEE_PENDIGN_LIST_SUCCESS, users };
  }
  function failure(error) {
    return { type: feeConstant.GET_TUTOR_FEE_PENDIGN_LIST_FAILURE, error };
  }
}

function getAllTutorFeePending(data) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "earning/getAllTutorFeePending ",
  };
  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (users) => {
        dispatch(success(users));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: feeConstant.GET_ALL_TUTOR_FEE_PENDING_REQUEST };
  }
  function success(users) {
    return { type: feeConstant.GET_ALL_TUTOR_FEE_PENDING_SUCCESS, users };
  }
  function failure(error) {
    return { type: feeConstant.GET_ALL_TUTOR_FEE_PENDING_FAILURE, error };
  }
}


function getFeeHistoryCount(data) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "feeHistory/getFeeHistoryCount ",
  };
  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (users) => {
        dispatch(success(users));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: feeConstant.GET_FEE_HISTORY_COUNT_REQUEST };
  }
  function success(users) {
    return { type: feeConstant.GET_FEE_HISTORY_COUNT_SUCCESS, users };
  }
  function failure(error) {
    return { type: feeConstant.GET_FEE_HISTORY_COUNT_FAILURE, error };
  }
}


function gettransaction(data) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "earning/viewPaymentHistory",
  };
  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (users) => {
        dispatch(success(users));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: feeConstant.GET_TRANSACTION_REQUEST };
  }
  function success(users) {
    return { type: feeConstant.GET_TRANSACTION_SUCCESS, users };
  }
  function failure(error) {
    return { type: feeConstant.GET_TRANSACTION_FAILURE, error };
  }
}










function feeRemark(data, feelistDetails) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "feeHistory/addUpdateRemark",
  };
  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (users) => {
        dispatch(success(users));
        // dispatch(feelistAction.getFeeHistoryList(feelistDetails))
        dispatch(alertActions.success("Success."))
        dispatch(feelistAction.getFeeHistoryListByAdmin(feelistDetails))
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error("Please try again later."))
      }
    );
  };

  function request() {
    return { type: feeConstant.FEE_REMARK_REQUEST };
  }
  function success(users) {
    return { type: feeConstant.FEE_REMARK_SUCCESS, users };
  }
  function failure(error) {
    return { type: feeConstant.FEE_REMARK_FAILURE, error };
  }
}
