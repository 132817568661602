/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { feeAction } from "../../../_actions/fee.action";
import { useLocation } from "react-router-dom";
import { downloadCSV } from "../../../_helpers/auth-header"
import { IoArrowBack } from "react-icons/io5";

import FeereceviedHeader from "./EarningHeader/FeereceviedHeader";
import { alertActions } from "../../../_actions";
import { PiExport } from "react-icons/pi";

const NoFee = () => {
  const dispatch = useDispatch();
  const selector = useSelector((state) => state);
  const location = useLocation();
  const feeData = location.state?.feeData;
  let monthId = feeData?.month
  let FeeData = selector?.Fee?.noFee?.data?.list;
  let allFeeData = selector?.Fee?.allNoFee?.data;
  const fields = ['Parent Name', 'Parent Mobile Number', 'Tutor Name', 'Tutor Mobile Number', 'Student / Classes', 'Tuition Fee Due Date', 'Tutor Fee Due Date', 'Tuition Fee', 'Tutor Fee', 'Reference Fee', 'Created At', 'Time'];

  const convertToCSV = (data) => {

    const array = typeof data !== 'object' ? JSON.parse(data) : data;
    let csv = fields.join(',') + '\r\n';

    array?.forEach(item => {
      const parentName = item.parentId ? item.parentId.name : '-';
      const parentMobNo = item.parentId ? item.parentId.mobNo : '-';
      const tutorName = item.tutorId ? item.tutorId.name : '-';
      const tutorMobNo = item.tutorId ? item.tutorId.mobNo : '-';
      const studentsClasses = item.demoClassId && item.demoClassId.bookFreeDemo ? item.demoClassId.bookFreeDemo.map(demo => {
        return `${demo.studentId.studentFullName} / ${demo.classId.name}`;
      }).join(' | ') : 'N/A';

      const tuitionFeeDueDate = item.demoClassId?.dueDate ?? '';
      const tutorFeeDueDate = item.demoClassId?.tutorDueDate ?? '';
      const tuitionFee = item?.tuitionFee ?? '';
      const tutorFee = item?.tutorFee ?? '';
      const referenceFee = item?.referenceFee ?? '';
      const createdAt = item.createdAt ? new Date(item.createdAt).toLocaleString() : '';
      const row = [parentName, parentMobNo, tutorName, tutorMobNo, studentsClasses, tuitionFeeDueDate, tutorFeeDueDate, tuitionFee, tutorFee, referenceFee, createdAt].join(',');
      csv += row + '\r\n';
    });

    return csv;
  };

  useEffect(() => {
    let feeData = {
      cityId: "",
      month: monthId,
      keyWord: "",
      pageNo: 1,
      size: 10,
    };
    let feeData1 = {
      cityId: "",
      month: monthId
    };
    dispatch(feeAction.getNoFeeList(feeData));
    dispatch(feeAction.getAllNoFeeList(feeData1));

  }, []);


  const handleBack = () => {
    window.history.back();
  };

  return (
    <>
      <FeereceviedHeader headerName={"No Fee"} />
      <section>
        <div className="lg:m-5 m-2">
          <div className="flex justify-between">
            <div className="flex gap-2">
              <IoArrowBack
                className="text-[#023565] text-3xl border rounded-full border-black my-2 mx-3 cursor-pointer bg-white"
                onClick={handleBack}
              />
            </div>
            <button className="bg-white px-3 py-2 gap-2 border flex items-center rounded-lg" onClick={() => { !allFeeData.length ? dispatch(alertActions.error("No data to export.")) : downloadCSV(convertToCSV(allFeeData)) }}>
              <span>Export</span>  <span><PiExport size={18} /></span>
            </button>
          </div>
          <div class="relative overflow-x-auto bg-white rounded-lg border border-[#D3D3D3] mt-2 lg:block hidden">
            <table class="w-full  text-left rtl:text-right bg-white text-[#313131]">
              <thead class=" ">
                <tr className="capitalize border-b">
                  <th scope="col" class="px-6 py-3 whitespace-nowrap">
                    Parent name
                  </th>
                  <th scope="col" class="px-6 py-3 whitespace-nowrap">
                    Tutor name
                  </th>
                  <th scope="col" class="px-6 py-3 whitespace-nowrap">
                    City
                  </th>
                  <th scope="col" class="px-6 py-3 whitespace-nowrap">
                    Tuition Fee
                  </th>
                  <th scope="col" class="px-6 py-4 whitespace-nowrap">
                    Tutor Fee
                  </th>
                  <th scope="col" class="px-6 py-4 whitespace-nowrap">
                    Reference Fee
                  </th>
                  <th scope="col" class="px-6 py-4 whitespace-nowrap">
                    Tuition Fee Status
                  </th>
                  <th scope="col" class="px-6 py-4 whitespace-nowrap">
                    Tutor Fee Status
                  </th>
                </tr>
              </thead>
              <tbody>
                {FeeData && FeeData.length > 0 ?
                  FeeData?.map((item, id) => (
                    <tr class=" capitalize  border-b  forfontsmall" key={id}>
                      <th
                        scope="row"
                        class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                      >
                        <div className="flex items-center gap-3 pla">
                          <div>
                            <img
                              src={
                                item?.parentId?.image ||
                                "https://thumbs.dreamstime.com/z/vector-illustration-avatar-dummy-logo-collection-image-icon-stock-isolated-object-set-symbol-web-137160339.jpg?ct=jpeg"
                              }
                              alt=''
                              className="w-8 h-8 rounded-full"
                            />
                          </div>
                          <div>
                            <p>{item?.parentId?.name} </p>
                            <p>{item?.parentId?.mobNo}</p>
                          </div>
                        </div>
                      </th>
                      <td className="px-6 py-3 ">
                        <tr className="text-center font-[700]">{item?.tutorId?.name}</tr>
                        <tr className="text-center">{item?.tutorId?.mobNo}</tr>
                      </td>
                      <td class="px-6 py-3">{item?.cityId?.name ?? "-"}</td>
                      <td class="px-6 py-3">{item?.tuitionFee ?? "0"}</td>
                      <td class="px-6 py-3">{item?.tutorFee ?? "0"}</td>
                      <td class="px-6 py-3">{item?.referenceFee ?? "0"}</td>
                      <td class="px-6 py-3">
                        {"No Fee this Month"}
                      </td>
                      <td class="px-6 py-3">
                        {"No Fee this Month"}
                      </td>
                    </tr>
                  ))
                  : <tr>
                    <td colSpan={6} className="px-6 py-4 font-[400] text-base text-[#313131] whitespace-nowrap text-center">Data Not Available</td>
                  </tr>}
              </tbody>
            </table>
          </div>
          <div className="lg:hidden block bg-white my-4 rounded-lg border border-[#023565]">
            {FeeData && FeeData.length > 0 ?
              FeeData?.map((item, id) => (
                <table key={id} className="w-full  bg-white rounded-[10px]">
                  <tr className="border-b border-gray-300">
                    <th className="text-left py-4 px-4">
                      <span className="font-[500]">Parent name</span>
                    </th>
                    <td
                      class="px-4 py-4 font-medium text-gray-900"
                    >
                      <div className="flex items-center gap-3">
                        <div>
                          <img src={item?.parentId?.image || "https://thumbs.dreamstime.com/z/vector-illustration-avatar-dummy-logo-collection-image-icon-stock-isolated-object-set-symbol-web-137160339.jpg?ct=jpeg"} alt='' className="w-8 h-8 lg:rounded-full rounded" />
                        </div>
                        <div>
                          <p className="text-[12px] text-left capitalize">{item?.parentId?.name} </p>
                          <p className="text-[12px] text-left capitalize">{item?.parentId?.mobNo}</p>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr className="border-b border-gray-300">
                    <th className="text-left py-4 px-4">
                      <span className="font-[500]">Tutor name</span>
                    </th>
                    <td className="text-auto py-4  px-4 gap-2">
                      <p className="font-[400] text-[14px] capitalize">
                        {item?.tutorId?.name}
                      </p>
                      <p className="font-[400] text-[14px] capitalize">
                        {item?.tutorId?.mobNo}
                      </p>
                    </td>
                  </tr>
                  <tr className="border-b border-gray-300">
                    <th className="text-left py-4 px-4">
                      <span className="font-[500]">Tuition Fee</span>
                    </th>
                    <td className="text-auto py-4  px-4 gap-2">
                      <p className="font-[400] text-[14px] capitalize">
                        {item?.tuitionFee ?? "0"}
                      </p>
                    </td>
                  </tr>
                  <tr className="border-b border-gray-300">
                    <th className="text-left py-4 px-4">
                      <span className="font-[500]">Tutor Fee</span>
                    </th>
                    <td className="text-auto py-4  px-4 gap-2">
                      <p className="font-[400] text-[14px] capitalize">
                        {item?.tutorFee ?? "0"}
                      </p>
                    </td>
                  </tr>
                  <tr className="border-b border-gray-300">
                    <th className="text-left py-4 px-4">
                      <span className="font-[500]">Reference Fee</span>
                    </th>
                    <td className="text-auto py-4  px-4 gap-2">
                      <p className="font-[400] text-[14px] capitalize">
                        {item?.referenceFee ?? "0"}
                      </p>
                    </td>
                  </tr>
                  <tr className="border-b border-gray-300">
                    <th className="text-left py-4 px-4">
                      <span className="font-[500]">Tuition Fee Status</span>
                    </th>
                    <td className="text-auto py-4  px-4 gap-2">
                      <p className="font-[400] text-[14px] capitalize">
                        {"No Fee this Month"}
                      </p>
                    </td>
                  </tr>
                  <tr className="">
                    <th className="text-left py-4 px-4">
                      <span className="font-[500]">Tutor Fee Status</span>
                    </th>
                    <td className="text-auto py-4  px-4 gap-2">
                      <p className="font-[400] text-[14px] capitalize">
                        {"No Fee this Month"}
                      </p>
                    </td>
                  </tr>
                </table>
              ))
              : <tr>
                <td colSpan={6} className="px-6 py-4 font-[400] text-base text-[#313131] whitespace-nowrap text-center">Data Not Available</td>
              </tr>
            }
          </div>

        </div>
      </section>
    </>
  );
};

export default NoFee;
