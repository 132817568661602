/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { userPackageAction } from '../../_actions';
import { FaChevronRight, FaChevronLeft } from "react-icons/fa";
import { FaCheck } from "react-icons/fa";
import moment from "moment";
import Loader from '../../components/Loader';
import UserPackageHeader from './UserPackageHeader';
import { userListAction } from '../../_actions';
import { FaFilterCircleXmark } from 'react-icons/fa6';
import Select from "react-select";
import DateRangePicker from '../../components/Atoms/DateRangePicker';
const UserPackage = () => {
  const dispatch = useDispatch();
  const selector = useSelector((state) => state);
  const [userPackage, setUserPackage] = useState([]);
  const [searchText, setSearchText] = useState('')
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const pageSize = 10;
  const [status, setStatus] = useState("")
  const [userId, setUserId] = useState({ label: "Select Tutor", value: "" })
  const [selectedData, setSelectedData] = useState('')
  const [dates, setDates] = useState({ fromDate: '', toDate: '' });

  const handleChange = (value, dateType) => {
    setDates((prevDates) => ({ ...prevDates, [dateType]: value }));
  };

  useEffect((e) => {
    let userPackageDetails = {
      status,
      keyWord: searchText,
      ...dates,
      pageNo: currentPage,
      size: pageSize,
      userId: userId.value !== "" ? [userId.value] : []
    };
    dispatch(userPackageAction.getUserOrderHistoryList(userPackageDetails));
  }, [currentPage, dispatch, status, userId, searchText, dates]);


  useEffect(() => {
    if (selector?.userPackage?.getUserPackageList?.data?.total) {
      setTotalPages(Math.ceil(selector?.userPackage?.getUserPackageList?.data?.total / pageSize));
    }
    setUserPackage(selector?.userPackage?.getUserPackageList?.data?.list || []);

  }, [selector]);

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };


  const handleClick = (data) => {
    let list = {
      status,
      keyWord: searchText,
      ...dates,

      pageNo: currentPage,
      size: pageSize,
      userId: userId.value !== "" ? [userId.value] : []
    };
    let details = {
      id: data._id
    }
    dispatch(userPackageAction.purchasedPackageByAdmin(details, list))
  }

  const handleSearch = (e) => {
    setSearchText(e.target.value)
  }





  useEffect(() => {
    setSelectedData(() => selector?.userList?.getUserListForSearch?.data)
  }, [selector])

  const options = selectedData ? selectedData.map((ele) => ({
    value: ele?._id,
    label: `${ele?.name} - ${ele?.mobNo}`
  })) : [];


  useEffect(() => {
    let details = {
      "cityId": "",
      "userType": "TUTOR",
      "isDisable": false
    }
    dispatch(userListAction.getUserTypeList(details))
  }, [])



  const customStyles = {
    container: (provided) => ({
      ...provided,
    }),
    control: (provided, state) => ({
      ...provided,
      // border: '1px solid #000',
      boxShadow: 'none',
      '&:hover': {
        border: '1px solid #000',
      },
      borderRadius: '5px',
    }),
    menu: (provided) => ({
      ...provided,

    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#000 !important',
    }),
  };

  return (
    <>
      <UserPackageHeader handleSearch={handleSearch} searchText={searchText} />
      <div className="flex justify-end items-center gap-5 mx-9 mt-2">
        <DateRangePicker
          fromDate={dates.fromDate}
          toDate={dates.toDate}
          handleChange={handleChange}
        />
        <div>
          <label for="" className='invisible'>User Id</label>
          <Select
            id="userId"
            name="userId"
            value={userId}
            options={[{ label: "Select Tutor", value: "" }, ...options]}
            className="capitalize text-black "
            styles={customStyles}
            classNamePrefix="react-select"
            onChange={(selectedOption, actionMeta) => {
              setUserId(selectedOption)
            }}
          />
        </div>
        <div>
          <label for="" className='invisible'>status</label>
          <select className="block py-2 px-4 text-sm text-gray-900 border border-gray-300 rounded-md shadow bg-white"
            value={status}
            onChange={(e) => setStatus(e.target.value)}>
            <option value="">Status</option>
            <option value="1">Done</option>
            <option value="0">Pending</option>
          </select>
        </div>
        <button className="text-center font-bold pt-6">
          <FaFilterCircleXmark size={24} className=" mx-auto text-[#023565] hover:text-[#E4006F] cursor-pointer" onClick={() => { setStatus(""); setUserId({ label: "Select Tutor", value: "" }); setDates({ fromDate: '', toDate: '' }) }} />
        </button>

      </div>
      <section className='overflow-hidden overflow-y-auto'>
        <Loader loading={selector?.userPackage?.loading} />

        <div className='m-5'>
          <div className='lg:block hidden'>
            <div className='relative overflow-x-auto bg-white rounded-lg'>
              <table class="w-full  text-left rtl:text-right bg-white text-[#313131]">
                <thead class="">
                  <tr className='capitalize border-b'>
                    <th scope='col' className='px-6 py-4 whitespace-nowrap'>
                      Tutor/Mobile No
                    </th>
                    <th scope='col' className='px-6 py-4 whitespace-nowrap'>
                      Package
                    </th>
                    <th scope='col' className='px-6 py-4 whitespace-nowrap'>
                      Amount
                    </th>
                    <th scope='col' className='px-6 py-4 whitespace-nowrap'>
                      Order ID
                    </th>
                    <th scope='col' className='px-6 py-4 whitespace-nowrap'>
                      Receipt ID
                    </th>
                    <th scope='col' className='px-6 py-4 whitespace-nowrap'>
                      Status
                    </th>
                    <th scope='col' className='px-6 py-4 whitespace-nowrap'>
                      Date
                    </th>
                    <th scope='col' className='px-6 py-4 whitespace-nowrap'>
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {userPackage && userPackage.length > 0 ? (
                    userPackage.map((ele, id) => (
                      <tr class=" capitalize  border-b dark:border-gray-700 " key={id}>
                        <td className=" flex items-center  gap-2 px-6 py-4">
                          <div className="flex items-center justify-center gap-2">
                            <span>
                              {ele.userId && ele?.userId?.image ?
                                <img
                                  alt=""
                                  src={
                                    ele.userId && ele?.userId?.image
                                      ? ele?.userId?.image
                                      : ""
                                  }
                                  className="rounded-full max-w-[50px] max-h-[50px] min-w-[50px] min-h-[50px]"
                                />
                                : <div className="bg-gray-300 rounded-full h-14 w-14 flex justify-center items-center">
                                  <span className="text-base font-semibold text-[#033565]"> {ele?.userId?.name ? (ele?.userId?.name.split(' ').map(name => name.charAt(0)).join('')) : ('')}</span>
                                </div>}
                            </span>
                            <div className="w-24">
                              <p className="font-semibold whitespace-nowrap text-[#023565]">
                                {ele?.userId && ele?.userId?.name ? ele?.userId?.name : "No Name"}
                              </p>
                              <p className="text-gray-500 whitespace-nowrap">
                                {ele?.userId && ele?.userId?.mobNo ? ele?.userId?.mobNo : null}
                              </p>
                            </div>
                          </div>
                        </td>


                        <td className='px-6 py-3 '>
                          {ele?.packageId?.name || 'N/A'}
                        </td>
                        <td className='px-6 py-3 '>
                          {ele.amount || '0'}
                        </td>
                        {/* <td className='px-6 py-3'>{ele.currency || 'N/A'}</td> */}
                        <td className='px-6 py-3'>{ele.orderId || 'Not Available'}</td>
                        <td className='px-6 py-3'>{ele.receiptId || 'Not Available'}</td>
                        <td className={`px-6 py-3 font-semibold ${ele.status === 0 ? 'text-[#C60E6B]' : ele.status === 1 ? 'text-[#00AFE2]' : 'text-blue-700'}`}>
                          {ele.status === 0 ? 'Pending' : ele.status === 1 ? 'Done' : ele.status === 2 ? 'Reject' : 'N/A'}
                        </td>
                        <td className='px-6 py-3'>
                          {ele && ele.createdAt
                            ? moment(ele.createdAt).format("DD-MMM-YYYY")
                            : null}
                        </td>
                        <td className='px-6 py-3 whitespace-nowrap'>
                          {ele.status === 0 ? (
                            <button className='bg-[#023565] text-white px-3 py-1 rounded-full' onClick={() => handleClick(ele)}>
                              Mark Paid
                            </button>
                          ) : (
                            <FaCheck className='text-[#023565] ms-6' />
                          )}

                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan='7' className='text-center'>
                        No data found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>

            </div>
            {totalPages > 1 && (
              <div className="flex justify-end items-center py-2 px-4">
                <button
                  className={`px-4 py-2 border text-white bg-[#023565] rounded ${currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""}`}
                  onClick={handlePreviousPage}
                  disabled={currentPage === 1}
                >
                  <FaChevronLeft />
                </button>
                <span className="text-gray-700 mx-1">
                  <span className="border px-4 py-2 text-black  rounded">{currentPage}</span> of <span className="border px-4 py-2 text-black  rounded">{totalPages}</span>
                </span>
                <button
                  className={`px-4 py-2 border text-white bg-[#023565] rounded ${currentPage === totalPages ? "opacity-50 cursor-not-allowed" : ""}`}
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                >
                  <FaChevronRight />
                </button>
              </div>
            )}
          </div>

          <section className="overflow-y-auto space-y-5 lg:hidden block">
            {userPackage && userPackage.length > 0 ? (
              userPackage.map((ele, id) => (
                <div className=' bg-white border-2 border-[#D3D3D3] rounded-lg'>

                  <div className='flex justify-between p-2 w-full '>
                    <div className='w-full'>
                      <p className='text-[16px] font-[700] text-left '>User Name :</p>
                    </div>
                    <div className='w-full'>
                      <p className='text-[14px] font-[400] text-left'> {ele.userId?.name || 'N/A'}</p>
                      {/* <p className='text-[14px] font-[400] text-left'> {ele.userId?.mobNo || 'N/A'}</p> */}
                    </div>
                  </div>
                  <div className='flex justify-between p-2 w-full '>
                    <div className='w-full'>
                      <p className='text-[16px] font-[700] text-left '>Mobile No :</p>
                    </div>
                    <div className='w-full'>
                      {/* <p className='text-[14px] font-[400] text-left'> {ele.userId?.name || 'N/A'}</p> */}
                      <p className='text-[14px] font-[400] text-left'> {ele.userId?.mobNo || 'N/A'}</p>
                    </div>
                  </div>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[16px] font-[700] text-left'>Amount :</p>
                    </div>
                    <div className='w-full'>
                      <p className='text-[14px] font-[400] text-left'>{ele.amount || '0'}</p>
                    </div>
                  </div>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[16px] font-[700] text-left'>Currency :</p>
                    </div>
                    <div className='w-full'>
                      <p className='text-[14px] font-[400] text-left'>{ele.currency || 'N/A'}</p>
                    </div>
                  </div>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[16px] font-[700] text-left'>Order ID :</p>
                    </div>
                    <div className='w-full'>
                      <p className='text-[14px] font-[400] text-left'>{ele.orderId || 'N/A'}</p>
                    </div>
                  </div>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[16px] font-[700] text-left'>Receipt ID :</p>
                    </div>
                    <div className='w-full'>
                      <p className='text-[14px] font-[400] text-left'>{ele.receiptId || 'N/A'}</p>
                    </div>
                  </div>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[16px] font-[700] text-left'>Status :</p>
                    </div>
                    <div className='w-full'>
                      <p className='text-[14px] font-[400] text-left'>{ele.status === 0 ? 'Pending' : ele.status === 1 ? 'Done' : ele.status === 2 ? 'Reject' : 'N/A'}</p>
                    </div>
                  </div>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[16px] font-[700] text-left'>Created At :</p>
                    </div>
                    <div className='w-full'>
                      <p className='text-[14px] font-[400] text-left'>  {ele && ele.createdAt
                        ? moment(ele.createdAt).format("DD-MMM-YYYY")
                        : null}</p>
                    </div>
                  </div>

                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[16px] font-[700] text-left'>Action :</p>
                    </div>
                    <div className='w-full'>
                      <p className='text-[14px] font-[400] text-left'>
                        {ele.status === 0 ? (
                          <button className='bg-[#023565] text-white px-3 py-1 rounded-full' onClick={() => handleClick(ele)}>
                            Mark Paid
                          </button>
                        ) : (
                          <FaCheck className='text-[#023565] ms-6' />
                        )}
                      </p>
                    </div>
                  </div>

                </div>
              ))
            ) : (
              <div className="text-center">
                <p className="px-6 py-4 text-center">
                  Data Not Available
                </p>
              </div>
            )
            }
          </section>
        </div>
      </section>
    </>

  );
};

export default UserPackage;
