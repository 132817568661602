import { termsAndConstant } from "../_constants";

export default function TermsAndCondition(state = {}, action) {
  

  switch (action.type) {
    case termsAndConstant.GET_TERMSANDCONDITION_LIST_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case termsAndConstant.GET_TERMSANDCONDITION_LIST_SUCCESS:
        return {
          ...state,
          loading: false,
          termsAndConditionList: action?.users?.data?.list,
          termsandconditionTotal:action?.users
        };
      case termsAndConstant.GET_TERMSANDCONDITION_LIST_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.error,
        };
        case termsAndConstant.DELETE_TERMSANDCONDITION_LIST_REQUEST:
          return {
            ...state,
            loading: true,
          };
        case termsAndConstant.DELETE_TERMSANDCONDITION_LIST_SUCCESS:
          return {
            ...state,
            loading: false,
          };
        case termsAndConstant.DELETE_TERMSANDCONDITION_LIST_FAILURE:
          return {
            ...state,
            loading: false,
            error: action.error,
          };

          case termsAndConstant.CREATE_TERMSANDCONDITIONT_LIST_REQUEST:
            return {
              ...state,
              loading: true,
            };
          case termsAndConstant.CREATE_TERMSANDCONDITION_LIST_SUCCESS:
            return {
              ...state,
              loading: false,
            };
          case termsAndConstant.CREATE_TERMSANDCONDITION_LIST_FAILURE:
            return {
              ...state,
              loading: false,
              error: action.error,
            };

            case termsAndConstant.UPDATE_TERMSANDCONDITION_LIST_REQUEST:
              return {
                ...state,
                loading: true,
              };
            case termsAndConstant.UPDATE_TERMSANDCONDITION_LIST_SUCCESS:
              return {
                ...state,
                loading: false,
              };
            case termsAndConstant.UPDATE_TERMSANDCONDITION_LIST_FAILURE:
              return {
                ...state,
                loading: false,
                error: action.error,
              };


              case termsAndConstant.ENA_DIS_TERMSANDCONDITION_LIST_REQUEST:
                return {
                  ...state,
                  loading: true,
                };
              case termsAndConstant.ENA_DIS_TERMSANDCONDITION_LIST_SUCCESS:
                return {
                  ...state,
                  loading: false,
                };
              case termsAndConstant.ENA_DIS_TERMSANDCONDITION_LIST_FAILURE:
                return {
                  ...state,
                  loading: false,
                  error: action.error,
                };
      default:
        return state;

  }
}
