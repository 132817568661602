import { tickectConstants } from "../_constants";

export default function Ticket(state={},action){
    switch(action.type){
        case tickectConstants.GET_TICKET_LIST_REQUEST:
            return {
                ...state,
                loading:true
            };
        case tickectConstants.GET_TICKET_LIST_SUCCESS:
            return{
                ...state,
                loading:false,
                getTicketList:action?.users
            }    
        case tickectConstants.GET_TICKET_LIST_FAILURE:
            return{
                ...state,
                loading:false,

            }  

            case tickectConstants.CREATE_TICKET_REPLY_REQUEST:
                return {
                    ...state,
                    loading:true
                };
            case tickectConstants.CREATE_TICKET_REPLY_SUCCESS:
                return{
                    ...state,
                    loading:false,
                    
                }    
            case tickectConstants.CREATE_TICKET_REPLY_FAILURE:
                return{
                    ...state,
                    loading:false,
    
                } 

                case tickectConstants.GET_MSG_TICKET_REPLY_REQUEST:
                    return {
                        ...state,
                        loading:true
                    };
                case tickectConstants.GET_MSG_TICKET_REPLY_SUCCESS:
                    return{
                        ...state,
                        loading:false,
                        ticketReplyList:action?.users
                        
                    }    
                case tickectConstants.GET_MSG_TICKET_REPLY_FAILURE:
                    return{
                        ...state,
                        loading:false,
        
                    } 

                    case tickectConstants.RESOLVED_TICKET_REQUEST:
                        return {
                            ...state,
                            loading:true
                        };
                    case tickectConstants.RESOLVED_TICKET_SUCCESS:
                        return{
                            ...state,
                            loading:false,
                           
                            
                        }    
                    case tickectConstants.RESOLVED_TICKET_FAILURE:
                        return{
                            ...state,
                            loading:false,
            
                        } 
          default: 
          return state    
}
}