/* eslint-disable react-hooks/exhaustive-deps */
import Multiselect from 'multiselect-react-dropdown'
import React, { useEffect, useState } from 'react'
import { MdOutlineClose } from 'react-icons/md'
import { tutortoliveAction, userActions } from '../../../_actions';
import { useDispatch, useSelector } from 'react-redux';
import { tutorUpdateInitialState } from '../../../_helpers/dataInitialState';
import CustomDatePicker from '../../../components/Atoms/CustomDatePicker';
import { dateInGivenFormat } from '../../../_helpers';

const handelTypeValue = (key) => {
    switch (key) {
        case "personality":
            return "Personality"
        case "communicationSkill":
            return "Communication Skill"
        case "subjectKnowledge":
            return "Subject Knowledge"
        default:
            break;
    }
}

const teachModeOption = [
    { _id: "HOME_TUITION", name: "Home Tuition" },
    { _id: "ONLINE_TUITION", name: "Online Tuition" },
];

function uniqueDataFunc(array = []) {
    const set = new Set();
    return array.filter(item => {
        if (set.has(item?._id)) {
            return false;
        } else {
            set.add(item?._id);
            return true;
        }
    });
}





const Star = ({ filled, onClick }) => (
    <svg
        className={`w-4 h-4 ms-1 cursor-pointer ${filled ? 'text-yellow-300' : 'text-gray-300 dark:text-gray-500'}`}
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox="0 0 22 20"
        onClick={onClick}
    >
        <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
    </svg>
);


function AcceptInterView({ closeModal, newAcceptData, acceptModal, setNewAcceptData, currentPage, pageSize, setOldRejectData, selectedTeachMode, setSelectedTeachMode,
    selectedPinCode, setSelectedPinCode,
    subjectYouCanTeach, setSubjectYouCanTeach,
    selectedClass, setSelectedClass }) {

    const [subjectOptions, setSubjectOptions] = useState([])
    const [cityData, setCityData] = useState({
        _id: "",
        name: "",
        pinCode: []
    })

    const [errors, setErrors] = useState({})



    const selector = useSelector(state => state)
    useEffect(() => {
        if (selectedClass && selectedClass.length > 0) {
            const subjectOptions = selectedClass.reduce((acc, ele) => {
                return acc.concat(ele.subjectId);
            }, []);
            setSubjectOptions(subjectOptions);
        } else {
            setSubjectOptions([]);
        }
    }, [JSON.stringify(selectedClass)])



    const dispatch = useDispatch();

    const [rating, setRating] = useState({
        communicationSkill: 0,
        subjectKnowledge: 0,
        personality: 0,
    });

    const handleClick = (ratingValue, type) => {
        setRating((pre) => {
            return {
                ...pre,
                [type]: ratingValue
            }
        })
    };


    const [selectedDate, setSelectedDate] = useState(new Date());

    const handleDateChange = (date) => {
        setSelectedDate(date);

        handelOnChangeInput({ target: { name: "dob", value: String(date) } })

    };

    function ratingSaveFunc(userId) {
        for (const [key, value] of Object.entries(rating)) {
            const data = {
                userId: userId,
                remark: handelTypeValue(key),
                rating: value,
            };
            dispatch(userActions.addUserRatingByAdmin(data));
        }
    }


    const [homeTuition, setHomeTuition] = useState(false);

    useEffect(() => {
        const dataNew = selectedTeachMode?.find((ele) => ele._id === "HOME_TUITION");
        setHomeTuition(!!dataNew); // set to true if found, false otherwise
    }, [selectedTeachMode]);

    const handleTeachModelSelect = (selectedList, selectedItem) => {
        if (selectedItem._id === "HOME_TUITION") {
            setHomeTuition(true);
        }
        setSelectedTeachMode(selectedList);
    };

    const handleRemoveTeachMode = (selectedList, removedItem) => {
        if (removedItem._id === "HOME_TUITION") {
            setHomeTuition(false);
        }
        setSelectedTeachMode(selectedList);
    };

    const handelPinCodeYouCanGoForTeach = (selectedList, selectedItem) => {
        setSelectedPinCode(selectedList);
    };

    const removePinCodeYouCanGoForTeach = (selectedList, removedItem) => {
        setSelectedPinCode(selectedList);
    };

    const handleSubjectYouCanTeach = (selectedList, selectedItem) => {
        setSubjectYouCanTeach(selectedList);
    };

    const handleRemoveSubject = (selectedList, removedItem) => {
        setSubjectYouCanTeach(selectedList);
    };

    const handelSelectClassGoForTeach = (selectedList, selectedItem) => {
        setSelectedClass(selectedList);
    };

    const handleRemoveMultiSelect = (selectedList, removedItem) => {
        setSelectedClass(selectedList);
    };


    const handelOnChangeInput = (e) => {
        const { name, value } = e.target;
        setNewAcceptData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };



    const handleOnSelect = (event) => {
        const selectedCityId = event.target.value;
        const selectedCity = selector?.Citys?.cityGetList.find(city => city._id === selectedCityId);

        if (selectedCity) {
            setCityData({
                _id: selectedCity._id,
                name: selectedCity.name,
                pinCode: selectedCity.pinCode
            });
            setNewAcceptData(prevState => ({
                ...prevState,
                city: { _id: selectedCity._id, name: selectedCity.name },
                pinCode: ""
            }));
            setSelectedPinCode([])
        }
    };

    const [ratingError, setRatingError] = useState()
    function validateRating(rating) {
        const errors = {};

        if (rating.communicationSkill < 1 || rating.communicationSkill > 5) {
            errors.communicationSkill = "Communication Skill rating must be between 1 and 5.";
        }

        if (rating.subjectKnowledge < 1 || rating.subjectKnowledge > 5) {
            errors.subjectKnowledge = "Subject Knowledge rating must be between 1 and 5.";
        }

        if (rating.personality < 1 || rating.personality > 5) {
            errors.personality = "Personality rating must be between 1 and 5.";
        }

        setRatingError(errors)
        return Object.keys(errors).length === 0
    }


    function validateNewAcceptData(newAcceptData, selectedTeachMode, selectedClass, subjectYouCanTeach) {
        const errorsDate = {};

        // Validate email
        if (!newAcceptData?.email) {
            errorsDate.email = "Email is required";
        } else if (!/\S+@\S+\.\S+/.test(newAcceptData.email)) {
            errorsDate.email = "Email is invalid";
        }

        // Validate name
        if (!newAcceptData?.name) {
            errorsDate.name = "Name is required";
        }

        // Validate WhatsApp number
        if (!newAcceptData?.whatsappNo) {
            errorsDate.whatsappNo = "WhatsApp number is required";
        } else if (!/^\d{7,15}$/.test(newAcceptData.whatsappNo)) {
            errorsDate.whatsappNo = "WhatsApp number is invalid";
        }

        // Validate address
        if (!newAcceptData?.address) {
            errorsDate.address = "Address is required";
        }

        // Validate city
        if (!newAcceptData?.city?._id) {
            errorsDate.city = "City is required";
        }

        // Validate pin code
        if (!newAcceptData?.pinCode) {
            errorsDate.pinCode = "Pin code is required";
        } else if (!/^\d{5,6}$/.test(newAcceptData.pinCode)) {
            errorsDate.pinCode = "Pin code is invalid";
        }

        // Validate date of birth (DOB)
        if (!newAcceptData?.dob) {
            errorsDate.dob = "Date of birth is required";
        } else if (isNaN(Date.parse(newAcceptData.dob))) {
            errorsDate.dob = "Date of birth is invalid";
        }

        // Validate gender
        if (!newAcceptData?.gender) {
            errorsDate.gender = "Gender is required";
        }

        // Validate school name
        if (!newAcceptData?.schoolName) {
            errorsDate.schoolName = "School name is required";
        }

        // Validate school pass out year
        if (!newAcceptData?.schoolPassOutYear) {
            errorsDate.schoolPassOutYear = "School pass out year is required";
        } else if (!/^\d{4}$/.test(newAcceptData.schoolPassOutYear)) {
            errorsDate.schoolPassOutYear = "School pass out year is invalid";
        }

        // Validate UG course
        if (!newAcceptData?.ugCourse) {
            errorsDate.ugCourse = "UG course is required";
        }

        // Validate UG college name
        if (!newAcceptData?.ugCollegeName) {
            errorsDate.ugCollegeName = "UG college name is required";
        }

        // Validate UG pass out year
        if (!newAcceptData?.ugPassOutYear) {
            errorsDate.ugPassOutYear = "UG pass out year is required";
        } else if (!/^\d{4}$/.test(newAcceptData.ugPassOutYear)) {
            errorsDate.ugPassOutYear = "UG pass out year is invalid";
        }

        // Validate teaching experience
        if (!newAcceptData?.teachingExp) {
            errorsDate.teachingExp = "Teaching experience is required";
        }

        // Validate teaching mode
        if (!selectedTeachMode || selectedTeachMode.length === 0) {
            errorsDate.teachingMode = "At least one teaching mode is required";
        }

        // Validate classes you can teach
        if (!selectedClass || selectedClass.length === 0) {
            errorsDate.classYouCanTeach = "At least one class you can teach is required";
        }

        // Validate subjects you can teach
        if (!subjectYouCanTeach || subjectYouCanTeach.length === 0) {
            errorsDate.subYouCanTeach = "At least one subject you can teach is required";
        }

        setErrors(errorsDate)
        return Object.keys(errorsDate).length > 0;
    }



    const handleAccept = () => {
        let newJoinedTutorData = {
            status: "NEW_TUTOR",
            keyWord: "",
            fromDate: "",
            toDate: "",
            sortOrder: "",
            sortBy: "createdAt",
            pageNo: currentPage,
            size: pageSize,
        };

        let updatedData = {
            id: newAcceptData?._id,
            email: newAcceptData?.email,
            name: newAcceptData?.name,
            whatsappNo: newAcceptData?.whatsappNo,
            address: newAcceptData?.address,
            city: newAcceptData?.city._id,
            pinCode: newAcceptData?.pinCode,
            dob: new Date(newAcceptData?.dob).toUTCString(),
            gender: newAcceptData?.gender,
            schoolName: newAcceptData?.schoolName,
            schoolPassOutYear: newAcceptData?.schoolPassOutYear,
            ugCourse: newAcceptData?.ugCourse,
            ugCollegeName: newAcceptData?.ugCollegeName,
            ugPassOutYear: newAcceptData?.ugPassOutYear,
            pgCourse: newAcceptData?.pgCourse,
            pgPassOutYear: newAcceptData?.pgPassOutYear,
            pgCollegeName: newAcceptData?.pgCollegeName,
            teachingExp: newAcceptData?.teachingExp,
            teachingMode: selectedTeachMode?.map((ele) => (ele?._id)),
            classYouCanTeach: selectedClass?.map((ele) => ele?._id) || [],
            subYouCanTeach: subjectYouCanTeach?.map((ele) => (ele?._id)) || [],
            pinCodeYouCanGo: []
        };
        const modeCheck = selectedTeachMode?.map((ele) => ele?._id)
        if (modeCheck.includes("HOME_TUITION")) {
            updatedData["pinCodeYouCanGo"] = selectedPinCode?.map((ele) => ele?._id)
        }

        let requiredData = {
            id: newAcceptData._id,
            interviewStatus: 1,
        };



        if (validateNewAcceptData(newAcceptData, selectedTeachMode, selectedClass, subjectYouCanTeach)) {
            return;
        }
        if (!validateRating(rating)) {
            return;
        }

        ratingSaveFunc(newAcceptData?._id)
        setOldRejectData(requiredData);

        dispatch(tutortoliveAction.interviewStatusUpdateByAdmin(requiredData, newJoinedTutorData));
        dispatch(tutortoliveAction.updateTutorByAdmin(updatedData, newJoinedTutorData));
        setRating({
            communicationSkill: 0,
            subjectKnowledge: 0,
            personality: 0,
        });
        closeModal()
    };




    return acceptModal && (
        <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40">
                <div className="relative w-11/12 mx-auto lg:w-[800px]">
                    <button
                        className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center"
                        onClick={() => {
                            closeModal()
                            setRating({
                                communicationSkill: 0,
                                subjectKnowledge: 0,
                                personality: 0,
                            });
                            setRatingError({})
                            setNewAcceptData(tutorUpdateInitialState)
                        }}
                    >
                        <span><MdOutlineClose /></span> Close
                    </button>
                    <div className="form_class border-0 rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        <div className="flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                            <h2 className="text-lg font-semibold">Accept</h2>
                        </div>
                        <div className="relative lg:h-[550px] h-[400px] Pokercardbar overflow-y-auto">
                            <div className="p-4">
                                <div className="grid lg:grid-cols-3 gap-4">
                                    <div className="w-full">
                                        <label className="text-[12px] font-[700] text-left text-[#313131]">
                                            Name:
                                        </label>
                                        <input
                                            type="text"
                                            name="name"
                                            value={newAcceptData?.name}
                                            onChange={handelOnChangeInput}
                                            className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                                        />
                                        {errors?.name && <span className='text-red-600 font-light text-[12px]' > {errors.name} </span>}
                                    </div>
                                    <div className="w-full">
                                        <label className="text-[12px] font-[700] text-left text-[#313131]">
                                            Email ID:{" "}
                                        </label>
                                        <input
                                            type="text"
                                            name="email"
                                            required
                                            value={newAcceptData?.email}
                                            onChange={handelOnChangeInput}
                                            className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                                        />
                                        {errors?.email && <span className='text-red-600 font-light text-[12px]' > {errors.email} </span>}
                                    </div>

                                    <div className="w-full">
                                        <label className="text-[12px] font-[700] text-left text-[#313131]">
                                            WhatsApp No.:{" "}
                                        </label>
                                        <input
                                            type="text"
                                            name="whatsappNo"
                                            value={newAcceptData?.whatsappNo}
                                            onChange={handelOnChangeInput}
                                            className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                                            title="WhatsApp number must be between 7 to 15 digits"
                                        />
                                        {errors?.whatsappNo && <span className='text-red-600 font-light text-[12px]' > {errors.whatsappNo} </span>}

                                    </div>

                                    <div className="w-full">
                                        <label className="text-[12px] font-[700] text-left text-[#313131]">
                                            Address:
                                        </label>

                                        <input
                                            type="text"
                                            name="address"
                                            value={newAcceptData?.address}
                                            onChange={handelOnChangeInput}
                                            className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                                        />
                                        {errors?.address && <span className='text-red-600 font-light text-[12px]' > {errors.address} </span>}
                                    </div>
                                    <div className="w-full">
                                        <label className="text-[12px] font-[700] text-left text-[#313131]">
                                            City:
                                        </label>
                                        <select
                                            name="city"
                                            value={newAcceptData?.city?._id || cityData?._id}
                                            onChange={handleOnSelect}
                                            className="mt-1 p-2.5 border block w-full bg-white border-gray-300 rounded-md capitalize"
                                        >
                                            <option value={null} className="capitalize" > Select city</option>
                                            {selector?.Citys?.cityGetList && selector?.Citys?.cityGetList.length > 0 ? selector?.Citys?.cityGetList.map((city) => (
                                                <option key={city._id} className="capitalize" value={city._id}>{city.name}</option>
                                            )) : null}
                                        </select>
                                        {errors?.city && <span className='text-red-600 font-light text-[12px]' > {errors.city} </span>}
                                    </div>
                                    <div className="w-full">
                                        <label className="text-[12px] font-[700] text-left text-[#313131]">
                                            PinCode:{" "}
                                        </label>
                                        <input
                                            type="text"
                                            name="pinCode"
                                            value={newAcceptData?.pinCode}
                                            onChange={handelOnChangeInput}
                                            className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                                        />
                                        {errors?.pinCode && <span className='text-red-600 font-light text-[12px]' > {errors.pinCode} </span>}
                                    </div>
                                    <div className="w-full">
                                        <label className="text-[12px] font-[700] text-left text-[#313131]">
                                            Date Of Birth:{" "}
                                        </label>
                                        {/* <input
                      type="date"
                      name="dob"
                      onChange={handelOnChangeInput}
                      value={DobFormat(newAcceptData?.dob)}
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    /> */}
                                        <CustomDatePicker selectedDate={selectedDate} value={dateInGivenFormat(newAcceptData?.dob, "DD/MM/YYYY")} onDateChange={handleDateChange} />
                                        {errors?.dob && <span className='text-red-600 font-light text-[12px]' > {errors.dob} </span>}
                                    </div>
                                    <div className="w-full">
                                        <label className="text-[12px] font-[700] text-left text-[#313131]">
                                            Gender:{" "}
                                        </label>
                                        <select
                                            name="gender"
                                            value={newAcceptData?.gender}
                                            onChange={handelOnChangeInput}
                                            className="mt-1 p-2.5 border block w-full bg-white border-gray-300 rounded-md"
                                        >
                                            <option value="">Select gender</option>
                                            <option value="MALE">Male</option>
                                            <option value="FEMALE">Female</option>
                                            <option value="OTHER">Other</option>
                                        </select>
                                        {errors?.gender && <span className='text-red-600 font-light text-[12px]' > {errors.gender} </span>}
                                    </div>

                                    <div className="w-full ">
                                        <label className="text-[12px] font-[700] text-left text-[#313131]">
                                            School Name:{" "}
                                        </label>

                                        <input
                                            type="text"
                                            name="schoolName"
                                            value={newAcceptData?.schoolName}
                                            onChange={handelOnChangeInput}
                                            className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                                        />
                                        {errors?.schoolName && <span className='text-red-600 font-light text-[12px]' > {errors.schoolName} </span>}
                                    </div>

                                    <div className="w-full">

                                        <label className="text-[12px] font-[700] text-left text-[#313131]">
                                            Passout Year:{" "}
                                        </label>
                                        <input
                                            type="number"
                                            name="schoolPassOutYear"
                                            value={newAcceptData?.schoolPassOutYear}
                                            onChange={handelOnChangeInput}
                                            className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                                        />
                                        {errors?.schoolPassOutYear && <span className='text-red-600 font-light text-[12px]' > {errors.schoolPassOutYear} </span>}
                                    </div>
                                    <div className="w-full">
                                        <label className="text-[12px] font-[700] text-left text-[#313131]">
                                            UG Course:{" "}
                                        </label>
                                        <input
                                            type="text"
                                            name="ugCourse"
                                            value={newAcceptData?.ugCourse}
                                            onChange={handelOnChangeInput}
                                            className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                                        />
                                        {errors?.ugCourse && <span className='text-red-600 font-light text-[12px]' > {errors.ugCourse} </span>}
                                    </div>

                                    <div className="w-full">
                                        <label className="text-[12px] font-[700] text-left text-[#313131]">
                                            UG College Name:{" "}
                                        </label>
                                        <input
                                            type="text"
                                            name="ugCollegeName"
                                            value={newAcceptData?.ugCollegeName}
                                            onChange={handelOnChangeInput}
                                            className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                                        />
                                        {errors?.ugCollegeName && <span className='text-red-600 font-light text-[12px]' > {errors.ugCollegeName} </span>}

                                    </div>


                                    <div className="w-full">
                                        <label className="text-[12px] font-[700] text-left text-[#313131]">
                                            Passout Year:{" "}
                                        </label>
                                        <input
                                            type="number"
                                            name="ugPassOutYear"
                                            value={newAcceptData?.ugPassOutYear}
                                            onChange={handelOnChangeInput}
                                            className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                                        />
                                        {errors?.ugPassOutYear && <span className='text-red-600 font-light text-[12px]' > {errors.ugPassOutYear} </span>}

                                    </div>

                                    <div className="w-full">
                                        <label className="text-[12px] font-[700] text-left text-[#313131]">
                                            PG College Name: (Optional){" "}
                                        </label>
                                        <input
                                            type="text"
                                            name="pgCollegeName"
                                            value={newAcceptData?.pgCollegeName}
                                            onChange={handelOnChangeInput}
                                            className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                                        />

                                    </div>
                                    <div className="w-full">
                                        <label className="text-[12px] font-[700] text-left text-[#313131]">
                                            PG Course:(Optional){" "}
                                        </label>
                                        <input
                                            type="text"
                                            name="pgCourse"
                                            value={newAcceptData?.pgCourse}
                                            onChange={handelOnChangeInput}

                                            className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                                        />
                                    </div>

                                    <div className="w-full">
                                        <label className="text-[12px] font-[700] text-left text-[#313131]">
                                            PG Pass Out Year (Optional) :{" "}
                                        </label>
                                        <input
                                            type="number"
                                            name="pgPassOutYear"
                                            value={newAcceptData?.pgPassOutYear}
                                            onChange={handelOnChangeInput}
                                            className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                                        />
                                    </div>

                                    <div className="w-full">
                                        <label className="text-[12px] font-[700] text-left text-[#313131]">
                                            Teaching Experience:
                                        </label>
                                        <input
                                            type="number"
                                            name="teachingExp"
                                            value={newAcceptData?.teachingExp}
                                            onChange={handelOnChangeInput}
                                            className="mt-1 p-2 border block w-full border-gray-300 rounded-md"
                                        />
                                        {errors?.teachingExp && <span className='text-red-600 font-light text-[12px]' > {errors.teachingExp} </span>}

                                    </div>

                                    <div className="w-full">
                                        <label className="text-[12px] font-[700] text-left text-[#313131]">
                                            Selected Classes:
                                        </label>
                                        <Multiselect
                                            options={selector?.Class?.classList}
                                            selectedValues={selectedClass}
                                            onSelect={handelSelectClassGoForTeach}
                                            onRemove={handleRemoveMultiSelect}
                                            displayValue="name"
                                        />
                                        {errors?.classYouCanTeach && <span className='text-red-600 font-light text-[12px]' > {errors.classYouCanTeach} </span>}
                                    </div>


                                    <div className="w-full">
                                        <label className="text-[12px] font-[700] text-left text-[#313131]">
                                            Selected Subject:
                                        </label>
                                        <Multiselect
                                            options={uniqueDataFunc(subjectOptions)}
                                            selectedValues={subjectYouCanTeach}
                                            onSelect={handleSubjectYouCanTeach}
                                            onRemove={handleRemoveSubject}
                                            displayValue="name"
                                        />
                                        {errors?.subYouCanTeach && <span className='text-red-600 font-light text-[12px]' > {errors.subYouCanTeach} </span>}

                                    </div>

                                    {homeTuition && <div className="w-full">
                                        <label className="text-[12px] font-[700] text-left text-[#313131]">
                                            Teaching Pincode:
                                        </label>
                                        <Multiselect
                                            options={cityData.pinCode?.map((ele) => ({ name: ele, _id: ele }))}
                                            selectedValues={selectedPinCode}
                                            onSelect={handelPinCodeYouCanGoForTeach}
                                            onRemove={removePinCodeYouCanGoForTeach}
                                            displayValue="name"
                                        />
                                        {errors?.pinCodeYouCanGo && <span className='text-red-600 font-light text-[12px]' > {errors.pinCodeYouCanGo} </span>}

                                    </div>}

                                    <div className="w-full">
                                        <label className="text-[12px] font-[700] text-left text-[#313131]">
                                            Preferred Teaching Mode:{" "}
                                        </label>
                                        <Multiselect
                                            options={teachModeOption}
                                            selectedValues={selectedTeachMode}
                                            onSelect={handleTeachModelSelect}
                                            onRemove={handleRemoveTeachMode}
                                            displayValue="name"
                                        />
                                        {errors?.teachingMode && <span className='text-red-600 font-light text-[12px]' > {errors.teachingMode} </span>}

                                    </div>

                                </div>
                                <div className="grid lg:grid-cols-3 grid-cols-2 gap-4 py-4">

                                    <div className="w-full space-y-2">
                                        <p className="text-[12px] font-[700] text-left text-[#313131]">
                                            Communication Skills{" "}
                                        </p>
                                        <div className="flex gap-2 items-center">
                                            {Array.from({ length: 5 }, (_, index) => (
                                                <Star
                                                    key={index}
                                                    filled={index < rating.communicationSkill}
                                                    onClick={() => handleClick(index + 1, "communicationSkill")}
                                                />
                                            ))}
                                        </div>
                                        {ratingError?.communicationSkill && <span className='text-red-600 font-light text-[12px]' > {ratingError.communicationSkill} </span>}
                                    </div>


                                    <div className="w-full space-y-2">
                                        <p className="text-[12px] font-[700] text-left text-[#313131]">
                                            Subject Knowledge{" "}
                                        </p>
                                        <div className="flex gap-2 items-center">

                                            {Array.from({ length: 5 }, (_, index) => (
                                                <Star
                                                    key={index}
                                                    filled={index < rating.subjectKnowledge}
                                                    onClick={() => handleClick(index + 1, "subjectKnowledge")}
                                                />
                                            ))}

                                        </div>
                                        {ratingError?.subjectKnowledge && <span className='text-red-600 font-light text-[12px]' > {ratingError.subjectKnowledge} </span>}
                                    </div>

                                    <div className="w-full space-y-2">
                                        <p className="text-[12px] font-[700] text-left text-[#313131]">
                                            Personality{" "}
                                        </p>
                                        <div className="flex gap-2 items-center">

                                            {Array.from({ length: 5 }, (_, index) => (
                                                <Star
                                                    key={index}
                                                    filled={index < rating.personality}
                                                    onClick={() => handleClick(index + 1, "personality")}
                                                />
                                            ))}
                                        </div>
                                        {ratingError?.personality && <span className='text-red-600 font-light text-[12px]' > {ratingError.personality} </span>}
                                    </div>
                                </div>
                                <div className="flex justify-center pt-4">
                                    <button
                                        className="w-60 mx-auto bg-[#023565] text-white font-semibold py-2 px-4 rounded-full "
                                        onClick={() => handleAccept()}
                                    >
                                        Accept
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default AcceptInterView