/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { parentAction } from '../../_actions';
import { useParams } from 'react-router-dom';
import moment from 'moment/moment';
import { IoArrowBack } from "react-icons/io5";



const ParentViewHistory = () => {
  const dispatch = useDispatch()
  const selector = useSelector(state => state)
  let historyData = selector?.Parent?.getLogHistoryList?.data?.list

  const { id } = useParams()

  useEffect(() => {
    let histoyDetails = {
      "userId": id,
      "keyWord": "",
      "pageNo": 1,
      "size": 7
    }
    dispatch(parentAction.getLogHistoryListByUserId(histoyDetails))
  }, [])
  const handleBack = () => {
    window.history.back();
  };

  return (
    <section className='overflow-hidden overflow-y-auto'>
      <div className="flex gap-2">
        <IoArrowBack
          className="text-[#023565] text-3xl border rounded-full border-black my-2 mx-3 cursor-pointer bg-white"
          onClick={handleBack}
        />
      </div>
      <div className='m-5'>
        <div class="relative overflow-x-auto md:block hidden">
          <table className="w-full text-sm text-left rtl:text-right text-black bg-white rounded-md">
            <thead className="">
              <tr className='border-b'>
                <th scope="col" class="px-6 py-3">
                  Admin
                </th>
                <th scope="col" class="px-6 py-3">
                  user
                </th>
                <th scope="col" class="px-6 py-3">
                  activity
                </th>
                <th scope="col" class="px-6 py-3">
                  createdAt
                </th>
              </tr>
            </thead>
            <tbody>
              {
                Array.isArray(historyData) && historyData.length > 0 && historyData?.map((item, id) => (
                  <tr class="border-b capitalize" key={id}>
                    <th scope="row" class="px-6 py-4">
                      {item && item?.isAdmin?.userName ? item?.isAdmin?.userName : "-"}
                    </th>
                    <td class="px-6 py-4">
                      {item && item?.userId?.name ? item?.userId?.name : "-"}
                    </td>
                    <td class="px-6 py-4">
                      {item && item?.activity ? item?.activity : null}
                    </td>
                    <td class="px-6 py-4">
                      {item && item?.createdAt ? moment(item.createdAt).format('DD-MMM-YYYY') : null}
                    </td>

                  </tr>

                ))
              }

            </tbody>
          </table>
        </div>
        <section className=" py-10 space-y-5">
          {
            Array.isArray(historyData) && historyData.length > 0 && historyData?.map((item, id) => (
              <div className='md:hidden block bg-white p-4 rounded-[10px]'>
                <>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[16px] font-[700] text-left capitalize'>  Admin</p>
                    </div>
                    <div className='w-full'>
                      <p className='text-[14px] font-[400] text-end'>{item && item?.isAdmin?.userName ? item?.isAdmin?.userName : "-"}</p>
                    </div>
                  </div>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[16px] font-[700] text-left capitalize'>user</p>
                    </div>
                    <div className='w-full'>
                      <p className='text-[14px] font-[400] text-end'>{item && item?.userId?.name ? item?.userId?.name : "-"}</p>
                    </div>
                  </div>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[16px] font-[700] text-left capitalize'>activity</p>
                    </div>
                    <div className='w-full'>
                      <p className='text-[14px] font-[400] text-end'>{item && item?.activity ? item?.activity : null}</p>
                    </div>
                  </div>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[16px] font-[700] text-left capitalize'>createdAt</p>
                    </div>
                    <div className='w-full'>
                      <p className='text-[14px] font-[400] text-end'> {item && item?.createdAt ? moment(item.createdAt).format('DD-MMM-YYYY') : null}</p>
                    </div>
                  </div>
                </>
              </div>
            ))}
        </section>
      </div>
    </section>
  )
}

export default ParentViewHistory
