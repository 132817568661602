import React from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './CalenderView.module.css';
import CustomToolbar from './CustomToolbar';

const localizer = momentLocalizer(moment);

// Custom Date Cell Component

// Custom Event Component
const CustomEvent = ({ event }) => {
  return (
   
      <div
        style={{
          backgroundColor: 'transparent',
          textAlign: 'center',
          display: "flex",
          alignItems: 'center',
          marginTop: '10px',
        }}
      >
        <div style={{
          backgroundColor: 'transparent',
          color: '#fff',
        }}>{event.title}</div>
      </div>
  );
};


const MyCalendar = ({ events, handleSelectSlot, handleNavigate,dateStampNew }) => {
  // Function to determine if a date has events


  // Function to style the days with events
  const dayPropGetter = (date) => {
    const eventDates = new Set(events.map(event => event.start.toDateString()));
    if (eventDates.has(date.toDateString())) {
      return { className: 'highlight-date', style: { backgroundColor: "#FFFFFF" } };
    }
    return {};
  };

  const handleOnClickSlot = (slotInfo) => {
    handleSelectSlot(slotInfo); // Call the parent handler
  };

  return (
    <>

      <div style={{ height: '70vh', backgroundColor: "#FFFFFF", marginBottom: "20px", padding: "20px" }}>
        <Calendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          selectable={true}
          defaultView="month"
          views={['month']}
          style={{ height: '100%' }}
          dayPropGetter={dayPropGetter}
          onSelectSlot={handleOnClickSlot} // Corrected function name
          components={{
            event: CustomEvent,
            toolbar: CustomToolbar, // Use custom toolbar
          }}
          onNavigate={(date, view) => handleNavigate(date, view)}
          onView={(view) => handleNavigate(null, view)}
          date={dateStampNew}
        />
      </div>
    </>
  );
};

export default MyCalendar;
