/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { tutortoliveAction, userListAction } from "../../_actions";
import { BsThreeDotsVertical } from "react-icons/bs";
import { cityAction } from "../../_actions/city.action";
import moment from "moment/moment";
import "./Tutor.css";
import { FaChevronCircleRight, FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { MdOutlineClose } from "react-icons/md";
import UpdateTutor from "./Model/UpdateTutor";
import { tutorUpdateInitialState } from "../../_helpers/dataInitialState";
import { teachModeViewData } from "../../_helpers";
import Loader from "../../components/Loader";

const BlockedTutors = ({ searchText }) => {
  const dispatch = useDispatch();
  const selector = useSelector((state) => state);
  const [blockedTutorData, setBlockedTutorData] = useState(null)
  const [action, setAction] = useState(null);
  const [history, setHistory] = useState(false);
  const [appliedTutionlistModal, setAppliedTutionListModal] = useState(false);
  const [fullDetailsModal, setfullDetailsModal] = useState(false);
  const [fullDetailsModalData, setfullDetailsModalData] = useState("");

  const [updateModal, setUpdateModal] = useState(false);
  const [selectedTutorName, setSelectedTutorName] = useState({
    updatedCity: "",
    updatedPinCode: "",
  });
  const [cityData, setCityData] = useState([]);
  const [newAcceptData, setNewAcceptData] = useState(tutorUpdateInitialState);
  const [selectedTeachMode, setSelectedTeachMode] = useState([]);
  const [selectedPinCode, setSelectedPinCode] = useState([]);
  const [subjectYouCanTeach, setSubjectYouCanTeach] = useState([]);
  const [selectedClass, setSelectedClass] = useState([]);
  const [acceptModal, setAcceptModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const pageSize = 10;
  const menuRef = useRef(null)

  useEffect(() => {
    let resData = {
      status: "BLOCK",
      "userType": "TUTOR",
      isManagement: false,
      keyWord: searchText ?? "",
      fromDate: "",
      toDate: "",
      sortOrder: "asc",
      sortBy: "createdAt",
      pageNo: currentPage,
      size: pageSize,
    };
    let cityDetails = {
      isManagement: false
    };

    dispatch(userListAction.getUserList(resData));
    dispatch(cityAction.cityGet(cityDetails));
  }, [currentPage]);

  useEffect(() => {
    setCityData(() => selector?.Citys?.cityGetList);
    setBlockedTutorData(selector?.userList?.userList?.data?.demoList || [])
    if (selector?.userList?.userList?.data?.total) {
      setTotalPages(
        Math.ceil(selector?.userList?.userList?.data?.total / pageSize)
      );
    }
  }, [selector]);



  const handleFullDetails = (data) => {
    setfullDetailsModal(true);
    setfullDetailsModalData([data]);
    // setHistory(false)
  };

  const toggleAction = (id) => {
    setAction(action === id ? null : id);
  };

  const showHistory = (resDataId) => {
    setHistory((prevresDataId) =>
      prevresDataId === resDataId ? null : resDataId
    );
  };

  //applied tution List------------------------->

  const handleAppliedTution = (data) => {
    setAppliedTutionListModal(true);

    let appliedData = {
      tutorId: data._id,
    };
    dispatch(userListAction.getAppliedTutionList(appliedData));
  };
  //Demo Done-------------------------------->>>>>>>>>>>>>>
  const handleDemoDone = (data) => {
    let demoDoneData = {
      tutorId: data._id,
    };
    dispatch(userListAction.getDemoDoneList(demoDoneData));
  };

  //Complete tution--------------------------------------->>>>>>>>>>>>>>>>>>>>

  const handleCompleTution = (data) => {
    let completeDemoData = {
      tutorId: data._id,
    };

    dispatch(userListAction.completeTutionList(completeDemoData));
  };

  const handleUnBlock = (data) => {
    let resData = {
      status: "BLOCK",
      "userType": "TUTOR",
      isManagement: false,
      // "userType":"PARENT",
      // 'TUTOR':'PARENT',
      keyWord: searchText ?? "",
      fromDate: "",
      toDate: "",
      sortOrder: "asc",
      sortBy: "createdAt",
      pageNo: currentPage,
      size: pageSize,
    };
    let unBlockTutor = {
      status: "ACTIVE",
      id: data._id,
    };

    dispatch(userListAction.blockByAdmin(unBlockTutor, resData));
    setAction(null);
  };


  const handleUpdateTutor = async (user) => {
    tutortoliveAction.getTutorByPromiseId({
      id: user._id
    }).then(async (data) => {
      let user = data.data
      setNewAcceptData({
        _id: user._id,
        name: user?.name,
        email: user?.email,
        whatsappNo: user?.whatsappNo,
        address: user?.address,
        city: user?.city,
        pinCode: user?.pinCode,
        dob: user?.tutorInfoId?.dob,
        gender: user?.tutorInfoId?.gender,
        schoolName: user?.tutorInfoId?.schoolName,
        schoolPassOutYear: user?.tutorInfoId?.schoolPassOutYear,
        ugCourse: user?.tutorInfoId?.ugCourse,
        ugCollegeName: user?.tutorInfoId?.ugCollegeName,
        ugPassOutYear: user?.tutorInfoId?.ugPassOutYear,
        pgCourse: user?.tutorInfoId?.pgCourse,
        pgCollegeName: user?.tutorInfoId?.pgCollegeName,
        pgPassOutYear: user?.tutorInfoId?.pgPassOutYear,
        teachingExp: user?.tutorInfoId?.teachingExp,
      })
      setSelectedTeachMode(user?.tutorInfoId?.teachingMode.map((ele) => ({ name: teachModeViewData(ele), _id: ele })))
      setSelectedPinCode(user?.tutorInfoId?.pinCodeYouCanGo?.map((ele) => ({ name: ele, _id: ele })) || [])
      setSubjectYouCanTeach(user?.tutorInfoId?.subYouCanTeach)
      setSelectedClass(user?.tutorInfoId?.classYouCanTeach)
      setAcceptModal((prev) => !prev);
    })

  }

  const closeModal = () => {
    setAcceptModal(false);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setAction(null);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };
  return (
    <>
      <Loader loading={selector?.userList?.loading} />

      <section className="lg:block hidden py-10">
        <div className=" border border-[#D3D3D3] rounded-xl overflow-x-auto">
          <table className="min-w-full bg-white rounded-xl">
            <thead className="border-b border-[#D1D1D1]">
              <tr className="text-[#313131]">
                <th className="px-6 py-3 whitespace-nowrap text-left">
                  Tutor Name
                </th>
                <th className="px-6 py-3 whitespace-nowrap text-left">
                  City
                </th>
                <th className="px-6 py-3 whitespace-nowrap text-left">
                  Signup Date
                </th>
                <th className="px-6 py-3 whitespace-nowrap text-left">
                  Blocked On
                </th>
                <th className="px-6 py-3 whitespace-nowrap text-left">
                  Blocked Reason
                </th>
                <th className="px-6 py-3 whitespace-nowrap text-left">
                  History
                </th>
                <th className="px-6 py-3 whitespace-nowrap text-left">
                  Action
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {blockedTutorData &&
                blockedTutorData?.map((itm, id) => (
                  <tr key={id} className="border-b border-[#D1D1D1] capitalize">
                    <td className="flex items-center gap-4 px-6 py-4 text-sm text-gray-900 ">
                      <div className="flex items-center justify-center gap-2">
                        <img
                          className="w-10 h-10 rounded-full"
                          src={
                            itm?.image ??
                            "https://thumbs.dreamstime.com/z/vector-illustration-avatar-dummy-logo-collection-image-icon-stock-isolated-object-set-symbol-web-137160339.jpg?ct=jpeg"
                          }
                          alt="User"
                        />
                        <div className="w-24">
                          <p className="font-medium whitespace-nowrap">{itm?.name ?? "No Data"}</p>
                          <p className="text-gray-500 whitespace-nowrap">{itm?.mobNo ?? "No Contact"}</p>
                        </div>
                      </div>
                      <FaChevronCircleRight
                        className="text-[#023565] text-lg cursor-pointer"
                        onClick={() => handleFullDetails(itm)}
                      />
                    </td>


                    <td className="px-6 py-4   text-gray-900">
                      {itm?.city?.name ?? "No City"}
                    </td>
                    <td className="px-6 py-4   text-gray-900">
                      {itm?.createdAt && moment(itm?.createdAt).format("DD-MMM-YYYY")}
                    </td>
                    <td className="px-6 py-4   text-gray-900">
                      {moment(itm?.blockDate).format("DD-MMM-YYYY")}
                    </td>
                    <td className="px-6 py-4 text-gray-900">
                      {itm?.reason || "---"}
                    </td>
                    <td className="px-6 py-4   text-gray-900 relative" onClick={() => showHistory(itm._id)}>
                      View
                      {history && history === itm._id && (
                        <div className="absolute mt-2 rounded-lg bg-white w-44 border shadow-lg z-50">
                          <ul className="p-2 border text-start   border-black  rounded bg-white z-10">
                            <li
                              className="p-1 cursor-pointer  hover:bg-[#023565]  hover:text-white rounded-full"
                            // onClick={() => handleAppliedTution(itm)}
                            >
                              Applied tution
                            </li>
                            <li
                              className="p-1 cursor-pointer  hover:bg-[#023565]  hover:text-white rounded-full"
                              onClick={() => handleDemoDone(itm)}
                            >
                              Demo Done
                            </li>
                            <li
                              className="p-1 cursor-pointer  hover:bg-[#023565]  hover:text-white rounded-full"
                              onClick={() => handleCompleTution(itm)}
                            >
                              Complete tution
                            </li>
                          </ul>
                        </div>
                      )}
                    </td>
                    <td className="px-6 py-4 text-sm text-center relative text-gray-900 sm:px-4 sm:py-2 forfontsmall">
                      <BsThreeDotsVertical
                        className="mx-auto text-2xl cursor-pointer text-[#C60E6B]"
                        onClick={() => toggleAction(itm._id)}
                      />
                    </td>
                    {action === itm._id && (
                      <div className="absolute right-5 mt-10 rounded-lg bg-white w-44 border shadow-lg z-50 " ref={menuRef}>
                        <ul className=" text-sm font-bold ">
                          <li
                            className="px-4 py-2 text-[#023565] cursor-pointer border-b hover:bg-gray-100"
                            onClick={() => handleUpdateTutor(itm)}
                          >
                            View & Update Info
                          </li>
                          <li
                            className="px-4 py-2 cursor-pointer text-[#023565] hover:bg-gray-100"
                            onClick={() => handleUnBlock(itm)}
                          >
                            Unblock Tutor
                          </li>
                        </ul>
                      </div>
                    )}
                  </tr>
                ))}

            </tbody>
          </table>
        </div>

      </section>
      {totalPages > 1 && (
        <div className="flex justify-end items-center py-2 px-4">
          <button
            className={`px-4 py-2 border text-white bg-[#023565] rounded ${currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""}`}
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
          >
            <FaChevronLeft />
          </button>
          <span className="text-gray-700 mx-1">
            <span className="border px-4 py-2 text-black  rounded">{currentPage}</span> of <span className="border px-4 py-2 text-black  rounded">{totalPages}</span>
          </span>
          <button
            className={`px-4 py-2 border text-white bg-[#023565] rounded ${currentPage === totalPages ? "opacity-50 cursor-not-allowed" : ""}`}
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            <FaChevronRight />
          </button>
        </div>
      )}
      {appliedTutionlistModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="relative w-2/3 bg-white rounded-lg shadow-lg">
            <div className="flex justify-end p-4 border-b border-gray-200">
              <button
                className="cursor-pointer p-2 bg-red-600 text-white rounded"
                onClick={() => setAppliedTutionListModal(false)}
              >
                Close
              </button>
            </div>

            <div className="p-4 max-h-[80vh] overflow-y-auto">
              <table className="min-w-full bg-white">
                <tbody>
                  <div className="border-b hover:bg-gray-100 flex justify-center ">
                    <p className="px-4 py-2 font-bold">Name :-</p>
                  </div>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
      {fullDetailsModal && (


        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40">
          <div className="relative w-11/12 mx-auto lg:w-[800px]">
            <div className="flex justify-end">
              <button
                onClick={() => setfullDetailsModal(false)}
                className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center"
              >
                Close
              </button>
            </div>


            {fullDetailsModal && (
              <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40">
                <div className="relative w-11/12 mx-auto lg:w-[800px]">
                  <div className="flex justify-end">
                    <button
                      onClick={() => setfullDetailsModal(false)}
                      className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center"
                    >
                      <MdOutlineClose /> Close
                    </button>
                  </div>

                  {fullDetailsModalData &&
                    fullDetailsModalData?.map((itm, id) => (

                      <div className="form_class border-0 rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">

                        <div className="flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                          <h2 className="text-lg font-semibold">Tutor Details </h2>
                        </div>

                        <div
                          className="grid md:grid-cols-2 gap-4 p-4"
                          key={id}
                        >
                          <div className="w-full text-center">
                            <img

                              src={
                                itm?.image ??
                                "https://thumbs.dreamstime.com/z/vector-illustration-avatar-dummy-logo-collection-image-icon-stock-isolated-object-set-symbol-web-137160339.jpg?ct=jpeg"
                              }
                              alt="Tutor"
                              className="w-56 h-56 mx-auto "
                            />
                          </div>
                          <div className="w-full capitalize space-y-2">


                            <div className="w-full flex justify-between items-center">
                              <p className="font-semibold w-[40%]">Name</p>
                              <span className="w-[20%]">:</span>
                              <p className="w-[40%]">{itm?.name ?? "N/A"}</p>
                            </div>
                            <div className="w-full flex justify-between items-center">
                              <p className="font-semibold w-[40%]">Mobile No</p>
                              <span className="w-[20%]">:</span>

                              <p className="w-[40%]">{itm?.mobNo ?? "N/A"}</p>
                            </div>
                            <div className="w-full flex justify-between items-center">
                              <p className="font-semibold w-[40%]">Email</p>
                              <span className="w-[20%]">:</span>

                              <p className="w-[40%]">{itm?.email ?? "N/A"}</p>
                            </div>
                            <div className="w-full flex justify-between items-center">
                              <p className="font-semibold w-[40%]">Address</p>
                              <span className="w-[20%]">:</span>

                              <p className="w-[40%]">{itm?.address ?? "N/A"}</p>
                            </div>
                            <div className="w-full flex justify-between items-center">
                              <p className="font-semibold w-[40%]">D O B</p>
                              <span className="w-[20%]">:</span>

                              <p className="w-[40%]">
                                {moment(itm?.tutorInfoId?.dob).format(
                                  "DD-MMM-YYYY"
                                )}
                              </p>
                            </div>
                            <div className="w-full flex justify-between items-center">
                              <p className="font-semibold w-[40%]">School Pass Out</p>
                              <span className="w-[20%]">:</span>

                              <p className="w-[40%]">
                                {itm?.tutorInfoId?.schoolPassOutYear ?? "N/A"}
                              </p>
                            </div>
                            <div className="w-full flex justify-between items-center">
                              <p className="font-semibold w-[40%]">UG Pass Out</p>
                              <span className="w-[20%]">:</span>

                              <p className="w-[40%]">{itm?.tutorInfoId?.ugPassOutYear ?? "N/A"}</p>
                            </div>
                            <div className="w-full flex justify-between items-center">
                              <p className="font-semibold w-[40%]">PG Pass Out</p>
                              <span className="w-[20%]">:</span>

                              <p className="w-[40%]">{itm?.tutorInfoId?.pgPassOutYear ?? "N/A"}</p>
                            </div>


                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            )}
          </div>
        </div>
      )}



      <UpdateTutor
        closeModal={closeModal}
        newAcceptData={newAcceptData}
        acceptModal={acceptModal}
        setNewAcceptData={setNewAcceptData}
        currentPage={currentPage}
        pageSize={pageSize}
        selectedTeachMode={selectedTeachMode}
        setSelectedTeachMode={setSelectedTeachMode}
        selectedPinCode={selectedPinCode}
        setSelectedPinCode={setSelectedPinCode}
        subjectYouCanTeach={subjectYouCanTeach}
        setSubjectYouCanTeach={setSubjectYouCanTeach}
        selectedClass={selectedClass}
        setSelectedClass={setSelectedClass}
        status={"BLOCK"}
      />



      <section className="space-y-5 py-10 lg:hidden block">
        {blockedTutorData && blockedTutorData.length > 0 ? (
          blockedTutorData?.map((itm, id) => (
            <div key={id} className=' bg-white p-4 rounded-[10px]'>
              <>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>Tutor Name</p>
                  </div>
                  <div className='w-full'>
                    <div className="flex items-center  gap-2 capitalize">
                      <img
                        className="w-12 h-12 rounded"
                        src={
                          itm?.image ??
                          "https://thumbs.dreamstime.com/z/vector-illustration-avatar-dummy-logo-collection-image-icon-stock-isolated-object-set-symbol-web-137160339.jpg?ct=jpeg"
                        }
                        alt="User"
                      />
                      <div>
                        <div className="text-xs font-medium text-black ">{itm?.name ?? "No Data"}</div>
                        <div className="text-xs text-gray-500">{itm?.mobNo || 'No Number'}</div>
                        <FaChevronCircleRight
                          className="text-[#023565] cursor-pointer"
                          onClick={() => handleFullDetails(itm)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>City</p>
                  </div>
                  <div className='w-full'>
                    <p className='text-[14px] font-[400]' >{itm?.city?.name ?? "No City"}</p>
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>  Signup Date</p>
                  </div>
                  <div className='w-full'>
                    <p className='text-[14px] font-[400]'>  {moment(itm?.createdAt).format("DD-MMM-YYYY")}</p>
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>Block On </p>
                  </div>
                  <div className='w-full'>
                    <p className='text-[14px] font-[400]'>  {moment(itm?.blockDate).format("DD-MMM-YYYY")}</p>
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>Block Reason</p>
                  </div>
                  <div className='w-full'>
                    <p className='text-[14px] font-[400]'>
                      {itm?.reason ?? "No Resign"}
                    </p>
                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>History</p>
                  </div>
                  <div className='w-full relative'>
                    <span onClick={() => showHistory(itm._id)}>View</span>
                    {history && history === itm._id && (
                      <div
                        className="absolute top-14 right-1 forfontsmall w-44 shadow-lg z-10"
                      // ref={viewRef}
                      >
                        <ul className="bg-white text-sm font-bold rounded-xl border">
                          <li
                            className="px-4 py-2 text-[#C60E6B] cursor-pointer hover:bg-gray-100"
                          // onClick={() => handleAppliedTution(itm)}
                          >
                            Applied Tuitions
                          </li>
                          <li
                            className="px-4 py-2 cursor-pointer text-[#023565] hover:bg-gray-100"
                            onClick={() => handleDemoDone(itm)}
                          >
                            Demo Done
                          </li>
                          <li
                            className="px-4 py-2 cursor-pointer text-[#023565] hover:bg-gray-100"
                            onClick={() => handleCompleTution(itm)}
                          >
                            Completed Tuitions
                          </li>
                        </ul>
                      </div>
                    )}

                  </div>
                </div>
                <div className='flex justify-between p-2 w-full'>
                  <div className='w-full'>
                    <p className='text-[16px] font-[700] text-left'>Action</p>
                  </div>
                  <div className='w-full'>
                    <p className='text-[14px] font-[400] relative'>
                      <BsThreeDotsVertical
                        className="text-2xl cursor-pointer text-[#C60E6B] "
                        onClick={() => toggleAction(itm._id)}
                      />

                      {action && action === itm._id && (
                        <div
                          className="absolute right-50 w-44  bg-white border border-gray-300 rounded-lg shadow-lg z-10"
                        >
                          <ul className=" text-sm font-bold ">
                            <li
                              className="px-4 py-2 text-[#023565] cursor-pointer border-b hover:bg-gray-100"
                              onClick={() => handleUpdateTutor(itm)}
                            >
                              View & Update Info
                            </li>
                            <li
                              className="px-4 py-2 cursor-pointer text-[#023565] hover:bg-gray-100"
                              onClick={() => handleUnBlock(itm)}
                            >
                              Unblock Tutor
                            </li>
                          </ul>
                        </div>
                      )}
                    </p>
                  </div>
                </div>
              </>
            </div>

          ))

        ) : (
          <p className="text-center">Data Not Found</p>
        )

        }
      </section>
    </>
  );
};

export default BlockedTutors;
