export const tutionConstant = {
    GETDEMO_CLASSLIST_BYSTATUS_REQUEST: "GETDEMO_CLASSLIST_BYSTATUS_REQUEST",
    GETDEMO_CLASSLIST_BYSTATUS_SUCCESS: "GETDEMO_CLASSLIST_BYSTATUS_SUCCESS",
    GETDEMO_CLASSLIST_BYSTATUS_FAILURE: 'GETDEMO_CLASSLIST_BYSTATUS_FAILURE',

    GET_DEMO_BYID_REQUEST: "GET_DEMO_BYID_REQUEST",
    GET_DEMO_BYID_SUCCESS: "GET_DEMO_BYID_SUCCESS",
    GET_DEMO_BYID_FAILURE: "GET_DEMO_BYID_FAILURE",

    GET_SUGGESTED_TUTOR_LIST_REQUEST: "GET_SUGGESTED_TUTOR_LIST_REQUEST",
    GET_SUGGESTED_TUTOR_LIST_SUCCESS: "GET_SUGGESTED_TUTOR_LIST_SUCCESS",
    GET_SUGGESTED_TUTOR_LIST_FAILURE: "GET_SUGGESTED_TUTOR_LIST_FAILURE",

    UPDATE_STATUS_REQUEST: "UPDATE_STATUS_REQUEST",
    UPDATE_STATUS_SUCCESS: "UPDATE_STATUS_SUCCESS",
    UPDATE_STATUS_FAILURE: "UPDATE_STATUS_FAILURE",

    GET_CREDIT_BASE_DEMO_CLASS_LIST_BY_STATUS_REQUEST: "GET_CREDIT_BASE_DEMO_CLASS_LIST_BY_STATUS_REQUEST",
    GET_CREDIT_BASE_DEMO_CLASS_LIST_BY_STATUS_SUCCESS: "GET_CREDIT_BASE_DEMO_CLASS_LIST_BY_STATUS_SUCCESS",
    GET_CREDIT_BASE_DEMO_CLASS_LIST_BY_STATUS_FAILURE: "GET_CREDIT_BASE_DEMO_CLASS_LIST_BY_STATUS_FAILURE",

    REVERT_DEMO_CLASS_REQUEST: "REVERT_DEMO_CLASS_REQUEST",
    REVERT_DEMO_CLASS_SUCCESS: "REVERT_DEMO_CLASS_SUCCESS",
    REVERT_DEMO_CLASS_FAILURE: "REVERT_DEMO_CLASS_FAILURE",

    GET_DEMO_CLASSLIST_BY_STATUS_1_REQUEST: "GET_DEMO_CLASSLIST_BY_STATUS_1_REQUEST",
    GET_DEMO_CLASSLIST_BY_STATUS_1_SUCCESS: "GET_DEMO_CLASSLIST_BY_STATUS_1_SUCCESS",
    GET_DEMO_CLASSLIST_BY_STATUS_1_FAILURE: "GET_DEMO_CLASSLIST_BY_STATUS_1_FAILURE",

    ASSIGN_TUTOR_BYADMIN_REQUEST: "ASSIGN_TUTOR_BYADMIN_REQUEST",
    ASSIGN_TUTOR_BYADMIN_SUCCESS: "ASSIGN_TUTOR_BYADMIN_SUCCESS",
    ASSIGN_TUTOR_BYADMIN_FAILURE: "ASSIGN_TUTOR_BYADMIN_FAILURE",

    CREATE_COPY_BYADMIN_REQUEST: "CREATE_COPY_BYADMIN_REQUEST",
    CREATE_COPY_BYADMIN_SUCCESS: "CREATE_COPY_BYADMIN_SUCCESS",
    CREATE_COPY_BYADMIN_FAILURE: "CREATE_COPY_BYADMIN_FAILURE",
    // CREATE_COPY_BYADMIN_REQUEST:"CREATE_COPY_BYADMIN_REQUEST",
    // CREATE_COPY_BYADMIN_SUCCESS:"CREATE_COPY_BYADMIN_SUCCESS",
    // CREATE_COPY_BYADMIN_FAILURE:"CREATE_COPY_BYADMIN_FAILURE",


    DEMO_DONE_BYADMIN_REQUEST: "DEMO_DONE_BYADMIN_REQUEST",
    DEMO_DONE_BYADMIN_SUCCESS: "DEMO_DONE_BYADMIN_SUCCESS",
    DEMO_DONE_BYADMIN_FAILURE: "DEMO_DONE_BYADMIN_FAILURE",

    TUITION_REMOVE_HOLD_BY_ADMIN_REQUEST: "TUITION_REMOVE_HOLD_BY_ADMIN_REQUEST",
    TUITION_REMOVE_HOLD_BY_ADMIN_SUCCESS: "TUITION_REMOVE_HOLD_BY_ADMIN_SUCCESS",
    TUITION_REMOVE_HOLD_BY_ADMIN_FAILURE: "TUITION_REMOVE_HOLD_BY_ADMIN_FAILURE",

    CHANGE_TUTOR_BY_ADMIN_REQUEST: "CHANGE_TUTOR_BY_ADMIN_REQUEST",
    CHANGE_TUTOR_BY_ADMIN_SUCCESS: "CHANGE_TUTOR_BY_ADMIN_SUCCESS",
    CHANGE_TUTOR_BY_ADMIN_FAILURE: "CHANGE_TUTOR_BY_ADMIN_FAILURE",

    GET_LOG_HISTORY_LIST_REQUEST: 'GET_LOG_HISTORY_LIST_REQUEST',
    GET_LOG_HISTORY_LIST_SUCCESS: 'GET_LOG_HISTORY_LIST_SUCCESS',
    GET_LOG_HISTORY_LIST_FAILURE: 'GET_LOG_HISTORY_LIST_FAILURE',

    TUITION_HOLD_BY_ADMIN_REQUEST: 'TUITION_HOLD_BY_ADMIN_REQUEST',
    TUITION_HOLD_BY_ADMIN_SUCCESS: 'TUITION_HOLD_BY_ADMIN_SUCCESS',
    TUITION_HOLD_BY_ADMIN_FAILURE: 'TUITION_HOLD_BY_ADMIN_FAILURE',

    TUITION_CLOSE_BY_ADMIN_REQUEST: 'TUITION_CLOSE_BY_ADMIN_REQUEST',
    TUITION_CLOSE_BY_ADMIN_SUCCESS: 'TUITION_CLOSE_BY_ADMIN_SUCCESS',
    TUITION_CLOSE_BY_ADMIN_FAILURE: 'TUITION_CLOSE_BY_ADMIN_FAILURE',

    UPDATE_TUITION_MODE_REQUEST: "UPDATE_TUITION_MODE_REQUEST",
    UPDATE_TUITION_MODE_SUCCESS: "UPDATE_TUITION_MODE_SUCCESS",
    UPDATE_TUITION_MODE_FAILURE: "UPDATE_TUITION_MODE_FAILURE",

    ACTIVE_DEMO_LIST_BY_PERENTID_REQUEST: "ACTIVE_DEMO_LIST_BY_PERENTID_REQUEST",
    ACTIVE_DEMO_LIST_BY_PERENTID_SUCCESS: "ACTIVE_DEMO_LIST_BY_PERENTID_SUCCESS",
    ACTIVE_DEMO_LIST_BY_PERENTID_FAILURE: "ACTIVE_DEMO_LIST_BY_PERENTID_FAILURE",

    CREATE_TUITION_REQUEST: "CREATE_TUITION_REQUEST",
    CREATE_TUITION_SUCCESS: "CREATE_TUITION_SUCCESS",
    CREATE_TUITION_FAILURE: "CREATE_TUITION_FAILURE",

    GET_ALL_TUTOR_LIST_REQUEST: "GET_ALL_TUTOR_LIST_REQUEST",
    GET_ALL_TUTOR_LIST_SUCCESS: "GET_ALL_TUTOR_LIST_SUCCESS",
    GET_ALL_TUTOR_LIST_FAILURE: "GET_ALL_TUTOR_LIST_FAILURE",

    GET_ALL_PARENT_LIST_REQUEST: "GET_ALL_PARENT_LIST_REQUEST",
    GET_ALL_PARENT_LIST_SUCCESS: "GET_ALL_PARENT_LIST_SUCCESS",
    GET_ALL_PARENT_LIST_FAILURE: "GET_ALL_PARENT_LIST_FAILURE",

    GET_STUDENT_LIST_BY_PARENT_ID_REQUEST: "GET_STUDENT_LIST_BY_PARENT_ID_REQUEST",
    GET_STUDENT_LIST_BY_PARENT_ID_SUCCESS: "GET_STUDENT_LIST_BY_PARENT_ID_SUCCESS",
    GET_STUDENT_LIST_BY_PARENT_ID_FAILURE: "GET_STUDENT_LIST_BY_PARENT_ID_FAILURE",

    UPATE_DEMO_INFO_REQUEST: "UPATE_DEMO_INFO_REQUEST",
    UPATE_DEMO_INFO_SUCCESS: "UPATE_DEMO_INFO_SUCCESS",
    UPATE_DEMO_INFO_FAILURE: "UPATE_DEMO_INFO_FAILURE",

    GET_ALL_CITY_LIST_REQUEST: "GET_ALL_CITY_LIST_REQUEST",
    GET_ALL_CITY_LIST_SUCCESS: "GET_ALL_CITY_LIST_SUCCESS",
    GET_ALL_CITY_LIST_FAILURE: "GET_ALL_CITY_LIST_FAILURE",

    GET_CITY_PINCODE_LIST_REQUEST: "GET_CITY_PINCODE_LIST_REQUEST",
    GET_CITY_PINCODE_LIST_SUCCESS: "GET_CITY_PINCODE_LIST_SUCCESS",
    GET_CITY_PINCODE_LIST_FAILURE: "GET_CITY_PINCODE_LIST_FAILURE",

    CREATE_DEMO_BY_ADMIN_REQUEST: "CREATE_DEMO_BY_ADMIN_REQUEST",
    CREATE_DEMO_BY_ADMIN_SUCCESS: "CREATE_DEMO_BY_ADMIN_SUCCESS",
    CREATE_DEMO_BY_ADMIN_FAILURE: "CREATE_DEMO_BY_ADMIN_FAILURE",

    GET_PARENT_ID_GET_PIN_CODE_REQUEST: "GET_PARENT_ID_GET_PIN_CODE_REQUEST",
    GET_PARENT_ID_GET_PIN_CODE_SUCCESS: "GET_PARENT_ID_GET_PIN_CODE_SUCCESS",
    GET_PARENT_ID_GET_PIN_CODE_FAILURE: "GET_PARENT_ID_GET_PIN_CODE_FAILURE",

    GET_ALL_CLASS_LIST_BY_ADMIN_REQUEST: "GET_ALL_CLASS_LIST_BY_ADMIN_REQUEST",
    GET_ALL_CLASS_LIST_BY_ADMIN_SUCCESS: "GET_ALL_CLASS_LIST_BY_ADMIN_SUCCESS",
    GET_ALL_CLASS_LIST_BY_ADMIN_FAILURE: "GET_ALL_CLASS_LIST_BY_ADMIN_FAILURE",

    GET_TUITION_COUNT_REQUEST: "GET_TUITION_COUNT_REQUEST",
    GET_TUITION_COUNT_SUCCESS: "GET_TUITION_COUNT_SUCCESS",
    GET_TUITION_COUNT_FAILURE: "GET_TUITION_COUNT_FAILURE",


    GET_ALL_BOARD_LIST_BY_ADMIN_REQUEST: "GET_ALL_BOARD_LIST_BY_ADMIN_REQUEST",
    GET_ALL_BOARD_LIST_BY_ADMIN_SUCCESS: "GET_ALL_BOARD_LIST_BY_ADMIN_SUCCESS",
    GET_ALL_BOARD_LIST_BY_ADMIN_FAILURE: "GET_ALL_BOARD_LIST_BY_ADMIN_FAILURE",


    GET_ONGOING_TUITION_LIST_REQUEST: "GET_ONGOING_TUITION_LIST_REQUEST",
    GET_ONGOING_TUITION_LIST_SUCCESS: "GET_ONGOING_TUITION_LIST_SUCCESS",
    GET_ONGOING_TUITION_LIST_FAILURE: "GET_ONGOING_TUITION_LIST_FAILURE",

    TUITION_DENIE_BYADMIN_REQUEST: "TUITION_DENIE_BYADMIN_REQUEST",
    TUITION_DENIE_BYADMIN_SUCCESS: "TUITION_DENIE_BYADMIN_SUCCESS",
    TUITION_DENIE_BYADMIN_FAILURE: "TUITION_DENIE_BYADMIN_FAILURE",

    ADD_REMARK_REQUEST: "ADD_REMARK_REQUEST",
    ADD_REMARK_SUCCESS: "ADD_REMARK_SUCCESS",
    ADD_REMARK_FAILURE: "ADD_REMARK_FAILURE",

    GET_TUTOR_DETAILS_REQUEST: "GET_TUTOR_DETAILS_REQUEST",
    GET_TUTOR_DETAILS_SUCCESS: "GET_TUTOR_DETAILS_SUCCESS",
    GET_TUTOR_DETAILS_FAILURE: "GET_TUTOR_DETAILS_FAILURE",


    UPDATE_INFO_ACTIVE_ON_APP_REQUEST: 'UPDATE_INFO_ACTIVE_ON_APP_REQUEST',
    UPDATE_INFO_ACTIVE_ON_APP_SUCCESS: 'UPDATE_INFO_ACTIVE_ON_APP_SUCCESS',
    UPDATE_INFO_ACTIVE_ON_APP_FAILURE: 'UPDATE_INFO_ACTIVE_ON_APP_FAILURE',


    GET_TUITION_TUTOR_CHANGE_REQUEST: "GET_TUITION_TUTOR_CHANGE_REQUEST",
    GET_TUITION_TUTOR_CHANGE_SUCCESS: "GET_TUITION_TUTOR_CHANGE_SUCCESS",
    GET_TUITION_TUTOR_CHANGE_FAILURE: "GET_TUITION_TUTOR_CHANGE_FAILURE",

    CLASS_REFERENCE_LIST_REQUEST: "CLASS_REFERENCE_LIST_REQUEST",
    CLASS_REFERENCE_LIST_SUCCESS: "CLASS_REFERENCE_LIST_SUCCESS",
    CLASS_REFERENCE_LIST_FAILURE: "CLASS_REFERENCE_LIST_FAILURE",

    GET_ALL_LEAD_MANAGER_REQUEST: "GET_ALL_LEAD_MANAGER_REQUEST",
    GET_ALL_LEAD_MANAGER_SUCCESS: "GET_ALL_LEAD_MANAGER_SUCCESS",
    GET_ALL_LEAD_MANAGER_FAILURE: "GET_ALL_LEAD_MANAGER_FAILURE",

    GET_STUDENT_LIST_BY_PARENT_ID_FOR_DEMO_REQUEST: "GET_STUDENT_LIST_BY_PARENT_ID_FOR_DEMO_REQUEST",
    GET_STUDENT_LIST_BY_PARENT_ID_FOR_DEMO_SUCCESS: "GET_STUDENT_LIST_BY_PARENT_ID_FOR_DEMO_SUCCESS",
    GET_STUDENT_LIST_BY_PARENT_ID_FOR_DEMO_FAILURE: "GET_STUDENT_LIST_BY_PARENT_ID_FOR_DEMO_FAILURE",

    DENIED_CHANGE_TUTOR_REQUEST: "DENIED_CHANGE_TUTOR_REQUEST",
    DENIED_CHANGE_TUTOR_SUCCESS: "DENIED_CHANGE_TUTOR_SUCCESS",
    DENIED_CHANGE_TUTOR_FAILURE: "DENIED_CHANGE_TUTOR_FAILURE",




}