export const userpackageConstant = {
    GET_USER_PACKAGE_LIST_REQUEST: "GET_USER_PACKAGE_LIST_REQUEST",
    GET_USER_PACKAGE_LIST_SUCCESS: "GET_USER_PACKAGE_LIST_SUCCESS",
    GET_USER_PACKAGE_LIST_FAILURE: "GET_USER_PACKAGE_LIST_FAILURE",

    USER_PACKAGE_PURCHASED_BY_ADMIN_REQUEST: "USER_PACKAGE_PURCHASED_BY_ADMIN_REQUEST",
    USER_PACKAGE_PURCHASED_BY_ADMIN_SUCCESS: "USER_PACKAGE_PURCHASED_BY_ADMIN_SUCCESS",
    USER_PACKAGE_PURCHASED_BY_ADMIN_FAILURE: "USER_PACKAGE_PURCHASED_BY_ADMIN_FAILURE",

    // ADD_USER_PACKAGE_REQUEST:"ADD_USER_PACKAGE_REQUEST",
    // ADD_USER_PACKAGE_SUCCESS:"ADD_USER_PACKAGE_SUCCESS",
    // ADD_USER_PACKAGE_FAILURE:"ADD_USER_PACKAGE_FAILURE",

    // UPDATE_USER_PACKAGE_REQUEST:"UPDATE_USER_PACKAGE_REQUEST",
    // UPDATE_USER_PACKAGE_SUCCESS:"UPDATE_USER_PACKAGE_SUCCESS",
    // UPDATE_USER_PACKAGE_FAILURE:"UPDATE_USER_PACKAGE_FAILURE",

    // DELETE_USER_PACKAGE_REQUEST:"DELETE_USER_PACKAGE_REQUEST",
    // DELETE_USER_PACKAGE_SUCCESS:"DELETE_USER_PACKAGE_SUCCESS",
    // DELETE

}