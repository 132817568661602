import React from 'react';

function Button({ name, onClick, value = 0 }) {
  return (
    <div>
      <button className='px-3' onClick={onClick}>
        {name} {value ? `(${value})` : null}
      </button>
    </div>
  );
}

export default Button;
