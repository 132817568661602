export const parentConstant = {
    GET_USERLIST_REQUEST: "GET_USERLIST_REQUEST",
    GET_USERLIST_SUCCESS: "GET_USERLIST_SUCCESS",
    GET_USERLIST_FAILURE: "GET_USERLIST_FAILURE",

    GET_ONGOING_TUTION_LIST_BYID_REQUEST: "GET_ONGOING_TUTION_LIST_BYID_REQUEST",
    GET_ONGOING_TUTION_LIST_BYID_SUCCESS: "GET_ONGOING_TUTION_LIST_BYID_SUCCESS",
    GET_ONGOING_TUTION_LIST_BYID_FAILURE: "GET_ONGOING_TUTION_LIST_BYID_FAILURE",


    GET_STUDENT_LIST_BY_PARENTID_REQUEST: "GET_STUDENT_LIST_BY_PARENTID_REQUEST",
    GET_STUDENT_LIST_BY_PARENTID_SUCCESS: "GET_STUDENT_LIST_BY_PARENTID_SUCCESS",
    GET_STUDENT_LIST_BY_PARENTID_FAILURE: "GET_STUDENT_LIST_BY_PARENTID_FAILURE",

    GET_FEE_HISTORY_BYUSERID_REQUEST: "GET_FEE_HISTORY_BYUSERID_REQUEST",
    GET_FEE_HISTORY_BYUSERID_SUCCESS: "GET_FEE_HISTORY_BYUSERID_SUCCESS",
    GET_FEE_HISTORY_BYUSERID_FAILURE: "GET_FEE_HISTORY_BYUSERID_FAILURE",

    BLOCK_BY_ADMIN_REQUEST: "BLOCK_BY_ADMIN_REQUEST",
    BLOCK_BY_ADMIN_SUCCESS: "BLOCK_BY_ADMIN_SUCCESS",
    BLOCK_BY_ADMIN_FAILURE: "BLOCK_BY_ADMIN_FAILURE",

    UPDATE_PARENT_BYADMIN_REQUEST: "UPDATE_PARENT_BYADMIN_REQUEST",
    UPDATE_PARENT_BYADMIN_SUCCESS: "UPDATE_PARENT_BYADMIN_SUCCESS",
    UPDATE_PARENT_BYADMIN_FAILURE: "UPDATE_PARENT_BYADMIN_FAILURE",

    GET_LOG_HISTORY_BYID_REQUEST: "GET_LOG_HISTORY_BYID_REQUEST",
    GET_LOG_HISTORY_BYID_SUCCESS: "GET_LOG_HISTORY_BYID_SUCCESS",
    GET_LOG_HISTORY_BYID_FAILURE: "GET_LOG_HISTORY_BYID_FAILURE",

    GET_STUDENT_LIST_BY_PARENT_ID_USER_REQUEST: "GET_STUDENT_LIST_BY_PARENT_ID_USER_REQUEST",
    GET_STUDENT_LIST_BY_PARENT_ID_USER_SUCCESS: "GET_STUDENT_LIST_BY_PARENT_ID_USER_SUCCESS",
    GET_STUDENT_LIST_BY_PARENT_ID_USER_FAILURE: "GET_STUDENT_LIST_BY_PARENT_ID_USER_FAILURE",
}