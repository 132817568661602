export const userListConstants = {
    GET_USERLIST_REQUEST: "GET_USERLIST_REQUEST",
    GET_USERLIST_SUCCESS: "GET_USERLIST_SUCCESS",
    GET_USERLIST_FAILURE: "GET_USERLIST_FAILURE",

    GETKYC_BYUSERID_REQUEST: "GETKYC_BYUSERID_REQUEST",
    GETKYC_BYUSERID_SUCCESS: "GETKYC_BYUSERID_SUCCESS",
    GETKYC_BYUSERID_FAILURE: "GETKYC_BYUSERID_FAILURE",

    GET_ONGOING_TUTION_LIST_BYID_REQUEST: "GET_ONGOING_TUTION_LIST_BYID_REQUEST",
    GET_ONGOING_TUTION_LIST_BYID_SUCCESS: "GET_ONGOING_TUTION_LIST_BYID_SUCCESS",
    GET_ONGOING_TUTION_LIST_BYID_FAILURE: "GET_ONGOING_TUTION_LIST_BYID_FAILURE",

    GET_FREE_HISTORY_BYUSERID_REQUEST: "GET_FREE_HISTORY_BYUSERID_REQUEST",
    GET_FREE_HISTORY_BYUSERID_SUCCESS: "GET_FREE_HISTORY_BYUSERID_SUCCESS",
    GET_FREE_HISTORY_BYUSERID_FAILURE: "GET_FREE_HISTORY_BYUSERID_FAILURE",

    GET_RATING_BYUSERID_REQUEST: "GET_RATING_BYUSERID_REQUEST",
    GET_RATING_BYUSERID_SUCCESS: "GET_RATING_BYUSERID_SUCCESS",
    GET_RATING_BYUSERID_FAILURE: "GET_RATING_BYUSERID_FAILURE",

    BLOCK_BY_ADMIN_REQUEST: "BLOCK_BY_ADMIN_REQUEST",
    BLOCK_BY_ADMIN_SUCCESS: "BLOCK_BY_ADMIN_SUCCESS",
    BLOCK_BY_ADMIN_FAILURE: "BLOCK_BY_ADMIN_FAILURE",

    GET_APPLIED_TUTION_LIST_REQUEST: "GET_APPLIED_TUTION_LIST_REQUEST",
    GET_APPLIED_TUTION_LIST_SUCCESS: "GET_APPLIED_TUTION_LIST_SUCCESS",
    GET_APPLIED_TUTION_LIST_FAILURE: "GET_APPLIED_TUTION_LIST_FAILURE",

    GET_DEMO_DONELIST_REQUEST: "GET_DEMO_DONELIST_REQUEST",
    GET_DEMO_DONELIST_SUCCESS: "GET_DEMO_DONELIST_SUCCESS",
    GET_DEMO_DONELIST_FAILURE: "GET_DEMO_DONELIST_FAILURE",

    GET_COMPLETE_TUTION_LIST_REQUEST: "GET_COMPLETE_TUTION_LIST_REQUEST",
    GET_COMPLETE_TUTION_LIST_SUCCESS: "GET_COMPLETE_TUTION_LIST_SUCCESS",
    GET_COMPLETE_TUTION_LIST_FAILURE: "GET_COMPLETE_TUTION_LIST_FAILURE",

    KYC_REMINDER_REQUEST: "KYC_REMINDER_REQUEST",
    KYC_REMINDER_SUCCESS: "KYC_REMINDER_SUCCESS",
    KYC_REMINDER_FAILURE: "KYC_REMINDER_FAILURE",

    PACKAGE_REMINDER_REQUEST: "PACKAGE_REMINDER_REQUEST",
    PACKAGE_REMINDER_SUCCESS: "PACKAGE_REMINDER_SUCCESS",
    PACKAGE_REMINDER_FAILURE: "PACKAGE_REMINDER_FAILURE",

    UPDATE_TUTOR_BYADMIN_REQUEST: 'UPDATE_TUTOR_BYADMIN_REQUEST',
    UPDATE_TUTOR_BYADMIN_SUCCESS: 'UPDATE_TUTOR_BYADMIN_SUCCESS',
    UPDATE_TUTOR_BYADMIN_FAILURE: 'UPDATE_TUTOR_BYADMIN_FAILURE',


    GET_USER_TYPE_REQUEST: "GET_USER_TYPE_REQUEST",
    GET_USER_TYPE_SUCCESS: "GET_USER_TYPE_SUCCESS",
    GET_USER_TYPE_FAILURE: "GET_USER_TYPE_FAILURE",

    GET_USER_TYPE_REQUEST2: "GET_USER_TYPE_REQUEST2",
    GET_USER_TYPE_SUCCESS2: "GET_USER_TYPE_SUCCESS2",
    GET_USER_TYPE_FAILURE2: "GET_USER_TYPE_FAILURE2",


    GET_ALL_SUBJECT_LIST_REQUEST: "GET_ALL_SUBJECT_LIST_REQUEST",
    GET_ALL_SUBJECT_LIST_SUCCESS: "GET_ALL_SUBJECT_LIST_SUCCESS",
    GET_ALL_SUBJECT_LIST_FAILURE: "GET_ALL_SUBJECT_LIST_FAILURE",



    GET_UPDATE_TUTOR_REQUEST: "GET_UPDATE_TUTOR_REQUEST",
    GET_UPDATE_TUTOR_SUCCESS: "GET_UPDATE_TUTOR_SUCCESS",
    GET_UPDATE_TUTOR_FAILURE: "GET_UPDATE_TUTOR_FAILURE",

    GET_ALLTUTORLIST_WITH_NAMEORID_REQUEST: "GET_ALLTUTORLIST_WITH_NAMEORID_REQUEST",
    GET_ALLTUTORLIST_WITH_NAMEORID_SUCCESS: "GET_ALLTUTORLIST_WITH_NAMEORID_SUCCESS",
    GET_ALLTUTORLIST_WITH_NAMEORID_FAILURE: "GET_ALLTUTORLIST_WITH_NAMEORID_FAILURE",



}