import axios from 'axios';
import moment from 'moment-timezone';

 export let backendUrl = `https://lms.bookmytutor.com/api`
// export let backendUrl = `http://192.168.29.58:8052/api`

// let backendUrl = `https://bmt2.jamsara.com/api`
// export let backendUrl = `http://localhost:8052/api`


export function authHeader() {
    let user = JSON.parse(window.sessionStorage.getItem('adminuser'));
    if (user && user.token) {
        return { 'Authorization': 'Bearer ' + user.token }; // returning an object
    } else {
        return {}; // returning an empty object if no user or token
    }
}


export function logoutFunction() {
    window.sessionStorage.removeItem('adminuser');
    window.location.replace('/login');
    window.location.reload()
}

export const headerForPublicAPI = () => {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    return headers;
};



export const headerForPrivateAPI = () => {
    const token = authHeader()['Authorization']; // Accessing Authorization property of the object returned by authHeader()
    const headers = new Headers();
    if (token) {
        headers.append('Authorization', token); // Adding Authorization header if token exists
    }
    headers.append('Content-Type', 'application/json'); // Always add Content-Type
    return headers;
}



export const headerForPrivateMediaAPI = () => {
    const token = authHeader()['Authorization']; // Accessing Authorization property of the object returned by authHeader()
    const headers = new Headers();
    if (token) {
        headers.append('Authorization', token); // Adding Authorization header if token exists
    }
    headers.append('Content-Type', 'multipart/form-data'); // Always add Content-Type
    return headers;
}



export const APIcallFunction = async (credentials) => {
    const requestOptions = {
        method: credentials.method,
        headers: credentials.header,
        body: JSON.stringify(credentials.body)
    };
    try {
        const response = await fetch(`${backendUrl}/v1/${credentials.endPoint}`, requestOptions);

        const responseData = await handleResponse(response);
        return {
            data: responseData.data
        };
    } catch (error) {
        throw error;
    }
};


export const apiCallFunctionSecond = async (credentials) => {
    const requestOptions = {
        method: credentials.method,
        headers: credentials.header,
        body: JSON.stringify(credentials.body)
    };
    try {
        const response = await fetch(`${backendUrl}/v1/${credentials.endPoint}`, requestOptions);

        const responseData = await handleResponse(response);
        return {
            data: responseData.data
        };
    } catch (error) {
        throw error;
    }
};

export const APIcallFunctionForFile = async (credentials) => {
    try {
        const response = await axios.post(`${backendUrl}/v1/${credentials.endPoint}`, credentials.body, {
            headers: {
                'Authorization': authHeader().Authorization,
                'Content-Type': 'multipart/form-data'
            }
        });
        const responseData = response.data.data;
        return {
            data: responseData
        };
    } catch (error) {
        throw error;
    }
};







function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (data.code === 1403) {
            // window.location.href = `${window.location.origin}/invalid-access`
        }
        if (!response.ok) {
            if (response.status === 401) {
                // logoutFunction();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        if (data.error) {
            if (data.code === 3) {
                // logoutFunction();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}


export const downloadCSV = (csv) => {
    // const csv = convertToCSV(allFeeData);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'data.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};


export const timeZoneReturnDateType = (stamp) => {
    const guessedTimezone = moment.tz.guess();
    return stamp ? moment(stamp).tz(guessedTimezone).format('DD-MMM-YYYY ')
        : "--/--/--";
};

export const timeStanderFollowFunction = (time) => {
    if (!time) {
        return "Invalid time";
    }

    // Trim leading and trailing whitespace
    let trimmedTime = time.trim();

    // Handle common incorrect formats
    const incorrectPatterns = [
        { pattern: /(\d{1,2})([APap][Mm])/g, replacement: '$1:00 $2' }, // e.g., "6pm" -> "6:00 PM"
        { pattern: /(\d{1,2})\s*:\s*(\d{2})\s*([APap][Mm])/g, replacement: '$1:$2 $3' }, // e.g., "10 : 20am" -> "10:20 AM"
    ];

    incorrectPatterns.forEach(({ pattern, replacement }) => {
        trimmedTime = trimmedTime.replace(pattern, replacement);
    });
    const timePattern = /^(\d{1,2})\s*:\s*(\d{2})\s*(AM|PM|am|pm)?$/i;
    const match = trimmedTime.match(timePattern);

    if (!match) {
        return "Invalid time";
    }

    let [, hours, minutes, period] = match;
    hours = parseInt(hours, 10);
    minutes = minutes.padStart(2, '0');

    // If period is not provided, assume 24-hour format
    // If period is not provided, assume 24-hour format
    if (!period) {
        period = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12 || 12; // Convert to 12-hour format
    } else {
        period = period.toUpperCase();
        if (period === 'PM' && hours < 12) {
            hours += 12;
        }
        if (period === 'AM' && hours === 12) {
            hours = 0;
        }
        hours = hours % 12 || 12; // Ensure hours are in 12-hour format
    }

    return `${hours}:${minutes} ${period}`;
};


export function truncateText(text, maxLength) {
    if (text) {
        if (text.length <= maxLength) {
            return text;
        }
        return text?.slice(0, maxLength) + '...';
    }

    return text
}


export function teachModeViewData(data) {
    switch (data) {
        case "HOME_TUITION":
            return "In-Home"
        case "ONLINE_TUITION":
            return "Online"
        default:
            return null
    }
}


export function convertTo24HourFormat(timeStr) {
    // Trim any extra spaces
    timeStr = timeStanderFollowFunction(timeStr.trim());

    // Check if the time format is 12-hour or 24-hour based on the presence of AM/PM
    let is12HourFormat = String(timeStr).toLowerCase().includes('am') || String(timeStr).toLowerCase().includes('pm');

    if (is12HourFormat) {
        let [time, modifier] = timeStr.split(' ');
        let [hours, minutes] = time.split(':').map(part => part.trim());
        hours = parseInt(hours, 10);
        if (modifier.toLowerCase() === 'pm' && hours !== 12) {
            hours += 12;
        } else if (modifier.toLowerCase() === 'am' && hours === 12) {
            hours = 0;
        }
        let formattedHours = String(hours).padStart(2, '0');
        let formattedMinutes = String(minutes).padStart(2, '0');
        return `${formattedHours}:${formattedMinutes}`;
    } else {
        // If it's already in 24-hour format, just normalize it to "HH:MM"
        let [hours, minutes] = timeStr.split(':').map(part => part.trim());

        // Format hours and minutes to always have two digits
        let formattedHours = String(hours).padStart(2, '0');
        let formattedMinutes = String(minutes).padStart(2, '0');

        // Return the time in 24-hour format
        return `${formattedHours}:${formattedMinutes}`;
    }
}

export const dateInGivenFormat = (date, format) => {
    const guessedTimezone = moment.tz.guess();
    return date ? moment(date).tz(guessedTimezone).format(format)
        : "--/--/--";
}


export const filterData = (list, keys, value) => {
    const lowerCaseValue = value.toLowerCase();
    return list.filter((item) =>
        keys.some((key) => {
            const data = key.split('.').reduce((o, i) => (o ? o[i] : null), item);
            return data?.toLowerCase().includes(lowerCaseValue);
        })
    );
};



export const genderWriteFormat = (gen) => {
    switch (gen) {
        case "MALE":
            return "Male"
        case "FEMALE":
            return "Female"
        case "NO_PREFERENCE":
            return "No Preference"
        default:
            return gen;
    }
}


export const statusWriteFormat = (status) => {
    switch (status) {
        case "PENDING":
            return "Basic Education Pending.";
        case "BASIC_EDUCATION":
            return "Teaching Details Pending.";
        case "TEACHING_DETAILS":
            return "KYC PENDING.";
        case "KYC":
            return "KYC PENDING.";
        case "PAYMENT_EXPIRE":
            return "Package Expire.";
        case "PAYMENT_PENDING":
            return "PLATFORM FEE PENDING.";
        case "KYC_VERIFICATION":
            return "KYC VERIFICATION PENDING.";
        case "REJECTED_IN_INTERVIEW":
            return "REJECTED IN INTERVIEW";
        default:
            return "";
    }
};
