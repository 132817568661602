import React from 'react'
import { timeZoneReturnDateType } from '../../../_helpers'

function MobileTable ({ banner, handleChange, deleteUser, handleEdit }) {
  return (
    <section className='space-y-5 py-10'>
      {banner?.bannerGetList?.length > 0 ? (
        banner?.bannerGetList?.map((item, id) => (
          <div className='md:hidden block bg-white p-4 rounded-[10px]' key={id}>
            <>
              <div className='flex justify-between p-2 w-full'>
                <div className='w-full'>
                  <p className='text-[16px] font-[700] text-left'>
                    Banner Name
                  </p>
                </div>
                <div className='w-full'>
                  <p className='text-[14px] font-[400] text-end'>
                    {item && item.name ? item.name : null}
                  </p>
                </div>
              </div>
              <div className='flex justify-between p-2 w-full'>
                <div className='w-full'>
                  <p className='text-[16px] font-[700] text-left'>Image</p>
                </div>
                <div className='w-full flex justify-end'>
                  <img
                    className='h-12 w-16 '
                    src={item.image}
                    alt={item.name}
                  />
                </div>
              </div>
              <div className='flex justify-between p-2 w-full'>
                <div className='w-full'>
                  <p className='text-[16px] font-[700] text-left'>Type</p>
                </div>
                <div className='w-full'>
                  <p className='text-[14px] font-[400] text-end'>
                    {' '}
                    {item.userType}
                  </p>
                </div>
              </div>
              <div className='flex justify-between p-2 w-full'>
                <div className='w-full'>
                  <p className='text-[16px] font-[700] text-left'>Date</p>
                </div>
                <div className='w-full'>
                  <p className='text-[14px] font-[400] text-end'>
                    {' '}
                    {timeZoneReturnDateType(item?.createdAt)}
                  </p>
                </div>
              </div>
              <div className='flex justify-between p-2 w-full'>
                <div className='w-full'>
                  <p className='text-[16px] font-[700] text-left'>Status</p>
                </div>
                <div className='w-full'>
                  <ul className='flex gap-4 justify-end  items-center '>
                    <div>
                      <label className='inline-flex items-center cursor-pointer'>
                        <input
                          type='checkbox'
                          className='sr-only peer'
                          checked={item.isDisable}
                          onChange={() => handleChange(item._id)}
                        />
                        <div className="relative w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600" />
                      </label>
                    </div>
                  </ul>
                </div>
              </div>
              <div className='flex justify-between p-2 w-full'>
                <div className='w-full'>
                  <p className='text-[16px] font-[700] text-left'>Action</p>
                </div>
                <div className='w-full'>
                  <ul className='flex gap-4 justify-end  items-center '>
                    <li
                      className='cursor-pointer'
                      onClick={() => handleEdit(item)}
                    >
                      <img src='/writing.png' alt='dfvn ' />
                    </li>
                    <li
                      className='cursor-pointer'
                      onClick={() => deleteUser(item._id)}
                    >
                      <img src='/delete.png' alt='dfvn ' />
                    </li>
                  </ul>
                </div>
              </div>
            </>
          </div>
        ))
      ) : (
        <p className='md:hidden'>Data Not Found</p>
      )}
    </section>
  )
}

export default MobileTable
