import { APIcallFunction, headerForPrivateAPI } from "../_helpers";
import { employprofileConstant } from "../_constants";
import { alertActions } from "./alert.actions";

export const employprofileAction = {
  getTeamList,
  deleteTeam,
  createTeam,
  getAllAdminList,
  updateTeamStatus,
  updateTeam,
  getSubAdminList,
  updateSubAdminStatus,
  updateSubAdmin,
  createSubAdmin,
  updateSubAdminPasswordByPromise
}

async function updateSubAdminPasswordByPromise(data) {

  const credentials = {
      header: headerForPrivateAPI(),
      method: "POST",
      body: data ?? {},
      endPoint: "admin/updateSubAdminPassword"
  };
  return await APIcallFunction(credentials)
}


function getTeamList(data) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "team/getTeamList  ",
  };
  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (users) => {
        dispatch(success(users));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: employprofileConstant.GET_TEAM_LIST_REQUEST };
  }
  function success(users) {
    return { type: employprofileConstant.GET_TEAM_LIST_SUCCESS, users };
  }
  function failure(error) {
    return { type: employprofileConstant.GET_TEAM_LIST_FAILURE, error };
  }
}


function deleteTeam(data) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "team/deleteTeam  ",
  };
  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (users) => {
        dispatch(success(users));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: employprofileConstant.DELETE_TEAM_REQUEST };
  }
  function success(users) {
    return { type: employprofileConstant.DELETE_TEAM_SUCCESS, users };
  }
  function failure(error) {
    return { type: employprofileConstant.DELETE_TEAM_FAILURE, error };
  }
}

function createTeam(data, teamDetails) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "team/createTeam  ",
  };
  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (users) => {
        dispatch(success(users));
        dispatch(getTeamList(teamDetails))
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: employprofileConstant.CREATE_TEAM_REQUEST };
  }
  function success(users) {
    return { type: employprofileConstant.CREATE_TEAM_SUCCESS, users };
  }
  function failure(error) {
    return { type: employprofileConstant.CREATE_TEAM_FAILURE, error };
  }
}


function getAllAdminList(data) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "team/getAllAdminList  ",
  };
  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (users) => {
        dispatch(success(users));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: employprofileConstant.GET_ALL_ADMIN_LIST_REQUEST };
  }
  function success(users) {
    return { type: employprofileConstant.GET_ALL_ADMIN_LIST_SUCCESS, users };
  }
  function failure(error) {
    return { type: employprofileConstant.GET_ALL_ADMIN_LIST_FAILURE, error };
  }
}


function updateTeamStatus(data, teamDetails) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "team/updateTeamStatus  ",
  };
  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (users) => {
        dispatch(success(users));
        dispatch(getTeamList(teamDetails))
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: employprofileConstant.UPDATE_TEAM_STATUS_REQUEST };
  }
  function success(users) {
    return { type: employprofileConstant.UPDATE_TEAM_STATUS_SUCCESS, users };
  }
  function failure(error) {
    return { type: employprofileConstant.UPDATE_TEAM_STATUS_FAILURE, error };
  }
}



function updateTeam(data, teamDetails) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "team/updateTeam",
  };
  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (users) => {
        dispatch(success(users));
        dispatch(getTeamList(teamDetails))
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: employprofileConstant.UPDATE_TEAM_REQUEST };
  }
  function success(users) {
    return { type: employprofileConstant.UPDATE_TEAM_SUCCESS, users };
  }
  function failure(error) {
    return { type: employprofileConstant.UPDATE_TEAM_FAILURE, error };
  }
}

function getSubAdminList(data, teamDetails) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "admin/getSubAdminList  ",
  };
  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (users) => {
        dispatch(success(users));
        dispatch(getTeamList(teamDetails))
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: employprofileConstant.GET_ALL_EMPLOY_REQUEST };
  }
  function success(users) {
    return { type: employprofileConstant.GET_ALL_EMPLOY_SUCCESS, users };
  }
  function failure(error) {
    return { type: employprofileConstant.GET_ALL_EMPLOY_FAILURE, error };
  }
}


function createSubAdmin(data, emplyDetails) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "admin/createSubAdmin ",
  };
  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (users) => {
        dispatch(success(users));
        dispatch(getSubAdminList(emplyDetails))
      },
      (error) => {
        dispatch(alertActions.error(error))
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: employprofileConstant.CREATE_SUBADMIN_REQUEST };
  }
  function success(users) {
    return { type: employprofileConstant.CREATE_SUBADMIN_SUCCESS, users };
  }
  function failure(error) {
    return { type: employprofileConstant.CREATE_SUBADMIN_FAILURE, error };
  }
}

















function updateSubAdminStatus(data, emplyDetails) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "admin/updateSubAdminStatus  ",
  };
  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (users) => {
        dispatch(success(users));
        dispatch(getSubAdminList(emplyDetails))
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: employprofileConstant.UPDATE_SUBADMIN_STATUS_REQUEST };
  }
  function success(users) {
    return { type: employprofileConstant.UPDATE_SUBADMIN_STATUS_SUCCESS, users };
  }
  function failure(error) {
    return { type: employprofileConstant.UPDATE_SUBADMIN_STATUS_FAILURE, error };
  }
}












function updateSubAdmin(data, emplyDetails) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: "POST",
    body: data ?? {},
    endPoint: "admin/updateSubAdmin  ",
  };
  return (dispatch) => {
    dispatch(request(data));
    APIcallFunction(credentials).then(
      (users) => {
        dispatch(success(users));
        dispatch(getSubAdminList(emplyDetails))
      },
      (error) => {
        dispatch(alertActions.error(error))
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: employprofileConstant.UPDATE_SUBADMIN_REQUEST };
  }
  function success(users) {
    return { type: employprofileConstant.UPDATE_SUBADMIN_SUCCESS, users };
  }
  function failure(error) {
    return { type: employprofileConstant.UPDATE_SUBADMIN_FAILURE, error };
  }
}