import { appSettingConstants } from '../_constants'
import { APIcallFunction, headerForPrivateAPI } from '../_helpers'
import { alertActions } from './alert.actions'
export const appSettingAction = {
  appSettingGet,
  appSettingDelete,
  createAppSetting,
  updateAppSetting,
  EnaDisAppSetting
}

function appSettingGet (data) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: 'POST',
    body: data ?? {},
    endPoint: 'appSetting/getList'
  }
  return dispatch => {
    dispatch(request(data))
    APIcallFunction(credentials).then(
      users => {
        dispatch(success(users))
      },
      error => {
        dispatch(failure(error))
      }
    )
  }

  function request () {
    return { type: appSettingConstants.GET_APP_SETTING_LIST_REQUEST }
  }
  function success (users) {
    return { type: appSettingConstants.GET_APP_SETTING_LIST_SUCCESS, users }
  }
  function failure (error) {
    return { type: appSettingConstants.GET_APP_SETTING_LIST_FAILURE, error }
  }
}

function appSettingDelete (data, AppSettingList) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: 'POST',
    body: data ?? {},
    endPoint: 'appSetting/delete'
  }
  return dispatch => {
    dispatch(request(data))
    APIcallFunction(credentials).then(
      users => {
        dispatch(success(users))
        dispatch(appSettingGet(AppSettingList))
      },
      error => {
        dispatch(failure(error))
      }
    )
  }

  function request () {
    return { type: appSettingConstants.DELETE_APP_SETTING_LIST_REQUEST }
  }
  function success (users) {
    return { type: appSettingConstants.DELETE_APP_SETTING_LIST_SUCCESS, users }
  }
  function failure (error) {
    return { type: appSettingConstants.DELETE_APP_SETTING_LIST_FAILURE, error }
  }
}

function createAppSetting (data, AppSettingList) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: 'POST',
    body: data ?? {},
    endPoint: 'appSetting/create'
  }
  return dispatch => {
    dispatch(request(data))
    APIcallFunction(credentials).then(
      users => {
        dispatch(alertActions.success('AppSetting created'))
        dispatch(success(users))
        dispatch(appSettingGet(AppSettingList))
      },
      error => {
        if (error === 'Unique key already present') {
          dispatch(alertActions.error('Unique key already present.'))
        } else {
          dispatch(alertActions.error('Please try again later.'))
        }
        dispatch(failure(error))
      }
    )
  }

  function request () {
    return { type: appSettingConstants.CREATE_APP_SETTING_LIST_REQUEST }
  }
  function success (users) {
    return { type: appSettingConstants.CREATE_APP_SETTING_LIST_SUCCESS, users }
  }
  function failure (error) {
    return { type: appSettingConstants.CREATE_APP_SETTING_LIST_FAILURE, error }
  }
}

function updateAppSetting (data, appSettingDetails) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: 'POST',
    body: data ?? {},
    endPoint: 'appSetting/update'
  }
  return dispatch => {
    dispatch(request(data))
    APIcallFunction(credentials).then(
      users => {
        dispatch(success(users))
        dispatch(appSettingGet(appSettingDetails))
        dispatch(alertActions.success('AppSetting Updated'))
      },
      error => {
        dispatch(failure(error))
      }
    )
  }

  function request () {
    return { type: appSettingConstants.UPDATE_APP_SETTING_LIST_REQUEST }
  }
  function success (users) {
    return { type: appSettingConstants.UPDATE_APP_SETTING_LIST_SUCCESS, users }
  }
  function failure (error) {
    return { type: appSettingConstants.UPDATE_APP_SETTING_LIST_FAILURE, error }
  }
}

function EnaDisAppSetting (data, appSettingDetails) {
  const credentials = {
    header: headerForPrivateAPI(),
    method: 'POST',
    body: data ?? {},
    endPoint: 'appSetting/enableDisable'
  }
  return dispatch => {
    dispatch(request(data))
    APIcallFunction(credentials).then(
      users => {
        dispatch(alertActions.success('Action...'))
        dispatch(success(users))
        dispatch(appSettingGet(appSettingDetails))
      },
      error => {
        dispatch(failure(error))
      }
    )
  }

  function request () {
    return { type: appSettingConstants.ENA_DIS_APP_SETTING_LIST_REQUEST }
  }
  function success (users) {
    return { type: appSettingConstants.ENA_DIS_APP_SETTING_LIST_SUCCESS, users }
  }
  function failure (error) {
    return { type: appSettingConstants.ENA_DIS_APP_SETTING_LIST_FAILURE, error }
  }
}
