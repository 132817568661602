import Multiselect from 'multiselect-react-dropdown'
import React from 'react'
import { AiOutlineCloseCircle } from 'react-icons/ai'

function AddNotification({ modal, handleClose, handleSubmitNotification, options, selectedCities, onSelect, onRemove, errors, setErrors, setTitle, title, setNewMessage, message, handleUpload, error }) {




  return (modal && (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-[10px] w-11/12 max-w-2xl p-6 space-y-4">
        <div className="flex justify-between items-center">
          <h2 className="text-lg font-bold">Create Notification</h2>
          <button className="font-semibold" onClick={handleClose}><span><AiOutlineCloseCircle size={24} /></span></button>
        </div>
        <div className="l">
          <form className="space-y-4" onSubmit={handleSubmitNotification}>
            <div className="flex justify-between items-center w-full">
              <div className="  w-[30%]">
                <label
                  htmlFor="countries"
                  className="block mb-2 text-sm font-medium text-[#131523]"
                >
                  Select City
                </label>
              </div>
              <div className=" w-[70%] ">
              <Multiselect
                  options={options}
                  selectedValues={selectedCities}
                  onSelect={onSelect}
                  onRemove={onRemove}
                  displayValue="name"
                  className="multiselect-container multiselect-container-height multiselect-container-dark capitalize"
                  placeholder='Select City'

                />
               {errors.selectedCities && <p className="text-red-500 text-xs mt-1">{errors.selectedCities}</p>}
              </div>
            </div>

            <div className="flex justify-between items-center w-full">
              <div className="w-[30%]">

                <label
                  htmlFor="small-input"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Title
                </label>
              </div>
              <div className="w-[70%]">
                <input
                  type="text"
                  value={title}
                  onChange={(e) => {
                    setTitle(e.target.value);

                    setErrors((prevErrors) => ({ ...prevErrors, title: '' }));

                  }}

                  required
                  id="small-input"
                  placeholder="Title"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  block p-2.5  dark:border-gray-600 dark:placeholder-gray-400  w-full"
                />
                {errors.title && <p className="text-red-500 text-xs mt-1">{errors.title}</p>}
              </div>
            </div>

            <div className="flex justify-between items-center w-full">
              <div className="w-[30%]">
                <label
                  htmlFor="message"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Your message
                </label>
              </div>
              <div className="w-[70%]">
                <textarea
                  id="message"
                  rows="5"
                  value={message}
                  required
                  onChange={(e) => {
                    setNewMessage(e.target.value);
                    setErrors((prevErrors) => ({ ...prevErrors, message: '' }));
                  }}

                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  block p-2.5  dark:border-gray-600 dark:placeholder-gray-400  w-full"
                  placeholder="Message"
                ></textarea>
                {errors.message && <p className="text-red-500 text-xs mt-1">{errors.message}</p>}
              </div>
            </div>
            <div className="flex justify-between items-center w-full">
              <div className="w-[30%]">
                <label
                  className="block mt-4 mb-2 text-sm font-medium text-gray-900"
                  htmlFor="user_avatar"
                >
                  Image (Optional)
                </label>
              </div>
              <div className="w-[70%]">

                <input
                  className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none  dark:border-gray-600 dark:placeholder-gray-400"
                  aria-describedby="user_avatar_help"
                  id="user_avatar"
                  type="file"
                  accept="image/*"
                  // style={{ display: 'none' }}
                  onChange={handleUpload}
                />
                {error && <p className="text-red-500 mt-2">{error}</p>}
                {/* <img src="/upload-img.png" alt="fs" onChange={handleUpload} /> */}
                <p className='text-xs text-gray-400 pt-1'>Image Size:- ( w-[200px] h-[324px] )</p>
              </div>
            </div>

          </form>
          <div className="flex justify-between w-full py-10">
            <div className="w-[30%] ">
              {/* <button
                      className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                      onClick={handleClose}
                    >
                      Close
                    </button> */}
            </div>
            <div className="w-[70%]">
              <button
                className="bg-[#023565]  text-white font-bold py-2 px-4 rounded-full w-full"
                onClick={handleSubmitNotification}
              >
                Submit
              </button>
            </div>

          </div>
        </div>
      </div>
    </div>
  )
  )
}

export default AddNotification
