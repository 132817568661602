/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { feeAction } from "../../../_actions/fee.action";
import { useLocation } from "react-router-dom";
import { FaChevronLeft, FaEye } from "react-icons/fa";
import { downloadCSV } from "../../../_helpers/auth-header"
import { IoArrowBack } from "react-icons/io5";
import TutorfeepaidHeader from './EarningHeader/TutorfeepaidHeader'
import { FaChevronRight } from "react-icons/fa";
import { MdOutlineClose } from "react-icons/md";
import Loader from "../../../components/Loader";
import { alertActions } from "../../../_actions";
const TutorFeePaid = () => {
  const dispatch = useDispatch();
  const selector = useSelector((state) => state);
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const pageSize = 10;
  let tutorFeePaidList = selector?.Fee?.tutorFeePaid?.data?.list;
  let allTutorFeePaid = selector?.Fee?.allTutorFeePaid?.data;

  const location = useLocation();
  const tutorfeepaidData = location.state?.tutorfeepaidData;
  let monthId = tutorfeepaidData?.month;

  useEffect(() => {
    let tutorFeePaidData = {
      cityId: "",
      month: monthId,
      keyWord: "",
      pageNo: currentPage,
      size: pageSize,
    };
    let tutorFeePaidData1 = {
      cityId: "",
      month: monthId
    };
    dispatch(feeAction.getTutorFeePaidList(tutorFeePaidData));
    dispatch(feeAction.getAllTutorFeePaid(tutorFeePaidData1));
  }, [currentPage]);

  const fields = ['Parent Name', 'Parent Mobile Number', 'Tutor Name', 'Tutor Mobile Number', 'Student / Classes', 'Tuition Fee Due Date', 'Tutor Fee Due Date', 'Tuition Fee', 'Tutor Fee', 'Reference Fee', 'Created At', 'Time'];

  const convertToCSV = (data) => {

    const array = typeof data !== 'object' ? JSON.parse(data) : data;
    let csv = fields.join(',') + '\r\n';

    array?.forEach(item => {
      const parentName = item.parentId ? item.parentId.name : '-';
      const parentMobNo = item.parentId ? item.parentId.mobNo : '-';
      const tutorName = item.tutorId ? item.tutorId.name : '-';
      const tutorMobNo = item.tutorId ? item.tutorId.mobNo : '-';

      const studentsClasses = item.demoClassId && item.demoClassId.bookFreeDemo ? item.demoClassId.bookFreeDemo.map(demo => {
        return `${demo.studentId.studentFullName} / ${demo.classId.name}`;
      }).join(' | ') : 'N/A';

      const tuitionFeeDueDate = item.demoClassId?.dueDate ?? '';
      const tutorFeeDueDate = item.demoClassId?.tutorDueDate ?? '';
      const tuitionFee = item?.tuitionFee ?? '';
      const tutorFee = item?.tutorFee ?? '';
      const referenceFee = item?.referenceFee ?? '';
      const createdAt = item.createdAt ? new Date(item.createdAt).toLocaleString() : '';
      const row = [parentName, parentMobNo, tutorName, tutorMobNo, studentsClasses, tuitionFeeDueDate, tutorFeeDueDate, tuitionFee, tutorFee, referenceFee, createdAt].join(',');
      csv += row + '\r\n';
    });

    return csv;
  };

  const [fullDetailsModal, setfullDetailsModal] = useState(false);
  const [fullDetailsModalData, setfullDetailsModalData] = useState("");
  const handleFullDetails = (data) => {
    setfullDetailsModal(true);
    setfullDetailsModalData(data);
  };

  const handleBack = () => {
    window.history.back();
  };
  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };
  useEffect(() => {
    if (selector?.Fee?.tutorFeePaid?.data?.total) {
      setTotalPages(Math.ceil(selector?.Fee?.tutorFeePaid?.data?.total / pageSize));
    }
  }, [selector]);
  return (
    <section>
      <TutorfeepaidHeader />
      <div className="lg:m-5 m-2">
        <Loader loading={selector?.Fee?.loading} />

        <div className="flex justify-between">
          <div className="flex gap-2">
            <IoArrowBack
              className="text-[#023565] text-3xl border rounded-full border-black my-2 mx-3 cursor-pointer bg-white"
              onClick={handleBack}
            />
          </div>
          <div className="flex justify-end">
            <button className="bg-white px-3 py-2 border flex justify-between rounded-lg" onClick={() => { !allTutorFeePaid.length ? dispatch(alertActions.error("No data to export.")) : downloadCSV(convertToCSV(allTutorFeePaid)) }}>
              <span>Export</span>  <img src="/export 1.png" alt="" />
            </button>
          </div>
        </div>
        <div class="relative overflow-x-auto bg-white rounded-lg border border-[#D3D3D3] mt-2 lg:block hidden">
          <table class="w-full  text-left rtl:text-right bg-white text-[#313131]">
            <thead class=" ">
              <tr className="capitalize border-b">
                <th scope="col" class="px-6 py-3 whitespace-nowrap">
                  Tutor name
                </th>
                <th scope="col" class="px-6 py-3 whitespace-nowrap">
                  Student
                </th>
                <th scope="col" class="px-6 py-3 whitespace-nowrap">
                  City
                </th>
                <th scope="col" class="px-6 py-3 whitespace-nowrap">
                  Tutor Fee
                </th>
                <th scope="col" class="px-6 py-3 whitespace-nowrap">
                  Status
                </th>
              </tr>
            </thead>
            <tbody>
              {tutorFeePaidList && tutorFeePaidList.length > 0 ?
                tutorFeePaidList?.map((item, id) => (
                  <tr
                    class=" capitalize  border-b dark:border-gray-700 forfontsmall"
                    key={id}
                  >
                    <th
                      scope="row"
                      class="px-6 py-3 font-medium text-gray-900 whitespace-nowrap"
                    >
                      <div className="flex gap-3 justify-start place-items-center ">
                        <div>
                          <img
                            src={
                              item?.tutorId?.image ??
                              "https://thumbs.dreamstime.com/z/vector-illustration-avatar-dummy-logo-collection-image-icon-stock-isolated-object-set-symbol-web-137160339.jpg?ct=jpeg"
                            }
                            alt=""
                            className="w-8 h-8 rounded-full"
                          />
                        </div>
                        <div className="text-black">
                          <p>{item?.tutorId?.name}</p>
                          <p>{item?.tutorId?.mobNo}</p>
                        </div>

                      </div>
                    </th>
                    <td class="px-6 py-3">
                      <FaEye
                        className="text-[#023565] text-[25px] hover:text-[#8f4a6a] cursor-pointer"
                        onClick={() => handleFullDetails(item)}
                      />
                    </td>

                    <td class="px-6 py-3">{item?.cityId?.name ?? "-"}</td>
                    <td class="px-6 py-3">{item?.tutorFee ?? "0"}</td>
                    <td class="px-6 py-3 text-green-700 font-semibold">
                      Paid
                    </td>
                  </tr>
                ))
                : <tr>
                  <td colSpan={3} className="px-6 py-4 font-[400] text-base text-[#313131] whitespace-nowrap text-center">Data Not Available</td>
                </tr>}
            </tbody>
          </table>
        </div>

        <div className="lg:hidden block bg-white my-4 rounded-lg border border-[#023565]">
          {tutorFeePaidList && tutorFeePaidList.length > 0 ?
            tutorFeePaidList?.map((item, id) => (
              <table key={id} className="w-full  bg-white rounded-[10px]">
                <tr className="border-b border-gray-300">
                  <th className="text-left py-4 px-4">
                    <span className="font-[500]">Tutor name</span>
                  </th>
                  <td
                    class="px-4 py-4 font-medium text-gray-900"
                  >
                    <div className="flex items-center gap-3">
                      <div>
                        <img src={item?.tutorId?.image || "https://thumbs.dreamstime.com/z/vector-illustration-avatar-dummy-logo-collection-image-icon-stock-isolated-object-set-symbol-web-137160339.jpg?ct=jpeg"} alt='' className="w-8 h-8 lg:rounded-full rounded" />
                      </div>
                      <div>
                        <p className="text-[12px] text-left capitalize">{item?.tutorId?.name} </p>
                        <p className="text-[12px] text-left capitalize">{item?.tutorId?.mobNo}</p>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr className="border-b border-gray-300">
                  <th className="text-left py-4 px-4">
                    <span className="font-[500]">Student</span>
                  </th>
                  <td className="text-auto py-4  px-4 gap-2">
                    <p className="font-[400] text-[14px] capitalize">
                      <FaEye
                        className="text-[#023565] text-[25px] hover:text-[#8f4a6a] cursor-pointer"
                        onClick={() => handleFullDetails(item)}
                      />
                    </p>
                  </td>
                </tr>
                <tr className="border-b border-gray-300">
                  <th className="text-left py-4 px-4">
                    <span className="font-[500]">Tutor Fee</span>
                  </th>
                  <td className="text-auto py-4  px-4 gap-2">
                    <p className="font-[400] text-[14px] capitalize">
                      {item?.tutorFee ?? "0"}
                    </p>
                  </td>
                </tr>
                <tr className="border-b border-gray-300">
                  <th className="text-left py-4 px-4">
                    <span className="font-[500]">Status</span>
                  </th>
                  <td className="text-auto py-4  px-4 gap-2">
                    <p className="font-[400] text-[14px] capitalize">
                      {item?.tutorStatus === 0
                        ? "Pending"
                        : item?.tutorStatus === 1
                          ? "Paid"
                          : item?.tutorStatus === 2
                            ? " advance paid"
                            : "Unknown status"}
                    </p>
                  </td>
                </tr>

              </table>
            ))
            : <tr>
              <td colSpan={6} className="px-6 py-4 font-[400] text-base text-[#313131] whitespace-nowrap text-center">Data Not Available</td>
            </tr>
          }
        </div>

        <div>
          {totalPages > 1 && (
            <div className="flex justify-end items-center py-2 px-4">
              <button
                className={`px-4 py-2 border text-white bg-[#023565] rounded ${currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""}`}
                onClick={handlePreviousPage}
                disabled={currentPage === 1}
              >
                <FaChevronLeft />
              </button>
              <span className="text-gray-700 mx-1">
                <span className="border px-4 py-2 text-black  rounded">{currentPage}</span> of <span className="border px-4 py-2 text-black  rounded">{totalPages}</span>
              </span>
              <button
                className={`px-4 py-2 border text-white bg-[#023565] rounded ${currentPage === totalPages ? "opacity-50 cursor-not-allowed" : ""}`}
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
              >
                <FaChevronRight />
              </button>
            </div>
          )}
        </div>
      </div>
      {fullDetailsModal && (
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40">
          <div className="relative w-11/12 mx-auto lg:w-[800px]">
            <div className="flex justify-end">
              <button
                onClick={() => setfullDetailsModal(false)}
                className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center"
              >
                <MdOutlineClose /> Close
              </button>
            </div>
            <div className="form_class border-0 overflow-hidden overflow-x-auto rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              <div className="flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                <h2 className="text-lg font-semibold">Student Details </h2>
              </div>
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">
                        Student Name
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">
                        Class
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">
                        Subject
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">
                        Board
                      </th>

                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {fullDetailsModalData?.demoClassId?.bookFreeDemo?.map((ele, index) => (
                      <tr className="capitalize" key={index}>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 flex justify-start place-items-center">
                          <img
                            alt=""
                            src={
                              ele?.studentId?.image
                                ? ele?.studentId?.image
                                : "https://thumbs.dreamstime.com/z/vector-illustration-avatar-dummy-logo-collection-image-icon-stock-isolated-object-set-symbol-web-137160339.jpg?w=768"
                            }
                            className="w-8 h-8 rounded-full"
                          />
                          {ele?.studentId?.studentFullName ?? "N/A"}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {ele?.classId?.name ?? "N/A"}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {ele?.subjectId?.map((subject, subIndex) => (
                            <div key={subIndex}>{subject?.name}</div>
                          ))}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {ele?.board?.name ?? "N/A"}
                        </td>

                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default TutorFeePaid;
