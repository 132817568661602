/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  parentAction,
  tutionAction,
  userActions,
  userListAction
} from '../../../_actions'
import { BsThreeDotsVertical } from 'react-icons/bs'
import {
  FaAngleRight,
  FaChevronCircleRight,
  FaChevronLeft,
  FaEye
} from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { FaChevronRight } from 'react-icons/fa'
import Loader from '../../../components/Loader'
import StudentDetail from './Models/StudentDetail'
import ImagePreviewModal from '../../Tutor-Profile/Model/ImagePreview'
import UpdateNoteModel from './Models/UpdateNoteModel'
import UpdateTuitionModel from './Models/UpdateTuitionModel'
import ViewParent from './Models/ViewParent'
import { teachModeViewData, truncateText } from '../../../_helpers'
import TuitionFilters from './TuitionFilters'
import ViewMoreTuitionModel from './Models/ViewMoreTuitionModel'

const tableHead = [
  'Parent Name',
  'Student',
  'Address/City',
  'Tuition Fee',
  'Reference',
  'Tutor’s Fee',
  'Tuition Mode',
  'Company',
  'Note',
  'Action'
]
const ButtonSevenTabel = ({ searchText, headerCity }) => {
  const dispatch = useDispatch()
  const selector = useSelector(state => state)
  const [closedData, setclosedData] = useState()
  const [open, setOpen] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const pageSize = 10
  const navigate = useNavigate()

  const menuRef = useRef(null)

  const mobileRef = useRef(null)
  const [filters, setFilters] = useState({
    tuitionFee: '',
    tutorFee: '',
    preferredTeachMode: '',
    tutorId: '',
    classId: '',
    subjectId: '',
    classReference: '',
    parentId: ''
  })

  const [isViewMoreModel, setIsViewMoreModel] = useState(false)
  const tuitionId = ''

  const handleClickOutside = event => {
    if (
      menuRef.current &&
      !menuRef.current.contains(event.target) &&
      mobileRef.current &&
      !mobileRef.current.contains(event.target)
    ) {
      setOpen(null)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    let tutionData = {
      status: [11],
      keyWord: searchText,
      cityId: headerCity ? [headerCity] : '',
      fromDate: '',
      toDate: '',
      sortOrder: '',
      sortBy: '',
      pageNo: currentPage,
      size: pageSize,
      ...filters,
      tutorId: filters.tutorId !== '' ? [filters.tutorId] : [],
      parentId: filters.parentId !== '' ? [filters.parentId] : []
    }

    dispatch(tutionAction.getDemoClassListStatus(tutionData, tutionData.cityId))
  }, [currentPage, filters, searchText, headerCity])
  useEffect(() => {
    if (selector?.tution?.demoList?.data?.total) {
      setTotalPages(
        Math.ceil(selector?.tution?.demoList?.data?.total / pageSize)
      )
    }

    setclosedData(() => selector?.tution?.demoList?.data?.list)
  }, [selector])

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1)
  }

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1)
  }

  const openAction = id => {
    setOpen(id === open ? null : id)
  }


  const handleViewHistory = data => {
    navigate(`/app/viewhistory/${data?._id}`)
  }

  const handleSendToDemoRequest = data => {
    let listData = {
      status: [11],
      keyWord: searchText,
      cityId: headerCity ? [headerCity] : '',
      fromDate: '',
      toDate: '',
      sortOrder: '',
      sortBy: '',
      pageNo: currentPage,
      size: pageSize,
      ...filters,
      tutorId: filters.tutorId !== '' ? [filters.tutorId] : [],
      parentId: filters.parentId !== '' ? [filters.parentId] : []
    }
    let revertDetails = {
      demoId: data._id
    }
    dispatch(tutionAction.revertDemoClass(revertDetails, listData))
    const alertBox = document.getElementById('alert-box')
    alertBox.style.display = 'block'
    setTimeout(() => {
      alertBox.style.display = 'none'
    }, 1000)
  }

  const [remarkModal, setRemarkModal] = useState(false)
  const [remarkId, setRemarkId] = useState('')
  const [remark, setRemark] = useState('')

  const addRemark = data => {
    let remarkId = data?._id
    setRemarkId(remarkId)
    setRemarkModal(true)
    setRemark(data?.reason ?? '')
  }

  const handleRemarkSubmit = e => {
    e.preventDefault()
    let demoData = {
      status: [11],
      // leadCreate: 'CREATED_SELF',
      keyWord: searchText,
      cityId: headerCity ? [headerCity] : '',
      fromDate: '',
      toDate: '',
      sortOrder: '',
      sortBy: '',
      pageNo: currentPage,
      size: pageSize,
      ...filters,
      tutorId: filters.tutorId !== '' ? [filters.tutorId] : [],
      parentId: filters.parentId !== '' ? [filters.parentId] : []
    }
    let resStatus = 'Hold'

    let remarkData = {
      demoId: remarkId,
      remark: remark ? remark : 'N/A'
    }
    dispatch(tutionAction.addUpdateRemark(remarkData, demoData, resStatus))
    setRemarkModal(false)
    setRemark('')
  }

  const remarkClose = () => {
    setRemark('')
    setRemarkModal(false)
  }

  useEffect(() => {
    let details = {
      cityId: ''
      // "userType": "TUTOR",
      // "isDisable": false
    }
    dispatch(userListAction.getUserTypeList(details))
  }, [])

  const [viewStudentModal, setViewStudentModal] = useState(false)
  const [studentListdata, setStudentListData] = useState()
  const viewStudent = (data, demoId) => {
    let studentListDetails = {
      parentId: data._id,
      demoId: demoId
    }
    parentAction
      .getStudentListByParentIdAndDemoIdPromise(studentListDetails)
      .then(resData => {
        setViewStudentModal(true)
        setStudentListData(resData.data)
      })
      .catch(() => {})
  }

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [image, setImage] = useState('')
  const handleCloseFunctionImageModel = data => {
    if (data) {
      setImage(() => data)
    } else {
      setImage('')
    }
    setIsModalVisible(!isModalVisible)
  }
  const [fullDetails, setFullDetails] = useState(false)
  const [DetailsData, setFullDetailsData] = useState()
  const [tuitionDetails, setTuitionDetails] = useState({})
  const handleFullDetails = data => {
    userActions.getUserByPromiseId({ id: data.parentId._id }).then(res => {
      setFullDetails(true)
      setFullDetailsData(res?.data)
      setTuitionDetails({
        classDuration: data?.classDuration,
        tuitionFee: data?.tuitionFee,
        numberOfClasses: data?.numberOfClasses,
        tutorFee: data?.tutorFee,
        tuitionType: data?.tuitionType,
        location: data?.location
      })
    })
  }

  const [updateDemoModel, setUpdateDemoModel] = useState(false)
  const [inputChangeField, setInputChangeField] = useState({})
  const allStudentList = []

  return (
    <section>
      <div className=''>
        <Loader loading={selector?.tution?.loading} />

        <TuitionFilters filters={filters} setFilters={setFilters} />

        <div className='lg:block hidden py-6'>
          <div className=' border border-[#D3D3D3] rounded-xl overflow-x-auto'>
            <table className='w-full bg-white rounded-xl'>
              <thead className='border-b border-[#D1D1D1]'>
                <tr className='border-b text-center'>
                  {tableHead?.map(ele => (
                    <th className='px-6 py-3 whitespace-nowrap text-sm text-black'>
                      {ele}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className='bg-white '>
                {closedData && closedData.length > 0 ? (
                  closedData?.map((item, index) => (
                    <tr key={index} className=' border-b capitalize'>
                      {/* <td className="px-4 py-4 text-sm text-gray-900 text-center">{index + 1}</td> */}
                      <td className='px-4 py-4 flex items-center gap-4 text-sm text-gray-900'>
                        <div className='flex items-center justify-center gap-2'>
                          <span
                            onClick={
                              item?.parentId?.image
                                ? () =>
                                    handleCloseFunctionImageModel(
                                      item?.parentId?.image
                                    )
                                : null
                            }
                          >
                            {item?.parentId && item?.parentId?.image ? (
                              <img
                                alt=''
                                src={
                                  item?.parentId && item?.parentId?.image
                                    ? item?.parentId?.image
                                    : 'https://thumbs.dreamstime.com/z/vector-illustration-avatar-dummy-logo-collection-image-icon-stock-isolated-object-set-symbol-web-137160339.jpg?ct=jpeg'
                                }
                                onError={e => {
                                  e.target.src =
                                    'https://thumbs.dreamstime.com/z/vector-illustration-avatar-dummy-logo-collection-image-icon-stock-isolated-object-set-symbol-web-137160339.jpg?ct=jpeg'
                                }}
                                className='rounded-full my-3 max-w-[50px] max-h-[50px] min-w-[50px] min-h-[50px]'
                              />
                            ) : (
                              <div className='bg-gray-300 rounded-full h-14 w-14 flex justify-center items-center'>
                                <span className='text-base font-semibold text-[#033565]'>
                                  {' '}
                                  {item?.parentId?.name
                                    ? item.parentId.name
                                        .split(' ')
                                        .map(name => name.charAt(0))
                                        .join('')
                                    : ''}
                                </span>
                              </div>
                            )}
                          </span>
                          <div className='w-24'>
                            <p className='font-semibold whitespace-nowrap text-[#023565]'>
                              {item?.parentId?.name ?? 'Not Available'}
                            </p>
                            <p className='text-gray-500 whitespace-nowrap'>
                              {item?.parentId?.mobNo ?? 'Not Available'}
                            </p>
                            <div
                              className='flex items-center  gap-1 cursor-pointer'
                              onClick={() => handleFullDetails(item)}
                            >
                              <p className='text-base font-normal cursor-pointer'>
                                More
                              </p>
                              <FaChevronCircleRight className='text-[#023565] text-base cursor-pointer' />
                            </div>
                          </div>
                        </div>
                      </td>

                      <td className='px-4 py-4 text-sm'>
                        <span className='flex items-center justify-center'>
                          <FaEye
                            size={24}
                            className='cursor-pointer text-[#023565] hover:text-[#E4006F]'
                            onClick={() =>
                              viewStudent(item?.parentId, item._id)
                            }
                          />
                        </span>
                      </td>

                      <td className='px-4 py-4 text-sm text-gray-900'>
                        <span
                          className='flex justify-center items-center text-center cursor-pointer'
                          title={item && item?.address}
                        >
                          {' '}
                          {item && item?.address
                            ? truncateText(item?.address, 12)
                            : 'N/A'}
                        </span>
                        <span className='flex justify-center items-center'>
                          {item && item?.cityId?.name
                            ? item?.cityId?.name
                            : 'N/A'}
                        </span>
                      </td>

                      <td className='px-4 py-4 text-sm text-gray-900 text-center'>
                        {item.tuitionFee}
                      </td>
                      <td className='px-4 py-4 text-sm text-gray-900 text-center'>
                        {item.referenceFee}
                      </td>
                      <td className='px-4 py-4 text-sm text-gray-900 text-center'>
                        {item.tutorFee}
                      </td>
                      <td className='px-4 py-4 text-sm text-gray-900 text-center'>
                        {' '}
                        {teachModeViewData(item?.preferredTeachMode)}
                      </td>
                      <td className='px-4 py-4 text-sm text-gray-900 text-center'>
                        {item.companyShare}
                      </td>

                      <td
                        className={`px-4 py-4 text-sm text-center cursor-pointer font-semibold ${
                          item.remark ? 'text-[#00AFE2]' : 'text-[#023565]'
                        }`}
                        onClick={() => addRemark(item)}
                      >
                        <span> {item?.reason ? 'View' : 'Add'}</span>
                      </td>

                      <td className='px-4 py-4 text-sm'>
                        <span className='flex items-center justify-center'>
                          <BsThreeDotsVertical
                            size={24}
                            className='cursor-pointer text-[#023565] hover:text-[#E4006F]'
                            onClick={() => openAction(item._id)}
                          />
                        </span>

                        {open === item._id && (
                          <ul
                            className='bg-white fixed right-10 border text-[#023565] rounded-md py-2 px-2 text-start text-sm font-semibold'
                            ref={menuRef}
                          >
                            <li
                              className='text-[#023565] py-1 border-b px-2 flex justify-between place-items-center hover:text-[#E4006F] '
                              onClick={() => handleSendToDemoRequest(item)}
                            >
                              <button>Send to Demo Request</button>
                              <FaAngleRight className='hover:text-[#C60E6B]' />
                            </li>
                            <li
                              className='text-[#023565] py-1 px-2 -b flex justify-between place-items-center hover:text-[#E4006F]  cursor-pointer'
                              onClick={() => handleViewHistory(item)}
                            >
                              <button>View History</button>
                              <FaAngleRight className='hover:text-[#C60E6B]' />
                            </li>
                          </ul>
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={9} className='px-6 py-4 text-center'>
                      Data Not Available.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          <ImagePreviewModal
            handleClose={handleCloseFunctionImageModel}
            showWarning={isModalVisible}
            image={image}
          />

          <UpdateNoteModel
            remarkModal={remarkModal}
            remarkClose={remarkClose}
            Heading={'Update Note'}
            remark={remark}
            setRemark={setRemark}
            handleRemarkSubmit={handleRemarkSubmit}
            btnName={`${remark !== 'N/A' || remark ? 'Update' : 'Add'} `}
          />

          <UpdateTuitionModel
            handleBack={() => {
              setUpdateDemoModel(false)
              setInputChangeField({})
            }}
            updateDemoModel={updateDemoModel}
            inputChangeField={inputChangeField}
            allStudentList={allStudentList}
            setInputChangeField={setInputChangeField}
            listPayload={{
              status: [11],
              keyWord: searchText,
              cityId: headerCity ? [headerCity] : '',
              fromDate: '',
              toDate: '',
              sortOrder: '',
              sortBy: '',
              pageNo: currentPage,
              size: pageSize,
              ...filters,
              tutorId: filters.tutorId !== '' ? [filters.tutorId] : []
            }}
          />
        </div>

        <div
          id='alert-box'
          style={{
            display: 'none',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'yellow',
            padding: '10px',
            borderRadius: '10px'
          }}
        >
          Tuition Send to Demo Request!
        </div>

        <StudentDetail
          setViewStudentModal={setViewStudentModal}
          viewStudentModal={viewStudentModal}
          setStudentListData={setStudentListData}
          studentListdata={studentListdata}
        />

        <ViewParent
          fullDetails={fullDetails}
          setFullDetails={setFullDetails}
          DetailsData={DetailsData}
          tuitionDetails={tuitionDetails}
        />

        <section className='space-y-5 lg:hidden block py-4'>
          {closedData && closedData.length > 0 ? (
            closedData?.map((item, index) => (
              <div key={index} className=' bg-white p-4 rounded-[10px]'>
                <>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[14px] font-[600] text-left'>
                        Parent Name
                      </p>
                    </div>
                    <div className='w-full flex items-start'>
                      <div className='flex justify-start  gap-2 w-[95%]'>
                        <div
                          onClick={
                            item?.parentId?.image
                              ? () =>
                                  handleCloseFunctionImageModel(
                                    item?.parentId?.image
                                  )
                              : null
                          }
                        >
                          {item?.parentId && item?.parentId?.image ? (
                            <img
                              alt=''
                              src={
                                item?.parentId && item?.parentId?.image
                                  ? item?.parentId?.image
                                  : ''
                              }
                              className='rounded  max-w-[50px] max-h-[50px] min-w-[50px] min-h-[50px]'
                            />
                          ) : (
                            <div className='bg-gray-300 rounded h-14 w-14 flex justify-center items-center'>
                              <span className='text-base font-semibold text-[#033565]'>
                                {' '}
                                {item?.parentId?.name
                                  ? item.parentId.name
                                      .split(' ')
                                      .map(name => name.charAt(0))
                                      .join('')
                                  : ''}
                              </span>
                            </div>
                          )}
                        </div>
                        <div className=''>
                          <p className='text-[12px] font-semibold whitespace-nowrap text-[#023565]'>
                            {/* {item?.parentId?.name ?? 'Not Available'} */}
                            {truncateText(item?.parentId?.name, 10) ?? 'N/A'}
                          </p>
                          <p className='text-[12px] font-medium text-gray-500 whitespace-nowrap'>
                            {item?.parentId?.mobNo ?? 'Not Available'}
                          </p>
                          <div className='flex items-center  gap-3 cursor-pointer'>
                            <div
                              className='flex items-center  gap-1'
                              onClick={() => handleFullDetails(item)}
                            >
                              <p className='text-[12px] font-medium cursor-pointer'>
                                More
                              </p>
                              <FaChevronCircleRight className='text-[#023565] text-base cursor-pointer' />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='w-[5%] relative'>
                        <BsThreeDotsVertical
                          onClick={() => openAction(item._id)}
                          className='text-2xl text-[#C60E6B]'
                        />
                        {open === item._id && (
                          <div
                            className='bg-white absolute w-64 right-2 border rounded-md text-[#023565] py-2 px-2 text-start text-sm font-semibold'
                            ref={mobileRef}
                          >
                            <div
                              className='flex justify-between place-items-center hover:text-[#E4006F] hover:bg-gray-100 cursor-pointer'
                              onClick={() => handleSendToDemoRequest(item)}
                            >
                              <button className='flex items-center  justify-between py-2  text-black'>
                                Send to Demo Request
                              </button>
                              <FaAngleRight className='hover:text-[#C60E6B]' />
                            </div>
                            <div
                              className='flex justify-between place-items-center hover:text-[#E4006F] hover:bg-gray-100 cursor-pointer'
                              onClick={() => handleViewHistory(item)}
                            >
                              <button className='flex items-center  justify-between py-2  text-black'>
                                View History
                              </button>
                              <FaAngleRight className='hover:text-[#C60E6B]' />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[16px] font-[700] text-left'>
                        Student
                      </p>
                    </div>
                    <div className='w-full'>
                      <p
                        className='text-[14px] font-[700] text-[#023565]'
                        onClick={() => viewStudent(item?.parentId, item._id)}
                      >
                        View
                      </p>
                    </div>
                  </div>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[16px] font-[700] text-left'>
                        Address
                      </p>
                    </div>
                    <div className='w-full'>
                      <p className='text-[14px] font-[400]'>
                        {' '}
                        {item && item?.address ? item?.address : 'N/A'}
                      </p>
                    </div>
                  </div>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[16px] font-[700] text-left'>City</p>
                    </div>
                    <div className='w-full'>
                      <p className='text-[14px] font-[400]'>
                        {' '}
                        {item && item?.cityId?.name
                          ? item?.cityId?.name
                          : 'N/A'}
                      </p>
                    </div>
                  </div>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[16px] font-[700] text-left'>
                        Tuition Fee
                      </p>
                    </div>
                    <div className='w-full'>
                      <p className='text-[14px] font-[400]'>
                        {' '}
                        {item.tuitionFee}{' '}
                      </p>
                    </div>
                  </div>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[16px] font-[700] text-left'>
                        Reference
                      </p>
                    </div>
                    <div className='w-full'>
                      <p className='text-[14px] font-[400]'>
                        {' '}
                        {item.referenceFee}
                      </p>
                    </div>
                  </div>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[16px] font-[700] text-left'>
                        Tutor Fee
                      </p>
                    </div>
                    <div className='w-full'>
                      <p className='text-[14px] font-[400]'>{item.tutorFee}</p>
                    </div>
                  </div>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[16px] font-[700] text-left'>
                        Tuition Mode
                      </p>
                    </div>
                    <div className='w-full'>
                      <p className='text-[14px] font-[400]'>
                        {teachModeViewData(item?.preferredTeachMode)}
                      </p>
                    </div>
                  </div>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[16px] font-[700] text-left'>
                        Company
                      </p>
                    </div>
                    <div className='w-full'>
                      <p className='text-[14px] font-[400]'>
                        {item.companyShare}
                      </p>
                    </div>
                  </div>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[16px] font-[700] text-left'>Note</p>
                    </div>
                    <div className='w-full'>
                      <p className='text-[14px] font-[400]'>
                        <td
                          className={`cursor-pointer font-semibold ${
                            item.remark ? 'text-[#00AFE2]' : 'text-[#023565]'
                          }`}
                          onClick={() => addRemark(item)}
                        >
                          <p> {item?.reason ? 'View' : 'Add'}</p>
                        </td>
                      </p>
                    </div>
                  </div>
                </>
              </div>
            ))
          ) : (
             <div  className=' bg-white p-4 rounded-[10px]'>
            <p className='text-center'>Data Not Found</p>
          </div>
          )}
        </section>

        {totalPages > 1 && (
          <div className='flex justify-end items-center py-2 px-4'>
            <button
              className={`px-4 py-2 border text-white bg-[#023565] rounded ${
                currentPage === 1 ? 'opacity-50 cursor-not-allowed' : ''
              }`}
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
            >
              <FaChevronLeft />
            </button>
            <span className='text-gray-700 mx-1'>
              <span className='border px-4 py-2 text-black  rounded'>
                {currentPage}
              </span>{' '}
              of{' '}
              <span className='border px-4 py-2 text-black  rounded'>
                {totalPages}
              </span>
            </span>
            <button
              className={`px-4 py-2 border text-white bg-[#023565] rounded ${
                currentPage === totalPages
                  ? 'opacity-50 cursor-not-allowed'
                  : ''
              }`}
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              <FaChevronRight />
            </button>
          </div>
        )}
      </div>
      {isViewMoreModel && (
        <ViewMoreTuitionModel
          isViewMoreModel={isViewMoreModel}
          setIsViewMoreModel={setIsViewMoreModel}
          tuitionId={tuitionId}
        />
      )}
    </section>
  )
}

export default ButtonSevenTabel
