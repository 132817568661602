/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FaChevronLeft, FaEye, FaPlus } from 'react-icons/fa'
import { MdOutlineClose } from 'react-icons/md'
import { FaChevronRight } from 'react-icons/fa'
import { roleAction } from '../../_actions'
import { moduleAction } from '../../_actions'
import './Emply.css'
import RoleUpdateModal from './Model/UpdateRole'
import Loader from '../../components/Loader'
import ModulePermission from './ModulePermission.json'
import { FaEdit } from 'react-icons/fa'
import { RiDeleteBin6Fill, RiDeleteBinLine } from 'react-icons/ri'

import Multiselect from 'multiselect-react-dropdown'

const customStyles = {
  container: provided => ({
    ...provided
  }),
  control: provided => ({
    ...provided,
    // border: '1px solid #000',
    boxShadow: 'none',
    '&:hover': {
      border: '1px solid #000'
    },
    borderRadius: '5px'
  }),
  menu: provided => ({
    ...provided
  }),
  placeholder: provided => ({
    ...provided,
    color: '#000 !important'
  })
}

const sidebarItems = [
  'DASHBOARD',
  'TUTOR_TO_LIVE',
  'EARNING',
  'TUITION',
  'FEE',
  'ATTENDANCE',
  // "WALLET",
  'TUTOR_PROFILE',
  'PARENT_STUDENT_PROFILE',
  // "EMPLOYEE_PROFILE",
  // "CITY_PROFILE",
  // "CLASS_SUBJECT_BOARD",
  // "REFERRAL_APP_SETTING",
  // "HOLIDAY_CALENDAR",
  // "TERMS_AND_CONDITION",
  'ASSIGNMENT',
  // "BANNERS",
  'FEEDBACK',
  'NOTIFICATION',
  'MY_PROFILE',
  // "PLATFORM_FEE",
  'HELP_AND_SUPPORT'
]

export const Roles = () => {
  const dispatch = useDispatch()
  const selector = useSelector(state => state)

  const [roleList, setRoleList] = useState()
  const [allModule, setAllModule] = useState()
  const [name, setName] = useState('')
  const [newRoleModal, setNewRoleModal] = useState(false)
  const [editRole, setEditRole] = useState({
    name: '',
    accessModule: []
  })
  const [selectedItems, setSelectedItems] = useState([])
  const [roleUpdateModal, setRoleUpdateModal] = useState(false)
  const [permitionmodal, setPermitionModal] = useState(false)
  const [permitionModalData, setPermitionModalData] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const pageSize = 10

  const [sidebarPermission, setSidebarPermission] = useState([])
  const [errors, setErrors] = useState({})

  const [options, setOptions] = useState([
    { label: 'CREATE', value: 'CREATE' },
    { label: 'LIST', value: 'LIST' },
    { label: 'UPDATE', value: 'UPDATE' },
    { label: 'DELETE', value: 'DELETE' },
    { label: 'ENABLE_DISABLE', value: 'ENABLE_DISABLE' }
  ])

  const permitionModal = role => {
    setPermitionModal(true)
    setPermitionModalData(role)
  }

  useEffect(() => {
    let roleData = {
      keyWord: '',
      fromDate: '',
      toDate: '',
      pageNo: currentPage,
      size: pageSize
    }
    dispatch(roleAction.roleGet(roleData))
    dispatch(moduleAction.allModule(roleData))
  }, [currentPage])

  useEffect(() => {
    if (selector?.Roles?.roleList?.data?.total) {
      setTotalPages(
        Math.ceil(selector?.Roles?.roleList?.data?.total / pageSize)
      )
    }
    setAllModule(selector?.allModule?.moduleList)
    setRoleList(selector?.Roles?.roleList?.data?.list)
    setOptions([
      { label: 'CREATE', id: 'CREATE' },
      { label: 'LIST', id: 'LIST' },
      { label: 'UPDATE', id: 'UPDATE' },
      { label: 'DELETE', id: 'DELETE' },
      { label: 'ENABLE_DISABLE', id: 'ENABLE_DISABLE' }
    ])
  }, [selector])

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1)
  }

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1)
  }

  const deleteRole = item => {
    let roleData = {
      keyWord: '',
      fromDate: '',
      toDate: '',
      pageNo: 1,
      size: 10
    }

    let deleteRoleId = {
      id: item._id
    }

    dispatch(roleAction.roleDelete(deleteRoleId, roleData))
  }

  const validateForm = () => {
    let errorData = {}
    if (!name) {
      errorData.name = 'Please enter name.'
    }
    if (!sidebarPermission.length) {
      errorData.sidebarPermission = 'Please select at least one permission.'
    }
    setErrors(errorData)
    return Object.keys(errorData).length === 0
  }
  const sendCreateData = () => {
    if (!validateForm()) {
      return
    }
    let roleData = {
      keyWord: '',
      fromDate: '',
      toDate: '',
      pageNo: 1,
      size: 10
    }

    const permissions = sidebarPermission.map(item => item.value)

    // Use flatMap and Set to get unique access modules
    const accessModule = [
      ...new Set(permissions.flatMap(item => ModulePermission[item]))
    ]

    // Create role data with structured access permissions
    const createRoleData = {
      name,
      permissions: permissions,
      accessModule: accessModule.map(item => ({
        name: item,
        access: ['CREATE', 'LIST', 'UPDATE', 'DELETE', 'ENABLE_DISABLE']
      }))
    }

    setNewRoleModal(false)
    dispatch(roleAction.roleCreate(createRoleData, roleData))
    setName('')
    setSidebarPermission([])
    // setSelectors([
    //   { id: 1, select1: "", selectedItem: [] },
    // ]);
  }

  //role Update------------------------------>
  const handleUpdate = data => {
    setRoleUpdateModal(true)
    setEditRole(() => ({
      ...data,
      permissions: sidebarItems
        .filter(item => data.permissions.includes(item))
        .map(item => ({
          value: item,
          label: item.replace(/_/g, ' ') // Replacing underscores with spaces
        }))
    }))
  }

  const closeModal = () => {
    setRoleUpdateModal(false)
    setErrors({})
    setEditRole({})
  }

  const submitUpdateData = () => {
    let errorData = {}
    if (!editRole.name) {
      errorData.name = 'Please enter name.'
    }
    if (!editRole.permissions.length) {
      errorData.sidebarPermission = 'Please select at least one permission.'
    }

    setErrors(errorData)
    if (Object.keys(errorData).length === 0) {
      const roleData = {
        keyWord: '',
        fromDate: '',
        toDate: '',
        pageNo: 1,
        size: 10
      }
      const permissions = editRole.permissions.map(item => item.value)

      // Use flatMap and Set to get unique access modules
      const accessModule = [
        ...new Set(permissions.flatMap(item => ModulePermission[item]))
      ]

      // Create role data with structured access permissions
      const updateRoleData = {
        id: editRole?._id,
        name: editRole.name,
        permissions: permissions,
        accessModule: accessModule.map(item => ({
          name: item,
          access: ['CREATE', 'LIST', 'UPDATE', 'DELETE', 'ENABLE_DISABLE']
        }))
      }

      dispatch(roleAction.roleUpdate(updateRoleData, roleData))
      setRoleUpdateModal(false)
      setEditRole({})
    }
  }

  return (
    <>
      <section className=''>
        <Loader loading={selector?.Roles?.loading} />
        <div className='md:flex hidden  justify-end gap-2 -mt-10'>
          <button
            className='flex justify-center items-center gap-2 md:w-40 w-full text-[#C60E6B] py-2.5 text-sm  border border-gray-300 rounded-lg bg-gray-50'
            onClick={() => setNewRoleModal(true)}
          >
            <span>
              <FaPlus />
            </span>{' '}
            New Role
          </button>
        </div>
        <section className='lg:block hidden'>
          <div className='mt-5 border border-[#D3D3D3] rounded-xl bg-white'>
            <table className='min-w-full table-autorounded-xl overflow-x-auto'>
              <thead className='border-b border-[#D1D1D1]'>
                <tr className='text-black'>
                  <th className='px-4 py-3 whitespace-nowrap text-sm text-left'>
                    Role Name
                  </th>
                  <th className='px-4 py-3 whitespace-nowrap text-sm text-left'>
                    Permissions
                  </th>
                  <th className='px-4 py-3 whitespace-nowrap text-sm text-left'>
                    Delete
                  </th>
                  <th className='px-4 py-3 whitespace-nowrap text-sm'>
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {roleList && roleList.length > 0 ? (
                  roleList?.map((role, id) => (
                    <tr key={id} className='capitalize border-b'>
                      <td className='px-4 py-3 text-sm text-gray-900 text-left'>
                        {role.name}
                      </td>
                      <td className='px-4 py-3 text-sm text-gray-900 text-left'>
                        <FaEye
                          size={24}
                          className='hover:text-[#CD0C5C] cursor-pointer'
                          onClick={() => permitionModal(role, id)}
                        />
                      </td>
                      <td className='px-4 py-3 text-sm text-gray-900 text-left'>
                        <RiDeleteBinLine
                          size={24}
                          className='hover:text-[#CD0C5C] cursor-pointer'
                          onClick={() => deleteRole(role)}
                        />
                      </td>
                      <td className='px-4 py-3 text-sm text-gray-900 text-center'>
                        <span
                          className='hover:text-[#CD0C5C] text-center flex items-center justify-center cursor-pointer'
                          onClick={() => handleUpdate(role)}
                        >
                          <FaEdit size={24} />
                        </span>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr className='border-b'>
                    <td
                      colSpan={4}
                      className='px-6 py-4 font-[400] text-base text-[#313131] whitespace-nowrap text-center'
                    >
                      Data Not Available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </section>

        {permitionmodal && (
          <>
            <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40'>
              <div className='relative w-11/12 mx-auto lg:w-[600px]'>
                <button
                  className='z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center'
                  onClick={() => setPermitionModal(false)}
                >
                  <span>
                    <MdOutlineClose />
                  </span>{' '}
                  Close
                </button>
                <div className='form_class border-0 rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
                  <div className='flex items-center justify-between px-5 py-2 border-b border-solid border-slate-200 rounded-t'>
                    <h2 className='text-lg font-semibold'>Permissions</h2>
                  </div>
                  <div className='relative h-[480px] overflow-y-auto'>
                    <div className='p-4'>
                      <table className='w-full bg-white rounded-lg'>
                        <tbody>
                          {permitionModalData?.permissions?.map((item, id) => (
                            <tr key={id} className='capitalize border'>
                              <td className='px-6 py-4 text-sm border text-gray-900'>
                                {item.replace(/_/g, ' ')}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {newRoleModal && (
          <>
            <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-black bg-opacity-40'>
              <div className='relative w-11/12 mx-auto lg:w-[600px]'>
                <button
                  className='z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center'
                  onClick={() => {
                    setNewRoleModal(false)
                    setName('')
                    setSidebarPermission([])
                    setErrors({})
                  }}
                >
                  <span>
                    <MdOutlineClose />
                  </span>{' '}
                  Close
                </button>
                <div className='form_class border-0 rounded-[10px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
                  <div className='flex items-center justify-between p-5 border-b border-solid border-slate-200 rounded-t'>
                    <h2 className='text-lg font-semibold'>Create New Role</h2>
                  </div>
                  <div className='relative'>
                    <form className='p-4 space-y-2'>
                      <div className='space-y-2'>
                        <label
                          htmlFor='role_name'
                          className='block text-sm font-medium text-gray-700'
                        >
                          Role Name
                        </label>
                        <input
                          type='text'
                          id='role_name'
                          className='mt-1 p-2 border block w-full border-gray-300 rounded-md'
                          placeholder='Role Name'
                          required
                          value={name}
                          onChange={e => setName(e.target.value)}
                        />
                        {errors.name && (
                          <p className='text-red-500 text-sm'>{errors.name}</p>
                        )}
                      </div>
                      {/* <div>
                        <Select
                          id="permission"
                          name="permission"
                          placeholder="Select permissions..."
                          value={sidebarPermission}
                          options={sidebarItems.map((item) => ({
                            value: item,
                            label: item.replace(/_/g, ' '),
                          }))}
                          className="capitalize text-black "
                          styles={customStyles}
                          classNamePrefix="react-select"
                          onChange={(selectedOption, actionMeta) => {
                            setSidebarPermission(selectedOption)
                          }}
                          isMulti
                        />
                        {errors.sidebarPermission && <p className="text-red-500 text-sm">{errors.sidebarPermission}</p>}

                      </div> */}

                      <div className='space-y-2'>
                        <label
                          htmlFor='permission'
                          className='block text-sm font-medium text-gray-700'
                        >
                          Select Permissions
                        </label>
                        <Multiselect
                          options={sidebarItems.map(item => ({
                            value: item,
                            label: item.replace(/_/g, ' ')
                          }))}
                          selectedValues={sidebarPermission}
                          onSelect={selectedList =>
                            setSidebarPermission(selectedList)
                          }
                          onRemove={removedList =>
                            setSidebarPermission(removedList)
                          }
                          displayValue='label'
                          className='capitalize text-black'
                          style={customStyles}
                        />
                        {errors.sidebarPermission && (
                          <p className='text-red-500 text-sm'>
                            {errors.sidebarPermission}
                          </p>
                        )}
                      </div>
                      <div className='flex justify-center  '>
                        <button
                          type='button'
                          className='w-60 mx-auto bg-[#023565] text-white font-semibold py-2 px-4 rounded-full '
                          onClick={sendCreateData}
                        >
                          Create Role
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        <section className='space-y-5  md:hidden block'>
          <div className='md:hidden flex justify-end gap-2 mt-5 md:mt-0'>
            <button
              className='px-4 w-36 py-2 border rounded-md text-[#C60E6B] border-[#D9D9D9] bg-white flex  place-items-center justify-between gap-2'
              onClick={() => setNewRoleModal(true)}
            >
              <FaPlus /> New Role
            </button>
          </div>
          {roleList && roleList.length > 0 ? (
            roleList?.map((role, id) => (
              <div className=' bg-white p-4 rounded-[10px] border border-[#023565]'>
                <>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[16px] font-[700] text-left'>
                        Role Name{' '}
                      </p>
                    </div>
                    <div className='w-full'>
                      <p className='text-[14px] font-[400]'> {role.name}</p>
                    </div>
                  </div>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[16px] font-[700] text-left'>
                        Permissions{' '}
                      </p>
                    </div>
                    <div className='w-full'>
                      <p
                        className='text-[14px] font-[700] text-[#023565] capitalize'
                        onClick={() => permitionModal(role, id)}
                      >
                        View
                      </p>
                    </div>
                  </div>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[16px] font-[700] text-left'>
                        Delete{' '}
                      </p>
                    </div>
                    <div className='w-full'>
                      <p
                        className='text-[14px] font-[400] capitalize'
                        onClick={() => deleteRole(role)}
                      >
                        <RiDeleteBin6Fill size={24} className='text-red-500' />
                      </p>
                    </div>
                  </div>
                  <div className='flex justify-between p-2 w-full'>
                    <div className='w-full'>
                      <p className='text-[16px] font-[700] text-left'>Action</p>
                    </div>
                    <div className='w-full'>
                      <p className='text-[14px] font-[800]'>
                        <FaEdit
                          size={24}
                          onClick={() => handleUpdate(role)}
                          className=' text-green-500'
                        />
                      </p>
                    </div>
                  </div>
                </>
              </div>
            ))
          ) : (
            <p>Data Not Found</p>
          )}
        </section>
      </section>

      {totalPages > 1 && (
        <div className='flex justify-end items-center py-2 px-4'>
          <button
            className={`px-4 py-2 border text-white bg-[#023565] rounded ${
              currentPage === 1 ? 'opacity-50 cursor-not-allowed' : ''
            }`}
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
          >
            <FaChevronLeft />
          </button>
          <span className='text-gray-700 mx-1'>
            <span className='border px-4 py-2 text-black  rounded'>
              {currentPage}
            </span>{' '}
            of{' '}
            <span className='border px-4 py-2 text-black  rounded'>
              {totalPages}
            </span>
          </span>
          <button
            className={`px-4 py-2 border text-white bg-[#023565] rounded ${
              currentPage === totalPages ? 'opacity-50 cursor-not-allowed' : ''
            }`}
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            <FaChevronRight />
          </button>
        </div>
      )}
      <RoleUpdateModal
        roleUpdateModal={roleUpdateModal}
        closeModal={closeModal}
        editRole={editRole}
        setEditRole={setEditRole}
        allModule={allModule}
        options={options}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        submitUpdateData={submitUpdateData}
        sidebarItems={sidebarItems}
        customStyles={customStyles}
        errors={errors}
      />
    </>
  )
}
