export const userConstants = {
  LOGIN_REQUEST: "USERS_LOGIN_REQUEST",
  LOGIN_SUCCESS: "USERS_LOGIN_SUCCESS",
  LOGIN_FAILURE: "USERS_LOGIN_FAILURE",

  ADD_RATING_ACCEPT_DATA_REQUEST: "ADD_RATING_ACCEPT_DATA_REQUEST",
  ADD_RATING_ACCEPT_DATA_SUCCESS: "ADD_RATING_ACCEPT_DATA_SUCCESS",
  ADD_RATING_ACCEPT_DATA_FAILURE: "ADD_RATING_ACCEPT_DATA_FAILURE",

  LOGOUT: "USERS_LOGOUT",
};
