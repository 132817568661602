import React from 'react'

const Button = ({ name, onclick, value = 0 }) => {
  return (
    <div>
      <button onClick={onclick} className='py-1'>{name} ({value})</button>
    </div>
  )
}

export default Button