import React, { Fragment } from "react";
import HeaderProfileComp from "../../../../components/Header/HeaderProfileComp";
import HeaderHeading from "../../../../components/Header/HeaderHeading";
import MobileSearchHeader from "../../../../components/Header/MobileSearchHeader";

const FeeReferenceHeader = ({ searchText, handleSearch }) => {


  return (
    <Fragment>
      <section className=" bg-[#ffffff] lg:block hidden border-r border pl-4">


        <div className="w-full py-3 px-10 flex justify-between items-center">
          <HeaderHeading label={"Referral"} />
          <div className="flex items-center gap-6">
            {/* <SearchIconComponent handleSearch={handleSearch} searchText={searchText} /> */}
          </div>
          <HeaderProfileComp />
        </div>
      </section>

      <section className="bg-white lg:hidden block">
        <MobileSearchHeader handleSearch={handleSearch} searchText={searchText} />
      </section>
    </Fragment>
  )
}

export default FeeReferenceHeader
