import React, { useEffect, useRef, useState } from 'react'
import { IoIosArrowDropdownCircle } from 'react-icons/io'
import { MdOutlineClose } from 'react-icons/md'

const OngoingBatchView = ({ data, closeModal, open, isFetching }) => {
    const [viewBatch, setViewBatch] = useState(null)
    const [showStudentDetails, setShowStudentDetails] = useState(null)
    const studentRef = useRef(null)

    const handleClickOutside = (event) => {
        if (studentRef.current && !studentRef.current.contains(event.target)) {
            setShowStudentDetails(null)
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleOpenViewBatch = (id) => {
        setViewBatch(viewBatch === id ? null : id)
    }
    return open && (

        <div className="fixed inset-0 flex items-center justify-center bg-gray-700 bg-opacity-50 z-50">
            <div className="lg:w-[40%] w-full p-4">
                <div className="flex justify-end">
                    <button
                        className="z-50 p-1 ml-auto bg-transparent border-0 text-white float-right text-lg leading-none font-semibold outline-none focus:outline-none inline-flex items-center"
                        onClick={() => { setViewBatch(null); setShowStudentDetails(null); closeModal() }}
                    >
                        <span><MdOutlineClose /></span> Close
                    </button>
                </div>
                <div className="bg-white rounded-lg overflow-hidden shadow-xl max-h-[90vh] overflow-y-auto">
                    <div className="p-4 ">
                        {data && data.length > 0 ? (
                            data.map((item, index) => (
                                <div key={index} className="">
                                    <div className="border-b pb-2 mb-2 flex justify-between items-center bg-[#F1F1F1] p-2 rounded-lg">
                                        <strong>Batch {index + 1}</strong>
                                        <div className="flex justify-between relative">
                                            <IoIosArrowDropdownCircle size={22} className="cursor-pointer text-[#023565] hover:text-[#E4006F]" onClick={() => { handleOpenViewBatch(item._id) }} />
                                        </div>
                                    </div>

                                    {viewBatch && (viewBatch === item._id) &&
                                        <div className="space-y-2">
                                            {item?.bookFreeDemo?.map((itm, id) => (
                                                <div className="flex justify-between">
                                                    <p>
                                                        <strong> Student Name:</strong>
                                                    </p>
                                                    <p className="capitalize">
                                                        <div className="relative flex gap-2 items-center">
                                                            {itm?.studentId?.studentFullName || "N/A"} {" "}
                                                            <IoIosArrowDropdownCircle size={18} className="cursor-pointer text-[#023565] hover:text-[#E4006F]" onClick={() => { setShowStudentDetails(showStudentDetails === itm?.studentId ? null : itm?.studentId) }} />
                                                        </div>
                                                        {showStudentDetails && showStudentDetails === itm?.studentId &&
                                                            <div className="absolute w-50 p-2 bg-white border border-gray-300 rounded-lg shadow-lg z-10"
                                                                ref={studentRef}>
                                                                <p>
                                                                    Class: {itm?.classId?.name || "N/A"}
                                                                </p>
                                                                <p>
                                                                    Subject: {itm?.subjectId?.map((ele) => ele?.name).join(', ') || "N/A"}
                                                                </p>
                                                                <p>
                                                                    Board: {itm?.board?.name || "N/A"}
                                                                </p>

                                                            </div>
                                                        }
                                                    </p>
                                                </div>))}


                                            <div className="flex justify-between">
                                                <p>
                                                    <strong>Class Duration:</strong>
                                                </p>
                                                <p>{item?.classDuration ?? "-"} Minutes</p>
                                            </div>


                                            <div className="flex justify-between">
                                                <p>
                                                    <strong>Assigned Tutor:</strong>
                                                </p>
                                                <p>{item?.tutorId
                                                    ?.name ?? 'N/A'}</p>
                                            </div>
                                            <div className="flex justify-between">
                                                <p>
                                                    <strong> Tutor Mob:</strong>
                                                </p>
                                                <p>{item?.tutorId
                                                    ?.mobNo ?? 'N/A'}</p>
                                            </div>
                                            <div className="flex justify-between">
                                                <p>
                                                    <strong>Tuition Fee:</strong>
                                                </p>
                                                <p>{item?.tuitionFee ?? "0"}</p>
                                            </div>
                                            <div className="flex justify-between">
                                                <p>
                                                    <strong>Tutor Fee:</strong>
                                                </p>
                                                <p>{item?.tutorFee ?? "0"}</p>
                                            </div>
                                            <div className="flex justify-between">
                                                <p>
                                                    <strong>Class Reference:</strong>
                                                </p>
                                                <p>{item.classReference}</p>
                                            </div>
                                            <div className="flex justify-between">
                                                <p>
                                                    <strong>Reference Fee:</strong>
                                                </p>
                                                <p>{item.referenceFee}</p>
                                            </div>
                                            <div className="flex justify-between">
                                                <p>
                                                    <strong>Company Share:</strong>
                                                </p>
                                                <p>{item.companyShare}</p>
                                            </div>
                                            <div className="flex justify-between">
                                                <p>
                                                    <strong>Tuition Start Date:</strong>
                                                </p>
                                                <p>{item.demoDate}</p>
                                            </div>
                                            <div className="flex justify-between">
                                                <p>
                                                    <strong>Fee Due Date:</strong>
                                                </p>
                                                <p>{item?.dueDate ?? 'N/A'
                                                }</p>
                                            </div>

                                            <div className="flex justify-between">
                                                <p>
                                                    <strong>Sessions Details:</strong>
                                                </p>
                                                <p>{item?.numberOfClasses}</p>
                                            </div>
                                            <div className="flex justify-between">
                                                <p>
                                                    <strong>Class Mode:</strong>
                                                </p>
                                                <p>{item && item?.preferredTeachMode && item?.preferredTeachMode === 'HOME_TUITION' ? 'Home Tuition' : item && item?.preferredTeachMode && item?.preferredTeachMode === 'ONLINE_TUITION' ? 'Online Tuition' : '---'}</p>
                                            </div>
                                            <div className="flex justify-between">
                                                <p>
                                                    <strong>Note:</strong>
                                                </p>
                                                <p>{item?.reason ?? "---"}</p>
                                            </div>

                                        </div>
                                    }
                                </div>
                            ))
                        ) : isFetching ? (

                            <div className="flex items-center justify-center border bg-gray-100">
                                <div className="flex items-center justify-center w-64 h-32 bg-white border border-gray-300 rounded-lg shadow-lg">
                                    <span className="text-black">Please wait...</span>
                                </div>
                            </div>
                        ) : (
                            <div className="flex items-center justify-center border bg-gray-100">
                                <div className="flex items-center justify-center w-64 h-32 bg-white border border-gray-300 rounded-lg shadow-lg">
                                    <span className="text-black">Data Not Available</span>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>)
}

export default OngoingBatchView