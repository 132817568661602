/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { FaFilterCircleXmark } from 'react-icons/fa6'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'
import { tutionAction } from '../../_actions'

const ParentTutorFilter = ({ filters, setFilters }) => {
  const selector = useSelector(state => state)
  const dispatch = useDispatch()

  const [selectedFilter, setSelectedFilter] = useState({})
  const [classList, setClassList] = useState([])
  const [subjectList, setSubjectList] = useState([])
  const [pinCodeList, setPinCodeList] = useState([])

  const customStyles = {
    container: provided => ({
      ...provided
    }),
    control: (provided, state) => ({
      ...provided,
      border: '1px solid #000',
      boxShadow: 'none',
      '&:hover': {
        border: '1px solid #000'
      },
      borderRadius: '5px'
    }),
    menu: provided => ({
      ...provided
    }),
    placeholder: provided => ({
      ...provided,
      color: '#000 !important'
    })
  }

  useEffect(() => {
    dispatch(tutionAction.gitAllClassListByAdmin({}))
  }, [])

  useEffect(() => {
    setClassList(() => selector?.tution?.getAllClassList?.data)
  }, [selector])

  const handleResetFilters = () => {
    let obj = { ...filters }
    Object.keys(obj).forEach(key => {
      obj[key] = ''
    })
    setFilters(obj)
    setSelectedFilter({})
    setPinCodeList([])
  }

  const handleClassChange = (id, selectedOption) => {
    const classSelected = classList.find(ele => id === ele._id)
    setSubjectList(classSelected?.subjectId || [])
    setFilters(prevState => ({
      ...prevState,
      classId: id,
      subjectId: ''
    }))
    setSelectedFilter(prevState => ({
      ...prevState,
      classId: selectedOption,
      subjectId: { label: 'Select Subject', value: '' }
    }))
  }

  const handleCityChange = (id, selectedOption) => {
    const citySelected = selector?.Citys?.cityGetList.find(
      ele => id === ele._id
    )
    setPinCodeList(citySelected?.pinCode || [])
    setFilters(prevState => ({
      ...prevState,
      cityId: id,
      pinCode: ''
    }))
    setSelectedFilter(prevState => ({
      ...prevState,
      cityId: selectedOption,
      pinCode: { label: 'Select pinCode', value: '' }
    }))
  }
  return (
    <>
      <div className='filter flex justify-end  flex-col md:flex-row gap-4 mt-10'>
        {filters?.cityId != null && (
          <Select
            name='cityId'
            className='capitalize w-[25%]'
            styles={customStyles}
            classNamePrefix='react-select'
            value={selectedFilter?.cityId || { label: 'All City', value: '' }}
            options={
              selector?.Citys?.cityGetList?.length
                ? [
                    { label: 'All City', value: '' },
                    ...selector?.Citys?.cityGetList.map(city => ({
                      label: city.name,
                      value: city._id
                    }))
                  ]
                : []
            }
            onChange={(selectedOption, actionMeta) => {
              handleCityChange(selectedOption.value, selectedOption)
            }}
            placeholder='All City'
          />
        )}
        {filters?.cityId != null && filters?.pinCode != null && (
          <>
            <Select
              id='pinCode'
              name='pinCode'
              className='capitalize w-[25%] text-black'
              styles={customStyles}
              classNamePrefix='react-select'
              value={
                selectedFilter?.pinCode || {
                  label: 'Select PinCode',
                  value: ''
                }
              }
              options={
                pinCodeList && pinCodeList.length > 0
                  ? [
                      { label: 'Select PinCode', value: '' },
                      ...pinCodeList.map(pin => ({
                        label: pin,
                        value: pin
                      }))
                    ]
                  : []
              }
              onChange={(selectedOption, actionMeta) => {
                setFilters(prevState => ({
                  ...prevState,
                  pinCode: selectedOption.value
                }))
                setSelectedFilter(prevState => ({
                  ...prevState,
                  pinCode: selectedOption
                }))
              }}
              placeholder='Select PinCode'
            />
          </>
        )}
        {filters.gender != null && (
          <Select
            id='gender'
            name='gender'
            className='capitalize w-[25%] text-black'
            styles={customStyles}
            classNamePrefix='react-select'
            value={
              selectedFilter?.gender || { label: 'Select Gender', value: '' }
            }
            options={[
              { value: '', label: 'Select Gender' },
              { value: 'MALE', label: 'Male' },
              { value: 'FEMALE', label: 'Female' }
            ]}
            onChange={(selectedOption, actionMeta) => {
              setFilters(prevState => ({
                ...prevState,
                gender: selectedOption.value
              }))
              setSelectedFilter(prevState => ({
                ...prevState,
                gender: selectedOption
              }))
            }}
            placeholder='Gender'
          />
        )}
        {filters?.classId != null && (
          <>
            <Select
              id='classId'
              name='classId'
              className='capitalize w-[25%] text-black'
              styles={customStyles}
              classNamePrefix='react-select'
              value={
                selectedFilter?.classId || { label: 'Select Class', value: '' }
              }
              options={
                classList && classList.length > 0
                  ? [
                      { label: 'Select Class', value: '' },
                      ...classList.map(tutor => ({
                        label: tutor.name,
                        value: tutor._id
                      }))
                    ]
                  : []
              }
              onChange={(selectedOption, actionMeta) =>
                handleClassChange(selectedOption.value, selectedOption)
              }
              placeholder='Class'
            />
          </>
        )}
        {filters?.classId != null && filters?.subjectId != null && (
          <>
            <Select
              id='subjectId'
              name='subjectId'
              className='capitalize w-[25%] text-black'
              styles={customStyles}
              classNamePrefix='react-select'
              value={
                selectedFilter?.subjectId || {
                  label: 'Select PinCode',
                  value: ''
                }
              }
              options={
                subjectList && subjectList.length > 0
                  ? [
                      { label: 'Select Subject', value: '' },
                      ...subjectList.map(tutor => ({
                        label: tutor.name,
                        value: tutor._id
                      }))
                    ]
                  : []
              }
              onChange={(selectedOption, actionMeta) => {
                setFilters(prevState => ({
                  ...prevState,
                  subjectId: selectedOption.value
                }))
                setSelectedFilter(prevState => ({
                  ...prevState,
                  subjectId: selectedOption
                }))
              }}
              placeholder='Subject'
            />
          </>
        )}
        <button
          className='text-center font-bold'
          onClick={() => handleResetFilters()}
        >
          <FaFilterCircleXmark
            size={24}
            className=' mx-auto text-[#023565] hover:text-[#E4006F] cursor-pointer'
          />
        </button>
      </div>
    </>
  )
}

export default ParentTutorFilter
