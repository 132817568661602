export const assignmentConstant = {
    GET_ASSIGNMENT_LIST_REQUEST: "GET_ASSIGNMENT_LIST_REQUEST",
    GET_ASSIGNMENT_LIST_SUCCESS: "GET_ASSIGNMENT_LIST_SUCCESS",
    GET_ASSIGNMENT_LIST_FAILURE: "GET_ASSIGNMENT_LIST_FAILURE",


    CREATE_ASSIGNMENT_LIST_REQUEST: "CREATE_ASSIGNMENT_LIST_REQUEST",
    CREATE_ASSIGNMENT_LIST_SUCCESS: "CREATE_ASSIGNMENT_LIST_SUCCESS",
    CREATE_ASSIGNMENT_LIST_FAILURE: "CREATE_ASSIGNMENT_LIST_FAILURE",

    DELETE_ASSIGNMENT_LIST_REQUEST: "DELETE_ASSIGNMENT_LIST_REQUEST",
    DELETE_ASSIGNMENT_LIST_SUCCESS: "DELETE_ASSIGNMENT_LIST_SUCCESS",
    DELETE_ASSIGNMENT_LIST_FAILURE: "DELETE_ASSIGNMENT_LIST_FAILURE",

    GET_ALL_TUITION_LIST_REQUEST: "GET_ALL_TUITION_LIST_REQUEST",
    GET_ALL_TUITION_LIST_SUCCESS: "GET_ALL_TUITION_LIST_SUCCESS",
    GET_ALL_TUITION_LIST_FAILURE: "GET_ALL_TUITION_LIST_FAILURE",

    UPDATE_ASSIGNMENT_REQUEST: "UPDATE_ASSIGNMENT_REQUEST",
    UPDATE_ASSIGNMENT_SUCCESS: "UPDATE_ASSIGNMENT_SUCCESS",
    UPDATE_ASSIGNMENT_FAILURE: "UPDATE_ASSIGNMENT_FAILURE",

    GET_ASSIGNMENT_CITY_LIST_REQUEST: "GET_ASSIGNMENT_CITY_LIST_REQUEST",
    GET_ASSIGNMENT_CITY_LIST_SUCCESS: "GET_ASSIGNMENT_CITY_LIST_SUCCESS",
    GET_ASSIGNMENT_CITY_LIST_FAILURE: "GET_ASSIGNMENT_CITY_LIST_FAILURE",


    GET_ASSIGNMENT_CLASS_LIST_REQUEST: "GET_ASSIGNMENT_CLASS_LIST_REQUEST",
    GET_ASSIGNMENT_CLASS_LIST_SUCCESS: "GET_ASSIGNMENT_CLASS_LIST_SUCCESS",
    GET_ASSIGNMENT_CLASS_LIST_FAILURE: "GET_ASSIGNMENT_CLASS_LIST_FAILURE",



    GET_ASSIGNMENT_TUITION_LIST_REQUEST: "GET_ASSIGNMENT_TUITION_LIST_REQUEST",
    GET_ASSIGNMENT_TUITION_LIST_SUCCESS: "GET_ASSIGNMENT_TUITION_LIST_SUCCESS",
    GET_ASSIGNMENT_TUITION_LIST_FAILURE: "GET_ASSIGNMENT_TUITION_LIST_FAILURE",

}