import React from "react";
import "./Dashboard.css";
import { useNavigate } from "react-router";
import { onDemoTuition, OnGoingTuitionSvg, requirementPostes } from "../../_helpers/svgFunction";

const Card = ({ index, title, buttonName, Group3, data, link }) => {

  const ImageGroup = {
    img1: OnGoingTuitionSvg(index === 0 ? "#C60E6B" : "#023565"),
    img2: onDemoTuition(index === 1 ? "#C60E6B" : "#023565"),
    img3: requirementPostes(index === 2 ? "#C60E6B" : "#023565"),
  };


  const navigate = useNavigate()

  const handelClickRedirect = (url) => {
    navigate(url)
  }

  const getDataByTitle = (title, data) => {
    if (title === "Ongoing Tuitions") return data?.onGoingTuition ?? 0;
    if (title === "On-Demo Tuitions") return data?.onDemoTuition ?? 0;
    if (title === "Demo Done") return data?.demoDoneTuition ?? 0;
    if (title === "Demo Request") return data?.demoRequest ?? 0;
    if (title === "Tutor Change Request") return data?.tutorChange ?? 0;
    if (title === "Referrals") return data?.referral ?? 0;
    return 0;
  };



  let image = ImageGroup[Group3];

  return (
    <>
      <div className="card card_small flex flex-col flex-1  text-center rounded-3xl" >
        <div className="card_heading flex  justify-between  mx-3 my-2 ">
          <div className=" w-16 h-16 card_img border border-[#023565] rounded-full flex justify-center items-center bg-white">
            {image}
          </div>
          <div className="p-3">
            <h1 className="text-2xl number font-bold text-center ">
              {getDataByTitle(title, data)}
            </h1>
          </div>
        </div>
        <div className="text-end mb-5 px-6">
          <h3 className="text-lg title py-2">{title}</h3>
          <button className="btn underline viewCard font-bold" onClick={() => handelClickRedirect(link)}>
            {buttonName}
          </button>
        </div>
      </div>
    </>
  );
};

export default Card;
